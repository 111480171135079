import { defineAsyncComponent } from 'vue'

const ClientCommunicationMessageModal = defineAsyncComponent(() =>
  import('./client-communication-message-modal.vue'),
)
const ClientCommunicationMessagesTable = defineAsyncComponent(() =>
  import('./client-communication-messages-table.vue'),
)

const ClientCommunicationMessagesDeliverAt = defineAsyncComponent(() =>
  import('./client-communication-messages-deliver-at-time.vue'),
)

const ClientCommunicationMessagesSentAt = defineAsyncComponent(() =>
  import('./client-communication-messages-sent-at-time.vue'),
)

const KINDS = [
  { value: 'pristine', label: 'Original' },
  { value: 'update', label: 'Update' },
  { value: 'opt_out', label: 'Opt-out' },
]

const STATES = [
  { value: 'outbox', label: 'Outbox' },
  { value: 'sending', label: 'Sending now' },
  { value: 'sent', label: 'Sent' },
  { value: 'not_sent', label: 'Not sent' },
  { value: 'discarded', label: 'Discarded' },
]

export {
  ClientCommunicationMessageModal,
  ClientCommunicationMessagesDeliverAt,
  ClientCommunicationMessagesSentAt,
  ClientCommunicationMessagesTable,
  KINDS,
  STATES,
}
