<script>
import { getEventOrganizationUser } from 'frontend/common/access-helpers'
import { useBlocker } from 'frontend/common/use-blocker'
import { EventOrganizationUsersService } from 'frontend/event-organization-users/event-organization-users-service'
import { EventsService } from 'frontend/events/events-service'
import { ref } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'EventTimeZoneChooser',
  inheritAttrs: false,
  data() {
    return {
      availableTimeZones: [],
    }
  },
  setup() {
    const { blocker, isBlocked } = useBlocker()
    const service = new EventsService({ blocker })
    const eouService = new EventOrganizationUsersService()
    const windowWidth = ref(window.innerWidth)
    return { service, blocker, isBlocked, eouService, windowWidth }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent']),
    ...mapGetters('session', ['currentUser']),
    eou() {
      if (this.currentUser && this.currentEvent) {
        return getEventOrganizationUser(this.currentUser, this.currentEvent.slug)
      } else {
        return null
      }
    },
    isNotSmallDevice() {
      return this.windowWidth > 576 // sm bootstrap $grid-breakpoint
    },
  },
  watch: {
    '$route.params.eventSlug': {
      immediate: true,
      handler() {
        this.eouService = new EventOrganizationUsersService()
        this.service = new EventsService({ blocker: this.blocker })
        this.fetchAvailableTimeZones()
      },
    },
    eou: {
      immediate: true,
      handler() {
        this.chooseAndSetTz()
      },
    },
  },
  mounted() {
    this.fetchAvailableTimeZones()
    this.$eventHub.$on('windowResize', this.setWindowWidth)
  },
  unmounted() {
    this.$eventHub.$off('windowResize', this.setWindowWidth)
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    async fetchAvailableTimeZones() {
      if (this.$route.params.eventSlug?.length) {
        const result = await this.service.availableTimeZones()
        this.availableTimeZones = result.sort()
        if (!this.availableTimeZones.includes(dayjs.tz.guess())) {
          this.availableTimeZones.push(dayjs.tz.guess())
        }
      } else {
        this.availableTimeZones = []
      }
      this.chooseAndSetTz()
    },
    chooseAndSetTz() {
      if (this.eou && this.availableTimeZones?.length) {
        if (
          this.eou.currentTimeZone?.length &&
          this.availableTimeZones.includes(this.eou.currentTimeZone)
        ) {
          // console.log(
          //   'chooseAndSetTz -',
          //   'last known:',
          //   this.eou?.currentTimeZone,
          //   ', available:',
          //   [...this.availableTimeZones],
          //   ', resolved:',
          //   this.eou.currentTimeZone,
          // )
          this.setTz(this.eou.currentTimeZone)
        } else {
          // console.log(
          //   'chooseAndSetTz -',
          //   'last known:',
          //   this.eou?.currentTimeZone,
          //   ', available:',
          //   [...this.availableTimeZones],
          //   ', resolved:',
          //   this.availableTimeZones[0],
          // )
          this.setTz(this.availableTimeZones[0])
        }
      } else {
        // console.log(
        //   'chooseAndSetTz -',
        //   'last known:',
        //   this.eou?.currentTimeZone,
        //   ', available:',
        //   [...this.availableTimeZones],
        //   ', resolved:',
        //   dayjs.tz.guess(),
        // )
        this.setTz(dayjs.tz.guess())
      }
    },
    setTz(tz, closeFn) {
      this.$store.commit('session/setTimeZone', tz)
      if (closeFn) closeFn()
    },
    setAndMemoizeTz(tz, closeFn) {
      this.$store.commit('session/setTimeZone', tz)
      if (closeFn) closeFn()
      if (this.eou) {
        this.eouService
          .saveCurrentTimeZone(this.eou.id, tz, () => ({}))
          .then(() => {
            this.eou.currentTimeZone = tz
          })
      }
    },
  },
}
</script>
<template lang="pug">
dropdown#event-time-zone-chooser(:alignRight=isNotSmallDevice)
  .cursor-pointer.position-relative(v-bind="$attrs")
    ea-spinner(v-if="isBlocked" :spinnerSize="5" matchParent)
    | {{ $store.state.session.timeZone }}
    i.ms-1.fa.fa-chevron-down
  template(#items="{ closeFn }")
    li.small(v-if="availableTimeZones.length")
      h6.dropdown-header Choose a time zone
    li.small(v-else)
      h6.dropdown-header(v-if="$route.params.eventSlug?.length")
        | The event has no clusters or locations configured
      h6.dropdown-header(v-else)
        | Choose an event in order to change time zone
    li.small(v-for="tz in availableTimeZones")
      a.dropdown-item(@click="setAndMemoizeTz(tz, closeFn)") {{ tz }}
</template>
