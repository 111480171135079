import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const ClusterDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'time_zone'
  ],
  subscriptions: {
    items: 'ClustersChannel',
  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}