import { defineAsyncComponent } from 'vue'

const ClientGroup = defineAsyncComponent(() => import('./client-group.vue'))
const ClientGroupFormButton = defineAsyncComponent(() => import('./client-group-form-button.vue'))
const ClientGroupFormModal = defineAsyncComponent(() => import('./client-group-form-modal.vue'))
const ClientGroupsSelect = defineAsyncComponent(() => import('./client-groups-select.vue'))
const ClientGroupsTable = defineAsyncComponent(() => import('./client-groups-table.vue'))

export {
  ClientGroup,
  ClientGroupFormButton,
  ClientGroupFormModal,
  ClientGroupsSelect,
  ClientGroupsTable,
}
