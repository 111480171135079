import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const EventDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'slug',
    'created_at',
    'updated_at',
    'smtp_configured',
    'sms_configured',
    'logo_urls',
    'start_date',
    'end_date',
    'archived_at',
  ],
  subscriptions: {
    items: 'EventsChannel',
  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}