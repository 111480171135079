<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalNavigation',
  computed: {
    ...mapGetters('modals', ['openedModals', 'modalCounter']),
    isModalFullScreen() {
      return this.openedModals?.[this.modalCounter]?.modalRef?.isModalFullScreen
    },
  },
  methods: {
    closeModalsUntil(index) {
      Object.keys(this.openedModals)
        .filter(key => key > index)
        .forEach(key => this.openedModals[key]?.modalRef?.close())
    },
  },
}
</script>

<template lang="pug">
.stack-position.cursor-pointer(
  v-if="modalCounter > 1"
  :class="{ 'fullscreen-stack': isModalFullScreen }"
)
  dropdown(alignRight)
    .stack {{ modalCounter }}
    template(#items)
      li.text-nowrap(
        v-for="[index, modal] in Object.entries(openedModals)"
        :class="{ disabled: index == modalCounter }"
        @click="closeModalsUntil(index)"
      )
        a.dropdown-item(:class="{ 'current-modal': index == modalCounter }")
          | {{ index }} - {{ modal.name }}
      hr.my-2
      li.close-all(@click="closeModalsUntil(0)")
        a.dropdown-item Close all
</template>

<style lang="scss" scoped>
.stack-position {
  position: fixed;
  top: 25px;
  right: 25px;
  z-index: 2000;
  transition: transform 300ms;
  @media (max-width: 1024px) {
    transition: transform 300ms ease-out;
    transform: translateY(75px);
  }
  &.fullscreen-stack {
    transition: transform 300ms ease-out;
    transform: translateY(55px);
  }
}
.stack {
  box-shadow: 0 0 8px 2px rgb(255 255 255 / 25%);
  width: 1.6em;
  height: 1.6em;
  font-size: 1.3em;
  border-radius: 50%;
  background: var(--custom-primary-darker);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
}
.current-modal {
  background: var(--custom-primary);
  color: #fff;
}
</style>
