import { defineAsyncComponent } from 'vue'

const Osl = defineAsyncComponent(() => import('./osl.vue'))
const OslModal = defineAsyncComponent(() => import('./osl-modal.vue'))
const OslsSelect = defineAsyncComponent(() => import('./osls-select.vue'))
const OslTbRuleFormModal = defineAsyncComponent(() =>
  import('./tb-rules/osl-tb-rule-form-modal.vue'),
)
const OslTbRulesTable = defineAsyncComponent(() => import('./tb-rules/osl-tb-rules-table.vue'))

export { Osl, OslModal, OslsSelect, OslTbRuleFormModal, OslTbRulesTable }
