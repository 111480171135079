import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function useOperationalModulesSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }
  const section = computed(() => {
    const result = {
      name: 'Operational Modules',
      items: [
        {
          title: 'Dispatcher',
          isDisabled: false,
          path: 'dispatcher-screen',
          icon: 'bi-bar-chart-steps',
        },
        {
          title: 'Shifts Manager',
          isDisabled: false,
          path: 'shifts-manager-screen',
          icon: 'bi-columns-gap',
        },
        {
          title: 'Live Panel',
          isDisabled: false,
          path: 'live-panel',
          icon: 'fas fa-location-arrow',
        },
        {
          title: 'Client Communication Messages',
          isDisabled: false,
          path: 'client-communication-messages',
          icon: 'bi-envelope',
        },
        {
          title: 'System Notifications',
          isDisabled: false,
          path: 'system-notifications',
          icon: 'bi-bell-fill',
        },
      ],
    }
    result.items = result.items.filter(el => hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
