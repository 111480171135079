import { defineAsyncComponent } from 'vue'

const ShiftJobsTable = defineAsyncComponent(() => import('./shift-jobs-table.vue'))

const ALL_KNOWN_AASM_STATES = [
  { value: 'pending', label: 'Pending' },
  { value: 'in_route', label: 'In route' },
  { value: 'at_pickup_site', label: 'At pick-up site' },
  { value: 'picked_up', label: 'Picked up' },
  { value: 'in_progress', label: 'In progress' },
  { value: 'started', label: 'Started' },
  { value: 'at_dropoff_site', label: 'At drop-off site' },
  { value: 'dropped_off', label: 'Dropped off' },
  { value: 'completed', label: 'Completed' },
  { value: 'no_show', label: 'Incomplete', hidden: true },
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'declined', label: 'Declined', disabledForAd: true },
  { value: 'cancelled', label: 'Cancelled', disabledForAd: true },
]

const AASM_STATES = ALL_KNOWN_AASM_STATES.filter(el => !el.hidden)

const AASM_STATES_MAPPING = ALL_KNOWN_AASM_STATES.reduce((result, next) => {
  result[next.value] = next.label
  return result
}, {})

const TYPES = [
  { value: 'TransferGroup', label: 'Transfer Group' },
  { value: 'ActivityTransfer', label: 'Activity Transfer' },
  { value: 'ShuttleTransfer', label: 'Shuttle Transfer' },
  { value: 'ShiftAction', label: 'Shift Action' },
]

const TYPE_MAPPING = TYPES.reduce((result, next) => {
  result[next.value] = next.label
  return result
}, {})

const TG_AT_IN_PROGRESS_STATES = new Set([
  'in_route',
  'at_pickup_site',
  'picked_up',
  'in_progress',
  'at_dropoff_site',
])
const TG_AT_PAST_STATES = new Set(['no_show', 'dropped_off'])

const SA_IN_PROGRESS_STATES = new Set(['started'])
const SA_PAST_STATES = new Set(['completed', 'incomplete', 'declined'])

const ST_IN_PROGRESS_STATES = new Set(['started'])
const ST_PAST_STATES = new Set(['cancelled', 'completed', 'incomplete'])

export {
  AASM_STATES,
  AASM_STATES_MAPPING,
  SA_IN_PROGRESS_STATES,
  SA_PAST_STATES,
  ShiftJobsTable,
  ST_IN_PROGRESS_STATES,
  ST_PAST_STATES,
  TG_AT_IN_PROGRESS_STATES,
  TG_AT_PAST_STATES,
  TYPE_MAPPING,
  TYPES,
}
