import axios from 'frontend/_config/axios'
import store from 'frontend/_stores'

export class ValidationService {
  constructor({ apiProvider = axios, baseUrl, blocker, validationUrl = `${baseUrl}/validations` }) {
    this.baseUrl = baseUrl
    this.apiProvider = apiProvider
    this.blocker = blocker
    this.validationUrl = validationUrl
  }

  getValidations({ validationUrl = this.validationUrl }) {
    if (this.blocker) {
      this.blocker.validation = true
    }
    return this.apiProvider
      .get(validationUrl)
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        console.error('validation service error', error)
        store.dispatch('toast/error', { message: 'Could not fetch validations' })
      })
      .finally(() => {
        if (this.blocker) {
          delete this.blocker.validation
        }
      })
  }
}
