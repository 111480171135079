import { convertColumnWidthMapToStoredWidths } from 'frontend/columns/functions/convertColumnWidthMapToStoredWidths'
import { convertStoredWidthsToColumnWidthMap } from 'frontend/columns/functions/convertStoredWidthsToColumnWidthMap'
import { IColumnPresetData } from 'frontend/columns/types/IColumnPresetData'
import { IColumnPresetResponseEntity } from 'frontend/columns/types/IColumnPresetResponseEntity'
import { IStoredUnsavedColumnPresetItem } from 'frontend/columns/types/IStoredUnsavedColumnPresetItem'
import { IUnsavedColumnPresetItem } from 'frontend/columns/types/IUnsavedColumnPresetItem'
import { useSortItems } from 'frontend/common/composables/useSortItems'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import localForage from 'localforage'
import { computed, onMounted, type Ref, ref, watch } from 'vue'

export function useUnsavedColumnPresetItems(
  dataModelType: DataModelType,
  currentColumnPresetId: Ref<string | null>,
  columnPresets: Ref<IColumnPresetResponseEntity[]>,
) {
  const { convertStoredSortToSortItems, convertSortItemsToStoredSort } = useSortItems()

  const unsavedColumnPresetItems = ref<IUnsavedColumnPresetItem[]>([])

  const currentUnsavedColumnPresetData = computed<IColumnPresetData>({
    get() {
      return (
        unsavedColumnPresetItems.value.find(presetItem => {
          return presetItem.columnPresetId === currentColumnPresetId.value
        })?.data || null
      )
    },
    set(newValue: IColumnPresetData) {
      const unsavedColumnPresetItem = unsavedColumnPresetItems.value.find(preset => {
        return preset.columnPresetId === currentColumnPresetId.value
      })
      if (unsavedColumnPresetItem) {
        unsavedColumnPresetItems.value = unsavedColumnPresetItems.value.map(preset => {
          if (preset.columnPresetId === currentColumnPresetId.value) {
            return {
              columnPresetId: currentColumnPresetId.value,
              data: newValue,
            }
          }
          return preset
        })
      } else {
        unsavedColumnPresetItems.value = [
          ...unsavedColumnPresetItems.value,
          {
            columnPresetId: currentColumnPresetId.value,
            data: newValue,
          },
        ]
      }
    },
  })

  const persistedCurrentUnsavedColumnPresetData = computed<IStoredUnsavedColumnPresetItem[]>({
    get() {
      return unsavedColumnPresetItems.value.map(e => {
        return {
          columnPresetId: e.columnPresetId,
          data: {
            visibleColumnTypes: e.data.visibleColumnTypes || undefined,
            sortItems: convertSortItemsToStoredSort(e.data.sortItems) || undefined,
            columnWidths: convertColumnWidthMapToStoredWidths(e.data.columnWidthsMap) || undefined,
          },
        }
      })
    },
    set(value: IStoredUnsavedColumnPresetItem[]) {
      unsavedColumnPresetItems.value = value.map(e => {
        return {
          columnPresetId: e.columnPresetId,
          data: {
            visibleColumnTypes: e.data.visibleColumnTypes || undefined,
            sortItems: e.data.sortItems
              ? convertStoredSortToSortItems(e.data.sortItems)
              : undefined,
            columnWidthsMap: e.data.columnWidths
              ? convertStoredWidthsToColumnWidthMap(e.data.columnWidths)
              : undefined,
          },
        }
      })
    },
  })

  const unsavedDataWasLoaded = ref(false)
  watch(persistedCurrentUnsavedColumnPresetData, async value => {
    if (!unsavedDataWasLoaded.value) {
      return
    }
    console.log('[UNSAVED PRESETS] Saving to local storage...')
    await localForage.setItem(`unsavedColumnPresetItems:${dataModelType}`, JSON.stringify(value))
  })

  onMounted(async () => {
    if (!dataModelType) {
      return
    }
    const unsavedPresetValuesFromStorage = await localForage.getItem(
      `unsavedColumnPresetItems:${dataModelType}`,
    )
    console.log('[UNSAVED PRESETS] Getting from local storage...')
    if (unsavedPresetValuesFromStorage) {
      persistedCurrentUnsavedColumnPresetData.value = JSON.parse(
        unsavedPresetValuesFromStorage as string,
      )
    }
    unsavedDataWasLoaded.value = true
  })

  return {
    unsavedColumnPresetItems,
    currentUnsavedColumnPresetData,
  }
}
