<script>
import mapbox from 'mapbox-gl'
import {
  defineAsyncComponent,
  inject,
  markRaw,
  onMounted,
  onUnmounted,
  provide,
  ref,
  useSlots,
  watch,
} from 'vue'

export const markerMapEvents = ['dragstart', 'drag', 'dragend']

export default {
  name: 'Marker',
  inheritAttrs: false,
  emits: [...markerMapEvents],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    coordinates: {
      type: Array,
      required: true,
    },
    cursor: {
      type: String,
      default: 'pointer',
    },
  },
  setup(props, { emit }) {
    const slots = useSlots()
    const map = inject('map')
    const marker = ref()
    provide('marker', marker)
    const markerEl = ref(null)

    onMounted(() => {
      const element = slots.default ? markerEl.value : null
      marker.value = markRaw(
        new mapbox.Marker(element, {
          anchor: element ? 'bottom' : null,
          ...props.options,
        }).setLngLat([...props.coordinates]),
      )

      addToMap(marker.value)
      setHoverCursor()
      listenMarkerEvents()
    })

    onUnmounted(() => {
      removeFromMap(marker.value)
      markerEl.value?.remove()
    })

    watch(
      () => props.coordinates,
      () => {
        updateMarkerPosition(marker.value)
      },
    )

    function removeFromMap(marker) {
      marker?.remove()
      marker = null
    }

    function addToMap(marker) {
      marker.addTo(map.value)
    }

    function setHoverCursor() {
      marker.value.getElement().style.cursor = props.options.cursor || 'default'
    }

    function updateMarkerPosition(marker) {
      if (props.coordinates) {
        marker.setLngLat(props.coordinates).addTo(map.value)
      } else {
        removeFromMap(marker.value)
      }
    }
    function listenMarkerEvents() {
      let coordinates
      // Listen to Marker Mapbox events
      markerMapEvents.forEach(event => {
        marker.value.on(event, e => {
          if (event === 'dragend') {
            if (props.coordinates instanceof Array) {
              coordinates = [e.target._lngLat.lng, e.target._lngLat.lat]
            } else {
              coordinates = e.target._lngLat
            }
            emit('update:coordinates', coordinates)
          }
          emit(event, e)
        })
      })
    }
    return {
      markerEl,
      marker,
    }
  },
  components: {
    popup: defineAsyncComponent('./popup.vue'),
  },
}
</script>

<template lang="pug">
div
  .ea-marker(ref="markerEl" v-bind="$attrs")
    slot(v-if="marker")

slot(v-if="marker" name="popup")
</template>
