import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import store from 'frontend/_stores'
import { POST, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'
import { startCase } from 'lodash'

export class EventOrganizationUsersService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/event_organization_users`,
    blocker,
    fetchError = 'Failed to fetch organization user.',
    saveError = 'Failed to save organization user.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'event-organization-users',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return () =>
      this.selectService.fetchOptions({
        fields: ['id', { user: ['id', 'email', 'name', 'surname'] }],
        sorts: 'user_username',
      })
  }

  saveFilterConfig(id, data, errorHandler = e => this._onSaveError(e)) {
    return this._makeCall('put', errorHandler, {
      url: `${this.baseUrl}/${id}/filter_config`,
      data: { eventOrganizationUser: data },
    })
  }

  saveCurrentTimeZone(id, tz, errorHandler = e => this._onSaveError(e)) {
    return this._makeCall('post', errorHandler, {
      url: `${this.baseUrl}/${id}/update_time_zone`,
      data: { time_zone: tz },
    })
  }

  prepareMessageFromErrors(errors, delimiter = '<br>') {
    const result = []
    Object.keys(errors).forEach(k => {
      result.push(`${startCase(k.replace('Id', ''))}: ${errors[k].join(', ')}`)
    })
    return result.join(delimiter)
  }

  dispatchAction(
    action,
    id,
    { data = {}, url = `${this.baseUrl}${id ? '/' + id : ''}/${action}`, errorHandler = null } = {},
  ) {
    const passedErrorHandler =
      errorHandler ||
      (error => {
        const messagePayload = { message: 'Requested action cannot be run' }
        messagePayload.message = error.response?.data?.message || messagePayload.message
        if (error.response?.status == 422 && error.response?.data?.errors?.base?.length) {
          messagePayload.title = messagePayload.message
          messagePayload.message =
            error.response.data.errors.base.constructor == Array
              ? error.response.data.errors.base.join('; ')
              : error.response.data.errors.base
        } else if (error.response?.status == 422 && error.response?.data?.errors) {
          messagePayload.title = messagePayload.message
          messagePayload.message = this.prepareMessageFromErrors(error.response.data.errors)
        }
        store.dispatch('toast/error', messagePayload)
      })
    return this._makeCall(POST, passedErrorHandler, { url, data })
  }
}
