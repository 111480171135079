import { Cluster } from 'frontend/clusters'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { Depot } from 'frontend/depots'
import { VehicleCategory } from 'frontend/vehicle-categories'
import { VehicleModel } from 'frontend/vehicle-models'
import { VehicleSource } from 'frontend/vehicle-sources'
import { VehicleType } from 'frontend/vehicle-types'
import { markRaw } from 'vue'

export const VehicleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'doors_number',
    'fleet_number',
    'fuel',
    'luggage_size',
    'production_year',
    'registration_plate',
    'seat_capacity',
    'toilet_on_board',
    'updated_at',
    'created_at',
    'phone',
    { cluster: ['id', 'name', 'time_zone'] },
    { depot: ['id', 'name', { location: ['id', 'name', 'time_zone'] }] },
    { vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
    { vehicle_model: ['id', 'name', { vehicle_brand: ['id', 'name'] }] },
    { vehicle_source: ['id', 'name', 'billable', 'maintainable'] },
    { vehicle_type: ['id', 'name'] },
  ],
  subscriptions: {
    clusters: 'ClustersSimpleChannel',
    depots: 'DepotsSimpleChannel',
    items: 'VehiclesChannel',
    locations: 'LocationsFlatChannel',
    vehicleBrands: 'VehicleBrandsSimpleChannel',
    vehicleCategories: 'VehicleCategoriesSimpleChannel',
    vehicleModels: 'VehicleModelsSimpleChannel',
    vehicleSources: 'VehicleSourcesSimpleChannel',
    vehicleTypes: 'VehicleTypesSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'fleetNumber',
    'phone',
    'cluster',
    'vehicleModel',
    'vehicleType',
    'vehicleCategory',
    'vehicleSource',
    'registrationPlate',
    'depot',
    'seatCapacity',
    'doorsNumber',
    'fuel',
    'luggageSize',
    'productionYear',
    'toiletOnBoard',
    'updatedAt',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    fleetNumber: {
      label: 'Fleet number',
      header: {
        sort: ['fleetNumberPrefix', 'fleetNumberInt'],
      },
      cell: {
        kind: 'simple',
        path: 'fleetNumber',
      },
    },
    phone: {
      label: 'Phone',
      header: {
        sort: 'phone',
      },
      cell: {
        kind: 'component',
        component: 'phone-number',
        componentProps: vehicle => ({ phoneNumber: vehicle.phone }),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({ cluster: item.cluster }),
      },
    },
    vehicleModel: {
      label: 'Model',
      header: {
        sort: ['vehicleBrandName', 'vehicleModelName'],
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleModel),
        componentProps: item => ({ vehicleModel: item.vehicleModel }),
      },
    },
    vehicleType: {
      label: 'Type',
      header: {
        sort: 'vehicleTypeName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleType),
        componentProps: item => ({ vehicleType: item.vehicleType }),
      },
    },
    vehicleCategory: {
      label: 'Category',
      header: {
        sort: 'vehicleCategoryPosition',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleCategory),
        componentProps: item => ({ vehicleCategory: item.vehicleCategory }),
      },
    },
    vehicleSource: {
      label: 'Source',
      header: {
        sort: 'vehicleSourceName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleSource),
        componentProps: item => ({ vehicleSource: item.vehicleSource }),
      },
    },
    registrationPlate: {
      label: 'Registration plate',
      header: {
        sort: 'registrationPlate',
      },
      cell: {
        kind: 'simple',
        path: 'registrationPlate',
      },
    },
    depot: {
      label: 'Depot',
      header: {
        sort: 'depotName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Depot),
        componentProps: item => ({ depot: item.depot }),
      },
    },
    seatCapacity: {
      label: 'Seat capacity',
      header: {
        sort: 'seatCapacity',
      },
      cell: {
        kind: 'simple',
        path: 'seatCapacity',
      },
    },
    doorsNumber: {
      label: 'Doors number',
      header: {
        sort: 'doorsNumber',
      },
      cell: {
        kind: 'simple',
        path: 'doorsNumber',
      },
    },
    fuel: {
      label: 'Fuel',
      header: {
        sort: 'fuel',
      },
      cell: {
        kind: 'simple',
        path: 'fuel',
      },
    },
    luggageSize: {
      label: 'Luggage size',
      header: {
        sort: 'luggageSize',
      },
      cell: {
        kind: 'simple',
        path: 'luggageSize',
      },
    },
    productionYear: {
      label: 'Year',
      header: {
        sort: 'productionYear',
      },
      cell: {
        kind: 'simple',
        path: 'productionYear',
      },
    },
    toiletOnBoard: {
      label: 'Toilet',
      header: {
        sort: 'toiletOnBoard',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: item => ({ item: item.toiletOnBoard }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt, format: 'dateOnly' }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt, format: 'timeOnly' }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt, format: 'dateOnly' }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt, format: 'timeOnly' }),
      },
    },
  },
}
