<script>
export default {
  name: 'TrueFalseLabel',
  props: {
    item: {
      type: [Boolean, Object],
      default: false,
    },
    labels: {
      type: Object,
      default: () => ({ yes: 'Yes', no: 'No' }),
    },
    revertColors: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    class: {
      type: [String, Array, Object],
    },
  },
}
</script>

<template lang="pug">
span.text-black-15(:class="class" v-if="hide") empty
template(v-else)
  span(:class="class")
    span.badge(v-if="!!item" :class="revertColors ? 'bg-danger' : 'bg-success'") {{ labels['yes'] }}
    span.badge(v-else :class="revertColors ? 'bg-success' : 'bg-danger'") {{ labels['no'] }}
</template>
