<script>
export default {
  name: 'HumanizedDuration',
  props: {
    start: {
      type: [String, Object],
      validator(value) {
        if (typeof value === 'object') {
          return dayjs.isDayjs(value)
        }
        return true
      },
    },
    end: {
      type: [String, Object],
      validator(value) {
        if (typeof value === 'object') {
          return dayjs.isDayjs(value)
        }
        return true
      },
    },
    minutes: { type: Number, default: null },
  },
  computed: {
    humanizedDuration() {
      let days = 0
      let hours = 0
      let minutes =
        this.minutes || Math.round((dayjs(this.end).unix() - dayjs(this.start).unix()) / 60)
      let result = `${minutes}min`
      if (minutes > 60) {
        hours = Math.floor(minutes / 60)
        minutes = minutes - hours * 60
        result = `${hours}h`
        if (minutes > 0) result += `${minutes}m`
        if (hours > 24) {
          days = Math.floor(hours / 24)
          hours = hours - days * 24
          result = `${days}d`
          if (hours > 0) result += `${hours}h`
          if (minutes > 0) result += `${minutes}m`
        }
      }
      return result
    },
  },
}
</script>

<template lang="pug">
span.text-black-15(v-if="!start && !end && !minutes && minutes != 0") empty
span(v-else) {{ humanizedDuration }}
</template>
