import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {DriverSource} from 'frontend/driver-sources'
import {Cluster} from 'frontend/clusters'
import {DriverCategory} from 'frontend/driver-categories'
import FormattedTime from 'frontend/common/formatted-time.vue'

export const DriverRegistrationRequestDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'surname',
    'email',
    'personal_phone',
    'is_approved',
    { driver_source: ['id', 'name'] },
    { cluster: ['id', 'name'] },
    { driver_category: ['id', 'name'] },
    'driver_registration_qr_token_id',
    'updated_at',
    'created_at',
  ],
  subscriptions: {
    items: 'DriverRegistrationRequestsChannel',
  },
  defaultColumns: [
    'fullName',
    'email',
    'phone',
    'driverSource',
    'driverCategory',
    'cluster',
    'updatedDatetime',
    'createdDatetime',
  ],
  columnConfig: {
    fullName: {
      label: 'Full name',
      header: {
        sort: 'reverse_full_name',
      },
      cell: {
        kind: 'component',
        component: 'full-name',
        componentProps: driver => ({item: driver}),
      },
    },
    email: {
      label: 'E-mail',
      header: {
        sort: 'email',
      },
      cell: {
        kind: 'simple',
        path: 'email',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    surname: {
      label: 'Surname',
      header: {
        sort: 'surname',
      },
      cell: {
        kind: 'simple',
        path: 'surname',
      },
    },
    phone: {
      label: 'Phone',
      header: {
        sort: 'personalPhone',
      },
      cell: {
        kind: 'simple',
        path: 'personalPhone',
      },
    },
    isApproved: {
      label: 'Is Approved?',
      header: {
        sort: 'isApproved',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: item => ({item: item.isApproved}),
      },
    },
    driverSource: {
      label: 'Source',
      header: {
        sort: 'driverSourceName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverSource),
        componentProps: item => ({driverSource: item.driverSource}),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({cluster: item.cluster}),
      },
    },
    driverCategory: {
      label: 'Category',
      header: {
        sort: 'driverCategoryName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverCategory),
        componentProps: item => ({driverCategory: item.driverCategory}),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.updatedAt,
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.createdAt,
        }),
      },
    },
  },
}