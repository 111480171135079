<script>
export default {
  name: 'SSOSignInTrigger',
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$route.query.mobile
    },
    basePath() {
      return `${window.location.protocol}//${this.$mainDomain}`
    },
  },
}
</script>
<template lang="pug">
template(v-if="['ping_federate', 'azure'].includes(organization.ssoProvider)")
  form(:action="`${organization.ssoAuthorizationUrl}`" method="post")
    input(:value="organization.ssoClientId" name="client_id" type="hidden")
    input(
      :value="`${basePath}/session/sso/${isMobile ? 'mobile/' : ''}${organization.id}`"
      name="redirect_uri"
      type="hidden"
    )
    input(name="response_type" type="hidden" value="code")
    input(name="scope" type="hidden" value="openid profile phone email address")

    button.sso-session-trigger(v-bind="$attrs" type="submit")
      slot Sign in with {{ organization.name }} SSO
</template>
