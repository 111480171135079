<script lang="ts">
import CriteriaValueTypeContentFactory from 'frontend/common/data-tables/search-criteria/factories/CriteriaValueTypeContentFactory.vue'
import CriteriaValueTypeInputFactory from 'frontend/common/data-tables/search-criteria/factories/CriteriaValueTypeInputFactory.vue'
import CriteriaValueTypePreviewFactory from 'frontend/common/data-tables/search-criteria/factories/CriteriaValueTypePreviewFactory.vue'
import { getCriteriaValueTypeByConfig } from 'frontend/common/data-tables/search-criteria/functions/getCriteriaValueTypeByConfig'
import { predicateMappings } from 'frontend/common/search-criterion/_search-criterion-predicate-config.js'
import { useVariables } from 'frontend/variables/composables/useVariables'
import { camelCase, startCase } from 'lodash'
import { mapGetters } from 'vuex'
import { FilterXSS } from 'xss'

export default {
  name: 'SearchCriterionPreviewContainer',
  emits: ['remove-filter', 'update:modelValue'],
  props: {
    filterValue: {
      type: [Object, Array, String, Number, Boolean],
    },
    filterKey: {
      type: String,
      required: true,
    },
    searchCriteria: {
      type: Object,
      required: true,
    },
    attributeNameClass: {
      type: [String, Object, Array],
      default: '',
    },
    predicateClass: {
      type: [String, Object, Array],
      default: '',
    },
    valueClass: {
      type: [String, Object, Array],
      default: '',
    },
    modelValue: {
      type: [Object, Array, String, Number, Boolean],
    },
  },
  data() {
    return {
      fetchedHumanizedValues: null,
      sanitizer: new FilterXSS({
        whiteList: {
          i: ['class'],
          b: ['class'],
          span: ['class'],
          div: ['class'],
        },
      }),
    }
  },
  setup() {
    const { variableByIdMap } = useVariables()
    return {
      variableByIdMap,
    }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent']),
    startCasedFilterKey() {
      return startCase(this.filterKey)
    },
    // tooltipText() {
    //   let result = this.criteriaPath.map(el => el.label).join('<i class="bi-arrow-right"></i>')
    //   const humanizedValuesHtml = this.humanizedValues
    //     .map(el => {
    //       return el.isHtml ? this.sanitizer.process(el.label) : el.label
    //     })
    //     .join(', ')
    //   result += ` ${this.humanizedPredicate} ${humanizedValuesHtml}`
    //   return this.sanitizer.process(result)
    // },
    criteriaValueType() {
      return getCriteriaValueTypeByConfig(this.criterion, this.predicate)
    },

    criteriaPath() {
      return this.findCriteriaPath(this.searchCriteria)
    },
    criterion() {
      return this.criteriaPath[this.criteriaPath.length - 1]
    },
    predicate() {
      if (this.criterion) {
        return this.criterion.predicates.find(
          p => this.filterKey == this.buildFilterKey(this.criterion.key, p),
        )
      }
      return null
    },
    humanizedPredicate() {
      if (this.criterion) {
        const predicate = this.criterion.predicates.find(
          p => this.filterKey == this.buildFilterKey(this.criterion.key, p),
        )
        if (predicate) {
          return (
            predicateMappings[predicate]?.humanized || predicateMappings[predicate]?.name || '??'
          )
        }
      }
      return '??'
    },
    filterValueModel: {
      get() {
        return this.filterValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    buildFilterKey(key, predicate) {
      return camelCase(`${key}_${predicate}`)
    },
    findCriteriaPath(criteriaAtLevel) {
      const potentialKey = Object.keys(criteriaAtLevel).find(k => {
        return (
          this.filterKey.startsWith(k) &&
          !!criteriaAtLevel[k]?.predicates?.find(el => this.buildFilterKey(k, el) == this.filterKey)
        )
      })
      if (potentialKey) {
        return [{ ...criteriaAtLevel[potentialKey], key: potentialKey }]
      }

      let result = []
      Object.keys(criteriaAtLevel).some(k => {
        if (criteriaAtLevel[k].children) {
          const subResult = this.findCriteriaPath(criteriaAtLevel[k].children)
          if (subResult?.length) {
            result = [{ ...criteriaAtLevel[k], key: k }, ...subResult]
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      })

      return result
    },
  },
  components: {
    CriteriaValueTypePreviewFactory,
    CriteriaValueTypeInputFactory,
    CriteriaValueTypeContentFactory,
  },
}
</script>

<template lang="pug">
.used-filter.align-items-center.d-inline-flex.justify-content-start.ms-1
  .filter-content
    span(v-if="!criteriaPath?.length" :class="attributeNameClass") {{ startCasedFilterKey }}
    div
      span.criterion-label(v-for="(criterion, i) in criteriaPath" :class="attributeNameClass")
        //- i.bi-arrow-right(v-if="i > 0")
        | {{ i > 0 ? ' ' : '' }}
        | {{ criterion.label }}
    .value-wrapper(:class="predicateClass")
      .values(:class="valueClass")
        CriteriaValueTypePreviewFactory(
          :criteriaValueType="criteriaValueType"
          :criterion="criterion"
          :humanizedPredicate="humanizedPredicate"
          :predicate="predicate"
          v-model="filterValueModel"
        )
  .actions
    slot(name="actions")
      i.fa.fa-times.text-danger.ms-1(@click="$emit('remove-filter')")
</template>

<style scoped lang="scss">
.filter-content {
  display: flex;
  align-items: center;
}
</style>
