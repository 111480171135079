<script>
export default {
  name: 'FileUrl',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null,
    },
    url: {
      type: [String, File],
      required: true,
    },
  },
  data() {
    return {
      blocker: false,
    }
  },
  computed: {
    validUrl() {
      let result = this.url
      if (result?.constructor == String) {
        if (
          !this.url.startsWith('http') &&
          !this.url.startsWith('/api') &&
          !this.url.startsWith('//')
        ) {
          if (!this.url.startsWith('/')) {
            result = `/api/${result}`
          } else {
            result = `/api${result}`
          }
        }
      }
      return result
    },
  },
  methods: {
    downloadFile(file) {
      if (file.constructor !== File) {
        return
      }
      const reader = new FileReader()
      reader.onload = event => {
        const dataURL = event.target.result
        const link = document.createElement('a')
        link.download = file.name
        link.href = dataURL
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.blocker = false
      }
      reader.onerror = () => {
        this.blocker = false
      }
      reader.onabort = () => {
        this.blocker = false
      }
      this.blocker = true
      reader.readAsDataURL(file)
    },
  },
}
</script>

<template lang="pug">
a(
  v-if="url?.constructor == String"
  v-bind="$attrs"
  :href="validUrl"
  @click.stop
  name="downloadReport"
)
  slot {{ label }}
a(v-else v-bind="$attrs" @click="downloadFile(url)" name="downloadReport")
  ea-spinner(v-if="blocker" :spinner-size="20" match-parent without-timeout)
  slot {{ label }}
</template>
