<script>
export default {
  name: 'Email',
  inheritAttrs: false,
  props: { email: { type: String, default: null } },
}
</script>

<template lang="pug">
span.text-black-15(v-if="!email") empty
a(v-else v-bind="$attrs" :href="`mailto:${email}`") {{ email }}
</template>
