<script>
import CancelToken from 'axios/lib/cancel/CancelToken'
import consumer from 'channels/consumer'
import { useCancellable } from 'frontend/_hooks/use-cancellable'
import SystemNotificationModal from 'frontend/system-notifications/system-notification-modal.vue'
import { ref } from 'vue'

import SystemNotificationsDropdownList from './_system-notifications-dropdown-list.vue'

const VERBOSE_SUBSCRIPTION = false

export default {
  name: 'SystemNotificationsIndicator',
  data() {
    return {
      unreadNotificationsCounter: 0,
      subscription: null,
      refetchInProgress: false,
      nextRefetchPending: false,
      refetchWatchTimeout: null,
      shownNotification: null,
    }
  },
  setup() {
    const windowWidth = ref(window.innerWidth)
    return { ...useCancellable(), windowWidth }
  },
  computed: {
    currentEvent() {
      return this.$store.state.currentContext.event
    },
    currentCluster() {
      return this.$store.state.currentContext.cluster
    },
    currentStagingArea() {
      return this.$store.state.currentContext.stagingArea
    },
    currentChannel() {
      if (this.currentStagingArea) {
        return 'UserUnreadSystemNotificationsStagingAreaChannel'
      } else if (this.currentCluster) {
        return 'UserUnreadSystemNotificationsClusterChannel'
      } else if (this.currentEvent) {
        return 'UserUnreadSystemNotificationsChannel'
      } else {
        return null
      }
    },
    channelParams() {
      const result = {}
      if (this.currentEvent) {
        result.event_id = this.currentEvent.id
      }
      if (this.currentCluster) {
        result.cluster_id = this.currentCluster.id
      } else if (this.currentStagingArea) {
        result.staging_area_id = this.currentStagingArea.id
      }
      return result
    },
    isLargeDevice() {
      return this.windowWidth > 1366 // tablets
    },
  },
  watch: {
    currentChannel() {
      clearTimeout(this.refetchWatchTimeout)
      this.refetchWatchTimeout = setTimeout(() => {
        this.refetchCounterAndSubscribe()
      }, 150)
    },
    channelParams() {
      clearTimeout(this.refetchWatchTimeout)
      this.refetchWatchTimeout = setTimeout(() => {
        this.refetchCounterAndSubscribe()
      }, 150)
    },
  },
  mounted() {
    this.refetchCounterAndSubscribe()
    this.$eventHub.$on('windowResize', this.setWindowWidth)
  },
  unmounted() {
    this.unsubscribeIfCan()
    this.$eventHub.$off('windowResize', this.setWindowWidth)
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    readAll() {
      this.axios({
        method: 'post',
        url: `events/${this.currentEvent.slug}/system_notifications/read_all`,
        data: this.channelParams,
        cancelToken: new CancelToken(cancel => {
          this.cancellable['read-all'] = cancel
        }),
      })
        .then(() => {
          this.$success({ message: 'All notifications successfully marked as read' })
        })
        .catch(() => {
          this.$error({ message: 'Cannot mark all notifications as read' })
        })
    },
    refetchCounterAndSubscribe() {
      if (!this.currentEvent?.slug) {
        this.unsubscribeIfCan()
        return
      }
      if (this.refetchInProgress) {
        this.nextRefetchPending = true
        return
      }
      this.refetchInProgress = true
      this.nextRefetchPending = false
      this.unsubscribeIfCan()
      this.axios({
        method: 'post',
        url: `events/${this.currentEvent.slug}/system_notifications/unread_counter`,
        data: this.channelParams,
        cancelToken: new CancelToken(cancel => {
          this.cancellable['unread-notifications-counter'] = cancel
        }),
      })
        .then(response => {
          this.unreadNotificationsCounter = response.data.counter
          this.subscription = consumer.subscriptions.create(
            {
              channel: this.currentChannel,
              ...this.channelParams,
            },
            {
              customIdentifier: Math.round(Math.random() * 1000000),
              connected: () => {
                if (VERBOSE_SUBSCRIPTION)
                  console.log(`%c CONNECTED TO ${this.currentChannel}`, 'color: #4c74b9')
              },
              disconnected: () => {
                if (VERBOSE_SUBSCRIPTION) console.warn(`DISCONNECTED FROM ${this.currentChannel}`)
              },
              rejected: () => {
                console.error(`REJECTED CONNECTION TO ${this.currentChannel}`)
              },
              received: message => {
                if (VERBOSE_SUBSCRIPTION)
                  console.log(`%c ${this.currentChannel} message came in:`, 'color: gray', message)
                this.unreadNotificationsCounter = message
                this.$eventHub.$emit('refetch-notifications')
              },
            },
          )
        })
        .catch(this.handleCancel)
        .finally(() => {
          this.refetchInProgress = false
          if (this.nextRefetchPending) {
            this.refetchCounterAndSubscribe()
          }
        })
    },
    unsubscribeIfCan() {
      this.unreadNotificationsCounter = 0
      if (this.subscription) {
        const remoteSubscription = consumer.subscriptions.subscriptions.find(
          s => s.customIdentifier == this.subscription.customIdentifier,
        )
        if (remoteSubscription) {
          if (VERBOSE_SUBSCRIPTION)
            console.log(
              `%c unreadSystemNotificationsCounter will unsubscribe from its channel`,
              'color: gray',
            )
          consumer.subscriptions.remove(remoteSubscription)
        }
        this.subscription = null
      }
    },
  },
  components: {
    SystemNotificationsDropdownList,
    SystemNotificationModal,
  },
}
</script>
<template lang="pug">
dropdown.system-notifications-indicator(:alignRight="isLargeDevice" scrollable)
  .header-indicator.me-3
    //- ea-spinner(v-if="refetchInProgress" :spinnerSize="0" matchParent)
    i(
      :class="{ 'bi-bell-fill text-primary ring': unreadNotificationsCounter, 'bi-bell-slash text-black-50': !unreadNotificationsCounter }"
      name="bellNotificator"
    )
    .addon.bg-warning.text-white(v-if="!!unreadNotificationsCounter") {{ unreadNotificationsCounter }}
  template(#items="{ isOpened }")
    li.d-flex.align-items-center.justify-content-end.pt-0.pb-0(v-if="!!unreadNotificationsCounter")
      a.d-inline-flex.small.w-auto.pe-2(@click="readAll($event)") Mark all as read
    system-notifications-dropdown-list(v-if="isOpened" @openModal="shownNotification = $event")

template(v-if="shownNotification")
  system-notification-modal(:item="shownNotification" @closed="shownNotification = null")
</template>
