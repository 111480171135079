<script>
import FifaGisMarker from './fifa-gis-marker.vue'
import FifaGisPolygon from './fifa-gis-polygon.vue'

export default {
  name: 'FifaGisItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    FifaGisMarker,
    FifaGisPolygon,
  },
}
</script>

<template lang="pug">
template(v-if="item?.type === 'Point'")
  fifa-gis-marker(:marker="item")
template(v-else-if="item?.type === 'Polygon'")
  fifa-gis-polygon(:polygon="item")
</template>
