<script>
import { inject, ref } from 'vue'

export default {
  name: 'DataTableNothingFound',
  setup() {
    const nothingFoundCellRef = ref(null)
    const tableHeight = inject('tableHeight')
    const isModalFullScreen = inject('isModalFullScreen', false)
    const inModal = inject('inModal', false)
    const searchPerformed = inject('searchPerformed', false)
    return {
      tableHeight,
      isModalFullScreen,
      nothingFoundCellRef,
      inModal,
      searchPerformed,
    }
  },
  computed: {
    teleportTo() {
      return this.nothingFoundCellRef?.offsetParent?.parentElement
    },
    calculatedItemHeight() {
      if (!!this.tableHeight && !!this.nothingFoundCellRef) {
        const noScrollFix = '1px'
        const hScrollHeight = '0px'
        // '7px'
        const cellOffset = `${this.nothingFoundCellRef.offsetTop}px`
        return `calc(${this.tableHeight}px - (${cellOffset} + ${noScrollFix} + ${hScrollHeight}))`
      } else {
        return null
      }
    },
  },
}
</script>

<template lang="pug">
tr.disable-sticky-actions
  td.nothing-found-cell(
    ref="nothingFoundCellRef"
    :style="{ height: inModal ? 0 : null }"
    colspan="2000"
  )
    teleport(:disabled="!teleportTo" :to="teleportTo")
      .nothing-found-container(:style="{ height: calculatedItemHeight }")
        svg.shape.bigger(preserveAspectRatio="none" viewBox="0 0 1122 148")
          path.shape-path(
            d="M1121 147.001H1C1 147.001 265 1 431 1C597 1 791 95 931 95C1071 95 1121 1 1121 1V147.001Z"
          )
        svg.shape.smaller(preserveAspectRatio="none" viewBox="0 0 1122 88")
          path.shape-path(
            d="M1121 87.001H1C1 87.001 265 1 431 1C597 1 791 95 931 95C1071 95 1121 1 1121 1V87.001Z"
          )
        .nothing-found
          .nothing-found-centerpiece
            .nothing-found-icons
              i.bi.bi-file-earmark-text
              i.bi.bi-search
            .text-left
              .bold No records found
              .bold.d-block.text-custom(v-if="searchPerformed") Try to change your search criteria.
              .bold.d-block.text-black-50(v-else) or they are out of your access scope
</template>
