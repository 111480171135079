<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NotSentCcmIndicator',
  data() {
    return {
      time: null,
      interval: null,
    }
  },
  computed: {
    ...mapGetters('notSentCCMCounter', ['counter']),
    ...mapGetters('currentContext', ['currentEvent']),
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  created() {
    this.$store.dispatch('notSentCCMCounter/fetch', { slug: this.currentEvent?.slug })
    this.time = new Date()
    this.interval = setInterval(() => {
      this.time = new Date()
    }, 1000)
  },
}
</script>
<template lang="pug">
router-link.me-sm-3.me-2.header-indicator(
  v-if="counter || counter === 0"
  to="client-communication-messages#not_sent"
  v-tooltip.options="{ title: counter == 0 ? 'Great, there are no failed messages' : 'There are messages that were attempted to be sent but failed', placement: 'bottom' }"
)
  template(v-if="counter")
    i.bi-envelope-fill.text-primary
    .addon.bg-warning.text-white {{ counter }}
  i.bi-envelope.text-black-50(v-else="counter")
</template>
