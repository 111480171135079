import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {Location} from 'frontend/locations'
import {Cluster} from 'frontend/clusters'

export const StagingAreaDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'has_minimum_vehicles_allotment',
    'has_subfleet',
    'has_welcome_desk',
    'updated_at',
    'created_at',
    {
      location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
    },
  ],
  subscriptions: {
    items: 'StagingAreasChannel',
    locations: 'LocationsSimpleChannel',
    clusters: 'ClustersSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'location',
    'hasMinimumVehiclesAllotment',
    'hasSubfleet',
    'hasWelcomeDesk',
    'updatedAt',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    location: {
      label: 'Location',
      header: {
        sort: 'location_lower_name',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: sa => ({
          location: sa.location,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'locationClusterName',
      },
      cell: {
        kind: 'component',
        component: Cluster,
        componentProps: sa => ({ cluster: sa.location.cluster }),
      },
    },
    hasMinimumVehiclesAllotment: {
      label: 'MVA',
      header: {
        sort: 'hasMinimumVehiclesAllotment',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: sa => ({
          item: sa?.hasMinimumVehiclesAllotment,
        }),
      },
    },
    hasSubfleet: {
      label: 'Subfleet',
      header: {
        sort: 'hasSubfleet',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: sa => ({
          item: sa?.hasSubfleet,
        }),
      },
    },
    hasWelcomeDesk: {
      label: 'Welcome desk',
      header: {
        sort: 'hasWelcomeDesk',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: sa => ({
          item: sa?.hasWelcomeDesk,
        }),
      },
    },
    createdAt: {
      label: 'Created datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt }),
      },
    },
    createdAtTime: {
      label: 'Created time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    createdAtDate: {
      label: 'Created date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },
    updatedAtTime: {
      label: 'Updated time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAtDate: {
      label: 'Updated date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
  }
}