<script>
import { Popover } from 'bootstrap'

export default {
  name: 'EaPopover',
  emits: ['shown', 'hidden'],
  props: {
    trigger: {
      type: String,
    },
    doNotRemoveContent: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'auto',
    },
    customClass: {
      type: String,
      default: '',
    },
    triggerClass: {
      type: String,
      default: 'd-inline-block',
    },
  },
  data() {
    return {
      popover: null,
      renderContentForPopover: false,
      cursorOnPopover: false,
      timeoutToClear: null,
    }
  },
  computed: {
    events() {
      if (this.trigger == 'hover') {
        return {
          onMouseenter: this.showPopover,
          onMouseleave: this.disposePopover,
        }
      } else if (this.trigger == 'hoverWithPopover') {
        return {
          onMouseenter: this.showPopover,
          onMouseleave: this.disposePopoverIfCursorOutOfIt,
        }
      } else {
        return {
          onMousedown: this.showPopover,
        }
      }
    },
    innerPopoverEvents() {
      if (this.trigger == 'hoverWithPopover') {
        return {
          onMouseenter: this.cursorIsOnPopover,
          onMouseleave: this.cursorOutOfPopover,
        }
      } else {
        return {}
      }
    },
  },
  mounted() {
    this.$eventHub.$on('mousedown', this.disposePopover)
  },
  unmounted() {
    this.disposePopover()
    this.$eventHub.$off('mousedown', this.disposePopover)
  },
  methods: {
    cursorIsOnPopover() {
      this.cursorOnPopover = true
    },
    cursorOutOfPopover() {
      if (!document.activeElement?.closest('.popoverContent')) {
        this.cursorOnPopover = false
        this.disposePopover()
      }
    },
    showPopover(event) {
      clearTimeout(this.timeoutToClear)
      event.stopPropagation()
      event.preventDefault()
      if (this.popover) return

      this.renderContentForPopover = true
      this.$nextTick(() => {
        if (this.$refs.popoverContent) {
          this.popover = new Popover(this.$refs.popoverTrigger, {
            html: true,
            container: 'body',
            placement: this.placement,
            customClass: this.customClass,
            trigger: 'manual',
            sanitize: false,
            // title: 'Transfer group details',
            content: this.$refs.popoverContent,
          })
          this.popover.show()
          this.$emit('shown', event)
        }
      })
    },
    disposePopover(event) {
      clearTimeout(this.timeoutToClear)
      if (this.popover?.dispose) {
        this.$emit('hidden', event)
        if (
          this.trigger != 'hover' &&
          event?.target?.closest &&
          (event.target.closest('.popoverContent') || event.target.closest('.flatpickr-calendar'))
        ) {
          return
        }
        this.popover.dispose()
        this.popover = null
        if (!this.doNotRemoveContent) {
          this.renderContentForPopover = false
        }
      }
    },
    disposePopoverIfCursorOutOfIt(event) {
      this.timeoutToClear = setTimeout(() => {
        if (!this.cursorOnPopover) this.disposePopover(event)
      }, 200)
    },
  },
}
</script>

<template lang="pug">
div(ref="popoverTrigger" v-bind="events" :class="triggerClass")
  slot(name="default")
    div Details
.d-none
  .popoverContent(ref="popoverContent" v-bind="innerPopoverEvents")
    template(v-if="renderContentForPopover")
      slot(:disposePopover="disposePopover" name="content")
        div Please provide content
</template>
