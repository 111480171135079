import axios from 'frontend/_config/axios'
import { ResourceService } from 'frontend/common/services/resource-service'

export class OrganizationsService extends ResourceService {
  constructor({
    baseUrl = `/organizations`,
    blocker,
    fetchError = 'Failed to fetch Organizations.',
    saveError = 'Failed to save Organization.',
    apiProvider = axios,
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
  }

  saveGlobalColumnConfig(id, data, errorHandler = e => this._onSaveError(e)) {
    return this._makeCall('put', errorHandler, {
      url: `${this.baseUrl}/${id}/global_column_config`,
      data: { organization: data },
    })
  }

  fetchCustomBackgroundImage(id, data, errorHandler = () => () => null) {
    return this._makeCall('get', errorHandler, {
      url: `${this.baseUrl}/${id}/custom_background_image`,
    })
  }

  fetchCustomBackgroundImageByDomain(errorHandler = () => () => null) {
    return this._makeCall('get', errorHandler, {
      url: `${this.baseUrl}/custom_background_image_by_domain`,
    })
  }
}
