<script>
export default {
  name: 'DriverSourceShiftCheckInOut',
  props: {
    attr: { type: String, required: true },
    driverSource: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
div(v-if="driverSource[attr] || driverSource[attr] == 0") {{ driverSource[attr] }}min
span.text-black-15.text-nowrap(v-else) default
</template>
