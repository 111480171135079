import { Cluster } from 'frontend/clusters'
import EllipsisWithTooltip from 'frontend/common/ellipsis-with-tooltip.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { DriverCategory } from 'frontend/driver-categories'
import { DriverSource } from 'frontend/driver-sources'
import InclusiveShiftCheckInOut from 'frontend/drivers/driver-inclusive-shift-check-in-out.vue'
import DriverLanguages from 'frontend/drivers/driver-languages.vue'
import DriverPhones from 'frontend/drivers/driver-phones.vue'
import ShiftCheckInOut from 'frontend/drivers/driver-shift-check-in-out.vue'
import { VehicleType } from 'frontend/vehicle-types'
import { markRaw } from 'vue'

export const DriverDataModelTypeConfig: IDataModelTypeConfig = {
  exportableType: 'Driver',
  fields: [
    'id',
    'eid',
    'name',
    'surname',
    'is_draft',
    'default_registration_plate',
    'email',
    'languages',
    'assigned_phone',
    'personal_phone',
    'primary_phone',
    'updated_at',
    'created_at',
    'computed_shift_check_in_time',
    'computed_shift_check_out_time',
    'computed_inclusive_shift_check_in_out',
    'shift_check_in_time',
    'shift_check_out_time',
    'inclusive_shift_check_in_out',
    'qr_content',
    'accreditation_registration_id',
    'session_prevented',
    { cluster: ['id', 'name', 'time_zone'] },
    { driver_source: ['id', 'name'] },
    {
      driver_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
    },
    { vehicle_types: ['id', 'name'] },
  ],
  subscriptions: {
    items: 'DriversChannel',
    clusters: 'ClustersSimpleChannel',
    driverSources: 'DriverSourcesSimpleChannel',
    driverCategories: 'DriverCategoriesSimpleChannel',
    vehicleTypes: 'VehicleTypesSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'fullName',
    'email',
    'phones',
    'cluster',
    'driverSource',
    'driverCategory',
    'defaultRegistrationPlate',
    'languages',
    'vehicleTypes',
    'accreditationRegistrationId',
    'qrContent',
    'sessionPrevented',
    'updatedAt',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    fullName: {
      label: 'Full name',
      header: {
        sort: 'reverse_full_name',
      },
      cell: {
        kind: 'component',
        component: 'full-name',
        componentProps: driver => ({ item: driver }),
      },
    },
    email: {
      label: 'E-mail',
      header: {
        sort: 'email',
      },
      cell: {
        kind: 'simple',
        path: 'email',
      },
    },
    sessionPrevented: {
      label: 'App login prevented',
      header: {
        sort: 'sessionPrevented',
      },
      cell: {
        kind: 'component',
        component: 'TrueFalseLabel',
        componentProps: item => ({
          item: item.sessionPrevented,
          revertColors: true,
        }),
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    surname: {
      label: 'Surname',
      header: {
        sort: 'surname',
      },
      cell: {
        kind: 'simple',
        path: 'surname',
      },
    },
    accreditationRegistrationId: {
      label: 'Acc Reg ID',
      header: {
        sort: 'accreditationRegistrationId',
      },
      cell: {
        kind: 'simple',
        path: 'accreditationRegistrationId',
      },
    },
    qrContent: {
      label: 'QR content',
      header: {
        sort: 'qrContent',
      },
      cell: {
        kind: 'component',
        component: markRaw(EllipsisWithTooltip),
        componentProps: driver => ({ text: driver.qrContent, limit: 32 }),
      },
    },
    phones: {
      label: 'Phone(s)',
      header: {
        sort: 'assigned_phone_or_personal_phone',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverPhones),
        componentProps: driver => ({ driver: driver }),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: driver => ({ cluster: driver.cluster }),
      },
    },
    driverSource: {
      label: 'Source',
      header: {
        sort: 'driverSourceName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverSource),
        componentProps: driver => ({ driverSource: driver.driverSource }),
      },
    },
    driverCategory: {
      label: 'Category',
      header: {
        sort: 'driverCategoryName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverCategory),
        componentProps: driver => ({ driverCategory: driver.driverCategory }),
      },
    },
    defaultRegistrationPlate: {
      label: 'Default Reg. Plate',
      header: {
        sort: 'defaultRegistrationPlateNormalized',
      },
      cell: {
        kind: 'simple',
        path: 'defaultRegistrationPlate',
      },
    },
    languages: {
      label: 'Languages',
      cell: {
        kind: 'component',
        component: markRaw(DriverLanguages),
        componentProps: driver => ({ driver: driver }),
      },
    },
    vehicleTypes: {
      label: 'Vehicle Types',
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: driver => ({
          items: driver.vehicleTypes,
          limit: 3,
          component: markRaw(VehicleType),
          componentProps: vt => ({ vehicleType: vt }),
        }),
      },
    },
    computedShiftCheckInTime: {
      label: 'Shift check-in time',
      header: {
        sort: 'computedShiftCheckInTime',
      },
      cell: {
        kind: 'component',
        component: markRaw(ShiftCheckInOut),
        componentProps: driver => ({ driver, attr: 'shiftCheckInTime' }),
      },
    },
    computedShiftCheckOutTime: {
      label: 'Shift check-out time',
      header: {
        sort: 'computedShiftCheckOutTime',
      },
      cell: {
        kind: 'component',
        component: markRaw(ShiftCheckInOut),
        componentProps: driver => ({ driver, attr: 'shiftCheckOutTime' }),
      },
    },
    computedInclusiveShiftCheckInOut: {
      label: 'Shift CI/CO included',
      tooltip: 'Shift check-in/check-out times are included in the shift length',
      header: {
        sort: 'computedInclusiveShiftCheckInOut',
      },
      cell: {
        kind: 'component',
        component: markRaw(InclusiveShiftCheckInOut),
        componentProps: driver => ({ driver }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: driver => ({
          t: driver.createdAt,
        }),
      },
    },

    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: driver => ({
          t: driver.createdAt,
          format: 'dateOnly',
        }),
      },
    },

    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: driver => ({
          t: driver.createdAt,
          format: 'timeOnly',
        }),
      },
    },

    updatedAt: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: driver => ({
          t: driver.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: driver => ({
          t: driver.updatedAt,
          format: 'dateOnly',
        }),
      },
    },

    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: driver => ({
          t: driver.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
