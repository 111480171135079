export function convertStoredWidthsToColumnWidthMap(storedColumnWidths: string): Map<string, number> {
    const parsedStoredColumnWidths = JSON.parse(storedColumnWidths)
    const newColumnWidthsMap = new Map()
    try {
        const columnWidthsObject = parsedStoredColumnWidths || {}
        for (const key of Object.keys(columnWidthsObject)) {
            newColumnWidthsMap.set(key, columnWidthsObject[key])
        }
    } catch(e) {
        // Do nothing
    }
    return newColumnWidthsMap
}
