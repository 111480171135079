import store from 'frontend/_stores'
import { each, keys } from 'lodash'

import validationMessages from './validation-messages'
import {
  equals,
  helpers,
  inArray,
  integer,
  maxArrayLength,
  maxLength,
  maxValue,
  maxValueExclusive,
  minArrayLength,
  minLength,
  minValue,
  minValueExclusive,
  not,
  notInArray,
  required,
  sameArrayLength,
  sameLength,
  timeBetween,
} from './validators/index.js'

const dayjsValidator = (type, messageParamName, format, resolver) => {
  return validationCriteria => {
    const messageParamValue = dayjs(validationCriteria?.t)
      .tz(validationCriteria?.tz || store.state.session.timeZone)
      .format(format)
    // if (
    //   validationCriteria?.tz?.length &&
    //   store.state.session.timeZone != validationCriteria?.tz &&
    //   dayjs().tz(validationCriteria.tz).utcOffset() !=
    //     dayjs().tz(store.state.session.timeZone).utcOffset()
    // ) {
    //   messageParamValue += ` (${validationCriteria.tz} time zone)`
    // }
    return helpers.withMessage(
      validationMessages[type],
      helpers.withParams(
        {
          type: type,
          [messageParamName]: messageParamValue,
        },
        provided => resolver(validationCriteria?.t, provided),
      ),
    )
  }
}

const railsValidatorsMap = {
  presence: helpers.withMessage(validationMessages.required, required),
  absence: helpers.withMessage(validationMessages.notRequired, not(required)),
  length: {
    minimum: val => helpers.withMessage(validationMessages.minLength, minLength(val)),
    maximum: val => helpers.withMessage(validationMessages.maxLength, maxLength(val)),
    is: val => helpers.withMessage(validationMessages.sameLength, sameLength(val)),
  },
  arrayLength: {
    minimum: val => helpers.withMessage(validationMessages.minArrayLength, minArrayLength(val)),
    maximum: val => helpers.withMessage(validationMessages.maxArrayLength, maxArrayLength(val)),
    is: val => helpers.withMessage(validationMessages.sameArrayLength, sameArrayLength(val)),
  },
  numericality: {
    greaterThan: val =>
      helpers.withMessage(validationMessages.minValueExclusive, minValueExclusive(val)),
    greaterThanOrEqualTo: val => helpers.withMessage(validationMessages.minValue, minValue(val)),
    lessThan: val =>
      helpers.withMessage(validationMessages.maxValueExclusive, maxValueExclusive(val)),
    lessThanOrEqualTo: val => helpers.withMessage(validationMessages.maxValue, maxValue(val)),
    otherThan: other =>
      helpers.withMessage(
        validationMessages.otherThan,
        helpers.withParams({ type: 'notEqual', other }, not(equals(other))),
      ),
    equalTo: val => helpers.withMessage(validationMessages.equals, equals(val)),
    onlyInteger: () => helpers.withMessage(validationMessages.integer, integer),
  },
  date: {
    greaterThan: dayjsValidator(
      'minValueExclusive',
      'min',
      'DD/MM/YYYY',
      (validated, provided) => !validated || dayjs(provided).isAfter(dayjs(validated), 'day'),
    ),
    greaterThanOrEqualTo: dayjsValidator(
      'minValue',
      'min',
      'DD/MM/YYYY',
      (validated, provided) => !validated || dayjs(provided).isSameOrAfter(dayjs(validated), 'day'),
    ),
    lessThan: dayjsValidator(
      'maxValueExclusive',
      'max',
      'DD/MM/YYYY',
      (validated, provided) => !validated || dayjs(provided).isBefore(dayjs(validated), 'day'),
    ),
    lessThanOrEqualTo: dayjsValidator(
      'maxValue',
      'max',
      'DD/MM/YYYY',
      (validated, provided) =>
        !validated || dayjs(provided).isSameOrBefore(dayjs(validated), 'day'),
    ),
  },
  datetime: {
    greaterThan: dayjsValidator(
      'minValueExclusive',
      'min',
      'DD/MM/YYYY HH:mm',
      (validated, provided) => !validated || dayjs(provided).isAfter(dayjs(validated), 'minute'),
    ),
    greaterThanOrEqualTo: dayjsValidator(
      'minValue',
      'min',
      'DD/MM/YYYY HH:mm',
      (validated, provided) =>
        !validated || dayjs(provided).isSameOrAfter(dayjs(validated), 'minute'),
    ),
    lessThan: dayjsValidator(
      'maxValueExclusive',
      'max',
      'DD/MM/YYYY HH:mm',
      (validated, provided) => {
        return !validated || dayjs(provided).isBefore(dayjs(validated), 'minute')
      },
    ),
    lessThanOrEqualTo: dayjsValidator(
      'maxValue',
      'max',
      'DD/MM/YYYY HH:mm',
      (validated, provided) =>
        !validated || dayjs(provided).isSameOrBefore(dayjs(validated), 'minute'),
    ),
  },
  format: {
    with: (expression, message) => {
      return helpers.withMessage(
        message || validationMessages.format,
        helpers.regex(new RegExp(expression)),
      )
    },
  },
  //TODO: not all cases works, only for arrays, edge-case: ruby's 1..20 syntax is being sent as string :bummer:
  inclusion: {
    in: val => helpers.withMessage(validationMessages.inArray, inArray(val)),
    within: val => helpers.withMessage(validationMessages.inArray, inArray(val)),
  },
  exclusion: {
    in: val => helpers.withMessage(validationMessages.notInArray, notInArray(val)),
    within: val => helpers.withMessage(validationMessages.notInArray, notInArray(val)),
  },
  acceptance: {
    accept: val => helpers.withMessage(validationMessages.inArray, inArray(val)),
  },
  uniqueness: {},

  time: {
    between: params => {
      return helpers.withMessage(
        validationMessages.timeBetween,
        helpers.withParams(
          { type: 'timeBetween', provided: params },
          timeBetween(params.start, params.end),
        ),
      )
    },
  },
  other: {
    than: params => {
      return helpers.withMessage(
        validationMessages.otherThan,
        helpers.withParams({ type: 'notEqual', provided: params }, not(equals(params.value))),
      )
    },
  },
}

const railsAttributeKeys = {
  presence: 'required',
  absence: 'absence',
  length: {
    minimum: 'minLength',
    maximum: 'maxLength',
    is: 'lengthIs',
  },
  arrayLength: {
    minimum: 'minArrayLength',
    maximum: 'maxArrayLength',
    is: 'arrayLengthIs',
  },
  date: {
    greaterThan: 'greaterThan',
    greaterThanOrEqualTo: 'greaterThanOrEqualTo',
    lessThan: 'lessThan',
    lessThanOrEqualTo: 'lessThanOrEqualTo',
  },
  datetime: {
    greaterThan: 'greaterThan',
    greaterThanOrEqualTo: 'greaterThanOrEqualTo',
    lessThan: 'lessThan',
    lessThanOrEqualTo: 'lessThanOrEqualTo',
  },
  numericality: {
    greaterThan: 'minValueExclusive',
    greaterThanOrEqualTo: 'minValueInclusive',
    lessThan: 'maxValueExclusive',
    lessThanOrEqualTo: 'maxValueInclusive',
    otherThan: 'notEqual',
    equalTo: 'equalTo',
    onlyInteger: 'integer',
  },
  format: {
    with: 'format',
  },
  inclusion: {
    in: 'inclusion.in',
    within: 'inclusion.in',
  },
  exclusion: {
    in: 'exclusion.in',
    within: 'exclusion.in',
  },
  confirmation: {
    confirmationField: 'sameAs',
  },
  acceptance: {
    accept: 'accept',
  },
  uniqueness: {},
  // custom!
  other: {
    than: 'other',
  },
  time: {
    between: 'time',
  },
}

export const convertValidatorsToVuelidate = validators => {
  if (!validators) {
    return {}
  } else {
    const result = {}
    each(keys(validators), key => {
      if (railsAttributeKeys[key]) {
        if (railsAttributeKeys[key].constructor == String) {
          result[railsAttributeKeys[key]] = railsValidatorsMap[key]
        } else if (validators[key].constructor == Object) {
          each(keys(validators[key]), subKey => {
            if (railsAttributeKeys[key]?.[subKey]) {
              result[railsAttributeKeys[key][subKey]] = railsValidatorsMap[key][subKey](
                validators[key][subKey],
              )
            }
          })
        }
        // } else {
        //   if (Object.prototype.hasOwnProperty.call(validators[key], '$validator')) {
        //     result[key] = validators[key]
        //     return
        //   }
      }
    })
    return result
  }
}
