<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

import Spotlight from './spotlight.vue'

export default {
  name: 'LayoutEvent',
  computed: {
    ...mapGetters('session', ['currentUser']),
  },
  components: {
    Chat: defineAsyncComponent(() => import('frontend/chat/chat.vue')),
    Spotlight,
  },
}
</script>

<template lang="pug">
router-view

#chat-anchor(v-if="currentUser")
  chat
spotlight(v-if="currentUser")
</template>

<style scoped lang="scss">
@import 'frontend_stylesheets/_variables.scss';
#chat-anchor {
  position: fixed;
  z-index: 20;
  pointer-events: none;
  height: calc(100% - #{$footer-height});
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  @include for-size(small-and-medium-devices) {
    height: calc(100% - env(safe-area-inset-bottom, 0px) - #{$footer-height});
  }
}
</style>
