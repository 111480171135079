<script>
import ErrorsControl from 'frontend/common/ea-form-controls/_errors-control.vue'
import { inject, onMounted } from 'vue'

import { DurationRulesService } from '../duration-rules-service'
import { useDurationRulePreview } from './use-duration-rule-preview'

export default {
  name: 'TomTomDurationPreview',
  props: {
    durationRule: { type: Object, required: true },
    departureAt: { type: Object },
    arrivalAt: { type: Object },
    providedStamp: String,
  },
  setup(props) {
    const blocker = inject('blocker')
    const durationRulesService = new DurationRulesService({ blocker })

    const { arrivalAtCeiled, duration, fetchPreview, route, routeMetadata, serverErrors } =
      useDurationRulePreview(props.durationRule, durationRulesService)

    onMounted(() => {
      fetchPreview(props.durationRule)
    })

    return {
      arrivalAtCeiled,
      duration,
      route,
      routeMetadata,
      serverErrors,
    }
  },
  components: {
    ErrorsControl,
  },
}
</script>

<template lang="pug">
.tom-tom-duration-preview
  .row(v-if="routeMetadata")
    .col-auto
      span.text-nowrap Pick-up:
        .bold
          formatted-time(:t="routeMetadata.departureAt" :tz="routeMetadata.departureTimeZone")
    .col-auto
      span.text-nowrap Drop-off:
        .bold
          formatted-time(
            :t="providedStamp == 'departureAt' ? arrivalAtCeiled : routeMetadata.arrivalAt"
            :tz="routeMetadata.arrivalTimeZone"
          )
    .col-auto
      span.text-nowrap Time:
        .bold.text-nowrap {{ duration }}
  .mt-2
    errors-control(v-for="error in Object.values(serverErrors || {})" :additionalErrors="error")
</template>
