<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'Cluster',
  props: {
    cluster: { type: Object },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
template(v-if="cluster")
  modal-button(:item="cluster" :modal="previewModals.Cluster")
    a {{ cluster.name }}
span.text-black-15(v-else) empty
</template>
