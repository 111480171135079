import { defineAsyncComponent } from 'vue'

const Vehicle = defineAsyncComponent(() => import('./vehicle.vue'))
const VehicleFormModal = defineAsyncComponent(() => import('./vehicle-form-modal.vue'))
const VehicleDetails = defineAsyncComponent(() => import('./vehicle-details.vue'))
const VehiclesIndex = defineAsyncComponent(() => import('./vehicles-index.vue'))
const VehiclesSelect = defineAsyncComponent(() => import('./vehicles-select.vue'))
const VehiclesTable = defineAsyncComponent(() => import('./vehicles-table.vue'))
const SuggestedDrivers = defineAsyncComponent(() => import('./suggested-drivers.vue'))

export {
  SuggestedDrivers,
  Vehicle,
  VehicleDetails,
  VehicleFormModal,
  VehiclesIndex,
  VehiclesSelect,
  VehiclesTable,
}
