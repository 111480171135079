export const InterfaceType = {
  constants: {
    ApiProvider: Symbol.for('ApiProvider'),
    EventSlug: Symbol.for('EventSlug'),
    CurrentRoute: Symbol.for('CurrentRoute'),
  },
  services: {
    DataModelTypeService: Symbol.for('DataModelTypeService'),
  },
  strategies: {
    DataModelTypeStrategy: Symbol.for('DataModelTypeStrategy'),
    DataModelTypeConfigStrategy: Symbol.for('DataModelTypeConfigStrategy'),
  },
  factories: {
    DataModelTypeStrategyFactory: Symbol.for('DataModelTypeStrategyFactory'),
    DataModelTypeConfigStrategyFactory: Symbol.for('DataModelTypeConfigStrategyFactory'),
  },
}
