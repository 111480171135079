export const TIME_VARIABLES = {
  beginning_of_today: 'Beginning of today',
  beginning_of_today_plus_one: 'Beginning of today + 1d',
  beginning_of_today_plus_two: 'Beginning of today + 2d',
  beginning_of_today_plus_three: 'Beginning of today + 3d',
  beginning_of_today_plus_four: 'Beginning of today + 4d',
  beginning_of_yesterday: 'Beginning of yesterday',
  beginning_of_week: 'Beginning of current week (monday)',
  beginning_of_month: 'Beginning of current month',
  beginning_of_year: 'Beginning of current year',
  end_of_today: 'End of today',
  end_of_yesterday: 'End of yesterday',
  end_of_week: 'End of current week (monday)',
  end_of_month: 'End of current month',
  end_of_year: 'End of current year',
}

export const predicateMappings = {
  start: {
    suffix: 'Start',
    name: 'starts with',
  },
  end: {
    suffix: 'End',
    name: 'ends with',
  },
  cont: {
    suffix: 'Cont',
    name: 'contains',
  },
  cont_any: {
    suffix: 'ContAny',
    name: 'contains any',
  },
  ccont: {
    suffix: 'Ccont',
    name: 'contains',
  },
  not_null: {
    suffix: 'NotNull',
    name: 'is present',
    humanized: 'is present',
  },
  present: {
    suffix: 'Present',
    name: 'is present',
    humanized: 'is present',
  },
  blank: {
    suffix: 'Blank',
    name: 'is empty',
    humanized: 'is empty',
  },
  // for scopes
  exists: {
    suffix: 'Exists',
    name: 'is present',
    humanized: 'is present',
  },
  null: {
    suffix: 'Null',
    name: 'is empty',
    humanized: 'empty',
  },
  eq: {
    suffix: 'Eq',
    name: 'equals',
    humanized: '=',
  },
  in: {
    suffix: 'In',
    name: 'is one of',
    humanized: 'is one of',
  },
  not_in: {
    suffix: 'NotIn',
    name: 'is not one of',
    humanized: 'is not one of',
  },
  overlap_array: {
    suffix: 'OverlapArray',
    name: 'is one of',
    humanized: 'is one of',
  },
  not_overlap_array: {
    suffix: 'NotOverlapArray',
    name: 'is not one of',
    humanized: 'is not one of',
  },
  gteq: {
    suffix: 'Gteq',
    name: 'greater than or equal to',
    humanized: '&ge;',
  },
  gt: {
    suffix: 'Gt',
    name: 'greater than',
    humanized: '>',
  },
  lteq: {
    suffix: 'Lteq',
    name: 'less than or equal to',
    humanized: '&le;',
  },
  lt: {
    suffix: 'Lt',
    name: 'less than',
    humanized: '<',
  },
}
