import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {ClientCategory} from 'frontend/client-categories'
import {ClientFunction} from 'frontend/client-functions'
import {ClientFunctionRemark} from 'frontend/client-function-remarks'
import {Asl} from 'frontend/asls'
import {Tsl} from 'frontend/tsls'
import {Client, ClientCommunicationBlacklist} from 'frontend/clients'
import {ClientGroup} from 'frontend/client-groups'

export const ClientDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'surname',
    'email',
    'phone',
    'remote_id',
    'email_communication_blacklist',
    'sms_communication_blacklist',
    'all_companions',
    'all_non_companions',
    'updated_at',
    'created_at',
    'archived_at',
    'personal_assistant_email',
    'event_phone',
    { tsl: ['id', 'name'] },
    { asl: ['id', 'name'] },
    { client_function_remark: ['id', 'name'] },
    { client_categories: ['id', 'name', 'group_path'] },
    { client_functions: ['id', 'name'] },
    { client_groups: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
  ],
  subscriptions: {
    items: 'ClientsChannel',
    tsls: 'TSLsSimpleChannel',
    asls: 'ASLsSimpleChannel',
    clientFunctionRemarks: 'ClientFunctionRemarksSimpleChannel',
    clientCategories: 'ClientCategoriesSimpleChannel',
    clientFunctions: 'ClientFunctionsSimpleChannel',
    clientGroups: 'ClientGroupsSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'fullName',
    'email',
    'phone',
    'clientCategories',
    'clientFunctions',
    'clientFunctionRemark',
    'asl',
    'tsl',
    'remoteId',
    'emailCommunicationBlacklist',
    'smsCommunicationBlacklist',
    'companions',
    'nonCompanions',
    'clientGroups',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    fullName: {
      label: 'Full name',
      header: {
        sort: 'full_name',
      },
      cell: {
        kind: 'component',
        component: 'full-name',
        componentProps: client => ({ item: client }),
      },
    },
    email: {
      label: 'E-mail',
      header: {
        sort: 'email',
      },
      cell: {
        kind: 'component',
        component: 'email',
        componentProps: client => ({ email: client.email }),
      },
    },
    paEmail: {
      label: 'PA Email',
      tooltip: 'Personal Assistant Email',
      header: {
        sort: 'personalAssistantEmail',
      },
      cell: {
        kind: 'component',
        component: 'email',
        componentProps: client => ({ email: client.personalAssistantEmail }),
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    surname: {
      label: 'Surname',
      header: {
        sort: 'surname',
      },
      cell: {
        kind: 'simple',
        path: 'surname',
      },
    },
    phone: {
      label: 'Phone',
      header: {
        sort: 'phone',
      },
      cell: {
        kind: 'component',
        component: 'phone-number',
        componentProps: client => ({ phoneNumber: client.phone }),
      },
    },
    eventPhone: {
      label: 'Event phone',
      header: {
        sort: 'eventPhone',
      },
      cell: {
        kind: 'component',
        component: 'phone-number',
        componentProps: client => ({ phoneNumber: client.eventPhone }),
      },
    },
    clientCategories: {
      label: 'Group paths',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: client => ({
          items: client.clientCategories,
          limit: 3,
          component: ClientCategory,
          componentProps: cc => ({ clientCategory: cc }),
        }),
      },
    },
    clientFunctions: {
      label: 'Functions',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: client => ({
          items: client.clientFunctions,
          limit: 3,
          component: ClientFunction,
          componentProps: cf => ({ clientFunction: cf }),
        }),
      },
    },
    clientFunctionRemark: {
      label: 'Function Remark',
      header: {
        sort: 'clientFunctionRemarkName',
      },
      cell: {
        kind: 'component',
        component: ClientFunctionRemark,
        componentProps: client => ({ clientFunctionRemark: client.clientFunctionRemark }),
      },
    },
    asl: {
      label: 'ASL',
      header: {
        sort: 'aslName',
      },
      cell: {
        kind: 'component',
        component: Asl,
        componentProps: client => ({ asl: client.asl }),
      },
    },
    tsl: {
      label: 'TSL',
      header: {
        sort: 'tslName',
      },
      cell: {
        kind: 'component',
        component: Tsl,
        componentProps: client => ({ tsl: client.tsl }),
      },
    },
    remoteId: {
      label: 'Remote ID',
      header: {
        sort: 'remoteId',
      },
      cell: {
        kind: 'simple',
        path: 'remoteId',
      },
    },
    emailCommunicationBlacklist: {
      label: 'EMAIL Messaging',
      header: {
        sort: 'emailCommunicationBlacklist',
      },
      cell: {
        kind: 'component',
        component: ClientCommunicationBlacklist,
        componentProps: client => ({ client, kind: 'email' }),
      },
    },
    smsCommunicationBlacklist: {
      label: 'SMS Messaging',
      header: {
        sort: 'smsCommunicationBlacklist',
      },
      cell: {
        kind: 'component',
        component: ClientCommunicationBlacklist,
        componentProps: client => ({ client, kind: 'sms' }),
      },
    },
    companions: {
      label: 'Companions',
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: client => ({
          items: client.allCompanions,
          limit: 5,
          component: Client,
          componentProps: client => ({ client }),
        }),
      },
    },
    nonCompanions: {
      label: 'Non-Companions',
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: client => ({
          items: client.allNonCompanions,
          limit: 5,
          component: Client,
          componentProps: client => ({ client }),
        }),
      },
    },

    clientGroups: {
      label: 'Client Groups',
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: client => ({
          items: client.clientGroups,
          limit: 3,
          component: ClientGroup,
          componentProps: cg => ({ clientGroup: cg }),
        }),
      },
    },

    createdAt: {
      label: 'Created datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt }),
      },
    },

    createdAtTime: {
      label: 'Created time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },

    createdAtDate: {
      label: 'Created date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },

    updatedAt: {
      label: 'Updated datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },

    updatedAtTime: {
      label: 'Updated time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },

    updatedAtDate: {
      label: 'Updated date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },

    archivedAt: {
      label: 'Archived datetime',
      header: {
        sort: 'archivedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.archivedAt }),
      },
    },

    archivedAtTime: {
      label: 'Archived time',
      header: {
        sort: 'archivedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.archivedAt,
          format: 'timeOnly',
        }),
      },
    },

    archivedAtDate: {
      label: 'Archived date',
      header: {
        sort: 'archivedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.archivedAt,
          format: 'dateOnly',
        }),
      },
    },
  }
}