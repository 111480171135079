<script>
import { NOTIFICATIONS } from './index'

export default {
  name: 'SystemNotificationTitle',
  props: {
    notification: {
      type: Object,
    },
  },
  computed: {
    humanizedTitle() {
      if (this.notification.title) {
        return NOTIFICATIONS[this.notification.title]
      }
      return this.notification.content
    },
  },
}
</script>

<template lang="pug">
.text-nowrap {{ notification?.additionalPayload?.preTitle }}{{ humanizedTitle }}
</template>
