//@vue/component
export default {
  name: 'DaysOfWeekOptions',
  data() {
    return {
      daysOfWeekOptions: [
        { label: 'Sun', value: 0 },
        { label: 'Mon', value: 1 },
        { label: 'Tue', value: 2 },
        { label: 'Wen', value: 3 },
        { label: 'Thu', value: 4 },
        { label: 'Fri', value: 5 },
        { label: 'Sat', value: 6 },
      ],
      daysOfWeekMapping: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wen',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
      },
    }
  },
}
