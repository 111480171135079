<script setup lang="ts">
import { dialogs } from 'frontend/_globals/dialogs'
import ColumnPresetEditorComponent from 'frontend/columns/components/ColumnPresetEditorComponent.vue'
import ColumnPresetItemComponent from 'frontend/columns/components/ColumnPresetItemComponent.vue'
import { ColumnPresetService } from 'frontend/columns/services/ColumnPresetService'
import { IColumnPresetResponseEntity } from 'frontend/columns/types/IColumnPresetResponseEntity'
import Modal from 'frontend/common/modal.vue'
import FormErrorMessageComponent from 'frontend/errors/components/FormErrorMessageComponent.vue'
import { useProvideFormErrors } from 'frontend/errors/composables/useFormErrors'
import ButtonComponent from 'frontend/roles/components/ButtonComponent.vue'
import ContainerHeaderIconTitleComponent from 'frontend/roles/components/ContainerHeaderIconTitleComponent.vue'
import EmptyListIndicatorComponent from 'frontend/roles/components/EmptyListIndicatorComponent.vue'
import EmptyRolesPlaceholderComponent from 'frontend/roles/components/EmptyRolesPlaceholderComponent.vue'
import { DataModelTypeItems } from 'frontend/roles/config/DataModelTypeItems'
import InputComponent from 'frontend/ui/components/InputComponent.vue'
import LoadingComponent from 'frontend/ui/components/LoadingComponent.vue'
import { useSmoothVisualLoadingDebouncer } from 'frontend/ui/composables/useSmoothVisualLoadingDebouncer'
import { computed, ref } from 'vue'
import Draggable from 'vuedraggable'

import { DataModelType } from '../../roles/enum/DataModelType'

interface Props {
  dataModelType: DataModelType
  // modelValue: string[]
  columnPresets: IColumnPresetResponseEntity[]
}
const props = defineProps<Props>()

interface Emits {
  (e: 'exit'): void
  (e: 'update:modelValue', value: string[]): void
  (e: 'fetchColumnPresets'): void
}
const emit = defineEmits<Emits>()

// const { columnPresetList, fetchItems } = useColumnPresets()
const { setErrors } = useProvideFormErrors({}, [['base', '']])

const columnPresetService = new ColumnPresetService()

const currentColumnPresetId = ref(null)
const isPresetInEditMode = ref(false)
const search = ref('')

// const valueModel = computed({
//   get() {
//     return props.modelValue
//   },
//   set(value) {
//     emit('update:modelValue', value)
//   },
// })

const mappedColumnPresets = computed(() => {
  if (!props.columnPresets) {
    return null
  }
  return props.columnPresets
    .filter(filter => filter.dataModelType === props.dataModelType)
    .filter(columnPreset => {
      return columnPreset.isGlobal === false
    })
    .map(item => {
      return {
        name: item.name,
        id: item.id,
        isCurrent: currentColumnPresetId.value === item.id,
      }
    })
})

const inactiveColumnPresets = computed(() => {
  if (!mappedColumnPresets.value) {
    return null
  }
  return mappedColumnPresets.value.filter(item => {
    return item.name.toLowerCase().includes(search.value.toLowerCase())
  })
})

async function saveColumnPresetOrder(columnPresetIds: string[]) {
  await columnPresetService.saveOrder(columnPresetIds)
  emit('fetchColumnPresets')
}

const inactiveColumnPresetIds = computed({
  get() {
    return inactiveColumnPresets.value?.map(item => item.id)
  },
  set(value: string[]) {
    saveColumnPresetOrder(value)
  },
})

async function createNew() {
  if (hasPresetUnsavedChanges.value) {
    await dialogs.confirm(
      'There is another preset with unsaved changes. Are you sure you want to discard them?',
    )
  }
  currentColumnPresetId.value = true
}

const dataModelTypeItem = computed(() => {
  return DataModelTypeItems.find(item => item.dataModelType === props.dataModelType)
})

const currentlyEditedColumnPreset = computed(() => {
  if (currentColumnPresetId.value === true) {
    return {
      name: '',
      id: null,
      dataModelType: props.dataModelType,
      defaultSortColumnType: null,
      enabledColumnTypes: [],
      defaultSortDirection: null,
    }
  }
  return props.columnPresets.find(item => item.id === currentColumnPresetId.value)
})

function afterPresetSave(columnPresetId: string) {
  isPresetInEditMode.value = false
  currentColumnPresetId.value = columnPresetId
  emit('fetchColumnPresets')
}

// watch(existingActiveColumnPresetIds, value => {
//   valueModel.value = value
// })

const isDragging = ref(false)

async function onDelete() {
  if (currentColumnPresetId.value === true) {
    return
  }
  try {
    await columnPresetService.delete(currentColumnPresetId.value)
    currentColumnPresetId.value = null
    emit('fetchColumnPresets')
  } catch (e) {
    setErrors(e.response.data.errors)
  }
}

const hasPresetUnsavedChanges = ref(false)
function onUnsavedChangesStateChange(hasUnsavedChanges: boolean) {
  hasPresetUnsavedChanges.value = hasUnsavedChanges
}

async function changePreset(columnPresetId: string) {
  if (hasPresetUnsavedChanges.value) {
    await dialogs.confirm(
      'There is another preset with unsaved changes. Are you sure you want to discard them?',
    )
  }
  currentColumnPresetId.value = columnPresetId
}

async function beforeModalClose() {
  if (hasPresetUnsavedChanges.value) {
    await dialogs.confirm('You have some unsaved changes. Are you sure you want to discard them?')
  }
}

const { hasLoadedWithDebounce } = useSmoothVisualLoadingDebouncer(() => {
  return !!mappedColumnPresets.value
})
</script>

<template>
  <Modal hide-footer :before-close="beforeModalClose" @closed="emit('exit')">
    <template #header>
      <ContainerHeaderIconTitleComponent icon-class="fas fa-table-columns">
        <span class="casual">Choose column presets for</span>
        <span>&nbsp;</span>
        <span class="underline">{{ dataModelTypeItem.name }}</span>
      </ContainerHeaderIconTitleComponent>
    </template>
    <div class="column-modal-content">
      <div
        v-if="hasLoadedWithDebounce"
        class="grid"
        :class="{ 'are-presets-visible': mappedColumnPresets.length }"
      >
        <div
          v-if="mappedColumnPresets.length"
          class="presets"
          :class="{ 'is-any-preset-in-edit-mode': isPresetInEditMode }"
        >
          <ContainerHeaderIconTitleComponent icon-class="fas fa-bookmark">
            Saved presets
          </ContainerHeaderIconTitleComponent>
          <div class="preset-group has-search">
            <div class="title">My presets</div>
            <div class="search-wrapper">
              <InputComponent v-model="search" placeholder="Search for preset" />
              <ButtonComponent
                icon-class="fas fa-plus"
                class="create-preset-square-button"
                @click="createNew"
              />
            </div>
            <div class="preset-items-wrapper" :class="{ 'is-dragging': isDragging }">
              <Draggable
                v-model="inactiveColumnPresetIds"
                :animation="200"
                :disabled="false"
                ghost-class="ghost"
                item-key=""
                class="preset-items"
                group="column-preset-items"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <template #item="{ index }">
                  <ColumnPresetItemComponent
                    :key="inactiveColumnPresets[index].id"
                    :can-delete="true"
                    :name="inactiveColumnPresets[index].name"
                    :class="{ 'is-current': inactiveColumnPresets[index].isCurrent }"
                    can-reorder
                    disable-toggle
                    @edit-click="changePreset(inactiveColumnPresets[index].id)"
                  />
                </template>
                <template #footer>
                  <div class="empty">There are no available presets here</div>
                </template>
              </Draggable>
            </div>
          </div>
          <FormErrorMessageComponent attribute-key="base" :disappear="5000" />
        </div>
        <div class="content">
          <ColumnPresetEditorComponent
            v-if="currentlyEditedColumnPreset"
            :key="currentColumnPresetId"
            v-model:isInEditMode="isPresetInEditMode"
            :column-preset="currentlyEditedColumnPreset"
            :is-preset-global="false"
            @save="afterPresetSave"
            @delete="onDelete"
            @unsaved-changes-state-change="onUnsavedChangesStateChange"
          />
          <div v-else class="empty-list-indicator-wrapper">
            <EmptyListIndicatorComponent>
              <template #before>
                <EmptyRolesPlaceholderComponent />
              </template>
              <template #title>
                {{ mappedColumnPresets.length ? 'Configure presets' : 'Create your first preset' }}
              </template>
              <template #description>
                Presets are sets of columns along with their<br />order and a default column for
                sorting.
              </template>
              <template v-if="!mappedColumnPresets.length" #after>
                <ButtonComponent icon-class="far fa-plus" @click="createNew">
                  Create preset
                </ButtonComponent>
              </template>
            </EmptyListIndicatorComponent>
          </div>
        </div>
      </div>
      <div v-else class="loading-wrapper">
        <LoadingComponent />
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.column-modal-content {
  min-height: 340px;
  min-width: 800px;
  box-sizing: border-box;
  display: grid;
  padding-bottom: 16px;
}

.casual {
  font-weight: 500;
}

.preset-items-wrapper {
  overflow-y: auto;
  position: relative;
  border-radius: 8px;
}

.preset-items {
  display: grid;
  gap: 2px;
  align-content: flex-start;
  min-height: 100%;
}

.empty {
  color: hsl(200deg, 5%, 50%);
  position: absolute;
  pointer-events: none;
  transition: all 0.25s cubic-bezier(0, 0.6, 0.4, 1);
  opacity: 1;
  height: 100%;
  border-radius: 8px;

  .preset-items:has(.column-preset-item) & {
    opacity: 0;
  }

  border: 2px dashed transparent;

  .is-dragging & {
    border: 2px dashed hsl(200deg, 5%, 85%);
    padding: 8px;
    box-sizing: border-box;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'content';
  align-content: flex-start;

  &.are-presets-visible {
    grid-template-columns: 240px 1fr;
    grid-template-areas: 'presets content';
  }
}

.empty-list-indicator-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;

  .create-preset-square-button {
    padding: 0;
    width: 2.2rem;
  }
}

.presets {
  display: grid;
  grid-template-rows: auto minmax(420px, 600px);
  grid-area: presets;
  border-right: 1.4px solid hsl(200deg, 5%, 89%);
  padding: 0 16px;
  gap: 8px;
  align-content: flex-start;
  user-select: none;

  &.is-any-preset-in-edit-mode {
    opacity: 0.5;
    pointer-events: none;
  }
}

.preset-group {
  display: grid;
  gap: 8px;
  align-content: flex-start;
  grid-template-rows: auto minmax(128px, 1fr);

  &.has-search {
    grid-template-rows: auto auto minmax(128px, 1fr);
  }
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container-header {
  grid-area: header;
  border-bottom: 1.4px solid hsla(200, 5%, 89%, 1);
  grid-template-columns: auto 1fr auto;
  padding: 16px;
  padding-bottom: 16px;
  align-items: center;

  .button.back {
    padding: 0;
    width: 2.2rem;
  }
}

.filter-list {
  display: grid;
}

.filter-item {
  cursor: pointer;
  padding: 4px 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-radius: 8px;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    display: none;
    gap: 4px;

    i {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover .actions {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: #edf4fa;
  }

  &.is-active {
    background: #edf4fa;
    font-weight: 600;
    color: hsl(211deg, 50%, 39%);
  }
}
.saved-filters {
  padding: 16px;
  border-right: 1.4px solid hsla(200, 5%, 89%, 1);
  display: grid;
  gap: 4px;
  align-content: flex-start;
}

.content {
  display: grid;
  padding: 0 16px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.builder-grid {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 16px;
}

.multi-button {
  display: flex;
  gap: 2px;

  .button.save-and-use {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .button.more-options {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 0;
    width: 2.4em;
  }
}

.underline {
  text-decoration: underline;
}
</style>
