<script>
import { useModals } from 'frontend/common/modals'
import { Osl } from 'frontend/osls'

export default {
  name: 'TransportBookingOsl',
  props: {
    transportBooking: { type: Object, default: () => ({}) },
  },
  setup() {
    return { ...useModals() }
  },
  components: {
    Osl,
  },
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-start(v-if="transportBooking.operationalServiceLevel")
  i.bi-info-circle.text-info.me-2(
    v-if="transportBooking.lockedOslAssignment"
    v-tooltip="`OSL was manually overridden so it won't be managed via batch actions`"
  )
  osl(:osl="transportBooking.operationalServiceLevel")
.bold.text-black-50(v-else-if="!transportBooking.readyForOsl") Missing data to compute OSL
.d-flex.align-items-center.justify-content-start(v-else)
  i.bi-info-circle.text-info.me-2(
    v-if="transportBooking.lockedOslAssignment"
    v-tooltip="`OSL was manually overridden so it won't be managed via batch actions`"
  )
  .bold.text-warning No service
</template>
