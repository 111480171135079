import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from "vue";
import VehicleBrand from "frontend/vehicle-brands/vehicle-brand.vue";

export const VehicleModelDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'created_at',
    'updated_at',
    { vehicle_brand: ['id', 'name'] }
  ],
  subscriptions: {
    items: 'VehicleModelsChannel',
    vehicleBrands: 'VehicleBrandsNameOnlyChannel',
  },
  defaultColumns: [
    'name',
    'vehicleBrand'
  ],
  columnConfig: {
    name: {
      label: 'Name',
      header: {
        sort: 'lower_name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    vehicleBrand: {
      label: 'Vehicle Brand',
      header: {
        sort: 'vehicleBrandName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleBrand),
        componentProps: vehicleModel => ({ vehicleBrand: vehicleModel.vehicleBrand }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleModel => ({
          t: vehicleModel.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleModel => ({
          t: vehicleModel.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleModel => ({
          t: vehicleModel.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleModel => ({
          t: vehicleModel.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleModel => ({
          t: vehicleModel.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleModel => ({
          t: vehicleModel.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}