import { AslsSelect } from 'frontend/asls'
import { AslsService } from 'frontend/asls/asls-service'
import { ClientCategoriesSelect } from 'frontend/client-categories'
import { ClientCategoriesService } from 'frontend/client-categories/client-categories-service'
import { ClientFunctionRemarksSelect } from 'frontend/client-function-remarks'
import { ClientFunctionRemarksService } from 'frontend/client-function-remarks/client-function-remarks-service'
import { ClientFunctionsSelect } from 'frontend/client-functions'
import { ClientFunctionsService } from 'frontend/client-functions/client-functions-service'
import { ClientGroupsSelect } from 'frontend/client-groups'
import { ClientGroupsService } from 'frontend/client-groups/client-groups-service'
import { ClientsSelect } from 'frontend/clients'
import { ClientsService } from 'frontend/clients/clients-service'
import { ClustersSelect } from 'frontend/clusters'
import { ClustersService } from 'frontend/clusters/clusters-service'
import { AsyncPresenter } from 'frontend/common/formatters/async-presenter'
import { DayLabelsSelect } from 'frontend/day-labels'
import { DayLabelsService } from 'frontend/day-labels/day-labels-service'
import { LocationsSelect } from 'frontend/locations'
import { LocationsService } from 'frontend/locations/locations-service'
import { OslsSelect } from 'frontend/osls'
import { OslsService } from 'frontend/osls/osls-service'
import { KINDS as TB_KINDS } from 'frontend/transport-bookings'
import { TransportationPointsSelect } from 'frontend/transportation-points'
import { TransportationPointsService } from 'frontend/transportation-points/transportation-points-service'
import { markRaw } from 'vue'

export const CRITERIA_CONFIG = [
  {
    label: 'Type',
    attribute: 'kind',
    kinds: ['in', 'notIn'],
    componentProps: {
      collection: TB_KINDS,
    },
  },
  {
    label: 'OSL',
    kinds: ['in', 'notIn'],
    attribute: 'operationalServiceLevelId',
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new OslsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => c.name)
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      in: {
        component: markRaw(OslsSelect),
        componentName: 'filters.operationalServiceLevelIdIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Consumed by demands',
              scopeName: 'havingDemandsOrExplicitIds',
            },
          ],
        },
      },
      notIn: {
        component: markRaw(OslsSelect),
        componentName: 'filters.operationalServiceLevelIdNotIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Consumed by demands',
              scopeName: 'havingDemandsOrExplicitIds',
            },
          ],
        },
      },
    },
  },
  {
    label: 'ASL',
    kinds: ['in', 'notIn'],
    attribute: 'computedAslId',
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new AslsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => c.name)
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      in: {
        component: markRaw(AslsSelect),
        componentName: 'filters.computedAslIdIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Having Clients or TBs',
              scopeName: 'havingClientsOrTransportBookingsOrExplicitIds',
            },
          ],
        },
      },
      notIn: {
        component: markRaw(AslsSelect),
        componentName: 'filters.computedAslIdNotIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Having Clients or TBs',
              scopeName: 'havingClientsOrTransportBookingsOrExplicitIds',
            },
          ],
        },
      },
    },
  },
  {
    label: 'Client',
    kinds: ['in', 'notIn'],
    attribute: 'clientId',
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new ClientsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name', 'surname'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => [c.surname, c.name].join(' '))
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      in: {
        component: markRaw(ClientsSelect),
        componentName: 'filters.idIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Assigned to TB',
              scopeName: 'havingTransportBookingsOrExplicitIds',
            },
            {
              label: 'Archived',
              scopeName: 'onlyArchivedClients',
            },
          ],
        },
      },
      notIn: {
        component: markRaw(ClientsSelect),
        componentName: 'filters.idNotIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Assigned to TB',
              scopeName: 'havingTransportBookingsOrExplicitIds',
            },
            {
              label: 'Archived',
              scopeName: 'onlyArchivedClients',
            },
          ],
        },
      },
    },
    children: [
      {
        label: 'Group paths',
        kinds: ['overlapArray', 'notOverlapArray', 'notNull'],
        attribute: 'clientCategoryIds',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new ClientCategoriesService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          overlapArray: {
            component: markRaw(ClientCategoriesSelect),
            componentName: 'filters.categoryIdsOverlapArray',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having clients',
                  scopeName: 'havingClientsOrExplicitIds',
                },
              ],
            },
          },
          notOverlapArray: {
            component: markRaw(ClientCategoriesSelect),
            componentName: 'filters.categoryIdsNotOverlapArray',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having clients',
                  scopeName: 'havingClientsOrExplicitIds',
                },
              ],
            },
          },
        },
      },
      {
        label: 'Client Groups',
        kinds: ['overlapArray', 'notOverlapArray', 'notNull'],
        attribute: 'clientGroupIds',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new ClientGroupsService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          overlapArray: {
            component: markRaw(ClientGroupsSelect),
            componentName: 'filters.clientGroupIdsOverlapArray',
          },
          notOverlapArray: {
            component: markRaw(ClientGroupsSelect),
            componentName: 'filters.clientGroupIdsNotOverlapArray',
          },
        },
      },
      {
        label: 'Functions',
        kinds: ['overlapArray', 'notOverlapArray', 'notNull'],
        attribute: 'clientFunctionIds',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new ClientFunctionsService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          overlapArray: {
            component: markRaw(ClientFunctionsSelect),
            componentName: 'filters.functionIdsOverlapArray',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having clients',
                  scopeName: 'havingClientsOrExplicitIds',
                },
              ],
            },
          },
          notOverlapArray: {
            component: markRaw(ClientFunctionsSelect),
            componentName: 'filters.functionIdsNotOverlapArray',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having clients',
                  scopeName: 'havingClientsOrExplicitIds',
                },
              ],
            },
          },
        },
      },
      {
        label: 'Function remark',
        kinds: ['in', 'notNull'],
        attribute: 'clientFunctionRemarkId',
        fetchSelectedItems: new AsyncPresenter(() => new ClientFunctionRemarksService()).present,
        slots: {
          in: {
            component: markRaw(ClientFunctionRemarksSelect),
            componentName: 'filters.clientClientFunctionRemarkId',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having clients',
                  scopeName: 'havingClientsOrExplicitIds',
                },
              ],
            },
          },
        },
      },
    ],
  },
  {
    label: 'Occasion',
    kinds: ['overlapArray', 'notOverlapArray', 'notNull'],
    attribute: 'dayLabelIds',
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new DayLabelsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => c.name)
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      overlapArray: {
        component: markRaw(DayLabelsSelect),
        componentName: 'filters.dayLabelIdIn',
      },
      notOverlapArray: {
        component: markRaw(DayLabelsSelect),
        componentName: 'filters.dayLabelIdIn',
      },
    },
  },
  {
    label: 'Pick-up',
    children: [
      {
        label: 'Cluster',
        kinds: ['in', 'notIn'],
        attribute: 'startLocationClusterId',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new ClustersService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          in: {
            component: markRaw(ClustersSelect),
            componentName: 'filters.startLocationClusterIdIn',
          },
          notIn: {
            component: markRaw(ClustersSelect),
            componentName: 'filters.startLocationClusterIdNotIn',
          },
        },
      },
      {
        label: 'Location',
        kinds: ['in', 'notIn'],
        attribute: 'startLocationId',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new LocationsService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          in: {
            component: markRaw(LocationsSelect),
            componentName: 'filters.startLocationIdIn',
          },
          notIn: {
            component: markRaw(LocationsSelect),
            componentName: 'filters.startLocationIdNotIn',
          },
        },
      },
      {
        label: 'Transportation point',
        kinds: ['in', 'notIn', 'notNull'],
        attribute: 'startTransportationPointId',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new TransportationPointsService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          in: {
            component: markRaw(TransportationPointsSelect),
            componentName: 'filters.startTransportationPointIdIn',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having TBs',
                  scopeName: 'havingFromTransportBookingsOrExplicitIds',
                },
              ],
            },
          },
          notIn: {
            component: markRaw(TransportationPointsSelect),
            componentName: 'filters.startTransportationPointIdNotIn',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having TBs',
                  scopeName: 'havingFromTransportBookingsOrExplicitIds',
                },
              ],
            },
          },
        },
      },
    ],
  },
  {
    label: 'Drop-off',
    children: [
      {
        label: 'Cluster',
        kinds: ['in', 'notIn'],
        attribute: 'endLocationClusterId',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new ClustersService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          in: {
            component: markRaw(ClustersSelect),
            componentName: 'filters.endLocationClusterIdIn',
          },
          notIn: {
            component: markRaw(ClustersSelect),
            componentName: 'filters.endLocationClusterIdNotIn',
          },
        },
      },
      {
        label: 'Location',
        kinds: ['in', 'notIn'],
        attribute: 'endLocationId',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new LocationsService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          in: {
            component: markRaw(LocationsSelect),
            componentName: 'filters.endLocationIdIn',
          },
          notIn: {
            component: markRaw(LocationsSelect),
            componentName: 'filters.endLocationIdNotIn',
          },
        },
      },
      {
        label: 'Transportation point',
        kinds: ['in', 'notIn', 'notNull'],
        attribute: 'endTransportationPointId',
        fetchSelectedItems: async ids => {
          if (ids?.length) {
            const response = await new TransportationPointsService().index({
              data: {
                idIn: ids.constructor == Array ? ids : [ids],
                fields: ['name'],
              },
            })
            return Object.values(response?.entities?.items || {})
              .map(c => c.name)
              .join(', ')
          } else {
            return 'empty'
          }
        },
        slots: {
          in: {
            component: markRaw(TransportationPointsSelect),
            componentName: 'filters.endTransportationPointIdIn',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having TBs',
                  scopeName: 'hasToTransportBookingsIn',
                },
              ],
            },
          },
          notIn: {
            component: markRaw(TransportationPointsSelect),
            componentName: 'filters.endTransportationPointIdNotIn',
            componentProps: {
              optionQuerySelectors: [
                {
                  label: 'Having TBs',
                  scopeName: 'hasToTransportBookingsIn',
                },
              ],
            },
          },
        },
      },
    ],
  },
  {
    label: 'Private jet',
    kinds: ['bool'],
    attribute: 'isFlightPrivate',
  },
  {
    label: 'Flight class',
    kinds: ['cont', 'start', 'end', 'present'],
    attribute: 'flightClass',
  },
]

const flattenChildren = (config, parent = null) => {
  let children = []
  config.parent = parent
  if (config.children?.length > 0) {
    children = config.children.flatMap(child => flattenChildren(child, config))
  }
  return [...children, config]
}

//TODO: make a map with attribute as key?
export const CRITERIA_CONFIG_FLAT = CRITERIA_CONFIG.flatMap(config => flattenChildren(config))
