import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from "vue";
import {startCase} from 'lodash'
import {User} from 'frontend/users'

export const VersionDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'item_type',
    'created_at',
    'item_id',
    'performed_by'
  ],
  subscriptions: {
    items: 'VersionsChannel',
  },
  defaultColumns: [
    'itemType',
    'eid',
    'createdAt',
    'performedBy'
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    itemType: {
      label: 'Resource type',
      header: {
        sort: 'itemType',
      },
      cell: {
        kind: 'function',
        fn: el => startCase(el.itemType),
      },
    },
    performedBy: {
      label: 'Actor',
      cell: {
        kind: 'component',
        component: markRaw(User),
        componentProps: el => ({
          user: el.performedBy,
          placeholderText: 'System',
        }),
      },
    },
    createdAt: {
      label: 'Resource destroyed At',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt, format: 'shortWithSeconds' }),
      },
    },
  }
}