<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'VehicleCategory',
  props: {
    vehicleCategory: { type: Object },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
template(v-if="vehicleCategory")
  modal-button(:item="vehicleCategory" :modal="previewModals.VehicleCategory")
    a
      erebus-label(:item="vehicleCategory")
span.text-black-15(v-else) empty
</template>
