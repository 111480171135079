<script>
import { range, startCase } from 'lodash'

const DEFAULT_PER_PAGE = 50

export default {
  name: 'NewEaPagination',
  emits: ['update:page', 'update:perPage'],
  props: {
    disablePagination: { type: Boolean, default: false },
    total: { type: Number },
    perPage: { type: Number },
    page: { type: Number },
    perPageOptions: { type: Array, default: () => [1, 10, 20, 50, 100, 1000] },
  },
  computed: {
    perPageDropdownActions() {
      return this.perPageOptions.map(opt => {
        return {
          title: `${startCase(opt)} per page`,
          clickFn: (e, closeFn) => this.perPageChanged(e, closeFn, opt),
        }
      })
    },
    lastPage() {
      const value = Math.ceil(((this.total || 0) * 1.0) / (this.perPage || DEFAULT_PER_PAGE))
      if (this.total && (this.page || 1) > value) {
        this.$emit('update:page', this.lastPage)
      }
      return value
    },
    displayedPages() {
      let min = this.page - 2
      if (min < 1) min = 1
      let max = this.page + 2
      if (max > this.lastPage) max = this.lastPage
      return range(min, max + 1)
    },
  },
  mounted() {
    if (!this.perPage || this.perPageOptions.indexOf(this.perPage) == -1) {
      this.$emit('update:perPage', DEFAULT_PER_PAGE)
    }
    if (!this.page || this.page < 1) {
      this.$emit('update:page', 1)
    }
  },
  methods: {
    perPageChanged(evt, closeFn, value) {
      evt.preventDefault()
      if (closeFn) closeFn()
      value == 'all' ? this.$emit('update:perPage', 100000) : this.$emit('update:perPage', value)
      this.$emit('update:page', 1)
    },
    pageClicked(evt, pageNo) {
      evt.preventDefault()
      if (pageNo != this.page) {
        this.$emit('update:page', pageNo)
      }
    },
  },
}
</script>

<template lang="pug">
.new-pagination-container.d-flex.align-items-center.justify-content-between.flex-column.flex-sm-row(
  v-if="page && total && !disablePagination"
)
  .new-pagination-container-text
    span(v-if="lastPage > 1") Displaying {{ (page - 1) * perPage + 1 }} - {{ page * perPage > total ? total : page * perPage }} of total {{ total }} record(s)
    span(v-else) Displaying {{ total }} record(s)

  nav.d-flex.flex-column.flex-sm-row.align-items-center(aria-label="Pagination")
    ul.pagination.pagination-sm(v-if="lastPage > 1")
      li.page-item(:class="{ disabled: page == 1 }")
        a.page-link(@click="pageClicked($event, 1)") First
      li.page-item(:class="{ disabled: page == 1 }")
        a.page-link(@click="pageClicked($event, page - 1)")
          i.bi-chevron-left
      li.page-item(v-for="pageNo in displayedPages" :class="{ active: pageNo == page }")
        a.page-link(@click="pageClicked($event, pageNo)") {{ pageNo }}
      li.page-item(:class="{ disabled: page == lastPage }")
        a.page-link(@click="pageClicked($event, page + 1)")
          i.bi-chevron-right
      li.page-item(:class="{ disabled: page == lastPage }")
        a.page-link(@click="pageClicked($event, lastPage)") Last

    new-dropdown.ms-3(:actions="perPageDropdownActions" name="perPage")
      button.btn.btn-outline-primary.btn-sm.dropdown-toggle.per-page-dropdown {{ perPage === 100000 ? 'all records' : `${perPage} per page` }}
</template>
