import * as Sentry from '@sentry/vue'
import consumer from 'channels/consumer'
import { camelResponse } from 'frontend/_config/case-converter'
import computeSchema from 'frontend/_globals/compute-schema'
import eventHub from 'frontend/_globals/event-hub'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter.js'
import { denormalize } from 'normalizr'

let sessionRefetchOnConnect = false

const OU_FIELDS = [
  'id',
  'provided_roles',
  'is_admin',
  'table_config',
  {
    active_event_organization_users: [
      'id',
      'roles',
      'role_description',
      'event_id',
      'filter_config',
      'unread_chat_groups_count',
      'current_time_zone',
      'favorites',
      'additional_enabled_view_types',
      { additional_role_scopes: ['id', 'data_model_type', 'action_types'] },
      { event: ['id', 'slug', 'archived_at', 'logo_urls'] },
      {
        role: [
          'enabled_view_types',
          'name',
          { role_scopes: ['id', 'data_model_type', 'action_types'] },
        ],
      },
    ],
  },
]

const USER_FIELDS = [
  'id',
  'email',
  'is_superuser',
  'name',
  'phone',
  'surname',
  'current_organization_id',
  'is_blocked',
  'impersonated_user_id',
  'impersonated_user_label',
  {
    impersonated_current_organization_user: OU_FIELDS,
  },
  {
    current_organization_user: OU_FIELDS,
  },
  {
    organizations: [
      'id',
      'name',
      'custom_app_name',
      'custom_domain',
      'custom_primary_color',
      'travel_time_modifiers_enabled',
      'sso',
      'global_column_config',
    ],
  },
]
const sessionStoreModule = {
  namespaced: true,
  state: () => ({
    user: null,
    cableSubscription: null,
    remoteLogoutCableSubscription: null,
    afterSignInPath:
      sessionStorage.getItem(`afterSignInPath`)?.length &&
      sessionStorage.getItem(`afterSignInPath`) != 'null'
        ? sessionStorage.getItem(`afterSignInPath`)
        : null,
    currentlyFetching: false,
    timeZone: dayjs.tz.guess(),
  }),

  mutations: {
    setUser(state, user) {
      if (!user) {
        this.dispatch('session/unsubscribe')
        Sentry.setUser(null)
      } else {
        Sentry.setUser(user)
      }
      state.user = user
    },
    setTimeZone(state, timeZone) {
      state.timeZone = timeZone
    },
    setCurrentlyFetching(state, currentlyFetching) {
      state.currentlyFetching = currentlyFetching
    },
    setAfterSignInPath(state, path) {
      if ((path || '').startsWith('/session/')) {
        state.afterSignInPath = null
        sessionStorage.setItem(`afterSignInPath`, '')
      } else {
        state.afterSignInPath = path?.length ? path : null
        sessionStorage.setItem(`afterSignInPath`, path?.length ? path : '')
      }
    },
    setCableSubscription(state, value) {
      state.cableSubscription = value
    },
    setRemoteLogoutCableSubscription(state, value) {
      state.remoteLogoutCableSubscription = value
    },
    setGlobalColumnConfig(state, { organizationId, config }) {
      const organization = state.organizations.find(org => org.id === organizationId)
      organization.globalColumnConfig = config
    },
  },
  actions: {
    subscribe(context, userId) {
      return new Promise(resolve => {
        context.dispatch('unsubscribe').then(() => {
          const customIdentifier = Math.round(Math.random() * 1000000)
          const cableSubscription = consumer.subscriptions.create(
            {
              channel: 'CurrentUserChannel',
              user_id: userId,
              fields: USER_FIELDS,
              customIdentifier,
            },
            {
              customIdentifier,
              connected() {
                console.log(`%c CONNECTED TO CurrentUserChannel`, 'color: #4c74b9')
                eventHub.$emit('cable-connection-check')
                if (sessionRefetchOnConnect) {
                  sessionRefetchOnConnect = false
                  context.dispatch('getOrFetchUser', true)
                }
              },
              disconnected() {
                sessionRefetchOnConnect = true
                console.warn(`DISCONNECTED FROM CurrentUserChannel`)
                eventHub.$emit('cable-connection-check')
              },
              rejected() {
                console.warn('REJECTED CONNECTION TO CURRENT USER CHANNEL')
              },
              received(message) {
                if (!message.obj) {
                  context.commit('setUser', null)
                } else {
                  const result = camelResponse(JSON.parse(message.obj))
                  const schema = computeSchema(USER_FIELDS, result.mappings)
                  const newUser = denormalize(message.id, (schema?.items || [])[0], result.entities)
                  context.commit('setUser', newUser)
                }
              },
            },
          )
          context.commit('setCableSubscription', cableSubscription)
          resolve(true)
        })
      })
    },
    subscribeToRemoteLogoutChannel(context, userId) {
      return new Promise(resolve => {
        context.dispatch('unsubscribeFromSessionChannel').then(() => {
          const customIdentifier = Math.round(Math.random() * 1000000)
          const cableSubscription = consumer.subscriptions.create(
            {
              channel: 'CurrentUserRemoteLogoutChannel',
              user_id: userId,
              customIdentifier,
            },
            {
              customIdentifier,
              // connected() {
              //   console.log(`%c CONNECTED TO CurrentUserRemoteLogoutChannel`, 'color: #4c74b9')
              // },
              rejected() {
                console.warn('REJECTED CONNECTION TO CURRENT USER REMOTE LOGOUT CHANNEL')
              },
              received(message) {
                if (message === true) {
                  context.commit('setUser', null)
                  setupRouter.push('/')
                }
              },
            },
          )
          context.commit('setRemoteLogoutCableSubscription', cableSubscription)
          resolve(true)
        })
      })
    },
    unsubscribe(context) {
      return new Promise(resolve => {
        if (context.state.cableSubscription) {
          const remoteSubscription = consumer.subscriptions.subscriptions.find(
            s => s.customIdentifier == context.state.cableSubscription.customIdentifier,
          )
          if (remoteSubscription) {
            console.log(`%c currentUser will unsubscribe from its channel`, 'color: gray')
            consumer.subscriptions.remove(remoteSubscription)
          }
          // context.state.cableSubscription.unsubscribe()
          context.commit('setCableSubscription', null)
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
    unsubscribeFromSessionChannel(context) {
      return new Promise(resolve => {
        if (context.state.remoteLogoutCableSubscription) {
          const remoteSubscription = consumer.subscriptions.subscriptions.find(
            s => s.customIdentifier == context.state.remoteLogoutCableSubscription.customIdentifier,
          )
          if (remoteSubscription) {
            console.log(
              `%c currentUser will unsubscribe from its remote logout channel`,
              'color: gray',
            )
            consumer.subscriptions.remove(remoteSubscription)
          }
          context.commit('setRemoteLogoutCableSubscription', null)
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
    getOrFetchUser(context, forceFetch = false) {
      return new Promise(resolve => {
        if (!forceFetch && context.state.user) {
          resolve(context.state.user)
        } else if (!context.state.currentlyFetching) {
          context.commit('setCurrentlyFetching', true)
          this.axios({
            method: 'get',
            url: `session/current_user`,
            fields: USER_FIELDS,
          })
            .then(response => {
              context.commit('setUser', response.data)
              context.commit('setCurrentlyFetching', false)
              if (!response.data.isBlocked)
                context.dispatch('subscribeToRemoteLogoutChannel', response.data.id)

              context.dispatch('subscribe', response.data.id).then(() => {
                resolve(response.data)
              })
            })
            .catch(errorResponse => {
              if (errorResponse?.response?.status != 401)
                console.error("Can't fetch the current user", errorResponse)
              context.commit('setUser', null)
              context.commit('setCurrentlyFetching', false)
              context.dispatch('unsubscribe').then(() => {
                resolve(null)
              })
            })
        } else {
          resolve({ currentlyFetching: true })
        }
      })
    },
  },
  getters: {
    timeZone: state => state.timeZone,
    currentUser: state => state.user,
    currentOrganization: state =>
      state.user
        ? state.user?.organizations?.find(org => org.id === state.user.currentOrganizationId)
        : null,
    currentlyFetching: state => state.currentlyFetching,
    organizationUser: state => state.user?.currentOrganizationUser,
    eventOrganizationUser: state => eventSlug =>
      state.user?.currentOrganizationUser?.activeEventOrganizationUsers.find(
        eou => eou.event?.slug === eventSlug,
      ),
    onlyOnePassengerEvent: state =>
      state.user?.currentOrganizationUser?.activeEventOrganizationUsers?.filter(
        eou => eou.roles.includes('passenger') && !eou.event?.archivedAt,
      )?.length === 1,
    activeEvents: state =>
      (
        state.user?.currentOrganizationUser?.activeEventOrganizationUsers?.filter(
          eou => eou.roles.includes('passenger') && !eou.event?.archivedAt,
        ) || []
      ).map(ae => ae.event),
    isAdminInEvent: (state, getters) => eventSlug =>
      getters.eventOrganizationUser(eventSlug)?.roles?.includes('admin'),
  },
}

export default sessionStoreModule
