<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'

interface Props {
  placeholder?: string
  type?: string
  modelValue?: string
  autofocus?: boolean
  inputClass?: string
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  type: 'text',
  modelValue: '',
  autofocus: false,
  inputClass: '',
})

interface Emits {
  (e: 'update:modelValue', value: string): void
}
const emit = defineEmits<Emits>()

const inputRef = ref(null)

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value: string) {
    emit('update:modelValue', value)
  },
})

onMounted(() => {
  nextTick(() => {
    if (props.autofocus && inputRef.value) {
      inputRef.value.focus()
    }
  })
})
</script>

<template>
  <div class="input-wrapper">
    <input
      ref="inputRef"
      v-model="valueModel"
      :class="inputClass"
      :type="props.type"
      :placeholder="props.placeholder"
    />
    <i v-if="valueModel" class="icon fas fa-times" @click="valueModel = ''" />
  </div>
</template>

<style scoped lang="scss">
.input-wrapper {
  width: 100%;
}

input {
  border-radius: 8px;
  border: 1px solid #d3d5d6;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  height: 36px;
  padding: 0 12px;
  width: 100%;
}

.icon {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(200deg, 4%, 72%);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    color: hsl(206deg, 58%, 55%);
  }
}
</style>
