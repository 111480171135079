<script setup lang="ts">
import { CriteriaValueType } from 'frontend/common/data-tables/search-criteria/enum/CriteriaValueType'
import BooleanCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/BooleanCriteriaValueTypeInputStrategy.vue'
import CustomContainsCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/CustomContainsCriteriaValueTypeInputStrategy.vue'
import DateMultiCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/DateMultiCriteriaValueTypeInputStrategy.vue'
import DateTimeCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/DateTimeCriteriaValueTypeInputStrategy.vue'
import DateTimeWithVariablesCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/DateTimeWithVariablesCriteriaValueTypeInputStrategy.vue'
import InputCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/InputCriteriaValueTypeInputStrategy.vue'
import ResourceListCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/ResourceListCriteriaValueTypeInputStrategy.vue'
import SelectionCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/SelectionCriteriaValueTypeInputStrategy.vue'
import TaggableSelectCriteriaValueTypeInputStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeInputStrategies/TaggableSelectCriteriaValueTypeInputStrategy.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import type { Component } from 'vue'
import { computed } from 'vue'

interface Props {
  criteriaValueType: CriteriaValueType
  modelValue: unknown
  criterion: ISearchCriteriaNode
  predicate: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: unknown): void
}
const emit = defineEmits<Emits>()

const strategyMap = new Map<CriteriaValueType, Component>([
  [CriteriaValueType.Boolean, BooleanCriteriaValueTypeInputStrategy],
  [CriteriaValueType.CustomContains, CustomContainsCriteriaValueTypeInputStrategy],
  [CriteriaValueType.DateMulti, DateMultiCriteriaValueTypeInputStrategy],
  [CriteriaValueType.DateTime, DateTimeCriteriaValueTypeInputStrategy],
  [CriteriaValueType.DateTimeWithVariables, DateTimeWithVariablesCriteriaValueTypeInputStrategy],
  [CriteriaValueType.Input, InputCriteriaValueTypeInputStrategy],
  [CriteriaValueType.ResourceList, ResourceListCriteriaValueTypeInputStrategy],
  [CriteriaValueType.Selection, SelectionCriteriaValueTypeInputStrategy],
  [CriteriaValueType.TaggableSelect, TaggableSelectCriteriaValueTypeInputStrategy],
])
const strategyComponent = computed(() => {
  return strategyMap.get(props.criteriaValueType)
})

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <Component
    :is="strategyComponent"
    v-if="strategyComponent"
    :key="criteriaValueType"
    v-model="valueModel"
    :criterion="criterion"
    :predicate="predicate"
  />
</template>
