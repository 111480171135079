<script>
import { ref } from 'vue'

export default {
  name: 'EaSwitch',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    label: { type: String, default: null },
    value: { type: [String, Number] },
    modelValue: { type: [String, Number, Array, Boolean] },
    validators: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    name: { type: String, required: true },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  setup() {
    return { formControlContainerRef: ref(null) }
  },
}
</script>

<template lang="pug">
form-control-container(
  ref="formControlContainerRef"
  :additional-errors="additionalErrors"
  :validators="validators"
  :value="modelValue"
)
  template(#default)
    ea-simple-checkbox(
      :as-switch="true"
      :disabled="disabled"
      :label="label"
      :name="name"
      :value="value"
      v-model="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    )
      template(v-if="label" #label)
        span.pe-1(v-if="formControlContainerRef?.required" v-tooltip="'Field is required'") *
        span {{ label }}
</template>
