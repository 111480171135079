import {IColumnSortItems} from "frontend/columns/types/IColumnSortItems";
import {ColumnSortDirection} from "frontend/columns/enum/ColumnSortDirection";

export function useSortItems() {
  function convertStoredSortToSortItems(sort: string): IColumnSortItems {
    if(!sort) {
      return []
    }
    return sort.split(',').map(sortItem => {
      const splittedSortItem = sortItem.split(':')
      const sortColumnKey = splittedSortItem[0]
      const sortDirection = splittedSortItem[1] as ColumnSortDirection

      return [sortColumnKey, sortDirection || ColumnSortDirection.Ascending]
    })

  }

  function convertSortItemsToStoredSort(sortItems: IColumnSortItems): string {
    return (sortItems || [])
    .map(sortItem => {
      return sortItem.join(':')
    })
    .join(',')
  }

  return {
    convertStoredSortToSortItems,
    convertSortItemsToStoredSort,
  }
}