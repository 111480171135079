import { defineAsyncComponent } from 'vue'

const LocationType = defineAsyncComponent(() => import('./location-type.vue'))
const LocationTypeModal = defineAsyncComponent(() => import('./location-type-modal.vue'))
const LocationTypesModal = defineAsyncComponent(() => import('./location-types-modal.vue'))
const LocationTypesSelect = defineAsyncComponent(() => import('./location-types-select.vue'))
const LocationTypesTable = defineAsyncComponent(() => import('./location-types-table.vue'))

export {
  LocationType,
  LocationTypeModal,
  LocationTypesModal,
  LocationTypesSelect,
  LocationTypesTable,
}
