<script>
export default {
  name: 'MinMaxHelper',
  props: {
    v: { type: Object, default: () => ({}) },
    validation: { type: Object, default: () => ({}) },
    precision: { type: Number, default: null },
    inputType: { type: String, default: 'text' },
    currentValue: { type: [String, Number] },
  },
  computed: {
    minLength() {
      return this.validation?.minLength?.$params?.min
    },
    minLengthValid() {
      return !this.v?.minLength?.$invalid
    },
    maxLength() {
      return this.validation?.maxLength?.$params?.max
    },
    maxLengthValid() {
      return !this.v?.maxLength?.$invalid
    },
    minValue() {
      const result = this.validation?.minValueExclusive?.$params?.min
      if (result || result === 0) {
        return result
      } else {
        return this.validation?.minValueInclusive?.$params?.min
      }
    },
    maxValue() {
      return (
        this.validation?.maxValueExclusive?.$params?.max ||
        this.validation?.maxValueInclusive?.$params?.max
      )
    },
    displayedMin() {
      if (this.minValue || this.minValue === 0) {
        if (this.precision || this.precision === 0)
          return (
            Math.round(+this.minValue * Math.pow(10, this.precision)) /
            parseFloat(Math.pow(10, this.precision))
          ).toString()
        else return this.minValue
      } else {
        return ''
      }
    },
    displayedMax() {
      if (this.maxValue) {
        if (this.precision || this.precision == 0)
          return (
            Math.round(+this.maxValue * Math.pow(10, this.precision)) /
            parseFloat(Math.pow(10, this.precision))
          ).toString()
        else return this.maxValue
      } else {
        return ''
      }
    },
  },
}
</script>

<template lang="pug">
template(v-if="['text', 'tel'].includes(inputType)")
  .form-text(v-if="minLength && (!minLengthValid || !maxLength)")
    | {{ `${(currentValue || '').toString().length}/min ${minLength}` }}

  .form-text(v-else-if="maxLength && (!minLength || minLengthValid)")
    | {{ `${(currentValue || '').toString().length}/${maxLength}` }}

template(v-else)
  .form-text(v-if="(minValue || minValue == 0) && maxValue")
    | {{ `Min: ${displayedMin}, max: ${displayedMax}` }}

  .form-text(v-else-if="minValue || minValue == 0")
    | {{ `Min: ${displayedMin}` }}

  .form-text(v-else-if="maxValue")
    | {{ `Max: ${displayedMax}` }}
</template>
