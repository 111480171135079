<script>
import { Collapse } from 'bootstrap'

const SHOWN_EVENT = 'shown.bs.collapse'
const HIDDEN_EVENT = 'hidden.bs.collapse'

export default {
  name: 'Collapsible',
  emits: ['collapsed'],
  props: {
    expand: { type: Boolean, default: false },
  },
  data() {
    return {
      collapse: null,
      initialState: false,
    }
  },
  watch: {
    expand: {
      handler(value) {
        if (value) {
          this.show()
        } else {
          this.hide()
        }
      },
    },
  },
  mounted() {
    this.initialState = this.expand
    this.collapse = new Collapse(this.$refs.collapsible, { toggle: false })
    this.handleCollapseDuringTransition()
  },
  methods: {
    handleCollapseDuringTransition() {
      this.$refs.collapsible.addEventListener(SHOWN_EVENT, () => {
        if (!this.expand) this.hide()
        this.$emit('collapsed')
      })
      this.$refs.collapsible.addEventListener(HIDDEN_EVENT, () => {
        if (this.expand) this.show()
      })
    },
    show() {
      this.collapse.show()
    },
    hide() {
      this.collapse.hide()
    },
    toggle() {
      this.collapse.toggle()
    },
  },
}
</script>

<template lang="pug">
slot(name="header")
.collapse(ref="collapsible" :class="{ show: initialState }")
  slot
</template>

<style scoped lang="scss"></style>
