import AbstractRoute from '../../common/abstract-route.vue'
import LayoutEvent from '../../common/layout/layout-event.vue'
import { RouteName } from './types/RouteName'

export function setupEventOperationalRoutes() {
  return [
    {
      path: '/events',
      component: AbstractRoute,
      children: [
        {
          path: '',
          component: () => import('../../events/events-index.vue'),
          name: RouteName.Events,
          meta: { title: 'Events available for you' },
        },
        {
          path: ':eventSlug',
          component: LayoutEvent,
          redirect: { name: RouteName.Events },
          children: [
            {
              path: 'dashboard',
              component: () => import('../../events/event-dashboard.vue'),
              name: RouteName.EventDashboard,
              meta: { title: 'Event dashboard' },
            },
            {
              path: 'roles',
              component: () => import('../../roles/pages/RolesPage.vue'),
              name: RouteName.Roles,
              meta: {
                title: 'Role Builder',
              },
            },
            {
              path: 'roles/new',
              component: () => import('../../roles/pages/RolePage.vue'),
              name: RouteName.CreateRole,
              meta: {
                title: 'Create new role',
              },
            },
            {
              path: 'roles/:roleId',
              component: () => import('../../roles/pages/RolePage.vue'),
              name: RouteName.Role,
              meta: {
                title: 'Edit role',
              },
            },
            {
              path: 'live-panel',
              component: () => import('../../live-panel/live-panel-screen.vue'),
              name: RouteName.LivePanel,
              meta: { title: 'Live panel' },
            },
            {
              path: 'dispatcher-screen',
              component: () => import('../../dispatcher/dispatcher-screen.vue'),
              meta: { title: 'Dispatcher Screen' },
            },
            {
              path: 'origin-x-destination-matrix',
              name: 'OriginXDestinationMatrix',
              component: () =>
                import('../../origin-x-destination-matrix/origin-x-destination-matrix.vue'),
              meta: { title: 'Origin x Destination Matrix' },
            },
            {
              path: 'shifts-manager-screen',
              component: () => import('../../shifts-manager/shifts-manager-screen.vue'),
              meta: { title: 'Shifts Manager Screen' },
            },
            {
              path: 'reports',
              component: () => import('../../reports/reports-index.vue'),
              meta: { title: 'Event reports' },
            },
            {
              path: 'deleted-resources',
              component: () => import('../../versions/versions-index.vue'),
              meta: { title: 'Deleted resources' },
            },
            {
              path: 'clusters',
              component: () => import('../../clusters/clusters-index.vue'),
              meta: { title: 'Clusters' },
            },
            {
              path: 'clients',
              component: () => import('../../clients/clients-index.vue'),
              meta: { title: 'Event clients' },
            },
            {
              path: 'client-groups',
              component: () => import('../../client-groups/client-groups-index.vue'),
              meta: { title: 'Client Groups' },
            },
            {
              path: 'notification-templates',
              component: () =>
                import('../../notification-templates/notification-templates-index.vue'),
              meta: { title: 'PUSH Notification Templates' },
            },
            {
              path: 'client-categories',
              component: () => import('../../client-categories/client-categories-index.vue'),
              meta: { title: 'Event Group Paths' },
            },
            {
              path: 'user-accesses',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  name: 'user-accesses',
                  component: () =>
                    import('../../event-organization-users/event-organization-users-index.vue'),
                  meta: { title: 'User Accesses' },
                },
                {
                  path: ':eouId',
                  component: () =>
                    import(
                      '../../event-organization-users/event-organization-user-customize-role.vue'
                    ),
                  meta: { title: 'Customize User Role' },
                },
              ],
            },
            {
              path: 'osl-tb-rules',
              component: () => import('../../osls/tb-rules/osl-tb-rules-index.vue'),
              meta: { title: 'Event OSL TB Rules' },
            },
            {
              path: 'osl-v-rules-matrix',
              component: () => import('../../osls/v-rules/matrix/osl-v-rules-matrix.vue'),
              meta: { title: 'Event OSL V Rules - Matrix' },
            },
            {
              path: 'osl-d-rules-matrix',
              component: () => import('../../osls/d-rules/matrix/osl-d-rules-matrix.vue'),
              meta: { title: 'Event OSL D Rules - Matrix' },
            },
            {
              path: 'travel-time-modifiers',
              component: () =>
                import('../../travel-time-modifiers/travel-time-modifiers-index.vue'),
              meta: { title: 'Event travel time modifiers' },
            },
            {
              path: 'driver-sources',
              component: () => import('../../driver-sources/driver-sources-index.vue'),
              meta: { title: 'Event driver sources' },
            },
            {
              path: 'drivers',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../drivers/drivers-index.vue'),
                  meta: { title: 'Event drivers' },
                },
              ],
            },
            {
              path: 'locations',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../locations/locations-index.vue'),
                  meta: { title: 'Event locations' },
                },
              ],
            },
            {
              path: 'transportation-points',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () =>
                    import('../../transportation-points/transportation-points-index.vue'),
                  meta: { title: 'Event transportation points' },
                },
              ],
            },
            {
              path: 'transport-buffers',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../transport-buffers/transport-buffers-index.vue'),
                  meta: { title: 'Event transport buffers' },
                },
              ],
            },
            {
              path: 'staging-areas',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../staging-areas/staging-areas-index.vue'),
                  meta: { title: 'Event staging areas' },
                },
              ],
            },
            {
              path: 'depots',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../depots/depots-index.vue'),
                  meta: { title: 'Depots' },
                },
              ],
            },
            {
              path: 'client-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../client-imports/client-import-index.vue'),
                  meta: { title: 'Event client imports' },
                },
                {
                  path: ':clientImportId',
                  component: () => import('../../client-imports/client-import-show.vue'),
                  meta: { title: 'Client import details' },
                },
              ],
            },
            {
              path: 'driver-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../driver-imports/drivers-imports-index.vue'),
                  meta: { title: 'Event driver imports' },
                },
                {
                  path: ':driverImportId',
                  component: () => import('../../driver-imports/driver-import-show.vue'),
                  meta: { title: 'Driver import details' },
                },
              ],
            },
            {
              path: 'client-group-activity-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () =>
                    import(
                      '../../client-group-activity-imports/client-group-activity-import-index.vue'
                    ),
                  meta: { title: 'Client Group Activities imports' },
                },
                {
                  path: ':clientGroupActivityImportId',
                  component: () =>
                    import(
                      '../../client-group-activity-imports/client-group-activity-import-show.vue'
                    ),
                  meta: { title: 'Client Group Activity import details' },
                },
              ],
            },
            {
              path: 'transport-booking-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () =>
                    import('../../transport-booking-imports/transport-booking-import-index.vue'),
                  meta: { title: 'Transport booking imports' },
                },
                {
                  path: ':transportBookingImportId',
                  component: () =>
                    import('../../transport-booking-imports/transport-booking-import-show.vue'),
                  meta: { title: 'Transport booking import details' },
                },
              ],
            },
            {
              path: 'shuttle-service-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () =>
                    import('../../shuttle-service-imports/shuttle-service-import-index.vue'),
                  meta: { title: 'Shuttle Service imports' },
                },
                {
                  path: ':shuttleServiceImportId',
                  component: () =>
                    import('../../shuttle-service-imports/shuttle-service-import-show.vue'),
                  meta: { title: 'Shuttle Service import details' },
                },
              ],
            },
            {
              path: 'transportation-point-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () =>
                    import(
                      '../../transportation-point-imports/transportation-point-import-index.vue'
                    ),
                  meta: { title: 'Transportation points imports' },
                },
                {
                  path: ':transportationPointImportId',
                  component: () =>
                    import(
                      '../../transportation-point-imports/transportation-point-import-show.vue'
                    ),
                  meta: { title: 'Transportation points import details' },
                },
              ],
            },
            {
              path: 'user-access-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () =>
                    import(
                      '../../event-organization-user-imports/event-organization-user-import-index.vue'
                    ),
                  meta: { title: 'User Accesses imports' },
                },
                {
                  path: ':eventOrganizationUserImportId',
                  component: () =>
                    import(
                      '../../event-organization-user-imports/event-organization-user-import-show.vue'
                    ),
                  meta: { title: 'User Accesses import details' },
                },
              ],
            },
            {
              path: 'minimum-vehicle-allotment',
              component: () =>
                import(
                  './../../calendar-entries/staging-area-calendar-entries/minimum-vehicle-allotment-index.vue'
                ),
              meta: { title: 'Event minimum vehicle allotment' },
            },
            {
              path: 'welcome-desk-working-hours',
              component: () =>
                import(
                  './../../calendar-entries/staging-area-calendar-entries/welcome-desk-working-hours-index.vue'
                ),
              meta: { title: 'Event welcome desk working hours' },
            },
            {
              path: 'subfleet',
              component: () =>
                import('../../calendar-entries/staging-area-calendar-entries/subfleet-index.vue'),
              meta: { title: 'Event subfleet' },
            },
            {
              path: 'driver-availabilities',
              component: () =>
                import(
                  '../../calendar-entries/driver-calendar-entries/driver-availabilities-index.vue'
                ),
              meta: { title: 'Driver availability' },
            },
            {
              path: 'driver-unavailabilities',
              component: () =>
                import(
                  './../../calendar-entries/driver-calendar-entries/driver-unavailabilities-index.vue'
                ),
              meta: { title: 'Driver unavailability' },
            },
            {
              path: 'occasions',
              component: () =>
                import('../../calendar-entries/cluster-calendar-entries/occasions-index.vue'),
              meta: { title: 'Event occasions' },
            },
            {
              path: 'operational-periods',
              component: () =>
                import(
                  '../../calendar-entries/cluster-calendar-entries/operational-periods-index.vue'
                ),
              meta: { title: 'Event operational periods' },
            },
            {
              path: 'calendar-view',
              component: () => import('../../operational-modules/calendar-index.vue'),
              meta: { title: 'Event calendar View' },
            },
            {
              path: 'vehicle-imports',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../vehicle-imports/vehicle-imports-index.vue'),
                  meta: { title: 'Event vehicle imports' },
                },
                {
                  path: ':vehicleImportId',
                  component: () => import('../../vehicle-imports/vehicle-import-show.vue'),
                  meta: { title: 'Vehicle import details' },
                },
              ],
            },
            {
              path: 'transport-bookings',
              component: () => import('../../transport-bookings/transport-bookings-index.vue'),
              meta: { title: 'Event transport bookings' },
            },
            {
              path: 'batch-onsite-requests',
              component: () =>
                import('../../batch-onsite-requests/batch-onsite-requests-index.vue'),
              meta: { title: 'Event batch on-site requests' },
            },
            {
              path: 'transport-booking-grouping-rules',
              component: () =>
                import(
                  './../../transport-booking-grouping-rules/transport-booking-grouping-rules-index.vue'
                ),
              meta: { title: 'Event TB grouping rules' },
            },
            {
              path: 'client-communication-rules',
              component: () =>
                import('../../client-communication-rules/client-communication-rules-index.vue'),
              meta: { title: 'Event Client Communication Rules' },
            },
            {
              path: 'client-communication-messages',
              component: () =>
                import(
                  '../../client-communication-messages/client-communication-messages-index.vue'
                ),
              meta: { title: 'Event Client Communication Messages' },
            },
            {
              path: 'vehicles',
              component: () => import('../../vehicles/vehicles-index.vue'),
              meta: { title: 'Event vehicles' },
            },
            {
              path: 'vehicles',
              component: () => import('../../vehicles/vehicles-index.vue'),
              meta: { title: 'Event vehicles' },
            },
            {
              path: 'driver-registration-qr-tokens',
              component: () =>
                import(
                  '../../driver-registration-qr-tokens/driver-registration-qr-token-index.vue'
                ),
              meta: { title: 'Event Driver registration QR tokens' },
            },
            {
              path: 'driver-registration-requests',
              component: () =>
                import('../../driver-registration-requests/driver-registration-request-index.vue'),
              meta: { title: 'Event Driver registration requests' },
            },
            {
              path: 'tracker-devices',
              component: () => import('../../tracker-devices/tracker-devices-index.vue'),
              meta: { title: 'Event tracker devices' },
            },
            {
              path: 'tracking-services',
              component: () => import('../../tracking-services/tracking-services-index.vue'),
              meta: { title: 'Event tracking services' },
            },
            {
              path: 'vehicle-sources',
              component: () => import('../../vehicle-sources/vehicle-sources-index.vue'),
              meta: { title: 'Event vehicle sources' },
            },
            {
              path: 'vehicle-source-pricings',
              component: () =>
                import('../../vehicle-source-pricings/vehicle-source-pricings-index.vue'),
              meta: { title: 'Event vehicle source pricing' },
            },
            {
              path: 'clusters',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../clusters/clusters-index.vue'),
                  meta: { title: 'Event clusters' },
                },
                {
                  path: ':clusterSlug',
                  component: AbstractRoute,
                  children: [
                    {
                      path: 'dashboard',
                      component: () => import('../../clusters/cluster-dashboard.vue'),
                      meta: { title: 'Cluster dashboard' },
                    },
                    {
                      path: 'vehicles',
                      component: () => import('../../vehicles/vehicles-index.vue'),
                      meta: { title: 'Cluster vehicles' },
                    },
                    {
                      path: 'drivers',
                      component: () => import('../../drivers/drivers-index.vue'),
                      meta: { title: 'Cluster drivers' },
                    },
                  ],
                },
              ],
            },
            {
              path: 'transfer-groups',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../transfer-groups/transfer-groups-index.vue'),
                  meta: { title: 'Event transfer groups' },
                },
              ],
            },
            {
              path: 'shift-jobs',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../shift-jobs/shift-jobs-index.vue'),
                  meta: { title: 'Event shift jobs' },
                },
              ],
            },
            {
              path: 'all-demands',
              component: AbstractRoute,
              children: [
                {
                  path: '',
                  component: () => import('../../all-demands/all-demands-index.vue'),
                  meta: { title: 'All demands' },
                },
              ],
            },
            {
              path: 'client-group-activities',
              component: () =>
                import('../../client-group-activities/client-group-activities-index.vue'),
              meta: { title: 'Client Group Activities' },
            },
            {
              path: 'shuttle-transfers',
              component: () => import('../../shuttle-transfers/shuttle-transfers-index.vue'),
              meta: { title: 'Shuttle transfers' },
            },
            {
              path: 'shuttle-services',
              component: () => import('../../shuttle-services/shuttle-services-index.vue'),
              meta: { title: 'Shuttle services' },
            },
            {
              path: 'shifts',
              component: () => import('../../shifts/shifts-index.vue'),
              meta: { title: 'Event shifts' },
            },
            {
              path: 'system-notifications',
              component: () => import('../../system-notifications/system-notifications-index.vue'),
              meta: { title: 'System Notifications' },
            },
          ],
        },
      ],
    },
  ]
}
