<script>
import { VehicleType } from 'frontend/vehicle-types'

export default {
  name: 'ClientGroupActivityIsCustomWithEndTime',
  props: {
    item: { type: Object, required: true },
  },
  components: {
    VehicleType,
  },
}
</script>

<template lang="pug">
span.pe-2
  true-false-label(:item="item.isCustom")
formatted-time(
  v-if="item.isCustom"
  :t="item.endAt"
  :tz="item.endLocation?.timeZone"
  format="timeOnly"
)
</template>

<style scoped lang="scss"></style>
