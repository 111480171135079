<script>
import consumer from 'channels/consumer'
import { organizationModuleGuard } from 'frontend/common/module-access-guard'
import { useSignOut } from 'frontend/common/sign-out'
import { useResourcePaginatedList } from 'frontend/common/useResourcePaginatedList'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import { ref } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutPassengerHeader',
  setup(props) {
    const blocker = ref(false)
    const isCableConnected = ref(!consumer.connection.disconnected)
    return {
      ...useSignOut(blocker),
      isCableConnected,
      ...useResourcePaginatedList({
        path: `events/index`,
        dataModelType: DataModelType.Event,
        defaultSort: `lower_name asc`,
        prefilters: props?.prefilters || {},
        verboseSubscriptions: true,
        instantDelete: true,
        checkAndInjectOnCreate: true,
        disableCustomizableColumns: true,
        fields: [
          'id',
          'name',
          'slug',
          'created_at',
          'updated_at',
          'smtp_configured',
          'sms_configured',
          'logo_urls',
          'start_date',
          'end_date',
          'archived_at',
        ],
        subscriptions: {
          items: 'EventsChannel',
        },
      }),
    }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent', 'currentCluster']),
    ...mapGetters('session', ['currentUser', 'onlyOnePassengerEvent']),
    currentOrganization() {
      if (!this.currentUser?.currentOrganizationId) {
        return {}
      } else {
        return (this.currentUser?.organizations || []).find(
          o => o.id == this.currentUser.currentOrganizationId,
        )
      }
    },
    canAccessMultipleModules() {
      const guard = organizationModuleGuard(
        null,
        this.currentUser?.impersonatedCurrentOrganizationUser ||
          this.currentUser?.currentOrganizationUser,
      )
      return guard.hasMultipleModulesAccess
    },
    userDropdownActions() {
      const isAdmin = this.currentUser.currentOrganizationUser?.isAdmin
      const isSuperuser = this.currentUser.isSuperuser
      const result = [{ title: this.currentUser.email, type: 'header' }]

      if (this.hasMoreEvents) {
        result.push({ title: 'Change Event', href: '/passenger-portal/events' })
      }
      if (this.canAccessMultipleModules) {
        result.push({ title: 'Back to TMM Hub', href: '/tmm-hub' })
      }
      if (isAdmin) {
        result.push({ title: 'Administration', href: '/admin', target: '_blank' })
      }
      if (isSuperuser) {
        result.push({ title: 'Super User Panel', href: '/su', target: '_blank' })
      }

      if ((this.currentUser.organizations?.length || 0) > 1) {
        result.push({ type: 'divider' })
        result.push({ type: 'header', title: 'Click item to change your context' })
        ;(this.currentUser.organizations || []).forEach(o => {
          result.push({ title: o.name, clickFn: () => this.chooseOrganization(o) })
        })
      }
      result.push({ type: 'divider' })
      result.push({ title: 'Sign out', clickFn: () => this.doSignOut() })
      return result
    },
    hasMoreEvents() {
      return this.list.result?.items.length > 1
    },
  },
  created() {
    this.page = 1
    this.perPage = 10000
    this.fetchItems()
  },
  mounted() {
    this.$eventHub.$on('cable-connection-check', this.updateCableConnectionStatus)
  },
  unmounted() {
    this.$eventHub.$off('cable-connection-check', this.updateCableConnectionStatus)
  },
  methods: {
    chooseOrganization(organization) {
      this.blocker = true
      this.axios({
        method: 'post',
        url: 'session/set_context',
        data: {
          organization_id: organization.id,
        },
      })
        .then(response => {
          this.blocker = false
          this.$store.commit('session/setUser', response.data)
          this.$router.push('/')
          this.$success({
            message: `The context has been successfully set to "${organization.name}"`,
          })
        })
        .catch(() => {
          this.$error({
            message: `Can't change context to "${organization.name}"`,
          })
        })
    },
    updateCableConnectionStatus() {
      this.isCableConnected = !consumer.connection.disconnected
    },
    doSignOut() {
      this.signOut().then(() => {
        this.$router.push('/')
      })
    },
  },
}
</script>

<template lang="pug">
.layout-passenger-controls
  dropdown(v-if="!!currentOrganization" :actions="userDropdownActions" alignRight)
    .dropdown-toggle.btn.btn-sm.btn-primary.px-0
      i.bi-person-fill.pe-2
      span MY ACCOUNT

    passenger-round-button.btn-primary
      i.bi-person-fill
</template>
