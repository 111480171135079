import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function useDemandSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }

  const section = computed(() => {
    const result = {
      name: 'Demand',
      items: [
        {
          title: 'Transport Bookings',
          isDisabled: false,
          path: 'transport-bookings',
          icon: 'bi-bookmarks',
        },
        {
          title: 'Batch On-Site Requests',
          isDisabled: false,
          path: 'batch-onsite-requests',
          icon: 'bi-bookmarks',
        },
        {
          title: 'All demands',
          isDisabled: false,
          path: 'all-demands',
          icon: 'bi-clipboard',
        },
        {
          title: 'Shift Jobs',
          isDisabled: false,
          path: 'shift-jobs',
          icon: 'bi-clipboard',
        },
        {
          title: 'Transfer Groups',
          isDisabled: false,
          path: 'transfer-groups',
          icon: 'bi-grid-3x3-gap',
        },
        {
          title: 'Client Group Activities',
          isDisabled: false,
          path: 'client-group-activities',
          icon: 'fas fa-user-tag',
        },
        {
          title: 'Shuttle services',
          isDisabled: false,
          path: 'shuttle-services',
          icon: 'fas fa-bus-alt',
        },
        {
          title: 'Shuttle transfers',
          isDisabled: false,
          path: 'shuttle-transfers',
          icon: 'fas fa-bus-alt',
        },
      ],
    }
    result.items = result.items.filter(el => hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
