import axios from 'frontend/_config/axios'
import store from 'frontend/_stores'
import { POST, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class UsersService extends ResourceService {
  constructor({
    baseUrl = `/users`,
    blocker,
    fetchError = 'Failed to fetch user.',
    saveError = 'Failed to save user.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'users',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        sorts: 'username asc',
        fields: ['id', 'name', 'surname', 'email'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }

  dispatchAction(
    action,
    id,
    { data = {}, url = `${this.baseUrl}${id ? '/' + id : ''}/${action}`, errorHandler = null } = {},
  ) {
    const passedErrorHandler =
      errorHandler ||
      (error => {
        const messagePayload = { message: 'Requested action cannot be run' }
        messagePayload.message = error.response?.data?.message || messagePayload.message

        if (error.response?.status == 422 && error.response?.data?.errors?.base?.length) {
          messagePayload.title = messagePayload.message
          messagePayload.message =
            error.response.data.errors.base.constructor == Array
              ? error.response.data.errors.base.join('; ')
              : error.response.data.errors.base
        } else if (error.response?.status == 422 && error.response?.data?.errors) {
          messagePayload.title = messagePayload.message
          messagePayload.message = this.prepareMessageFromErrors(error.response.data.errors)
        }

        store.dispatch('toast/error', messagePayload)
      })
    return this._makeCall(POST, passedErrorHandler, { url, data })
  }

  // get getForSelect() {
  //   return () =>
  //     this.selectService.fetchOptions({
  //       sorts: 'username asc',
  //       fields: ['id', 'name', 'surname', 'email'],
  //     })
  // }
}
