<script>
import { computed, inject } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'DataTableTools',
  props: {
    disableTitleAndFilters: { type: Boolean, default: false },
    title: {
      type: String,
    },
  },
  setup(props) {
    const inModal = inject('inModal')

    const shownTitle = computed(() => {
      if (inModal.value || props.title || props.title === null) {
        return props.title
      } else {
        return useRoute().meta?.title
      }
    })

    return {
      shownTitle,
    }
  },
  computed: {
    leftSideSlotsPresent() {
      return Object.keys(this.$slots).filter(slot => ['title', 'filters'].includes(slot))
    },
  },
}
</script>

<template lang="pug">
.data-table-tools(
  :class="shownTitle?.length || leftSideSlotsPresent ? 'justify-content-between' : 'justify-content-end'"
)
  .data-table-tools-title-container(
    v-if="(shownTitle?.length || leftSideSlotsPresent) && !disableTitleAndFilters"
  )
    h6.data-table-title
      slot(name="title") {{ shownTitle }}
    slot(name="filters")
  .data-table-tools-slot-container
    slot
</template>
