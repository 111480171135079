import { defineAsyncComponent } from 'vue'

const Cluster = defineAsyncComponent(() => import('./cluster.vue'))
const ClusterCalendarModal = defineAsyncComponent(() => import('./cluster-calendar-modal.vue'))
const ClusterDashboard = defineAsyncComponent(() => import('./cluster-dashboard.vue'))
const ClusterFormModal = defineAsyncComponent(() => import('./cluster-form-modal.vue'))
const ClustersTable = defineAsyncComponent(() => import('./clusters-table.vue'))
const ClustersSelect = defineAsyncComponent(() => import('./clusters-select.vue'))

export {
  Cluster,
  ClusterCalendarModal,
  ClusterDashboard,
  ClusterFormModal,
  ClustersSelect,
  ClustersTable,
}
