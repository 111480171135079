<script>
export default {
  name: 'DataTableTbody',
  props: {
    isLoading: { type: Boolean, default: false },
  },
}
</script>

<template lang="pug">
tbody.align-middle
  slot
    data-table-nothing-found(v-if="!isLoading")
</template>
