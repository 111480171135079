import { defineAsyncComponent } from 'vue'

const ClientFunctionRemark = defineAsyncComponent(() => import('./client-function-remark.vue'))
const ClientFunctionRemarkModal = defineAsyncComponent(() =>
  import('./client-function-remark-modal.vue'),
)
const ClientFunctionRemarksModal = defineAsyncComponent(() =>
  import('./client-function-remarks-modal.vue'),
)
const ClientFunctionRemarksSelect = defineAsyncComponent(() =>
  import('./client-function-remarks-select.vue'),
)
const ClientFunctionRemarksTable = defineAsyncComponent(() =>
  import('./client-function-remarks-table.vue'),
)

export {
  ClientFunctionRemark,
  ClientFunctionRemarkModal,
  ClientFunctionRemarksModal,
  ClientFunctionRemarksSelect,
  ClientFunctionRemarksTable,
}
