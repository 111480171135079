<script>
export default {
  name: 'DataTableVersionButton',
  emits: ['click'],
  props: {
    tooltip: { type: String, default: 'History' },
    iconSizeClass: { type: String, default: null },
  },
}
</script>

<template lang="pug">
button.datatable-history-btn.btn.btn-link.py-0.px-1(
  @click="$emit('click', $event)"
  type="button"
  v-tooltip.options="{ title: tooltip, placement: 'left' }"
)
  .hover-backdrop
  slot
    i.bi-clock-history(:class="iconSizeClass")
</template>
