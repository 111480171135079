import { createGlobalState } from '@vueuse/core'

import { setupContainer } from 'frontend/_setup/setupContainer/setupContainer'

export const useContainer = createGlobalState(() => {
  const container = setupContainer()

  return {
    container,
  }
})
