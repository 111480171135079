<script>
import TravelDurationPreviewPopoverContent from 'frontend/origin-x-destination-matrix/connection-duration-preview/_travel-duration-preview-popover-content.vue'

export default {
  name: 'ConnectionPreview',
  components: {
    TravelDurationPreviewPopoverContent,
  },
}
</script>

<template lang="pug">
.connection-preview.header-indicator.me-sm-3.me-2.d-none.d-md-inline-block
  ea-popover(customClass="wide-popover small-paddings" doNotRemoveContent placement="bottom")
    template(#default)
      i.fas.fa-route.text-primary
      .addon.bg-info.text-white(style="padding: 3px; bottom: -0.5rem")
        i.far.fa-clock
    template(#content)
      travel-duration-preview-popover-content
</template>
