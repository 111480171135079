import { KINDS } from 'frontend/client-communication-rules'
import DeliveryConditions from 'frontend/client-communication-rules/client-communication-rule-delivery-conditions.vue'
import { CRITERIA_CONFIG_FLAT } from 'frontend/client-communication-rules/criteria-config'
import FILTER_KINDS from 'frontend/common/ea-filter/_ea-filter-kind-data'
import DatatableQueryPreview from 'frontend/common/ea-query-preview/datatable-query-preview.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { EmailTemplate } from 'frontend/email-templates'

export const ClientCommunicationRuleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'kind',
    'resend_minutes_after_update',
    'resend_on_update',
    'send_minutes_before_start',
    'configured_languages',
    'priority',
    'exclusive',
    'archived_at',
    'criteria',
    'created_at',
    'updated_at',
    {
      email_template: ['id', 'name'],
    },
  ],
  subscriptions: {
    items: 'ClientCommunicationRulesChannel',
  },
  defaultColumns: [
    'eid',
    'name',
    'kind',
    'emailTemplate',
    'exclusive',
    'configuredLanguages',
    'deliveryConditions',
    'criteria',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    emailTemplate: {
      label: 'E-mail template',
      header: {
        sort: 'emailTemplateName',
      },
      cell: {
        kind: 'component',
        component: EmailTemplate,
        componentProps: item => ({ emailTemplate: item.emailTemplate }),
      },
    },
    kind: {
      label: 'Type',
      header: {},
      cell: {
        kind: 'function',
        fn: ccr => KINDS.find(el => el.value == ccr.kind)?.label,
      },
    },
    exclusive: {
      label: 'Exclusive',
      header: {
        sort: 'exclusive',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: el => ({
          item: el.exclusive,
        }),
      },
    },
    configuredLanguages: {
      label: 'Configured languages',
      header: {},
      cell: {
        kind: 'function',
        fn: ccr => (ccr.configuredLanguages || []).map(el => el.toUpperCase()).join(', '),
      },
    },
    deliveryConditions: {
      label: 'Delivery conditions',
      cell: {
        kind: 'component',
        component: DeliveryConditions,
        componentProps: el => ({
          clientCommunicationRule: el,
        }),
      },
    },
    criteria: {
      label: 'Criteria',
      cell: {
        kind: 'component',
        component: DatatableQueryPreview,
        componentProps: el => ({
          query: el.criteria,
          config: CRITERIA_CONFIG_FLAT,
          filterKinds: FILTER_KINDS,
        }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({ t: el.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({
          t: el.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({
          t: el.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({ t: el.updatedAt }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({
          t: el.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({
          t: el.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
