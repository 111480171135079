<script>
import { padStart } from 'lodash'

import Weekdays from './weekdays.vue'

export default {
  name: 'DurationRuleConfig',
  props: {
    rule: { type: Object, required: true },
    fromTimeZone: { type: String, required: true },
    toTimeZone: { type: String, required: true },
  },
  methods: {
    zeroPad(val) {
      return val != null && padStart(val.toString(), 2, '0')
    },
  },
  components: {
    Weekdays,
  },
}
</script>

<template lang="pug">
.row.flex-nowrap(v-if="rule.kind === 'recurring'")
  .col.small(v-if="rule.daysOfWeek")
    weekdays(:daysOfWeek="rule.daysOfWeek")
  .col.text-nowrap.text-end [{{ zeroPad(rule.startHour) }}:{{ zeroPad(rule.startMinute) }}] - [{{ zeroPad(rule.endHour) }}:{{ zeroPad(rule.endMinute) }}]

.d-flex.text-nowrap(v-else-if="rule.kind === 'specific'")
  div
    .small From:
    formatted-time(:t="rule.startAt" :tz="fromTimeZone")
  .ms-3
    .small To:
    formatted-time(:t="rule.endAt" :tz="toTimeZone")
.text-muted(v-else) All remaining
</template>
