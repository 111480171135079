import SessionLayout from '../../session/session-layout.vue'
import SessionPasswordRecovery from '../../session/session-password-recovery.vue'
import SessionSetPassword from '../../session/session-set-password.vue'
import SessionSignIn from '../../session/session-sign-in.vue'
import SessionSSOCallback from '../../session/session-sso-callback.vue'
import SessionSSOMobileCallback from '../../session/session-sso-mobile-callback.vue'
import { RouteName } from './types/RouteName'

export function setupSessionRoutes() {
  return [
    {
      path: '/session',
      name: RouteName.Session,
      component: SessionLayout,
      meta: { auth: 'opened' },
      children: [
        {
          path: 'sign-in',
          component: SessionSignIn,
          meta: {
            auth: 'opened',
            title: 'Sign in',
          },
        },
        {
          name: RouteName.PasswordRecovery,
          path: 'password-recovery',
          component: SessionPasswordRecovery,
          meta: {
            auth: 'opened',
            title: 'Password recovery',
          },
        },
        {
          path: 'set-password/:token',
          component: SessionSetPassword,
          meta: {
            auth: 'opened',
            title: 'Set password',
          },
        },
        {
          path: 'sso/:organizationId',
          component: SessionSSOCallback,
          meta: {
            auth: 'opened',
            title: 'Signing in...',
          },
        },
        {
          path: 'sso/mobile/:organizationId',
          component: SessionSSOMobileCallback,
          meta: {
            auth: 'opened',
            title: 'Signing in...',
          },
        },
        {
          name: RouteName.ChooseContext,
          path: 'choose-context',
          component: () => import('../../session/session-choose-context.vue'),
          meta: { title: 'Choose context' },
        },
      ],
    },
  ]
}
