import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import DatatableQueryPreview from 'frontend/common/ea-query-preview/datatable-query-preview.vue'

export const TransportBufferDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'priority',
    'name',
    'updated_at',
    'buffered_minutes',
    'criteria',
  ],
  subscriptions: {
    items: 'TransportBuffersChannel',
  },
  defaultColumns: [
    'priority',
    'eid',
    'name',
    'bufferedMinutes',
    'criteria',
    'updatedAt',
  ],
  columnConfig: {
    priority: {
      label: 'Priority',
      header: {
        sort: 'priority',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'priority',
      },
    },
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'eid',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'name',
      },
    },
    bufferedMinutes: {
      label: 'Minutes',
      header: {
        sort: 'bufferedMinutes',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'bufferedMinutes',
      },
    },
    criteria: {
      label: 'Criteria',
      cell: {
        kind: 'component',
        component: DatatableQueryPreview,
        componentProps: buffer => ({
                                     query: buffer.criteria,
                                     readonly: true,
                                     // scale: 0.8,
                                   }),
      },
    },
    updatedAt: {
      label: 'Updated At',
      header: {},
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
                                                  t: clientGroupActivity.updatedAt,
                                                }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {},
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
                                                  t: clientGroupActivity.updatedAt,
                                                  format: 'dateOnly',
                                                }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {},
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
                                                  t: clientGroupActivity.updatedAt,
                                                  format: 'timeOnly',
                                                }),
      },
    },
  }
}