<script>
import Collapsible from 'frontend/common/collapsible.vue'

export function useTomTomDirectionOptions() {
  const routeTypeOptions = [
    { label: 'Fastest (default)', value: 'fastest' },
    { label: 'Shortest', value: 'shortest' },
    { label: 'Short', value: 'short' },
    { label: 'Eco', value: 'eco' },
    { label: 'Thrilling', value: 'thrilling' },
  ]

  const routeTypesMap = routeTypeOptions.reduce(
    (result, next) => ({ ...result, [next.value]: next.label }),
    {},
  )

  const avoidOptions = [
    { label: 'Motorways', value: 'motorways' },
    { label: 'Tolls', value: 'tollRoads' },
    { label: 'Ferries', value: 'ferries' },
    { label: 'Unpaved roads', value: 'unpavedRoads' },
    { label: 'Carpools', value: 'carpools' },
    { label: 'Already used roads', value: 'alreadyUsedRoads' },
  ]

  const avoidsMap = avoidOptions.reduce(
    (result, next) => ({ ...result, [next.value]: next.label }),
    {},
  )

  return {
    routeTypeOptions,
    routeTypesMap,
    avoidOptions,
    avoidsMap,
  }
}

export default {
  name: 'TomTomDirectionsConfigSubform',
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Object, default: () => ({}) },
    validators: { type: Object, default: () => ({}) },
    serverErrors: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showDetails: false,
    }
  },
  setup() {
    const { routeTypeOptions, avoidOptions } = useTomTomDirectionOptions()
    return { routeTypeOptions, avoidOptions }
  },
  computed: {
    rangeDescription() {
      const preference = this.modelValue?.rangePreference ?? 50
      if (preference <= 20) {
        return 'fast-moving traffic'
      } else if (preference <= 40) {
        return 'light traffic'
      } else if (preference <= 60) {
        return 'moderate traffic'
      } else if (preference <= 80) {
        return 'heavy traffic'
      } else {
        return 'peak traffic'
      }
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', { ...this.modelValue, ...value })
    },
  },
  components: {
    Collapsible,
  },
}
</script>

<template lang="pug">
.row.mt-2
  .col-md-6.col-sm-12
    .mt-4.pt-1
      ea-switch(
        :additional-errors="serverErrors?.traffic"
        :modelValue="modelValue?.traffic"
        :validators="validators?.traffic"
        @update:modelValue="val => updateValue({ traffic: val })"
        label="Use traffic prediction"
        name="tomTom.traffic"
      )
  .col-md-6.col-sm-12
    .form-label.time-range-preference Traffic prediction modifier:
      i.text-muted.ms-1.fa.fa-info-circle(
        v-tooltip.options="{ title: 'Travel time prediction comes as a range between fastest and slowest travel time. Choose how to calculate the resulting time.<br><br>Example:<br>Predicted travel time: <b>from 24 to 36</b> minutes. <br> Selected time range preference: <b>25%</b> <br><br> Calculation: 24 + (36 - 24) * 25% = 27 <br> <b>Resulting time: 27 minutes.</b>', html: true, customClass: 'time-range-info-tooltip' }"
      )
    .d-flex.align-items-center.position-relative
      input.form-range.mb-2(
        :value="modelValue?.rangePreference ?? 50"
        @change="updateValue({ rangePreference: $event.target.value })"
        max="200"
        min="0"
        step="5"
        type="range"
      )
      .time-range-preference-value.position-absolute.start-50
        span {{ modelValue?.rangePreference ?? 50 }}% ({{ rangeDescription }})
.row.mt-2
  .col-md-6.col-sm-12
    ea-select(
      :additional-errors="serverErrors?.routeType"
      :modelValue="modelValue?.routeType"
      :options="routeTypeOptions"
      :reduce="item => item.value"
      :validators="validators?.routeType"
      @update:modelValue="val => updateValue({ routeType: val })"
      label="Route type:"
      name="tomTom.routeType"
      placeholder="Fastest (default)"
    )
  .col-md-6.col-sm-12
    ea-select(
      :additional-errors="serverErrors?.avoid"
      :modelValue="modelValue?.avoid"
      :options="avoidOptions"
      :reduce="item => item.value"
      :validators="validators?.avoid"
      @update:modelValue="val => updateValue({ avoid: val })"
      label="Avoid:"
      multiple
      name="durationRule.startHour"
    )
.row
  .col
    a(@click="showDetails = !showDetails")
      span {{ showDetails ? 'Hide details' : 'Show route type details' }}
  collapsible(:expand="showDetails")
    h5 Route type:
    p
      | Specifies the type of optimization used when calculating routes.
      |
      | The available values for this parameter are:

    ul
      li
        code(dir="ltr" translate="no") Fastest
        |
        | -
        | (default) optimized by travel time, while keeping the routes sensible.
        | For example, the calculation may avoid shortcuts along inconvenient side roads or long detours that only save very little time.
        |
      li
        code(dir="ltr" translate="no") Shortest
        |
        | -
        | optimized by travel distance, while keeping the routes sensible. For example, straight routes are preferred over those incurring turns.
      li
        code(dir="ltr" translate="no") Short
        |
        | -
        | optimized such that a good compromise between small travel time and short travel distance is achieved.
      li
        code(dir="ltr" translate="no") Eco
        |
        | -
        | optimized such that a good compromise between small travel time and low fuel or energy consumption is achieved.
      li
        code(dir="ltr" translate="no") Thrilling
        |
        | -
        | optimized such that routes include interesting or challenging roads and use as few motorways as possible.
</template>
