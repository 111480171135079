<script>
import { uniq } from 'lodash'

export default {
  name: 'CollapsibleItems',
  props: {
    items: { type: Object, default: () => ({}) },
    isHidden: { type: Boolean, default: false },
    placeholderText: { type: String, default: '' },
    allowWrappingItem: { type: Boolean, default: false },
    buttonBottom: { type: Boolean, default: false },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    itemsStyle() {
      if (this.isExpanded) {
        return {
          maxWidth: '80vw',
          whiteSpace: 'normal',
          lineHeight: 1.5,
        }
      } else {
        return null
      }
    },
    itemsList() {
      let items
      const sortedItems = uniq(this.items).sort()

      if (!this.isExpanded) {
        items = sortedItems.slice(0, 3)
        if (sortedItems.length > 3) {
          items.push('...')
        }
      } else {
        items = sortedItems
      }
      return items
    },
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>

<template lang="pug">
.d-inline-flex(
  v-if="!isHidden"
  :class="buttonBottom ? 'flex-column align-items-start' : 'align-items-center'"
)
  .d-inline-block.vertical-align-middle(:style="itemsStyle")
    template(v-if="allowWrappingItem") {{ itemsList.join(', ') }}
    template(v-else)
      template(v-for="(item, index) in itemsList")
        span.text-nowrap {{ item }}
        span(v-if="index + 1 != itemsList.length") {{ `, ` }}

  button.btn.btn-sm.btn-default.ps-1.pe-1(v-if="items?.length > 3" @click="toggleExpand()")
    span(v-if="!isExpanded") EXPAND
    span(v-else) COLLAPSE

  span.text-nowrap.text-black-30(v-if="!itemsList.length") {{ placeholderText }}
</template>
