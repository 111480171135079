import { defineAsyncComponent } from 'vue'

const DriverImportFormModal = defineAsyncComponent(() => import('./driver-import-form-modal.vue'))
const DriverImportShow = defineAsyncComponent(() => import('./driver-import-show.vue'))
const DriversImportsIndex = defineAsyncComponent(() => import('./drivers-imports-index.vue'))
const DriversImportsTable = defineAsyncComponent(() => import('./drivers-imports-table.vue'))

const STATES = [
  { value: 'idle', label: 'Idle' },
  { value: 'rows_loading', label: 'Loading rows' },
  { value: 'rows_not_loaded', label: 'Error while loading rows' },
  { value: 'rows_loaded', label: 'Rows loaded, waiting for the import' },
  { value: 'in_progress', label: 'Import in progress' },
  { value: 'finished', label: 'Import finished' },
  { value: 'finished_with_errors', label: 'Import finished (with errors)' },
  { value: 'major_error', label: 'Major error, cannot continue' },
]

export { DriverImportFormModal, DriverImportShow, DriversImportsIndex, DriversImportsTable, STATES }
