<script>
import { LANGUAGES } from './index'

export default {
  name: 'DriverLanguages',
  props: {
    driver: { type: Object, default: () => ({}) },
  },
  computed: {
    languages() {
      if (!this.driver.languages?.length) {
        return ''
      }
      return LANGUAGES.filter(l => this.driver.languages.includes(l.value))
        .map(l => l.label)
        .join(', ')
    },
  },
}
</script>

<template lang="pug">
.text-nowrap.text-black-15(v-if="!languages?.length") empty
span(v-else) {{ languages }}
</template>
