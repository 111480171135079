import { defineAsyncComponent } from 'vue'

const VehicleCategoriesModal = defineAsyncComponent(() => import('./vehicle-categories-modal.vue'))
const VehicleCategoriesSelect = defineAsyncComponent(() =>
  import('./vehicle-categories-select.vue'),
)
const VehicleCategoriesTable = defineAsyncComponent(() => import('./vehicle-categories-table.vue'))
const VehicleCategory = defineAsyncComponent(() => import('./vehicle-category.vue'))
const VehicleCategoryModal = defineAsyncComponent(() => import('./vehicle-category-modal.vue'))

export {
  VehicleCategoriesModal,
  VehicleCategoriesSelect,
  VehicleCategoriesTable,
  VehicleCategory,
  VehicleCategoryModal,
}
