<script>
import { useModals } from 'frontend/common/modals'
import { Location } from 'frontend/locations'
import { TransportationPoint } from 'frontend/transportation-points'

export default {
  name: 'ShuttleServicePoint',
  props: {
    point: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ...useModals(),
    }
  },
  components: { Location, TransportationPoint },
}
</script>

<template lang="pug">
div(v-if="point.commonName?.length")
  span(v-if="!point.location") {{ point.commonName }}
  modal-button(v-else :item="point.location" :modal="previewModals.Location")
    a {{ point.commonName }}
template(v-else)
  span
    location(:location="point.location" noCluster)
  span.ms-1(v-if="point.transportationPoint")
    span (
    transportation-point(:transportationPoint="point.transportationPoint")
    span )
</template>
