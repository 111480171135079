<script>
export default {
  name: 'DataTableArchiveButton',
  emits: ['click'],
  props: {
    tooltip: { type: String, default: 'Archive' },
    iconSizeClass: { type: String, default: null },
  },
}
</script>

<template lang="pug">
.d-inline-flex
  button.btn.btn-link.py-0.px-1.ms-2(
    @click="$emit('click', $event)"
    type="button"
    v-tooltip.options="{ title: tooltip, placement: 'left' }"
  )
    i.fas.fa-archive.text-warning(:class="iconSizeClass")
</template>
