<script>
import { KINDS as RULE_KINDS } from 'frontend/client-communication-rules'

export default {
  name: 'EffectiveChannelDisplay',
  props: {
    clientCommunicationMessage: {
      type: Object,
      required: true,
    },
  },
  methods: {
    humanizedRuleKind(k) {
      return RULE_KINDS.find(el => el.value == k)?.label
    },
  },
}
</script>

<template lang="pug">
.d-flex.text-nowrap.align-items-baseline(v-if="clientCommunicationMessage.sentToRecipient")
  span.me-2 {{ humanizedRuleKind(clientCommunicationMessage.clientCommunicationRule.kind) }}:
  email(
    v-if="clientCommunicationMessage.clientCommunicationRule.kind == 'email'"
    :email="clientCommunicationMessage.sentToRecipient"
  )
  phone-number(
    v-if="clientCommunicationMessage.clientCommunicationRule.kind == 'sms'"
    :phoneNumber="clientCommunicationMessage.sentToRecipient"
  )
.d-flex(v-else)
  span.text-black-15 empty
</template>
<style lang="scss" scoped></style>
