<script>
import { getEventOrganizationUser } from 'frontend/common/access-helpers'
import PureInput from 'frontend/common/ea-form-controls/_pure-input.vue'
import { useMapProviders } from 'frontend/common/maps/map-providers'
import { EventOrganizationUsersService } from 'frontend/event-organization-users/event-organization-users-service'
import Fuse from 'fuse.js'
import latinize from 'latinize'
import { debounce, throttle } from 'lodash'
import { computed, ref, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import { mapGetters, useStore } from 'vuex'

import EventDashboardSection from './_event-dashboard-section.vue'
import { useCalendarEntriesSection } from './event-dashboard-item-sections/calendar-entries'
import { useDemandSection } from './event-dashboard-item-sections/demand'
import { useGlobalResourcesSection } from './event-dashboard-item-sections/global-resources'
import { useImportsSection } from './event-dashboard-item-sections/imports'
import { useOperationalModulesSection } from './event-dashboard-item-sections/operational-modules'
import { useOslsSection } from './event-dashboard-item-sections/osls'
import { usePlacesSection } from './event-dashboard-item-sections/places'
import { useResourcesSection } from './event-dashboard-item-sections/resources'
import { useSettingsSection } from './event-dashboard-item-sections/settings'

const NAVIGATION_EVENTS = new Set([
  'Down',
  'ArrowDown',
  'Up',
  'ArrowUp',
  'Right',
  'ArrowRight',
  'Left',
  'ArrowLeft',
  'Enter',
])

export default {
  name: 'EventDashboardItems',
  props: {
    inSpotlight: {
      type: Boolean,
      default: false,
    },
    isSpotlightOpen: {
      type: Boolean,
      default: false,
    },
    spotlightWidth: {
      type: Number,
      default: 0,
    },
    injectedSearchTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      blocker: false,
      searchTerm: '',
      activeItem: null,
      firstItem: null,
      itemMap: [],
      allowedSections: new Map(),
      debouncedUpdateQuery: debounce(this.updateQuery, 300),
      throttledBuildItemMap: throttle(this.buildItemMap, 100),
      throttledBuildAllowedSections: throttle(this.buildAllowedSections, 100),
    }
  },
  setup() {
    const filteredItems = ref(new Map())
    const $store = useStore()
    const slug = ref(useRoute().params.eventSlug)
    const eou = computed(() => {
      return getEventOrganizationUser($store.state?.session?.user, slug.value)
    })
    const service = new EventOrganizationUsersService()

    const { availableMapProviders } = useMapProviders()

    return {
      slug,
      eou,
      filteredItems,
      service,
      operationalModulesSection: useOperationalModulesSection(eou),
      demandSection: useDemandSection(eou),
      resourcesSection: useResourcesSection(eou),
      placesSection: usePlacesSection(eou),
      calendarEntriesSection: useCalendarEntriesSection(eou),
      oslsSection: useOslsSection(eou),
      settingsSection: useSettingsSection(eou, $store.state?.session?.user, availableMapProviders),
      importsSection: useImportsSection(eou),
      globalResourcesSection: useGlobalResourcesSection(eou),
    }
  },
  computed: {
    favoriteSection() {
      let items = []
      if (this.eou?.favorites?.length) {
        const allItems = this.preparedSections.map(el => el?.items).flat()
        items = allItems.filter(el => el?.path?.length && this.eou.favorites.includes(el.path))
      }
      if (items?.length) {
        return {
          name: 'Favorites',
          items: items.map(item => ({ ...item, id: Math.random().toString(36).substring(3) })),
        }
      } else {
        return null
      }
    },
    ...mapGetters('modals', ['modalCounter']),
    preparedSections() {
      const result = [
        this.operationalModulesSection,
        this.demandSection,
        this.resourcesSection,
        this.placesSection,
        this.calendarEntriesSection,
        this.oslsSection,
        this.settingsSection,
        this.importsSection,
        this.formsSection,
        this.globalResourcesSection,
      ]
        .filter(el => !!el?.name)
        .filter(el => !el?.isHidden)
        .map(section => {
          const items = section?.items.map(item => ({
            ...item,
            id: Math.random().toString(36).substring(3),
            acronym: item.title.match(/\b(\w)/gi).join(''),
          }))

          return {
            ...section,
            items,
            fuse: new Fuse(items, {
              keys: ['title', 'acronym'],
              threshold: 0.4,
              shouldSort: true,
              distance: 80,
              includeScore: true,
            }),
            normalizedName: latinize(section.name).toLowerCase(),
          }
        })
      return result
    },
    normalizedSearchTerm() {
      return latinize(this.searchTerm || '').toLowerCase()
    },
    displayUnfilteredResults() {
      return !this.inSpotlight || this.searchTerm?.length
    },
  },
  watch: {
    isSpotlightOpen: {
      handler: function (isSpotlightOpen) {
        if (isSpotlightOpen) {
          this.searchTerm = this.injectedSearchTerm || ''
        }
      },
      immediate: true,
    },
    normalizedSearchTerm: {
      handler: function () {
        this.throttledBuildAllowedSections()
      },
      immediate: true,
    },
    filteredItems() {
      this.throttledBuildItemMap()
    },
    '$route.query.searchTerm': {
      handler: function (searchTerm) {
        if (searchTerm && !this.isSpotlightOpen && !this.inSpotlight && !this.searchTerm) {
          this.searchTerm = searchTerm ?? ''
        }
      },
      immediate: true,
    },
    preparedSections: {
      handler() {
        this.throttledBuildItemMap()
        this.throttledBuildAllowedSections()
      },
      immediate: true,
    },
    favoriteSection: {
      handler() {
        this.throttledBuildItemMap()
        this.throttledBuildAllowedSections()
      },
      immediate: true,
    },
  },
  mounted() {
    this.$eventHub.$on('windowResize', this.throttledBuildItemMap)
    this.$eventHub.$on('keydown', this.calculateNextItem)
  },
  unmounted() {
    this.$eventHub.$off('keydown', this.calculateNextItem)
    this.$eventHub.$off('windowResize', this.throttledBuildItemMap)
    this.throttledBuildItemMap?.cancel()
    this.throttledBuildAllowedSections?.cancel()
  },
  methods: {
    async toggleFavorite(path) {
      const favorites = this.eou?.favorites || []
      const i = favorites.indexOf(path)
      if (i >= 0) {
        favorites.splice(i, 1)
      } else {
        favorites.push(path)
      }
      await this.service.dispatchAction('update_favorites', this.eou.id, {
        data: { favorites: favorites },
      })
    },
    buildAllowedSections() {
      let sections
      if (this.favoriteSection) {
        sections = [this.favoriteSection, ...this.preparedSections]
      } else {
        sections = [...this.preparedSections]
      }
      const sectionsMap = sections.reduce((result, section) => {
        const score = this.filterAllowsSection(section)
        if (score > 0) {
          result.set(toRaw(section), score)
        }
        return result
      }, new Map())
      this.allowedSections = new Map([...sectionsMap.entries()].sort((a, b) => a[1] - b[1]))
      // triggerRef(this.filteredItems) TODO: rewrite for watchEffect and use shallowRef and triggerRef
      this.filteredItems = new Map([...this.filteredItems.entries()])
    },
    buildItemMap() {
      let amountItemsInRow
      if (this.inSpotlight) {
        amountItemsInRow = this.getAmountOfRowItemsForSpotlight(this.spotlightWidth)
      } else {
        amountItemsInRow = this.getAmountOfRowItems(this.$refs.eventDashboardContainer?.clientWidth)
      }
      if (Number.isNaN(amountItemsInRow)) return
      if (amountItemsInRow === 0) amountItemsInRow = 1

      const searchItem = { position: [0, 0], item: { title: 'Search' } }
      this.activeItem = searchItem
      const itemMap = [searchItem]

      let sectionIndex = 0
      ;[...this.allowedSections.keys()].forEach(section => {
        const items = this.filteredItems?.get(section) ?? []

        if (items.length > amountItemsInRow) {
          const subRows = this.subRows(items, amountItemsInRow)
          for (const subRow of subRows) {
            sectionIndex++
            itemMap.push(
              ...subRow.map((item, index) => {
                return {
                  position: [sectionIndex, index],
                  item: item,
                }
              }),
            )
          }
        } else {
          sectionIndex++
          if (items.length) {
            itemMap.push(
              ...items.map((item, index) => {
                return {
                  position: [sectionIndex, index],
                  item: item,
                }
              }),
            )
          }
        }
      })

      this.itemMap = itemMap
      this.firstItem = this.itemMap[1]
    },
    subRows(itemsArg, amountItemsInRow) {
      const subRows = []
      for (let i = 0; i <= itemsArg.length; ) {
        subRows.push(itemsArg.slice(i, (i += amountItemsInRow)))
      }
      return subRows
    },
    calculateNextItem(event) {
      if (
        ((/input|textarea/i.test(event.target.tagName) ||
          event.target.attributes?.contenteditable) &&
          event.target !== this.$refs.searchInput?.$el) ||
        this.modalCounter > 0
      ) {
        return
      }

      if (event.key.match(/^[a-zA-Z]$/) || event.key === 'Backspace') {
        this.focusSearch()
        return
      }

      if (!NAVIGATION_EVENTS.has(event.key)) return

      if (this.inSpotlight && !this.isSpotlightOpen) {
        return
      }

      const currentItem = this.activeItem?.item?.id ? this.activeItem : this.firstItem

      if (currentItem) {
        let newActiveItem
        if (event.key !== 'Enter') {
          event.stopPropagation()
          event.preventDefault()
        }

        if (event.key === 'Down' || event.key === 'ArrowDown') {
          newActiveItem = this.findBelowItem(this.itemMap, currentItem)
        } else if (event.key === 'Up' || event.key === 'ArrowUp') {
          newActiveItem = this.findAboveItem(this.itemMap, currentItem)
        } else if (event.key === 'Right' || event.key === 'ArrowRight') {
          newActiveItem = this.findRightItem(this.itemMap, currentItem)
        } else if (event.key === 'Left' || event.key === 'ArrowLeft') {
          newActiveItem = this.findLeftItem(this.itemMap, currentItem)
        } else if (event.key === 'Enter') {
          if (currentItem === this.firstItem) {
            this.debouncedUpdateQuery?.cancel()
            const currentPath = this.$route.path.split('/')
            if ((currentPath?.length || 0) > 4) {
              const eventBasePath = currentPath
              eventBasePath.length = 3
              this.$router.push(`${eventBasePath.join('/')}/${this.firstItem.item.path}`)
            } else {
              this.$router.push(this.firstItem.item.path)
            }
            if (this.inSpotlight && this.displayUnfilteredResults) {
              this.$eventHub.$emit('close-spotlight')
            }
          }
        }

        if (newActiveItem) {
          this.activeItem = newActiveItem
        }
      }
    },
    getAmountOfRowItems(containerWidth) {
      const containerMargin = -5
      const itemPaddings = 24
      const itemWidth = 150
      return Math.floor(
        (containerWidth + itemPaddings + containerMargin) / (itemWidth + itemPaddings),
      )
    },
    getAmountOfRowItemsForSpotlight(spotlightWidth) {
      const spaceLeftAfterIncludingCSSMargins = 0.56
      const containerWidth = spotlightWidth * spaceLeftAfterIncludingCSSMargins
      const containerPaddings = 16
      const itemPaddings = 24
      const itemWidth = 120
      return Math.floor(
        (containerWidth - containerPaddings + itemPaddings) / (itemWidth + itemPaddings),
      )
    },
    isInFirstRow(item) {
      return item.position[0] === 1
    },
    isFirstRow(item) {
      return item.position[0] === 0
    },
    searchInput() {
      return this.itemMap.find(item => item.item.title == 'Search')
    },
    focusSearch() {
      this.$refs.searchInput?.focusInput()
    },
    findBelowItem(itemMap, activeItem) {
      let lastItemInBelowRow
      const belowRowIndex = itemMap
        .filter(item => {
          return item.position[0] > activeItem?.position[0]
        })
        .sort((a, b) => a.position[0] - b.position[0])[0]?.position[0]
      if (!belowRowIndex) {
        return null
      }
      const directlyBelow = itemMap.find(item => {
        return item.position[0] == belowRowIndex && item.position[1] == activeItem?.position[1]
      })
      if (!directlyBelow) {
        const belowRowItems = itemMap.filter(item => {
          return item.position[0] == belowRowIndex
        })
        lastItemInBelowRow = belowRowItems.sort((a, b) => a.position[1] - b.position[1])[
          belowRowItems.length - 1
        ]
      }
      return directlyBelow || lastItemInBelowRow
    },
    findAboveItem(itemMap, activeItem) {
      let lastItemInAboveRow
      const aboveRowIndex = itemMap
        .filter(item => {
          return item.position[0] < activeItem?.position[0]
        })
        .sort((a, b) => b.position[0] - a.position[0])[0]?.position[0]
      if (aboveRowIndex == 0) {
        this.focusSearch()
        return this.searchInput()
      }
      const directlyAbove = itemMap.find(item => {
        return item.position[0] == aboveRowIndex && item.position[1] == activeItem?.position[1]
      })
      if (!directlyAbove) {
        const aboveRowItems = itemMap.filter(item => {
          return item.position[0] == aboveRowIndex
        })
        lastItemInAboveRow = aboveRowItems.sort((a, b) => a.position[1] - b.position[1])[
          aboveRowItems.length - 1
        ]
      }
      return directlyAbove || lastItemInAboveRow
    },
    findRightItem(itemMap, activeItem) {
      let firstInBelowRow
      const nextRightItem = itemMap.find(item => {
        return (
          item.position[0] == activeItem?.position[0] &&
          item.position[1] == activeItem?.position[1] + 1
        )
      })
      if (!nextRightItem) {
        firstInBelowRow = itemMap.find(item => {
          return item.position[0] == activeItem?.position[0] + 1 && item.position[1] == 0
        })
        const belowRowIndex = itemMap
          .filter(item => {
            return item.position[0] > activeItem?.position[0]
          })
          .sort((a, b) => a.position[0] - b.position[0])[0]?.position[0]
        if (belowRowIndex == 0) {
          this.focusSearch()
          return this.searchInput()
        }
        const belowRowItems = itemMap.filter(item => {
          return item.position[0] == belowRowIndex
        })
        firstInBelowRow = belowRowItems.sort((a, b) => b.position[1] - a.position[1])[
          belowRowItems.length - 1
        ]
      }
      return nextRightItem || firstInBelowRow
    },
    findLeftItem(itemMap, activeItem) {
      let lastItemInAboveRow
      const nextLeftItem = itemMap.find(item => {
        return (
          item.position[0] == activeItem?.position[0] &&
          item.position[1] == activeItem?.position[1] - 1
        )
      })
      if (!nextLeftItem) {
        const aboveRowIndex = itemMap
          .filter(item => {
            return item.position[0] < activeItem?.position[0]
          })
          .sort((a, b) => b.position[0] - a.position[0])[0]?.position[0]
        if (aboveRowIndex == 0) {
          this.focusSearch()
          return this.searchInput()
        }
        const aboveRowItems = itemMap.filter(item => {
          return item.position[0] == aboveRowIndex
        })
        lastItemInAboveRow = aboveRowItems.sort((a, b) => a.position[1] - b.position[1])[
          aboveRowItems.length - 1
        ]
      }
      return nextLeftItem || lastItemInAboveRow
    },
    filterAllowsSection(section) {
      const { normalizedName, fuse } = section

      if (!this.searchTerm?.length) {
        this.filteredItems.set(section, section.items)
        return 1
      }

      if (!normalizedName) {
        return 0
      }

      if (normalizedName.includes(this.normalizedSearchTerm)) {
        this.filteredItems.set(section, section.items)
        return 0.01
      }

      const filtered = fuse.search(this.normalizedSearchTerm)
      if (filtered.length) {
        this.filteredItems.set(
          section,
          filtered.map(({ item }) => item),
        )

        return Math.min(...filtered.map(item => item.score))
      }

      this.filteredItems.delete(section)
      return -1
    },
    filterItems({ normalizedName, items, fuse }) {
      if (!this.searchTerm?.length) {
        return items
      }

      if (normalizedName.includes(this.normalizedSearchTerm)) {
        return items
      }
      const i = fuse.search(this.normalizedSearchTerm).map(i => i.item)
      return i
    },
    updateQuery() {
      if (this.$route.name != 'event-dashboard') {
        return
      }
      if (this.searchTerm) {
        this.$router.replace({ query: { searchTerm: this.searchTerm } })
      } else {
        this.$router.replace({ query: {} })
      }
    },
  },
  components: {
    EventDashboardSection,
    PureInput,
  },
}
</script>

<template lang="pug">
.row.search-row
  .col-xl-8(:class="{ 'col-xl-12': inSpotlight }")
    .event-dashboard-search-wrapper(
      v-if="!inSpotlight || isSpotlightOpen"
      @click.stop="activeItem = searchInput()"
    )
      form(@submit.prevent autocomplete="off")
        pure-input(
          ref="searchInput"
          v-model="searchTerm"
          @update:modelValue="debouncedUpdateQuery"
          focusOnInit
          maxlength="100"
          name="searchTerm"
          placeholder="Search Modules"
        )
.event-dashboard-container(ref="eventDashboardContainer")
  template(v-if="inSpotlight && !searchTerm?.length && !!favoriteSection")
    event-dashboard-section(
      :activeItem="activeItem?.item"
      :favorites="eou?.favorites || []"
      :firstItem="firstItem?.item"
      :inSpotlight="true"
      :items="favoriteSection.items"
      :title="favoriteSection.name"
      @toggle-favorite="toggleFavorite($event)"
    )
  template(v-if="allowedSections?.size && displayUnfilteredResults")
    template(v-for="section in [...allowedSections.keys()]")
      event-dashboard-section(
        :activeItem="activeItem?.item"
        :favorites="eou?.favorites || []"
        :firstItem="firstItem?.item"
        :inSpotlight="inSpotlight"
        :items="filteredItems.get(section)"
        :title="section.name"
        @toggle-favorite="toggleFavorite($event)"
      )
</template>
