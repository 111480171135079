export function mergeColumnPresetData(...dataItems: Record<string, unknown>) {
  const targetObj = {}
  for(const dataItem of dataItems) {
    for(const [key, value] of Object.entries(dataItem)) {
      if(value) {
        targetObj[key] = value
      }
    }
  }
  return targetObj
}