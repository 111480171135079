<script>
import SidebarIcon from 'frontend/common/layout/sidebar-icon.vue'
import SidebarTextIcon from 'frontend/common/layout/sidebar-text-icon.vue'

export default {
  name: 'SidebarItem',
  inheritAttrs: false,
  props: {
    link: { type: String, required: true },
    title: { type: String, required: true },
    subscript: { type: String, default: null },
    iconClasses: { type: [String, Array], default: null },
    alaIconTitle: { type: String, default: null },
  },
  components: {
    SidebarTextIcon,
    SidebarIcon,
  },
}
</script>

<template lang="pug">
router-link(:to="link" custom v-slot="{ href, route, navigate, isActive, isExactActive }")
  li.nav-item(v-bind="$attrs")
    a.nav-link(
      :class="[isExactActive && !route.query.searchTerm ? 'active' : null]"
      :href="href"
      @click="navigate"
    )
      sidebar-text-icon(
        v-if="alaIconTitle"
        :title="alaIconTitle"
        v-tooltip.options="{ title: title, placement: 'right' }"
      )
      sidebar-icon(v-else)
        i(:class="iconClasses" v-tooltip.options="{ title: title, placement: 'right' }")
        template(v-if="subscript" #subicon) {{ subscript }}
      span(v-tooltip="title") {{ title }}
</template>
