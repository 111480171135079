import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class OslsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = eventSlug?.length
      ? `/events/${eventSlug}/operational_service_levels`
      : `/operational_service_levels`,
    blocker,
    fetchError = 'Failed to fetch OSLs.',
    saveError = 'Failed to save OSL.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'osls',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  getForSelect() {
    return (additionalPayload = {}) => {
      return this.selectService.fetchOptions({
        additionalPayload,
        sorts: 'position asc',
        fields: [
          'id',
          'name',
          'background_color',
          'border_color',
          'font_color',
          'description',
          'archived_at',
        ],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
    }
  }

  // get getForSelect() {
  //   return config =>
  //     this.selectService.fetchOptions({
  //       sorts: 'position asc',
  //       fields: ['id', 'name', 'background_color', 'border_color', 'font_color'],
  //       ...config,
  //     })
  // }
}
