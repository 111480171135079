<script setup lang="ts">
import type { Alignment, Placement } from '@floating-ui/vue'
import { autoPlacement, autoUpdate, flip, offset, shift, size, useFloating } from '@floating-ui/vue'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

interface Props {
  isTransparent?: boolean
  placement?: Placement
  isOpen?: boolean
  matchReferenceSize?: boolean
  offsetMainAxis?: number
  offsetCrossAxis?: number
  dropdownClass?: string
  alignment?: Alignment
  minimalAcceptableHeight?: number
  placementStrategy?: 'autoPlacement' | 'flip'
  allowedPlacements?: Placement[]
}

interface Emits {
  (e: 'hide'): void
  (e: 'toggle', value: boolean): void
}

const props = withDefaults(defineProps<Props>(), {
  isTransparent: false,
  placement: 'bottom',
  isOpen: null,
  matchReferenceSize: false,
  offsetMainAxis: 5,
  offsetCrossAxis: 0,
  dropdownClass: null,
  alignment: 'start',
  minimalAcceptableHeight: 50,
  placementStrategy: 'autoPlacement',
  allowedPlacements: null,
})
const emit = defineEmits<Emits>()

const dropdownRef = ref<HTMLElement>(null)
const referenceRef = ref<HTMLElement>(null)

const isOpen = ref<boolean>(props.isOpen)

watch(
  () => props.isOpen,
  value => {
    isOpen.value = value
  },
)

watch(
  () => isOpen.value,
  value => {
    emit('toggle', value)
  },
)

// onClickOutside($$(contextMenuRef), () => {
//   isOpen = false
//   emit('hide')
// })

// useContextMenuOutside($$(referenceRef), () => {
//   isOpen = false
//   emit('hide')
// })

function onCoverClick() {
  isOpen.value = false
  emit('hide')
}

function matchReferenceSizeFactory() {
  return size({
    apply({ rects }) {
      Object.assign(dropdownRef.value.style, {
        width: `${rects.reference.width}px`,
      })
    },
  })
}

const floatingHeight = ref(0)

function getPlacementMiddlewares() {
  return props.placementStrategy === 'autoPlacement'
    ? [
        autoPlacement({
          alignment: props.alignment,
          allowedPlacements: props.allowedPlacements ? props.allowedPlacements : undefined,
        }),
        size({
          apply({ availableHeight }) {
            floatingHeight.value = Math.max(props.minimalAcceptableHeight, availableHeight) - 24
          },
        }),
        shift({
          padding: 8,
        }),
      ]
    : [
        size({
          apply({ availableHeight }) {
            floatingHeight.value = Math.max(props.minimalAcceptableHeight, availableHeight) - 24
          },
        }),
        flip({
          fallbackStrategy: 'initialPlacement',
        }),
        shift({
          padding: 8,
        }),
      ]
}

let middlewares = [
  offset({
    mainAxis: props.offsetMainAxis,
    crossAxis: props.offsetCrossAxis,
  }),
  ...getPlacementMiddlewares(),
]

if (props.matchReferenceSize) {
  middlewares = [...middlewares, matchReferenceSizeFactory()]
}

const { floatingStyles, update } = useFloating(referenceRef, dropdownRef, {
  placement: props.placement,
  middleware: middlewares,
  whileElementsMounted: autoUpdate,
})

const dropdownStyle = computed(() => {
  return {
    maxHeight: `${floatingHeight.value}px`,
    // background: props.isTransparent ? 'transparent' : 'hsla(0,0%,20%,1)',
  }
})

function onOpenEvent() {
  if (typeof props.isOpen === 'boolean') {
    return
  }
  isOpen.value = true
}

onMounted(() => {
  window.addEventListener('resize', update)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', update)
})

const onReferenceClick = () => {
  // event?.preventDefault()
  onOpenEvent()
}

watch(referenceRef, (value, oldValue) => {
  oldValue?.removeEventListener('click', onReferenceClick)
  value?.addEventListener('click', onReferenceClick)
})

onBeforeUnmount(() => {
  if (referenceRef.value) {
    referenceRef.value.removeEventListener('click', onReferenceClick)
  }
})

function onClose() {
  isOpen.value = false
  emit('hide')
}

defineExpose({
  isOpen,
})
</script>

<template>
  <div ref="referenceRef" class="dropdown-wrapper">
    <slot :is-open="isOpen" />
    <Teleport to="#teleport-target">
      <div v-if="isOpen" class="cover" @click="onCoverClick" />
      <div
        v-if="isOpen"
        ref="dropdownRef"
        class="dropdown-area"
        :style="[floatingStyles, dropdownStyle]"
      >
        <div class="dropdown" :class="dropdownClass">
          <slot name="dropdown" :is-open="isOpen" :close="onClose" :max-height="floatingHeight" />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  background: white;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.33));
  border-radius: 8px;
  overflow: auto;
  min-height: 0;
  display: grid;
  pointer-events: all;
}

.dropdown-area {
  position: fixed;
  z-index: 2010;
  min-height: 0;
  display: grid;
  align-items: flex-start;
  pointer-events: none;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2010;
}
</style>
