<script>
import CriteriaValueTypeInputFactory from 'frontend/common/data-tables/search-criteria/factories/CriteriaValueTypeInputFactory.vue'
import { getCriteriaValueTypeByConfig } from 'frontend/common/data-tables/search-criteria/functions/getCriteriaValueTypeByConfig'
import FormLabel from 'frontend/common/ea-form-controls/_form-label.vue'
import { defineAsyncComponent, ref } from 'vue'

import { predicateMappings } from './_search-criterion-predicate-config.js'

export default {
  name: 'SearchCriterion',
  emits: ['update:modelValue'],
  props: {
    attribute: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Object,
    },
  },
  setup() {
    return {
      ccontCollection: [
        { value: 'valid_value', label: 'A valid OSL' },
        { value: 'no_service', label: 'No service' },
        { value: 'not_ready', label: 'Not ready' },
      ],
      predicateMappings,
      resourceOptions: ref([]),
      getCriteriaValueTypeByConfig,
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    searchKey(predicate) {
      return `${this.attribute}${this.predicateMappings[predicate].suffix}`
    },
    valueChanged(p, newVal) {
      this.localModelValue[this.searchKey(p)] = newVal
      this.$emit('update:modelValue', this.localModelValue)
    },
  },
  components: {
    FormLabel,
    CriteriaValueTypeInputFactory,
    DatetimeWithVariablesInput: defineAsyncComponent(() =>
      import('./datetime-with-variables-input.vue'),
    ),
    TaggableSelect: defineAsyncComponent(() =>
      import('frontend/common/selects/taggable-select.vue'),
    ),
    ResourceSelectInput: defineAsyncComponent(() => import('./resource-select-input.vue')),
  },
}
</script>

<template lang="pug">
.ea-filter-input-container(v-for="p in config.predicates")
  FormLabel(:label="predicateMappings[p].name")
  CriteriaValueTypeInputFactory(
    :criteriaValueType="getCriteriaValueTypeByConfig(config, p)"
    :criterion="config"
    :key="searchKey(p)"
    :modelValue="localModelValue[searchKey(p)]"
    :name="`filters.${searchKey(p)}`"
    :predicate="p"
    @update:modelValue="valueChanged(p, $event)"
  )
</template>
