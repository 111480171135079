<script>
export default {
  name: 'DriverSourceInclusiveShiftCheckInOut',
  props: {
    driverSource: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
true-false-label(
  v-if="[true, false].includes(driverSource.inclusiveShiftCheckInOut)"
  :item="driverSource.inclusiveShiftCheckInOut"
)
span.text-black-15.text-nowrap(v-else) default
</template>
