<script>
export default {
  name: 'TransportationPointEnabledStatuses',
  props: {
    transportationPoint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasAnyLoadTime() {
      return (
        this.transportationPoint?.arrivalLoadTime ||
        this.transportationPoint?.departureLoadTime ||
        this.transportationPoint?.onsiteLoadTime ||
        this.transportationPoint?.activityLoadTime ||
        this.transportationPoint?.shuttleLoadTime
      )
    },
  },
}
</script>

<template lang="pug">
.enabled-row.text-nowrap(:class="{ 'time-offset': hasAnyLoadTime }")
  .kind-col.flex-column.d-flex.align-items-center
    .small(v-if="transportationPoint.enabledForArrival && transportationPoint.arrivalLeadTime") {{ transportationPoint.arrivalLeadTime }}m
    .circle-frame(
      :class="{ enabled: transportationPoint.enabledForArrival }"
      v-tooltip="'Arrival'"
    )
      i.fas.fa-plane-arrival
    .small(v-if="transportationPoint.enabledForArrival && transportationPoint.arrivalLoadTime") {{ transportationPoint.arrivalLoadTime }}m
  .kind-col.flex-column.d-flex.align-items-center
    .small(v-if="transportationPoint.enabledForDeparture && transportationPoint.departureLeadTime") {{ transportationPoint.departureLeadTime }}m
    .circle-frame(
      :class="{ enabled: transportationPoint.enabledForDeparture }"
      v-tooltip="'Departure'"
    )
      i.fas.fa-plane-departure
    .small(v-if="transportationPoint.enabledForDeparture && transportationPoint.departureLoadTime") {{ transportationPoint.departureLoadTime }}m
  .kind-col.flex-column.d-flex.align-items-center
    .small(v-if="transportationPoint.enabledForOnsite && transportationPoint.onsiteLeadTime") {{ transportationPoint.onsiteLeadTime }}m
    .circle-frame(:class="{ enabled: transportationPoint.enabledForOnsite }" v-tooltip="'On-site'")
      i.fas.fa-car
    .small(v-if="transportationPoint.enabledForOnsite && transportationPoint.onsiteLoadTime") {{ transportationPoint.onsiteLoadTime }}m
  .kind-col.flex-column.d-flex.align-items-center
    .small(v-if="transportationPoint.enabledForActivity && transportationPoint.activityLeadTime") {{ transportationPoint.activityLeadTime }}m
    .circle-frame(
      :class="{ enabled: transportationPoint.enabledForActivity }"
      v-tooltip="'Group Activities'"
    )
      i.fas.fa-users
    .small(v-if="transportationPoint.enabledForActivity && transportationPoint.activityLoadTime") {{ transportationPoint.activityLoadTime }}m
  .kind-col.flex-column.d-flex.align-items-center
    .small(v-if="transportationPoint.enabledForShuttle && transportationPoint.shuttleLeadTime") {{ transportationPoint.shuttleLeadTime }}m
    .circle-frame(
      :class="{ enabled: transportationPoint.enabledForShuttle }"
      v-tooltip="'Shuttle'"
    )
      i.fas.fa-bus-alt
    .small(v-if="transportationPoint.enabledForShuttle && transportationPoint.shuttleLoadTime") {{ transportationPoint.shuttleLoadTime }}m
</template>

<style>
.time-offset .circle-frame:last-child {
  margin-bottom: 1em;
}
</style>
