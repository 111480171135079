import { RouteName } from './types/RouteName'

export function setupExternalRoutes() {
  return [
    {
      path: '/driver-register/:token',
      component: () => import('./../../session/driver-register/driver-register.vue'),
      name: RouteName.DriverRegisterToken,
      meta: {
        auth: 'opened',
        title: 'Driver Register',
      },
    },
    {
      path: '/share-qr',
      component: () => import('./../../session/driver-register/driver-register-qr-token.vue'),
      name: RouteName.ShareQrToken,
      meta: {
        auth: 'opened',
        title: 'Share QR',
      },
    },
  ]
}
