<script>
export default {
  name: 'ContextMenu',
  emits: ['closed'],
  props: {
    event: {
      type: Object,
    },
  },
  computed: {
    coordinates() {
      if (!this.event) {
        return null
      }
      let radiusToClear = ''
      const result = {}
      if (this.event.clientY > (window.innerHeight * 2) / 3) {
        result.bottom = `${window.innerHeight - this.event.clientY + 2}px`
        radiusToClear += 'bottom'
      } else {
        result.top = `${this.event.clientY + 2}px`
        radiusToClear += 'top'
      }
      if (this.event.clientX > (window.innerWidth * 3) / 4) {
        result.right = `${window.innerWidth - this.event.clientX + 2}px`
        radiusToClear += '-right'
      } else {
        result.left = `${this.event.clientX + 2}px`
        radiusToClear += '-left'
      }
      result[`border-${radiusToClear}-radius`] = '0'
      return result
    },
  },
  watch: {
    event(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          this.$eventHub.$on('mousedown', this.close)
        } else {
          this.$eventHub.$off('mousedown', this.close)
        }
      })
    },
  },
  mounted() {
    this.$eventHub.$on('windowResize', this.close)
  },
  unmounted() {
    this.$eventHub.$off('mousedown', this.close)
    this.$eventHub.$off('windowResize', this.close)
  },
  methods: {
    close(event) {
      setTimeout(() => {
        if (this.event?.timeStamp != event?.timeStamp) {
          this.$emit('closed', event)
        }
      }, 150)
    },
  },
}
</script>
<template lang="pug">
span
  teleport(v-if="coordinates" to="#context-menu-portal")
    .context-menu(
      ref="contextMenu"
      :style="coordinates"
      @mousedown="$event.stopPropagation(); $event.preventDefault()"
      @mouseup.prevent="close($event)"
    )
      slot
</template>
