<script>
import { groupBy } from 'lodash'

export default {
  name: 'EaCheckboxGroup',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    name: { type: String, required: true },
    label: { type: String, default: null },
    modelValue: { type: [Array] },
    collection: { type: Array, required: true },
    collectionLabel: { type: String, default: 'name' },
    collectionValue: { type: String, default: 'id' },
    columns: { type: Number, default: 1 },
    validators: { type: Object, default: null },
    deselectAllHidden: { type: Boolean, default: false },
    asInlineFlex: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  data() {
    return { groupName: null }
  },
  computed: {
    collectionGrouped() {
      return groupBy(this.collection, item => this.collection.indexOf(item) % this.columns)
    },
  },
}
</script>

<template lang="pug">
form-control-container(
  :additional-errors="additionalErrors"
  :label="label"
  :validators="validators"
  :value="modelValue"
)
  template(v-if="!disabled && (modelValue || []).length && !deselectAllHidden" #label-addon)
    i.ms-2.bi-x-lg.text-danger.cursor-pointer(@click="$emit('update:modelValue', [])")
  template(#default)
    .align-items-start.justify-content-between.ps-2(
      :class="asInlineFlex ? 'd-inline-flex' : 'd-flex'"
    )
      .flex-grow-1(
        v-for="column in Object.keys(collectionGrouped)"
        :class="{ 'pe-3': asInlineFlex }"
      )
        ea-simple-checkbox(
          v-for="(item, index) in collectionGrouped[column]"
          :disabled="disabled"
          :index="`${column}.${index}`"
          :label="item[collectionLabel]"
          :name="`${name}.${item[collectionValue]}`"
          :value="item[collectionValue]"
          v-model="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)"
        )
</template>
