export enum DataModelType {
  Event = 'Event',
  ASL = 'ASL',
  ClientFunction = 'ClientFunction',
  ClientFunctionRemark = 'ClientFunctionRemark',
  DayLabel = 'DayLabel',
  DriverCategory = 'DriverCategory',
  LocationType = 'LocationType',
  OperationalServiceLevel = 'OperationalServiceLevel',
  VehicleBrand = 'VehicleBrand',
  VehicleCategory = 'VehicleCategory',
  VehicleModel = 'VehicleModel',
  VehicleType = 'VehicleType',
  TSL = 'TSL',
  ClientCategory = 'ClientCategory',
  MapProvider = 'MapProvider',
  SystemNotification = 'SystemNotification',
  ClusterCalendarEntry = 'ClusterCalendarEntry',
  NotificationTemplate = 'NotificationTemplate',
  DriverCalendarEntry = 'DriverCalendarEntry',
  StagingAreaCalendarEntry = 'StagingAreaCalendarEntry',
  Shift = 'Shift',
  ShiftJob = 'ShiftJob',
  ClientNonCompanion = 'ClientNonCompanion',
  Client = 'Client',
  ClientGroup = 'ClientGroup',
  ClientGroupActivity = 'ClientGroupActivity',
  EmailTemplate = 'EmailTemplate',
  ShuttleTransfer = 'ShuttleTransfer',
  ShuttleService = 'ShuttleService',
  VehicleSourcePricing = 'VehicleSourcePricing',
  VehicleSource = 'VehicleSource',
  Vehicle = 'Vehicle',
  TrackerDevice = 'TrackerDevice',
  TrackingService = 'TrackingService',
  Driver = 'Driver',
  DriverSource = 'DriverSource',
  ClientCommunicationRule = 'ClientCommunicationRule',
  ClientCommunicationMessage = 'ClientCommunicationMessage',
  OSLTBRule = 'OSLTBRule',
  OSLVRule = 'OSLVRule',
  OSLDRule = 'OSLDRule',
  DurationRule = 'DurationRule',
  Location = 'Location',
  TransportationPoint = 'TransportationPoint',
  TransportBuffer = 'TransportBuffer',
  StagingArea = 'StagingArea',
  Cluster = 'Cluster',
  Depot = 'Depot',
  TransportBooking = 'TransportBooking',
  BatchOnsiteRequest = 'BatchOnsiteRequest',
  TransportBookingGroupingRule = 'TransportBookingGroupingRule',
  VehicleImport = 'VehicleImport',
  DriverImport = 'DriverImport',
  ClientImport = 'ClientImport',
  ClientGroupActivityImport = 'ClientGroupActivityImport',
  ShuttleServiceImport = 'ShuttleServiceImport',
  TransportBookingImport = 'TransportBookingImport',
  TransportationPointImport = 'TransportationPointImport',
  EventOrganizationUserImport = 'EventOrganizationUserImport',
  Report = 'Report',
  EventOrganizationUser = 'EventOrganizationUser',
  DriverRegistrationQrToken = 'DriverRegistrationQrToken',
  DriverRegistrationRequest = 'DriverRegistrationRequest',
  Version = 'Version',
  TBVersion = 'TBVersion',
  Role = 'Role',
  User = 'User',
  ColumnPreset = 'ColumnPreset',
  PredefinedCriteriaScope = 'PredefinedCriteriaScope',
  Variable = 'Variable',
}
