export default function (value, format, timeZone = 'UTC') {
  if (!value) return ''
  if (
    dayjs.isDayjs(value) ||
    /\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}.\d{6})?/i.test(value.toString())
  )
    if (timeZone) return dayjs(value).tz(timeZone).format(format)
    else return dayjs(value).format(format)
  return ''
}
