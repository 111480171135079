import { Client } from 'frontend/clients'
import { Cluster } from 'frontend/clusters'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { Location, LocationWithTp } from 'frontend/locations'
import { EXTENDED_KINDS } from 'frontend/transport-bookings'
import { TransportationPoint } from 'frontend/transportation-points'
import { User } from 'frontend/users'
import TBVersionOsl from 'frontend/versions/transport-booking-version-osl.vue'
import { markRaw } from 'vue'

const humanizedKind = tb => EXTENDED_KINDS.find(el => el.value == tb.extendedKind)?.label

const buildClientFromTb = item => {
  let result = item.client
  if (
    !result &&
    (item.objectChanges?.batchOnsiteRequestId?.[0] || item.objectChanges?.batchOnsiteRequestId?.[1])
  ) {
    result = {
      surname: `unnamed`,
      name: 'client',
    }
  }
  return result
}

export const TBVersionDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'item_type',
    'created_at',
    'item_id',
    'performed_by',
    'object_changes',
    'start_location',
    'start_transportation_point',
    'end_location',
    'end_transportation_point',
    'extended_kind',
    'client',
    'operational_service_level',
  ],
  subscriptions: {
    items: 'VersionsChannel',
  },
  defaultColumns: [
    'eid',
    'kind',
    'clientDetails',
    'requestedDatetime',
    'startAt',
    'pu',
    'endAt',
    'do',
    'osl',
    'createdAt',
    'performedBy',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    kind: {
      label: 'Type',
      header: {
        sort: 'extendedKind',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'function',
        fn: humanizedKind,
      },
    },
    requestedDatetime: {
      label: 'Req. datetime',
      header: {
        sort: 'requestedTime',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.requestedTime?.[0],
          tz: item[
            `${(item.objectChanges?.providedStamp?.[0] || 'start_at').replace('_at', '')}Location`
          ]?.timeZone,
        }),
      },
    },

    requestedTime: {
      label: 'Req. time',
      header: {
        sort: 'requestedTime',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.requestedTime?.[0],
          tz: item[
            `${(item.objectChanges?.providedStamp?.[0] || 'start_at').replace('_at', '')}Location`
          ]?.timeZone,
        }),
      },
    },
    requestedDate: {
      label: 'Req. date',
      header: {
        sort: 'requestedTime',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.requestedTime?.[0],
          tz: item[
            `${(item.objectChanges?.providedStamp?.[0] || 'start_at').replace('_at', '')}Location`
          ]?.timeZone,
        }),
      },
    },

    startAt: {
      label: 'Req. pick-up',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.startAt?.[0],
          tz: item.startLocation?.timeZone,
        }),
      },
    },

    startAtTime: {
      label: 'Req. pick-up time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.startAt?.[0],
          tz: item.startLocation?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    startAtDate: {
      label: 'Req. pick-up date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.startAt?.[0],
          tz: item.startLocation?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    endAt: {
      label: 'Req. drop-off',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.endAt?.[0],
          tz: item.endLocation?.timeZone,
        }),
      },
    },

    endAtTime: {
      label: 'Req. drop-off time',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.endAt?.[0],
          tz: item.endLocation?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    endAtDate: {
      label: 'Req. drop-off date',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.objectChanges?.endAt?.[0],
          tz: item.endLocation?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    startCluster: {
      label: 'Pick-up Cluster',
      header: {
        sort: 'startLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: tb => ({
          cluster: tb.startLocation?.cluster,
        }),
      },
    },

    startLocation: {
      label: 'Pick-up Location',
      header: {
        sort: 'startLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: tb => ({
          location: tb.startLocation,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },

    startTransportationPoint: {
      label: 'Pick-up TP',
      header: {
        sort: 'startTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: tb => ({
          transportationPoint: tb.startTransportationPoint,
          kindIn: "['onsite', 'arrival', 'departure']",
          nowrap: true,
        }),
      },
    },

    pu: {
      label: 'Pick-up',
      header: {
        sort: 'startLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(LocationWithTp),
        componentProps: tb => ({
          item: tb,
          kind: 'start',
          minWidth: 250,
          // nowrap: true,
        }),
      },
    },

    endCluster: {
      label: 'Drop-off Cluster',
      header: {
        sort: 'endLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: tb => ({
          cluster: tb.endLocation?.cluster,
        }),
      },
    },

    endLocation: {
      label: 'Drop-off Location',
      header: {
        sort: 'endLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: tb => ({
          location: tb.endLocation,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },

    endTransportationPoint: {
      label: 'Drop-off TP',
      header: {
        sort: 'endTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: tb => ({
          transportationPoint: tb.endTransportationPoint,
          kindIn: "['onsite', 'arrival', 'departure']",
          nowrap: true,
        }),
      },
    },

    do: {
      label: 'Drop-off',
      header: {
        sort: 'endLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(LocationWithTp),
        componentProps: tb => ({
          item: tb,
          kind: 'end',
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },

    client: {
      label: 'Client full name',
      header: {
        sort: 'clientFullName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Client),
        componentProps: tb => ({
          client: buildClientFromTb(tb),
          nowrap: true,
          disabled: !tb.client?.id,
          batchOnsiteRequest: !tb.client?.id ? tb.batchOnsiteRequest : null,
        }),
      },
    },

    clientName: {
      label: 'Client name',
      header: {
        sort: 'clientName',
      },
      cell: {
        kind: 'simple',
        path: 'client.name',
      },
    },

    clientSurname: {
      label: 'Client surname',
      header: {
        sort: 'clientSurname',
      },
      cell: {
        kind: 'simple',
        path: 'client.surname',
      },
    },

    clientPhone: {
      label: 'Client phone number',
      header: {
        sort: 'clientPhone',
      },
      cell: {
        kind: 'component',
        component: 'phone-number',
        componentProps: tb => ({
          phoneNumber: tb.client?.phone,
        }),
      },
    },

    clientRemoteId: {
      label: 'Client remote ID',
      header: {
        sort: 'clientRemoteId',
      },
      cell: {
        kind: 'simple',
        path: 'client.remoteId',
      },
    },

    clientDetails: {
      label: 'Client',
      header: {
        sort: 'clientFullName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Client),
        componentProps: tb => ({
          client: buildClientFromTb(tb),
          detailed: !!tb.client?.id,
          nowrap: true,
          disabled: !tb.client?.id,
          batchOnsiteRequest: !tb.client?.id ? tb.batchOnsiteRequest : null,
        }),
      },
    },

    osl: {
      label: 'OSL',
      header: {
        sort: 'operationalServiceLevelPosition',
      },
      cell: {
        kind: 'component',
        component: markRaw(TBVersionOsl),
        componentProps: item => ({
          versionItem: item,
        }),
      },
    },

    comment: {
      label: 'Comment',
      header: {
        sort: 'comment',
      },
      cell: {
        kind: 'component',
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item.objectChanges?.comment?.[0],
          limit: 250,
        }),
      },
    },

    performedBy: {
      label: 'Actor',
      cell: {
        kind: 'component',
        component: markRaw(User),
        componentProps: el => ({
          user: el.performedBy,
          placeholderText: 'System',
        }),
      },
    },
    createdAt: {
      label: 'TB destroyed At',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt, format: 'shortWithSeconds' }),
      },
    },
  },
}
