<script>
export default {
  name: 'SidebarTextIcon',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<template lang="pug">
.icon-wrapper {{ title }}
</template>
