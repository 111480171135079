import { defineAsyncComponent } from 'vue'

const AASM_STATES = [
  { value: 'pending', label: 'Pending', action: 'reset_state' },
  { value: 'in_route', label: 'In route', action: 'start_pickup_journey' },
  { value: 'at_pickup_site', label: 'At pick-up site', action: 'arrive_at_pickup_site' },
  { value: 'picked_up', label: 'Picked up', action: 'onboard_clients' },
  { value: 'in_progress', label: 'In progress', action: 'start_journey' },
  { value: 'at_dropoff_site', label: 'At drop-off site', action: 'arrive_at_dropoff_site' },
  { value: 'dropped_off', label: 'Dropped off', action: 'dropoff_clients' },
  { value: 'no_show', label: 'Incomplete', action: 'nobody_to_onboard' },
]

const ActivityTransferDetails = defineAsyncComponent(() =>
  import('./activity-transfer-details.vue'),
)
const ActivityTransfersTable = defineAsyncComponent(() => import('./activity-transfers-table.vue'))
const ActivityTransferModal = defineAsyncComponent(() => import('./activity-transfer-modal.vue'))
const ActivityTransferAasmState = defineAsyncComponent(() =>
  import('./activity-transfer-aasm-state.vue'),
)

export {
  AASM_STATES,
  ActivityTransferAasmState,
  ActivityTransferDetails,
  ActivityTransferModal,
  ActivityTransfersTable,
}
