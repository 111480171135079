<script>
const FORMATS_MAP = {
  long: 'dddd, DD/MM/YYYY, HH:mm',
  short: 'DD/MM, HH:mm',
  shortAt: 'DD/MM [at] HH:mm',
  shortWithSeconds: 'DD/MM HH:mm:ss',
  timeOnly: 'HH:mm',
  dateOnly: 'DD/MM/YYYY',
  dayHumanizedMonth: 'D MMMM',
  dayOnly: 'DD/MM',
}

export default {
  name: 'FormattedTime',
  props: {
    t: {
      type: [String, Object],
      validator(value) {
        if (typeof value === 'object') {
          return dayjs.isDayjs(value)
        }
        return true
      },
    },
    tz: { type: String, default: null },
    format: {
      type: String,
      default: 'short',
      validator(value) {
        return Object.keys(FORMATS_MAP).includes(value)
      },
    },
    noTooltips: {
      type: Boolean,
      default: false,
    },
    noInBrowserTimezone: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    browserTZ() {
      return this.$store.state.session.timeZone
    },
    providedTZ() {
      return this.tz?.length ? this.tz : this.$store.state.session.timeZone
    },
    isDifferentOffset() {
      return this.timeInBrowserTZ.utcOffset() !== this.timeInProvidedTZ.utcOffset()
    },
    timeInBrowserTZ() {
      return dayjs(this.t).tz(this.browserTZ)
    },
    timeInProvidedTZ() {
      return dayjs(this.t).tz(this.providedTZ)
    },
    inBrowserTZTooltipOptions() {
      if (this.noTooltips) return {}
      return {
        title: `${this.timeInBrowserTZ.format(FORMATS_MAP['long'])} in ${this.browserTZ}`,
        placement: 'auto',
      }
    },
    inProvidedTZTooltipOptions() {
      if (this.noTooltips) return {}
      return {
        title: `${this.timeInProvidedTZ.format(FORMATS_MAP['long'])} in ${this.providedTZ}`,
        placement: 'auto',
      }
    },
    formattedTimeInProvidedTZ() {
      return this.timeInProvidedTZ.format(FORMATS_MAP[this.format])
    },
    formattedTimeInBrowserTZ() {
      return this.timeInBrowserTZ.format(FORMATS_MAP['timeOnly'])
    },
  },
}
</script>

<template lang="pug">
template(v-if="t && providedTZ")
  span
    time.in-provided-tz(:datetime="t" v-tooltip.options="inProvidedTZTooltipOptions") {{ formattedTimeInProvidedTZ }}
    time.in-browser-tz(
      v-if="isDifferentOffset && !noInBrowserTimezone"
      :datetime="t"
      v-tooltip.options="inBrowserTZTooltipOptions"
    ) {{ formattedTimeInBrowserTZ }} in {{ browserTZ }}
span.text-black-15(v-else-if="!t") empty
</template>

<style scoped>
.in-browser-tz {
  display: block;
  font-size: 0.75em;
}
</style>
