<script>
import { sanitizer } from 'frontend/_config/sanitize-html'

export default {
  name: 'TaggableSelect',
  emits: ['update:modelValue'],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    mainText: {
      type: String,
      default: 'Type your text',
    },
  },
  computed: {
    properOptions() {
      let result = [...this.options]
      if (this.modelValue?.length) {
        result = Array.from(new Set([...this.options, ...Array.from(this.modelValue)]))
      }
      return result
    },
  },
  methods: {
    updateModelValue(value) {
      if (value?.constructor === Array) {
        this.$emit(
          'update:modelValue',
          value.map(v => sanitizer.process(v)),
        )
      } else if (value) {
        this.$emit('update:modelValue', sanitizer.process(value))
      } else {
        this.$emit('update:modelValue', value)
      }
    },
  },
}
</script>

<template lang="pug">
ea-select(
  v-bind="{ ...$attrs }"
  :modelValue="modelValue"
  :options="properOptions"
  @update:modelValue="updateModelValue($event)"
  multiple
  pushTags
  taggable
)
  //- template(#list-header)
    //- li.text-center
  template(#no-options="{ search, searching, loading }")
    .small.text-black-50 {{ mainText }}, then press Enter
    .small.text-black-50 or choose from the list.
    .small.text-black-50 (can be repeated multiple times)
  template(#no-more-matching-options="{ search, searching, loading }")
    .small.text-black-50 {{ mainText }}, then press Enter
    .small.text-black-50 or choose from the list.
    .small.text-black-50 (can be repeated multiple times)
</template>
