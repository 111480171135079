import { Cluster } from 'frontend/clusters'
import ErebusLabel from 'frontend/common/erebus-label.vue'
import FormattedTime from 'frontend/common/formatted-time.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { markRaw } from 'vue'

export const ClusterCalendarEntryDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'start',
    'end',
    'class_name',
    'eid',
    'start_at',
    'end_at',
    'kind',
    'created_at',
    'updated_at',
    {
      extended_props: [
        { day_label: ['id', 'name', 'font_color', 'background_color', 'border_color'] },
      ],
    },
    { cluster: ['id', 'name', 'time_zone'] },
  ],
  subscriptions: {
    items: 'CalendarEntriesClusterCalendarChannel',
  },
  defaultColumns: ['eid', 'cluster', 'dayLabel', 'startAt', 'endAt'],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: Cluster,
        componentProps: item => ({ cluster: item.cluster }),
      },
    },
    dayLabel: {
      label: 'Occasion Day labels',
      header: {
        sort: 'dayLabelName',
      },
      cell: {
        kind: 'component',
        component: ErebusLabel,
        componentProps: item => ({ item: item.dayLabel }),
      },
    },
    startAt: {
      label: 'Start Datetime',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item?.startAt, tz: item.cluster?.timeZone }),
      },
    },
    startTime: {
      label: 'Start Time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item?.startAt,
          tz: item.cluster?.timeZone,
          format: 'timeOnly',
        }),
      },
    },
    startDate: {
      label: 'Start Date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item?.startAt,
          tz: item.cluster?.timeZone,
          format: 'dateOnly',
        }),
      },
    },
    endAt: {
      label: 'End Datetime',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({ t: item?.endAt, tz: item.cluster?.timeZone }),
      },
    },
    endTime: {
      label: 'End Time',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item?.endAt,
          tz: item.cluster?.timeZone,
          format: 'timeOnly',
        }),
      },
    },
    endDate: {
      label: 'End Date',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item?.endAt,
          tz: item.cluster?.timeZone,
          format: 'dateOnly',
        }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
