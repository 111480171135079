import { Tooltip } from 'bootstrap'

function disposeTooltip(tooltip) {
  tooltip.dispose()
  // When hide is called, it is registering a callback which can run after the tooltip was disposed.
  // We could either ensure that cleanup after the hide cen success with monkey-patch below or add
  // listeners to ensure that dispose is called only after hidden event.
  const placeholderEl = document.createElement('div')
  placeholderEl.setAttribute('class', 'monkey-patch')
  tooltip.tip = placeholderEl
  tooltip._element = placeholderEl
}

export default {
  name: 'VTooltip',
  mounted(el, { value, modifiers }) {
    if (modifiers?.options) {
      if (
        value?.title?.length ||
        value?.title instanceof HTMLElement ||
        value?.title?.constructor == Function
      ) {
        new Tooltip(el, { ...value })
      }
    } else if (value?.length || value?.constructor == Function) {
      new Tooltip(el, { title: value, placement: 'auto' })
    }
    el.addEventListener('click', () => {
      const tooltip = Tooltip.getInstance(el)
      if (tooltip?.hide) {
        tooltip.hide()
      }
    })
  },
  beforeUnmount(el) {
    const tooltip = Tooltip.getInstance(el)
    if (tooltip?.dispose) {
      tooltip._config.animation = false
      disposeTooltip(tooltip)
    }
  },
  beforeUpdate(el, params) {
    if (params.modifiers?.options) {
      const isElement = params.value?.title instanceof HTMLElement
      if (params.value?.title?.length || isElement) {
        const instance = Tooltip.getInstance(el)
        if (
          // watching only the title because of optimization
          // intersection(Object.values(params.oldValue || {}), Object.values(params.value || {})).length != Object.values(params.value || {}).length
          params.oldValue?.title != params.value?.title ||
          isElement
        ) {
          if (instance && params.modifiers?.sync) {
            const tip = instance.getTipElement().querySelector('.tooltip-inner')
            if (isElement) {
              tip.replaceChildren(params.value.title)
            } else {
              tip.innerHTML = params.value.title
            }

            instance.update()
          } else if (instance && instance.dispose) {
            disposeTooltip(instance)
          }
          Tooltip.getOrCreateInstance(el, params.value)
        }
      } else {
        const instance = Tooltip.getInstance(el)
        if (instance && instance.dispose) {
          disposeTooltip(instance)
        }
      }
    } else if (params.value?.length || params.value?.constructor == Function) {
      const instance = Tooltip.getInstance(el)
      if (params.oldValue != params.value) {
        if (instance) {
          const tip = instance.getTipElement().querySelector('.tooltip-inner')
          const isElement = params.value instanceof HTMLElement
          if (isElement) {
            tip.replaceChildren(params.value.title)
          } else {
            tip.innerHTML = params.value.title
          }
          instance.update()
        }
        Tooltip.getOrCreateInstance(el, params.value)
      }

      //   if (instance && instance.dispose) {
      //     disposeTooltip(instance)
      //   }
      //   new Tooltip(el, { title: params.value, placement: 'auto' })
      // }
    } else {
      const instance = Tooltip.getInstance(el)
      if (instance && instance.dispose) {
        disposeTooltip(instance)
      }
    }
  },
}
