<template>
  <div class="empty-roles-placeholder">
    <div v-for="i in 3" :key="i" class="empty-role-item-wrapper">
      <div class="empty-role-item" :style="{ animationDelay: `${i * -0.4}s` }">
        <i class="fas fa-list" />
        <div v-for="j in 5" :key="j" class="empty-container" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
<style scoped lang="scss">
.empty-roles-placeholder {
  display: grid;
  transform: translateX(24px);
  padding-top: 10px;
}

.empty-role-item-wrapper {
  &:first-child {
    transform: translate(-48px, 0px) rotate(-4deg);
    z-index: 2;
  }

  &:nth-child(2) {
    z-index: 1;
  }

  &:nth-child(3) {
    transform: translate(-36px, 0px) rotate(2deg);
    z-index: 0;
  }
}

.empty-role-item {
  display: grid;
  grid-template-columns: auto 16px repeat(4, 40px);
  box-shadow: 0px 4.587px 12.232px -6.116px rgba(0, 0, 0, 0.08),
    0px 6.88px 21.406px 0px rgba(0, 0, 0, 0.05), 0px 9.174px 36.696px 12.232px rgba(0, 0, 0, 0.03);
  padding: 12px;
  border-radius: 8px;
  gap: 8px;
  background: white;
  align-items: center;
  position: relative;
  animation: shake 30s infinite alternate;
}

.empty-container {
  border-radius: 4px;
  background: #f0f1f1;
  height: 8px;

  &:nth-child(2) {
    height: 12px;
  }
}

i {
  font-size: 12px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
