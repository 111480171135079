<script>
import { computed } from 'vue'
import { Polyline } from 'vue3-google-map'

export default {
  name: 'Route',
  inheritAttrs: false,
  emits: ['route:removed', 'route:added'],
  props: {
    layerId: {
      type: String,
      default: () =>
        `route-${Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')}`,
    },
    routeGeometry: {
      type: Object,
      required: true,
    },
    properties: { type: Object },
    focusOnShow: { type: Boolean, default: false },
  },
  setup(props) {
    const path = computed(() => {
      return {
        path: (props.routeGeometry?.coordinates || []).map(c => ({ lat: c[1], lng: c[0] })),
        geodesic: true,
        strokeColor: '#51ADFB',
        strokeOpacity: 0.8,
        strokeWeight: 5,
      }
    })

    return {
      path,
    }
  },
  components: { Polyline },
}
</script>

<template lang="pug">
Polyline(v-if="path?.path" :options="path")
</template>
