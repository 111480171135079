import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { POST, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class TransportationPointsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/transportation_points`,
    blocker,
    fetchError = 'Failed to fetch transportation point.',
    saveError = 'Failed to save transportation point.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'transportation_points',
    }),
  } = {}) {
    super({ apiProvider, baseUrl, blocker, fetchError, saveError })
    this.selectService = selectService
  }

  getCriteriaForResources(additionalParams = {}) {
    return this._makeCall(POST, this._onFetchError, {
      url: `${this.baseUrl}/criteria_for_resources`,
      params: additionalParams,
    })
  }

  getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        sorts: 'lower_name asc',
        fields: ['id', 'name', 'location_id'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }
}
