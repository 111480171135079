import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {Cluster} from 'frontend/clusters'
import {LocationType} from 'frontend/location-types'
import {StagingArea} from 'frontend/staging-areas'
import {Depot} from 'frontend/depots'
import TransportationPoints from 'frontend/locations/transportation-points.vue'
import {User} from 'frontend/users'

export const LocationDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'time_zone',
    'abbreviation',
    'address',
    'latitude',
    'longitude',
    'remote_id',
    'updated_at',
    'created_at',
    { cluster: ['id', 'name', 'time_zone'] },
    { location_type: ['id', 'name'] },
    { transportation_points: ['id', 'name'] },
    { created_by: ['id', 'email', 'name', 'surname'] },
    {
      staging_area: ['id', 'has_minimum_vehicles_allotment', 'has_subfleet', 'has_welcome_desk'],
    },
    { depot: ['id', 'name'] },
  ],
  subscriptions: {
    items: 'LocationsChannel',
    clusters: 'ClustersSimpleChannel',
    locationTypes: 'LocationTypesSimpleChannel',
    stagingAreas: 'StagingAreasFlatChannel',
    depots: 'DepotsFlatChannel',
    transportationPoints: 'TransportationPointsSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'lowerName',
    'timeZone',
    'abbreviation',
    'address',
    'coordinates',
    'cluster',
    'locationType',
    'stagingArea',
    'depot',
    'transportationPoints',
    'remoteId',
    'updatedDatetime',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    lowerName: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    timeZone: {
      label: 'Time zone',
      header: {
        sort: 'timeZone',
      },
      cell: {
        kind: 'simple',
        path: 'timeZone',
      },
    },
    abbreviation: {
      label: 'Abbreviation',
      header: {
        sort: 'abbreviation',
      },
      cell: {
        kind: 'simple',
        path: 'abbreviation',
      },
    },
    address: {
      label: 'Address',
      header: {
        sort: 'address',
      },
      cell: {
        kind: 'simple',
        path: 'address',
      },
    },
    coordinates: {
      label: 'Coordinates',
      header: {
        sort: 'latitude',
      },
      cell: {
        kind: 'component',
        component: 'TrueFalseLabel',
        componentProps: location => ({
          item: !!(location.latitude && location.longitude),
        }),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: location => ({ cluster: location.cluster }),
      },
    },
    locationType: {
      label: 'Type',
      header: {
        sort: 'locationTypeName',
      },
      cell: {
        kind: 'component',
        component: markRaw(LocationType),
        componentProps: location => ({ locationType: location.locationType }),
      },
    },
    stagingArea: {
      label: 'Staging area',
      header: {
        sort: 'stagingAreaId',
      },
      cell: {
        kind: 'component',
        component: markRaw(StagingArea),
        componentProps: location => ({ stagingArea: location.stagingArea }),
      },
    },
    depot: {
      label: 'Depot',
      header: {
        sort: 'depotEid',
      },
      cell: {
        kind: 'component',
        component: markRaw(Depot),
        componentProps: location => ({ depot: location.depot }),
      },
    },
    transportationPoints: {
      label: 'Transportation points',
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoints),
        componentProps: location => ({ location: location }),
      },
    },
    remoteId: {
      label: 'Remote ID',
      header: {
        sort: 'remoteId',
      },
      cell: {
        kind: 'simple',
        path: 'remoteId',
      },
    },
    createdBy: {
      label: 'Created by',
      header: {
        sort: 'createdByUsername',
      },
      cell: {
        kind: 'component',
        component: markRaw(User),
        componentProps: item => ({
          user: item.createdBy,
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: location => ({
          t: location.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: location => ({
          t: location.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: location => ({
          t: location.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: location => ({
          t: location.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: location => ({
          t: location.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: location => ({
          t: location.createdAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}