<script setup lang="ts">
import ResourceSelectInput from 'frontend/common/search-criterion/resource-select-input.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: string | string[]
  criterion: ISearchCriteriaNode
  predicate: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string | string[]): void
}
const emit = defineEmits<Emits>()

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <ResourceSelectInput
    v-model="valueModel"
    :config="props.criterion"
    :name="`nameToBeRenamed`"
    skip-dirty-check
  />
</template>
