<template>
  <div class="loading-wrapper">
    <div class="loading-frame">
      <i class="car fas fa-truck" />
      <div class="line" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>
<style scoped lang="scss">
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loading-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  overflow: hidden;
  position: relative;
}

.car {
  font-size: 28px;
  animation: car-forward 2s infinite ease-in-out;
}

.line {
  position: absolute;
  height: 2px;
  background-image: linear-gradient(
    to right,
    var(--loading-secondary-color) 0%,
    var(--loading-secondary-color) 12.5%,
    transparent 12.5%,
    transparent 25%,
    var(--loading-secondary-color) 25%,
    var(--loading-secondary-color) 37.5%,
    transparent 37.5%,
    transparent 50%,
    var(--loading-secondary-color) 50%,
    var(--loading-secondary-color) 62.5%,
    transparent 62.5%,
    transparent 75%,
    var(--loading-secondary-color) 75%,
    var(--loading-secondary-color) 87.5%,
    transparent 87.5%,
    transparent 100%
  );
  width: 200%;
  bottom: 0;
  left: 0;
  animation: move 0.5s infinite linear;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes car-forward {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px) rotate(2deg);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
