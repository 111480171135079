import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from "vue";
import FormattedTime from "frontend/common/formatted-time.vue";

export const VehicleTypeDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'updated_at'
  ],
  subscriptions: {
    items: 'ClientFunctionRemarksChannel',
  },
  defaultColumns: [
    'lowerName'
  ],
  columnConfig: {
    lowerName: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        kind: 'simple',
        path: 'name',
        class: 'text-nowrap',
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}