<script>
export default {
  name: 'SubmitButton',
  emits: ['click'],
  props: {
    size: {
      type: String,
      default: null,
      validator(val) {
        return ['xs', 'sm', 'md', 'lg'].includes(val)
      },
    },
  },
  computed: {
    sizeClass() {
      return this.size ? `btn-${this.size}` : ''
    },
  },
}
</script>

<template lang="pug">
button.btn.btn-primary(:class="sizeClass" @click="$emit('click', $event)" type="submit")
  slot Submit
</template>
