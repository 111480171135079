import 'frontend/_config/dayjs'
// import 'app/javascript/frontend/_config/dayjs'
// import 'frontend/_config/airbrake'
import 'frontend/_config/axios'
import 'frontend/_config/axios-interceptors'
import 'frontend/_config/extensions'

import initGlitchtip from 'frontend/_config/glitchtip'
import errorHandler from 'frontend/_config/global-error-handler'
import {
  registerComponents,
  registerDirectives,
  registerGlobalProperties,
  registerPlugins,
} from 'frontend/_config/vue-globals'
import Tmm from 'frontend/common/tmm.vue'
import { createApp } from 'vue'

// // eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
// import icons from '../images'

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp(Tmm)
  app.config.errorHandler = errorHandler
  initGlitchtip(app)

  registerPlugins(app)
  registerGlobalProperties(app)
  registerDirectives(app)
  registerComponents(app)

  app.mount('#vue-app')
})

// browser does not catch the F8 sometimes, easier debugging
if (process.env.NODE_ENV === 'development') {
  document.addEventListener('keydown', event => {
    if (event.key === 'F8') {
      // eslint-disable-next-line no-debugger
      debugger
    }
  })
}
