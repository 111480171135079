import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function useResourcesSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }

  const section = computed(() => {
    const result = {
      name: 'Resources',
      items: [
        {
          title: 'Vehicles',
          isDisabled: false,
          path: 'vehicles',
          icon: 'bi-truck',
        },
        {
          title: 'Drivers',
          isDisabled: false,
          path: 'drivers',
          icon: 'bi-person-badge',
        },
        {
          title: 'Clients',
          isDisabled: false,
          path: 'clients',
          icon: 'bi-people',
        },
        {
          title: 'Shifts',
          isDisabled: false,
          path: 'shifts',
          icon: 'bi-ui-checks',
        },
        {
          title: 'Driver Registration QR Tokens',
          isDisabled: false,
          path: 'driver-registration-qr-tokens',
          icon: 'bi-qr-code',
        },
        {
          title: 'Driver Registration Requests',
          isDisabled: false,
          path: 'driver-registration-requests',
          icon: 'bi-person-badge',
        },
        {
          title: 'Reports',
          isDisabled: false,
          path: 'reports',
          icon: 'far fa-file-excel',
        },
      ],
    }
    result.items = result.items.filter(el => hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
