import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function useOslsSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }
  const section = computed(() => {
    const result = {
      name: 'OSLs',
      items: [
        {
          title: 'OSL TB Rules',
          isDisabled: false,
          path: 'osl-tb-rules',
          icon: 'bi-file-spreadsheet-fill',
        },
        {
          title: 'OSL V Rules Matrix',
          isDisabled: false,
          path: 'osl-v-rules-matrix',
          icon: 'bi-x-diamond',
        },
        {
          title: 'OSL D Rules Matrix',
          isDisabled: false,
          path: 'osl-d-rules-matrix',
          icon: 'bi-x-diamond',
        },
        {
          title: 'TB Grouping Rules',
          isDisabled: false,
          path: 'transport-booking-grouping-rules',
          icon: 'bi-file-earmark-spreadsheet',
        },
        {
          title: 'Client Communication Rules',
          isDisabled: false,
          path: 'client-communication-rules',
          icon: 'bi-file-spreadsheet-fill',
        },
      ],
    }
    result.items = result.items.filter(el => hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
