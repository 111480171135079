import { defineAsyncComponent } from 'vue'

const VehicleSource = defineAsyncComponent(() => import('./vehicle-source.vue'))
const VehicleSourceFormModal = defineAsyncComponent(() => import('./vehicle-source-form-modal.vue'))
const VehicleSourceModal = defineAsyncComponent(() => import('./vehicle-source-modal.vue'))
const VehicleSourcesIndex = defineAsyncComponent(() => import('./vehicle-sources-index.vue'))
const VehicleSourcesSelect = defineAsyncComponent(() => import('./vehicle-sources-select.vue'))
const VehicleSourcesTable = defineAsyncComponent(() => import('./vehicle-sources-table.vue'))

export {
  VehicleSource,
  VehicleSourceFormModal,
  VehicleSourceModal,
  VehicleSourcesIndex,
  VehicleSourcesSelect,
  VehicleSourcesTable,
}
