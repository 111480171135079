import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function useImportsSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }
  const section = computed(() => {
    const result = {
      name: 'Imports',
      items: [
        {
          title: 'Drivers',
          path: 'driver-imports',
          icon: 'bi-file-earmark-excel',
        },
        {
          title: 'Vehicles',
          path: 'vehicle-imports',
          icon: 'bi-file-earmark-excel',
        },
        {
          title: 'Clients',
          path: 'client-imports',
          icon: 'bi-file-earmark-excel',
        },
        {
          title: 'Client Group Activities',
          path: 'client-group-activity-imports',
          icon: 'bi-file-earmark-excel',
        },
        {
          title: 'Shuttle Services',
          path: 'shuttle-service-imports',
          icon: 'bi-file-earmark-excel',
        },
        {
          title: 'Transport Booking',
          path: 'transport-booking-imports',
          icon: 'bi-file-earmark-excel',
        },
        {
          title: 'Transportation Point',
          path: 'transportation-point-imports',
          icon: 'bi-file-earmark-excel',
        },
        {
          title: 'User Accesses',
          path: 'user-access-imports',
          icon: 'bi-file-earmark-excel',
        },
      ],
    }

    result.items = result.items.filter(el => hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
