<script>
import { upperFirst } from 'lodash'

export default {
  name: 'TransportBookingTime',
  props: {
    transportBooking: { type: Object, default: () => ({}) },
    tz: { type: String, default: null },
    attr: { type: String, default: null },
    showAnchor: { type: Boolean, default: false },
    format: { type: String, default: 'short' },
    originalLookup: { type: Boolean, default: false },
    showOverriddenLeadLoadTime: { type: Boolean, default: false },
  },
  computed: {
    originalAttr() {
      return `original${upperFirst(this.attr)}`
    },
  },
}
</script>

<template lang="pug">
div
  formatted-time(:format="format" :t="transportBooking[attr]" :tz="tz")
  i.ms-1.fas.fa-anchor.text-black-50(
    v-if="showAnchor"
    v-tooltip="transportBooking.providedStamp == 'start_at' ? 'Earliest departure' : 'Latest arrival'"
  )

  .ps-2.small.line-through(
    v-if="originalLookup && transportBooking[attr] != transportBooking[originalAttr]"
  )
    formatted-time(:format="format" :t="transportBooking[originalAttr]" :tz="tz")
  .ps-2.small.text-warning.text-nowrap(
    v-if="showOverriddenLeadLoadTime && (transportBooking.overriddenLeadLoadTime || transportBooking.overriddenLeadLoadTime == 0)"
  )
    span.me-1 Overridden {{ transportBooking.providedStamp == 'start_at' ? 'load' : 'lead' }} time:
    humanized-duration(:minutes="transportBooking.overriddenLeadLoadTime")
</template>
