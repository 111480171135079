import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const OperationalServiceLevelDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [

  ],
  subscriptions: {

  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}