<script>
import { VehicleType } from 'frontend/vehicle-types'

export default {
  name: 'ActivityRequirements',
  props: {
    items: { type: Array },
  },
  components: {
    VehicleType,
  },
}
</script>

<template lang="pug">
template(v-if="items?.length")
  div(v-for="item in (items || []).slice(0, 5)")
    .d-flex.align-items-center.justify-content-start
      .bold.me-2 {{ item.quantity }}x
      vehicle-type(:vehicleType="item.vehicleType")
  .small.text-black-50(v-if="(items || []).length > 5")
    div and {{ (items || []).length - 5 }} more...
    div (click details to see all)
span.text-black-15(v-else) empty
</template>

<style scoped lang="scss"></style>
