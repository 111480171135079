<script>
export default {
  name: 'TimeDisplay',
  props: {
    time: {
      type: Date,
    },
    timezone: {
      type: String,
    },
  },
  computed: {
    computedTime() {
      const utcTime = dayjs(this.time).utc()
      const timezoneTime = this.timezone ? utcTime.tz(this.timezone) : dayjs(this.time)
      return timezoneTime.format('HH:mm')
    },
  },
}
</script>
<template lang="pug">
.text-center
  .time {{ computedTime }}
  //- .timezone(v-if="timezone") {{ timezone }}
</template>

<style lang="scss" scoped>
.time {
  font-size: 1.1rem;
  line-height: 1.1;
}
.timezone {
  font-size: 0.7rem;
  line-height: 1;
}
</style>
