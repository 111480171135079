import originalApplyConverters, {
  camelResponse as originalCamelResponse,
} from 'axios-case-converter'

const requestConverterOptions = {
  exclude: [
    { key: /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gi },
    { key: /_destroy/ },
    { key: /openid_config/, isNested: true },
    { key: /raw_response/, isNested: true },
    { key: /copy-of-EID-[0-9]+/ },
  ],
}

const camelResponse = (responseToCamelize, headers = {}, options = requestConverterOptions) => {
  return originalCamelResponse(responseToCamelize, headers, options)
}

const applyConverters = (axios, options = requestConverterOptions) =>
  originalApplyConverters(axios, options)

export { applyConverters, camelResponse, requestConverterOptions }
