<script>
const PREDICTION_TYPE_MAP = {
  BEST_GUESS: 'Best guess',
  OPTIMISTIC: 'Optimistic',
  AVERAGE: 'Average',
}

export default {
  name: 'QmicDirectionsConfig',
  props: {
    config: { type: Object },
  },
  computed: {
    humanizedPredictionType() {
      return PREDICTION_TYPE_MAP[this.config?.predictionType] || 'Best guess(default)'
    },
  },
}
</script>

<template lang="pug">
.dir-grid-container(v-if="config")
  .small-with-normal-height.text-muted.text-end Traffic model:
  span.ms-1 {{ humanizedPredictionType }}

  .small-with-normal-height.text-muted.text-end Range:
  span.ms-1 {{ config.rangePreference ?? 0 }}%
</template>
