<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'VehicleSource',
  props: {
    vehicleSource: { type: Object, default: () => ({}) },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
span.text-black-15(v-if="!vehicleSource?.name") empty
modal-button(v-else :item="vehicleSource" :modal="previewModals.VehicleSource")
  a {{ vehicleSource?.name }}
    div
      .badge.bg-secondary.me-1(v-if="vehicleSource?.billable") billable
      .badge.bg-secondary.me-1(v-if="vehicleSource?.maintainable") maintainable
</template>
