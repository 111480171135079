<script>
export default {
  name: 'SessionSSOCallback',
  data() {
    return {
      blocker: true,
      organization: null,
    }
  },
  created() {
    if (document.body?.attributes?.rw?.value?.length) {
      return
    }
    this.signIn()
  },
  methods: {
    signIn(retryOnCsrf = true) {
      this.axios({
        method: 'POST',
        url: 'session/sso_sign_in',
        params: {
          ...this.$route.query,
          organization_id: this.$route.params.organizationId,
          redirect_url: `${window.location.protocol}//${this.$mainDomain}/session/sso/${this.$route.params.organizationId}`,
        },
        headers: { 'Csrf-Retry': false },
      })
        .then(() => {
          this.$store
            .dispatch('session/getOrFetchUser', true)
            .then(() => {
              this.$success({ message: 'You have been successfully signed in.' })
              this.redirectToProperPath()
            })
            .catch(err => {
              console.error(err)
              this.$error({ message: "We can't sign you in, try again." })
              this.$router.push('/session/sign-in')
            })
        })
        .catch(error => {
          if (retryOnCsrf && error.response?.status == 460) {
            this.axios({ method: 'get', url: '', headers: { 'Csrf-Retry': false } })
              .then(() => {
                // should have csrf token now
                this.signIn(false)
              })
              .catch(() => {
                this.blocker = false
                this.showErrorMessage(error)
              })
            return
          } else if (error.response?.status == 495 && error.response?.data?.customDomain?.length) {
            console.warn('need to use custom domain before sign in!', error.response)
            window.location.href = window.location.href.replace(
              window.location.host,
              error.response.data.customDomain,
            )
            return
          } else {
            this.showErrorMessage(error)
            this.$router.push('/session/sign-in')
          }
        })
    },
    showErrorMessage(error) {
      console.error('sign in error', error?.response || error)
      let shownError
      if (!error.response) {
        shownError = { message: 'Major sign-in error occurred' }
      } else if (
        (error.response.data?.error || '').toLowerCase().indexOf('too many attempts') != -1
      ) {
        shownError = { message: 'Too many failed attempts, account is temporarily blocked.' }
      } else if (error.response.status == 401) {
        if (error.response.data?.error?.constructor == String) {
          shownError = { title: "We can't sign you in", message: error.response.data.error }
        } else {
          shownError = { title: "We can't sign you in", message: 'Please try again' }
        }
      } else if (error.response.data.errors) {
        shownError = { message: Object.values(error.response.data.errors).join(', ') }
      } else if (
        error.response.data.error &&
        error.response.data.error.constructor == ''.constructor
      ) {
        shownError = { message: error.response.data.error }
      } else {
        shownError = { message: "We can't sign you in, try again." }
      }
      this.$error(shownError)
    },
    redirectToProperPath() {
      console.log(
        'do we have remembered after sign in path?',
        this.$store.state.session?.afterSignInPath,
        sessionStorage.afterSignInPath,
      )
      if (this.$store.state.session?.afterSignInPath?.length) {
        if (this.$store.state.session?.afterSignInPath.startsWith('/gis')) {
          console.log('it starts with /gis, doing simple redirection')
          window.location.href = this.$store.state.session?.afterSignInPath
        } else {
          console.log('it does not start with /gis, using router')
          this.$router.push(this.$store.state.session.afterSignInPath)
        }
        return
      } else {
        console.log("we don't, using redirection to base path")
        this.$router.push('/')
      }
    },
  },
}
</script>
<template lang="pug">
ea-spinner(v-if="blocker" :spinner-size="30" matchParent)
</template>
