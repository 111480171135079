const TOAST_AIR_DURATION = 5000
const RANDOMIZER = 1000000

const bakeToast = (payload, type) => ({
  id: payload.id || `${dayjs().valueOf()}-${parseInt(Math.random() * RANDOMIZER)}`,
  message: payload.message,
  stay: payload.stay ?? TOAST_AIR_DURATION,
  autohide: payload.autohide ?? true,
  showSpinner: payload.showSpinner ?? false,
  title: payload.title,
  onClick: payload.onClick,
  type,
  force: payload.force,
})

const isToastInAir = (toasts, { message }) => {
  const it = toasts.values()
  let { value, done } = it.next()
  while (!done) {
    if (value.message == message) {
      return true
    }
    ;({ value, done } = it.next())
  }
}

const pushToast = (toasts, toast) => {
  if (toast.force || !isToastInAir(toasts, toast)) {
    toasts.set(toast.id, toast)
  }
}

const toastStoreModule = {
  namespaced: true,
  state: () => ({
    toasts: new Map(),
  }),
  actions: {
    error({ commit }, payload) {
      const toast = bakeToast(payload, 'error')
      commit('add', toast)
      return toast.id
    },
    warning({ commit }, payload) {
      const toast = bakeToast(payload, 'warning')
      commit('add', toast)
      return toast.id
    },
    success({ commit }, payload) {
      const toast = bakeToast(payload, 'success')
      commit('add', toast)
      return toast.id
    },
    info({ commit }, payload) {
      const toast = bakeToast(payload, 'info')
      commit('add', toast)
      return toast.id
    },
  },
  mutations: {
    add({ toasts }, toast) {
      pushToast(toasts, toast)
    },

    destroyToast({ toasts }, id) {
      toasts.delete(id)
    },

    clearToasts({ toasts }) {
      toasts.clear()
    },
  },
  getters: {
    toasts: state => state.toasts.values(),
  },
}

export default toastStoreModule
