import createPersistedState from 'vuex-persistedstate'

export const FeatureKind = {
  Shift: 'Shift',
  Location: 'Location',
}

const MARKER_ZOOM_THRESHOLDS = [14, 2]

const defaultState = () => ({
  selectedItemId: null,
  selectedItemType: null,
  trackedTransferId: null,
  routes: {
    /*
    id: {
      id :string,
      geojson :geojson
    } */
  },
  focusedRouteId: {},
  map: null,
  hiddenMarkerIds: new Set(),
  isSidebarOpened: false,
  showOnlyShiftMarkers: false,
  gpsHistory: null,
  isZoomLocked: false,
  isMobileLayout: false,
  locationsLayerEnabled: true,
  mapboxTrafficEnabled: true,
  shiftNotificationsEnabled: false,
  trackerClusteringZoomThreshold: 14,
})

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    mapFlyTo({ state }, { to, config }) {
      const latitude = parseFloat(to?.latitude)
      const longitude = parseFloat(to?.longitude)

      if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
        const flyOptions = {
          center: [longitude, latitude],
          speed: 1.8,
          zoom: 16,
          maxDuration: 4000,
          ...config,
        }
        if (state.isZoomLocked) {
          delete flyOptions.zoom
        }
        state.map?.flyTo(flyOptions)
        if (state.isMobileLayout) {
          state.isSidebarOpened = false
        }
      } else {
        console.warn('Invalid mapFlyTo call, NaN as coordinates')
      }
    },
  },
  mutations: {
    setSelectedShift(state, shiftId) {
      state.selectedItemKind = FeatureKind.Shift
      state.selectedItemId = shiftId
      if (state.isMobileLayout) {
        state.isSidebarOpened = false
      }
    },
    clearSelectedShift(state) {
      state.selectedItemKind = null
      state.selectedItemId = null
    },
    setSelectedLocation(state, locationId) {
      state.selectedItemKind = FeatureKind.Location
      state.selectedItemId = locationId
      if (state.isMobileLayout) {
        state.isSidebarOpened = false
      }
    },
    clearSelectedLocation(state) {
      state.selectedItemKind = null
      state.selectedItemId = null
    },
    clearSelection(state) {
      state.selectedItemKind = null
      state.selectedItemId = null
    },
    setFollowedMarker(state, id) {
      state.followedMarkerId = id
      if (state.isMobileLayout) {
        state.isSidebarOpened = false
      }
    },
    clearFollowedMarker(state) {
      state.followedMarkerId = null
    },
    setFocusedRoute(state, id) {
      state.focusedRouteId = id
      if (state.isMobileLayout) {
        state.isSidebarOpened = false
      }
    },
    clearFocusedRoute(state) {
      state.focusedRouteId = null
    },
    setSelectedVehicle(state, vehicleId) {
      state.selectedVehicleId = vehicleId
      if (state.isMobileLayout) {
        state.isSidebarOpened = false
      }
    },
    clearSelectedVehicle(state) {
      state.selectedVehicleId = null
    },
    addShiftJobRoute(state, route) {
      state.routes[route.id] = route
    },
    removeShiftJobRoute(state, routeId) {
      delete state.routes[routeId]
    },
    clearRoutes(state) {
      state.routes = {}
    },
    setMap(state, map) {
      state.map = map
    },
    setIsMobileLayout(state, value) {
      state.isMobileLayout = value
    },
    hideMarker(state, markerId) {
      state.hiddenMarkerIds.add(markerId)
      if (state.followedMarkerId === markerId) state.followedMarkerId = null
    },
    unhideMarker(state, markerId) {
      state.hiddenMarkerIds.delete(markerId)
    },
    hideMultipleMarkers(state, markerIds) {
      state.hiddenMarkerIds = new Set([...state.hiddenMarkerIds, ...markerIds])
    },
    unhideMultipleMarkers(state, markerIds) {
      state.hiddenMarkerIds = new Set(
        [...state.hiddenMarkerIds].filter(id => !markerIds.includes(id)),
      )
    },
    clearHiddenMarkers(state) {
      state.hiddenMarkerIds.clear()
    },
    openSidebar(state, value) {
      state.isSidebarOpened = value
    },
    toggleShowOnlyShiftMarkers(state) {
      state.showOnlyShiftMarkers = !state.showOnlyShiftMarkers
    },
    setGpsHistory(state, history) {
      state.gpsHistory = history
    },
    clearGpsHistory(state) {
      state.gpsHistory = null
    },
    appendPointToGpsHistory(state, point) {
      state.gpsHistory?.points?.push(point)
    },
    setIsSidebarOpened(state, value) {
      state.isSidebarOpened = value
    },
    setMapboxTrafficEnabled(state, value) {
      state.mapboxTrafficEnabled = value
    },
    toggleSidebar(state, value) {
      state.isSidebarOpened = value ?? !state.isSidebarOpened
    },
    toggleIsZoomLocked(state, value) {
      state.isZoomLocked = value ?? !state.isZoomLocked
    },
    toggleLocationsLayerEnabled(state, value) {
      state.locationsLayerEnabled = value ?? !state.locationsLayerEnabled
    },
    cycleTrackerClusteringZoomThreshold(state) {
      state.trackerClusteringZoomThreshold =
        MARKER_ZOOM_THRESHOLDS[
          (MARKER_ZOOM_THRESHOLDS.indexOf(state.trackerClusteringZoomThreshold) + 1) %
            MARKER_ZOOM_THRESHOLDS.length
        ]
    },
    toggleShiftNotificationsEnabled(state, value) {
      state.shiftNotificationsEnabled = value ?? !state.shiftNotificationsEnabled
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },
  getters: {
    focusedRouteId: state => state.focusedRouteId,
    followedMarkerId: state => state.followedMarkerId,
    gpsHistory: state => state.gpsHistory,
    hiddenDriverLocationIds: state =>
      new Set([...state.hiddenMarkerIds].filter(id => id.startsWith('DriverLocation:'))),
    hiddenMarkerIds: state => state.hiddenMarkerIds,
    hiddenTrackerLocationIds: state =>
      new Set([...state.hiddenMarkerIds].filter(id => id.startsWith('TrackerLocation:'))),
    isItemSelected: state => !!state.selectedItemKind && !!state.selectedItemId,
    isMobileLayout: state => state.isMobileLayout,
    isSidebarOpened: state => state.isSidebarOpened,
    isZoomLocked: state => state.isZoomLocked,
    locationsLayerEnabled: state => state.locationsLayerEnabled,
    mapboxTrafficEnabled: state => state.mapboxTrafficEnabled,
    mapCenter: state => state.mapCenter,
    selectedLocationId: state =>
      state.selectedItemKind === FeatureKind.Location ? state.selectedItemId : null,
    selectedShiftId: state =>
      state.selectedItemKind === FeatureKind.Shift ? state.selectedItemId : null,
    selectedVehicleId: state => state.selectedVehicleId,
    shiftJobRoutes: state => Object.values(state.routes),
    shiftNotificationsEnabled: state => state.shiftNotificationsEnabled,
    showOnlyShiftMarkers: state => state.showOnlyShiftMarkers,
    trackerClusteringZoomThreshold: state => state.trackerClusteringZoomThreshold,
  },
}

export const livePanelPersisted = createPersistedState({
  paths: [
    'livePanel.isSidebarOpened',
    'livePanel.isZoomLocked',
    'livePanel.locationsLayerEnabled',
    'livePanel.mapboxTrafficEnabled',
    'livePanel.shiftNotificationsEnabled',
    'livePanel.trackerClusteringZoomThreshold',
  ],
  filter(mutation) {
    if (!mutation.type.startsWith('livePanel')) return
    const triggers = [
      'livePanel/cycleTrackerClusteringZoomThreshold',
      'livePanel/openSidebar',
      'livePanel/setIsSidebarOpened',
      'livePanel/setMapboxTrafficEnabled',
      'livePanel/toggleIsZoomLocked',
      'livePanel/toggleLocationsLayerEnabled',
      'livePanel/toggleShiftNotificationsEnabled',
      'livePanel/toggleSidebar',
    ]
    return triggers.includes(mutation.type)
  },
})
