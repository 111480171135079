export const uuidRegExpAsString =
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}'

export const isUuid = value => value?.constructor === String && value.match(uuidRegExpAsString)
export const userWhitelistedPaths = ['dashboard']

export function getEventOrganizationUser(user, eventSlug) {
  if (user?.impersonatedCurrentOrganizationUser) {
    return (user?.impersonatedCurrentOrganizationUser?.activeEventOrganizationUsers || []).find(
      eou => eou.event?.slug == eventSlug,
    )
  } else {
    return (user?.currentOrganizationUser?.activeEventOrganizationUsers || []).find(
      eou => eou.event?.slug == eventSlug,
    )
  }
}

export function isAdmin(eou) {
  return eou?.roles?.includes('admin')
}

export function isUser(eou) {
  return eou?.roles?.includes('user')
}

export function checkAccessToPath(eou, eventPath) {
  if (eou?.roles?.includes('admin')) {
    return true
  } else if (eventPath?.length && !!eou && !eou?.isDisabled && !eou?.event?.archivedAt) {
    if (userWhitelistedPaths.includes(eventPath)) {
      return true
    } else {
      return (
        eou?.role?.enabledViewTypes?.includes(eventPath) ||
        eou?.additionalEnabledViewTypes?.includes(eventPath) ||
        false
      )
    }
  } else {
    return false
  }
}

export function checkAccessToEventRoute(eou, path, eventSlug) {
  let eventPath = path.replace(`/events/${eventSlug}/`, '')
  eventPath = eventPath
    .split('/')
    .filter(el => !!el && !isUuid(el))
    .join('/')
  return checkAccessToPath(eou, eventPath)
}

export function checkAccessToEventRouteByUser(user, route) {
  if (
    (
      user?.impersonatedCurrentOrganizationUser || user?.currentOrganizationUser
    )?.providedRoles?.includes('admin')
  ) {
    return true
  }
  const eou = getEventOrganizationUser(user, route.params.eventSlug)
  return checkAccessToEventRoute(eou, route.path, route.params.eventSlug)
}
