<script>
import { Dropdown } from 'bootstrap'
import { isAdmin } from 'frontend/common/access-helpers'
import { useModals } from 'frontend/common/modals'
import { useBlocker } from 'frontend/common/use-blocker'
import { EventOrganizationUsersService } from 'frontend/event-organization-users/event-organization-users-service'
import { EventsService } from 'frontend/events/events-service'
import CriteriaBuilderContainer from 'frontend/filters/containers/CriteriaBuilderContainer.vue'
// import ButtonComponent from 'frontend/roles/components/ButtonComponent.vue'
// import DropdownComponent from 'frontend/ui/components/DropdownComponent.vue'
import { camelCase } from 'lodash'
import { provide, ref } from 'vue'
import { mapGetters } from 'vuex'

// import DataTableSearchCriteriaSection from './data-table-search-criteria-section.vue'

export default {
  name: 'DataTableSearchCriteria',
  emits: ['update:filters', 'open', 'close', 'hidden'],
  props: {
    searchCriteria: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
    },
    height: {
      type: Number,
      default: 300,
    },
    standalone: {
      type: Boolean,
      default: true,
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
    tableName: {
      type: String,
    },
    withPresets: {
      type: Boolean,
      default: false,
    },
    footerTop: {
      type: Boolean,
      default: false,
    },
    useKeyboardShortcuts: {
      type: Boolean,
      default: true,
    },
    predicate: {
      type: String,
      default: null,
    },
    overwriteFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const eouService = new EventOrganizationUsersService()
    const eventsService = new EventsService()
    provide('selectAppendToBody', true)
    provide('height', `${props.height}px`)
    let initialFiltersValue = {}
    if (props.overwriteFilters) {
      initialFiltersValue = { ...props.filters }
    }
    const localFilters = ref({ ...initialFiltersValue })
    const dropdownTriggerRef = ref(null)
    const arrowsNavigableItemsContainerRef = ref(null)
    const isOpened = ref(false)
    const firstLevelFiltersSectionRef = ref(null)
    const windowWidth = ref(window.innerWidth)

    const reposition = () => {
      if (dropdownTriggerRef.value && isOpened) {
        Dropdown.getInstance(dropdownTriggerRef.value)?.update()
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      reposition()
    })

    const dropdownRef = ref(null)
    return {
      eouService,
      eventsService,
      ...useModals(),
      ...useBlocker(),
      localFilters,
      dropdownTriggerRef,
      firstLevelFiltersSectionRef,
      isOpened,
      resizeObserver,
      windowWidth,
      dropdownRef,
      arrowsNavigableItemsContainerRef,
    }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent']),
    ...mapGetters('session', { getEventOrganizationUser: 'eventOrganizationUser' }),
    eventOrganizationUser() {
      return this.getEventOrganizationUser(this.$route.params.eventSlug)
    },
    storedName() {
      return this.tableName || this.getParentNameIfTable(this.$parent)
    },
    noDefaultTableConfig() {
      return !this.eventOrganizationUser?.filterConfig?.[this.storedName]?.some(el => el?.default)
    },
    isSmallDevice() {
      return this.windowWidth <= 576 // sm bootstrap $grid-breakpoint
    },
    anyFilterSet() {
      return (
        this.filters &&
        Object.keys(this.filters).length &&
        Object.values(this.filters).some(x => x !== null)
      )
    },
    eouTableFilterConfigs() {
      return this.eventOrganizationUser?.filterConfig?.[this.storedName] || []
    },
    alphabeticalEouTableFilterConfigs() {
      return [...this.eouTableFilterConfigs].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
    },
    globalTableFilterConfigs() {
      return this.currentEvent?.globalFilterConfig?.[this.storedName] || []
    },
    alphabeticalGlobalTableFilterConfigs() {
      return [...this.globalTableFilterConfigs].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
    },
    defaultGlobalTableFilterConfig() {
      return this.globalTableFilterConfigs.find(globalConfig => globalConfig.default)
    },
    isAdmin() {
      return this.eventOrganizationUser && isAdmin(this.eventOrganizationUser)
    },
    isDisabled() {
      return !(this.eouTableFilterConfigs.length || this.globalTableFilterConfigs.length)
    },
    isDisabledForAdmin() {
      return !this.globalTableFilterConfigs.length
    },
  },
  watch: {
    filters() {
      this.localFilters = { ...this.filters }
    },
    isOpened(value) {
      if (value) {
        this.$eventHub.$on('mouseup', this.closeIfAutoClose)
        this.attachResizeObserver()
      } else {
        this.$eventHub.$off('mouseup', this.closeIfAutoClose)
        this.resizeObserver.disconnect()
      }
    },
  },
  mounted() {
    this.$eventHub.$on('windowResize', this.setWindowWidth)
    if (this.useKeyboardShortcuts) {
      this.$eventHub.$on('keydown', this.keyHandler)
    }
  },
  unmounted() {
    this.$eventHub.$off('mouseup', this.closeIfAutoClose)
    this.$eventHub.$off('windowResize', this.setWindowWidth)
    this.$eventHub.$off('keydown', this.keyHandler)
  },
  methods: {
    questionMarkEventHandler(event) {
      if (this.dropdownRef?.isOpen) {
        event.stopPropagation()
        event.preventDefault()
        this.arrowsNavigableItemsContainerRef
          ?.getElementsByClassName('arrows-navigable-item')
          ?.item(0)
          .focus()
      } else if (!this.isBehindOtherElement(this.dropdownTriggerRef)) {
        event.stopPropagation()
        event.preventDefault()
        this.dropdownRef.isOpen = true
      }
    },
    escapeKeyHandler(event) {
      if (
        event.target?.tagName?.toLowerCase() != 'input' &&
        event.srcElement?.tagName?.toLowerCase() != 'input'
      ) {
        event.stopPropagation()
        event.preventDefault()
        // this.dismiss()
      }
    },
    enterKeyHandler(event) {
      if (this.dropdownRef?.isOpen) {
        if (event.ctrlKey || event.shiftKey) {
          event.stopPropagation()
          event.preventDefault()
          this.setFilters()
        } else if (document.activeElement?.classList?.contains('arrows-navigable-item')) {
          event.stopPropagation()
          event.preventDefault()
          document.activeElement.click()
        }
      }
    },
    arrowKeysHandler(event) {
      if (this.dropdownRef?.isOpen) {
        if (
          (event.key == 'ArrowUp' || event.key == 'ArrowDown') &&
          document.activeElement?.classList?.contains('arrows-navigable-item')
        ) {
          event.preventDefault()
          event.stopPropagation()
          const items = [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...this.arrowsNavigableItemsContainerRef?.getElementsByClassName(
              'arrows-navigable-item',
            ),
          ]
          const activeIndex = items.findIndex(el => el == document.activeElement)
          if (
            (activeIndex == 0 && event.key == 'ArrowUp') ||
            (activeIndex == items.length - 1 && event.key == 'ArrowDown')
          ) {
            return
          } else if (event.key == 'ArrowUp') {
            items[activeIndex - 1].focus()
          } else {
            items[activeIndex + 1].focus()
          }
        } else if (
          event.key == 'ArrowRight' &&
          document.activeElement?.classList?.contains('arrows-navigable-item')
        ) {
          event.preventDefault()
          event.stopPropagation()
          document.activeElement.click()
        } else if (event.key == 'ArrowLeft') {
          if (
            document.activeElement?.classList?.contains('arrows-navigable-item') ||
            document.activeElement == document.body
          ) {
            const activatedItems =
              this.arrowsNavigableItemsContainerRef.getElementsByClassName('is-active')
            if (activatedItems.length) {
              const item = activatedItems[activatedItems.length - 1]
              item.focus()
              item.click()
            } else {
              this.arrowsNavigableItemsContainerRef
                ?.getElementsByClassName('arrows-navigable-item')
                ?.item(0)
                .focus()
            }
          }
        }
      }
    },
    keyHandler(event) {
      if (
        event.key === '?' &&
        event.target?.tagName?.toLowerCase() != 'input' &&
        event.srcElement?.tagName?.toLowerCase() != 'input'
      ) {
        this.questionMarkEventHandler(event)
      } else if (event.key === 'Escape' && this.dropdownRef?.isOpen) {
        this.escapeKeyHandler(event)
      } else if (this.dropdownRef?.isOpen && event.key === 'Enter') {
        this.enterKeyHandler(event)
      } else if (
        this.dropdownRef?.isOpen &&
        (event.key === 'ArrowDown' ||
          event.key === 'ArrowUp' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight')
      ) {
        this.arrowKeysHandler(event)
      }
    },
    isBehindOtherElement(element) {
      const boundingRect = element.getBoundingClientRect()
      const left = boundingRect.left + 1
      const right = boundingRect.right - 1
      const top = boundingRect.top + 1
      const bottom = boundingRect.bottom - 1

      if (!element.contains(document.elementFromPoint(left, top))) return true
      if (!element.contains(document.elementFromPoint(right, top))) return true
      if (!element.contains(document.elementFromPoint(left, bottom))) return true
      if (!element.contains(document.elementFromPoint(right, bottom))) return true

      return false
    },
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    deselect() {
      if (this.firstLevelFiltersSectionRef) {
        this.firstLevelFiltersSectionRef.deselect()
      }
    },
    getParentNameIfTable(comp, level = 0) {
      if (level > 10) {
        return null
      }
      if (comp.$options.name.endsWith('Table')) {
        return camelCase(comp.$options.name)
      }
      if (comp.$parent) {
        return this.getParentNameIfTable(comp.$parent, level + 1)
      }
      return null
    },
    attachResizeObserver() {
      // this.resizeObserver.observe(this.$refs.dropdownMenuRef)
    },
    closeIfAutoClose(event) {
      if (
        this.autoClose &&
        !this.$el.contains(event.target) &&
        !(
          typeof event.composedPath !== 'function' ||
          event.composedPath()?.some(el => el.classList?.contains('vs__dropdown-menu'))
        ) &&
        !(
          typeof event.composedPath !== 'function' ||
          event.composedPath()?.some(el => el.classList?.contains('flatpickr-calendar'))
        )
      ) {
        this.closeFn()
      }
    },
    closeFn() {
      if (this.dropdownTriggerRef) {
        Dropdown.getInstance(this.dropdownTriggerRef)?.hide()
      }
    },
    clear() {
      this.$emit('update:filters', {})
      this.localFilters = {}
      this.hide()
    },
    dismiss() {
      this.localFilters = { ...this.filters }
      this.hide()
    },
    setFilters() {
      let newFilters = Object.assign({}, this.filters, this.localFilters)
      if (!this.overwriteFilters) {
        newFilters = Object.assign({}, this.localFilters)
        this.localFilters = {}
      }
      this.$emit('update:filters', newFilters)
      this.hide()
    },
    setFiltersInResponseToUsedFiltersChange(filters) {
      const newFilters = Object.assign({}, this.filters, filters ? filters : {})
      this.$emit('update:filters', newFilters)
    },
    hide() {
      if (!this.dropdownRef) {
        return
      }
      this.dropdownRef.isOpen = false
      // const dropdown = Dropdown.getInstance(this.dropdownTriggerRef)
      // if (dropdown?.hide) {
      //   dropdown.hide()
      // }
    },
    setSystemDefaultConfig(closeFn) {
      this.$emit('update:filters', this.defaultGlobalTableFilterConfig.value)
      this.hide()
      if (closeFn) closeFn()
    },
    saveAs({ name } = {}) {
      if (!name) return
      const filterConfig = this.eventOrganizationUser.filterConfig || {}
      filterConfig[this.storedName] = filterConfig[this.storedName] || []
      const storedFilterConfig = filterConfig[this.storedName]?.find(tg => tg.name == name)
      const filtersWithValue = this.cleanEmpty(this.localFilters)
      if (storedFilterConfig) {
        storedFilterConfig.value = filtersWithValue
      } else {
        filterConfig[this.storedName].push({
          name,
          value: filtersWithValue,
        })
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot save config' })
      }
      this.saveUserFilterConfig(filterConfig, errorHandler).then(() => {
        this.$success({ message: 'Filter config saved' })
      })
    },
    async saveUserFilterConfig(filterConfig) {
      await this.eouService.saveFilterConfig(this.eventOrganizationUser.id, { filterConfig })
    },
    async saveGlobalFilterConfig(globalFilterConfig) {
      await this.eventsService.saveGlobalFilterConfig(this.currentEvent.id, { globalFilterConfig })
    },
    cleanEmpty(obj) {
      return Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
    },
    saveAsAdmin({ name } = {}) {
      if (!name) return
      const globalFilterConfig = this.currentEvent.globalFilterConfig || {}
      globalFilterConfig[this.storedName] = globalFilterConfig[this.storedName] || []
      const storedGlobalFilterConfig = globalFilterConfig[this.storedName]?.find(
        tg => tg.name == name,
      )
      const filtersWithValue = this.cleanEmpty(this.localFilters)
      if (storedGlobalFilterConfig) {
        storedGlobalFilterConfig.value = this.localFilters
      } else {
        globalFilterConfig[this.storedName].push({
          name,
          value: filtersWithValue,
        })
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot save config' })
      }
      this.saveGlobalFilterConfig(globalFilterConfig, errorHandler).then(() => {
        this.$success({ message: 'Filter config saved' })
      })
    },
    setMyConfig(filterConfig, closeFn) {
      this.$emit('update:filters', filterConfig?.value)
      this.hide()
      if (closeFn) closeFn()
    },
    removeMyConfig(filterConfig) {
      this.$dialogs.confirm('Are you sure that you want to remove chosen config?').then(() => {
        const newUserFilterConfig = this.eventOrganizationUser.filterConfig || {}
        newUserFilterConfig[this.storedName] = newUserFilterConfig[this.storedName] || []
        const i = newUserFilterConfig[this.storedName].findIndex(tg => tg.name == filterConfig.name)
        if (i > -1) {
          newUserFilterConfig[this.storedName].splice(i, 1)

          const errorHandler = () => {
            this.$error({ message: 'Cannot remove config' })
          }
          this.eouService
            .saveFilterConfig(
              this.eventOrganizationUser.id,
              { filterConfig: newUserFilterConfig },
              errorHandler,
            )
            .then(() => {
              this.$success({ message: 'Filter config removed' })
            })
        }
      })
    },
    removeGlobalConfig(globalFilterConfig) {
      this.$dialogs.confirm('Are you sure that you want to remove chosen config?').then(() => {
        const newGlobalFilterConfig = this.currentEvent.globalFilterConfig || {}
        newGlobalFilterConfig[this.storedName] = newGlobalFilterConfig[this.storedName] || []
        const i = newGlobalFilterConfig[this.storedName].findIndex(
          tg => tg.name == globalFilterConfig.name,
        )
        if (i > -1) {
          newGlobalFilterConfig[this.storedName].splice(i, 1)
          const errorHandler = () => {
            this.$error({ message: 'Cannot remove config' })
          }
          this.eventsService
            .saveGlobalFilterConfig(
              this.currentEvent.id,
              {
                globalFilterConfig: newGlobalFilterConfig,
              },
              errorHandler,
            )
            .then(() => {
              this.$success({ message: 'Filter config removed' })
            })
        }
      })
    },
    setDefault(name) {
      const filterConfig = this.eventOrganizationUser.filterConfig || {}
      filterConfig[this.storedName] = filterConfig[this.storedName] || []
      const usedDefaultFilterConfig = filterConfig[this.storedName]?.find(tg => tg.default == true)
      const storedFilterConfig = filterConfig[this.storedName]?.find(tg => tg.name == name)

      if (storedFilterConfig && storedFilterConfig == usedDefaultFilterConfig) {
        storedFilterConfig.default = false
      } else {
        if (usedDefaultFilterConfig) {
          usedDefaultFilterConfig.default = false
        }
        if (name) {
          if (storedFilterConfig) {
            storedFilterConfig.default = true
          } else {
            filterConfig[this.storedName].push({
              name,
              value: this.saveUserFilterConfig(this.localFilters),
            })
          }
        }
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot change default status' })
      }
      this.saveUserFilterConfig(filterConfig, errorHandler).then(() => {
        usedDefaultFilterConfig == storedFilterConfig
          ? this.$success({ message: 'Filter unset from default' })
          : this.$success({ message: 'Filter set as default' })
      })
    },
    setGlobalDefault({ name } = {}) {
      const { globalFilterConfig = {} } = this.currentEvent
      const globalTableConfigs = globalFilterConfig[this.storedName] || []
      const storedGlobalFilterConfig = globalTableConfigs?.find(tg => tg.name == name)
      const usedGlobalDefaultFilterConfig = globalTableConfigs?.find(tg => tg.default == true)

      if (storedGlobalFilterConfig == usedGlobalDefaultFilterConfig) {
        storedGlobalFilterConfig.default = false
      } else {
        if (usedGlobalDefaultFilterConfig && storedGlobalFilterConfig) {
          usedGlobalDefaultFilterConfig.default = false
          storedGlobalFilterConfig.default = true
        }
        if (storedGlobalFilterConfig) {
          storedGlobalFilterConfig.default = true
        } else {
          globalFilterConfig[this.storedName].push({
            name,
            value: this.saveGlobalFilterConfig(this.localFilters),
          })
        }
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot change default status' })
      }
      this.saveGlobalFilterConfig(globalFilterConfig, errorHandler).then(() => {
        usedGlobalDefaultFilterConfig == storedGlobalFilterConfig
          ? this.$success({ message: 'Filter unset from default' })
          : this.$success({ message: 'Filter set as default' })
      })
    },
  },
  components: {
    // ButtonComponent,
    // DropdownComponent,
    CriteriaBuilderContainer,
    // DataTableSearchCriteriaSection,
  },
}
</script>

<template lang="pug">
dropdown(ref="dropdownRef" :autoClose="false")
  template(#default="{ closeFn, show, isOpen }")
    .d-trigger.ms-2(ref="dropdownTriggerRef")
      slot(:anyFilterSet="anyFilterSet && isSmallDevice")
        .btn.btn-sm.fs-6.pb-0.pe-2.ps-2.pt-0(
          :class="anyFilterSet && isSmallDevice ? 'btn-outline-warning' : 'btn-outline-secondary'"
        )
          i(:class="[anyFilterSet && isSmallDevice ? 'bi-funnel-fill' : 'bi-funnel']")
  template(#items="{ closeFn, isOpen, maxHeight }")
    .criteria-builder-dropdown-content(ref="arrowsNavigableItemsContainerRef")
      CriteriaBuilderContainer(
        v-if="isOpen"
        :maxParentHeight="maxHeight"
        :searchCriteria="searchCriteria"
        :windowWidth="windowWidth"
        v-model="localFilters"
      )
        template(#empty-list-after)
          .small You can use keyboard shortcuts for navigation
          .shortcut-items
            div
            .shortcut-item(
              v-tooltip.options="{ title: 'Opens this dropdown or (if opened) focusses search input', placement: 'top' }"
            )
              .shortcut ?
            .shortcut-item(
              v-tooltip.options="{ title: 'Shortcut for SET button', placement: 'top' }"
            )
              .shortcut Shift + Enter
            .shortcut-item(
              v-tooltip.options="{ title: 'Shortcut for \"DISMISS\" button', placement: 'top' }"
            )
              .shortcut Esc
            .shortcut-item(
              v-tooltip.options="{ title: 'Removes last filter applied to the table', placement: 'top' }"
            )
              .shortcut Shift + Backspace
            .shortcut-item.d-flex.align-items-center.justify-content-center(
              v-tooltip.options="{ title: 'Navigates through list of criteria if any criterion or search input is focussed', placement: 'top' }"
            )
              .shortcut Arrow up
              .or OR
              .shortcut Arrow down
            .shortcut-item.d-flex.align-items-center.justify-content-center(
              v-tooltip.options="{ title: 'Activates currently focussed criterion', placement: 'top' }"
            )
              .shortcut Enter
              .or OR
              .shortcut Arrow right
            .shortcut-item(
              v-tooltip.options="{ title: 'Collapses the currently selected criteria sub-list or focusses search input', placement: 'top' }"
            )
              .shortcut Arrow left

      .filters-actions.d-flex.align-items-center.justify-content-between
        .d-flex.align-items-center.justify-content-between
          span(
            v-if="withPresets"
            v-tooltip.options="{ title: isDisabled ? 'No configs saved' : '', placement: 'bottom' }"
          )
            dropdown(:disabled="isDisabled")
              .btn.btn-sm.btn-outline-info.ms-1.px-1
                span.d-none.d-md-inline Config
                i.fas.fa-cog.fa-fw.d-md-none
              template(#items="{ closeFn }")
                h6.dropdown-header.bold(v-if="eouTableFilterConfigs.length") My configs
                li(v-for="savedFilterConfig in alphabeticalEouTableFilterConfigs")
                  a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1(
                    :disabled="!savedFilterConfig?.value?.length"
                    :name="savedFilterConfig.name"
                    @click.stop.prevent
                  )
                    div
                      | {{ savedFilterConfig.name }}
                    .text-end
                      button.datatable-preview-btn.btn.btn-link.text-primary.py-0.px-1(
                        @click="setDefault(savedFilterConfig.name)"
                        type="button"
                        v-tooltip.options="{ title: savedFilterConfig.default ? 'Is default' : 'Set as default', placement: 'left' }"
                      )
                        .hover-backdrop
                        i(:class="savedFilterConfig.default ? 'bi-check-square' : 'bi-square'")

                      data-table-show-button(
                        @click="setMyConfig(savedFilterConfig)"
                        tooltip="Load config"
                      )

                      data-table-remove-button(
                        @click="removeMyConfig(savedFilterConfig)"
                        noMarginBefore
                        rootClass="d-inline"
                        tooltip="Remove config"
                      )

                li(v-if="eouTableFilterConfigs.length && globalTableFilterConfigs.length")
                  hr.dropdown-divider
                h6.dropdown-header.bold(v-if="globalTableFilterConfigs.length") Global configs
                li(v-for="globalFilterConfig in alphabeticalGlobalTableFilterConfigs")
                  a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1(
                    :disabled="!globalFilterConfig?.value?.length"
                    :name="globalFilterConfig.name"
                    @click.stop.prevent
                  )
                    div
                      | {{ globalFilterConfig.name }}
                    .text-end
                      data-table-show-button(
                        @click="setMyConfig(globalFilterConfig)"
                        tooltip="Load config"
                      )
                li
                  a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1.text-primary(
                    v-if="!!defaultGlobalTableFilterConfig"
                    @click.prevent
                  )
                    div
                      | {{ defaultGlobalTableFilterConfig.name }} (global default)
                    .text-end
                      button.datatable-preview-btn.btn.btn-link.text-primary.py-0.px-1(
                        @click="setDefault()"
                        type="button"
                        v-tooltip.options="{ title: noDefaultTableConfig ? 'Is default' : 'Set as default', placement: 'left' }"
                      )
                        .hover-backdrop
                        i(:class="noDefaultTableConfig ? 'bi-check-square' : 'bi-square'")
                      data-table-show-button(
                        @click="setSystemDefaultConfig()"
                        tooltip="Load config"
                      )
          span(
            v-if="withPresets"
            v-tooltip.options="{ title: isDisabledForAdmin ? 'No global configs saved' : '', placement: 'bottom' }"
          )
            dropdown(v-if="isAdmin" :disabled="isDisabledForAdmin")
              .btn.btn-sm.btn-outline-danger.ms-1.px-1 Global
              template(#items="{ closeFn }")
                h6.dropdown-header.bold.small(v-if="globalTableFilterConfigs.length") Configured presets for every user
                li(v-for="globalFilterConfig in alphabeticalGlobalTableFilterConfigs")
                  a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1(
                    :disabled="!globalFilterConfig?.value?.length"
                    :name="globalFilterConfig.name"
                    @click.stop.prevent
                  )
                    div
                      | {{ globalFilterConfig.name }}
                    .text-end
                      data-table-show-button(
                        @click="setMyConfig(globalFilterConfig)"
                        tooltip="Load config"
                      )

                      button.datatable-preview-btn.btn.btn-link.text-primary.py-0.px-1(
                        @click="setGlobalDefault(globalFilterConfig)"
                        type="button"
                        v-tooltip.options="{ title: globalFilterConfig.default ? 'Is default' : 'Set as default', placement: 'left' }"
                      )
                        .hover-backdrop
                        i(:class="globalFilterConfig.default ? 'bi-check-square' : 'bi-square'")

                      data-table-remove-button(
                        @click="removeGlobalConfig(globalFilterConfig)"
                        noMarginBefore
                        rootClass="d-inline"
                        tooltip="Remove config"
                      )
          dropdown(v-if="withPresets")
            .btn.btn-sm.btn-outline-success.ms-1.px-1 Save as
            template(#items="{ closeFn }")
              div(v-if="!isAdmin")
                li(v-for="savedFilterConfig in alphabeticalEouTableFilterConfigs")
                  a.dropdown-item(
                    :disabled="!savedFilterConfig?.value?.length"
                    :name="savedFilterConfig.name"
                    @click="saveAs(savedFilterConfig)"
                  )
                    | {{ savedFilterConfig.name }}
                    span.ms-1.text-black-50.small(v-if="savedFilterConfig.default") (default)
                li(v-if="eouTableFilterConfigs?.length")
                  hr.dropdown-divider
                li
                  modal-button(
                    :item="{ name: '' }"
                    :modal="formModals.Name"
                    @modalClosed="$event?.length ? saveAs({ name: $event }) : null"
                    external
                    modalSize="sm"
                  )
                    template(#default="{ open }")
                      a.dropdown-item.text-primary(@click="open()" name="system") New set of filters

              dropdown.dropend(hoverable)
                .dropdown-item.d-flex.align-items-center.justify-content-between User configs
                  i.fa.fa-chevron-right
                template(v-if="isAdmin" #items="{ closeFn }")
                  li(v-for="savedFilterConfig in alphabeticalEouTableFilterConfigs")
                    a.dropdown-item(
                      :disabled="!savedFilterConfig?.value?.length"
                      :name="savedFilterConfig.name"
                      @click="saveAs(savedFilterConfig)"
                    )
                      | {{ savedFilterConfig.name }}
                      span.ms-1.text-black-50.small(v-if="savedFilterConfig.default") (default)
                  li(v-if="eouTableFilterConfigs.length")
                    hr.dropdown-divider
                  li
                    modal-button(
                      :item="{ name: '' }"
                      :modal="formModals.Name"
                      @modalClosed="saveAs({ name: $event })"
                      external
                      modalSize="sm"
                    )
                      template(#default="{ open }")
                        a.dropdown-item.text-primary(@click="open()" name="system") New set of filters

              dropdown.dropend(hoverable)
                .dropdown-item.d-flex.align-items-center.justify-content-between Global configs
                  i.fa.fa-chevron-right
                template(v-if="isAdmin" #items="{ closeFn }")
                  li(v-for="globalFilterConfig in alphabeticalGlobalTableFilterConfigs")
                    a.dropdown-item(
                      :disabled="!globalFilterConfig?.value?.length"
                      :name="globalFilterConfig.name"
                      @click="saveAsAdmin(globalFilterConfig)"
                    )
                      | {{ globalFilterConfig.name }}
                      span.ms-1.text-black-50.small(v-if="globalFilterConfig.default") (default)
                  li(v-if="globalTableFilterConfigs.length")
                    hr.dropdown-divider
                  li
                    modal-button(
                      :item="{ name: '' }"
                      :modal="formModals.Name"
                      @modalClosed="saveAsAdmin({ name: $event })"
                      external
                      modalSize="sm"
                    )
                      template(#default="{ open }")
                        a.dropdown-item.text-primary(@click="open()" name="system") New set of global filters
        div
          .btn.btn-sm.btn-outline-secondary.ms-1.px-1(@click="dismiss()" name="dismiss-filters") Dismiss
          .btn.btn-sm.btn-outline-danger.ms-1.px-1(@click="clear()" name="clear-filters") Clear
          .btn.btn-sm.btn-primary.ms-1(@click="setFilters()" name="propagate-filters" size="sm")
            span.d-none.d-md-inline SET
            i.fas.fa-check.fa-fw.d-md-none
</template>

<style scoped lang="scss">
.criteria-builder-dropdown-content {
  display: grid;
  gap: 8px;
  padding: 8px 16px;
  min-height: 0;
}
.shortcut-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}
.shortcut-item {
  padding: 0.1rem 0.2rem;
  font-size: 0.75rem;
  .or {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.5rem;
    padding: 0 0.1rem;
  }
  .shortcut {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    color: #c7c7c7;
    border: 2px solid #dadada;
    border-radius: 4px;
  }
}
</style>
