import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const VehicleCategoryDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'background_color',
    'border_color',
    'font_color',
    'created_at',
    'updated_at',
  ],
  subscriptions: {
    items: 'VehicleCategoriesChannel'
  },
  defaultColumns: [
    'name',
    'label'
  ],
  columnConfig: {
    name: {
      label: 'Name',
      header: {
        sort: 'lower_name',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'name',
      },
    },
    label: {
      label: 'Label',
      cell: {
        kind: 'component',
        component: 'erebus-label',
        componentProps: vehicleCategory => ({ item: vehicleCategory }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleCategory => ({
          t: vehicleCategory.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleCategory => ({
          t: vehicleCategory.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleCategory => ({
          t: vehicleCategory.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleCategory => ({
          t: vehicleCategory.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleCategory => ({
          t: vehicleCategory.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: vehicleCategory => ({
          t: vehicleCategory.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}