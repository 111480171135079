import { InterfaceType } from 'frontend/_setup/setupContainer/constants/InterfaceType'
import { IAvailableDataModelColumnItem } from 'frontend/dataModels/types/IAvailableDataModelColumnItem'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { inject, injectable } from 'inversify'

import { type IApiProvider } from '../../../apiProviders/types/IApiProvider'
import { DataModelType } from '../../../roles/enum/DataModelType'
import { IDataModelTypeStrategy } from '../../types/IDataModelTypeStrategy'
import { IDataModelTypeService } from './types/IDataModelTypeService'

@injectable()
export class DataModelTypeService implements IDataModelTypeService {
  constructor(
    @inject(InterfaceType.factories.DataModelTypeStrategyFactory)
    private dataModelTypeStrategyFactory: (dataModelType: DataModelType) => IDataModelTypeStrategy,
    @inject(InterfaceType.factories.DataModelTypeConfigStrategyFactory)
    private dataModelTypeConfigStrategyFactory: (
      dataModelType: DataModelType,
    ) => IDataModelTypeConfig,
    @inject(InterfaceType.constants.ApiProvider) private apiProvider: IApiProvider,
  ) {}

  async getSearchCriteriaByDataModelType(dataModelType: DataModelType): Promise<unknown> {
    const strategy = this.dataModelTypeStrategyFactory(dataModelType)
    const baseUrl = strategy.baseUrl
    const res = await this.apiProvider({
      method: 'post',
      url: `${baseUrl}/search_criteria`,
    })
    return res.data
  }

  getBaseUrl(dataModelType: DataModelType): string {
    const strategy = this.dataModelTypeStrategyFactory(dataModelType)
    return strategy.baseUrl
  }

  getAvailableColumnItems(dataModelType: DataModelType): IAvailableDataModelColumnItem[] {
    const strategy = this.dataModelTypeConfigStrategyFactory(dataModelType)
    const columnConfig = strategy.columnConfig || {}
    const keys = Object.keys(columnConfig)
    return keys.map(key => {
      return {
        key,
        name: columnConfig[key].label,
      }
    })
  }

  getExportableType(dataModelType: DataModelType): IDataModelTypeConfig['exportableType'] {
    const strategy = this.dataModelTypeConfigStrategyFactory(dataModelType)
    return strategy.exportableType
  }

  getFields(dataModelType: DataModelType): IDataModelTypeConfig['fields'] {
    const strategy = this.dataModelTypeConfigStrategyFactory(dataModelType)
    return strategy.fields
  }

  getSubscriptions(dataModelType: DataModelType): IDataModelTypeConfig['subscriptions'] {
    const strategy = this.dataModelTypeConfigStrategyFactory(dataModelType)
    return strategy.subscriptions
  }

  getDefaultColumns(dataModelType: DataModelType): IDataModelTypeConfig['defaultColumns'] {
    const strategy = this.dataModelTypeConfigStrategyFactory(dataModelType)
    return strategy.defaultColumns
  }

  getColumnConfig(dataModelType: DataModelType): IDataModelTypeConfig['columnConfig'] {
    const strategy = this.dataModelTypeConfigStrategyFactory(dataModelType)
    return strategy.columnConfig
  }

  canEditColumns(dataModelType: DataModelType): boolean {
    const strategy = this.dataModelTypeStrategyFactory(dataModelType)
    return strategy.canEditColumns !== false
  }
}
