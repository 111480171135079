import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { convertToFormData } from 'frontend/common/form-data-converter'
import { GET, POST, ResourceService } from 'frontend/common/services/resource-service'

export class DurationRulesService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/duration_rules`,
    blocker,
    fetchError = 'Failed to fetch Duration rule.',
    saveError = 'Failed to save Duration rule.',
    apiProvider = axios,
  } = {}) {
    super({ apiProvider, baseUrl, blocker, fetchError, saveError, blockerKey: 'durationRules' })
  }

  get downloadFixedTimeTemplate() {
    return this._downloadFixedTimeTemplate.bind(this)
  }

  get createBulkConnections() {
    return this._createBulkConnections.bind(this)
  }

  get createConnections() {
    return this._createConnections.bind(this)
  }

  get resolveRule() {
    return this._resolveRule.bind(this)
  }

  get previewRule() {
    return this._previewRule.bind(this)
  }
  get previewEventDefault() {
    return this._previewEventDefault.bind(this)
  }

  _downloadFixedTimeTemplate({ data, config, errorHandler = this._onFetchError }) {
    return this._makeCall(POST, errorHandler, {
      url: `${this.baseUrl}/download_fixed_time_template`,
      data,
      ...config,
    })
  }

  _createBulkConnections({ data, asFormData, config, errorHandler = this._onSaveError }) {
    let payload

    if (asFormData) {
      payload = convertToFormData('duration_rule', data)
      config = { ...config, headers: { 'Content-Type': 'multipart/form-data' } }
    } else {
      payload = { duration_rule: data }
    }

    return this._makeCall(POST, errorHandler, {
      url: `${this.baseUrl}/create_bulk_connections`,
      data: payload,
      ...config,
    })
  }

  _createConnections({ data, config, errorHandler = this._onSaveError }) {
    return this._makeCall(POST, errorHandler, {
      url: `${this.baseUrl}/create_connections`,
      data,
      ...config,
    })
  }

  _resolveRule(data, config, errorHandler = this._onFetchError) {
    return this._makeCall(
      POST,
      errorHandler,
      {
        url: `${this.baseUrl}/resolve_rule`,
        data,
        ...config,
      },
      true,
    )
  }

  _previewRule(data, config, errorHandler = this._onFetchError) {
    return this._makeCall(GET, errorHandler, {
      url: `${this.baseUrl}/${data.id}/preview_rule`,
      params: data,
      ...config,
    })
  }

  _previewEventDefault(data, config, errorHandler = this._onFetchError) {
    return this._makeCall(GET, errorHandler, {
      url: `${this.baseUrl}/preview_event_default`,
      params: data,
      ...config,
    })
  }
}
