import { defineAsyncComponent } from 'vue'

const ShuttleServiceImportFormModal = defineAsyncComponent(() =>
  import('./shuttle-service-import-form-modal.vue'),
)
const ShuttleServiceImportShow = defineAsyncComponent(() =>
  import('./shuttle-service-import-show.vue'),
)
const ShuttleServiceImportIndex = defineAsyncComponent(() =>
  import('./shuttle-service-import-index.vue'),
)
const ShuttleServiceImportTable = defineAsyncComponent(() =>
  import('./shuttle-service-import-table.vue'),
)
const STATES = [
  { value: 'idle', label: 'Idle' },
  { value: 'rows_loading', label: 'Loading rows' },
  { value: 'rows_not_loaded', label: 'Error while loading rows' },
  { value: 'rows_loaded', label: 'Rows loaded, waiting for the import' },
  { value: 'in_progress', label: 'Import in progress' },
  { value: 'finished', label: 'Import finished' },
  { value: 'finished_with_errors', label: 'Import finished (with errors)' },
  { value: 'major_error', label: 'Major error, cannot continue' },
]
export {
  ShuttleServiceImportFormModal,
  ShuttleServiceImportIndex,
  ShuttleServiceImportShow,
  ShuttleServiceImportTable,
  STATES,
}
