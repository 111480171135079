import { camelResponse } from 'axios-case-converter'
import consumer from 'channels/consumer'
import { normalize } from 'normalizr'

export default function normalizedSubscription(subscriptionPayload, list, schema, verbose = false) {
  if (verbose) console.log(subscriptionPayload.channel, 'will subscribe')
  return consumer.subscriptions.create(subscriptionPayload, {
    rejected() {
      console.warn(`REJECTED CONNECTION TO ${subscriptionPayload?.channel}`)
    },
    received: message => {
      if (verbose) console.log(subscriptionPayload.channel, 'message came in:', message)
      const i = Object.keys(list.entities[schema.key]).indexOf(message.id)
      if (i >= 0) {
        if (verbose)
          console.log(
            subscriptionPayload.channel,
            'message object is present on the proper entities list',
            message.id,
            i,
          )
        if (message.action == 'delete') {
          if (verbose)
            console.log(subscriptionPayload.channel, 'message is to delete, will remove the item')
          if (schema.key == 'items') {
            list.result.items.splice(i, 1)
          }
          delete list.entities[schema.key][message.id]
          return
        }
        if (verbose)
          console.log(
            subscriptionPayload.channel,
            'message is to create/update/touch, will update the item',
          )
        const result = normalize(camelResponse(JSON.parse(message.obj)), schema)
        Object.keys(result.entities).forEach(collectionKey => {
          Object.keys(result.entities[collectionKey] || {}).forEach(id => {
            list.entities[collectionKey][id] = result.entities[collectionKey][id]
          })
        })
      }
    },
  })
}
