import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import SystemNotificationTitle from 'frontend/system-notifications/system-notification-title.vue'
import SystemNotificationStatus from 'frontend/system-notifications/system-notification-status.vue'
import SystemNotificationDownloadButton from 'frontend/system-notifications/system-notification-download-button.vue'
import {User} from 'frontend/users'
import FormattedTime from 'frontend/common/formatted-time.vue'

export const SystemNotificationDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'read',
    'title',
    'status',
    'additional_payload',
    'content',
    'created_at',
    'actor',
    {
      system_action: [{ performed_by: ['email', 'name', 'surname'] }],
    },
  ],
  subscriptions: {
    items: 'UserSystemNotificationsDetailedChannel',
  },
  defaultColumns: [
    'read',
    'title',
    'status',
    'context',
    'actor',
    'createdAt'
  ],
  columnConfig: {
    read: {
      label: 'Read',
      header: {
        sort: 'read',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: notification => ({
          item: notification.read,
        }),
      },
    },
    title: {
      label: 'Title',
      header: {
        sort: 'title',
      },
      cell: {
        kind: 'component',
        component: markRaw(SystemNotificationTitle),
        componentProps: notification => ({ notification }),
      },
    },
    status: {
      label: 'Status',
      header: {
        sort: 'status',
      },
      cell: {
        kind: 'component',
        component: markRaw(SystemNotificationStatus),
        componentProps: notification => ({ notification }),
      },
    },
    context: {
      label: 'Context',
      header: {},
      cell: {
        kind: 'component',
        component: markRaw(SystemNotificationDownloadButton),
        componentProps: notification => ({ notification }),
      },
    },
    actor: {
      label: 'Actor',
      header: {
        sort: 'actorUsername',
      },
      cell: {
        kind: 'component',
        component: markRaw(User),
        componentProps: notification => ({
          user: notification.actor,
          placeholderText: 'System',
        }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.createdAt,
        }),
      },
    },
    createdAtDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdAtTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}