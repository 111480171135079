const modalsStoreModule = {
  namespaced: true,
  state: () => ({
    counter: 0,
    openedModals: {},
  }),
  mutations: {
    open(state, { name, modalRef }) {
      state.counter += 1
      state.openedModals[state.counter] = { name, modalRef }
    },
    close(state) {
      delete state.openedModals[state.counter]
      state.counter -= 1
    },
  },
  getters: {
    openedModals: state => state.openedModals,
    modalCounter: state => state.counter,
  },
}

export default modalsStoreModule
