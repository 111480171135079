<script>
export default {
  name: 'NewDataTable',
}
</script>

<template lang="pug">
table.new-table
  slot
</template>
