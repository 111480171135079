import { defineAsyncComponent } from 'vue'

// const Report = defineAsyncComponent(() => import('./report.vue'))
const ReportFormModal = defineAsyncComponent(() => import('./report-form-modal.vue'))
// const ReportDetails = defineAsyncComponent(() => import('./report-details.vue'))
// const ReportsIndex = defineAsyncComponent(() => import('./reports-index.vue'))
// const ReportsSelect = defineAsyncComponent(() => import('./reports-select.vue'))
const ReportsTable = defineAsyncComponent(() => import('./reports-table.vue'))
const ReportAasmState = defineAsyncComponent(() => import('./report-aasm-state.vue'))

const KINDS = {
  search_criteria: 'Search criteria',
  specific_records: 'Chosen rows',
}

const AASM_STATES = {
  pending: 'Pending',
  in_progress: 'In progress',
  ready: 'Ready',
  error: 'Error happened',
}

export {
  AASM_STATES,
  KINDS,
  ReportAasmState,
  // Report,
  // ReportDetails,
  ReportFormModal,
  // ReportsIndex,
  // ReportsSelect,
  ReportsTable,
}
