import ErebusLabel from 'frontend/common/erebus-label.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import RoleHasScopedDataModelsLabel from 'frontend/roles/components/RoleHasScopedDataModelsLabelComponent.vue'
import { markRaw } from 'vue'

export const RoleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'color',
    'created_at',
    'updated_at',
    'enabled_view_types',
    'description',
    'has_scoped_data_models',
    'number_of_enabled_data_models',
    'number_of_enabled_views',
    {
      roleScopes: [
        {
          roleScope: [
            'id',
            'actionTypes',
            'dataModelType',
            'scope',
            'filter',
            'predefinedCriteriaScopeId',
          ],
        },
      ],
      columnPresetRoles: [
        {
          columnPresetRole: ['id', 'columnPresetId', 'position'],
        },
      ],
    },
  ],
  subscriptions: {},
  defaultColumns: [
    'name',
    'description',
    'label',
    'numberOfEnabledDataModels',
    'numberOfEnabledViews',
    'hasScopedDataModels',
    'usageInCurrentEvent',
    'updatedAt',
  ],
  columnConfig: {
    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
        class: 'w-25',
      },
    },
    description: {
      label: 'Description',
      header: {
        sort: 'description',
      },
      cell: {
        kind: 'simple',
        path: 'description',
      },
    },
    label: {
      label: 'Color',
      header: {},
      cell: {
        kind: 'component',
        component: markRaw(ErebusLabel),
        componentProps: role => ({ item: { name: role.name, backgroundColor: role.color } }),
      },
    },
    hasScopedDataModels: {
      label: 'Scope',
      header: {},
      cell: {
        kind: 'component',
        component: markRaw(RoleHasScopedDataModelsLabel),
        componentProps: role => ({ item: role.hasScopedDataModels }),
      },
    },
    numberOfEnabledDataModels: {
      label: 'Data models',
      header: {},
      cell: {
        kind: 'function',
        fn: item => {
          return `${item.numberOfEnabledDataModels} model${
            item.numberOfEnabledDataModels == 1 ? '' : 's'
          }`
        },
      },
    },
    numberOfEnabledViews: {
      label: 'Views',
      header: {},
      cell: {
        kind: 'function',
        fn: item => {
          return `${item.numberOfEnabledViews} view${item.numberOfEnabledViews == 1 ? '' : 's'}`
        },
      },
    },
    usageInCurrentEvent: {
      label: 'Usage in current event',
      header: {},
      cell: {
        kind: 'simple',
        path: 'usageInEvent',
      },
    },
    updatedAt: {
      label: 'Updated At',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item?.updatedAt }),
      },
    },
  },
}
