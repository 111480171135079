import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {Driver} from 'frontend/drivers'
import FormattedTime from 'frontend/common/formatted-time.vue'

export const DriverCalendarEntryDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'start_at',
    'end_at',
    'kind',
    'created_at',
    'updated_at',
    {
      driver: [
        'id',
        'name',
        'surname',
        'assigned_phone',
        'personal_phone',
        'primary_phone',
        { cluster: ['id', 'name', 'time_zone'] },
        { driver_category: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
        { driver_source: ['id', 'name'] },
      ],
    },
  ],
  subscriptions: {
    items: 'CalendarEntriesDriverAvailabilitiesChannel',
    drivers: 'DriversSimpleChannel',
    clusters: 'ClustersSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'driver',
    'startAt',
    'endAt'
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    driver: {
      label: 'Driver',
      header: {
        sort: 'driverId',
      },
      cell: {
        kind: 'component',
        component: markRaw(Driver),
        componentProps: entry => ({ driver: entry.driver, detailed: true }),
      },
    },
    startAt: {
      label: 'Start Datetime',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({ t: entry?.startAt, tz: entry.driver?.cluster?.timeZone }),
      },
    },
    startTime: {
      label: 'Start Time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry?.startAt,
          tz: entry.driver?.cluster?.timeZone,
          format: 'timeOnly',
        }),
      },
    },
    startDate: {
      label: 'Start Date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry?.startAt,
          tz: entry.driver?.cluster?.timeZone,
          format: 'dateOnly',
        }),
      },
    },
    endAt: {
      label: 'End Datetime',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({ t: entry?.endAt, tz: entry.driver?.cluster?.timeZone }),
      },
    },
    endTime: {
      label: 'End Time',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry?.endAt,
          tz: entry.driver?.cluster?.timeZone,
          format: 'timeOnly',
        }),
      },
    },
    endDate: {
      label: 'End Date',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry?.endAt,
          tz: entry.driver?.cluster?.timeZone,
          format: 'dateOnly',
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}