import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const PredefinedCriteriaScopeDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'filter',
    'data_model_type',
  ],
  subscriptions: {

  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}