import { checkAccessToPath, isAdmin } from 'frontend/common/access-helpers'
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export function useEventAccess() {
  const route = useRoute()
  const store = useStore()

  const currentEou = computed(() => {
    const user = store.state.session.user
    if (user?.impersonatedCurrentOrganizationUser) {
      return (user?.impersonatedCurrentOrganizationUser?.activeEventOrganizationUsers || []).find(
        eou => eou.event?.slug == route.params.eventSlug,
      )
    } else {
      return (user?.currentOrganizationUser?.activeEventOrganizationUsers || []).find(
        eou => eou.event?.slug == route.params.eventSlug,
      )
    }
    // return (user?.currentOrganizationUser?.activeEventOrganizationUsers || []).find(
    //   eou => eou.event?.slug == route.params.eventSlug,
    // )
  })

  const computedIsAdmin = computed(() => {
    return isAdmin(currentEou.value)
  })

  const canSeePath = path => {
    return checkAccessToPath(path, currentEou)
  }

  const can = (actionOrPath, dataModelType = null) => {
    // console.log(
    //   'can',
    //   actionOrPath,
    //   dataModelType,
    //   computedIsAdmin.value,
    //   currentEou.value,
    //   computedIsAdmin.value ||
    //     !![
    //       ...(currentEou.value?.role?.roleScopes || []),
    //       ...(currentEou.value?.additionalRoleScopes || []),
    //     ].find(
    //       el => el.dataModelType == dataModelType && (el.actionTypes || []).includes(actionOrPath),
    //     ),
    // )
    if (computedIsAdmin.value) {
      return true
    } else if (!dataModelType) {
      return checkAccessToPath(currentEou.value, actionOrPath)
    } else {
      return !![
        ...(currentEou.value?.role?.roleScopes || []),
        ...(currentEou.value?.additionalRoleScopes || []),
      ]?.find(
        el => el.dataModelType == dataModelType && (el.actionTypes || []).includes(actionOrPath),
      )
    }
  }

  provide('isAdmin', computedIsAdmin)
  provide('can', can)
  provide('canSeePath', canSeePath)

  return {
    currentEou,
    can,
    canSeePath,
  }
}
