import { helpers } from '@vuelidate/validators'

export default (startTime, endTime) => {
  return helpers.withParams({ type: 'timeBetween', startTime, endTime }, value => {
    if (value?.length) {
      const splitted = value.split(':')
      const providedMinutes = parseInt(splitted[0]) * 60 + parseInt(splitted[1])

      const splittedStart = startTime.split(':')
      const providedStartMinutes = parseInt(splittedStart[0]) * 60 + parseInt(splittedStart[1])

      const splittedEnd = endTime.split(':')
      const providedEndMinutes = parseInt(splittedEnd[0]) * 60 + parseInt(splittedEnd[1])

      if (providedStartMinutes > providedEndMinutes) {
        // over midnight
        return providedMinutes > providedStartMinutes || providedMinutes < providedEndMinutes
      } else if (providedStartMinutes < providedEndMinutes) {
        // normal
        return providedMinutes > providedStartMinutes && providedMinutes < providedEndMinutes
      } else {
        // there is only one time that is forbidden
        return providedMinutes != providedStartMinutes
      }
    }
    return value != startTime && value != endTime
  })
}
