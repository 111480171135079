<script setup lang="ts">
import { useTimestamp } from '@vueuse/core'
import { capitalizeFirstLetter } from 'frontend/common/functions/capitalizeFirstLetter'
import { useFormErrors } from 'frontend/errors/composables/useFormErrors'
import { computed, ref, watch } from 'vue'

interface Props {
  attributeKey: string | string[]
  disappear?: number
}
const props = defineProps<Props>()

const { errorFieldMap, mappings, isShaking } = useFormErrors()

const attributeKeys = computed(() => {
  return Array.isArray(props.attributeKey) ? props.attributeKey : [props.attributeKey]
})

const errorMessage = computed(() => {
  for (const attributeKey of attributeKeys.value) {
    if (!errorFieldMap.value.has(attributeKey)) {
      continue
    }
    const reasonText = errorFieldMap.value.get(attributeKey)[0]
    const foundMapping = mappings.find(mapping => mapping[0] === attributeKey)
    let attributeName = attributeKey
    if (foundMapping) {
      attributeName = foundMapping[1]
    }
    if (attributeName) {
      return `${capitalizeFirstLetter(attributeName)} ${reasonText}`
    }
    return capitalizeFirstLetter(reasonText)
  }
  return null
})

const randomDelay = Math.random() * 1

const lastErrorFieldMapUpdateAt = ref(new Date())

watch(errorFieldMap, value => {
  if (value) {
    lastErrorFieldMapUpdateAt.value = new Date()
  }
})
const timestamp = useTimestamp()
const canShowErrorMessage = computed(() => {
  return (
    errorMessage.value &&
    timestamp.value - lastErrorFieldMapUpdateAt.value.getTime() < (props.disappear || Infinity)
  )
})
</script>

<template>
  <div
    v-if="errorMessage && canShowErrorMessage"
    class="form-error-message"
    :class="{ 'is-shaking': isShaking }"
    :style="{ animationDelay: `-${randomDelay}s` }"
  >
    {{ errorMessage }}
  </div>
</template>

<style scoped lang="scss">
.form-error-message {
  color: #eb5757;
  font-size: 11px;
  font-weight: 600;

  &.is-shaking {
    animation: shake 0.5s infinite;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}
</style>
