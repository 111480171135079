<script>
export default {
  name: 'EaChooser',
  emits: ['update:modelValue'],
  props: {
    options: { type: Object },
    modelValue: { type: [String, Boolean] },
    value: { type: String, default: 'value' },
    colorized: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledValues: { type: Array, default: () => [] },
    name: { type: String, required: true },
    label: { type: String, default: '' },
    validators: { type: Object, default: null },
    stretch: { type: Boolean, default: false },
    slim: { type: Boolean, default: false },
    nowrap: { type: Boolean, default: true },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  computed: {
    itemStyle() {
      if (this.stretch) {
        return { flexGrow: 1, width: `calc(100% / ${this.options.length})` }
      }
      return { flexGrow: 0 }
    },
  },
  methods: {
    itemClass(option) {
      const { value, activeClass } = option
      return {
        active: this.modelValue === value,
        [activeClass]: this.modelValue === value,
        slim: this.slim,
        'text-nowrap': this.nowrap,
      }
    },
  },
}
</script>

<template lang="pug">
form-control-container(
  :additional-errors="additionalErrors"
  :label="label"
  :name="name"
  :validators="validators"
  :value="modelValue"
  hideInvalidIcon
)
  .options-container.d-flex.justify-content-evenly
    .ea-chooser_option-container(
      v-for="option in options"
      :style="itemStyle"
      v-tooltip="option.tooltip"
    )
      button.btn.ea-chooser_option-button.w-100(
        :class="itemClass(option)"
        :disabled="disabled || (disabledValues || []).includes(option.value)"
        :id="option.value"
        :name="name"
        @click="$emit('update:modelValue', option.value)"
        type="button"
      ) {{ option.label }}
</template>
