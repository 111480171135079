<script>
import FileUrl from 'frontend/common/file-url.vue'
import { NOTIFICATIONS, STATUSES } from 'frontend/system-notifications'

export default {
  name: 'SystemNotificationBody',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notifications: NOTIFICATIONS,
      statuses: STATUSES,
    }
  },
  computed: {
    progress() {
      if (
        this.notification?.additionalPayload?.totalRows &&
        (this.notification?.additionalPayload?.currentRow || 0) >= 0
      ) {
        return Math.floor(
          (parseFloat(this.notification?.additionalPayload?.currentRow || 0) /
            this.notification?.additionalPayload?.totalRows) *
            100,
        )
      } else if (this.notification?.additionalPayload?.progress) {
        return this.notification?.additionalPayload?.progress
      } else {
        return null
      }
    },
    // payloadKeys() {
    //   return Object.keys(this.notification.additionalPayload?.filter => !!this.notification.additionalPayload?.])   // },
    payloadHasError() {
      return (this.notification.additionalPayload?.error || '').toString().length
    },
    payloadHasDuplicates() {
      return !!this.notification.additionalPayload?.duplicates
    },
    payloadHasErrors() {
      return (
        !!this.notification.additionalPayload?.errors ||
        !!this.notification.additionalPayload?.parsingErrors ||
        !!this.notification.additionalPayload?.deletionErrors
      )
      // return (
      //   (this.payloadKeys || []).includes('errors') ||
      //   (this.payloadKeys || []).includes('parsing_errors') ||
      //   (this.payloadKeys || []).includes('deletion_errors')
      //   // this.payloadKeys?.errors?.length ||
      //   // this.payloadKeys?.parsingErrors?.length ||
      //   // (this.payloadKeys?.parsingErrors && Object.keys(this.payloadKeys?.parsingErrors).length) ||
      //   // (this.payloadKeys?.deletionErrors && Object.keys(this.payloadKeys?.deletionErrors).length)
      // )
    },
    payloadRequiredFulfillment() {
      return !!this.notification.additionalPayload?.requiredFulfillment
      // (this.payloadKeys || []).includes('required_fulfillment')
      // this.payloadKeys?.requiredFulfillment?.length
    },
    payloadWarnings() {
      return this.payloadRequiredFulfillment || this.notification.status === 'finished_with_warning'
    },
    finishedWithError() {
      return this.notification.status === 'finished_with_error'
    },
    finished() {
      return this.notification.status === 'finished'
    },
    read() {
      return this.notification.read
    },
    humanizedTitle() {
      if (this.notification.title) {
        return this.notifications[this.notification.title]
      }
      return this.notification.content
    },
    humanizedStatus() {
      return this.statuses[this.notification.status]
    },
  },
  components: {
    FileUrl,
  },
}
</script>

<template lang="pug">
.d-flex.align-items-start.justify-content-between.notification-content.w-100(
  :class="{ 'text-dark': !read, 'text-black-30': read, 'text-danger': finishedWithError || payloadHasError, 'text-warning': payloadWarnings || payloadHasErrors, 'not-allowed': !finished || payloadHasError }"
)
  .flex-grow-1
    b
      | {{ notification?.additionalPayload?.preTitle }}{{ humanizedTitle }} - {{ humanizedStatus }} {{ payloadHasErrors ? '(with errors)' : '' }}
      //- | {{ payloadHasError ? '(error)' : '' }}
      | {{ payloadRequiredFulfillment ? '(required fulfillment)' : '' }}
      span.text-nowrap.text-danger {{ payloadHasDuplicates ? `(${this.notification.additionalPayload?.duplicates} DUPLICATE${this.notification.additionalPayload?.duplicates != 1 ? 'S' : ''} FOUND!)` : '' }}
    .mt-1.d-flex.align-items-center.justify-content-start(
      v-if="![null, 100].includes(progress) && !['finished_with_warning', 'finished_with_error', 'finished'].includes(notification?.status)"
    )
      .progress(style="width: 50%")
        .progress-bar.progress-bar-striped.progress-bar-animated.bg-success(
          :style="{ width: `${progress}%` }"
        )
      .ps-2.small(style="width: 50%")
        span {{ progress }}%
        span.ms-1(v-if="notification?.additionalPayload?.totalRows") ({{ notification?.additionalPayload?.currentRow || 0 }} of {{ notification?.additionalPayload?.totalRows }})

    .mt-1.small.text-danger(
      v-else-if="notification.status == 'finished_with_error' && payloadHasError && this.notification.additionalPayload?.error?.constructor == String"
    )
      | {{ this.notification.additionalPayload?.error }}

    .mt-1(v-else-if="notification.status == 'in_progress'")
      .progress(style="width: 50%")
        .progress-bar.progress-bar-striped.progress-bar-animated.bg-success(style="width: 100%")
      .pl-2.pt-1.small(v-if="notification?.additionalPayload?.totalRows" style="width: 50%") {{ notification?.additionalPayload?.totalRows }} row(s) total

    .mt-1.position-relative(v-if="notification?.additionalPayload?.downloadUrl")
      file-url(:url="notification?.additionalPayload?.downloadUrl" label="Download")

    .mt-1.small(
      v-else-if="notification?.additionalPayload?.totalRows && notification.status != 'in_progress'"
    ) {{ notification?.additionalPayload?.totalRows }} row(s) total

  .ms-2.text-black-15.text-nowrap.small.text-end
    formatted-time(:t="notification.createdAt")
</template>

<style lang="scss" scoped>
.progress {
  height: 0.4rem;
}

.notification-content {
  padding: 0.75rem 0.5rem;
}

.not-allowed {
  cursor: not-allowed !important;
}
</style>
