import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class ClientGroupsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/client_groups`,
    blocker,
    fetchError = 'Failed to fetch client group.',
    saveError = 'Failed to save client group.',
    apiProvider = axios,

    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'client_groups',
    }),
  } = {}) {
    super({ apiProvider, baseUrl, blocker, fetchError, saveError })
    this.selectService = selectService
  }

  // get getForSelect() {
  //   return () =>
  //     this.selectService.fetchOptions({
  //       sorts: 'lower_name asc',
  //       fields: ['id', 'name', 'background_color', 'border_color', 'font_color'],
  //     })
  // }
  getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        sorts: 'lower_name asc',
        fields: ['id', 'name', 'background_color', 'border_color', 'font_color'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }
}
