import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const ClientCategoryDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'ancestry',
    'full_ancestry_path',
    'group_path',
    'remote_id',
    'updated_at',
    'created_at',
  ],
  subscriptions: {
    items: 'ClientCategoriesChannel'
  },
  defaultColumns: [
    'name',
    'groupPath',
    'remoteId'
  ],
  columnConfig: {
    name: {
      label: 'Name',
      header: {
        sort: 'lower_name',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'name',
      },
    },
    groupPath: {
      label: 'Group Path',
      header: {
        sort: 'groupPath',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'groupPath',
      },
    },
    remoteId: {
      label: 'Remote ID',
      header: {
        sort: 'remoteId',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'remoteId',
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientCategory => ({
          t: clientCategory.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientCategory => ({
          t: clientCategory.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientCategory => ({
          t: clientCategory.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientCategory => ({
          t: clientCategory.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientCategory => ({
          t: clientCategory.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientCategory => ({
          t: clientCategory.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}