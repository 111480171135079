import { isEqual } from 'lodash'
import { markRaw, ref } from 'vue'
import { useStore } from 'vuex'

export function ensureSingleMapContext(props) {
  const { apiKey, region, version, language, libraries } = markRaw(props)
  const mapOptions = { apiKey, region, version, language, libraries }
  const previousOptions = useStore().getters['mapsContext/getGoogleOptions']
  const invalidGoogleMapsOptions = ref(false)

  if (previousOptions?.apiKey && !isEqual(previousOptions, mapOptions)) {
    invalidGoogleMapsOptions.value = true
  } else if (!previousOptions?.apiKey) {
    useStore().commit('mapsContext/cacheGoogleOptions', mapOptions)
  }

  return {
    invalidGoogleMapsOptions,
  }
}
