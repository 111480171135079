import { computed, provide, reactive } from 'vue'

export function useBlocker() {
  const blocker = reactive({})

  provide('blocker', blocker)

  const isBlocked = computed(() => {
    return Object.values(blocker).reduce((prev, next) => prev || next, false)
  })

  provide('isBlocked', isBlocked)

  return { blocker, isBlocked }
}
