import consumer from 'channels/consumer'

let counterRefetchOnConnect = false
let currentlyFetching = false

const counterStoreModule = {
  namespaced: true,
  state: () => ({
    counter: null,
    cableSubscription: null,
  }),
  getters: {
    counter: state => state.counter,
  },
  mutations: {
    setCounter(state, counter) {
      state.counter = counter
    },
    setCableSubscription(state, value) {
      state.cableSubscription = value
    },
  },
  actions: {
    unsubscribe({ commit, state }) {
      return new Promise(resolve => {
        if (state.cableSubscription) {
          const remoteSubscription = consumer.subscriptions.subscriptions.find(
            s => s.customIdentifier == state.cableSubscription.customIdentifier,
          )
          if (remoteSubscription) {
            console.log(`%c counter will unsubscribe from its channel`, 'color: gray')
            consumer.subscriptions.remove(remoteSubscription)
          }
          // state.cableSubscription.unsubscribe()
          commit('setCableSubscription', null)
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },

    subscribe({ state, commit, dispatch }, eventId) {
      return new Promise(resolve => {
        dispatch('unsubscribe').then(() => {
          const customIdentifier = Math.round(Math.random() * 1000000)
          const cableSubscription = consumer.subscriptions.create(
            {
              channel: 'ClientCommunicationMessagesNotSentCounterChannel',
              event_id: eventId,
              fields: ['counter', 'id'],
              customIdentifier,
            },
            {
              customIdentifier,
              connected() {
                if (counterRefetchOnConnect) {
                  counterRefetchOnConnect = false
                  dispatch('fetch', { slug: state.event?.slug })
                }
              },
              disconnected() {
                counterRefetchOnConnect = true
              },
              rejected() {
                console.warn('REJECTED CONNECTION TO NOT SENT CCM COUNTER CHANNEL')
              },
              received(message) {
                if (!message.obj) {
                  dispatch('unsubscribe')
                  commit('setCounter', null)
                } else {
                  commit('setCounter', message.obj.counter || 0)
                }
              },
            },
          )
          commit('setCableSubscription', cableSubscription)
          resolve(true)
        })
      })
    },
    fetch({ commit, dispatch }, { slug }) {
      return new Promise(resolve => {
        if (currentlyFetching) {
          return
        }
        if (!slug || !slug.length) {
          dispatch('unsubscribe')
          commit('setCounter', null)
          resolve(null)
        } else {
          currentlyFetching = true
          this.axios({
            method: 'post',
            url: `events/${slug}/client_communication_messages/not_sent_counter`,
          })
            .then(response => {
              currentlyFetching = false
              if (!response.data) {
                dispatch('unsubscribe')
                commit('setCounter', null)
              } else {
                commit('setCounter', response.data.counter)
                dispatch('subscribe', response.data.id).then(() => {
                  resolve(response.data)
                })
              }
            })
            .catch(error => {
              console.error("Can't fetch the not sent messages counter", error)
              commit('setCounter', null)
              currentlyFetching = false
              resolve(null)
            })
        }
      })
    },
  },
}

export default counterStoreModule
