import {createInjectionState} from '@vueuse/core'
import {ResourceDataModelType} from 'frontend/dataModels/enum/ResourceDataModelType'

const [useProvideFilterScope, useFilterScopeWithoutDefaultValue] = createInjectionState((resourceDataModelType: ResourceDataModelType) => {
  return {
    resourceDataModelType,
  }
})

function useFilterScope() {
  return useFilterScopeWithoutDefaultValue() ?? {resourceDataModelType: ResourceDataModelType.Event}
}

export {useProvideFilterScope, useFilterScope}