<script>
import { ResourceService } from './services/resource-service'

export default {
  name: 'ArchiveItemButton',
  inheritAttrs: false,
  emits: ['archived', 'archive', 'blocker', 'archive-error'],
  props: {
    item: { type: Object },
    path: {
      type: String,
    },
    service: {
      type: ResourceService,
    },
    table: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmAction(action) {
      this.$dialogs.confirm('Are you sure that you want to archive chosen item?').then(action)
    },
    archiveItem() {
      if (this.path) {
        this.$emit('blocker', true)
        this.axios({
          method: 'post',
          url: this.path,
        })
          .then(() => {
            this.$success({ message: 'The item has been archived' })
            this.$emit('archived', true)
          })
          .catch(err => {
            console.error(err)
            const message =
              err?.response?.data?.errors?.base?.join('; ') ?? "Can't archive the item"
            this.$error({ message })
            this.$emit('archive-error', err?.response)
          })
          .finally(() => {
            this.$emit('blocker', false)
          })
      } else {
        this.$emit('archive')
      }
    },
    onArchiveClick() {
      this.confirmAction(this.archiveItem)
    },
  },
}
</script>

<template lang="pug">
.d-inline-block(@click="onArchiveClick($event)")
  slot
    template(v-if="table")
      data-table-archive-button(v-bind="$attrs")
    template(v-else)
      archive-button(v-bind="$attrs")
</template>

<style scoped lang="scss"></style>
