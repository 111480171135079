<script>
import { padStart } from 'lodash'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SeparatedDatetimepicker',
  inheritAttrs: false,
  emits: ['update:modelValue', 'finished-picking'],
  props: {
    clearable: { type: Boolean, default: true },
    skipDirtyCheck: { type: Boolean, default: false },
    label: { type: String, default: null },
    name: { type: String, required: true },
    modelValue: { type: String },
    timePercentageWidth: { type: Number, default: 45 },
    timeZone: {
      type: String,
      default: null,
    },
    defaultDate: {
      type: Object,
      default: () => dayjs().startOf('second'),
      validator(v) {
        if (!v) return true
        return dayjs.isDayjs(v)
      },
    },
    timeZoneNotMine: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validators: { type: Object, default: null },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
    inputSize: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['sm', 'lg'].includes(val)
      },
    },
    initialConfig: {
      type: Object,
      default: () => ({}),
    },
    disableLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const teleportIsSet = ref(false)
    const $store = useStore()
    const teleportId = ref(
      `picker-errors-teleport-${props.name}-${Math.random().toString()}`
        .replace(/\./gi, '-')
        .toLowerCase(),
    )

    const properTimeZone = computed(() => {
      return props.timeZone || $store.state.session.timeZone
    })

    const tmpTimeValue = ref(
      props.modelValue?.length
        ? dayjs(props.modelValue).tz(properTimeZone.value).format('HH:mm')
        : null,
    )

    return {
      blurringTimeInput: ref(false),
      properTimeZone,
      tmpTimeValue,
      teleportId,
      teleportIsSet,
    }
  },
  computed: {
    dateValue: {
      get() {
        if (!this.modelValue?.length) return null
        return this.modelValue
      },
      set(newVal) {
        let properNewVal
        if (!newVal?.length) {
          this.$emit('update:modelValue', newVal)
          return
        }
        if (this.modelValue?.length) {
          const oldVal = dayjs(this.modelValue).tz(this.properTimeZone)
          const newValInTz = dayjs(newVal).tz(this.properTimeZone)
          properNewVal = dayjs(
            `${newValInTz.format('YYYY-MM-DD')}T${oldVal.format('HH:mm:00')}${newValInTz.format(
              'Z',
            )}`,
          )
            .utc()
            .format()
        } else {
          properNewVal = dayjs(newVal).utc().format()
        }

        if (properNewVal != this.modelValue) {
          this.$emit('update:modelValue', properNewVal)
        }

        if (
          !(
            document.activeElement &&
            document.activeElement != document.body &&
            ![...(this.$refs.dateEl?.$el?.getElementsByTagName('input') || [])].includes(
              document.activeElement,
            )
          )
        ) {
          if (this.blurringTimeInput) {
            this.blurringTimeInput = false
            return
          }
          this.$nextTick(() => {
            this.$refs.timeEl?.$refs?.pureInputRef?.$refs?.inputRef?.focus()
          })
        }
      },
    },
    timeValue: {
      get() {
        if (!this.modelValue?.length) return null
        return dayjs(this.modelValue).tz(this.properTimeZone).format('HH:mm')
      },
      set(newVal) {
        let properNewVal
        let hour, minute
        if (newVal?.length) {
          properNewVal = newVal.replace(/[^0-9:]/gi, '')
          if (properNewVal.includes(':')) {
            ;[hour, minute] = properNewVal.split(':')
            hour = parseInt(hour) % 24
            minute = parseInt(minute) % 60
            hour = padStart(hour || '', 2, '0')
            minute = padStart(minute || '', 2, '0')
          } else {
            hour = parseInt(properNewVal) % 24
            hour = padStart(hour || '', 2, '0')
            minute = '00'
          }
        } else {
          hour = '00'
          minute = '00'
        }

        const newDatetime = dayjs.tz(
          `${dayjs(this.modelValue)
            .tz(this.properTimeZone)
            .format('YYYY-MM-DD')}T${hour}:${minute}:00`,
          this.properTimeZone,
        )
        this.tmpTimeValue = newDatetime.format('HH:mm')
        if (newVal != newDatetime.format('HH:mm')) {
          if (this.$refs.timeEl?.$refs?.pureInputRef?.$refs?.inputRef) {
            this.$refs.timeEl.$refs.pureInputRef.$refs.inputRef.value = this.tmpTimeValue
          }
        }
        this.$emit('update:modelValue', newDatetime.utc().format())
        setTimeout(() => {
          if (
            !(
              document.activeElement &&
              [...(this.$refs.dateEl?.$el?.getElementsByTagName('input') || [])].includes(
                document.activeElement,
              )
            )
          ) {
            this.$emit('finished-picking')
          }
        })
      },
    },
  },
  watch: {
    modelValue(newVal) {
      if (newVal && dayjs(newVal).tz(this.properTimeZone).format('HH:mm') != this.tmpTimeValue) {
        this.tmpTimeValue = dayjs(newVal).tz(this.properTimeZone).format('HH:mm')
      }
    },
    properTimeZone(newVal) {
      if (newVal && dayjs(this.modelValue).tz(newVal).format('HH:mm') != this.tmpTimeValue) {
        this.tmpTimeValue = dayjs(this.modelValue).tz(newVal).format('HH:mm')
      }
    },
    teleportId: {
      handler() {
        setTimeout(() => {
          this.teleportIsSet = !!this.teleportId?.length
        }, 200)
      },
      immediate: true,
    },
  },
  methods: {
    timeBlurred() {
      this.blurringTimeInput = true
      if (this.timeValue != this.tmpTimeValue) {
        this.timeValue = this.tmpTimeValue
      }
    },
    timeFocussed() {
      this.blurringTimeInput = false
      if (this.timeValue != this.tmpTimeValue) {
        this.tmpTimeValue = this.timeValue
      }
    },
  },
}
</script>

<template lang="pug">
.d-flex.align-items-stretch.justify-content-start.flex-column-reverse
  div(:id="teleportId")
  div
    label.form-label(v-if="!disableLabel")
      span.pe-1(v-if="Object.keys(validators || {}).includes('presence')") *
      span {{ label }}
      span.ms-1.text-black-50(v-if="timeZoneNotMine") (in {{ properTimeZone }} time zone)
    .d-flex.align-items-start.justify-content-start
      div(:style="{ width: `${100 - timePercentageWidth}%` }")
        ea-datetimepicker(
          ref="dateEl"
          :additional-errors="additionalErrors"
          :clearable="false"
          :config="{ ...initialConfig, altFormat: 'd/m/Y', enableTime: false, position: 'bottom right' }"
          :disabled="disabled"
          :errorsTeleport="teleportIsSet ? `#${teleportId}` : null"
          :initialVisibleDate="defaultDate"
          :inputClasses="`border-radius-tr-0 border-radius-br-0 ${inputSize == 'sm' ? 'form-control-sm' : ''}`"
          :name="`${name}Date`"
          :skipDirtyCheck="skipDirtyCheck"
          :timeZone="properTimeZone"
          :validators="validators"
          v-model="dateValue"
          hideInvalidIcon
        )
      div(:style="{ width: `${timePercentageWidth}%` }")
        ea-input(
          ref="timeEl"
          :additional-errors="[...($refs.dateEl?.$refs?.fcc?.v$?.$errors?.map(el => el.$message) || []), ...(additionalErrors || [])]"
          :disabled="disabled"
          :inputClasses="[clearable ? 'border-radius-tr-0 border-radius-br-0' : '', 'border-radius-tl-0', 'border-radius-bl-0', 'border-left-0', 'pe-1']"
          :inputSize="inputSize"
          :name="`${name}Time`"
          :required="!clearable"
          :skipDirtyCheck="skipDirtyCheck"
          v-model="tmpTimeValue"
          @blur="timeBlurred()"
          @focus="timeFocussed()"
          hideErrors
          hideInvalidIcon
          type="time"
        )
          template(v-if="clearable" #input-append="prop")
            .input-group-append.cursor-pointer(
              :class="prop.class"
              @click="$emit('update:modelValue', null)"
            )
              .input-group-text.bg-white &times;
</template>
