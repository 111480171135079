import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { Container, interfaces } from 'inversify'

import { IDataModelTypeStrategy } from '../../../dataModels/types/IDataModelTypeStrategy'
import { DataModelType } from '../../../roles/enum/DataModelType'
import { InterfaceType } from '../constants/InterfaceType'

export function bindFactories(container: Container): void {
  container
    .bind<interfaces.Factory<IDataModelTypeStrategy>>(
      InterfaceType.factories.DataModelTypeStrategyFactory,
    )
    .toFactory<IDataModelTypeStrategy>((context: interfaces.Context) => {
      return (dataModelType: DataModelType) => {
        if (
          context.container.isBoundNamed(
            InterfaceType.strategies.DataModelTypeStrategy,
            dataModelType,
          )
        ) {
          return context.container.getNamed<IDataModelTypeStrategy>(
            InterfaceType.strategies.DataModelTypeStrategy,
            dataModelType,
          )
        }
        throw new Error(`Invalid dataModelType strategy ${dataModelType}`)
      }
    })

  container
    .bind<interfaces.Factory<IDataModelTypeConfig>>(
      InterfaceType.factories.DataModelTypeConfigStrategyFactory,
    )
    .toFactory<IDataModelTypeConfig>((context: interfaces.Context) => {
      return (dataModelType: DataModelType) => {
        if (
          context.container.isBoundNamed(
            InterfaceType.strategies.DataModelTypeConfigStrategy,
            dataModelType,
          )
        ) {
          return context.container.getNamed<IDataModelTypeConfig>(
            InterfaceType.strategies.DataModelTypeConfigStrategy,
            dataModelType,
          )
        }
        throw new Error(`Invalid dataModelTypeConfig strategy ${dataModelType}`)
      }
    })
}
