<script>
import { denormalize } from 'normalizr'

export default {
  name: 'DenormalizedItem',
  emits: ['update:isChecked', 'edit', 'remove'],
  props: {
    itemId: { type: String, required: true },
    schema: { type: Object, required: true },
    entities: { type: Object, required: true },
  },
  computed: {
    item() {
      return denormalize(this.itemId, (this.schema?.items || [])[0] || this.schema, this.entities)
    },
  },
}
</script>

<template lang="pug">
slot(:item="item")
</template>
