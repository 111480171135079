import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {TransferGroup} from 'frontend/transfer-groups'
import {Shift} from 'frontend/shifts'
import {Driver} from 'frontend/drivers'
import {camelCase} from 'lodash'
import {DriverCategory} from 'frontend/driver-categories'
import {DriverSource} from 'frontend/driver-sources'
import {Vehicle} from 'frontend/vehicles'
import {Cluster} from 'frontend/clusters'
import {VehicleCategory} from 'frontend/vehicle-categories'
import {VehicleType} from 'frontend/vehicle-types'
import {VehicleSource} from 'frontend/vehicle-sources'
import {VehicleModel} from 'frontend/vehicle-models'
import {Client} from 'frontend/clients'
import {ClientCategory} from 'frontend/client-categories'
import {ClientGroup} from 'frontend/client-groups'
import {ClientFunction} from 'frontend/client-functions'
import {ClientFunctionRemark} from 'frontend/client-function-remarks'
import {Tsl} from 'frontend/tsls'
import {Asl} from 'frontend/asls'
import {Location, LocationWithTp} from 'frontend/locations'
import {TransportationPoint} from 'frontend/transportation-points'
import {EXTENDED_KINDS, TransportBooking} from 'frontend/transport-bookings'
import TBTime from 'frontend/transport-bookings/transport-booking-time.vue'
import ProvidedStamp from 'frontend/transport-bookings/transport-booking-provided-stamp.vue'
import {BatchOnsiteRequest} from 'frontend/batch-onsite-requests'
import {User} from 'frontend/users'
import TBOsl from 'frontend/transport-bookings/transport-booking-osl.vue'
import GroupingCause from 'frontend/transport-bookings/transport-booking-grouping-cause.vue'
import TrueFalseLabel from 'frontend/common/true-false-label.vue'
import TBChanged from 'frontend/transport-bookings/transport-booking-changed.vue'
import FileUrl from 'frontend/common/file-url.vue'
import ImportStatus from 'frontend/common/import-status.vue'
import {STATES} from 'frontend/transport-booking-imports'


export const TransportBookingImportDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'file_url',
    'file_name',
    'aasm_state',
    'finished_at',
    'finished_with_errors_at',
    'major_error_at',
    'progress',
    'created_at',
    'updated_at',
    { created_by: ['id', 'email'] },
  ],
  subscriptions: {
    items: 'TransportBookingImportsChannel',
    users: 'CreatedByChannel',
  },
  defaultColumns: [
    'eid',
    'fileName',
    'importStatus',
    'createdBy',
    'createdDatetime'
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    fileName: {
      label: 'File',
      header: {
        sort: 'file',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: markRaw(FileUrl),
        componentProps: transportBooking => ({
          label: transportBooking.fileName,
          url: transportBooking.fileUrl,
        }),
      },
    },
    importStatus: {
      label: 'Import status',
      header: {
        sort: 'aasmState',
      },
      cell: {
        kind: 'component',
        component: markRaw(ImportStatus),
        componentProps: transportBooking => ({
          importStatus: transportBooking,
          states: STATES,
        }),
      },
    },
    createdBy: {
      label: 'Created by',
      header: {
        sort: 'createdByUsername',
      },
      cell: {
        kind: 'component',
        component: markRaw(User),
        componentProps: transportBooking => ({
          user: transportBooking.createdBy,
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: transportBooking => ({
          t: transportBooking.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: transportBooking => ({
          t: transportBooking.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: transportBooking => ({
          t: transportBooking.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: transportBooking => ({
          t: transportBooking.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: transportBooking => ({
          t: transportBooking.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: transportBooking => ({
          t: transportBooking.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}