/* cSpell:ignore syncshuttletransfers eventorganizationuserimport syncclientcategories recalculatetransfergroups transportbooking resolvemultipleosls resolvemultipletimes batchonsiterequest resolvemultipletransportationpoints mainprocess clientgroupactivityimport vehicleimport loadrows mainprocess mainprocess driverimport transportationpointimport clientimport transportbookingimport */

import { defineAsyncComponent } from 'vue'

const SystemNotificationsTable = defineAsyncComponent(() =>
  import('./system-notifications-table.vue'),
)

const NOTIFICATIONS = {
  report_exportable: ' report',
  driver_toggleapplogin: 'Drivers: Toggle App login possibility',
  transportbooking_recalculatetransfergroups: 'TB: Recalculate groupings',
  transportbooking_resolvemultipleosls: 'TB: Resolve OSLs',
  transportbooking_resolvemultipletimes: 'TB: Recompute travel durations',
  transportbooking_resolvemultipletransportationpoints: 'TB: Recompute transportation points',
  batchonsiterequest_resolvemultipleosls: 'BOR: Resolve OSLs',
  batchonsiterequest_resolvemultipletimes: 'BOR: Recompute travel durations',
  batchonsiterequest_resolvemultipletransportationpoints: 'BOR: Recompute transportation points',
  shiftjob_resolvemultipletimes: 'Shift Job: Recompute travel durations',
  clientgroupactivityimport_mainprocess: 'Client Group Activities import',
  clientgroupactivityimport_loadrows: 'Client Group Activities import (loading rows)',
  eventorganizationuserimport_mainprocess: 'User Accesses import',
  eventorganizationuserimport_loadrows: 'User Accesses import (loading rows)',
  shuttleserviceimport_mainprocess: 'Shuttle Services import',
  shuttleserviceimport_loadrows: 'Shuttle Services import (loading rows)',
  transportationpointimport_mainprocess: 'Transportation Points import',
  transportationpointimport_loadrows: 'Transportation Points import (loading rows)',
  transportbookingimport_mainprocess: 'Transport Booking import',
  transportbookingimport_loadrows: 'Transport Booking import (loading rows)',
  vehicleimport_mainprocess: 'Vehicles import',
  vehicleimport_loadrows: 'Vehicles import (loading rows)',
  driverimport_mainprocess: 'Drivers import',
  driverimport_loadrows: 'Drivers import (loading rows)',
  clientimport_mainprocess: 'Clients import',
  clientimport_loadrows: 'Clients import (loading rows)',
  integration_synclocations: 'Sync locations',
  integration_syncclientcategories: 'Sync group paths',
  integration_clientcategories: 'Sync group paths',
  integration_syncclients: 'Sync clients',
  integration_synctransportbookings: 'Sync transport bookings',
  integration_syncshuttletransfers: 'Sync shuttle transfers',
  integration_syncdrivers: 'Sync drivers',
  shiftjob_batchstatuschange: 'Shift Job: Change statuses',
  clientcommunicationmessage_deliverall: 'Batch sending of Client Communication Messages',
}

const STATUSES = {
  pending: 'pending',
  in_progress: 'in progress',
  finished_with_warning: 'warning',
  finished_with_error: 'error',
  finished: 'finished',
}

export { NOTIFICATIONS, STATUSES, SystemNotificationsTable }
