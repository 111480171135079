<script>
import turfBbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import { lineString } from '@turf/helpers'
import ErrorsControl from 'frontend/common/ea-form-controls/_errors-control.vue'
import GoogleMapPreview from 'frontend/common/maps/google-maps/google-map-preview.vue'
import Route from 'frontend/common/maps/google-maps/route.vue'
import { computed, inject, onMounted } from 'vue'
import { Marker } from 'vue3-google-map'

import { DurationRulesService } from '../duration-rules-service'
import { useDurationRulePreview } from './use-duration-rule-preview'

export default {
  name: 'GoogleMapsDurationPreview',
  props: {
    durationRule: { type: Object, required: true },
    departureAt: { type: Object },
    arrivalAt: { type: Object },
    apiKey: {
      type: String,
    },
    providedStamp: String,
  },
  setup(props) {
    const blocker = inject('blocker')
    const durationRulesService = new DurationRulesService({ blocker })

    const { arrivalAtCeiled, duration, fetchPreview, route, routeMetadata, serverErrors } =
      useDurationRulePreview(props.durationRule, durationRulesService)

    onMounted(() => {
      fetchPreview(props.durationRule, { withRoute: true })
    })

    const routeCoordinates = computed(() => route.value?.coordinates || [])
    const firstPoint = computed(() => routeCoordinates.value[0])
    const lastPoint = computed(() => routeCoordinates.value[routeCoordinates.value.length - 1])
    const boundingBox = computed(() => {
      if (routeCoordinates.value?.length) {
        const line = lineString(routeCoordinates.value)
        const bbox = turfBbox(line)
        return bboxPolygon(bbox).geometry
      } else {
        return null
      }
    })

    return {
      firstPoint,
      lastPoint,
      duration,
      route,
      routeMetadata,
      serverErrors,
      arrivalAtCeiled,
      boundingBox,
    }
  },
  components: {
    GoogleMapPreview,
    ErrorsControl,
    Route,
    Marker,
  },
}
</script>

<template lang="pug">
.google-maps-duration-preview
  .row(v-if="routeMetadata")
    .col-auto
      span.text-nowrap Pick-up:
        .bold
          formatted-time(:t="routeMetadata.departureAt" :tz="routeMetadata.departureTimeZone")
    .col-auto
      span.text-nowrap Drop-off:
        .bold
          formatted-time(
            :t="providedStamp == 'departureAt' ? arrivalAtCeiled : routeMetadata.arrivalAt"
            :tz="routeMetadata.arrivalTimeZone"
          )
    .col-auto
      span.text-nowrap Time:
        .bold.text-nowrap {{ duration }}
  google-map-preview(
    v-if="route && (durationRule?.apiKey || apiKey)"
    :apiKey="durationRule.apiKey || apiKey"
    :boundingBox="boundingBox"
    :height="300"
  )
    template(#map-item)
      Route(v-if="route" :routeGeometry="route")
      Marker(
        v-if="firstPoint"
        :options="{ position: { lat: firstPoint[1], lng: firstPoint[0] }, label: { text: 'A', color: '#ffffff' } }"
      )
      Marker(
        v-if="lastPoint"
        :options="{ position: { lat: lastPoint[1], lng: lastPoint[0] }, label: { text: 'B', color: '#ffffff' } }"
      )
  .mt-2
    errors-control(v-for="error in Object.values(serverErrors || {})" :additionalErrors="error")
</template>
