<script setup lang="ts">
/* eslint-disable import/default */
import DismissButton from 'frontend/common/dismiss-button.vue'
import Modal from 'frontend/common/modal.vue'
import FormErrorMessageComponent from 'frontend/errors/components/FormErrorMessageComponent.vue'
import { useProvideFormErrors } from 'frontend/errors/composables/useFormErrors'
import ButtonComponent from 'frontend/roles/components/ButtonComponent.vue'
import ContainerHeaderIconTitleComponent from 'frontend/roles/components/ContainerHeaderIconTitleComponent.vue'
import InputComponent from 'frontend/ui/components/InputComponent.vue'
import { ref } from 'vue'

interface Emits {
  (e: 'exit'): void
  (
    e: 'save',
    {
      name,
      closeCallback,
    }: {
      name: string
      closeCallback(): void
      errorsCallback(errors: Record<string, string[]>): void
    },
  ): void
}
const emit = defineEmits<Emits>()

const isCreating = ref(false)
const name = ref('')

async function onSave(closeFn) {
  isCreating.value = true
  emit('save', {
    name: name.value,
    closeCallback: closeFn,
    errorsCallback: errors => {
      isCreating.value = false
      setErrors(errors)
    },
  })
}

const { setErrors } = useProvideFormErrors(
  {
    name() {
      return name.value
    },
  },
  // [
  //   ['enabledViewTypes', 'Enabled views'],
  //   ['roleScopes.actionTypes', null],
  //   ['roleScopes', null],
  // ],
)
</script>

<template>
  <Modal :size="320" :z-index="2020" @closed="emit('exit')">
    <template #header>
      <span>
        <ContainerHeaderIconTitleComponent icon-class="fas fa-th-large">
          Save column preset
        </ContainerHeaderIconTitleComponent>
      </span>
    </template>
    <template #default>
      <div class="form">
        <InputComponent v-model="name" placeholder="Enter name for your preset" autofocus />
        <FormErrorMessageComponent attribute-key="name" />
      </div>
    </template>
    <template #footer="modal">
      <DismissButton @click="modal.close()" />
      <ButtonComponent :is-loading="isCreating" @click.prevent="onSave(modal.close)"
        >SAVE</ButtonComponent
      >
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.create-column-preset-modal-content {
  min-height: 240px;
  min-width: 420px;
}

.form {
  // padding: 0 24px;
}

input {
  width: 100%;
}
.container-header {
  padding: 24px;
  padding-bottom: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  .button.back {
    padding: 0;
    width: 2.2rem;
  }
}

.description {
  padding: 0 24px;
}
.header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto auto;
  padding: 32px;
}

.form {
  display: grid;
  gap: 8px;
  padding-bottom: 24px;
}
</style>
