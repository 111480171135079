export default class BaseModel {
  constructor() {
    if (this.constructor == BaseModel) {
      throw new Error(`Abstract class ${this.constructor.name} can't be instantiated.`)
    }
  }

  initialize(obj) {
    if (!obj) {
      return
    }
    Object.entries(obj).forEach(([key, value]) => {
      if (key in this) {
        this[key] = value
      }
    })
  }

  serialize() {
    const json = this.toJSON()
    Object.keys(this.associations).forEach(key => {
      const snake_key = key
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(k => k.toLowerCase())
        .join('_')
      const subject = this[key]
      if (Array.isArray(subject)) {
        if (subject[0] && typeof subject[0].serialize == 'function') {
          json[snake_key] = subject.map(obj => obj.serialize())
        } else {
          json[snake_key] = subject
        }
      } else if (subject) {
        json[snake_key] = subject.serialize()
      }
    })
    return json
  }
}
