<script>
import EventTimeZoneChooser from './event-time-zone-chooser.vue'

export default {
  name: 'LayoutClock',
  props: {
    timzeone: {
      type: String,
    },
  },
  data() {
    return {
      time: null,
      interval: null,
    }
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  created() {
    this.time = new Date()
    this.interval = setInterval(() => {
      this.time = new Date()
    }, 1000)
  },
  components: {
    EventTimeZoneChooser,
  },
}
</script>
<template lang="pug">
.me-sm-3.me-2
  time-display(:time="time" :timezone="$store.state.session.timeZone")
  event-time-zone-chooser.x-small
</template>
