<script>
export default {
  name: 'EllipsisWithTooltip',
  props: {
    text: {
      type: String,
    },
    limit: {
      type: Number,
      required: true,
    },
  },
}
</script>

<template lang="pug">
span
.text-black-15(v-if="!text?.length") empty
.small(
  v-else-if="text.length > limit"
  style="width: 300px; word-wrap: break-word"
  v-tooltip="text"
) {{ text.substring(0, limit) }}...
.small(v-else style="width: 300px; word-wrap: break-word") {{ text }}
</template>
