<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ChatIndicator',
  computed: {
    ...mapGetters('chat', ['isChatManagerOpened']),
    ...mapGetters('session', ['eventOrganizationUser']),
    currentEventOrganizationUser() {
      return this.eventOrganizationUser(this.$route.params.eventSlug)
    },
    unreadCount() {
      return this.currentEventOrganizationUser?.unreadChatGroupsCount || 0
    },
  },
  watch: {
    isChatManagerOpened: {
      handler(value) {
        if (value) {
          this.$eventHub.$on('mouseup', this.mouseup)
        } else {
          this.$eventHub.$off('mouseup', this.mouseup)
        }
      },
      immediate: true,
    },
  },
  unmounted() {
    this.$eventHub.$off('mouseup', this.mouseup)
  },
  methods: {
    ...mapMutations('chat', ['toggleChatManager', 'closeChatManager']),
    mouseup(event) {
      if (!this.$el.contains(event.target)) this.closeChatManager()
    },
  },
}
</script>

<template lang="pug">
.chat-indicator.me-sm-3.me-2
  i.chat-icon.bi-chat-left-dots(@click="toggleChatManager")
  .chat-unread-count.addon.bg-warning.text-white(v-if="unreadCount > 0") {{ unreadCount }}
  .chat-manager-anchor
    .position-relative
      transition(name="fade")
        #chat-manager-portal(v-show="isChatManagerOpened")
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/variables.scss';
.chat-indicator {
  position: relative;
  // margin-right: 8px;
  .position-relative {
    margin-top: 5px;
  }
}
.chat-manager-anchor {
  position: absolute;
  right: 0;
}
#chat-manager-portal {
  position: absolute;
  right: 0;
  @include for-size(small-and-medium-devices) {
    position: fixed;
  }
}
.addon {
  font-size: 0.7rem;
  position: absolute;
  bottom: -0.25rem;
  right: -0.5rem;
  border-radius: 2rem;
  padding: 0 2px;
  text-align: center;
  min-width: 1rem;
}
</style>
