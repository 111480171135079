import { cloneDeep } from 'lodash'
import createPersistedState from 'vuex-persistedstate'

const chatUiStoreModule = {
  namespaced: true,
  state: () => ({
    eventsChat: {
      /*
        [eventSlug]: {
          minimizedChatGroupIdSet
          openedChatGroupIdSet
        }
      */
    },
    isChatOpen: false,
    isNewConversationOpen: false,
    isChatManagerOpened: false,
    chatGroupFilterText: '',
    newConversationFilterText: '',
    newConversationPreselect: null,
    currentEventSlug: null,
  }),
  mutations: {
    openChat(state) {
      state.isChatOpen = true
    },
    closeChat(state) {
      state.isChatOpen = false
    },
    minimizeChatGroup(state, chatGroupId) {
      const eventChat = state.eventsChat[state.currentEventSlug]
      if (eventChat) eventChat.minimizedChatGroupIdSet.add(chatGroupId)
    },
    maximizeChatGroup(state, chatGroupId) {
      const eventChat = state.eventsChat[state.currentEventSlug]
      if (eventChat) eventChat.minimizedChatGroupIdSet.delete(chatGroupId)
    },
    openChatGroup(state, chatGroupId) {
      const eventChat = state.eventsChat[state.currentEventSlug]
      if (eventChat) {
        eventChat.minimizedChatGroupIdSet.delete(chatGroupId)
        eventChat.openedChatGroupIdSet.add(chatGroupId)
      }
    },
    openChatGroupAsFirst(state, chatGroupId) {
      const eventChat = state.eventsChat[state.currentEventSlug]
      if (eventChat) {
        eventChat.minimizedChatGroupIdSet.delete(chatGroupId)
        eventChat.openedChatGroupIdSet = new Set([chatGroupId, ...eventChat.openedChatGroupIdSet])
      }
    },
    closeChatGroup(state, chatGroupId) {
      const eventChat = state.eventsChat[state.currentEventSlug]
      if (eventChat) {
        eventChat.openedChatGroupIdSet.delete(chatGroupId)
      }
    },
    clearOpenedChatGroups(state) {
      const eventChat = state.eventsChat[state.currentEventSlug]
      if (eventChat) {
        eventChat.openedChatGroupIdSet.clear()
      }
    },
    toggleChatManager(state) {
      state.isChatManagerOpened = !state.isChatManagerOpened
    },
    setChatGroupFilterText(state, filterText) {
      state.chatGroupFilterText = filterText
    },
    setCurrentEventUser(state, eventOrganizationUser) {
      state.currentEventUser = eventOrganizationUser
    },
    openNewConversationWindow(state, { prefilter = '', preselect = [] } = {}) {
      state.isNewConversationOpen = true
      state.newConversationFilterText = prefilter
      state.newConversationPreselect = preselect
    },
    clearNewConversationPreselect(state) {
      state.newConversationPreselect = null
    },
    closeNewConversationWindow(state) {
      state.isNewConversationOpen = false
    },
    setNewConversationFilterText(state, filterText) {
      state.newConversationFilterText = filterText
    },
    clearNewConversationFilterText(state) {
      state.newConversationFilterText = ''
    },
    closeChatManager(state) {
      state.isChatManagerOpened = false
      state.filterText = ''
    },
    setCurrentEventSlug(state, eventSlug) {
      if (state.currentEventSlug != eventSlug) state.currentEventSlug = eventSlug

      const eventChat = state.eventsChat[state.currentEventSlug]

      if (!eventChat) {
        state.eventsChat[eventSlug] = {
          openedChatGroupIdSet: new Set(),
          minimizedChatGroupIdSet: new Set(),
        }
      }
    },
  },
  getters: {
    isChatGroupMinimized: state => chatGroupId =>
      getForEvent(state, 'minimizedChatGroupIdSet')?.has(chatGroupId),
    isChatGroupOpened: state => chatGroupId =>
      getForEvent(state, 'openedChatGroupIdSet')?.has(chatGroupId),
    openedChatGroupIds: state => getForEvent(state, 'openedChatGroupIdSet'),

    chatGroupFilterText: state => state.chatGroupFilterText,
    currentEventUser: state => state.currentEventUser,
    isNewConversationOpen: state => state.isNewConversationOpen,
    newConversationFilterText: state => state.newConversationFilterText,
    newConversationPreselect: state => state.newConversationPreselect,
    isChatManagerOpened: state => state.isChatManagerOpened,
  },
}

const getForEvent = (state, key) => {
  const eventChat = state.eventsChat[state.currentEventSlug]
  return eventChat?.[key]
}

const chatPersisted = createPersistedState({
  paths: [
    'chat.isChatOpen',
    'chat.isNewConversationOpen',
    'chat.currentEventSlug',
    'chat.eventsChat',
  ],
  filter(mutation) {
    if (!mutation.type.startsWith('chat')) return
    const triggers = [
      'chat/minimizeChatGroup',
      'chat/maximizeChatGroup',
      'chat/openChatGroupAsFirst',
      'chat/closeNewConversationWindow',
      'chat/openNewConversationWindow',
      'chat/clearOpenedChatGroups',
      'chat/closeChatGroup',
      'chat/openChatGroup',
    ]
    return triggers.includes(mutation.type)
  },
  rehydrated: store => {
    const chatState = store.state.chat
    const eventsChat = chatState.eventsChat
    const eventsChatValues = Object.values(eventsChat || {})

    eventsChatValues.forEach(eventChat => {
      Object.keys(eventChat).forEach(key => {
        //!Warning ALL Sets must have a name that ends with Set
        // this is not ideal but i couldn't find better solution with vuex-persistedstate
        if (key.endsWith('Set')) {
          eventChat[key] = new Set(eventChat[key])
        }
      })
    })
  },
  setState: (key, state, storage) => {
    const data = state['chat']
    const serialized = cloneDeep(data)
    const eventsChat = serialized?.eventsChat

    Object.values(eventsChat || {}).forEach(eventChat => {
      for (const property in eventChat) {
        let serializedProp = eventChat[property]

        if (eventChat[property] instanceof Set) {
          serializedProp = Array.from(eventChat[property])
        }

        eventChat[property] = serializedProp
      }
    })

    storage.setItem(key, JSON.stringify({ chat: serialized }))
  },
})

export default chatUiStoreModule
export { chatPersisted }
