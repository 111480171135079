<script>
import { cloneDeep } from 'lodash'
import { ref } from 'vue'
import { GoogleMap, Marker, Polygon } from 'vue3-google-map'

import { ensureSingleMapContext } from './ensure-single-map-context'

export default {
  name: 'GoogleMapPreview',
  emits: ['map:loaded', 'update:mapProvider'],
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    location: {
      type: Object,
    },
    libraries: {
      type: Array,
      default: () => ['places', 'geometry'],
    },
    directions: {
      type: Object,
    },
    mapItems: {
      type: Array,
    },
    mapCenterItem: {
      type: Object,
    },
    boundingBox: {
      type: Object,
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  setup(props) {
    const mapRef = ref(null)
    const infoWindow = ref(null)
    const isMapLoaded = ref(false)

    return {
      isMapLoaded,
      infoWindow,
      mapRef,
      ...ensureSingleMapContext(props),
    }
  },
  computed: {
    currentItemMarkerImage() {
      const result = {
        path: 'M 0 0 C -2 -20 -10 -22 -10 -30 A 10 10 0 1 1 10 -30 C 10 -22 2 -20 0 0 z M -2 -30 a 2 2 0 1 1 4 0 a 2 2 0 1 1 -4 0 z M -1 -30 a 1 1 0 1 1 2 0 a 1 1 0 1 1 -2 0 z M -3 -30 a 3 3 0 1 1 6 0 a 3 3 0 1 1 -6 0',
        // path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        // path: 'M 0 0 C -2 -20 -10 -22 -10 -30 A 10 10 0 1 1 10 -30 C 10 -22 2 -20 0 0 z',
        fillOpacity: 1,
        fillColor: '#5ac9dd',
        strokeWeight: 2.2,
        strokeColor: '#0082b5',
        scale: 1,
      }
      if (window?.google?.maps || this.isMapLoaded) {
        result.labelOrigin = new google.maps.Point(0, -29)
      }
      return result
    },

    markerPosition() {
      if (this.location?.latitude && this.location?.longitude) {
        return {
          lat: Number.parseFloat(this.location.latitude),
          lng: Number.parseFloat(this.location.longitude),
        }
      } else {
        return null
      }
    },
    mapCenter() {
      return this.markerPosition || this.mapRef?.map?.getCenter() || { lat: 0, lng: 0 }
    },
  },
  watch: {
    directions() {
      this.directionsRenderer?.setDirections(this.directions)
    },
  },
  methods: {
    showDirections() {
      const directions = {
        request: { travelMode: google.maps.TravelMode.DRIVING },
        ...cloneDeep(this.directions),
      }

      const bestRoute = directions.routes[0]

      directions.routes[0].bounds = new google.maps.LatLngBounds(
        bestRoute.bounds.southwest,
        bestRoute.bounds.northeast,
      )

      bestRoute.overview_path = google.maps.geometry.encoding.decodePath(
        bestRoute.overview_polyline.points,
      )

      directions.routes[0].legs = bestRoute.legs.map(leg => {
        leg.start_location = new google.maps.LatLng(leg.start_location.lat, leg.start_location.lng)
        leg.end_location = new google.maps.LatLng(leg.end_location.lat, leg.end_location.lng)
        leg.steps = leg.steps.map(step => {
          step.path = google.maps.geometry.encoding.decodePath(step.polyline.points)
          step.start_location = new google.maps.LatLng(
            step.start_location.lat,
            step.start_location.lng,
          )
          step.end_location = new google.maps.LatLng(step.end_location.lat, step.end_location.lng)
          return step
        })
        return leg
      })

      this.directionsRenderer.setDirections(directions)
      this.directionsRenderer.setMap(this.mapRef.map)
    },
    onMapLoaded() {
      if (this.isMapLoaded) {
        return
      }
      this.infoWindow = new google.maps.InfoWindow()
      this.isMapLoaded = true
      if (!this.markerPosition) {
        if (this.boundingBox) {
          this.fitBoundsOnMap(this.boundingBox)
        } else if (this.mapCenterItem) {
          this.moveMapViewportToElement(this.mapCenterItem)
        }
      }

      this.directionsRenderer = new google.maps.DirectionsRenderer({
        suppressBicyclingLayer: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })

      if (this.directions) {
        this.showDirections()
      }
    },
    refreshPage() {
      window.location.reload()
    },
    moveMapViewportToElement(item) {
      if (!item) return

      if (item.type == 'Polygon') {
        const itemPath = (item.coordinates?.[0] || []).map(c => ({
          lat: Number.parseFloat(c[0]),
          lng: Number.parseFloat(c[1]),
        }))
        this.mapRef?.map?.fitBounds(new google.maps.LatLngBounds(itemPath[0], itemPath[2]))
      } else if (item.type == 'Point') {
        this.mapRef?.map?.setCenter({ lat: item.coordinates?.[0], lng: item.coordinates?.[1] })
        this.mapRef?.map?.setZoom(12)
      }
    },
    //TODO: Fix backend to return [lon, lat] and keep geojson standard for fitBounds
    fitBoundsOnMap(item) {
      if (!item) return
      switch (item.type) {
        case 'Polygon': {
          const itemPath = item.coordinates?.[0]
            .filter(c => c?.length >= 2)
            .map(c => ({
              //switched for this component as our bounding boxes are invalid
              lat: Number.parseFloat(c[1]),
              lng: Number.parseFloat(c[0]),
            }))

          this.mapRef.map?.fitBounds(new google.maps.LatLngBounds(itemPath[0], itemPath[2]))

          break
        }
        case 'Point': {
          const coords = item.coordinates
          if (coords?.length >= 2) {
            this.map?.setCenter({ lat: coords[0], lng: coords[1] })
            this.map?.setZoom(12)
          }
          break
        }
      }
    },
    componentFor(item) {
      if (item.type == 'Polygon') {
        return Polygon
      } else if (item.type == 'Point') {
        return Marker
      } else {
        return null
      }
    },
    labelFontSize(letters) {
      if (letters > 3) {
        return '8px'
      } else if (letters > 2) {
        return '10px'
      }
      if (letters > 1) {
        return '11px'
      } else {
        return '12px'
      }
    },
    mapItemData(item) {
      if (item.type == 'Polygon') {
        return {
          path: (item.coordinates?.[0] || []).map(c => ({
            lat: Number.parseFloat(c[0]),
            lng: Number.parseFloat(c[1]),
          })),
          strokeColor: item.strokeColor || '#FF0000',
          strokeOpacity: item.strokeOpacity || 1.0,
          strokeWeight: item.strokeWeight || 2,
          fillColor: item.fillColor || '#000000',
          fillOpacity: item.fillOpacity || 0.1,
        }
      } else if (item.type == 'Point') {
        let markerImage = null
        if (window?.google?.maps) {
          markerImage = {
            // path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
            path: 'M 0 0 C -2 -20 -10 -22 -10 -30 A 10 10 0 1 1 10 -30 C 10 -22 2 -20 0 0 z',
            fillOpacity: item.fillOpacity || 1,
            fillColor: item.fillColor || '#ff4646',
            strokeWeight: item.strokeWeight || 2,
            strokeColor: item.strokeColor || '#d73534',
            scale: item.scale || 1,
            labelOrigin: new google.maps.Point(0, -29),
          }
        }

        return {
          position: {
            lat: Number.parseFloat(item.coordinates?.[0]),
            lng: Number.parseFloat(item.coordinates?.[1]),
          },
          icon: markerImage,
          label: {
            text: item?.label || null,
            color: item.labelColor || '#ffffff',
            fontSize: this.labelFontSize(item.label?.length || 0),
          },
        }
      } else {
        return null
      }
    },
    showInfoWindow(event, mapItem) {
      if (mapItem.type != 'Point') {
        return
      }
      if (this.infoWindow) {
        if (
          !this.infoWindow.getMap() ||
          this.infoWindow.getPosition()?.lat() != event.latLng?.lat() ||
          this.infoWindow.getPosition()?.lng() != event.latLng?.lng()
        ) {
          this.infoWindow.close()
          this.infoWindow.setContent(mapItem.title)
          this.infoWindow.setOptions({
            position: { lat: event.latLng.lat(), lng: event.latLng.lng() },
            pixelOffset: { width: 0, height: -40 },
          })
          this.infoWindow.open(this.mapRef.map)
        }
      }
    },
    hideInfoWindow(event, mapItem) {
      if (
        mapItem.type == 'Point' &&
        this.infoWindow.getPosition()?.lat() == event.latLng?.lat() &&
        this.infoWindow.getPosition()?.lng() == event.latLng?.lng()
      ) {
        this.infoWindow.close()
      }
    },
  },
  components: {
    GoogleMap,
    // eslint-disable-next-line vue/no-reserved-component-names
    Marker,
    // eslint-disable-next-line vue/no-reserved-component-names
    Polygon,
  },
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-center.flex-column.p-2(v-if="invalidGoogleMapsOptions")
  i.fs-2.fas.fa-exclamation-triangle.text-danger.my-2(v-tooltip="'Cannot load Google Maps'")
  span.mt-1 Google maps was loaded within two organizations
  span.mt-1 Refresh page to reload Google Maps
  span.mt-2
    button.btn.btn-warning(@click="refreshPage" type="button") Refresh page
google-map(
  ref="mapRef"
  v-if="!invalidGoogleMapsOptions"
  :api-key="apiKey"
  :center="mapCenter"
  :libraries="['places', 'geometry']"
  :style="`width: 100%; height: ${height}px`"
  :zoom="15"
  @tilesloaded="onMapLoaded"
)
  Marker(
    v-if="markerPosition"
    :options="{ position: markerPosition, icon: currentItemMarkerImage }"
  )
  template(v-for="mapItem in mapItems")
    component(
      v-if="componentFor(mapItem)"
      :is="componentFor(mapItem)"
      :options="mapItemData(mapItem)"
      @mouseout="hideInfoWindow($event, mapItem)"
      @mouseover="showInfoWindow($event, mapItem)"
    )
  slot(name="map-item")
slot
</template>
