import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {Vehicle} from 'frontend/vehicles'

export const TrackerDeviceDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'color',
    'name',
    'provider',
    'tracking_status',
    'created_at',
    'updated_at',
    'remote_id',
    'event_id',
    {
      vehicle: [
        'id',
        'fleet_number',
        'registration_plate',
        { vehicle_model: ['id', 'name', { vehicle_brand: ['id', 'name'] }] },
      ],
    },
  ],
  subscriptions: {
    items: 'TrackerDevicesChannel',
    vehicles: 'VehiclesSimpleChannel',
  },
  defaultColumns: [
    'remoteId',
    'name',
    'provider',
    'vehicle',
    'updatedAt'
  ],
  columnConfig: {
    remoteId: {
      label: 'Tracker ID',
      header: {
        sort: ['remoteIdInt', 'remoteId'],
      },
      cell: {
        kind: 'simple',
        path: 'remoteId',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    vehicle: {
      label: 'Vehicle',
      header: {
        sort: ['vehicleFleetNumberPrefix', 'vehicleFleetNumberInt'],
      },
      cell: {
        kind: 'component',
        component: markRaw(Vehicle),
        componentProps: item => ({
          vehicle: item.vehicle,
          detailed: true,
        }),
      },
    },
    provider: {
      label: 'Provider',
      header: {
        sort: 'provider',
      },
      cell: {
        kind: 'simple',
        path: 'provider',
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },
    updatedAtTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAtDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
  }
}