import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { GET, POST, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class ClientsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/clients`,
    blocker,
    fetchError = 'Failed to fetch client.',
    saveError = 'Failed to save client.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'clients',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        sorts: 'full_name asc',
        fields: ['id', 'name', 'surname', 'archived_at'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }

  possibleLanguages(errorHandler = this._onFetchError, config = {}) {
    const url = `${this.baseUrl}/possible_language_preferences`
    return this._makeCall(GET, errorHandler, { url, data: {}, ...config })
  }

  archive(clientId, archiveTransportBookings = false) {
    const data = {}
    if (archiveTransportBookings) {
      data['archiveTransportBookings'] = true
    }
    return this._makeCall(POST, null, {
      url: `${this.baseUrl}/${clientId}/archive`,
      data,
    })
  }
}
