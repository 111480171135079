const mapsContextStoreModule = {
  namespaced: true,
  state: () => ({
    googleOptions: null,
  }),
  mutations: {
    cacheGoogleOptions(state, options) {
      state.googleOptions = { ...options }
    },
  },
  getters: {
    getGoogleOptions: state => (state.googleOptions ? { ...state.googleOptions } : null),
  },
}

export default mapsContextStoreModule
