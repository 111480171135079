<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'VehicleBrand',
  props: {
    vehicleBrand: { type: Object, default: () => ({}) },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
modal-button(:item="vehicleBrand" :modal="previewModals.VehicleBrand")
  a {{ vehicleBrand?.name }}
</template>
