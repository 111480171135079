<script>
import latinize from 'latinize'
import { provide, ref } from 'vue'

export default {
  name: 'ObjectTable',
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      default: () => [{ name: 'Value', attribute: 'value' }],
    },
    exportName: {
      type: String,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {},
    }
  },
  setup() {
    const searchPerformed = ref(false)
    provide('searchPerformed', searchPerformed)
    return {
      searchPerformed,
    }
  },
  computed: {
    filteredValues() {
      return this.rows.filter(row => {
        return this.columns.every(col => {
          return this.contains(col.attribute, row[col.attribute])
        })
      })
    },
    exportable() {
      return this.exportName?.length > 0
    },
  },
  methods: {
    onFilter({ attribute }) {
      const startsWith = attribute + 'Start'
      const endsWith = attribute + 'End'
      const contains = attribute + 'Cont'

      if (
        this.filters?.[startsWith]?.length ||
        this.filters?.[endsWith]?.length ||
        this.filters?.[contains]?.length
      ) {
        this.searchPerformed = true
      } else {
        this.searchPerformed = false
      }
    },
    contains(attribute, value) {
      const startsWith = attribute + 'Start'
      const endsWith = attribute + 'End'
      const contains = attribute + 'Cont'

      let result = true
      if (!value?.length) return result

      if (result && this.filters?.[startsWith]?.length) {
        const searchValue = latinize(this.filters[startsWith]).toLowerCase()
        result = latinize(value.toString()).toLowerCase().startsWith(searchValue)
      }
      if (result && this.filters?.[endsWith]?.length) {
        const searchValue = latinize(this.filters[endsWith]).toLowerCase()
        result = latinize(value.toString()).toLowerCase().endsWith(searchValue)
      }
      if (result && this.filters?.[contains]?.length) {
        const searchValue = latinize(this.filters[contains]).toLowerCase()
        result = value.toString().toLowerCase().indexOf(searchValue) != -1
      }

      return result
    },
    arrayToCsvRow(values, delimiter = ';') {
      const asString = val => {
        if (typeof val === 'object') {
          return JSON.stringify(val)
        } else {
          return val ? String(val) : ''
        }
      }

      return values
        .map(asString)
        .map(v => v.replaceAll('"', '""'))
        .map(v => (v?.length > 0 ? `"${v}"` : v))
        .join(delimiter)
    },
    serializeToCsv(data, delimiter = ';') {
      const headersData = this.columns.map(col => col.name)
      const headers = this.arrayToCsvRow(headersData, delimiter)

      const values = Object.values(data ?? {})
      const rows = values.map(row => {
        const rowData = this.columns.map(col => row[col.attribute])
        return this.arrayToCsvRow(rowData, delimiter)
      })

      return [headers, ...rows].join('\r\n')
    },
    downloadCsv() {
      const content = this.serializeToCsv(this.filteredValues)

      const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)

      const el = document.createElement('a')
      el.href = url
      el.setAttribute('download', this.exportName + dayjs().format('MM-DD-YYYY'))
      el.click()
    },
    copyCsvAsText() {
      const csvString = this.serializeToCsv(this.filteredValues, '\t')
      this.$copyText(csvString).then(() => {
        this.$success({ message: 'Successfully copied to clipboard' })
      })
    },
  },
}
</script>
<template lang="pug">
div
  data-table-tools(v-if="exportable || copyable")
    button.btn.btn-primary.btn-sm.ms-2(v-if="copyable" @click="copyCsvAsText")
      | Copy for Excel
    button.btn.btn-primary.btn-sm.ms-2(v-if="exportable" @click="downloadCsv")
      | Export as CSV
  responsive-data-table.disable-sticky-actions(ref="tableScroller")
    data-table
      thead.align-middle
        tr
          template(v-for="column in columns")
            data-table-header-cell(:title="column.name")
              ea-filter(
                :attribute="column.attribute"
                :kinds="['start', 'end', 'cont']"
                v-model:filters="filters"
                @update:filters="onFilter(column)"
              )
      data-table-tbody
        tr(v-for="value in filteredValues" :key="value")
          template(v-for="col in columns")
            td.w-100
              span(v-if="value[col.attribute]") {{ value[col.attribute] }}
              span.text-black-15(v-else) empty
</template>
