import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const VariableDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'name',
    'created_at',
    'updated_at',
    'data_model_type',
    'description',
  ],
  subscriptions: {

  },
  defaultColumns: [

  ],
  columnConfig: {

  }
}