<script>
import { STATUSES } from './index'

export default {
  name: 'SystemNotificationStatus',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    humanizedStatus() {
      return STATUSES[this.notification.status]
    },
    payloadHasError() {
      return this.notification.additionalPayload?.error?.toString()?.length
    },
    payloadHasErrors() {
      const { errors, parsingErrors, deletionErrors } = this.notification?.additionalPayload || {}
      return !!errors || !!parsingErrors || !!deletionErrors
    },
    payloadRequiredFulfillment() {
      return !!this.notification.additionalPayload?.requiredFulfillment
    },
    payloadWarnings() {
      return this.payloadRequiredFulfillment || this.notification.status === 'finished_with_warning'
    },
    finishedWithError() {
      return this.notification.status === 'finished_with_error'
    },
    finished() {
      return this.notification.status === 'finished'
    },
    progress() {
      const { totalRows, currentRow = 0, progress } = this.notification?.additionalPayload || {}
      if (totalRows && currentRow >= 0) {
        return Math.floor((parseFloat(currentRow) / totalRows) * 100)
      } else if (progress) {
        return progress
      } else {
        return null
      }
    },
  },
}
</script>

<template lang="pug">
div(
  :class="{ 'text-danger': finishedWithError || payloadHasError, 'text-warning': payloadWarnings || payloadHasErrors, 'not-allowed': !finished || payloadHasError }"
)
  .bold.text-nowrap {{ humanizedStatus }} {{ payloadHasErrors ? '(with errors)' : '' }} {{ payloadRequiredFulfillment ? '(required fulfillment)' : '' }}

  .mt-1.d-flex.align-items-center.justify-content-start(
    v-if="![null, 100].includes(progress) && !['finished_with_warning', 'finished_with_error', 'finished'].includes(notification?.status)"
  )
    .progress.progress-sm(style="width: 50%")
      .progress-bar.progress-bar-striped.progress-bar-animated.bg-success(
        :style="{ width: `${progress}%` }"
      )
    .ps-2.small(style="width: 50%")
      span {{ progress }}%
      span.ms-1(v-if="notification?.additionalPayload?.totalRows") ({{ notification?.additionalPayload?.currentRow || 0 }} of {{ notification?.additionalPayload?.totalRows }})

  .mt-1.small.text-danger(
    v-else-if="notification.status == 'finished_with_error' && payloadHasError && this.notification.additionalPayload?.error?.constructor == String"
    style="min-width: 200px"
  )
    | {{ this.notification.additionalPayload?.error }}

  .mt-1(v-else-if="notification.status == 'in_progress'")
    .progress.progress-sm(style="width: 50%")
      .progress-bar.progress-bar-striped.progress-bar-animated.bg-success(style="width: 100%")
    .pl-2.small(v-if="notification?.additionalPayload?.totalRows" style="width: 50%") {{ notification?.additionalPayload?.totalRows }} row(s) total

  .mt-1.small(v-if="notification?.additionalPayload?.totalRows") {{ notification?.additionalPayload?.totalRows }} row(s) total
</template>
