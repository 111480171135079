<script lang="ts">
import CancelToken from 'axios/lib/cancel/CancelToken'
import { useCancellable } from 'frontend/_hooks/use-cancellable'
import { useModals } from 'frontend/common/modals'
import { useResourcePaginatedList } from 'frontend/common/useResourcePaginatedList'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import { provide, ref } from 'vue'
import { useRoute } from 'vue-router'

import SystemNotificationBody from './_system-notification-body.vue'

const PER_PAGE_STEP = 10
const MODAL_DISABLED_BY_TITLE = ['report_exportable']
const AVAILABLE_MODAL_STATUS = ['finished']

export default {
  name: 'SystemNotificationsDropdownList',
  emits: ['openModal'],
  data() {
    return {
      readMultipleTimeoutId: null,
    }
  },
  setup(props) {
    const { cancellable } = useCancellable()

    provide('can', () => false)
    const initialDataLoaded = ref(false)

    return {
      cancellable,
      initialDataLoaded,
      ...useModals(),
      ...useResourcePaginatedList({
        dataModelType: DataModelType.SystemNotification,
        path: `events/${useRoute().params.eventSlug}/system_notifications/index`,
        defaultSort: `created_at desc`,
        prefilters: props?.prefilters || {},
        verboseSubscriptions: false,
        instantDelete: true,
        checkAndInjectOnCreate: true,
        disableCustomizableColumns: true,
        fields: ['id', 'title', 'status', 'additional_payload', 'content', 'read', 'created_at'],
        subscriptions: {
          items: 'UserSystemNotificationsChannel',
        },
      }),
    }
  },
  watch: {
    'list.result.items': function () {
      const ids = Object.keys(this.list?.entities?.items || {}).filter(
        id => !this.list.entities.items[id].read,
      )
      if (ids.length) {
        this.readMultipleTimeoutId = setTimeout(() => {
          const path = `events/${this.$route.params.eventSlug}/system_notifications/read_multiple`
          this.axios({
            method: 'post',
            url: path,
            data: {
              id_in: ids,
            },
            cancelToken: new CancelToken(cancel => {
              this.cancellable[path] = cancel
            }),
          }).catch(e => {
            if (!e.cancelled) {
              throw e
            }
          })
        }, 3000)
      }
    },
  },
  mounted() {
    this.page = 1
    this.perPage = PER_PAGE_STEP
    this.fetchItems()
    this.$eventHub.$on('refetch-notifications', this.refetchItems)
  },
  unmounted() {
    clearTimeout(this.readMultipleTimeoutId)
    this.$eventHub.$off('refetch-notifications', this.refetchItems)
  },
  methods: {
    refetchItems() {
      if (!this.initialDataLoaded) {
        this.initialDataLoaded = true
      }
      this.fetchItems()
    },
    modalAvailableFor(status, title) {
      return AVAILABLE_MODAL_STATUS.includes(status) && !MODAL_DISABLED_BY_TITLE.includes(title)
    },
    loadMore(event) {
      this.initialDataLoaded = true
      event.preventDefault()
      event.stopPropagation()
      this.perPage += PER_PAGE_STEP
      this.fetchItems()
    },
  },
  components: {
    SystemNotificationBody,
  },
}
</script>
<template lang="pug">
template(v-if="!initialDataLoaded && isBlocked")
  ea-spinner(:spinnerSize="15" matchParent)
  li.disabled.d-flex.align-items-center.justify-content-center(style="height: 50px") loading data...
template(v-else)
  ea-spinner(v-if="isBlocked" :spinnerSize="15" matchParent)
  li.mt-2.ps-2.pe-2.text-center(v-if="!list.result.items.length")
    b.text-primary.text-center.notification-content Sorry, there is nothing to show
  li.border-bottom.notification-item(
    v-for="itemId in list.result.items"
    :class="{ 'record-deleted': list.entities?.items[itemId]?._deleted }"
    :key="itemId"
  )
    denormalized-item(:entities="list.entities" :itemId="itemId" :schema="schema")
      template(#default="{ item: notification }")
        //- modal-button(
        //-   :item="notification"
        //-   :modal="previewModals.SystemNotification"
        //-   v-if="modalAvailableFor(notification.status) && !notification.additionalPayload?.error"
        //- )
        a(
          v-if="modalAvailableFor(notification.status, notification.title) && !notification.additionalPayload?.error"
          @click="$emit('openModal', notification)"
        )
          system-notification-body(:notification="notification")

        span(v-else @click.prevent="$event.stopPropagation()")
          system-notification-body(:notification="notification")

  li.load-notifications(v-if="perPage < total")
    .btn.btn-outline-primary.btn-sm.load-more.text-center(@click="loadMore($event)") Load older notifications
</template>
