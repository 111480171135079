import { defineAsyncComponent } from 'vue'

const DriverSource = defineAsyncComponent(() => import('./driver-source.vue'))
const DriverSourceFormModal = defineAsyncComponent(() => import('./driver-source-form-modal.vue'))
const DriverSourcesIndex = defineAsyncComponent(() => import('./driver-sources-index.vue'))
const DriverSourcesSelect = defineAsyncComponent(() => import('./driver-sources-select.vue'))
const DriverSourcesTable = defineAsyncComponent(() => import('./driver-sources-table.vue'))

export {
  DriverSource,
  DriverSourceFormModal,
  DriverSourcesIndex,
  DriverSourcesSelect,
  DriverSourcesTable,
}
