<script setup lang="ts">
import DateTimeWithVariablesInput from 'frontend/common/search-criterion/datetime-with-variables-input.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: unknown
  criterion: ISearchCriteriaNode
  predicate: string
  label?: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string): void
}
const emit = defineEmits<Emits>()

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <DateTimeWithVariablesInput
    v-model="valueModel"
    :label="props.label"
    :name="`nameToBeRenamed`"
    skip-dirty-check
  />
</template>
