import { defineAsyncComponent } from 'vue'

const BatchOnsiteRequest = defineAsyncComponent(() => import('./batch-onsite-request.vue'))
const BatchOnsiteRequestsTable = defineAsyncComponent(() =>
  import('./batch-onsite-requests-table.vue'),
)
const ReturningBookingDecisionModal = defineAsyncComponent(() =>
  import('./returning-booking-decision-modal.vue'),
)

const PROVIDED_STAMPS = [
  {
    value: 'start_at',
    // label: 'Pick-up / Arrival time',
    label: 'Pick-up',
    onsiteLabel: 'Pick-up',
    arrivalLabel: 'Arrival time',
    departureLabel: '???',
  },
  {
    value: 'end_at',
    // label: 'Drop-off / Departure time',
    label: 'Drop-off',
    onsiteLabel: 'Drop-off',
    arrivalLabel: '???',
    departureLabel: 'Departure time',
  },
]

export {
  BatchOnsiteRequest,
  BatchOnsiteRequestsTable,
  PROVIDED_STAMPS,
  ReturningBookingDecisionModal,
}
