import axios from 'frontend/_config/axios'

const BY_NAME = 'name asc'
const NAME = ['id', 'name']

export class SimpleSelectService {
  constructor(baseUrl, { blocker, blockerKey, prefilters, apiProvider = axios }) {
    this.baseUrl = baseUrl
    this.blockerKey = blockerKey
    this.blocker = blocker
    this.prefilters = prefilters || {}
    this.apiProvider = apiProvider
  }

  get fetchOptions() {
    return this._fetchOptions.bind(this)
  }

  _fetchOptions({
    additionalPayload = {},
    sorts = BY_NAME,
    fields = NAME,
    errorHandler,
    path = `${this.baseUrl}/index`,
    normalized = false,
    prefilters = this.prefilters,
  } = {}) {
    this._block()
    return this.apiProvider
      .post(path, {
        ...additionalPayload,
        sorts,
        fields,
        normalized,
        ...prefilters,
      })
      .then(res => {
        return res.data.contents
      })
      .catch(error => {
        if (errorHandler) {
          errorHandler(error)
        } else {
          throw error
        }
      })
      .finally(() => this._unblock())
  }

  _block() {
    if (this.blocker) this.blocker[this.blockerKey] = true
  }

  //protected
  _unblock() {
    if (this.blocker) delete this.blocker[this.blockerKey]
  }
}
