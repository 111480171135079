import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from "vue";
import TrueFalseLabel from "frontend/common/true-false-label.vue";
import VehicleSourcesPricing from "frontend/vehicle-sources/vehicle-sources-pricing.vue";
import FormattedTime from "frontend/common/formatted-time.vue";
import VehicleSource from "frontend/vehicle-sources/vehicle-source.vue";
import VehicleType from "frontend/vehicle-types/vehicle-type.vue";
import VehicleCategory from "frontend/vehicle-categories/vehicle-category.vue";

export const VehicleSourcePricingDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'rate',
    'rate_accuracy',
    'rate_currency',
    'updated_at',
    'created_at',
    { vehicle_type: ['id', 'name'] },
    { vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
    { vehicle_source: ['id', 'billable', 'maintainable', 'name'] },
  ],
  subscriptions: {
    items: 'VehicleSourcePricingsChannel',
    vehicleSources: 'VehicleSourcesNoPricingChannel',
    vehicleTypes: 'VehicleTypesSimpleChannel',
    vehicleCategories: 'VehicleCategoriesSimpleChannel',
  },
  defaultColumns: [
    'source',
    'rate',
    'rateCurrency',
    'rateAccuracy',
    'vehicleType',
    'vehicleCategory',
    'updatedDatetime',
  ],
  columnConfig: {
    source: {
      label: 'Source',
      header: {
        sort: 'vehicleSourceLowerName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleSource),
        componentProps: item => ({ vehicleSource: item.vehicleSource }),
      },
    },
    rate: {
      label: 'Rate',
      header: {
        sort: 'rate',
      },
      cell: {
        kind: 'simple',
        path: 'rate',
      },
    },
    rateCurrency: {
      label: 'Rate currency',
      header: {
        sort: 'rateCurrency',
      },
      cell: {
        kind: 'simple',
        path: 'rateCurrency',
      },
    },
    rateAccuracy: {
      label: 'Rate accuracy',
      header: {
        sort: 'rateAccuracy',
      },
      cell: {
        kind: 'simple',
        path: 'rateAccuracy',
      },
    },
    vehicleType: {
      label: 'Vehicle type',
      header: {
        sort: 'vehicleTypeName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleType),
        componentProps: item => ({ vehicleType: item.vehicleType }),
      },
    },
    vehicleCategory: {
      label: 'Vehicle category',
      header: {
        sort: 'vehicleCategoryName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleCategory),
        componentProps: item => ({ vehicleCategory: item.vehicleCategory }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}