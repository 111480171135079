export default function calcDuration(start, end) {
  start = dayjs(start).startOf(1, 'minutes')
  end = dayjs(end).endOf(1, 'minutes')

  const diffInMinutes = end.diff(start, 'minutes')
  const hours = Math.floor(diffInMinutes / 60)
  let result = ''
  if (diffInMinutes <= 60) {
    result = `${diffInMinutes}min`
  } else if (hours > 48) {
    const days = Math.floor(hours / 24)
    result = `${days} days`
  } else {
    result = `${hours}h` + (diffInMinutes % 60 > 0 ? ` ${diffInMinutes % 60}min` : '')
  }
  return result
}

export function humanizedDuration(seconds, delimiter = '') {
  const diffInMinutes = Math.ceil(seconds / 60)
  const hours = Math.floor(diffInMinutes / 60)
  let result = ''

  if (hours === 0) {
    result = `${diffInMinutes}${delimiter}min`
  } else {
    result = `${hours}${delimiter}h ${diffInMinutes % 60}${delimiter}min`
  }
  return result
}

export function durationInSeconds(startTime, endTime) {
  return startTime.diff(dayjs(endTime), 's')
}

export function calcElapsedTime(startTime, endTime) {
  const seconds = durationInSeconds(startTime, endTime)
  return humanizedDurationShort(seconds)
}

export function humanizedDurationShort(seconds) {
  if (seconds < 0) {
    return '0s'
  }
  if (seconds < 60) {
    return `${Math.floor(seconds)}s`
  }
  if (seconds < 3600) {
    return `${Math.floor(seconds / 60)}m`
  }
  if (seconds < 3600 * 24) {
    return `${Math.floor(seconds / 3600)}h`
  }

  return `${Math.floor(seconds / (3600 * 24))}d`
}
