<script>
import { useVuelidate } from '@vuelidate/core'
import computeSchema from 'frontend/_globals/compute-schema'
import { useBlocker } from 'frontend/common'
import { calcRGB } from 'frontend/common/helpers'
import { organizationModuleGuard } from 'frontend/common/module-access-guard'
import { denormalize } from 'normalizr'
import QrcodeVue from 'qrcode.vue'

import SsoSignInTrigger from './sso-sign-in-trigger.vue'

//@vue/component
export default {
  name: 'SignIn',
  data() {
    return {
      errors: {},
      user: {
        email: null,
        password: null,
      },
      validatedItemKey: 'user',
      ssoOrganizations: [],
      customOrganization: null,
      showForm: false,
      redirectionTriggered: false,

      showOtpVerification: false,
      otpProvisioningUri: null,
      otp: null,
      isSSOLoading: true,
    }
  },
  setup() {
    const { blocker, isBlocked } = useBlocker()

    return { v$: useVuelidate(), blocker, isBlocked }
  },
  validations() {
    return {}
  },
  computed: {
    basePath() {
      return `${window.location.protocol}//${window.location.host}`
    },
    isOnMainDomain() {
      return window.location.host == this.$mainDomain
    },
    currentUser() {
      const user = this.$store.state.session.user
      if (user) {
        this.redirectToProperPath(user)
      }
      return user
    },
  },
  mounted() {
    if (!this.isOnMainDomain) {
      this.getCustomOrganization()
    } else {
      this.getSSOOrganizations()
    }
    setTimeout(() => {
      if (this.$route.query?.confirmed) {
        alert('The account has been successfully activated, please sign in.')
        this.$route.query = {}
      } else if (this.$route.query?.not_confirmed) {
        alert('Can not activate the account, probably the activation message has expired')
        this.$route.query = {}
      }
    }, 1000)
  },
  methods: {
    redirectToProperPath(user) {
      if (this.redirectionTriggered) {
        return
      }
      this.redirectionTriggered = true
      if (this.$store.state.session?.afterSignInPath?.length) {
        this.$router.push(this.$store.state.session.afterSignInPath)
        return
      } else {
        const guard = organizationModuleGuard(
          null,
          user?.impersonatedCurrentOrganizationUser || user?.currentOrganizationUser,
        )
        if (guard.hasMultipleModulesAccess) {
          this.$router.push({ name: 'tmm-hub' })
        } else if (guard.hasOnlyOperationalAccess) {
          this.$router.push({ name: 'events' })
        } else if (guard.hasOnlyPassengerAccess) {
          this.$router.push({ name: 'passenger-portal' })
        } else {
          this.$router.push({ name: 'root' })
        }
      }
    },
    signIn(e, retryOnCsrf = true) {
      if (e) e.preventDefault()
      this.v$.$touch()

      if (Object.keys(this.errors).length > 0) {
        this.$error({ message: 'Please provide email and password to sign in.' })
      } else {
        this.blocker['sign-in'] = true
        const data = {
          user: this.user,
        }
        if (this.otp) data.otp = this.otp
        this.axios(
          {
            method: 'post',
            url: 'session/sign_in',
            data,
            headers: {
              'Csrf-Retry': false,
            },
          },
          {
            withCredentials: true,
          },
        )
          .then(response => {
            delete this.blocker['sign-in']
            if (response.status == 200) this.getOrFetchUserThenRedirect()
          })
          .catch(error => {
            if (retryOnCsrf && error.response.status == 460) {
              this.axios({ method: 'get', url: '', headers: { 'Csrf-Retry': false } })
                .then(() => {
                  // should have csrf token now
                  this.signIn(e, false)
                })
                .catch(() => {
                  delete this.blocker['sign-in']
                  this.showErrorMessage(error)
                })
              return
            } else if (error.response.status == 495) {
              console.warn('need to use custom domain before sign in!', error.response)
              window.location.href = window.location.href.replace(
                window.location.host,
                error.response.data.customDomain,
              )
            }
            delete this.blocker['sign-in']
            this.showErrorMessage(error)
          })
      }
    },
    showErrorMessage(error) {
      let errorMessage
      if (!error.response) {
        errorMessage = 'Major sign-in error occurred.'
      } else if ((error.response.data?.error || '').toLowerCase().indexOf('blocked') != -1) {
        errorMessage = 'User has been blocked. Contact system administrator.'
      } else if (
        (error.response.data?.error || '').toLowerCase().indexOf('otp_auth_failed') != -1
      ) {
        errorMessage = 'One time password verification failed.'
      } else if (
        (error.response.data?.error || '').toLowerCase().indexOf('otp_auth_needed') != -1
      ) {
        this.$info({ message: 'Account protected. Provide one time password.' })
        this.showOtpVerification = true
        this.getOtpProvisioningUri()
        return
      } else if (
        (error.response.data?.error || '').toLowerCase().indexOf('too many attempts') != -1
      ) {
        errorMessage = 'Too many failed attempts, account is temporarily blocked.'
      } else if (error.response.status == 401) {
        errorMessage = 'Invalid email or password.'
      } else if (error.response.data.errors) {
        errorMessage = Object.values(error.response.data.errors).join(', ')
      } else if (
        error.response.data.error &&
        error.response.data.error.constructor == ''.constructor
      ) {
        errorMessage = error.response.data.error
      } else {
        errorMessage = 'Error occurred.'
      }
      this.$error({ message: errorMessage })
    },
    getSSOOrganizations() {
      this.isSSOLoading = true
      this.axios({
        method: 'get',
        url: 'organizations/with_sso_configs',
      })
        .then(response => {
          this.ssoOrganizations = denormalize(
            response.data.result,
            computeSchema(),
            response.data.entities,
          ).items
          if (!this.ssoOrganizations?.length) {
            this.showForm = true
          }
        })
        .catch(error => {
          if ((error.response.data?.error || '').toLowerCase().indexOf('blocked') != -1) {
            this.$error({ message: 'User has been blocked. Contact system administrator.' })
          }
        })
        .finally(() => {
          this.isSSOLoading = false
        })
    },
    getCustomOrganization() {
      this.axios({
        method: 'get',
        url: 'organizations/by_domain',
      })
        .then(response => {
          this.customOrganization = response.data
          const customColors = this.getCustomColors()
          Object.keys(customColors || {}).forEach(key => {
            document.body.style.setProperty(key, customColors[key])
          })
        })
        .catch(error => {
          console.error(error)
          console.error(error?.response)
          window.location.href = window.location.href.replace(
            window.location.host,
            this.$mainDomain,
          )
        })
    },
    showFormToggle() {
      this.showForm = !this.showForm
    },
    getOrFetchUserThenRedirect() {
      this.$store
        .dispatch('session/getOrFetchUser')
        .then(user => {
          this.$success({ message: 'You have been successfully signed in.' })
          this.redirectToProperPath(user)
        })
        .catch(() => {
          this.$error({ message: 'Cannot fetch user.' })
        })
    },
    getOtpProvisioningUri() {
      this.blocker['authentication-factor'] = true
      this.axios({ method: 'get', url: '', headers: { 'Csrf-Retry': false } }).then(() => {
        this.axios({
          method: 'post',
          url: 'session/authentication_factor',
          data: {
            email: this.user.email,
          },
        })
          .then(response => {
            if (response.status == 200) {
              this.otpProvisioningUri = response.data?.provisioningUri
            }
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            delete this.blocker['authentication-factor']
          })
      })
    },
    getCustomColors() {
      const primaryColor = this.customOrganization?.customPrimaryColor ?? '#000'
      const { rgb: primary, rgbValues: primaryValues } = calcRGB({ rgbstr: primaryColor })
      const { rgb: lighter, rgbValues: lighterValues } = calcRGB({
        rgbstr: primaryColor,
        colorChange: 0.25,
      })
      const { rgb: darker, rgbValues: darkerValues } = calcRGB({
        rgbstr: primaryColor,
        colorChange: -0.15,
      })
      return {
        '--custom-primary': primary,
        '--custom-primary-lighter': lighter,
        '--custom-primary-darker': darker,
        '--custom-primary-rgb': primaryValues,
        '--custom-primary-lighter-rgb': lighterValues,
        '--custom-primary-darker-rgb': darkerValues,
      }
    },
  },
  components: {
    SsoSignInTrigger,
    QrcodeVue,
  },
}
</script>

<template lang="pug">
ea-spinner(v-if="isBlocked" :spinner-size="30" matchParent)
template(v-if="!currentUser")
  .d-flex.flex-column.position-relative.w-100.h-100(v-if="isOnMainDomain")
    .session-wrapper
      h3.pb-3.mb-0 Welcome to TMM
      .thick-divider.mb-4
      .mt-5.position-relative(v-if="isSSOLoading")
        ea-spinner(:spinner-size="30" match-parent message="Loading organizations" without-timeout)
      .mb-4(v-else-if="ssoOrganizations.length")
        div(v-for="org in ssoOrganizations")
          sso-sign-in-trigger.btn.btn-outline-primary.custom(:organization="org")
      transition(name="fade")
        form.sign-in-form(
          v-if="showForm && !showOtpVerification"
          :class="isSSOLoading ? 'my-5' : ''"
          @submit="signIn"
        )
          .w-100
            ea-input#user_email(
              :additional-errors="errors.email"
              :validators="{ presence: null }"
              v-model="user.email"
              label="E-mail"
              name="session.email"
              type="email"
            )
          .w-100
            ea-input#user_password(
              :additional-errors="errors.password"
              :validators="{ presence: null }"
              v-model="user.password"
              label="Password"
              name="session.password"
              type="password"
            )
          .w-100.mb-4
            router-link(:to="{ name: 'password-recovery' }") Forgot password
          .w-100.text-right
            submit-button.w-100 Sign in

      transition(name="fade")
        form.verify-otp-form(v-if="showForm && showOtpVerification" @submit="signIn")
          template(v-if="otpProvisioningUri")
            h6.text-center Create one time passwords by scanning QR code in chosen auth app (Google authenticator, MS authenticator)
            qrcode-vue.mb-4(:class="'qr-code'" :size="600" :value="otpProvisioningUri" level="H")

          h6.text-center(v-if="!otpProvisioningUri") Enter One time password provided from your authentication app

          ea-input#one_time_password(
            :additional-errors="errors.otp"
            :validators="{ presence: null }"
            v-model="otp"
            label="One-time-password"
            name="One.time.password"
            placeholder="One time password"
            type="text"
          )
          submit-button.w-100 Verify OTP

    a.show-form-toggle.d-block.text-center.w-100.mb-4(@click="showFormToggle")
      span(v-if="!showForm") Click here to sign in with e-mail and password
      span(v-else-if="ssoOrganizations.length") Click here to hide sign in form

  //- custom domain view
  .d-flex.flex-column.position-relative.w-100.h-100(v-else-if="customOrganization")
    .session-wrapper
      h3.pb-3.mb-0 Welcome to {{ customOrganization.name }} {{ customOrganization.customAppName }}
      .thick-divider.mb-4
      div(v-if="customOrganization.sso")
        sso-sign-in-trigger.btn.btn-outline-primary.custom(:organization="customOrganization") Sign in with SSO

      form.verify-otp-form(v-else-if="showOtpVerification" @submit="signIn")
        template(v-if="otpProvisioningUri")
          h6.text-center Create one time passwords by scanning QR code in chosen auth app (Google authenticator, MS authenticator)
          qrcode-vue.mb-4(:class="'qr-code'" :size="600" :value="otpProvisioningUri" level="H")

        h6.text-center(v-if="!otpProvisioningUri") Enter One time password provided from your authentication app

        ea-input#one_time_password(
          :additional-errors="errors.otp"
          :validators="{ presence: null }"
          v-model="otp"
          label="One-time-password"
          name="One.time.password"
          placeholder="One time password"
          type="text"
        )
        submit-button.w-100 Verify OTP

      form.sign-in-form(v-else @submit="signIn")
        .w-100
          ea-input#user_email(
            :additional-errors="errors.email"
            :validators="{ presence: null }"
            v-model="user.email"
            label="E-mail"
            name="session.email"
            type="email"
          )
        .w-100
          ea-input#user_password(
            :additional-errors="errors.password"
            :validators="{ presence: null }"
            v-model="user.password"
            label="Password"
            name="session.password"
            type="password"
          )
        .w-100.mb-4
          router-link(:to="{ name: 'password-recovery' }") Forgot password
        .w-100.text-right
          submit-button.w-100 Sign in
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/_variables.scss';
@import 'bootstrap/scss/bootstrap-utilities.scss';

a {
  color: $darker-gray;
  &:hover {
    color: $black;
  }
  @include media-breakpoint-up(md) {
    &.show-form-toggle {
      position: absolute;
      bottom: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
