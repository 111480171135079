<template>
  <div class="drag-icon">
    <i class="fas fa-bars" />
    <!--    <i class="arrow fas fa-chevron-up" />-->
    <!--    <i class="arrow fas fa-chevron-down" />-->
  </div>
</template>

<style scoped lang="scss">
.drag-icon {
  display: grid;
  gap: 0px;
  color: hsl(0 0% 66% / 1);
  width: 18px;
  justify-content: center;
  cursor: move;
  align-items: center;

  &:hover {
    color: hsl(0 0% 33% / 1);
  }
}

.arrow {
  font-size: 10px;
  line-height: 7px;
}
</style>
