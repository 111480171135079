<script>
import { TYPES } from './index'

export default {
  name: 'ShiftJobType',
  props: {
    item: { type: Object },
  },
  computed: {
    typeLabel() {
      return TYPES.find(s => s.value == this.item.type)?.label
    },
  },
}
</script>

<template lang="pug">
.text-nowrap(v-if="item?.type?.length") {{ typeLabel }}
span.text-black-15(v-else) empty
</template>
