import {computed, ref, watch} from 'vue'
import {createInjectionState} from '@vueuse/core'

type IErrorObject = Record<string[]|IErrorObject>

const [useProvideFormErrors, useFormErrors] = createInjectionState((temporaryValues: Record<string, () => unknown>, mappings: [string, string][]) => {
  const latestErrors = ref<IErrorObject>(null)
  const clearedErrorKeys = ref<string[]>([])
  const isShaking = ref<boolean>(false)
  const isShakingTimeout = ref<ReturnType<typeof setTimeout>>(null)

  function setErrors(errors: Record<string, string[]>) {
    latestErrors.value = errors
    clearedErrorKeys.value = []
    isShaking.value = true
    clearTimeout(isShakingTimeout.value)
    isShakingTimeout.value = setTimeout(() => {
      isShaking.value = false
    }, 500)
  }

  function clearErrors() {
    clearedErrorKeys.value = Object.keys(latestErrors.value)
  }

  const errorArray = computed(() => {
    return getErrorsOutOfObject(latestErrors.value)
  })

  const errorFieldMap = computed(() => {
    const errors = errorArray.value
    const fieldMap = new Map<string, string[]>()
    const uniqueErrorKeys = [...new Set(errors.map(error => error[0]))]
    for(const field of uniqueErrorKeys) {
      const errorsByThatField = errors.filter(error => error[0] === field)
      if(!clearedErrorKeys.value.includes(field)) {
        fieldMap.set(field, errorsByThatField.map(error => error[1]))
      }
    }
    return fieldMap
  })

  function getErrorsOutOfObject(errors: string[]) {
    if(errors === null) {
      return []
    }
    let finalErrors = []
    for(const [field, childErrors] of Object.entries(errors)) {
      let i = 0;
      for(const error of childErrors) {
        if(typeof error === 'string') {
          finalErrors.push([field, error, i])
        } else {
          const moreErrors = getErrorsOutOfObject(error)
          const mappedErrors = moreErrors.map(error => {
            return [`${field}.${error[0]}`, error[1], i]
          })
          finalErrors = finalErrors.concat(mappedErrors)
        }
        i++
      }
    }
    return finalErrors
  }

  for(const [key, valueFn] of Object.entries(temporaryValues)) {
    watch(valueFn, () => {
      clearError(key)
    })
  }

  function clearError(key: string) {
    clearedErrorKeys.value = [...clearedErrorKeys.value, key]
  }

  return {
    setErrors,
    clearErrors,
    errorFieldMap,
    clearError,
    mappings: mappings || [],
    isShaking,
    errorArray,
  }
})

export {
  useProvideFormErrors,
  useFormErrors,
  errorArray,
}