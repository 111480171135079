export function allowKeys(obj, keys) {
  const whitelisted = new Set(keys)
  return !Object.keys(obj).find(key => !whitelisted.has(key))
}

export function divmod(dividend, divisor) {
  return [Math.floor(dividend / divisor), dividend % divisor]
}

export function isEmpty(obj) {
  for (const key in obj) return false
  return true
}

/**
 * Calculates lighter (positive colorChange) or darker rgb color,
 * @param {Object} params - rgb calculation options
 * @param {string} params.rgbstr - rgb hex string
 * @param {number} [params.colorChange]  - -1..1 values representing lightness change
 * @default { rgbstr, colorChange = 0 }
 * @returns {object} { rgb: rgbstr, rgbValues: '0,0,0' }
 */
export function calcRGB({ rgbstr, colorChange = 0 }) {
  try {
    rgbstr = rgbstr.substr(1)

    if (rgbstr.length === 3) {
      rgbstr = rgbstr + rgbstr
    }

    const r_hex = rgbstr.slice(0, 2)
    const g_hex = rgbstr.slice(2, 4)
    const b_hex = rgbstr.slice(4, 6)

    let r = parseInt(r_hex, 16)
    let g = parseInt(g_hex, 16)
    let b = parseInt(b_hex, 16)

    const oneMinus = 1.0 + colorChange
    r = Math.min(colorChange + oneMinus * r, 255) ^ 0
    g = Math.min(colorChange + oneMinus * g, 255) ^ 0
    b = Math.min(colorChange + oneMinus * b, 255) ^ 0

    const rgb =
      '#' +
      r.toString(16).padStart(2, 0) +
      g.toString(16).padStart(2, 0) +
      b.toString(16).padStart(2, 0)

    return { rgb, rgbValues: [r, g, b].join(',') }
  } catch (e) {
    console.warn('Could not calculate primary color variants.')
    return { rgb: rgbstr, rgbValues: '0,0,0' }
  }
}
