import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {Location, LocationWithTp} from 'frontend/locations'
import {Cluster} from 'frontend/clusters'
import {TransportationPoint} from 'frontend/transportation-points'
import {TransportBookingOsl, TransportBookingTime} from 'frontend/transport-bookings'
import {BatchOnsiteRequest, PROVIDED_STAMPS} from 'frontend/batch-onsite-requests'
import {ClientGroup} from 'frontend/client-groups'
import {Client} from 'frontend/clients'
import {User} from 'frontend/users'

export const BatchOnsiteRequestDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'end_at',
    'start_at',
    'requested_time',
    'provided_stamp',
    'provided_stamp_fixed',
    'updated_at',
    'clients',
    'client_groups',
    'additional_clients',
    'ready_for_osl',
    'comment',
    'tpt_comment',
    'locked_osl_assignment',
    'opposite_route_booking_id',
    'opposite_route_booking_eid',
    { end_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
    { start_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
    { end_transportation_point: ['id', 'name'] },
    { start_transportation_point: ['id', 'name'] },
    {
      created_by: ['id', 'name', 'surname', 'email'],
    },
    {
      operational_service_level: [
        'id',
        'name',
        'font_color',
        'background_color',
        'border_color',
        'description',
      ],
    },
  ],
  subscriptions: {
    items: 'BatchOnsiteRequestsChannel',
    locations: 'LocationsSimpleChannel',
    transportationPoints: 'TransportationPointsSimpleChannel',
    clusters: 'ClustersSimpleChannel',
    operationalServiceLevel: 'OperationalServiceLevelsSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'pu',
    'do',
    'requestedDatetime',
    'providedStamp',
    'startAt',
    'endAt',
    'totalClients',
    'clients',
    'osl',
    'comment',
    'tptComment',
    'createdBy',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    pu: {
      label: 'Pick-up',
      header: {
        sort: 'startLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(LocationWithTp),
        componentProps: item => ({
          item: item,
          kind: 'start',
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },
    startCluster: {
      label: 'Pick-up cluster',
      header: {
        sort: 'startLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({ cluster: item?.startLocation?.cluster }),
      },
    },
    startLocation: {
      label: 'Pick-up location',
      header: {
        sort: 'startLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: item => ({ minWidth: 250, location: item?.startLocation }),
      },
    },
    startTransportationPoint: {
      label: 'Pick-up TP',
      header: {
        sort: 'startTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: item => ({ transportationPoint: item?.startTransportationPoint }),
      },
    },
    do: {
      label: 'Drop-off',
      header: {
        sort: 'endLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(LocationWithTp),
        componentProps: item => ({
          item: item,
          kind: 'end',
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },
    endCluster: {
      label: 'Drop-off cluster',
      header: {
        sort: 'endLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({ cluster: item?.endLocation?.cluster }),
      },
    },
    endLocation: {
      label: 'Drop-off location',
      header: {
        sort: 'endLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: item => ({ minWidth: 250, location: item?.endLocation }),
      },
    },
    endTransportationPoint: {
      label: 'Drop-off TP',
      header: {
        sort: 'endTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: item => ({ transportationPoint: item?.endTransportationPoint }),
      },
    },
    requestedDatetime: {
      label: 'Req. datetime',
      header: {
        sort: 'requestedTime',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'requestedTime',
          tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
          showAnchor: !!item.providedStampFixed,
        }),
      },
    },
    requestedTime: {
      label: 'Req. time',
      header: {
        sort: 'requestedTime',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'requestedTime',
          tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
          showAnchor: !!item.providedStampFixed,
          format: 'timeOnly',
        }),
      },
    },
    requestedDate: {
      label: 'Req. date',
      header: {
        sort: 'requestedTime',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'requestedTime',
          tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
          showAnchor: !!item.providedStampFixed,
          format: 'dateOnly',
        }),
      },
    },
    providedStamp: {
      label: 'Req. time kind',
      header: {
        sort: 'providedStamp',
      },
      cell: {
        kind: 'function',
        fn: item =>
          (PROVIDED_STAMPS.find(el => el.value == item.providedStamp) || {}).label || 'empty',
      },
    },
    startAt: {
      label: 'Pick-up datetime',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'startAt',
          tz: item.startLocation?.timeZone,
          showAnchor: !!item.providedStampFixed && item.providedStamp == 'start_at',
        }),
      },
    },
    startTime: {
      label: 'Pick-up time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'startAt',
          tz: item.startLocation?.timeZone,
          showAnchor: !!item.providedStampFixed && item.providedStamp == 'start_at',
          format: 'timeOnly',
        }),
      },
    },
    startDate: {
      label: 'Pick-up date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'startAt',
          tz: item.startLocation?.timeZone,
          showAnchor: !!item.providedStampFixed && item.providedStamp == 'start_at',
          format: 'dateOnly',
        }),
      },
    },
    endAt: {
      label: 'Drop-off datetime',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'endAt',
          tz: item.endLocation?.timeZone,
          showAnchor: !!item.providedStampFixed && item.providedStamp == 'end_at',
        }),
      },
    },
    endTime: {
      label: 'Drop-off time',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'endAt',
          tz: item.endLocation?.timeZone,
          showAnchor: !!item.providedStampFixed && item.providedStamp == 'end_at',
          format: 'timeOnly',
        }),
      },
    },
    endDate: {
      label: 'Drop-off date',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingTime),
        componentProps: item => ({
          transportBooking: item,
          attr: 'endAt',
          tz: item.endLocation?.timeZone,
          showAnchor: !!item.providedStampFixed && item.providedStamp == 'end_at',
          format: 'dateOnly',
        }),
      },
    },
    osl: {
      label: 'Operational Service Level',
      header: {
        sort: 'operationalServiceLevelPosition',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBookingOsl),
        componentProps: item => ({ transportBooking: item }),
      },
    },
    clientGroups: {
      label: 'Client Groups',
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: bor => ({
          items: bor?.clientGroups,
          limit: 5,
          component: markRaw(ClientGroup),
          componentProps: c => ({ clientGroup: c }),
        }),
      },
    },
    updatedAt: {
      label: 'Updated At',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item?.updatedAt }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item?.updatedAt, format: 'timeOnly' }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item?.updatedAt, format: 'dateOnly' }),
      },
    },
    clients: {
      label: 'Clients',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: item => ({
          items: clientsWithEmpty(item),
          limit: 5,
          component: markRaw(Client),
          componentProps: c => ({ client: c, disabled: !c.id }),
        }),
      },
    },
    totalClients: {
      label: 'Total clients',
      tooltip: 'Total number of clients in Batch Onsite Requests',
      header: {
        sort: 'clientsCount',
      },
      cell: {
        kind: 'function',
        fn: item =>
          item.clients?.length
            ? (item.clients?.length + (item.additionalClients || 0)).toString()
            : null,
      },
    },
    createdBy: {
      label: 'Created by',
      header: {
        sort: 'createdByUsername',
      },
      cell: {
        kind: 'component',
        component: markRaw(User),
        componentProps: item => ({
          user: item.createdBy,
        }),
      },
    },
    comment: {
      label: 'Comment',
      header: {
        sort: 'comment',
      },
      cell: {
        kind: 'component',
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item.comment,
          limit: 250,
        }),
      },
    },
    tptComment: {
      label: 'TPT Comment',
      tooltip: 'Transportation Team Comment',
      header: {
        sort: 'tptComment',
      },
      cell: {
        kind: 'component',
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item.tptComment,
          limit: 250,
        }),
      },
    },
    oppositeRouteBooking: {
      label: 'Opposite route BOR',
      header: {
        sort: 'oppositeRouteBookingId',
      },
      cell: {
        kind: 'component',
        component: markRaw(BatchOnsiteRequest),
        componentProps: item => ({
          batchOnsiteRequest: item.oppositeRouteBookingId
            ? {id: item.oppositeRouteBookingId, eid: item.oppositeRouteBookingEid}
            : null,
          eidOnly: true,
        }),
      },
    }
  }
}