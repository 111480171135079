import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function useCalendarEntriesSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }

  const section = computed(() => {
    const result = {
      name: 'Calendar Entries',
      items: [
        {
          title: 'Operational Periods',
          isDisabled: false,
          path: 'operational-periods',
          icon: 'bi-calendar2-week',
        },
        {
          title: 'Occasions',
          isDisabled: false,
          path: 'occasions',
          icon: 'bi-calendar2-week',
        },
        {
          title: 'Welcome Desk Working Hours',
          isDisabled: false,
          path: 'welcome-desk-working-hours',
          icon: 'bi-calendar2-week',
        },
        {
          title: 'Minimum Vehicle Allotment',
          isDisabled: false,
          path: 'minimum-vehicle-allotment',
          icon: 'bi-calendar2-week',
        },
        {
          title: 'Subfleet',
          isDisabled: false,
          path: 'subfleet',
          icon: 'bi-calendar2-week',
        },
        {
          title: 'Driver Availability',
          isDisabled: false,
          path: 'driver-availabilities',
          icon: 'bi-calendar2-week',
        },
        {
          title: 'Driver Unavailability',
          isDisabled: false,
          path: 'driver-unavailabilities',
          icon: 'bi-calendar2-week',
        },
      ],
    }
    result.items = result.items.filter(el => hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
