import { snakeCase } from 'lodash'
import pluralize from 'pluralize'
import { computed, inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { isUuid } from './access-helpers'

function useTimeZonePresenter(
  resource,
  attributePrefix,
  timeZoneResourceIdAttribute,
  timeZoneResourceModel,
  datetimeAttribute = null,
  overriddenTimeZone = null,
) {
  const axios = inject('axios')
  const $store = useStore()

  const timeZoneResourceSelectRef = ref(null)
  const tzMappings = ref({})

  let lastKnownTimeZone = null
  const timeZone = computed(() => {
    if (overriddenTimeZone?.value) {
      return overriddenTimeZone.value
    }
    let result = null
    if (isUuid((resource.value || {})[timeZoneResourceIdAttribute])) {
      if (tzMappings.value[(resource.value || {})[timeZoneResourceIdAttribute]]) {
        result = tzMappings.value[(resource.value || {})[timeZoneResourceIdAttribute]]
      } else {
        result = (timeZoneResourceSelectRef?.value?.options || []).find(
          el => el.id == (resource.value || {})[timeZoneResourceIdAttribute],
        )?.timeZone
        if (!result) {
          result = lastKnownTimeZone
          fetchTimeZoneFor((resource.value || {})[timeZoneResourceIdAttribute])
        }
      }
    }
    if (result?.length) {
      lastKnownTimeZone = result
    }
    return result
  })

  const timeZoneNotMine = computed(() => {
    if (timeZone.value && $store.state.session.timeZone != timeZone.value) {
      if (
        (resource.value || {})[
          datetimeAttribute?.length ? datetimeAttribute : `${attributePrefix}At`
        ]
      ) {
        return (
          dayjs(
            (resource.value || {})[
              datetimeAttribute?.length ? datetimeAttribute : `${attributePrefix}At`
            ],
          )
            .tz(timeZone.value)
            .utcOffset() !=
          dayjs(
            (resource.value || {})[
              datetimeAttribute?.length ? datetimeAttribute : `${attributePrefix}At`
            ],
          )
            .tz($store.state.session.timeZone)
            .utcOffset()
        )
      } else {
        return (
          dayjs().tz(timeZone.value).utcOffset() !=
          dayjs().tz($store.state.session.timeZone).utcOffset()
        )
      }
    }
    return false
  })

  const fetchTimeZoneFor = timeZoneResourceId => {
    axios({
      method: 'get',
      url: `/events/${useRoute().params.eventSlug}/${snakeCase(
        pluralize(timeZoneResourceModel),
      )}/${timeZoneResourceId}/time_zone`,
    }).then(response => {
      if (response.data.timeZone) {
        tzMappings.value[timeZoneResourceId] = response.data.timeZone
      }
    })
  }

  const result = {}
  result[`${attributePrefix}TimeZoneResourceSelectRef`] = timeZoneResourceSelectRef
  result[`${attributePrefix}TimeZone`] = timeZone
  result[`${attributePrefix}TimeZoneNotMine`] = timeZoneNotMine
  return result
}

export { useTimeZonePresenter }
