<script>
export default {
  name: 'EditButton',
  emits: ['click'],
  props: {
    name: {
      type: String,
    },
    size: {
      type: String,
      default: null,
      validator(val) {
        return ['xs', 'sm', 'md', 'lg'].includes(val)
      },
    },
    noMs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClass() {
      return this.size ? `btn-${this.size}` : ''
    },
  },
}
</script>

<template lang="pug">
button.btn.btn-primary.text-white(
  :class="[sizeClass, { 'ms-2': !noMs }]"
  :name="name"
  @click="$emit('click', $event)"
  type="button"
)
  slot Edit record
</template>
