<script>
export default {
  name: 'LoadingIndicator',
  props: {
    message: { type: String, default: 'Map is loading...' },
  },
}
</script>

<template lang="pug">
transition(name="fade")
  .text-center.position-relative.d-flex.align-items-center.justify-content-center.pt-1.pb-1
    .spinner-grow.text-warning.me-2
    slot
      span {{ message }}
</template>
