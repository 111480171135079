import { defineAsyncComponent } from 'vue'

const ClientFunction = defineAsyncComponent(() => import('./client-function.vue'))
const ClientFunctionModal = defineAsyncComponent(() => import('./client-function-modal.vue'))
const ClientFunctionsModal = defineAsyncComponent(() => import('./client-functions-modal.vue'))
const ClientFunctionsSelect = defineAsyncComponent(() => import('./client-functions-select.vue'))
const ClientFunctionsTable = defineAsyncComponent(() => import('./client-functions-table.vue'))

export {
  ClientFunction,
  ClientFunctionModal,
  ClientFunctionsModal,
  ClientFunctionsSelect,
  ClientFunctionsTable,
}
