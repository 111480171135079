<script>
export default {
  name: 'DataTableShowButton',
  emits: ['click'],
  props: {
    name: { type: String },
    tooltip: { type: String, default: 'View' },
    iconSizeClass: { type: String, default: null },
    tooltipPlacement: { type: String, default: 'left' },
  },
}
</script>

<template lang="pug">
button.datatable-preview-btn.btn.btn-link.text-info.py-0.px-1(
  :name="name"
  @click="$emit('click', $event)"
  type="button"
  v-tooltip.options="{ title: tooltip, placement: tooltipPlacement }"
)
  .hover-backdrop
  slot
    i.bi-eye(:class="iconSizeClass")
</template>
