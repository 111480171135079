<script>
import { camelCase } from 'lodash'

export default {
  name: 'ImportStatus',
  props: {
    importStatus: {
      type: Object,
    },
    states: {
      type: Array,
    },
  },
  methods: {
    camelCase(el) {
      return camelCase(el)
    },
    humanizedState(item) {
      return this.states.find(el => el.value == item.aasmState)?.label
    },
  },
}
</script>

<template lang="pug">
.text-nowrap {{ humanizedState(importStatus) }}
  .progress(v-if="['rows_loading', 'in_progress'].includes(importStatus.aasmState)")
    .progress-bar(
      :aria-valuenow="importStatus.progress"
      :style="{ width: `${importStatus.progress}%` }"
      aria-valuemax="100"
      aria-valuemin="0"
      role="progressbar"
    ) {{ importStatus.progress }}%
  .small(v-else-if="importStatus[`${camelCase(importStatus.aasmState)}At`]")
    formatted-time(:t="importStatus[`${camelCase(importStatus.aasmState)}At`]")
</template>
