<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'RuleGroupPreview',
  props: {
    group: { type: Object, required: true },
    root: { type: Boolean, default: false },
    depth: { type: Number, default: 0 },
  },
  computed: {
    isOr() {
      return this.group.m === 'or'
    },
    indent() {
      return `margin-left: ${this.depth * 10}px`
    },
    hasOnlyGroups() {
      return !this.group.c?.length
    },

    operator() {
      return this.group.m === 'or' ? 'OR' : 'AND'
    },
  },
  components: {
    RulePreview: defineAsyncComponent(() => import('./rule-preview.vue')),
  },
}
</script>

<template lang="pug">
.rule-group(:class="{ root, or: isOr }")
  .operator.bold {{ operator }}
  ul
    li.rule-row(v-for="rule in group.c" :class="{ or: isOr }")
      rule-preview(:rule="rule")
    li.rules(v-for="childGroup in group.g" :class="{ or: isOr }")
      rule-group-preview(:depth="depth + 1" :group="childGroup")
</template>

<style scoped lang="scss"></style>
