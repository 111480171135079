import computeSchema from 'frontend/_globals/compute-schema'
import { InterfaceType } from 'frontend/_setup/setupContainer/constants/InterfaceType'
import { useUnsavedColumnPresetItems } from 'frontend/columns/composables/useUnsavedColumnPresetItems'
import { ColumnPresetFields } from 'frontend/columns/config/ColumnPresetFields'
import { ColumnSortDirection } from 'frontend/columns/enum/ColumnSortDirection'
import { convertColumnWidthMapToStoredWidths } from 'frontend/columns/functions/convertColumnWidthMapToStoredWidths'
import { convertStoredWidthsToColumnWidthMap } from 'frontend/columns/functions/convertStoredWidthsToColumnWidthMap'
import { mergeColumnPresetData } from 'frontend/columns/functions/mergeColumnPresetData'
import { ColumnPresetService } from 'frontend/columns/services/ColumnPresetService'
import { IColumnPresetData } from 'frontend/columns/types/IColumnPresetData'
import { IColumnPresetResponseEntity } from 'frontend/columns/types/IColumnPresetResponseEntity'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import { getEventOrganizationUser } from 'frontend/common/access-helpers'
import { useSortItems } from 'frontend/common/composables/useSortItems'
import { useToggleMultiSort } from 'frontend/common/composables/useToggleMultiSort'
import { useContainer } from 'frontend/container/composables/useContainer'
import { IDataModelTypeService } from 'frontend/dataModels/services/DataModelTypeService/types/IDataModelTypeService'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import localForage from 'localforage'
import { denormalize } from 'normalizr'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export function useCustomizableTableColumns(
  dataModelType: DataModelType,
  options: {
    defaultDisplayedColumnNames
    availableColumns
    forceProvidedDefaultColumnsOnInit
    defaultSort
  },
) {
  const store = useStore()
  const route = useRoute()

  const { container } = useContainer()
  const { convertStoredSortToSortItems, convertSortItemsToStoredSort } = useSortItems()
  const dataModelTypeService = container.get<IDataModelTypeService>(
    InterfaceType.services.DataModelTypeService,
  )

  const columnPresetService = new ColumnPresetService()
  const columnPresets = ref<IColumnPresetResponseEntity[]>(null)
  const currentColumnPresetId = ref<string>(null)
  const availableColumns =
    options.availableColumns || dataModelTypeService.getColumnConfig(dataModelType)
  const defaultColumns =
    options.defaultDisplayedColumnNames || dataModelTypeService.getDefaultColumns(dataModelType)
  const defaultVisibleColumnTypes = defaultColumns.filter(el =>
    Object.keys(availableColumns).includes(el),
  )
  const columnWidthsMap = ref<Map<string, number>>(new Map())
  const sortItems = ref<IColumnSortItems>([])
  const isMultiSortEnabled = ref<boolean>(false)
  const { toggleMultiSort } = useToggleMultiSort(sortItems, isMultiSortEnabled)
  const visibleColumnTypes = ref<string[]>(defaultVisibleColumnTypes)

  const { unsavedColumnPresetItems, currentUnsavedColumnPresetData } = useUnsavedColumnPresetItems(
    dataModelType,
    currentColumnPresetId,
    columnPresets,
  )

  function getPresetDataFromGlobalConfig(): IColumnPresetData {
    return {
      sortItems: [defaultVisibleColumnTypes[0], ColumnSortDirection.Ascending],
      visibleColumnTypes: defaultVisibleColumnTypes,
      columnWidthsMap: new Map(),
    }
  }
  function getPresetDataFromColumnPreset(
    columnPreset: IColumnPresetResponseEntity,
  ): IColumnPresetData {
    if (!columnPreset) {
      return
    }
    return {
      sortItems: convertStoredSortToSortItems(columnPreset.defaultSortColumnType),
      columnWidthsMap: convertStoredWidthsToColumnWidthMap(columnPreset.columnWidths),
      visibleColumnTypes: columnPreset.enabledColumnTypes.filter(el => {
        return Object.keys(availableColumns).includes(el)
      }),
    }
  }
  function getPresetDataFromUnsavedColumnPresetItem(columnPresetId: string): IColumnPresetData {
    const unsavedColumnPresetItemData = unsavedColumnPresetItems.value.find(preset => {
      return preset.columnPresetId === columnPresetId
    })?.data
    return unsavedColumnPresetItemData || {}
  }
  function getDefaultColumnData(columnPreset?: IColumnPresetResponseEntity): IColumnPresetData {
    return columnPreset
      ? getPresetDataFromColumnPreset(columnPreset)
      : getPresetDataFromGlobalConfig()
  }

  const unsavedColumnPresets = computed(() => {
    return (
      columnPresets.value?.filter(columnPreset => {
        const unsavedColumnPresetItemData = unsavedColumnPresetItems.value.find(preset => {
          return preset.columnPresetId === columnPreset.id
        })?.data
        if (!unsavedColumnPresetItemData) {
          return false
        }
        return isDataDifferent(
          mergeColumnPresetData(getDefaultColumnData(columnPreset), unsavedColumnPresetItemData),
          getDefaultColumnData(columnPreset),
        )
      }) || []
    )
  })
  const unsavedColumnPresetIds = computed(() => {
    return unsavedColumnPresets.value.map(item => item.id)
  })

  const currentOrganizationUserId = computed(() => {
    return (
      store.state.session.user?.impersonatedCurrentOrganizationUser?.id ||
      store.state.session.user?.currentOrganizationUser?.id
    )
  })

  function isDataDifferent(data1: IColumnPresetData, data2: IColumnPresetData) {
    const allColumnWidthKeys = [
      Array.from(data1.columnWidthsMap.keys()),
      Array.from(data2.columnWidthsMap.keys()),
    ]
    const uniqueColumnWidthKeys = Array.from(new Set(allColumnWidthKeys.flat()))

    const areColumnWidthsEqual = uniqueColumnWidthKeys.every(key => {
      return (data1.columnWidthsMap.get(key) || null) === (data2.columnWidthsMap.get(key) || null)
    })
    const rules = [
      data1.visibleColumnTypes?.join(',') === data2.visibleColumnTypes?.join(','),
      convertSortItemsToStoredSort(data1.sortItems) ===
        convertSortItemsToStoredSort(data2.sortItems),
      areColumnWidthsEqual,
    ]
    return rules.some(rule => !rule)
  }

  function loadColumnPreset(columnPresetId: string | null) {
    if (columnPresetId) {
      localForage.setItem(`currentColumnPresetId:${dataModelType}`, columnPresetId)
    } else {
      localForage.removeItem(`currentColumnPresetId:${dataModelType}`)
    }
    const columnPreset = columnPresets.value.find(preset => preset.id === columnPresetId)
    const currentData = mergeColumnPresetData(
      getDefaultColumnData(columnPreset),
      getPresetDataFromUnsavedColumnPresetItem(columnPresetId) || {},
    )
    visibleColumnTypes.value = currentData.visibleColumnTypes
    sortItems.value = currentData.sortItems
    if (isMultiSortEnabled.value === false && currentData.sortItems.length > 1) {
      isMultiSortEnabled.value = true
    } else if (isMultiSortEnabled.value === true && currentData.sortItems.length == 1) {
      isMultiSortEnabled.value = false
    }
    columnWidthsMap.value = currentData.columnWidthsMap
  }
  watch(currentColumnPresetId, value => {
    loadColumnPreset(value)
  })

  async function fetchColumnPresets() {
    const response = await columnPresetService.getAvailableFor(dataModelType, ColumnPresetFields)
    let schema
    if (!Object.keys(response.mappings || {}).length) {
      schema = computeSchema({}, {})
    } else {
      schema = computeSchema(ColumnPresetFields, response.mappings)
    }
    return response.result?.items?.map(itemId => {
      return denormalize(itemId, (schema?.items || [])[0] || schema, response.entities)
    })
  }

  watch(sortItems, value => {
    if (!currentColumnPreset.value) {
      return
    }
    currentUnsavedColumnPresetData.value = {
      ...currentUnsavedColumnPresetData.value,
      sortItems: value,
    }
  })

  watch(columnWidthsMap, value => {
    if (!currentColumnPreset.value) {
      return
    }
    currentUnsavedColumnPresetData.value = {
      ...currentUnsavedColumnPresetData.value,
      columnWidthsMap: value,
    }
  })

  watch(visibleColumnTypes, value => {
    if (!currentColumnPreset.value) {
      return
    }
    currentUnsavedColumnPresetData.value = {
      ...currentUnsavedColumnPresetData.value,
      visibleColumnTypes: value,
    }
  })

  watch(currentOrganizationUserId, () => {
    fetchAndInitializeColumnPresets()
  })

  async function fetchAndInitializeColumnPresets() {
    if (!dataModelType) {
      return
    }
    const [columnPresetsResponse, currentColumnPresetIdFromStorage] = await Promise.all([
      fetchColumnPresets(),
      localForage.getItem(`currentColumnPresetId:${dataModelType}`),
      localForage.getItem(`unsavedColumnPresetItems:${dataModelType}`),
    ])

    columnPresets.value = columnPresetsResponse

    if (columnPresets.value.length) {
      if (
        currentColumnPresetIdFromStorage &&
        !!columnPresets.value.find(el => el.id == currentColumnPresetIdFromStorage)
      ) {
        currentColumnPresetId.value = currentColumnPresetIdFromStorage as string
      } else {
        currentColumnPresetId.value = columnPresets.value[0].id
      }
    } else {
      currentColumnPresetId.value = null
      const finalSortItems = []
      const defaultSortArray = Array.isArray(options.defaultSort)
        ? options.defaultSort
        : [options.defaultSort]
      for (const item of defaultSortArray) {
        const splittedItem = item.split(' ')
        finalSortItems.push([
          item[0],
          splittedItem[1] === 'asc'
            ? ColumnSortDirection.Ascending
            : ColumnSortDirection.Descending,
        ])
      }
      sortItems.value = defaultSortArray
    }
  }

  onMounted(async () => {
    fetchAndInitializeColumnPresets()
  })

  const currentColumnPreset = computed(() => {
    return columnPresets.value?.find(preset => preset.id === currentColumnPresetId.value)
  })
  const defaultDisplayedColumnNames = computed(() => {
    return defaultColumns
  })
  const computedAvailableColumns = computed(() => {
    return availableColumns
  })
  const areColumnPresetsLoading = computed(() => {
    return !columnPresets.value
  })
  const isPresetUnsaved = computed(() => {
    if (!currentColumnPreset.value) {
      return false
    }
    return unsavedColumnPresetIds.value.includes(currentColumnPreset.value.id)
  })

  async function saveCurrentColumnPreset() {
    if (!currentColumnPreset.value) {
      return
    }
    await saveColumnPreset({
      id: currentColumnPreset.value.id,
    })
  }
  const allColumnPresetData = computed(() => {
    return (
      columnPresets.value?.map(columnPreset => {
        const unsavedColumnPresetItemData = unsavedColumnPresetItems.value.find(preset => {
          return preset.columnPresetId === columnPreset.id
        })?.data
        return {
          columnPresetId: columnPreset.id,
          data: {
            ...getDefaultColumnData(columnPreset),
            ...(unsavedColumnPresetItemData || {}),
          },
        }
      }) || []
    )
  })

  async function saveColumnPreset(params: {
    columnPresetId: string | null
    name?: string
    onSuccessCallback?(): void,
    onErrorCallback?(errors: Record<string, string[]>): void
  }) {
    const { columnPresetId, name, onSuccessCallback, onErrorCallback } = params
    const columnPreset = columnPresets.value.find(preset => {
      return preset.id === columnPresetId
    })
    const columnPresetData = allColumnPresetData.value.find(item => {
      return item.columnPresetId === columnPresetId
    })?.data
    const eventOrganizationUser = getEventOrganizationUser(
      store.state.session.user,
      route.params.eventSlug,
    )
    const columnPresetToSave = {
      defaultSortColumnType: convertSortItemsToStoredSort(columnPresetData.sortItems),
      enabledColumnTypes: columnPresetData.visibleColumnTypes,
      eventOrganizationUserId: eventOrganizationUser.id,
      dataModelType: dataModelType,
      columnWidths: convertColumnWidthMapToStoredWidths(columnPresetData.columnWidthsMap),
    }
    const isCurrentPresetGlobal = columnPreset?.isGlobal
    if (isCurrentPresetGlobal || !columnPresetId) {
      columnPresetToSave.name = name || `New preset ${columnPresets.value.length + 1}`
    } else {
      columnPresetToSave.id = columnPresetId
    }

    try {
      await columnPresetService.save({
        data: {
          ...columnPresetToSave,
        },
      })
      unsavedColumnPresetItems.value = unsavedColumnPresetItems.value.filter(preset => {
        return preset.columnPresetId !== columnPresetId
      })
      await refetchColumnPresets()
      currentColumnPresetId.value = columnPresetId
      loadColumnPreset(columnPresetId)
      if (onSuccessCallback) {
        onSuccessCallback()
      }
    } catch(e) {
      if(onErrorCallback) {
        onErrorCallback(e.response.data.errors)
      }
    }
  }

  function restoreColumnPreset(columnPresetId: string | null) {
    unsavedColumnPresetItems.value = unsavedColumnPresetItems.value.filter(preset => {
      return preset.columnPresetId !== columnPresetId
    })
    if (columnPresetId === currentColumnPresetId.value) {
      loadColumnPreset(columnPresetId)
    }
  }

  async function refetchColumnPresets() {
    columnPresets.value = await fetchColumnPresets()
  }
  return {
    defaultDisplayedColumnNames,
    displayedColumnNames: visibleColumnTypes,
    availableColumns: computedAvailableColumns,
    columnWidthsMap,
    areColumnPresetsLoading,
    columnPresets,
    currentColumnPresetId,
    isPresetUnsaved,
    saveCurrentColumnPreset,
    sortItems,
    isMultiSortEnabled,
    toggleSort: toggleMultiSort,
    unsavedColumnPresetIds,
    saveColumnPreset,
    restoreColumnPreset,
    fetchColumnPresets: refetchColumnPresets,
  }
}
