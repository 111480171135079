<script>
import { inject, ref, watchEffect } from 'vue'

export default {
  name: 'Tab',
  props: {
    title: {
      type: String,
    },
    onTabClick: {
      type: Function,
      default: () => ({}),
    },
    eager: { type: Boolean, default: false },
    counter: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
  },
  setup(props) {
    const selectedTitle = inject('selectedTitle')
    const wasLoaded = ref(props.eager)

    watchEffect(() => {
      if (!wasLoaded.value && selectedTitle.value === props.title) {
        wasLoaded.value = true
      }
    })

    return {
      selectedTitle,
      wasLoaded,
    }
  },
}
</script>
<template lang="pug">
div(v-if="wasLoaded" v-show="title == selectedTitle")
  slot
</template>
