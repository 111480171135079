<script>
// import ValidatedForm from 'frontend/_mixins/validated-form'
import FormLabel from './_form-label.vue'

export default {
  name: 'EaNestedFormCollection',
  // mixins: [ValidatedForm],
  emits: ['update:modelValue'],
  props: {
    disableAddOption: { type: Boolean, default: false },
    hideDestroyed: { type: Boolean, default: false },
    label: { type: String, default: null },
    addLabel: { type: String, default: 'Add an item' },
    required: { type: Boolean, default: false },
    modelValue: { type: Array, default: () => [] },
    validatorsUrl: { type: String, default: null },
    positionOfAddedItem: { type: Number, default: -1 },
    bottomButtonClasses: { type: [Array, String, Object], default: 'mt-4' },
    skippedRemoveButtonIndexes: { type: [String, Array], default: () => [] },
    skipRowBorders: { type: Boolean, default: false },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => ![Array, Object].includes(item.constructor))
      },
    },
  },
  // setup() {
  //   return ValidatedForm.setup()
  // },
  methods: {
    addItem() {
      const newValue = [...(this.modelValue || [])]
      let positionToInject = null
      if (this.positionOfAddedItem < 0) {
        positionToInject = newValue.length + this.positionOfAddedItem + 1
      } else {
        positionToInject = this.positionOfAddedItem
      }
      newValue.splice(positionToInject, 0, {})
      // newValue.push({})
      this.$emit('update:modelValue', newValue)
    },
    toggleItem(index) {
      const newValue = [...(this.modelValue || [])]
      if (newValue[index]?.id) {
        newValue[index]._destroy = !newValue[index]._destroy
      } else {
        newValue.splice(index, 1)
      }
      this.$emit('update:modelValue', newValue)
    },
    itemUpdated(index, newItem) {
      const newValue = [...(this.modelValue || [])]
      newValue[index] = newItem
      this.$emit('update:modelValue', newValue)
    },
  },
  components: {
    FormLabel,
  },
}
</script>

<template lang="pug">
.mt-2.mb-2
  form-label(:label="label" :required="required")
  .d-flex.align-items-center.justify-content-center.mt-1.text-gray(v-if="!modelValue.length")
    .me-2 This section is empty
    add-button(@click="addItem()" name="addNestedFormItem" size="sm") {{ addLabel }}
  div(v-else)
    .d-flex.align-items-end.justify-content-between.mb-1.mt-1.subform(
      v-for="(iteratedItem, index) in modelValue"
      :class="{ 'no-border': skipRowBorders }"
      v-tooltip="iteratedItem._destroy ? 'Item is going to be removed' : null"
    )
      .flex-grow-1(:class="{ 'opacity-25 pointer-events-none': iteratedItem._destroy }")
        ea-nested-form(
          :additional-errors="(additionalErrors || [])[index]"
          :modelValue="iteratedItem"
          :validators-url="validatorsUrl"
          @update:modelValue="itemUpdated(index, $event)"
        )
          template(#default="item")
            slot(v-bind="Object.assign({ index: index }, item)")

      template(v-if="skippedRemoveButtonIndexes != 'all'")
        button.btn.text-white.btn-sm.ms-2.p-2.align-self-start(
          v-if="skippedRemoveButtonIndexes.includes(index)"
          :class="bottomButtonClasses"
          disabled
        )
          i.bi-x-lg
        remove-button.ms-2.p-2.align-self-start(
          v-else
          :class="bottomButtonClasses"
          @click="toggleItem(index)"
          size="sm"
          v-tooltip="!modelValue[index]?._destroy ? 'Remove item' : 'Restore item'"
        )
          i.bi-x-lg(v-if="!modelValue[index]?._destroy")
          i.bi-recycle(v-else)
    .text-end(v-if="!disableAddOption")
      add-button(@click="addItem()" size="sm") {{ addLabel }}
</template>
