import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import TrueFalseLabel from 'frontend/common/true-false-label.vue'
import ShowNumberOfHours from 'frontend/common/show-number-of-hours.vue'
import ShiftCheckInOut from 'frontend/driver-sources/driver-source-shift-check-in-out.vue'
import InclusiveShiftCheckInOut from 'frontend/driver-sources/driver-source-inclusive-shift-check-in-out.vue'
import FormattedTime from 'frontend/common/formatted-time.vue'

export const DriverSourceDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'billable',
    'default_shift_length',
    'max_overtime',
    'min_time_between_shifts',
    'shift_check_in_time',
    'shift_check_out_time',
    'inclusive_shift_check_in_out',
    'updated_at',
    'created_at',
  ],
  subscriptions: {
    items: 'DriverSourcesChannel',
  },
  defaultColumns: [
    'eid',
    'name',
    'billable',
    'defaultShiftLength',
    'maxOvertime',
    'minTimeBetweenShifts',
    'shiftCheckInTime',
    'shiftCheckOutTime',
    'inclusiveShiftCheckInOut',
    'updatedDatetime',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    billable: {
      label: 'Billable',
      header: {
        sort: 'billable',
      },
      cell: {
        kind: 'component',
        component: markRaw(TrueFalseLabel),
        componentProps: driverSource => ({ item: driverSource.billable }),
      },
    },
    defaultShiftLength: {
      label: 'Default shift length',
      header: {
        sort: 'defaultShiftLength',
      },
      cell: {
        kind: 'component',
        component: markRaw(ShowNumberOfHours),
        componentProps: driverSource => ({ numberOfHours: driverSource.defaultShiftLength }),
      },
    },
    maxOvertime: {
      label: 'Max overtime',
      header: {
        sort: 'maxOvertime',
      },
      cell: {
        kind: 'component',
        component: markRaw(ShowNumberOfHours),
        componentProps: driverSource => ({ numberOfHours: driverSource.maxOvertime }),
      },
    },
    minTimeBetweenShifts: {
      label: 'Min time between shifts',
      header: {
        sort: 'minTimeBetweenShifts',
      },
      cell: {
        kind: 'component',
        component: markRaw(ShowNumberOfHours),
        componentProps: driverSource => ({ numberOfHours: driverSource.minTimeBetweenShifts }),
      },
    },
    shiftCheckInTime: {
      label: 'Shift check-in time',
      header: {
        sort: 'shiftCheckInTime',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: markRaw(ShiftCheckInOut),
        componentProps: driverSource => ({
          driverSource,
          attr: 'shiftCheckInTime',
        }),
      },
    },
    shiftCheckOutTime: {
      label: 'Shift check-out time',
      header: {
        sort: 'shiftCheckOutTime',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: markRaw(ShiftCheckInOut),
        componentProps: driverSource => ({
          driverSource,
          attr: 'shiftCheckOutTime',
        }),
      },
    },
    inclusiveShiftCheckInOut: {
      label: 'Shift CI/CO included',
      tooltip: 'Shift check-in/check-out times are included in the shift length',
      header: {
        sort: 'inclusiveShiftCheckInOut',
      },
      cell: {
        kind: 'component',
        component: markRaw(InclusiveShiftCheckInOut),
        componentProps: driverSource => ({
          driverSource,
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.createdAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}