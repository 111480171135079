import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function usePlacesSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }

  const section = computed(() => {
    const result = {
      name: 'Places',
      items: [
        {
          title: 'Clusters',
          isDisabled: false,
          path: 'clusters',
          icon: 'bi-grid',
        },
        {
          title: 'Locations',
          isDisabled: false,
          path: 'locations',
          icon: 'bi-grid',
        },
        {
          title: 'Transportation points',
          isDisabled: false,
          path: 'transportation-points',
          icon: 'bi-grid',
        },
        {
          title: 'Staging Areas',
          isDisabled: false,
          path: 'staging-areas',
          icon: 'bi-grid',
        },
        {
          title: 'Depots',
          isDisabled: false,
          path: 'depots',
          icon: 'bi-grid',
        },
        {
          title: 'Origin X Destination Matrix',
          isDisabled: false,
          path: 'origin-x-destination-matrix',
          icon: 'fas fa-route',
        },
      ],
    }

    result.items = result.items.filter(el => hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
