<script>
const defaultOptions = {
  strokeColor: '#63BFC2',
  fillColor: '#4477AA',
  fontColor: '#FFFFFF',
  scale: 1,
}
export default {
  name: 'LocationPin',
  props: {
    options: {
      type: Object,
      default: () => defaultOptions,
    },
  },
  computed: {
    fontSize() {
      const letters = this.options.label?.length
      if (letters > 3) {
        return 11
      } else if (letters > 2) {
        return 12
      }
      if (letters > 1) {
        return 14
      } else {
        return 12
      }
    },
    mergedOptions() {
      return {
        ...defaultOptions,
        ...(this.options || {}),
      }
    },
  },
}
</script>

<template lang="pug">
svg.custom-mapbox-marker(
  :style="{ scale: mergedOptions.scale }"
  height="36"
  viewBox="-4 0 36 36"
  width="36"
  xmlns="http://www.w3.org/2000/svg"
)
  g(fill="none" fill-rule="evenodd")
    path(
      :fill="mergedOptions.strokeColor"
      d="M14 0c7.732 0 14 5.641 14 12.6C28 23.963 14 36 14 36S0 24.064 0 12.6C0 5.641 6.268 0 14 0Z"
    )
    circle(:fill="mergedOptions.fillColor" cx="14" cy="14" fill-rule="nonzero" r="11")
    text(
      v-if="mergedOptions.label"
      :fill="mergedOptions.fontColor"
      :font-size="fontSize"
      text-anchor="middle"
      x="14"
      y="18"
    ) {{ mergedOptions.label }}
</template>

<style scoped>
.custom-mapbox-marker {
  transform-origin: bottom;
}
</style>
