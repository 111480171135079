import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { GET, POST, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export const FIELDS_FOR_SELECT = ['id', 'name', 'exportable_type', 'type']
export class ReportsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/reports`,
    blocker,
    fetchError = 'Failed to fetch report.',
    saveError = 'Failed to save report.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'reports',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        sorts: ['exportable_type asc', 'name asc'],
        fields: FIELDS_FOR_SELECT,
      })
  }

  regenerate(item, errorHandler = this._onFetchError, config = {}) {
    const url = `${this.baseUrl}/${item.id}/regenerate`
    return this._makeCall(POST, errorHandler, { url, ...config })
  }

  getColumnNames(errorHandler = this._onFetchError, config = {}) {
    const url = `${this.baseUrl}/column_names`
    return this._makeCall(GET, errorHandler, { url, ...config })
  }
}
