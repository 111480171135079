<script>
import { OrganizationsService } from 'frontend/organizations/organizations-service'

import car from './car_75.jpeg'

export default {
  name: 'SessionLayout',
  data() {
    return {
      car,
      imageFetched: false,
      customBgImage: null,
    }
  },
  setup() {
    const organizationsService = new OrganizationsService()
    return {
      organizationsService,
    }
  },
  computed: {
    isOnMainDomain() {
      return window.location.host == this.$mainDomain
    },
  },
  async created() {
    if (this.isOnMainDomain) {
      this.imageFetched = true
    } else {
      this.customBgImage = await this.organizationsService.fetchCustomBackgroundImageByDomain()
      this.imageFetched = true
    }
  },
}
</script>
<template lang="pug">
.welcome-container
  .left-col
    img(v-if="imageFetched" :src="customBgImage || car")
  .right-col
    router-view
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/_variables.scss';
@import 'bootstrap/scss/bootstrap-utilities.scss';

.welcome-container {
  height: 100vh;
  width: calc(100% + var(--bs-gutter-x, 1.5rem));
  display: flex;
  margin-left: var(--bs-gutter-x, -0.75rem);
  .left-col {
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(xl) {
      width: calc(100% - 400px);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right-col {
    box-shadow: $box-shadow;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 70vw;
    top: 10vh;
    left: 15vw;
    padding: 2rem 1rem;
    background: rgba(255, 255, 255, 0.6);
    @include media-breakpoint-up(md) {
      top: auto;
      left: auto;
      padding: 1rem;
      justify-content: stretch;
      height: 100%;
      position: relative;
      background: $white;
      width: 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(xl) {
      width: 400px;
    }
  }
}
</style>
