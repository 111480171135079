import {DataModelType} from 'frontend/roles/enum/DataModelType'
import {useContainer} from 'frontend/container/composables/useContainer'
import {IDataModelTypeService} from 'frontend/dataModels/services/DataModelTypeService/types/IDataModelTypeService'
import {InterfaceType} from 'frontend/_setup/setupContainer/constants/InterfaceType'
import {inject, Ref} from "vue"
import {ReportsService} from "frontend/reports/reports-service";
import {computed} from "vue";

export function useDataModelReport(
  dataModelType: DataModelType,
  displayedColumnNames: Ref<string[]>,
  providedExportableType?: string,
) {
  const {container} = useContainer()
  const dataModelTypeService = container.get<IDataModelTypeService>(InterfaceType.services.DataModelTypeService)
  const reportsService = new ReportsService()
  const $info = inject('$info')

  const reportErrorHandler = err => {
    console.error('ERROR CREATING A REPORT', err)
    $error({
      title: 'Could not generate the report',
      message: 'Please try again later or let us know using feedback',
    })
  }

  const exportableType = computed(() => {
    return providedExportableType || dataModelTypeService.getExportableType(dataModelType)
  })

  function generateReport(filterQuery) {
    const {
      filters,
      prefilters,
      sort,
      batchActions
    } = filterQuery
    const payload = {
      exportable_type: exportableType.value,
      sorts: (sort || '').constructor == String ? [sort] : sort,
      columns: [...displayedColumnNames.value],
    }
    if (!batchActions?.allChecked && Object.values(batchActions?.checkedItems || {}).length) {
      payload.filters = { id_in: Object.values(batchActions.checkedItems) }
    } else {
      payload.filters = { ...(prefilters || {}), ...(filters || {}) }
    }

    reportsService.save({ data: payload, errorHandler: reportErrorHandler }).then(() => {
      $info({
        title: 'The report is being generated...',
        message:
          'You will be notified once it is finished or you can see progress in "Reports" module',
        showSpinner: true,
      })
    })
  }

  return {
    generateReport,
    exportableType,
  }
}