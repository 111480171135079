export default {
  required: () => `Can not be empty`,
  notRequired: () => `Should be absent`,
  minLength: ({ $params }) => `Should be at least ${$params.min} characters long`,
  maxLength: ({ $params }) => `The maximum length allowed is ${$params.max}`,
  sameLength: ({ $params }) => `The length must be exactly ${$params.value} characters`,
  minValueExclusive: ({ $params }) => `Must be greater than ${$params.min}`,
  minValue: ({ $params }) => `Must be equal or greater than ${$params.min}`,
  maxValueExclusive: ({ $params }) => `Must be lower than ${$params.max}`,
  maxValue: ({ $params }) => `Must be equal or lower than ${$params.max}`,
  otherThan: ({ $params }) =>
    `Must be different from ${$params?.provided?.message || 'provided value'}`,
  timeBetween: ({ $params }) => {
    const startInt = parseInt($params?.provided?.start.replace(':', ''))
    const endInt = parseInt($params?.provided?.end.replace(':', ''))
    if (startInt > endInt) {
      return `Must be between 0:00-${$params?.provided?.end} or ${$params?.provided?.start}-23:59`
    } else if (startInt < endInt) {
      return `Must be between ${$params?.provided?.start}-${$params?.provided?.end}`
    } else {
      return `Must be other than ${$params?.provided?.start}`
    }
  },
  equals: ({ $params }) => `Must be equal to ${$params.value}`,
  integer: () => `Must be an integer`,
  inArray: () => `Not included in the allowed values`,
  notInArray: () => `Matches one of the forbidden values`,
  minArrayLength: ({ $params }) => `Should contain at least ${$params.min} items`,
  maxArrayLength: ({ $params }) => `Maximum amount of items is ${$params.max}`,
  sameArrayLength: ({ $params }) => `The number of items should be exactly ${$params.value}`,
  taken: () => 'The value is already taken',
  immutable: () => 'The value can not be modified',
  uniqueness: () => 'The value must be unique',
  format: () => 'Invalid format',
}
