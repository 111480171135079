<script setup lang="ts">
import { CriteriaValueType } from 'frontend/common/data-tables/search-criteria/enum/CriteriaValueType'
import BooleanCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/BooleanCriteriaValueTypeContentStrategy.vue'
import CustomContainsCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/CustomContainsCriteriaValueTypeContentStrategy.vue'
import DateMultiCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/DateMultiCriteriaValueTypeContentStrategy.vue'
import DateTimeCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/DateTimeCriteriaValueTypeContentStrategy.vue'
import DateTimeWithVariablesCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/DateTimeWithVariablesCriteriaValueTypeContentStrategy.vue'
import InputCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/InputCriteriaValueTypeContentStrategy.vue'
import ResourceListCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/ResourceListCriteriaValueTypeContentStrategy.vue'
import SelectionCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/SelectionCriteriaValueTypeContentStrategy.vue'
import TaggableSelectCriteriaValueTypeContentStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypeContentStrategies/TaggableSelectCriteriaValueTypeContentStrategy.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import type { Component } from 'vue'
import { computed } from 'vue'

interface Props {
  criteriaValueType: CriteriaValueType
  modelValue: unknown
  humanizedPredicate: string
  criterion: ISearchCriteriaNode
  predicate: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: unknown): void
}
const emit = defineEmits<Emits>()

const strategyMap = new Map<CriteriaValueType, Component>([
  [CriteriaValueType.Boolean, BooleanCriteriaValueTypeContentStrategy],
  [CriteriaValueType.CustomContains, CustomContainsCriteriaValueTypeContentStrategy],
  [CriteriaValueType.DateMulti, DateMultiCriteriaValueTypeContentStrategy],
  [CriteriaValueType.DateTime, DateTimeCriteriaValueTypeContentStrategy],
  [CriteriaValueType.DateTimeWithVariables, DateTimeWithVariablesCriteriaValueTypeContentStrategy],
  [CriteriaValueType.Input, InputCriteriaValueTypeContentStrategy],
  [CriteriaValueType.ResourceList, ResourceListCriteriaValueTypeContentStrategy],
  [CriteriaValueType.Selection, SelectionCriteriaValueTypeContentStrategy],
  [CriteriaValueType.TaggableSelect, TaggableSelectCriteriaValueTypeContentStrategy],
])
const strategyComponent = computed(() => {
  return strategyMap.get(props.criteriaValueType)
})

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <Component
    :is="strategyComponent"
    v-if="strategyComponent"
    :key="criteriaValueType"
    v-model="valueModel"
    :humanized-predicate="humanizedPredicate"
    :criterion="criterion"
    :predicate="predicate"
  >
    <slot />
  </Component>
</template>
