<script>
import { AASM_STATES } from './index'

export default {
  name: 'ShiftActionAasmState',
  props: {
    aasmState: {
      type: String,
      required: true,
    },
  },

  computed: {
    aasmStateLabel() {
      return AASM_STATES.find(as => as.value == this.aasmState)?.label
    },
  },
}
</script>

<template lang="pug">
span(:class="`sa-state-${aasmState}`") {{ aasmStateLabel }}
</template>
