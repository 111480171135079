import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import Osl from "frontend/osls/osl.vue";
import DatatableQueryPreview from "frontend/common/ea-query-preview/datatable-query-preview.vue";

export const OSLTBRuleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'priority',
    'eid',
    'only_within_operational_period',
    'criteria',
    'updated_at',
    'created_at',
    {
      operational_service_level: [
        'id',
        'name',
        'font_color',
        'background_color',
        'border_color',
        'description',
      ],
    },
  ],
  subscriptions: {
    items: 'OSLTBRulesChannel',
    operational_service_level: 'OperationalServiceLevelsSimpleChannel',
  },
  defaultColumns: [
    'priority',
    'eid',
    'onlyWithinOperationalPeriod',
    'operationalServiceLevel',
    'criteria',
  ],
  columnConfig: {
    priority: {
      label: 'Priority',
      cell: {
        kind: 'simple',
        path: 'priority',
      },
    },
    eid: {
      label: 'EID',
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    onlyWithinOperationalPeriod: {
      label: 'Only within operational period',
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: oslTbRule => ({
          item: oslTbRule.onlyWithinOperationalPeriod,
        }),
      },
    },
    operationalServiceLevel: {
      label: 'Operational Service Level',
      cell: {
        kind: 'component',
        component: Osl,
        componentProps: oslTbRule => ({
          osl: oslTbRule.operationalServiceLevel,
        }),
      },
    },
    criteria: {
      label: 'Criteria',
      cell: {
        kind: 'component',
        component: DatatableQueryPreview,
        componentProps: oslTbRule => ({
          query: oslTbRule.criteria,
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: oslTbRule => ({ t: oslTbRule.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: oslTbRule => ({ t: oslTbRule.createdAt, format: 'dateOnly' }),
      },
    },
    createdTime: {
      label: 'Created Time',
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: oslTbRule => ({ t: oslTbRule.createdAt, format: 'timeOnly' }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: oslTbRule => ({ t: oslTbRule.updatedAt }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: oslTbRule => ({ t: oslTbRule.updatedAt, format: 'dateOnly' }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: oslTbRule => ({ t: oslTbRule.updatedAt, format: 'timeOnly' }),
      },
    },
  }
}