import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class ClientFunctionsService extends ResourceService {
  constructor({
    apiProvider = axios,
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = eventSlug?.length ? `/events/${eventSlug}/client_functions` : `/client_functions`,
    blocker,
    fetchError = 'Failed to fetch client function',
    saveError = 'Failed to save client function.',
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'client-functions',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        fields: ['id', 'name'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }
}
