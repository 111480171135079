import { chatGroupsModule } from 'frontend/chat/data/stores/chat-groups-store'
import chatStoreModule, { chatPersisted } from 'frontend/chat/data/stores/chat-ui.js'
import livePanelStoreModule, { livePanelPersisted } from 'frontend/live-panel/data/live-panel-store'
import { createStore } from 'vuex'

import currentContextStoreModule from './current-context-store'
import mapsContextStoreModule from './maps-context-store'
import modalsStoreModule from './modals-store'
import notSentCCMCounterStoreModule from './not-sent-ccm-counter-store'
import sessionStoreModule from './session-store'
import toastStoreModule from './toast-store'

const store = createStore({
  modules: {
    session: sessionStoreModule,
    toast: toastStoreModule,
    modals: modalsStoreModule,
    notSentCCMCounter: notSentCCMCounterStoreModule,
    currentContext: currentContextStoreModule,
    chat: chatStoreModule,
    chatGroups: chatGroupsModule,
    livePanel: livePanelStoreModule,
    mapsContext: mapsContextStoreModule,
  },
  plugins: [chatPersisted, livePanelPersisted],
})

export default store
