import { defineAsyncComponent } from 'vue'

const VehicleBrandsAndModelsSelect = defineAsyncComponent(() =>
  import('./vehicle-brands-and-models-select.vue'),
)
const VehicleModel = defineAsyncComponent(() => import('./vehicle-model.vue'))
const VehicleModelModal = defineAsyncComponent(() => import('./vehicle-model-modal.vue'))
const VehicleModelsModal = defineAsyncComponent(() => import('./vehicle-models-modal.vue'))
const VehicleModelsSelect = defineAsyncComponent(() => import('./vehicle-models-select.vue'))
const VehicleModelsTable = defineAsyncComponent(() => import('./vehicle-models-table.vue'))

export {
  VehicleBrandsAndModelsSelect,
  VehicleModel,
  VehicleModelModal,
  VehicleModelsModal,
  VehicleModelsSelect,
  VehicleModelsTable,
}
