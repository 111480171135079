import consumer from 'channels/consumer'
import { camelResponse } from 'frontend/_config/case-converter'
import computeSchema from 'frontend/_globals/compute-schema'
import { denormalize } from 'normalizr'

const eventChannelFields = [
  'id',
  'name',
  'slug',
  'min_default_shift_length',
  'global_filter_config',
  'sync_clients_enabled',
  'sync_client_categories_enabled',
  'sync_locations_enabled',
  'sync_transport_bookings_enabled',
  'sync_shuttle_transfers_enabled',
  'sync_drivers_enabled',
  'dropoff_request_enabled',
  'tb_no_service_error_message',
  'smtp_sender',
  'smtp_reply_to',
  'smtp_bcc',
  'footer',
  'start_at',
  'end_at',
  'hotline_number',
  'default_time_zone',
  'available_time_zones',
  'shift_check_in_time',
  'shift_check_out_time',
  'inclusive_shift_check_in_out',
  'bounding_box',
  'duration_rules_gis_provider',
  'duration_rules_gis_config',
  'fleet_number_mask',
  'fleet_number_mask_message',
  'opt_out_message_body',
  'opt_out_message_enabled',
  'start_date',
  'end_date',
  'drivers_logout_date',
  'auto_time_recalculation_nearest_start_offset',
  'auto_time_recalculation_nearest_end_offset',
  'auto_time_recalculation_nearest_update_behavior',
  'auto_time_recalculation_nearest_rule_behavior',
  'auto_time_recalculation_nearest_min_delta',
  'auto_time_recalculation_nearest_affected_models',
  'auto_time_recalculation_nearest_rule_gis_provider',
  'auto_time_recalculation_nearest_rule_gis_config',
  'auto_time_recalculation_nearest_frequency',
  'auto_time_recalculation_further_start_offset',
  'auto_time_recalculation_further_end_offset',
  'auto_time_recalculation_further_update_behavior',
  'auto_time_recalculation_further_rule_behavior',
  'auto_time_recalculation_further_min_delta',
  'auto_time_recalculation_further_affected_models',
  'auto_time_recalculation_further_rule_gis_provider',
  'auto_time_recalculation_further_rule_gis_config',
  'auto_time_recalculation_further_frequency',
  'live_traffic_enabled',
  'logo_urls',
  {
    default_map_provider: ['id', 'name', 'provider', 'api_key'],
  },
  {
    default_gis_provider: ['id', 'name', 'provider', 'api_key'],
  },
  { clusters: ['id', 'name', 'time_zone', 'slug'] },
]

const eventFields = [...eventChannelFields, 'custom_mapbox_style'] //, 'logo'

let eventRefetchOnConnect = false

const currentContextStoreModule = {
  namespaced: true,
  state: () => ({
    event: null,
    eventIsBeingFetched: false,
    eventCableSubscription: null,
    cluster: null,
    clusterIsBeingFetched: false,
    clusterCableSubscription: null,
    liveTrafficSegments: null,
  }),
  getters: {
    currentCluster: state => state.cluster,
    currentEvent: state => state.event,
    defaultMapProvider: state => state.event?.defaultMapProvider,
    defaultGisProvider: state => state.event?.defaultGisProvider,
    boundingBox: state => state.event?.boundingBox,
    liveTrafficEnabled: state => state.event?.liveTrafficEnabled,
    liveTrafficSegments: state => state.liveTrafficSegments,
  },
  mutations: {
    setEvent(state, event) {
      state.event = event
    },
    setCluster(state, cluster) {
      state.cluster = cluster
    },
    setEventIsBeingFetched(state, value) {
      state.eventIsBeingFetched = value
    },
    setEventCableSubscription(state, value) {
      state.eventCableSubscription = value
    },
    setLiveTrafficSegments(state, value) {
      state.liveTrafficSegments = value
    },
  },
  actions: {
    unsubscribe({ commit, state }) {
      return new Promise(resolve => {
        if (state.eventCableSubscription) {
          const remoteSubscription = consumer.subscriptions.subscriptions.find(
            s => s.customIdentifier == state.eventCableSubscription.customIdentifier,
          )
          if (remoteSubscription) {
            console.log(`%c currentEvent will unsubscribe from its channel`, 'color: gray')
            consumer.subscriptions.remove(remoteSubscription)
          }
          eventRefetchOnConnect = false
          // state.eventCableSubscription.unsubscribe()
          commit('setEventCableSubscription', null)
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
    subscribe({ state, commit, dispatch }, eventId) {
      return new Promise(resolve => {
        dispatch('unsubscribe').then(() => {
          const customIdentifier = Math.round(Math.random() * 1000000)
          const eventCableSubscription = consumer.subscriptions.create(
            {
              channel: 'CurrentEventChannel',
              event_id: eventId,
              fields: eventChannelFields,
              customIdentifier,
            },
            {
              customIdentifier,
              connected() {
                console.log(`%c CONNECTED TO CurrentEventChannel`, 'color: #4c74b9')
                if (eventRefetchOnConnect) {
                  eventRefetchOnConnect = false
                  let slug = state.event?.slug
                  if (!slug || !slug.length || slug == 'undefined') {
                    const regex = new RegExp('\\/events\\/(?<slug>[a-zA-Z_\\-0-9]+)\\/.*')
                    slug = window.location.pathname.match(regex)?.groups?.slug
                  }
                  dispatch('fetchEvent', { slug: slug, forceRefetch: true })
                }
              },
              disconnected() {
                eventRefetchOnConnect = true
                console.warn(`DISCONNECTED FROM CurrentEventChannel`)
              },
              rejected() {
                console.error('REJECTED CONNECTION TO CurrentEventChannel')
              },
              received(message) {
                console.log(`%c CurrentEventChannel message came in:`, 'color: gray', message)
                if (!message.obj) {
                  dispatch('unsubscribe')
                  commit('setEvent', null)
                  commit('setCluster', null)
                  commit('setLiveTrafficSegments', null)
                } else {
                  const result = camelResponse(JSON.parse(message.obj))
                  const schema = computeSchema(eventFields, result.mappings)
                  const newEvent = denormalize(
                    message.id,
                    (schema?.items || [])[0],
                    result.entities,
                  )
                  commit('setEvent', newEvent)
                  if (state.event?.id != newEvent.id) {
                    commit('setLiveTrafficSegments', null)
                  }
                  if (state.cluster?.slug) {
                    const newCluster = newEvent.clusters.find(c => c.slug == state.cluster.slug)
                    commit('setCluster', newCluster)
                  }
                }
              },
            },
          )
          commit('setEventCableSubscription', eventCableSubscription)
          resolve(true)
        })
      })
    },
    fetchEvent(
      { state, commit, dispatch, rootGetters },
      { slug, forceRefetch, withoutSubscription },
    ) {
      return new Promise(resolve => {
        if (!slug || !slug.length || slug == 'undefined') {
          dispatch('unsubscribe')
          commit('setEvent', null)
          commit('setCluster', null)
          commit('setLiveTrafficSegments', null)
          resolve(null)
        } else if (!forceRefetch && state.event?.slug == slug) {
          resolve(state.event)
        } else if (!state.eventIsBeingFetched) {
          commit('setEventIsBeingFetched', true)
          this.axios({
            method: 'post',
            url: `events/${slug}/show`,
            data: {
              fields: eventFields,
            },
          })
            .then(response => {
              if (!response.data) {
                dispatch('unsubscribe')
                commit('setEvent', null)
                commit('setCluster', null)
                commit('setEventIsBeingFetched', false)
                commit('setLiveTrafficSegments', null)
              } else {
                if (state.event?.id != response.data.id) {
                  commit('setLiveTrafficSegments', null)
                }
                commit('setEvent', response.data)
                if (state.cluster?.slug) {
                  const newCluster = response.data.clusters.find(c => c.slug == state.cluster.slug)
                  commit('setCluster', newCluster)
                }
                if (
                  response.data.defaultTimeZone?.length &&
                  (!response.data.availableTimeZones ||
                    !response.data.availableTimeZones.includes(rootGetters['session/timeZone']))
                ) {
                  console.log(
                    `%c set default tz ${response.data.defaultTimeZone} because currently set TZ DOES NOT meet event available TZs`,
                    'color: #48b6a3;',
                  )
                  commit('session/setTimeZone', response.data.defaultTimeZone, { root: true })
                } else if (response.data.defaultTimeZone?.length) {
                  console.log(
                    `%c DONT NEED TO set default tz ${response.data.defaultTimeZone} because currently set TZ meets event available TZs`,
                    'color: #48b6a3;',
                  )
                } else {
                  console.log(
                    "%c WON'T set default TZ because event has no locations/clusters configured yet",
                    'color: orange;',
                  )
                }
                // dispatch('notSentCCMCounter/fetch', { slug: response.data?.slug }, { root: true })
                commit('setEventIsBeingFetched', false)
                if (!withoutSubscription) {
                  dispatch('subscribe', response.data.id).then(() => {
                    resolve(response.data)
                  })
                }
              }
            })
            .catch(error => {
              console.error("Can't fetch the current event", error)
              commit('setEvent', null)
              commit('setEventIsBeingFetched', false)
              commit('setLiveTrafficSegments', null)
              resolve(null)
            })
        } else {
          resolve({ eventIsBeingFetched: true })
        }
      })
    },
    fetchCluster({ state, commit }, { slug, forceRefetch }) {
      return new Promise(resolve => {
        if (!forceRefetch && (!slug || !slug.length)) {
          commit('setCluster', null)
          resolve(null)
        } else if (!forceRefetch && state.cluster?.slug == slug) {
          resolve(state.cluster)
        } else if (!state.eventIsBeingFetched) {
          const cluster = state.event.clusters.find(c => c.slug == slug)
          commit('setCluster', cluster)
          resolve(cluster)
        } else {
          resolve({ eventIsBeingFetched: true })
        }
      })
    },
  },
}

export default currentContextStoreModule
