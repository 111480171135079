<script>
import { useVuelidate } from '@vuelidate/core'
import { convertValidatorsToVuelidate } from 'frontend/_validation/rails-validators-helper.js'
import { inject } from 'vue'

import ErrorsControl from './_errors-control.vue'
import FormGroup from './_form-group.vue'
import FormLabel from './_form-label.vue'
import InputGroup from './_input-group.vue'
import LengthHelpersControl from './_length-helpers-control.vue'

export default {
  name: 'FormControlContainer',
  props: {
    errorsTeleport: { type: String, default: null },
    label: { type: String, default: null },
    value: { type: [Array, Boolean, File, Number, Object, String] },
    hint: { type: String, default: null },
    name: { type: String, default: '' },
    inputAppend: { type: String, default: null },
    inputPrepend: { type: String, default: null },
    inputSize: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['sm', 'lg'].includes(val)
      },
    },
    skipDirtyCheck: { type: Boolean, default: false },
    hideInvalidIcon: { type: Boolean, default: false },
    hideErrors: { type: Boolean, default: false },
    autodirty: { type: Boolean, default: true },
    validators: { type: Object, default: null },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
    useContext: { type: Boolean, default: false },
  },
  setup(props) {
    let fromState
    if (props.useContext) {
      fromState = inject('formState')
    }

    return { v$: useVuelidate(), fromState }
  },
  validations() {
    return this.computedValidation
  },
  computed: {
    computedValidation() {
      if (!this.validators) {
        return {}
      }
      if (this.autodirty) {
        return {
          value: Object.assign({ $autoDirty: true }, convertValidatorsToVuelidate(this.validators)),
        }
      } else {
        return { value: convertValidatorsToVuelidate(this.validators) }
      }
    },
    required() {
      return !!this.computedValidation?.value?.required
    },
    invalid() {
      return !!this.v$.$errors?.length || !!this.additionalErrors?.length
    },
  },
  components: {
    FormGroup,
    InputGroup,
    FormLabel,
    ErrorsControl,
    LengthHelpersControl,
  },
}
</script>

<template lang="pug">
form-group(
  :dirty="!skipDirtyCheck && v$.$dirty"
  :hideInvalidIcon="hideInvalidIcon"
  :invalid="invalid"
  :name="'formGroup.' + name"
)
  form-label(:label="label" :required="required")
    template(v-if="Object.keys($slots).includes('label-addon')" #label-addon)
      slot(name="label-addon")

  input-group(
    :hint="hint"
    :input-append="inputAppend"
    :input-prepend="inputPrepend"
    :input-size="inputSize"
  )
    template(#default)
      slot(name="default")
        div THERE IS A SLOT FOR AN ITEM, PLEASE CONSUME IT!

    template(v-if="Object.keys($slots).includes('input-prepend')" #input-prepend)
      slot(name="input-prepend")

    template(v-if="Object.keys($slots).includes('input-append')" #input-append)
      slot(name="input-append")

  .d-flex.align-items-start.justify-content-between
    div
      template(v-if="!errorsTeleport?.length")
        errors-control(
          v-if="!hideErrors"
          :additional-errors="additionalErrors"
          :errors="v$.$errors"
        )
      template(v-else)
        teleport(:to="errorsTeleport")
          errors-control(
            v-if="!hideErrors"
            :additional-errors="additionalErrors"
            :errors="v$.$errors"
          )

    length-helpers-control
      slot(name="length-helpers")
</template>
