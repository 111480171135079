import { Container } from 'inversify'

import { DataModelTypeService } from '../../../dataModels/services/DataModelTypeService/DataModelTypeService'
import { IDataModelTypeService } from '../../../dataModels/services/DataModelTypeService/types/IDataModelTypeService'
import { InterfaceType } from '../constants/InterfaceType'

export function bindServices(container: Container): void {
  container
    .bind<IDataModelTypeService>(InterfaceType.services.DataModelTypeService)
    .to(DataModelTypeService)
}
