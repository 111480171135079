<script>
import ValuesPerVariable from './_values-per-variable.vue'

export default {
  name: 'EventOrganizationUserVariableValues',
  props: {
    variables: { type: Array, default: () => [] },
    values: { type: Object, default: () => ({}) },
    usedValuePresets: { type: Array, default: () => [] },
  },
  components: {
    ValuesPerVariable,
  },
}
</script>

<template lang="pug">
//- span TODO: {{ variables }} : {{ values }}
template(v-for="variable in variables")
  values-per-variable(
    :usedValuePresets="usedValuePresets"
    :value-ids="values?.[variable.id] || null"
    :variable="variable"
  )
</template>
