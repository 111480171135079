import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

import { IEventDashboardSection } from '../types/IEventDashboardSection'

export function useGlobalResourcesSection(eou) {
  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }

  const section = computed(() => {
    const result = {
      name: 'Global Resources',
      // isHidden: !isAdmin(eou.value),
      items: [
        {
          title: 'Client Functions',
          isDisabled: false,
          isModal: true,
          modalComponent: 'client-functions-modal',
          icon: 'bi-person',
        },
        {
          title: 'Client Function Remarks',
          isDisabled: false,
          isModal: true,
          modalComponent: 'client-function-remarks-modal',
          icon: 'bi-person',
        },
        {
          title: 'Day Labels',
          isDisabled: false,
          isModal: true,
          modalComponent: 'day-labels-modal',
          icon: 'bi-bookmark-star',
        },
        {
          title: 'Driver Categories',
          isDisabled: false,
          isModal: true,
          modalComponent: 'driver-categories-modal',
          icon: 'bi-person-badge',
        },
        {
          title: 'Location Types',
          isDisabled: false,
          isModal: true,
          modalComponent: 'location-types-modal',
          icon: 'bi-grid',
        },
        {
          title: 'Vehicle Brands',
          isDisabled: false,
          isModal: true,
          modalComponent: 'vehicle-brands-modal',
          icon: 'bi-truck',
        },
        {
          title: 'Vehicle Models',
          isDisabled: false,
          isModal: true,
          modalComponent: 'vehicle-models-modal',
          icon: 'bi-truck',
        },
        {
          title: 'Vehicle Categories',
          isDisabled: false,
          isModal: true,
          modalComponent: 'vehicle-categories-modal',
          icon: 'bi-truck',
        },
        {
          title: 'Vehicle Types',
          isDisabled: false,
          isModal: true,
          modalComponent: 'vehicle-types-modal',
          icon: 'bi-truck',
        },
        {
          title: 'Roles',
          isDisabled: false,
          path: 'roles',
          icon: 'bi-incognito',
        },
      ],
    } as IEventDashboardSection

    result.items = result.items.filter(el =>
      hasAccessTo(el.path || el.modalComponent.replace('-modal', '')),
    )
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
