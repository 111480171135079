import { ClientCategory } from 'frontend/client-categories'
import { ClientFunction } from 'frontend/client-functions'
import { ClientGroup } from 'frontend/client-groups'
import { Client } from 'frontend/clients'
import { Cluster } from 'frontend/clusters'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { DriverCategory } from 'frontend/driver-categories'
import { DriverSource } from 'frontend/driver-sources'
import { Driver } from 'frontend/drivers'
import { Location } from 'frontend/locations'
import { currentState, KINDS } from 'frontend/shifts'
import CheckInOut from 'frontend/shifts/shift-check-in-out.vue'
import InclusiveCheckInOut from 'frontend/shifts/shift-inclusive-check-in-out.vue'
import { StagingArea } from 'frontend/staging-areas'
import { VehicleCategory } from 'frontend/vehicle-categories'
import { VehicleModel } from 'frontend/vehicle-models'
import { VehicleSource } from 'frontend/vehicle-sources'
import { VehicleType } from 'frontend/vehicle-types'
import { Vehicle } from 'frontend/vehicles'
import { camelCase } from 'lodash'
import { markRaw } from 'vue'

export const ShiftDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'kind',
    'computed_seat_capacity',
    'aasm_state',
    'acceptance_aasm_state',
    'start_at',
    'end_at',
    'updated_at',
    'dedicated_client_groups',
    'check_in_at',
    'check_out_at',
    'overridden_check_in_time',
    'overridden_check_out_time',
    'overridden_inclusive_check_in_out',
    'computed_check_in_time',
    'computed_check_out_time',
    'computed_inclusive_check_in_out',
    'acceptance_accepted_at',
    'acceptance_declined_at',
    'acceptance_not_required_at',
    'acceptance_seen_at',
    'acceptance_sent_at',
    'checked_out_at',
    'checking_in_at',
    'ended_at',
    'first_ended_at',
    'first_started_at',
    'planned_at',
    'ready_at',
    'started_at',
    { cluster: ['id', 'name', 'time_zone'] },
    {
      client: [
        'id',
        'name',
        'surname',
        'archived_at',
        'email',
        'phone',
        'personal_assistant_email',
        'event_phone',
        { client_categories: ['id', 'name', 'group_path'] },
        { client_functions: ['id', 'name'] },
        {
          client_groups: [
            'id',
            'background_color',
            'border_color',
            'description',
            'font_color',
            'name',
          ],
        },
      ],
    },
    {
      driver: [
        'id',
        'name',
        'surname',
        'assigned_phone',
        'personal_phone',
        'primary_phone',
        { cluster: ['id', 'name', 'time_zone'] },
        { driver_category: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
        { driver_source: ['id', 'name'] },
      ],
    },
    {
      vehicle: [
        'id',
        'fleet_number',
        'registration_plate',
        { cluster: ['id', 'name', 'time_zone'] },
        { vehicle_model: ['id', 'name', { vehicle_brand: ['id', 'name'] }] },
        { vehicle_type: ['id', 'name'] },
        {
          vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
        },
        { vehicle_source: ['id', 'name', 'billable', 'maintainable'] },
      ],
    },
    {
      staging_area: [
        'id',
        'has_minimum_vehicles_allotment',
        'has_subfleet',
        'has_welcome_desk',
        { location: ['id', 'name', 'time_zone'] },
      ],
    },
  ],
  subscriptions: {
    items: 'ShiftsChannel',
    clusters: 'ClustersSimpleChannel',
    drivers: 'DriversSimpleChannel',
    vehicles: 'VehiclesWithSourceDetailedChannel',
    clients: 'ClientsForShiftsChannel',
    staging_areas: 'StagingAreasSimpleChannel',
    driverSources: 'DriverSourcesSimpleChannel',
    vehicleSources: 'VehicleSourcesSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'kind',
    'checkInAtTime',
    'startAt',
    'endAt',
    'checkOutAtTime',
    'cluster',
    'driver',
    'vehicle',
    'location',
    'dedicatedClient',
    'dedicatedClientGroups',
    'seatCapacity',
    'computedCheckInTime',
    'computedCheckOutTime',
    'computedInclusiveCheckInOut',
    'updatedAt',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    kind: {
      label: 'Kind',
      header: {
        sort: 'kind',
      },
      cell: {
        kind: 'function',
        fn: s => KINDS[s.kind],
      },
    },
    seatCapacity: {
      label: 'Seat capacity',
      header: {
        sort: 'computedSeatCapacity',
      },
      cell: {
        kind: 'simple',
        path: 'computedSeatCapacity',
      },
    },
    currentState: {
      label: 'Current State',
      header: {
        sort: 'currentState',
      },
      cell: {
        kind: 'function',
        fn: currentState,
      },
    },

    driver: {
      label: 'Driver',
      header: {
        sort: 'driverReverseFullName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Driver),
        componentProps: item => ({
          driver: item.driver,
          detailed: true,
        }),
      },
    },

    driverName: {
      label: 'Driver name',
      header: {
        sort: 'driverName',
      },
      cell: {
        kind: 'simple',
        path: 'driver.name',
      },
    },

    driverSurname: {
      label: 'Driver surname',
      header: {
        sort: 'driverSurname',
      },
      cell: {
        kind: 'simple',
        path: 'driver.surname',
      },
    },

    driverPhoneNumber: {
      label: 'Driver phone number',
      header: {
        sort: 'driverMainPhone',
      },
      cell: {
        kind: 'component',
        component: 'phone-number',
        componentProps: item => ({
          phoneNumber: item.driver?.[camelCase(item.driver?.primaryPhone || '')],
        }),
      },
    },

    driverCategory: {
      label: 'Driver category',
      header: {
        sort: ['driverDriverCategoryName'],
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverCategory),
        componentProps: item => ({
          driverCategory: item.driver?.driverCategory,
        }),
      },
    },

    driverSource: {
      label: 'Driver source',
      header: {
        sort: ['driverDriverSourceName'],
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverSource),
        componentProps: item => ({
          driverSource: item.driver?.driverSource,
        }),
      },
    },

    vehicle: {
      label: 'Vehicle',
      header: {
        sort: ['vehicleFleetNumberPrefix', 'vehicleFleetNumberInt'],
      },
      cell: {
        kind: 'component',
        component: markRaw(Vehicle),
        componentProps: item => ({
          vehicle: item.vehicle,
          detailed: true,
        }),
      },
    },

    vehicleFleetNumber: {
      label: 'Vehicle fleet number',
      header: {
        sort: ['vehicleFleetNumberPrefix', 'vehicleFleetNumberInt'],
      },
      cell: {
        kind: 'simple',
        path: 'vehicle.fleetNumber',
      },
    },

    vehicleRegistrationPlate: {
      label: 'Vehicle reg. plate',
      header: {
        sort: 'vehicleRegistrationPlate',
      },
      cell: {
        kind: 'simple',
        path: 'vehicle.registrationPlate',
      },
    },

    vehicleCluster: {
      label: 'Vehicle cluster',
      header: {
        sort: 'vehicleVehicleClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({
          cluster: item.vehicle?.cluster,
        }),
      },
    },

    vehicleCategory: {
      label: 'Vehicle category',
      header: {
        sort: 'vehicleVehicleCategoryName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleCategory),
        componentProps: item => ({
          vehicleCategory: item.vehicle?.vehicleCategory,
        }),
      },
    },

    vehicleType: {
      label: 'Vehicle type',
      header: {
        sort: 'vehicleVehicleTypeName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleType),
        componentProps: item => ({
          vehicleType: item.vehicle?.vehicleType,
        }),
      },
    },

    vehicleModel: {
      label: 'Vehicle model',
      header: {
        sort: 'vehicleVehicleModelName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleModel),
        componentProps: item => ({
          vehicleModel: item.vehicle?.vehicleModel,
        }),
      },
    },

    vehicleSource: {
      label: 'Vehicle source',
      header: {
        sort: ['vehicleVehicleSourceName'],
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleSource),
        componentProps: item => ({
          vehicleSource: item.vehicle?.vehicleSource,
        }),
      },
    },

    dedicatedClient: {
      label: 'Allocated client',
      header: {
        sort: 'clientFullName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Client),
        componentProps: item => ({
          client: item.client,
          nowrap: true,
        }),
      },
    },

    dedicatedClientName: {
      label: 'Allocated client name',
      header: {
        sort: 'clientName',
      },
      cell: {
        kind: 'simple',
        path: 'client.name',
      },
    },

    clientSurname: {
      label: 'Allocated client surname',
      header: {
        sort: 'clientSurname',
      },
      cell: {
        kind: 'simple',
        path: 'client.surname',
      },
    },

    dedicatedClientGroup: {
      label: "Allocated client's client groups",
      header: {
        // sort: 'clientFullName',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: item => ({
          items: item.client?.clientGroups,
          limit: 3,
          component: ClientGroup,
          componentProps: cg => ({ clientGroup: cg }),
        }),
      },
    },

    dedicatedClientCategories: {
      label: "Allocated client's Group paths",
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: item => ({
          items: item.client?.clientCategories,
          limit: 3,
          component: ClientCategory,
          componentProps: cc => ({ clientCategory: cc }),
        }),
      },
    },
    dedicatedClientFunctions: {
      label: "Allocated client's Functions",
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: item => ({
          items: item.client?.clientFunctions,
          limit: 3,
          component: ClientFunction,
          componentProps: cf => ({ clientFunction: cf }),
        }),
      },
    },

    dedicatedClientGroups: {
      label: 'Allocated client group',
      header: {
        sort: 'dedicatedClientGroupsNameForSort',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: item => ({
          items: item.dedicatedClientGroups,
          limit: 5,
          component: markRaw(ClientGroup),
          componentProps: c => ({ clientGroup: c }),
        }),
      },
    },

    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({
          cluster: item.cluster,
        }),
      },
    },

    location: {
      label: 'Location',
      header: {
        sort: 'locationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: item => ({
          location: item.stagingArea?.location,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },

    stagingArea: {
      label: 'Staging Area',
      header: {},
      cell: {
        kind: 'component',
        component: markRaw(StagingArea),
        componentProps: item => ({
          stagingArea: item.stagingArea,
          hideYes: true,
        }),
      },
    },

    checkInAt: {
      label: 'Check-in datetime',
      header: {
        sort: 'checkInAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkInAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkInAtTime: {
      label: 'Check-in time',
      header: {
        sort: 'checkInAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkInAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    checkInAtDate: {
      label: 'Check-in date',
      header: {
        sort: 'checkInAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkInAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    startAt: {
      label: 'Start datetime',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    startAtTime: {
      label: 'Start time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    startAtDate: {
      label: 'Start date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    endAt: {
      label: 'End datetime',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    endAtTime: {
      label: 'End time',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    endAtDate: {
      label: 'End date',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    checkOutAt: {
      label: 'Check-out datetime',
      header: {
        sort: 'checkOutAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkOutAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkOutAtTime: {
      label: 'Check-out time',
      header: {
        sort: 'checkOutAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkOutAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    checkOutAtDate: {
      label: 'Check-out date',
      header: {
        sort: 'checkOutAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkOutAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    computedCheckInTime: {
      label: 'Check-in minutes',
      header: {
        sort: 'computedCheckInTime',
      },
      cell: {
        kind: 'component',
        component: markRaw(CheckInOut),
        componentProps: shift => ({ shift, attrSuffix: 'CheckInTime' }),
      },
    },
    computedCheckOutTime: {
      label: 'Check-out minutes',
      header: {
        sort: 'computedCheckOutTime',
      },
      cell: {
        kind: 'component',
        component: markRaw(CheckInOut),
        componentProps: shift => ({ shift, attrSuffix: 'CheckOutTime' }),
      },
    },
    computedInclusiveCheckInOut: {
      label: 'CI/CO included',
      tooltip: 'check-in/check-out times are included in the length',
      header: {
        sort: 'computedInclusiveCheckInOut',
      },
      cell: {
        kind: 'component',
        component: markRaw(InclusiveCheckInOut),
        componentProps: shift => ({ shift }),
      },
    },

    updatedAt: {
      label: 'Updated datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },

    updatedAtTime: {
      label: 'Updated time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },

    updatedAtDate: {
      label: 'Updated date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },

    acceptanceAcceptedAt: {
      label: 'Driver accepted datetime',
      header: {
        sort: 'acceptanceAcceptedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceAcceptedAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceAcceptedDate: {
      label: 'Driver accepted date',
      header: {
        sort: 'acceptanceAcceptedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceAcceptedAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceAcceptedTime: {
      label: 'Driver accepted time',
      header: {
        sort: 'acceptanceAcceptedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceAcceptedAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceDeclinedAt: {
      label: 'Driver declined datetime',
      header: {
        sort: 'acceptanceDeclinedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceDeclinedAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceDeclinedDate: {
      label: 'Driver declined date',
      header: {
        sort: 'acceptanceDeclinedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceDeclinedAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceDeclinedTime: {
      label: 'Driver declined time',
      header: {
        sort: 'acceptanceDeclinedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceDeclinedAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceNotRequiredAt: {
      label: 'Acceptance process skipped datetime',
      header: {
        sort: 'acceptanceNotRequiredAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceNotRequiredAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceNotRequiredDate: {
      label: 'Acceptance process skipped date',
      header: {
        sort: 'acceptanceNotRequiredAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceNotRequiredAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceNotRequiredTime: {
      label: 'Acceptance process skipped time',
      header: {
        sort: 'acceptanceNotRequiredAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceNotRequiredAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceSeenAt: {
      label: 'Driver seen acceptance datetime',
      header: {
        sort: 'acceptanceSeenAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceSeenAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceSeenDate: {
      label: 'Driver seen acceptance date',
      header: {
        sort: 'acceptanceSeenAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceSeenAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceSeenTime: {
      label: 'Driver seen acceptance time',
      header: {
        sort: 'acceptanceSeenAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceSeenAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceSentAt: {
      label: 'Acceptance request sent datetime',
      header: {
        sort: 'acceptanceSentAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceSentAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceSentDate: {
      label: 'Acceptance request sent date',
      header: {
        sort: 'acceptanceSentAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceSentAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    acceptanceSentTime: {
      label: 'Acceptance request sent time',
      header: {
        sort: 'acceptanceSentAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.acceptanceSentAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkedOutAt: {
      label: 'Checked out datetime',
      header: {
        sort: 'checkedOutAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkedOutAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkedOutDate: {
      label: 'Checked out date',
      header: {
        sort: 'checkedOutAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkedOutAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkedOutTime: {
      label: 'Checked out time',
      header: {
        sort: 'checkedOutAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkedOutAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkingInAt: {
      label: 'Checking in started datetime',
      header: {
        sort: 'checkingInAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkingInAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkingInDate: {
      label: 'Checking in started date',
      header: {
        sort: 'checkingInAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkingInAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    checkingInTime: {
      label: 'Checking in started time',
      header: {
        sort: 'checkingInAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.checkingInAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    endedAt: {
      label: 'Ended datetime',
      header: {
        sort: 'endedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endedAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    endedDate: {
      label: 'Ended date',
      header: {
        sort: 'endedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endedAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    endedTime: {
      label: 'Ended time',
      header: {
        sort: 'endedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endedAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    firstEndedAt: {
      label: 'First state change to ended datetime',
      header: {
        sort: 'firstEndedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.firstEndedAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    firstEndedDate: {
      label: 'First state change to ended date',
      header: {
        sort: 'firstEndedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.firstEndedAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    firstEndedTime: {
      label: 'First state change to ended time',
      header: {
        sort: 'firstEndedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.firstEndedAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    firstStartedAt: {
      label: 'First state change to started datetime',
      header: {
        sort: 'firstStartedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.firstStartedAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    firstStartedDate: {
      label: 'First state change to started date',
      header: {
        sort: 'firstStartedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.firstStartedAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    firstStartedTime: {
      label: 'First state change to started time',
      header: {
        sort: 'firstStartedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.firstStartedAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    plannedAt: {
      label: 'Planed datetime',
      header: {
        sort: 'plannedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.plannedAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    plannedDate: {
      label: 'Planed date',
      header: {
        sort: 'plannedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.plannedAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    plannedTime: {
      label: 'Planed time',
      header: {
        sort: 'plannedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.plannedAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    readyAt: {
      label: 'Ready datetime',
      header: {
        sort: 'readyAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.readyAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    readyDate: {
      label: 'Ready date',
      header: {
        sort: 'readyAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.readyAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    readyTime: {
      label: 'Ready time',
      header: {
        sort: 'readyAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.readyAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    startedAt: {
      label: 'Started datetime',
      header: {
        sort: 'startedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startedAt,
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    startedDate: {
      label: 'Started date',
      header: {
        sort: 'startedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startedAt,
          format: 'dateOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },

    startedTime: {
      label: 'Started time',
      header: {
        sort: 'startedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startedAt,
          format: 'timeOnly',
          tz: item.stagingArea?.location?.timeZone,
        }),
      },
    },
  },
}
