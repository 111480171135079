<script>
import { Toast } from 'bootstrap'

export default {
  name: 'SingleToast',
  emits: ['destroy'],
  props: {
    title: String,
    message: String,
    type: String,
    errors: Object,
    stay: Number,
    showSpinner: { type: Boolean, default: false },
    autohide: { type: Boolean, default: true },
    id: String,
  },
  computed: {
    classType() {
      return this.type === 'error' ? 'danger' : this.type
    },
    classes() {
      return [`border-${this.classType}`]
    },
    progressBarClasses() {
      return [`bg-${this.classType}`]
    },
  },
  mounted() {
    const toast = new Toast(this.$el, { delay: this.stay, autohide: this.autohide })
    toast.show()
    this.$el.addEventListener('hidden.bs.toast', () => {
      this.$emit('destroy', this.id)
    })
  },
}
</script>

<template lang="pug">
.toast(:class="classes" aria-atomic="true" aria-live="assertive" role="alert")
  .toast-header
    .me-auto(:class="{ bold: title?.length }" v-html="title?.length ? title : message")
    button.btn-close(aria-label="Close" data-bs-dismiss="toast" type="button")
  .toast-body(v-if="title?.length" v-html="message")
  .progress-bar.progress-bar-striped.progress-bar-animated(
    v-if="showSpinner"
    :classes="progressBarClasses"
  )
</template>

<style scoped lang="scss">
.toast {
  border-radius: 0;
  border-width: 2px;
  border-left-width: 0.5rem;
}
.progress-bar {
  height: 5px;
}
</style>
