<script>
import { camelCase } from 'lodash'

export default {
  name: 'ModalButton',
  inheritAttrs: false,
  emits: ['modalClosed', 'modalOpened'],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    btnName: {
      type: String,
    },
    btnSize: {
      type: String,
      default: 'sm',
      validator(val) {
        return ['xs', 'sm', 'md', 'lg'].includes(val)
      },
    },
    table: {
      type: Boolean,
      default: false,
    },
    skipDescriptorPrefix: {
      type: Boolean,
      default: false,
    },
    modal: { type: Object, required: true },
    modalSize: {
      type: String,
      default: 'lg',
      validator(val) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].includes(val)
      },
    },
    external: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      modalShown: false,
    }
  },
  computed: {
    modalAttrs() {
      const result = {}
      Object.keys(this.$attrs)
        .filter(k => k.toString().startsWith('modal') && k.toString().length > 5)
        .forEach(k => {
          let newKey = camelCase(k.replace('modal', ''))
          if (newKey.startsWith('-')) {
            newKey = newKey.substring(1)
          }
          result[newKey] = this.$attrs[k]
        })
      return result
    },
    notModalAttrs() {
      const result = {}
      Object.keys(this.$attrs)
        .filter(k => !(k.toString().startsWith('modal') && k.toString().length > 5))
        .forEach(k => {
          result[k] = this.$attrs[k]
        })
      return result
    },
    buttonDescriptor() {
      if (!this.item?.id) return `${this.skipDescriptorPrefix ? '' : 'Add '}${this.name}`
      else return `${this.skipDescriptorPrefix ? '' : 'Edit '}${this.name}`
    },
  },

  methods: {
    showModal(event) {
      if (!this.disabled) {
        if (event) {
          event.stopPropagation()
          event.preventDefault()
        }
        if (!this.external) {
          this.openModal()
          this.$emit('modalOpened')
        }
      }
    },
    openModal() {
      this.modalShown = true
    },
    modalClosed(event) {
      this.modalShown = false
      this.$emit('modalClosed', event)
    },
  },
}
</script>

<template lang="pug">
.modal-button(
  v-if="!external"
  :class="{ 'd-inline-flex': !(external || fullWidth), 'd-grid gap-2': fullWidth }"
  @click="showModal"
)
  slot(v-bind:item="item" v-bind:open="openModal")
    template(v-if="table")
      data-table-edit-button(v-bind="notModalAttrs" :name="btnName" :tooltip="buttonDescriptor")
    template(v-else)
      add-button(v-if="!item?.id" v-bind="notModalAttrs" :name="btnName" :size="btnSize") {{ buttonDescriptor }}
      edit-button(v-else v-bind="notModalAttrs" :name="btnName") {{ buttonDescriptor }}
slot(v-else v-bind:item="item" v-bind:open="openModal")
  template(v-if="table")
    data-table-edit-button(v-bind="notModalAttrs" :name="btnName" :tooltip="buttonDescriptor")
  template(v-else)
    add-button(v-if="!item?.id" v-bind="notModalAttrs" :name="btnName" :size="btnSize") {{ buttonDescriptor }}
    edit-button(v-else v-bind="notModalAttrs" :name="btnName") {{ buttonDescriptor }}
template(v-if="!disabled && modalShown")
  component(
    v-bind="modalAttrs"
    :is="modal"
    :item="item"
    :size="modalSize"
    @closed="modalClosed($event)"
  )
</template>
