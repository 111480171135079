export enum DataModelActionType {
  Create = 'create',
  Read = 'read',
  ReadInput = 'read_input',
  Update = 'update',
  Delete = 'delete',
  ReplaceDriver = 'replace_driver',
  WorkingHoursReport = 'working_hours_report',
  Reify = 'reify',
  AssignDriver = 'assign_driver',
  AssignVehicle = 'assign_vehicle',
  EmergencyReplaceDriver = 'emergency_replace_driver',
  ChangeAcceptanceStates = 'change_acceptance_states',
  ChangeExecutionStatus = 'change_execution_status',
  AssignToShift = 'assign_to_shift',
  ManageAutoRecomputations = 'manage_auto_recomputations',
  ManageStatus = 'manage_status',
  ToggleEmailCommunicationBlacklist = 'toggle_email_communication_blacklist',
  ToggleSmsCommunicationBlacklist = 'toggle_sms_communication_blacklist',
  ManageArchives = 'manage_archives',
  Discard = 'discard',
  Deliver = 'deliver',
  ResolveTime = 'resolve_time',
  ResolveTransportationPoints = 'resolve_transportation_points',
  UpdateTptComment = 'update_tpt_comment',
  ResolveOsl = 'resolve_osl',
  ProvideOsl = 'provide_osl',
  SyncLocations = 'sync_locations',
  SyncClientCategories = 'sync_client_categories',
  SyncClients = 'sync_clients',
  SyncTransportBookings = 'sync_transport_bookings',
  SyncShuttleTransfers = 'sync_shuttle_transfers',
  SyncDrivers = 'sync_drivers',
  UpdateDefaultDurationRule = 'update_default_duration_rule',
  UpdateAutoTimeRecalculationConfig = 'update_auto_time_recalculation_config',
  OptOutSmsConfig = 'opt_out_sms_config',
  RecalculateTransferGroup = 'recalculate_transfer_group',
  OverrideLeadLoadTime = 'override_lead_load_time',
  ChangeTransportationPoints = 'change_transportation_points',
  CreateCustom = 'create_custom',
  AssignRole = 'assign_role',
  Approve = 'approve',
  AppLoginPossibilityManagement = 'app_login_possibility_management',
}
