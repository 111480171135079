<template lang="pug">
div(v-if="currentUser")
</template>

<script>
import { mapGetters } from 'vuex'

import { organizationModuleGuard } from '../../common/module-access-guard'

export default {
  name: 'RootComponent',
  computed: {
    ...mapGetters('session', ['currentUser']),
    properOrganizationUser() {
      return (
        this.currentUser?.impersonatedCurrentOrganizationUser ||
        this.currentUser?.currentOrganizationUser
      )
    },
    moduleGuard() {
      return organizationModuleGuard(null, this.properOrganizationUser)
    },
  },
  watch: {
    currentUser: {
      handler(user) {
        if (!user) {
          console.log(`%c no currentUser, redirection to /session/sign-in`, 'color: gray')
          this.$router.push('/session/sign-in')
        } else if (!user.currentOrganizationId) {
          console.log(
            `%c no currentOrganization, redirection to /session/choose-context`,
            'color: gray',
          )
          this.$router.push('/session/choose-context')
        } else if (this.moduleGuard?.hasOnlyPassengerAccess) {
          console.log(
            `%c only passenger access, redirection to /passenger-portal/events`,
            'color: gray',
          )
          this.$router.push('/passenger-portal/events')
        } else if (this.moduleGuard?.hasOnlyOperationalAccess) {
          console.log(`%c only operational access, redirection to /dashboard`, 'color: gray')
          this.$router.push('/events')
        } else {
          console.log(`%c multiple accesses, redirection to /tmm-hub`, 'color: gray')
          this.$router.push('/tmm-hub')
        }
      },
      immediate: true,
    },
  },
}
</script>
