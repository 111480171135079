import { partition } from 'lodash'

import BaseModel from '../common/base-model'
// import {
//   CRITERIA_CONFIG as activityCriteriaConfig,
//   CRITERIA_CONFIG_FLAT as activityCriteriaConfigFlat,
// } from './activity-criteria-config'
// import {
//   CRITERIA_CONFIG as arrivalAndDepartureCriteriaConfig,
//   CRITERIA_CONFIG_FLAT as arrivalAndDepartureCriteriaConfigFlat,
// } from './arrival-and-departure-criteria-config'
// import {
//   CRITERIA_CONFIG as onsiteCriteriaConfig,
//   CRITERIA_CONFIG_FLAT as onsiteCriteriaConfigFlat,
// } from './onsite-criteria-config'
// import {
//   CRITERIA_CONFIG as shuttleCriteriaConfig,
//   CRITERIA_CONFIG_FLAT as shuttleCriteriaConfigFlat,
// } from './shuttle-criteria-config'

export class TransportationPoint extends BaseModel {
  id
  onSpotTime
  activityLeadTime
  activityLoadTime
  address
  arrivalLeadTime
  arrivalLoadTime
  departureLeadTime
  departureLoadTime
  enabledForActivity = false
  enabledForArrival = false
  enabledForDeparture = false
  enabledForOnsite = false
  enabledForShuttle = false
  latitude
  longitude
  name
  onsiteLeadTime
  onsiteLoadTime
  shuttleLeadTime
  shuttleLoadTime
  locationId
  createdAt
  updatedAt
  arrivalCriteria
  departureCriteria
  onsiteCriteria
  activityCriteria
  shuttleCriteria

  static TB_KINDS_DEFINITIONS = Object.freeze({
    arrival: {
      title: 'Arrival',
      icon: 'fa-plane-arrival',
      enabled: 'enabledForArrival',
      leadTime: 'arrivalLeadTime',
      loadTime: 'arrivalLoadTime',
      criteria: 'arrivalCriteria',
      // criteriaConfig: arrivalAndDepartureCriteriaConfig,
      // criteriaConfigFlat: arrivalAndDepartureCriteriaConfigFlat,
    },
    departure: {
      title: 'Departure',
      icon: 'fa-plane-departure',
      enabled: 'enabledForDeparture',
      leadTime: 'departureLeadTime',
      loadTime: 'departureLoadTime',
      criteria: 'departureCriteria',
      // criteriaConfig: arrivalAndDepartureCriteriaConfig,
      // criteriaConfigFlat: arrivalAndDepartureCriteriaConfigFlat,
    },
    onsite: {
      title: 'On-site',
      icon: 'fa-car',
      enabled: 'enabledForOnsite',
      leadTime: 'onsiteLeadTime',
      loadTime: 'onsiteLoadTime',
      criteria: 'onsiteCriteria',
      // criteriaConfig: onsiteCriteriaConfig,
      // criteriaConfigFlat: onsiteCriteriaConfigFlat,
    },
    activity: {
      title: 'Activity',
      icon: 'fa-users',
      enabled: 'enabledForActivity',
      criteria: 'activityCriteria',
      // criteriaConfig: activityCriteriaConfig,
      // criteriaConfigFlat: activityCriteriaConfigFlat,
    },
    shuttle: {
      title: 'Shuttle',
      icon: 'fa-bus-alt',
      enabled: 'enabledForShuttle',
      criteria: 'shuttleCriteria',
      // criteriaConfig: shuttleCriteriaConfig,
      // criteriaConfigFlat: shuttleCriteriaConfigFlat,
    },
  })

  get KINDS() {
    return ['arrival', 'departure', 'onsite', 'shuttle', 'activity']
  }

  constructor(obj) {
    super()
    this.initialize(obj)
  }

  clearOslsByTbKind(kind) {
    const [byKind, other] = (this.transportationPointOsls = partition(
      this.transportationPointOsls,
      osl => osl.tbKind === kind,
    ))

    this.transportationPointOsls = [...other, ...byKind.map(osl => ({ ...osl, _destroy: true }))]
  }

  getOslsByKind(kind) {
    return this.transportationPointOsls.filter(osl => osl.tbKind === kind && !osl._destroy)
  }

  getOslsToRemove() {
    return this.transportationPointOsls.filter(osl => osl._destroy)
  }

  serialize() {
    const json = { ...this }

    Object.values(TransportationPoint.TB_KINDS_DEFINITIONS).forEach(value => {
      if (!this[value.enabled]) {
        json[value.leadTime] = null
        json[value.loadTime] = null
        if (value.criteria) {
          json[value.criteria] = {}
        }
      }
    })

    return json
  }
}
