<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import SearchCriterion from 'frontend/common/search-criterion/search-criterion.vue'
import ExpandableCategoryChooserComponent from 'frontend/filters/components/ExpandableCategoryChooserComponent.vue'
import { getPredicateFilterKey } from 'frontend/filters/functions/getPredicateFilterKey'
import { getTextPartsByMatches } from 'frontend/filters/functions/getTextPartsByMatches'
import { mapCriteriaToCategories } from 'frontend/filters/functions/mapCriteriaToCategories'
import { IMappedSearchCriteriaCategory } from 'frontend/filters/types/IMappedSearchCriteriaCategory'
import { ISearchCriteria } from 'frontend/filters/types/ISearchCriteria'
import EmptyListIndicatorComponent from 'frontend/roles/components/EmptyListIndicatorComponent.vue'
import EmptyRolesPlaceholderComponent from 'frontend/roles/components/EmptyRolesPlaceholderComponent.vue'
import InputWithIconComponent from 'frontend/roles/components/InputWithIconComponent.vue'
import InputComponent from 'frontend/ui/components/InputComponent.vue'
import Fuse from 'fuse.js'
import { computed, ref } from 'vue'

interface Props {
  searchCriteria: ISearchCriteria
  modelValue: unknown
  maxParentHeight?: number
}
const props = withDefaults(defineProps<Props>(), {
  maxParentHeight: null,
})

interface Emits {
  (e: 'update:modelValue', value: unknown): void
}
const emit = defineEmits<Emits>()

const currentCategoryId = ref<symbol>(null)
const expandedCategoryIds = ref<symbol[]>([])
const categorySearch = ref<string>('')

const categories = computed(() => {
  return mapCriteriaToCategories(props.searchCriteria, null)
})

const categoriesWithParentIdNames = computed(() => {
  return categories.value.map(category => {
    return {
      ...category,
      allParentIdNames: category.allParentIds
        .map(id => {
          return categories.value.find(singleCategory => singleCategory.id === id).label
        })
        .concat([category.label])
        .join(' '),
    }
  })
})

const fuse = computed(() => {
  return new Fuse(categoriesWithParentIdNames.value, {
    keys: [
      'label',
      {
        name: 'allParentIdNames',
        weight: 1.1,
      },
    ],
    threshold: 0.4,
    distance: 80,
    includeScore: true,
    includeMatches: true,
  })
})

const foundItems = computed(() => {
  return fuse.value.search(categorySearch.value)
})

const mappedCategories = computed<IMappedSearchCriteriaCategory[]>(() => {
  const foundItemIds = foundItems.value.map(item => item.item.id)
  const items = categories.value.map(category => {
    const anyParentHasBeenFound = category.allParentIds.some(id => foundItemIds.includes(id))
    const childrenIds = categories.value
      .filter(singleCategory => singleCategory.allParentIds.includes(category.id))
      .map(category => category.id)
    const anyChildHasBeenFound = childrenIds.some(id => foundItemIds.includes(id))
    const foundItem = foundItems.value.find(item => item.item.id === category.id)
    const matches = foundItem?.matches
    const score = foundItem?.score
    const activeFilterCount = Object.entries(props.modelValue).filter(
      ([activeFilterKey, value]) => {
        if (value === null) {
          return
        }
        if (!category.config.predicates) {
          return
        }
        const keysWithPredicates = category.config.predicates?.map(predicate => {
          return getPredicateFilterKey(category.key, predicate)
        })
        return keysWithPredicates.includes(activeFilterKey)
      },
    ).length
    return {
      ...category,
      isExpanded: expandedCategoryIds.value.includes(category.id) || anyChildHasBeenFound,
      textParts: getTextPartsByMatches(category.label, matches),
      anyParentHasBeenFound,
      childrenIds,
      anyChildHasBeenFound,
      matches,
      score,
      hasBeenFound: foundItemIds.includes(category.id),
      activeFilterCount,
    }
  })
  return items.map(item => {
    return {
      ...item,
      activeFilterCountIncludingChildren:
        item.childrenIds.reduce((acc, id) => {
          const childCategory = items.find(singleCategory => singleCategory.id === id)
          return acc + childCategory.activeFilterCount
        }, 0) + item.activeFilterCount,
      activeFilterCountIncludingChildren2: item.activeFilterCount,
    }
  })
})

const filteredMappedCategories = computed(() => {
  return mappedCategories.value
    .filter(mappedCategory => {
      return (
        !categorySearch.value || mappedCategory.hasBeenFound || mappedCategory.anyChildHasBeenFound
      )
    })
    .sort((a, b) => {
      return (a.score || 0) - (b.score || 0)
    })
})

watchDebounced(
  foundItems,
  () => {
    const highestLevelOfFilteredCategory = Math.max(
      ...filteredMappedCategories.value.map(category => category.level),
    )
    const filteredMappedCategoriesOnHighestLevel = filteredMappedCategories.value.filter(
      category => {
        return category.level === highestLevelOfFilteredCategory
      },
    )
    if (filteredMappedCategoriesOnHighestLevel.length === 1) {
      onCategoryClick(filteredMappedCategoriesOnHighestLevel[0].id, true)
    }
  },
  {
    immediate: true,
    debounce: 50,
  },
)

const activeCategory = computed(() => {
  return mappedCategories.value?.find(category => category.id === currentCategoryId.value)
})

const activeCategoryTree = computed(() => {
  return activeCategory.value?.allParentIds
    .map(id => {
      return mappedCategories.value.find(category => category.id === id)
    })
    .concat([activeCategory.value])
})

const model = computed({
  get() {
    return props.modelValue
  },
  set(value: unknown) {
    emit('update:modelValue', value)
  },
})

function onCategoryClick(categoryId: symbol, state?: boolean) {
  if (typeof state === 'undefined') {
    if (expandedCategoryIds.value.includes(categoryId)) {
      if (currentCategoryId.value === categoryId) {
        expandedCategoryIds.value = expandedCategoryIds.value.filter(id => id !== categoryId)
      }
    } else {
      expandedCategoryIds.value = expandedCategoryIds.value.filter(id => {
        const category = mappedCategories.value.find(category => category.id === id)
        if (!category) {
          return false
        }
        return (
          category.level < mappedCategories.value.find(category => category.id === categoryId).level
        )
      })
      expandedCategoryIds.value = [...expandedCategoryIds.value, categoryId]
    }
  }
  if (currentCategoryId.value === categoryId && state !== true) {
    const parentCategoryId = mappedCategories.value.find(
      category => category.id === categoryId,
    ).parentCategoryId
    currentCategoryId.value = parentCategoryId || null
  } else {
    currentCategoryId.value = categoryId
  }
}

const expandableCategoryChooserHeight = computed(() => {
  return Math.min(420, props.maxParentHeight - (36 + 8 + 32 + 28 + 8)) + 'px'
})
</script>

<template>
  <div class="criteria-builder">
    <div class="categories-wrapper">
      <InputWithIconComponent class="search-input-with-icon">
        <InputComponent
          v-model="categorySearch"
          autofocus
          placeholder="Search for criteria"
          input-class="arrows-navigable-item"
        />
      </InputWithIconComponent>
      <ExpandableCategoryChooserComponent
        :categories="filteredMappedCategories"
        :current-category-id="currentCategoryId"
        @category-click="onCategoryClick"
      />
    </div>
    <div class="criteria-wrapper">
      <div v-if="activeCategoryTree" class="active-criteria">
        <div class="category-tree">
          <template v-for="(category, i) in activeCategoryTree" :key="category.id">
            <span class="category-label" @click="onCategoryClick(category.id, true)">{{
              category.label
            }}</span>
            <span v-if="i !== activeCategoryTree.length - 1"> / </span>
          </template>
        </div>
        <div v-if="!activeCategory.config.type" class="empty-list-indicator-wrapper">
          <EmptyListIndicatorComponent>
            <template #before>
              <EmptyRolesPlaceholderComponent />
            </template>
            <template #title> Criteria empty </template>
            <template #description>
              This is an empty group, please choose child category to see criteria</template
            >
          </EmptyListIndicatorComponent>
        </div>
        <div v-else class="criteria-content">
          <SearchCriterion
            v-model="model"
            :attribute="activeCategory.key"
            :config="activeCategory.config"
            @update:model-value="$emit('update:modelValue', $event)"
          />
        </div>
      </div>
      <div v-else class="empty-list-indicator-wrapper">
        <EmptyListIndicatorComponent>
          <template #before>
            <EmptyRolesPlaceholderComponent />
          </template>
          <template #title> Choose criteria </template>
          <template #description>
            Click on any category on the left side to start editing specific criteria
          </template>
          <template #after>
            <slot name="empty-list-after" />
          </template>
        </EmptyListIndicatorComponent>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.criteria-builder {
  display: grid;
  grid-template-columns: 260px 1fr;
  width: 620px;
  gap: 24px;
}

.expandable-category-chooser {
  max-height: v-bind(expandableCategoryChooserHeight);
  min-height: 240px;
}

.search-input-with-icon {
  input {
    width: 100%;
  }
}

.categories-wrapper {
  display: grid;
  gap: 8px;
  user-select: none;
  grid-template-rows: auto 1fr;
  font-size: 14px;
}

.empty-list-indicator-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-tree {
  color: #4994ce;
  user-select: none;
  font-size: 16px;

  span:last-child {
    font-weight: 700;
    pointer-events: none;
  }
}

.category-label {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.active-criteria {
  display: grid;
  gap: 8px;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.criteria-content {
  &:deep(.form-control) {
    font-size: 0.85rem;
  }
  &:deep(.form-label) {
    font-size: 0.85rem;
  }
  &:deep(.form-group) {
    font-size: 0.85rem;
  }

  &:deep(.vs__selected) {
    margin: 0.1rem 0.1rem 0px 0;
  }
}
</style>
