<script>
export default {
  name: 'DriverPhones',
  props: {
    driver: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
div(v-if="driver.assignedPhone?.length")
  .d-flex
    phone-number(:phoneNumber="driver.assignedPhone")
    i.is-primary.text-info.ms-1(
      v-if="driver.personalPhone?.length"
      :class="{ 'bi-award': driver.primaryPhone == 'assigned_phone' }"
      v-tooltip="driver.primaryPhone == 'assigned_phone' ? 'Primary' : ''"
    )
div(v-if="driver.personalPhone?.length")
  .d-flex
    phone-number(:phoneNumber="driver.personalPhone")
    i.is-primary.text-info.ms-1(
      v-if="driver.assignedPhone?.length"
      :class="{ 'bi-award': driver.primaryPhone == 'personal_phone' }"
      v-tooltip="driver.primaryPhone == 'personal_phone' ? 'Primary' : ''"
    )
</template>
