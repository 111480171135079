<script>
import UsedFilter from './data-table-used-filter.vue'

export default {
  name: 'DataTableUsedFilters',
  emits: ['update:filters'],
  props: {
    filters: {
      type: Object,
    },
    filtersConfig: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activeFilters() {
      return Object.keys(this.filters ?? {}).filter(
        k => !!this.filters[k] || this.filters[k] == 0 || this.filters[k] == false,
      )
    },
  },
  methods: {
    removeFilter(f) {
      const newFilters = { ...this.filters }
      newFilters[f] = null
      // const newFilters = Object.assign({}, this.filters, this.localFilters)
      this.$emit('update:filters', newFilters)
    },
  },
  components: {
    UsedFilter,
  },
}
</script>

<template lang="pug">
.data-table-used-filters.flex-fill.align-items-center.justify-content-start.flex-wrap
  template(v-for="f in activeFilters")
    used-filter(
      :filterKey="f"
      :filterValue="filters[f]"
      :filtersConfig="filtersConfig"
      @remove-filter="removeFilter(f)"
    )
</template>
