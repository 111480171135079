<script>
import daysOfWeekOptions from 'frontend/_mixins/days-of-week-options'

export default {
  name: 'Weekdays',
  mixins: [daysOfWeekOptions],
  props: {
    daysOfWeek: { type: Array, required: true },
  },
  computed: {
    weekend() {
      return this.hasSameElements([0, 6], this.daysOfWeek)
    },
    everyday() {
      return this.hasSameElements([0, 1, 2, 3, 4, 5, 6], this.daysOfWeek)
    },
    workdays() {
      return this.hasSameElements([1, 2, 3, 4, 5], this.daysOfWeek)
    },
  },
  methods: {
    hasSameElements(a, b) {
      return a.length === b.length && new Set(a.concat(b)).size === a.length
    },
  },
}
</script>
d
<template lang="pug">
.weekdays.day-label.workdays(v-if="workdays") Mon-Fri
.weekdays.day-label.weekend(v-else-if="weekend") Weekend
.weekdays.day-label.everyday(v-else-if="everyday") Everyday
.d-flex(v-else)
  .weekdays.day-label(v-for="day in daysOfWeek") {{ daysOfWeekMapping[day] }}
</template>
