<script setup lang="ts">
import { useElementBounding, useEventListener, useMouse } from '@vueuse/core'
import { ColumnSortDirection } from 'frontend/columns/enum/ColumnSortDirection'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import { computed, ref } from 'vue'

interface Props {
  title?: string
  dynamicTitle?: () => string
  tooltipOptions?: unknown
  alignment?: string
  canManage?: boolean
  width?: number
  columnKey?: string
  sortAttribute?: unknown
  isBeingMoved?: boolean
  isOtherColumnBeingMoved?: boolean
  isDropTarget?: boolean
  sortItems?: IColumnSortItems
}
const props = withDefaults(defineProps<Props>(), {
  title: null,
  dynamicTitle: null,
  tooltipOptions: null,
  alignment: 'left',
  canManage: false,
  width: null,
  sort: null,
  sortAttribute: null,
  isBeingMoved: false,
  isOtherColumnBeingMoved: false,
  isDropTarget: false,
  sortItems() {
    return []
  },
  columnKey: null,
})

interface Emits {
  (e: 'resize', value: number): void
  (e: 'update:sort', value: number): void
  (e: 'moveStart', value: MouseEvent): void
  (e: 'toggleSort'): void
}
const emit = defineEmits<Emits>()

const alignmentClass = computed(() => {
  if (props.alignment == 'left') {
    return 'text-start'
  } else if (props.alignment == 'right') {
    return 'text-end'
  } else if (props.alignment == 'center') {
    return 'text-center'
  } else {
    return props.alignment
  }
})

const targetTitle = computed(() => {
  if (props.dynamicTitle) {
    return props.dynamicTitle()
  } else {
    return props.title
  }
})

const cellRef = ref(null)

const isResizing = ref(false)
const initialMouseLeft = ref(null)
const initialWidth = ref(null)

const { x: currentMouseLeft } = useMouse()
const { width: cellWidth } = useElementBounding(cellRef)
function onResizeMouseDown(event) {
  initialMouseLeft.value = event.clientX
  initialWidth.value = cellWidth.value
  isResizing.value = true
}

useEventListener('mouseup', () => {
  if (!isResizing.value) {
    return
  }
  isResizing.value = false
  emit('resize', initialWidth.value + currentMouseLeft.value - initialMouseLeft.value)
})

const cellStyle = computed(() => {
  if (isResizing.value) {
    return {
      width: `${initialWidth.value + currentMouseLeft.value - initialMouseLeft.value}px`,
    }
  }
  if (props.width) {
    return {
      width: `${props.width}px`,
    }
  }
  return {}
})

function onResizeDoubleClick() {
  emit('resize', null)
}

const sortItem = computed(() => {
  return props.sortItems?.find(([key]) => {
    return key === props.columnKey
  })
})

const sortDirection = computed(() => {
  return sortItem.value?.[1] || null
})

const sortIndex = computed(() => {
  if (!props.sortItems) {
    return -1
  }
  return props.sortItems.findIndex(([key]) => {
    return key === props.columnKey
  })
})

const isSortActive = computed(() => {
  return sortDirection.value !== null
})

const sortIconClassMap = new Map([
  [ColumnSortDirection.Ascending, 'fas fa-arrow-down-wide-short'],
  [ColumnSortDirection.Descending, 'fas fa-arrow-up-wide-short'],
  [null, 'fas fa-arrow-down-wide-short'],
])

const sortIconClass = computed(() => {
  return sortIconClassMap.get(sortDirection.value)
})
</script>

<template>
  <th
    ref="cellRef"
    class="header-cell sticky-top"
    :class="[
      alignmentClass,
      {
        'is-resizing': isResizing,
        'is-drop-target': isDropTarget,
        'is-sort-active': isSortActive,
        'is-being-moved': isBeingMoved,
        'is-other-column-being-moved': isOtherColumnBeingMoved,
      },
    ]"
  >
    <div v-if="canManage" class="content" :style="cellStyle">
      <div class="action-icon move" @mousedown="emit('moveStart', $event)">
        <i class="icon fas fa-grip-vertical" />
      </div>
      <!-- :class="{ 'is-disabled': !sortAttribute }" -->
      <div v-if="!!sortAttribute" class="action-icon sort" @click="emit('toggleSort')">
        <i class="icon" :class="sortIconClass" />
        <i v-if="sortIndex > -1 && sortItems.length > 1" class="order-indicator">
          <div class="text">{{ sortIndex + 1 }}</div>
        </i>
      </div>

      <template v-if="title == null && dynamicTitle == null">
        <slot />
      </template>
      <template v-else>
        <div v-tooltip.options="tooltipOptions" class="title-wrapper invisible">
          {{ targetTitle }} <slot />
        </div>
        <div v-tooltip.options="tooltipOptions" class="title-wrapper visible">
          {{ targetTitle }} <slot />
        </div>
      </template>
      <div class="resize-handler" @mousedown="onResizeMouseDown" @dblclick="onResizeDoubleClick" />
    </div>
    <div v-else class="plain-content">
      <div v-if="!!sortAttribute" class="action-icon sort" @click="emit('toggleSort')">
        <i class="icon" :class="sortIconClass" />
        <i v-if="sortIndex > -1 && sortItems.length > 1" class="order-indicator">
          <div class="text">{{ sortIndex + 1 }}</div>
        </i>
      </div>
      <slot>
        <div v-tooltip.options="tooltipOptions" class="title-wrapper visible">
          {{ targetTitle }} <slot />
        </div>
      </slot>
    </div>
    <div class="drop-target-wrapper" />
  </th>
</template>

<style scoped lang="scss">
.header-cell {
  position: relative;
  z-index: 9;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  user-select: none;

  &.is-being-moved {
    opacity: 0.5;
  }

  &.is-other-column-being-moved {
    pointer-events: none;
  }
}

.plain-content {
  padding: 0 8px;
}

.drop-target-wrapper {
  position: absolute;
  left: 4px;
  top: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 2px dashed var(--custom-primary);
  visibility: hidden;
  opacity: 0;
  border-radius: 8px;

  .header-cell.is-drop-target & {
    visibility: visible;
    opacity: 1;
  }
}

.content {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  min-height: 100%;
  height: 100%;
  overflow: hidden;

  .header-cell.is-drop-target & {
    opacity: 0;
  }
}

.resize-handler {
  height: 100%;
  width: 8px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20000;
  border-right: 2px solid transparent;
  cursor: col-resize;

  &:hover {
    border-right: 2px solid var(--custom-primary);
  }

  .header-cell.is-resizing & {
    border-right: 2px solid var(--custom-primary);
  }
}

.title-wrapper {
  padding: 0 8px;
  box-sizing: border-box;
  transition: padding 0.1s cubic-bezier(0, 0.6, 0.4, 1);
  padding: 0 28px;

  &.invisible {
    //opacity: 0;
    //visibility: hidden;
    //opacity: 0.5;
    //transform: translateY(-10px);
  }

  &.visible {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  th:hover &.visible,
  .header-cell.is-being-moved &.visible {
    padding: 0 24px;
  }
}

.action-icon {
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 36px;
  width: 24px;
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;

  transition: transform 0.1s cubic-bezier(0, 0.6, 0.4, 1), opacity 0.1s cubic-bezier(0, 0.6, 0.4, 1);
  opacity: 0;
  z-index: 2;

  &.is-disabled {
    pointer-events: none;
  }

  &.move {
    transform: translateX(-16px);
    left: 0;
    padding-left: 8px;
    cursor: move;

    .header-cell.is-being-moved & {
      transform: translateX(0);
      opacity: 1;

      &.is-disabled {
        opacity: 0.5;
      }
    }
  }

  &.sort {
    transform: translateX(16px);
    right: 0;
    padding-right: 8px;

    .header-cell.is-sort-active & {
      transform: translateX(0);
      opacity: 1;
      color: var(--custom-primary);

      &.is-disabled {
        opacity: 0.5;
      }
    }
  }

  th:hover & {
    transform: translateX(0);
    opacity: 1;

    &.is-disabled {
      opacity: 0.5;
    }
  }

  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }

  .icon {
    width: 16px;
    text-align: center;
  }
}

.order-indicator {
  background: var(--custom-primary);
  color: white;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  position: absolute;
  transform: translateX(6px) translateY(6px);

  .text {
    position: absolute;
    font-size: 9px;
    font-weight: 700;
    left: 50%;
    transform: translateX(calc(-50% - 0.5px));
    text-align: center;
  }
}
</style>
