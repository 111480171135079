<script>
export default {
  name: 'Text',
  props: {
    text: { type: String },
  },
}
</script>

<template lang="pug">
.text-black-15(v-if="!text?.length") empty
.small(v-else-if="text.length > 250" style="width: 300px; word-wrap: break-word" v-tooltip="text") {{ text.substring(0, 250) }}...
.small(v-else style="width: 300px; word-wrap: break-word") {{ text }}
</template>

<style scoped lang="scss"></style>
