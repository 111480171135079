<script setup lang="ts">
import EaSelect from 'frontend/common/ea-form-controls/ea-select.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: string | number
  criterion: ISearchCriteriaNode
  predicate: string
  label?: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string): void
}
const emit = defineEmits<Emits>()

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const ccontCollection = [
  { value: 'valid_value', label: 'A valid OSL' },
  { value: 'no_service', label: 'No service' },
  { value: 'not_ready', label: 'Not ready' },
]
</script>

<template>
  <EaSelect
    v-model="valueModel"
    :label="props.label"
    :name="`nameToBeRenamed`"
    skip-dirty-check
    :multiple="false"
    :options="ccontCollection"
    :reduce="item => item.value"
    :select-label="'label'"
    :selectable="opt => !opt.isOptgroup && !opt.disabled"
  />
</template>
