<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'Location',
  emits: ['click'],
  props: {
    location: { type: Object, default: () => null },
    disabled: { type: Boolean, default: false },
    noCluster: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    previousLocation: { type: Object, default: null },
    nowrap: { type: Boolean, default: false },
    nameNowrap: { type: Boolean, default: false },
    minWidth: { type: [Number, String], default: null },
  },
  setup() {
    return { ...useModals() }
  },
  computed: {
    clusterPresent() {
      return !!this.location?.cluster
    },
  },
}
</script>

<template lang="pug">
span(
  v-if="location"
  :class="{ 'text-nowrap': nowrap, 'd-block': nowrap || minWidth }"
  :style="{ minWidth: minWidth?.constructor == Number ? `${minWidth}px` : minWidth }"
)
  modal-button(v-if="!disabled" :item="location" :modal="previewModals.Location")
    a(:class="{ bold, 'text-nowrap': nameNowrap }") {{ location.name }}
  a.text-reset.text-decoration-none(
    v-else
    :class="{ 'text-nowrap': nameNowrap }"
    @click="$emit('click', $event)"
  )
    | {{ location.name }}
  template(v-if="!noCluster && clusterPresent")
    br(v-if="!singleLine")
    span(v-else) &nbsp;
    modal-button(v-if="!disabled" :item="location.cluster" :modal="previewModals.Cluster")
      a(v-tooltip.options="{ title: 'Cluster' }") [{{ location.cluster.name }}]
    a.text-reset.text-decoration-none(
      v-else
      @click="$emit('click', $event)"
      v-tooltip.options="{ title: 'Cluster' }"
    ) [{{ location.cluster.name }}]

  .pl-2.small.line-through(v-if="previousLocation && previousLocation.id != location?.id")
    location(
      :disabled="disabled"
      :location="previousLocation"
      :noCluster="noCluster"
      :nowrap="nowrap"
    )
span.text-black-15(v-else) empty
</template>
