<script>
export default {
  name: 'PrivateJetDisplay',
  props: {
    transportBooking: {
      type: Object,
      default: () => ({}),
    },
    isSole: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<template lang="pug">
span.me-2(v-if="!isSole") EID[{{ transportBooking?.eid }}]:
true-false-label(:item="transportBooking?.isFlightPrivate")
</template>
