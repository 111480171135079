import { defineAsyncComponent } from 'vue'

const ShuttleTransferFormModal = defineAsyncComponent(() =>
  import('./shuttle-transfer-form-modal.vue'),
)
const ShuttleTransferModal = defineAsyncComponent(() => import('./shuttle-transfer-modal.vue'))
const ShuttleTransferDetails = defineAsyncComponent(() => import('./shuttle-transfer-details.vue'))
const ShuttleTransfersTable = defineAsyncComponent(() => import('./shuttle-transfers-table.vue'))
const BusIdDisplay = defineAsyncComponent(() => import('./bus-id-display.vue'))
const AASM_STATES = [
  { value: 'pending', label: 'Pending', action: 'reset_state' },
  { value: 'cancelled', label: 'Cancelled', action: 'cancel' },
  {
    value: 'started',
    label: 'Started',
    action: 'start',
    visible: item => item.aasmState != 'cancelled',
  },
  {
    value: 'completed',
    label: 'Completed',
    action: 'complete',
    visible: item => item.aasmState != 'cancelled',
  },
  {
    value: 'incomplete',
    label: 'Incomplete',
    action: 'mark_as_incomplete',
    visible: item => item.aasmState != 'cancelled',
    requiresDriverComment: true,
  },
]

const SOURCES = [
  { value: 'generated', label: 'Generated' },
  { value: 'imported', label: 'Imported' },
  { value: 'manually_created', label: 'Manually created' },
  { value: 'synchronized_via_api', label: 'API synchronization' },
  { value: 'overwritten_api_synchronization', label: 'Overridden API synchronization' },
]

export {
  AASM_STATES,
  BusIdDisplay,
  ShuttleTransferDetails,
  ShuttleTransferFormModal,
  ShuttleTransferModal,
  ShuttleTransfersTable,
  SOURCES,
}
