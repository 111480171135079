<script>
export default {
  name: 'EaFilterClearAll',
  props: {
    anyFiltersSet: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      if (this.anyFiltersSet) {
        return 'text-warning bi-funnel-fill'
      } else {
        return 'text-black-50 bi-funnel'
      }
    },
  },
}
</script>

<template lang="pug">
.btn.btn-link.py-0.px-0
  i.pe-2(:class="classes")
    i.bi-x.fs-6.ms-n2.text-danger.position-absolute(style="margin-top: 0.1rem")
</template>
