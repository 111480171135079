<script>
export default {
  name: 'DataTableFooter',
}
</script>

<template lang="pug">
tfoot
  tr
    td.sticky-bottom.p-0.border-0(colspan="200")
      slot
</template>
