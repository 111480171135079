export function convertToFormData(
  baseKey,
  item,
  additionalPayload = {},
  formData = new FormData(),
) {
  Object.keys(item).forEach(k => {
    if (item[k]?.constructor == Array) {
      item[k].forEach((el, i) => {
        if (el?.constructor == Object) {
          convertToFormData(`${baseKey}[${k}][${i}]`, el, null, formData)
        } else {
          formData.append(`${baseKey}[${k}][${i}]`, el)
        }
      })
    } else if (item[k]?.constructor == Object) {
      convertToFormData(`${baseKey}[${k}]`, item[k], null, formData)
    } else {
      formData.append(`${baseKey}[${k}]`, item[k])
    }
  })
  if (Object.keys(additionalPayload || {}).length) {
    Object.keys(additionalPayload).forEach(k => {
      if (additionalPayload[k]?.constructor == Array) {
        additionalPayload[k].forEach((el, i) => {
          if (el?.constructor == Object) {
            convertToFormData(`${k}[${i}]`, el, null, formData)
          } else {
            formData.append(`${k}[${i}]`, el)
          }
        })
      } else if (additionalPayload[k]?.constructor == Object) {
        convertToFormData(`${k}`, additionalPayload[k], null, formData)
      } else {
        formData.append(`${k}`, additionalPayload[k])
      }
      // convertToFormData(`${k}`, additionalPayload[k], null, formData)
    })
  }
  return formData
}
