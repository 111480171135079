<script>
import { sanitizer } from 'frontend/_config/sanitize-html'

export default {
  name: 'SingleDialog',
  emits: ['closed'],
  props: {
    kind: {
      type: String,
      validator(val) {
        return ['confirm', 'alert', 'info'].includes(val)
      },
    },
    title: String,
    body: String,
    size: {
      type: String,
      validator(val) {
        return !val || ['xs', 'sm', 'md', 'lg'].includes(val)
      },
    },
    dismissBtnText: {
      type: String,
      default: 'NO',
    },
    confirmBtnText: {
      type: String,
      default: 'YES',
    },
    promise: {
      type: Promise,
      validator(val) {
        return val.constructor === Promise
      },
    },
    resolve: {
      type: Function,
      validator(val) {
        return val.constructor === Function
      },
    },
    reject: {
      type: Function,
      validator(val) {
        return val.constructor === Function
      },
    },
  },
  data() {
    return {
      result: false,
    }
  },
  computed: {
    escapedBody() {
      return sanitizer.process(this.body || '')
    },
    escapedTitle() {
      return sanitizer.process(this.title || this.escapedBody || '')
    },
    iconClass() {
      if (this.kind == 'confirm') {
        return 'bi-question-octagon text-warning'
      } else if (this.kind == 'alert') {
        return 'bi-exclamation-octagon text-danger'
      } else {
        return 'bi-info-circle text-info'
      }
    },
  },
}
</script>

<template lang="pug">
modal(:size="size" @closed="$emit('closed', result)")
  template(#header="modal")
    i(:class="iconClass")
    span.ps-2(v-html="escapedTitle")

  template(#default="modal")
    p(v-html="!!(escapedTitle || '').length ? escapedBody : ''")

  template(#footer="modal")
    dismiss-button(v-if="kind == 'confirm'" @click="modal.close()" size="sm") {{ dismissBtnText }}
    submit-button(@click.prevent="result = true; modal.close()" size="sm") {{ confirmBtnText }}
</template>
