<script>
import { Dropdown } from 'bootstrap'
import { camelCase, difference, each, filter, find, intersection, map, startCase } from 'lodash'

import KindData, { TIME_VARIABLES } from './_ea-filter-kind-data'

export default {
  name: 'EaFilter',
  emits: ['update:filters'],
  props: {
    filters: { type: Object, default: () => ({}) },
    attribute: { type: String, default: '' },
    humanized: { type: String, default: null },
    kinds: { type: Array, default: () => [] },
    inline: { type: Boolean, default: false },
    timeZone: {
      type: String,
      default: null,
    },
    collection: { type: Array, default: () => [] },
    boolCollection: {
      type: Array,
      default: () => {
        return [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ]
      },
    },
    collectionValue: { type: String, default: 'value' },
    collectionLabel: { type: String, default: 'label' },
    externalShown: { type: Boolean, default: false },
  },
  data() {
    return {
      datetimeItems: {},
      existsCollection: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
      shown: false,
      skipNextLocalFiltersSync: false,
      localFilters: {},
      kindData: KindData,
    }
  },
  computed: {
    datetimeVariablesSelectOptions() {
      const result = Object.keys(TIME_VARIABLES).map(k => ({ value: k, label: TIME_VARIABLES[k] }))
      result.push({ value: 'specific', label: 'Specific value' })
      return result
    },
    properTimeZone() {
      return this.timeZone || this.$store.state.session.timeZone
    },
    anyFilterSet() {
      return !!find(this.usedKinds, kind => {
        return !!this.kindData[kind].isPresent(this.filters[this.attributeKey(kind)])
      })
    },
    usedKinds() {
      const value = filter(this.kinds, f => Object.keys(this.kindData).indexOf(f) != -1)
      if (value.length != this.kinds.length) {
        console.warn(
          'THERE ARE KINDS FOR',
          this.humanized || this.attribute,
          'THAT ARE NOT HANDLED, REVIEW THESE:',
          difference(this.kinds, value),
        )
      }
      return value
    },
  },
  watch: {
    localFilters: {
      handler() {
        if (this.inline && (this.shown || this.externalShown)) {
          this.setFilters()
        }
      },
      deep: true,
    },
    collection: {
      handler(newVal) {
        if (this.usedKinds.indexOf('in') != -1 || this.usedKinds.indexOf('eq') != -1) {
          const newIds = map(newVal, this.collectionValue)
          const updatedFilters = {}
          if (
            this.usedKinds.indexOf('in') != -1 &&
            this.kindData['in'].isPresent(this.localFilters[this.attributeKey('in')])
          ) {
            this.localFilters[this.attributeKey('in')] = intersection(
              this.localFilters[this.attributeKey('in')],
              newIds,
            )
            updatedFilters[this.attributeKey('in')] = this.localFilters[this.attributeKey('in')]
          }
          if (
            this.usedKinds.indexOf('eq') != -1 &&
            this.kindData['eq'].isPresent(this.localFilters[this.attributeKey('eq')]) &&
            newIds.indexOf(this.localFilters[this.attributeKey('eq')]) == -1
          ) {
            this.localFilters[this.attributeKey('eq')] = null
            updatedFilters[this.attributeKey('eq')] = null
          }
          if (Object.keys(updatedFilters).length) {
            this.$emit('update:filters', Object.assign({}, this.filters, updatedFilters))
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.syncLocalFilters()
  },
  mounted() {
    if (!this.inline) {
      this.$eventHub.$on('ea-filter-dropdown-opened', this.eventHubDropdownOpened)
      this.runDropdownListeners()
    }
  },
  unmounted() {
    if (!this.inline) {
      this.$eventHub.$off('ea-filter-dropdown-opened', this.eventHubDropdownOpened)
    }
  },
  methods: {
    updateItem(kind) {
      return event => {
        this.localFilters[this.attributeKey(kind)] = event
      }
    },
    runDropdownListeners() {
      this.$refs.dropdown.addEventListener('hidden.bs.dropdown', this.syncLocalFilters)
      this.$refs.dropdown.addEventListener('show.bs.dropdown', () => {
        this.shown = true
        this.skipNextLocalFiltersSync = false
        this.syncLocalFilters()
        this.$eventHub.$emit('ea-filter-dropdown-opened', this.attribute)
      })
    },
    eventHubDropdownOpened(openedAttribute) {
      if (this.attribute != openedAttribute) this.hide()
    },
    isOptionSelectable() {
      return opt => {
        return !opt.isOptgroup && !opt.disabled
      }
    },
    startCaseFor(value) {
      return startCase(value)
    },
    attributeKey(kind) {
      return `${camelCase(this.attribute)}${this.kindData[kind].suffix}`
    },
    syncLocalFilters() {
      if (this.skipNextLocalFiltersSync) {
        this.skipNextLocalFiltersSync = false
        return
      }
      if (Object.keys(this.localFilters || {})?.length) {
        each(Object.keys(this.localFilters), k => {
          this.localFilters[k] = this.filters[k]
        })
      } else {
        each(this.kinds, k => {
          if (
            !!this.filters[this.attributeKey(k)] ||
            [0, false].includes(this.filters[this.attributeKey(k)])
          ) {
            this.localFilters[this.attributeKey(k)] = this.filters[this.attributeKey(k)]
          }
        })
      }
      this.usedKinds
        .filter(k => k.includes('WithVariables'))
        .forEach(k => {
          if (this.filters[this.attributeKey(k)]?.length) {
            if (Object.keys(TIME_VARIABLES).includes(this.filters[this.attributeKey(k)])) {
              this.datetimeItems[this.attributeKey(k)] = this.filters[this.attributeKey(k)]
            } else {
              this.datetimeItems[this.attributeKey(k)] = 'specific'
            }
          }
        })
    },
    clear() {
      each(Object.keys(this.localFilters), k => {
        this.localFilters[k] = null
      })
      const newFilters = Object.assign({}, this.filters, this.localFilters)
      this.$emit('update:filters', newFilters)
      this.skipNextLocalFiltersSync = true
      this.hide()
    },
    setFilters() {
      const localFiltersToMerge = {}
      each(this.usedKinds, k => {
        if (this.kindData[k].isPresent(this.localFilters[this.attributeKey(k)])) {
          localFiltersToMerge[this.attributeKey(k)] = this.localFilters[this.attributeKey(k)]
        } else {
          localFiltersToMerge[this.attributeKey(k)] = null
        }
      })

      const newFilters = Object.assign({}, this.filters, localFiltersToMerge)
      this.$emit('update:filters', newFilters)
      this.skipNextLocalFiltersSync = true
      this.hide()
    },
    hide() {
      const dp = Dropdown.getInstance(this.$refs.dropdown)
      if (dp?.hide) {
        dp.hide()
      }
    },
    pickerWithVariablesVisible(modelValue) {
      return modelValue == 'specific'
    },
    datetimeVariablesChanged(key, value) {
      if (Object.keys(TIME_VARIABLES).includes(value)) {
        this.localFilters[key] = value
      } else {
        this.localFilters[key] = null
      }
      this.datetimeItems[key] = value
    },
  },
}
</script>

<template lang="pug">
.dropdown(v-if="!inline" :name="'table-filter.' + attribute")
  .btn.btn-link.py-0.px-0.dropdown-toggle(
    ref="dropdown"
    :class="[anyFilterSet ? 'text-warning' : 'text-black-50']"
    data-bs-auto-close="false"
    data-bs-offset="0,4"
    data-bs-toggle="dropdown"
  )
    i(:class="[anyFilterSet ? 'bi-funnel-fill' : 'bi-funnel']")
  .dropdown-menu.px-2
    form(@submit.prevent="setFilters()")
      .ea-filter-input-container.mb-2(v-for="kind in usedKinds" v-if="shown || externalShown")
        template(v-if="kindData[kind].kind == 'exists'")
          slot(
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :multiple="kindData[kind].multiple"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-select(
              :class="{ multiple: kindData[kind].multiple }"
              :label="kindData[kind].name"
              :multiple="kindData[kind].multiple"
              :name="`filters.${attributeKey(kind)}`"
              :options="existsCollection"
              :reduce="item => item[collectionValue]"
              :select-label="collectionLabel"
              :selectable="isOptionSelectable(kind)"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )

        template(
          v-else-if="['blank', 'present', 'bool', 'notNull'].indexOf(kindData[kind].kind) != -1"
        )
          slot(
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :multiple="kindData[kind].multiple"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-select(
              :class="{ multiple: kindData[kind].multiple }"
              :label="kindData[kind].name"
              :multiple="kindData[kind].multiple"
              :name="`filters.${attributeKey(kind)}`"
              :options="boolCollection"
              :reduce="item => item[collectionValue]"
              :select-label="collectionLabel"
              :selectable="isOptionSelectable(kind)"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )

        template(v-else-if="kindData[kind].kind == 'select'")
          slot(
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :multiple="kindData[kind].multiple"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-select(
              :class="{ multiple: kindData[kind].multiple }"
              :label="kindData[kind].name"
              :multiple="kindData[kind].multiple"
              :name="`filters.${attributeKey(kind)}`"
              :options="collection"
              :reduce="item => item[collectionValue]"
              :select-label="collectionLabel"
              :selectable="isOptionSelectable(kind)"
              v-model="localFilters[attributeKey(kind)]"
              skipDirtyCheck
            )
              template(v-slot:option="option")
                span(:style="[option.isOptgroup ? 'margin-left: -0.5rem' : '']") {{ option[collectionLabel] }}

        template(v-else-if="kindData[kind].kind == 'dateMulti'")
          slot(
            :config="kindData[kind].flatpickrConfig"
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-date-selector(
              :config="kindData[kind].flatpickrConfig"
              :label="kindData[kind].name"
              :name="`filters.${attributeKey(kind)}`"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )

        template(v-else-if="kindData[kind].kind == 'date'")
          slot(
            :config="kindData[kind].flatpickrConfig"
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-datetimepicker(
              :config="kindData[kind].flatpickrConfig"
              :label="kindData[kind].name"
              :name="`filters.${attributeKey(kind)}`"
              :timeZone="properTimeZone"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )

        template(v-else-if="kindData[kind].kind == 'datetime'")
          slot(
            :config="kindData[kind].flatpickrConfig"
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            separated-datetimepicker(
              :label="kindData[kind].name"
              :name="`filters.${attributeKey(kind)}`"
              :timeZone="properTimeZone"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )
            //- :config="kindData[kind].flatpickrConfig"

        template(v-else-if="kindData[kind].kind == 'dateWithVariables'")
          slot(
            :config="kindData[kind].flatpickrConfig"
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-select.select-nowrap(
              :label="kindData[kind].name"
              :modelValue="datetimeItems[attributeKey(kind)]"
              :name="`filters.${attributeKey(kind)}.variablesSelector`"
              :options="datetimeVariablesSelectOptions"
              :reduce="item => item.value"
              @update:modelValue="datetimeVariablesChanged(attributeKey(kind), $event)"
              input-size="sm"
              select-label="label"
              skipDirtyCheck
            )
            ea-datetimepicker(
              v-if="pickerWithVariablesVisible(datetimeItems[attributeKey(kind)])"
              :config="kindData[kind].flatpickrConfig"
              :name="`filters.${attributeKey(kind)}`"
              :timeZone="properTimeZone"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )

        template(v-else-if="kindData[kind].kind == 'datetimeWithVariables'")
          slot(
            :config="kindData[kind].flatpickrConfig"
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-select.select-nowrap(
              :label="kindData[kind].name"
              :modelValue="datetimeItems[attributeKey(kind)]"
              :name="`filters.${attributeKey(kind)}.variablesSelector`"
              :options="datetimeVariablesSelectOptions"
              :reduce="item => item.value"
              @update:modelValue="datetimeVariablesChanged(attributeKey(kind), $event)"
              input-size="sm"
              select-label="label"
              skipDirtyCheck
            )
            separated-datetimepicker(
              v-if="pickerWithVariablesVisible(datetimeItems[attributeKey(kind)])"
              :name="`filters.${attributeKey(kind)}`"
              :timeZone="properTimeZone"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )
            //- :config="kindData[kind].flatpickrConfig"

        template(v-else-if="kindData[kind].kind == 'dateWithVariables'")
          slot(
            :config="kindData[kind].flatpickrConfig"
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :name="kind"
            :update-item="updateItem(kind)"
          )
            ea-select.select-nowrap(
              :label="kindData[kind].name"
              :modelValue="datetimeItems[attributeKey(kind)]"
              :name="`filters.${attributeKey(kind)}.variablesSelector`"
              :options="datetimeVariablesSelectOptions"
              :reduce="item => item.value"
              @update:modelValue="datetimeVariablesChanged(attributeKey(kind), $event)"
              input-size="sm"
              select-label="label"
              skipDirtyCheck
            )
            separated-datetimepicker(
              v-if="pickerWithVariablesVisible(datetimeItems[attributeKey(kind)])"
              :name="`filters.${attributeKey(kind)}`"
              :timeZone="properTimeZone"
              v-model="localFilters[attributeKey(kind)]"
              input-size="sm"
              skipDirtyCheck
            )
            //- :config="kindData[kind].flatpickrConfig"

        template(v-else)
          slot(
            :item="localFilters[attributeKey(kind)]"
            :label="kindData[kind].name"
            :name="kind"
            :type="kindData[kind].kind"
            :update-item="updateItem(kind)"
          )
            ea-input(
              :label="kindData[kind].name"
              :name="`filters.${attributeKey(kind)}`"
              :type="kindData[kind].kind"
              v-model="localFilters[attributeKey(kind)]"
              skipDirtyCheck
            )

      .d-flex.align-items-center.justify-content-between
        div
          dismiss-button(@click="hide()" size="sm")
        div
          .btn.btn-sm.btn-outline-danger.ms-1(:name="'clear.' + attribute" @click="clear()") Clear
          submit-button.ms-1(:name="'filter.' + attribute" @click="setFilters()" size="sm") SET
div(v-else)
  //- span {{ humanized }}
  .ea-filter-input-container.mb-2(v-for="kind in usedKinds" v-if="shown || externalShown")
    template(v-if="kindData[kind].kind == 'exists'")
      slot(
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :multiple="kindData[kind].multiple"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        ea-select(
          :class="{ multiple: kindData[kind].multiple }"
          :label="kindData[kind].name"
          :multiple="kindData[kind].multiple"
          :name="`filters.${attributeKey(kind)}`"
          :options="existsCollection"
          :reduce="item => item[collectionValue]"
          :select-label="collectionLabel"
          :selectable="isOptionSelectable(kind)"
          v-model="localFilters[attributeKey(kind)]"
          input-size="sm"
          skipDirtyCheck
        )

    template(
      v-else-if="['blank', 'present', 'bool', 'notNull'].indexOf(kindData[kind].kind) != -1"
    )
      slot(
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :multiple="kindData[kind].multiple"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        ea-select(
          :class="{ multiple: kindData[kind].multiple }"
          :label="kindData[kind].name"
          :multiple="kindData[kind].multiple"
          :name="`filters.${attributeKey(kind)}`"
          :options="boolCollection"
          :reduce="item => item[collectionValue]"
          :select-label="collectionLabel"
          :selectable="isOptionSelectable(kind)"
          v-model="localFilters[attributeKey(kind)]"
          input-size="sm"
          skipDirtyCheck
        )

    template(v-else-if="kindData[kind].kind == 'select'")
      slot(
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :multiple="kindData[kind].multiple"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        ea-select(
          :class="{ multiple: kindData[kind].multiple }"
          :label="kindData[kind].name"
          :multiple="kindData[kind].multiple"
          :name="`filters.${attributeKey(kind)}`"
          :options="collection"
          :reduce="item => item[collectionValue]"
          :select-label="collectionLabel"
          :selectable="isOptionSelectable(kind)"
          v-model="localFilters[attributeKey(kind)]"
          skipDirtyCheck
        )
          template(v-slot:option="option")
            span(:style="[option.isOptgroup ? 'margin-left: -0.5rem' : '']") {{ option[collectionLabel] }}

    template(v-else-if="kindData[kind].kind == 'dateMulti'")
      slot(
        :config="kindData[kind].flatpickrConfig"
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        ea-date-selector(
          :config="kindData[kind].flatpickrConfig"
          :label="kindData[kind].name"
          :name="`filters.${attributeKey(kind)}`"
          v-model="localFilters[attributeKey(kind)]"
          input-size="sm"
          skipDirtyCheck
        )

    template(v-else-if="kindData[kind].kind == 'date'")
      slot(
        :config="kindData[kind].flatpickrConfig"
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        ea-datetimepicker(
          :config="kindData[kind].flatpickrConfig"
          :label="kindData[kind].name"
          :name="`filters.${attributeKey(kind)}`"
          :timeZone="properTimeZone"
          v-model="localFilters[attributeKey(kind)]"
          input-size="sm"
          skipDirtyCheck
        )

    template(v-else-if="kindData[kind].kind == 'datetime'")
      slot(
        :config="kindData[kind].flatpickrConfig"
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        separated-datetimepicker(
          :label="kindData[kind].name"
          :name="`filters.${attributeKey(kind)}`"
          :timeZone="properTimeZone"
          v-model="localFilters[attributeKey(kind)]"
          input-size="sm"
          skipDirtyCheck
        )
        //- :config="kindData[kind].flatpickrConfig"

    template(v-else-if="kindData[kind].kind == 'dateWithVariables'")
      slot(
        :config="kindData[kind].flatpickrConfig"
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        ea-select.select-nowrap(
          :label="kindData[kind].name"
          :modelValue="datetimeItems[attributeKey(kind)]"
          :name="`filters.${attributeKey(kind)}.variablesSelector`"
          :options="datetimeVariablesSelectOptions"
          :reduce="item => item.value"
          @update:modelValue="datetimeVariablesChanged(attributeKey(kind), $event)"
          input-size="sm"
          select-label="label"
          skipDirtyCheck
        )
        ea-datetimepicker(
          v-if="pickerWithVariablesVisible(datetimeItems[attributeKey(kind)])"
          :config="kindData[kind].flatpickrConfig"
          :name="`filters.${attributeKey(kind)}`"
          :timeZone="properTimeZone"
          v-model="localFilters[attributeKey(kind)]"
          input-size="sm"
          skipDirtyCheck
        )

    template(v-else-if="kindData[kind].kind == 'datetimeWithVariables'")
      slot(
        :config="kindData[kind].flatpickrConfig"
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :name="kind"
        :update-item="updateItem(kind)"
      )
        ea-select.select-nowrap(
          :label="kindData[kind].name"
          :modelValue="datetimeItems[attributeKey(kind)]"
          :name="`filters.${attributeKey(kind)}.variablesSelector`"
          :options="datetimeVariablesSelectOptions"
          :reduce="item => item.value"
          @update:modelValue="datetimeVariablesChanged(attributeKey(kind), $event)"
          input-size="sm"
          select-label="label"
          skipDirtyCheck
        )
        separated-datetimepicker(
          v-if="pickerWithVariablesVisible(datetimeItems[attributeKey(kind)])"
          :name="`filters.${attributeKey(kind)}`"
          :timeZone="properTimeZone"
          v-model="localFilters[attributeKey(kind)]"
          input-size="sm"
          skipDirtyCheck
        )
        //- :config="kindData[kind].flatpickrConfig"

    template(v-else)
      slot(
        :item="localFilters[attributeKey(kind)]"
        :label="kindData[kind].name"
        :name="kind"
        :type="kindData[kind].kind"
        :update-item="updateItem(kind)"
      )
        ea-input(
          :label="kindData[kind].name"
          :name="`filters.${attributeKey(kind)}`"
          :type="kindData[kind].kind"
          v-model="localFilters[attributeKey(kind)]"
          skipDirtyCheck
        )
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/_variables.scss';
.dropdown-toggle {
  &:after {
    display: none;
  }
  &.show i.bi-funnel:before {
    content: '\f3e0';
  }
}
</style>
