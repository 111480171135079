import { defineAsyncComponent } from 'vue'

const TransportBookingGroupingRuleFormModal = defineAsyncComponent(() =>
  import('./transport-booking-grouping-rule-form-modal.vue'),
)
const TransportBookingGroupingRulesIndex = defineAsyncComponent(() =>
  import('./transport-booking-grouping-rules-index.vue'),
)
const TransportBookingGroupingRulesTable = defineAsyncComponent(() =>
  import('./transport-booking-grouping-rules-table.vue'),
)

const FLIGHT_NUMBER_MATCHES = [
  {
    value: null,
    label: 'Not applied',
    labelWithDescription: 'Not applied',
  },
  {
    value: 'exact_match',
    label: 'Exact match',
    labelWithDescription:
      'Exact match<div class="small ms-2">All of the grouped TBs have to have <b>the same</b> normalized travel number</div>',
  },
  {
    value: 'empty_match',
    label: 'Exact match or empty',
    labelWithDescription:
      'Exact match or empty<div class="small ms-2">Each grouped TB has to have <b>empty or the same</b> normalized travel number</div>',
  },
]

export {
  FLIGHT_NUMBER_MATCHES,
  TransportBookingGroupingRuleFormModal,
  TransportBookingGroupingRulesIndex,
  TransportBookingGroupingRulesTable,
}
