<script>
export default {
  name: 'ErebusLabel',
  props: { item: { type: Object, default: () => ({}) }, additionalClass: { type: String } },
  computed: {
    badgeStyle() {
      if (!this.item) return {}
      return {
        color: this.fontColor,
        backgroundColor: this.item.backgroundColor,
        borderColor: this.item.borderColor || this.item.backgroundColor,
        borderStyle: 'solid',
      }
    },
    fontColor() {
      if (this.item.fontColor) {
        return this.item.fontColor
      } else if (this.item.backgroundColor) {
        return this.pickTextColorBasedOnBgColor(this.item.backgroundColor)
      } else {
        return '#333'
      }
    },
  },
  methods: {
    pickTextColorBasedOnBgColor(bgColor) {
      let r, g, b
      if (bgColor.charAt(0) === '#') {
        const color = bgColor.substring(1, 7)
        r = parseInt(color.substring(0, 2), 16) // hexToR
        g = parseInt(color.substring(2, 4), 16) // hexToG
        b = parseInt(color.substring(4, 6), 16) // hexToB
      } else {
        const color = bgColor
          .replace(/rgba?\(/g, '')
          .replace(/\)/g, '')
          .replace(/ /g, '')
          .split(',')
          .map(el => parseInt(el))
        r = color[0]
        g = color[1]
        b = color[2]
      }
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#333' : '#fff'
    },
  },
}
</script>

<template lang="pug">
span.erebus-label(
  :class="{ 'line-through': !!item?.archivedAt }"
  v-tooltip="!!item?.archivedAt ? 'Is archived' : item.description?.length ? item.description : null"
)
  span.badge.border-1(v-if="item" :class="additionalClass" :id="item.id" :style="badgeStyle") {{ item.name }}
    slot
  span.text-black-15(v-else) empty
</template>

<style lang="scss" scoped>
.erebus-label {
  height: 100%;
  display: flex;
  align-items: center;
}
.badge {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}
</style>
