import { setupEventOperationalRoutes } from 'frontend/_setup/setupRouter/setupEventOperationalRoutes.ts'
import { setupExternalRoutes } from 'frontend/_setup/setupRouter/setupExternalRoutes.ts'
import { setupPassengerPortalRoutes } from 'frontend/_setup/setupRouter/setupPassengerPortalRoutes.ts'
import { setupSessionRoutes } from 'frontend/_setup/setupRouter/setupSessionRoutes.ts'
import qs from 'qs'
import { createRouter, createWebHistory } from 'vue-router'

import { appModule } from '../../_config/modules'
import Main from '../components/main.vue'

function registerModule(routes, moduleName) {
  routes.forEach(route => {
    // keeps the module if it was defined before registration
    // to allow for nested modules
    route.meta = { module: moduleName, ...route.meta }
    if (route.children) {
      registerModule(route.children, moduleName)
    }
  })
  return routes
}

const externalRoutes = setupExternalRoutes()
const sessionRoutes = setupSessionRoutes()
const passengerPortalRoutes = setupPassengerPortalRoutes()
const eventOperationalRoutes = setupEventOperationalRoutes()

const setupRouter = createRouter({
  history: createWebHistory('/'),
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
  routes: [
    {
      path: '',
      component: Main,
      name: 'root',
      meta: { auth: 'opened', title: 'Welcome' },
    },
    {
      path: '/tmm-hub',
      component: () => import('../../session/tmm-hub.vue'),
      name: 'tmm-hub',
      meta: { title: 'TMM Hub' },
    },
    ...registerModule(externalRoutes, appModule.EXTERNAL),
    ...registerModule(sessionRoutes, appModule.SESSION),
    ...registerModule(passengerPortalRoutes, appModule.PASSENGER),
    ...registerModule(eventOperationalRoutes, appModule.OPERATIONAL),

    { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/' },
  ],
})

export default setupRouter
