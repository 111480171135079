<script>
import { ref } from 'vue'

import FileUrl from '../file-url.vue'
import InputGroup from './_input-group.vue'

export default {
  name: 'EaFileInput',
  inheritAttrs: false,
  emits: ['update:modelValue', 'update:fileName', 'update:fileUrl', 'update:fileObject'],
  props: {
    modelValue: { type: File, default: null },
    label: { type: String, default: null },
    placeholder: { type: String, default: 'No file chosen' },
    fileName: {
      type: String,
      validator(val) {
        return val !== undefined
      },
    },
    fileUrl: {
      type: String,
      validator(val) {
        return val !== undefined
      },
    },
    disabled: { type: Boolean, default: false },
    name: { type: String, required: true },
    validators: { type: Object, default: null },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  setup() {
    return { formControlContainerRef: ref(null) }
  },
  computed: {
    buttonLabel() {
      return this.fileName ? 'Change file' : 'Choose file'
    },
  },
  methods: {
    fileChanged($event) {
      if ($event?.target?.files?.length) {
        this.$emit('update:modelValue', $event.target.files[0])
        this.$emit('update:fileUrl', 'current')
        this.$emit('update:fileName', $event.target.files[0].name)
        this.$emit('update:fileObject', {
          fileUrl: 'current',
          fileName: $event.target.files[0].name,
          modelValue: $event.target.files[0],
        })
      }
    },
  },
  components: {
    InputGroup,
    FileUrl,
  },
}
</script>

<template lang="pug">
form-control-container(
  ref="formControlContainerRef"
  :additional-errors="additionalErrors"
  :label="label"
  :validators="validators"
  :value="modelValue"
)
  template(#default)
    .file-input-wrapper
      input(:disabled="disabled" @change="fileChanged($event)" type="file")

      input-group
        //- template(#input-prepend)
        template(#default)
          input.form-control(
            :disabled="disabled"
            :placeholder="placeholder"
            :value="fileName"
            type="text"
          )
        template(#input-append)
          .input-group-append
            button.input-group-text(:class="{ disabled: disabled }") {{ buttonLabel }}
          template(v-if="fileUrl?.length")
            .input-group-append.download-current-file(v-tooltip="'Download'")
              file-url.input-group-text(:url="fileUrl == 'current' ? modelValue : fileUrl")
                i.bi-download
</template>
