<script>
import { camelCase } from 'lodash'
import { inject, ref } from 'vue'

import KindData from '../ea-filter/_ea-filter-kind-data'

export default {
  name: 'DataTableFiltersSection',
  emits: ['update:modelValue'],
  props: {
    level: {
      type: Number,
      required: true,
    },
    config: {
      type: Object,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    footerTop: {
      type: Boolean,
      default: false,
    },
    windowWidth: {
      type: Number,
      default: window.innerWidth,
    },
  },
  setup() {
    const selectedConfig = ref(null)
    const height = inject('height', `300px`)
    return { selectedConfig, height }
  },
  computed: {
    children() {
      if (this.config.constructor != Array) {
        return this.config.children || []
      } else {
        return this.config
      }
    },
    isSmallDevice() {
      return this.windowWidth <= 576 // sm bootstrap $grid-breakpoint
    },
    styleConfig() {
      return {
        minWidth: this.filtersSectionWidth,
        height: !this.footerTop ? `calc(${this.height} - 30px - 1.5rem)` : 'initial',
        overflowY: 'auto',
        overflowX: `hidden`,
      }
    },
    filtersSectionWidth() {
      if (this.isSmallDevice) {
        return '100%'
      } else {
        if (this.level == 0 && !this.selectedConfig) {
          return '353px'
        } else {
          return '250px'
        }
      }
    },
  },
  watch: {
    config() {
      this.selectedConfig = null
    },
  },
  methods: {
    deselect() {
      this.selectedConfig = null
    },
    isChildFilled(child) {
      if (child.attribute) {
        const possibilities = Object.keys(this.modelValue || {}).filter(
          k =>
            k.startsWith(child.attribute) &&
            (!!this.modelValue[k] || this.modelValue[k] == 0 || this.modelValue[k] == false),
        )
        if (
          possibilities.length &&
          possibilities.find(
            key =>
              !!child.kinds.find(kind => {
                return camelCase(`${child.attribute} ${KindData[kind]?.suffix}`) == key
              }),
          )
        ) {
          return true
        }
      }
      if (child.children?.length) {
        const result = child.children.find(subChild => {
          return this.isChildFilled(subChild)
        })
        if (result) {
          return true
        }
      }
      return false
    },
    slotNames(column) {
      return Object.keys(this.availableColumns[column]?.header?.filter?.slots || {})
    },
    columnStyle(column) {
      return this.availableColumns[column]?.header?.style || null
    },
    configClicked(childConfig) {
      if (this.selectedConfig?.label == childConfig?.label) {
        this.selectedConfig = null
      } else if (this.selectedConfig) {
        this.selectedConfig = null
        this.$nextTick(() => {
          this.selectedConfig = childConfig
        })
      } else {
        this.selectedConfig = childConfig
      }
    },
  },
}
</script>

<template lang="pug">
.data-table-filters-section.d-flex.align-items-start.justify-content-start(
  name="dataTableFiltersSection"
)
  .text-nowrap(:style="styleConfig")
    .mb-1.section-ea-filters(
      v-if="!!config && config.constructor != Array && config?.attribute && config?.kinds"
    )
      ea-filter(
        v-bind="config.componentProps || {}"
        :attribute="config.attribute"
        :filters="modelValue"
        :humanized="config.label"
        :kinds="config.kinds"
        @update:filters="$emit('update:modelValue', $event)"
        externalShown
        inline
      )
        template(
          v-for="slotName in Object.keys(config?.slots || {})"
          v-slot:[slotName]="{ label, item, multiple, inputClass, updateItem }"
        )
          component(
            v-bind="config.slots[slotName]?.componentProps || {}"
            :inputClass="inputClass"
            :is="config.slots[slotName].component"
            :label="label"
            :model-value="item"
            :multiple="multiple"
            :name="config.slots[slotName].componentName"
            @update:modelValue="updateItem($event)"
          )

          //- @blocker="blocker[column] = $event"

    .next-section-indicator(
      v-for="childConfig in children"
      :class="{ selected: selectedConfig?.label == childConfig?.label, filled: isChildFilled(childConfig) }"
      :name="childConfig?.attribute"
      @click="configClicked(childConfig)"
    )
      .d-flex.align-items-center.justify-content-between
        label.me-2 {{ childConfig.label }}
        i.fa.fa-chevron-right(
          :class="selectedConfig?.label == childConfig?.label ? '' : 'text-black-50'"
        )

  .subsequent-data-table-filters-section-wrapper(
    v-if="!!selectedConfig"
    :style="{ 'z-index': level + 1 }"
  )
    .btn.btn-sm.btn-white.text-primary.btn-data-table-filters-mobile-back.d-flex.align-items-center.p-0.ps-2.pe-2(
      v-if="isSmallDevice"
      @click="selectedConfig = null"
    )
      h1.fa.fa-chevron-left.text-primary.justify-content-center.mb-0
    data-table-filters-section(
      :config="selectedConfig"
      :footerTop="footerTop"
      :level="level + 1"
      :windowWidth="windowWidth"
      v-model="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    )
</template>
