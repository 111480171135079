import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {Location} from 'frontend/locations'
import {Cluster} from 'frontend/clusters'

export const DepotDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'created_at',
    'updated_at',
    { location: ['id', 'name', 'time_zone'] },
    { cluster: ['id', 'name', 'time_zone'] },
  ],
  subscriptions: {
    items: 'DepotsChannel',
    locations: 'LocationsFlatChannel',
    clusters: 'ClustersSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'name',
    'location',
    'cluster',
    'createdDatetime',
    'updatedDatetime',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
        class: 'text-nowrap',
      },
    },
    location: {
      label: 'Location',
      header: {
        sort: 'locationName',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: Location,
        componentProps: depot => ({ location: depot.location }),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: Cluster,
        componentProps: depot => ({ cluster: depot.cluster }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: depot => ({
          t: depot.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: depot => ({
          t: depot.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: depot => ({
          t: depot.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: depot => ({
          t: depot.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: depot => ({
          t: depot.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: depot => ({
          t: depot.createdAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}