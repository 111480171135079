import { createPlugin, globalPlugins, NamedTimeZoneImpl } from '@fullcalendar/common'

class DayjsNamedTimeZone extends NamedTimeZoneImpl {
  offsetForArray(a) {
    return dayjs.tz(a, this.timeZoneName).utcOffset()
  }

  timestampToArray(ms) {
    return dayjs.tz(ms, this.timeZoneName).toArray()
  }
}

const plugin = createPlugin({
  namedTimeZonedImpl: DayjsNamedTimeZone,
})

globalPlugins.push(plugin)

export default plugin
