import Email from 'frontend/common/email.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import EventOrganizationUserRolePreview from 'frontend/event-organization-users/_event-organization-user-role-preview.vue'
import VariableNames from 'frontend/event-organization-users/_event-organization-user-variable-names.vue'
import VariableValues from 'frontend/event-organization-users/_event-organization-user-variable-values.vue'
import { markRaw } from 'vue'

export const EventOrganizationUserDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'roles',
    // 'role_description',
    'role_variables_definition',
    'is_disabled',
    'has_personalized_role',
    'updated_at',
    'created_at',
    { user: ['id', 'email', 'name', 'surname', 'label'] },
    { additional_role_scopes: ['id', 'action_types', 'data_model_type'] },
    {
      role: [
        'id',
        'name',
        'enabled_view_types',
        'color',
        'used_variable_ids',
        { used_variables: ['id', 'name', 'data_model_type'] },
        { role_scopes: ['id', 'action_types', 'data_model_type'] },
      ],
    },
    { role_variables: ['id', 'name', 'data_model_type'] },
    { used_value_presets: ['id', 'label'] },
  ],
  subscriptions: {
    items: 'EventOrganizationUsersChannel',
    users: 'UsersSimpleChannel',
    roles: 'RolesSimpleChannel',
  },
  defaultColumns: [
    'userLabel',
    'userEmail',
    'isDisabled',
    'wasSignedIn',
    'roleName',
    'roleVariables',
    'roleValues',
  ],
  columnConfig: {
    userSurname: {
      label: 'Surname',
      header: {
        sort: 'userSurnameEmail',
      },
      cell: {
        kind: 'simple',
        path: 'user.surname',
      },
    },
    userName: {
      label: 'Name',
      header: {
        sort: 'userName',
      },
      cell: {
        kind: 'simple',
        path: 'user.name',
      },
    },
    userLabel: {
      label: 'User',
      header: {
        sort: 'userSurnameEmail',
      },
      cell: {
        kind: 'simple',
        path: 'user.label',
      },
    },
    wasSignedIn: {
      label: 'Validated by logging in',
      header: {},
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: item => ({ item: item.user.wasSignedIn }),
      },
    },
    userEmail: {
      label: 'Email',
      header: {
        sort: 'userEmail',
      },
      cell: {
        kind: 'component',
        component: markRaw(Email),
        componentProps: eou => ({
          email: eou?.user?.email,
        }),
      },
    },
    isDisabled: {
      label: 'Disabled',
      header: {},
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: item => ({ item: item.isDisabled, revertColors: true }),
      },
    },
    roles: {
      label: 'Global Roles',
      header: {},
      cell: {
        kind: 'function',
        fn: eou => (eou?.roles || []).sort().join(', '),
      },
    },
    roleName: {
      label: 'Role',
      header: {
        sort: 'roleName',
      },
      cell: {
        kind: 'component',
        component: markRaw(EventOrganizationUserRolePreview),
        componentProps: eou => ({ item: eou }),
        // { name: eou.role.name, backgroundColor: eou.role.color } }),
        // kind: 'simple',
        // path: 'role.name',
      },
    },
    roleVariables: {
      label: 'Variables',
      cell: {
        kind: 'component',
        component: markRaw(VariableNames),
        componentProps: eou => ({
          variables: eou?.role?.usedVariables,
        }),
      },
    },
    roleValues: {
      label: 'Value used',
      cell: {
        kind: 'component',
        component: markRaw(VariableValues),
        componentProps: eou => ({
          variables: eou?.role?.usedVariables,
          values: eou?.roleVariablesDefinition,
          usedValuePresets: eou?.usedValuePresets,
        }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.createdAt }),
      },
    },
    createdAtTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    createdAtDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },
    updatedAtTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAtDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
  },
}
