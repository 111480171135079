import { AslsSelect } from 'frontend/asls'
import { AslsService } from 'frontend/asls/asls-service'
import { ClientCategoriesSelect } from 'frontend/client-categories'
import { ClientCategoriesService } from 'frontend/client-categories/client-categories-service'
import { ClientFunctionsSelect } from 'frontend/client-functions'
import { ClientFunctionsService } from 'frontend/client-functions/client-functions-service'
import { ClientsSelect } from 'frontend/clients'
import { ClientsService } from 'frontend/clients/clients-service'
import { TslsSelect } from 'frontend/tsls'
import { TslsService } from 'frontend/tsls/tsls-service'
import { markRaw } from 'vue'

import CRITERIA_CONFIG_KINDS_MAP from './criteria-config-kinds-map.json'

export const CRITERIA_CONFIG = [
  {
    label: 'Client',
    attribute: 'id',
    kinds: CRITERIA_CONFIG_KINDS_MAP['id'],
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new ClientsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name', 'surname'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => [c.surname, c.name].join(' '))
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      in: {
        component: markRaw(ClientsSelect),
        componentName: 'filters.idIn',
      },
      notIn: {
        component: markRaw(ClientsSelect),
        componentName: 'filters.idNotIn',
      },
    },
  },
  {
    label: 'Group paths',
    attribute: 'categoryIds',
    kinds: CRITERIA_CONFIG_KINDS_MAP['categoryIds'],
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new ClientCategoriesService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => c.name)
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      overlapArray: {
        component: markRaw(ClientCategoriesSelect),
        componentName: 'filters.categoryIdsOverlapArray',
      },
      notOverlapArray: {
        component: markRaw(ClientCategoriesSelect),
        componentName: 'filters.categoryIdsNotOverlapArray',
      },
    },
    children: [
      {
        label: 'Names',
        attribute: 'categoriesPhrase',
        kinds: CRITERIA_CONFIG_KINDS_MAP['categoriesPhrase'],
      },
    ],
  },
  {
    label: 'Functions',
    attribute: 'functionIds',
    kinds: CRITERIA_CONFIG_KINDS_MAP['functionIds'],
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new ClientFunctionsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => c.name)
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      overlapArray: {
        component: markRaw(ClientFunctionsSelect),
        componentName: 'filters.functionIdsOverlapArray',
      },
      notOverlapArray: {
        component: markRaw(ClientFunctionsSelect),
        componentName: 'filters.functionIdsNotOverlapArray',
      },
    },
  },

  {
    label: 'ASL',
    attribute: 'aslId',
    kinds: CRITERIA_CONFIG_KINDS_MAP['aslId'],
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new AslsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => c.name)
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      in: {
        component: markRaw(AslsSelect),
        componentName: 'filters.aslIdIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Having Clients',
              scopeName: 'havingClientsOrExplicitIds',
            },
          ],
        },
      },
      notIn: {
        component: markRaw(AslsSelect),
        componentName: 'filters.aslIdNotIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Having Clients',
              scopeName: 'havingClientsOrExplicitIds',
            },
          ],
        },
      },
    },
  },
  {
    label: 'TSL',
    attribute: 'tslId',
    kinds: CRITERIA_CONFIG_KINDS_MAP['tslId'],
    fetchSelectedItems: async ids => {
      if (ids?.length) {
        const response = await new TslsService().index({
          data: {
            idIn: ids.constructor == Array ? ids : [ids],
            fields: ['name'],
          },
        })
        return Object.values(response?.entities?.items || {})
          .map(c => c.name)
          .join(', ')
      } else {
        return 'empty'
      }
    },
    slots: {
      in: {
        component: markRaw(TslsSelect),
        componentName: 'filters.tslIdIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Having Clients',
              scopeName: 'havingClientsOrExplicitIds',
            },
          ],
        },
      },
      notIn: {
        component: markRaw(TslsSelect),
        componentName: 'filters.tslIdNotIn',
        componentProps: {
          optionQuerySelectors: [
            {
              label: 'Having Clients',
              scopeName: 'havingClientsOrExplicitIds',
            },
          ],
        },
      },
    },
  },
]

const flattenChildren = (config, parent = null) => {
  let children = []
  config.parent = parent
  if (config.children?.length > 0) {
    children = config.children.flatMap(child => flattenChildren(child, config))
  }
  return [...children, config]
}

export const CRITERIA_CONFIG_FLAT = CRITERIA_CONFIG.flatMap(config => flattenChildren(config))
