<script setup lang="ts">
import TaggableSelect from 'frontend/common/selects/taggable-select.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: string | number
  criterion: ISearchCriteriaNode
  predicate: string
  label?: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string): void
}
const emit = defineEmits<Emits>()

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <TaggableSelect
    v-model="valueModel"
    :label="props.label"
    :main-text="`Type ${criterion.helperSubject}`"
    :name="`todo`"
    skip-dirty-check
  />
</template>
