import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {DriverSource} from 'frontend/driver-sources'
import {Cluster} from 'frontend/clusters'
import {DriverCategory} from 'frontend/driver-categories'
import FormattedTime from 'frontend/common/formatted-time.vue'

export const DriverRegistrationQrTokenDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'token',
    { driver_source: ['id', 'name'] },
    { cluster: ['id', 'name'] },
    { driver_category: ['id', 'name'] },
    'updated_at',
    'created_at',
  ],
  subscriptions: {
    items: 'DriverRegistrationQrTokensChannel',
  },
  defaultColumns: [
    'driverSource',
    'driverCategory',
    'cluster',
    'updatedDatetime',
    'createdDatetime',
  ],
  columnConfig: {
    driverSource: {
      label: 'Source',
      header: {
        sort: 'driverSourceName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverSource),
        componentProps: driverTokenEntry => ({
          driverSource: driverTokenEntry?.driverSource,
        }),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: driver => ({ cluster: driver.cluster }),
      },
    },
    driverCategory: {
      label: 'Category',
      header: {
        sort: 'driverCategoryName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverCategory),
        componentProps: driver => ({ driverCategory: driver.driverCategory }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.updatedAt,
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.createdAt,
        }),
      },
    },
  }
}