<script>
import Collapsible from 'frontend/common/collapsible.vue'

export function useGoogleDirectionOptions() {
  const trafficModelOptions = [
    { label: 'Best guess (default)', value: 'best_guess' },
    { label: 'Optimistic', value: 'optimistic' },
    { label: 'Pessimistic', value: 'pessimistic' },
  ]
  const trafficModelsMap = trafficModelOptions.reduce(
    (result, next) => ({ ...result, [next.value]: next.label }),
    {},
  )
  const avoidOptions = [
    { label: 'Highways', value: 'highways' },
    { label: 'Tolls', value: 'tolls' },
    { label: 'Ferries', value: 'ferries' },
  ]

  const avoidsMap = avoidOptions.reduce(
    (result, next) => ({ ...result, [next.value]: next.label }),
    {},
  )

  return {
    trafficModelOptions,
    trafficModelsMap,
    avoidOptions,
    avoidsMap,
  }
}

export default {
  name: 'GoogleDirectionsConfigSubform',
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Object, default: () => ({}) },
    validators: { type: Object, default: () => ({}) },
    serverErrors: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showDetails: false,
    }
  },
  setup() {
    const { trafficModelOptions, avoidOptions } = useGoogleDirectionOptions()
    return { trafficModelOptions, avoidOptions }
  },
  computed: {
    rangeDescription() {
      const preference = this.modelValue?.rangePreference ?? 50
      if (preference <= 20) {
        return 'fast-moving traffic'
      } else if (preference <= 40) {
        return 'light traffic'
      } else if (preference <= 60) {
        return 'moderate traffic'
      } else if (preference <= 80) {
        return 'heavy traffic'
      } else {
        return 'peak traffic'
      }
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', { ...this.modelValue, ...value })
    },
  },
  components: {
    Collapsible,
  },
}
</script>

<template lang="pug">
.arrive-at-warning.text-warning
  i.fas.fa-exclamation-triangle
  span.ms-1.small GoogleMaps does not take traffic conditions into account while calculating travel duration based only on drop-off at or when the requested time is from the past.

.row.mt-2
  .col-12
    .d-flex.align-items-center.justify-content-start
      ea-checkbox(
        :modelValue="modelValue?.doubleCalculationForKnownDropoff"
        @update:modelValue="val => updateValue({ doubleCalculationForKnownDropoff: val })"
        name="double-calculation-for-known-dropoff"
      )
      //- label="Double calculation for guessing departures pickup time"
      label.form-check-label(for="double-calculation-for-known-dropoff") Double calculation for guessing departures pickup time:
        i.text-muted.ms-1.fa.fa-info-circle(
          v-tooltip.options="{ title: 'Travel duration for bookings with only drop-off time will be calculated by guessing pick-up time starting from [drop-off - 30 minutes]. <br><br>Example:<br> Booking drop-off time: 11:00<br>Guessing travel time for pick-up at 10:30 - have result 20 minutes<br>Guessing travel time for pick-up at 10:40 - have result 11:01<br>Assuming that final pick-up suitable for drop-off at 11:00 will be 10:39.', html: true, customClass: 'time-range-info-tooltip' }"
        )

.row.mt-2(v-if="!modelValue?.doubleCalculationForKnownDropoff")
  .col-md-6.col-sm-12
    .form-label.google-range-preference Offset for arrive at:
      i.text-muted.ms-1.fa.fa-info-circle(
        v-tooltip.options="{ title: 'Travel duration for bookings with only drop-off time will be calculated without traffic information. You can provide additional offset to compensate for the traffic.<br><br>Example:<br> Selected time range preference: <b>25%</b><br> Resolved time: <b>20</b> minutes <br><br> Calculation: 20 + (20 * 25%) = 25 <br> <b>Resulting time: 25 minutes.</b><br>Added 25% of resolved time (5min) to accommodate for traffic.', html: true, customClass: 'time-range-info-tooltip' }"
      )
    .d-flex.align-items-center.position-relative
      input.form-range.mb-2(
        :value="modelValue?.arriveByTrafficOffset ?? 0"
        @change="updateValue({ arriveByTrafficOffset: $event.target.value })"
        max="100"
        min="-100"
        step="5"
        type="range"
      )
      .time-range-preference-value.position-absolute.start-50
        span {{ modelValue?.arriveByTrafficOffset ?? 0 }}%
.row.mt-2
  .col-md-6.col-sm-12
    ea-select(
      :additional-errors="serverErrors?.trafficModel"
      :modelValue="modelValue?.trafficModel"
      :options="trafficModelOptions"
      :reduce="item => item.value"
      :validators="validators?.trafficModel"
      @update:modelValue="val => updateValue({ trafficModel: val })"
      label="Traffic model:"
      name="durationRule.startHour"
      placeholder="Best guess (default)"
    )
  .col-md-6.col-sm-12
    .form-label.google-range-preference Traffic prediction modifier:
      i.text-muted.ms-1.fa.fa-info-circle(
        v-tooltip.options="{ title: 'Travel time prediction comes as a range between fastest and slowest travel time. Choose how to calculate the resulting time.<br><br>Example:<br>Predicted travel time: <b>from 24 to 36</b> minutes. <br> Selected time range preference: <b>25%</b> <br><br> Calculation: 24 + (36 - 24) * 25% = 27 <br> <b>Resulting time: 27 minutes.</b>', html: true, customClass: 'time-range-info-tooltip' }"
      )
    .d-flex.align-items-center.position-relative
      input.form-range.mb-2(
        :value="modelValue?.rangePreference ?? 50"
        @change="updateValue({ rangePreference: $event.target.value })"
        max="200"
        min="0"
        step="5"
        type="range"
      )
      .time-range-preference-value.position-absolute.start-50
        span {{ modelValue?.rangePreference ?? 50 }}% ({{ rangeDescription }})
.row
  .col-md-6.col-sm-12
    ea-select(
      :additional-errors="serverErrors?.avoid"
      :modelValue="modelValue?.avoid"
      :options="avoidOptions"
      :reduce="item => item.value"
      :validators="validators?.avoid"
      @update:modelValue="val => updateValue({ avoid: val })"
      label="Avoid:"
      multiple
      name="durationRule.startHour"
    )

.row
  .col
    a(@click="showDetails = !showDetails")
      span {{ showDetails ? 'Hide details' : 'Show traffic model details' }}
  collapsible(:expand="showDetails")
    h5 Traffic model:
    p
      | Specifies the assumptions to use when calculating time in traffic. This
      | setting affects the predicted time in traffic based on historical
      | averages. The
      |
      code(dir="ltr" translate="no") traffic_model
      |
      | parameter may only be specified
      | for driving directions where the request includes a
      |
      code(dir="ltr" translate="no") departure_time
      | . The available values for this parameter are:

    ul
      li
        code(dir="ltr" translate="no") best_guess
        |
        | (default) indicates that the returned
        | duration_in_traffic should be the best estimate of travel time given
        | what is known about both historical traffic conditions and live traffic.
        | Live traffic becomes more important the closer the
        |
        code(dir="ltr" translate="no") departure_time
        |
        | is to now.
      li
        code(dir="ltr" translate="no") pessimistic
        |
        | indicates that the returned duration_in_traffic
        | should be longer than the actual travel time on most days, though
        | occasional days with particularly bad traffic conditions may exceed this value.
      li
        code(dir="ltr" translate="no") optimistic
        |
        | indicates that the returned duration_in_traffic
        | should be shorter than the actual travel time on most days, though
        | occasional days with particularly good traffic conditions may be faster
        | than this value.

    p
      | The default value of
      |
      code(dir="ltr" translate="no") best_guess
      |
      | will give the most useful
      | predictions for the vast majority of use cases. It is possible the
      |
      code(dir="ltr" translate="no") best_guess
      |
      | travel time prediction may be shorter than
      |
      code(dir="ltr" translate="no") optimistic
      | , or alternatively, longer than
      |
      code(dir="ltr" translate="no") pessimistic
      | , due to the way the
      |
      code(dir="ltr" translate="no") best_guess
      |
      | prediction model integrates live traffic information.
</template>
