<script>
export default {
  name: 'FormLabel',
  props: {
    label: { type: String, default: null },
    required: { type: Boolean, default: false },
  },
}
</script>

<template lang="pug">
label.form-label(v-if="label?.length || !!Object.keys($slots).includes('label-addon')")
  span.pe-1(v-if="required" v-tooltip="'Field is required'") *
  //- not sure if there is a single case with html label, but we use dynamic values for labels and it can be exploited
  span {{ label }}
  slot(name="label-addon")
</template>
