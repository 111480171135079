<script>
import { camelCase } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'DataTableFilterPresets',
  emits: ['update:filters', 'open', 'close', 'hidden'],
  props: {
    filters: {
      type: Object,
    },
    filtersConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      skipFiltersWatcher: false,
      timeoutToCancel: null,
    }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent']),
    ...mapGetters('session', { getEventOrganizationUser: 'eventOrganizationUser' }),
    eventOrganizationUser() {
      return this.getEventOrganizationUser(this.$route.params.eventSlug)
    },
    storedName() {
      return this.getParentNameIfTable(this.$parent)
    },
    anyPresetSaved() {
      return !!this.eouTableFilterConfigs.length
    },
    eouTableFilterConfigs() {
      return this.eventOrganizationUser?.filterConfig?.[this.storedName] || []
    },
    alphabeticalEouTableFilterConfigs() {
      return [...this.eouTableFilterConfigs].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
    },
    defaultGlobalTableFilterConfig() {
      return this.globalTableFilterConfigs.find(globalConfig => globalConfig.default === true)
    },
    globalTableFilterConfigs() {
      return this.currentEvent?.globalFilterConfig?.[this.storedName] || []
    },
    alphabeticalGlobalTableFilterConfigs() {
      return [...this.globalTableFilterConfigs].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
    },
    anyFilterConfigsPresent() {
      return !!(this.eouTableFilterConfigs.length || this.globalTableFilterConfigs.length)
    },
  },
  watch: {
    filters() {
      if (this.skipFiltersWatcher) {
        this.skipFiltersWatcher = false
      } else {
        this.$refs?.filtersItem?.deselect()
      }
    },
  },
  unmounted() {
    clearTimeout(this.timeoutToCancel)
  },
  methods: {
    updateFiltersFromInside(newVal) {
      clearTimeout(this.timeoutToCancel)
      this.skipFiltersWatcher = true
      this.$emit('update:filters', newVal)
      // let's introduce "fuse"
      this.timeoutToCancel = setTimeout(() => {
        this.skipFiltersWatcher = false
      }, 500)
    },
    getParentNameIfTable(comp, level = 0) {
      if (level > 10) {
        return null
      }
      if (comp.$options.name.endsWith('Table')) {
        return camelCase(comp.$options.name)
      }
      if (comp.$parent) {
        return this.getParentNameIfTable(comp.$parent, level + 1)
      }
      return null
    },
  },
}
</script>

<template lang="pug">
.data-table-filter-presets.btn-group.btn-group-sm
  data-table-filters(
    ref="filtersItem"
    :filters="filters"
    :filtersConfig="filtersConfig"
    :tableName="storedName"
    @update:filters="updateFiltersFromInside($event)"
    withPresets
  )
    template(#default="{ anyFilterSet }")
      .btn.btn-sm.fs-6.pb-0.pe-2.ps-2.pt-0(
        :class="{ 'btn-outline-warning': anyFilterSet, 'btn-outline-secondary': !anyFilterSet, 'filter-btn': anyFilterConfigsPresent }"
        name="filterButton"
      )
        i(:class="[anyFilterSet ? 'bi-funnel-fill' : 'bi-funnel']")
  .dropdown(v-if="anyFilterConfigsPresent")
    .btn.btn-outline-secondary.btn-sm.border-start-0.border-radius-tl-0.border-radius-bl-0.three-dots-button(
      data-bs-toggle="dropdown"
    )
      i.bi-three-dots-vertical

    ul.dropdown-menu.dropdown-menu-start
      div(v-if="eouTableFilterConfigs.length")
        li
          h6.dropdown-header.bold My configs
        li(v-for="filterConfig in alphabeticalEouTableFilterConfigs")
          a.dropdown-item(
            :name="filterConfig.name"
            @click="updateFiltersFromInside(filterConfig?.value)"
          )
            | {{ filterConfig.name }}
            span.ms-1.text-black-50.small(v-if="filterConfig.default") (user default)
        div(v-if="!!globalTableFilterConfigs.length")
          li
            hr.dropdown-divider
      div(v-if="!!globalTableFilterConfigs.length")
        li
          h6.dropdown-header.bold Global configs
        li(v-for="globalFilterConfig in alphabeticalGlobalTableFilterConfigs")
          a.dropdown-item(
            :name="globalFilterConfig.name"
            @click="updateFiltersFromInside(globalFilterConfig?.value)"
          )
            | {{ globalFilterConfig.name }}
            span.ms-1.text-black-50.small(v-if="globalFilterConfig.default") (global default)
</template>
