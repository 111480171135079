import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from "vue";
import ErebusLabel from "frontend/common/erebus-label.vue";
import RoleHasScopedDataModelsLabel from "frontend/roles/components/RoleHasScopedDataModelsLabelComponent.vue";
import {startCase} from 'lodash'
import {KINDS, ReportAasmState} from 'frontend/reports'
import ReportConfiguration from 'frontend/reports/report-configuration.vue'
import CollapsibleItems from 'frontend/common/collapsible-items.vue'

export const ReportDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'kind',
    'time_zone',
    'exportable_type',
    'progress',
    'aasm_state',
    'ready_at',
    'latest_file',
    'columns',
    'column_names',
    'email_interval',
    'start_sending_at',
    'stop_sending_at',
    'users_email',
  ],
  subscriptions: {
    items: 'ReportsChannel',
  },
  defaultColumns: [
    'eid',
    'name',
    'timeZone',
    'exportableType',
    'kind',
    'columns',
    'aasmState',
    'readyAt',
    'scheduleSettings',
    'emailRecipient',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    timeZone: {
      label: 'Used time zone',
      header: {
        sort: 'timeZone',
      },
      cell: {
        kind: 'simple',
        path: 'timeZone',
      },
    },
    exportableType: {
      label: 'Resource',
      header: {
        sort: 'exportableType',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'function',
        fn: item => startCase(item.exportableType),
      },
    },

    aasmState: {
      label: 'Current state',
      header: {
        sort: 'aasmState',
      },
      cell: {
        kind: 'component',
        component: markRaw(ReportAasmState),
        componentProps: item => ({ report: item }),
      },
    },

    scheduleSettings: {
      label: 'Schedule settings',
      cell: {
        class: 'small lh-sm',
        kind: 'component',
        component: markRaw(ReportConfiguration),
        componentProps: item => ({
          startSendingAt: item.startSendingAt,
          stopSendingAt: item.stopSendingAt,
          emailInterval: item.emailInterval,
        }),
      },
    },
    emailRecipient: {
      label: 'Recipients',
      cell: {
        class: 'small lh-sm',
        kind: 'component',
        component: markRaw(CollapsibleItems),
        componentProps: item => ({ items: item.usersEmail, placeholderText: 'empty' }),
      },
    },

    kind: {
      label: 'Kind',
      header: {
        sort: 'kind',
      },
      cell: {
        kind: 'function',
        fn: item => KINDS[item.kind],
      },
    },

    columns: {
      label: 'Columns',
      header: {
        style: {
          minWidth: '250px',
        },
      },
      cell: {
        kind: 'function',
        fn: () => 'loading...',
      },
    },

    readyAt: {
      label: 'Ready At',
      header: {
        sort: ['readyAt', 'createdAt'],
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.readyAt }),
      },
    },
  }
}