<template>
  <div class="empty-list-indicator">
    <div v-if="$slots.before" class="before">
      <slot name="before" />
    </div>
    <div class="title-description">
      <div v-if="$slots.title" class="title">
        <slot name="title">This list is empty</slot>
      </div>
      <div v-if="$slots.description" class="description">
        <slot name="description"></slot>
      </div>
    </div>
    <div v-if="$slots.after" class="after">
      <slot name="after" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.empty-list-indicator {
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 16px;
  text-align: center;
  font-size: 0.9rem;
}

.title {
  font-size: 1.15rem;
  font-weight: 700;
}
.title-description {
  display: grid;
  gap: 8px;
}
</style>
