<script>
export default {
  name: 'User',
  props: {
    user: {
      type: Object,
      default: null,
    },
    placeholderText: {
      type: String,
      default: 'empty',
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltip() {
      const emailPart = this.short ? '' : `(${this.user.email})`
      return ['User:', this.user.surname, this.user.name, emailPart]
        .filter(el => el?.length)
        .join(' ')
    },
  },
}
</script>

<template lang="pug">
span(v-tooltip.options="{ title: short && tooltip }")
  span(v-if="user?.surname?.length || user?.name?.length")
    strong(v-if="user?.surname?.length") {{ user.surname }}
    span(v-if="user?.name?.length") , {{ user.name }}
    span(v-if="user?.email?.length && !short") {{ ' ' }}({{ user.email }})
  span(v-else-if="user?.email") {{ user.email }}
  span.text-black-15(v-else) {{ placeholderText }}
</template>
