<script>
import { computed, provide } from 'vue'

import RuleGroupPreview from './components/rule-group-preview.vue'

export default {
  name: 'EaQueryPreview',
  props: {
    query: {
      type: Object,
    },
    searchCriteria: {
      type: Object,
    },
    breakWhitespace: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedSearchCriteria = computed(() => {
      return props.searchCriteria
    })
    provide('search-criteria', computedSearchCriteria)
  },
  computed: {
    mainGroup() {
      return this.query?.g?.[0]
    },
    criteriaCount() {
      return this.countCriteria(this.mainGroup)
    },
    mainStyle() {
      let result = ''
      if (this.breakWhitespace) {
        result += '--ea-query-preview-white-spaces: break-spaces;'
      } else {
        result += 'white-space: nowrap;'
      }
      return result
    },
  },
  methods: {
    countCriteria(group) {
      let sum = group?.c?.length || 0

      group?.g?.forEach(g => {
        sum += this.countCriteria(g)
      })

      return sum
    },
  },
  components: {
    RuleGroupPreview,
  },
}
</script>

<template lang="pug">
.ea-query-preview(:class="{ readonly: readonly }" :style="mainStyle")
  template(v-if="criteriaCount > 0 && mainGroup")
    rule-group-preview(:group="mainGroup" :root="true")
  span.text-black-15(v-else) No criteria
</template>

<style lang="scss" scoped>
.readonly {
  user-select: none;
  pointer-events: none;
}
</style>
