<script>
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'SetPassword',
  data: function () {
    return {
      errors: {},
      user: {
        password: null,
        passwordConfirmation: null,
        resetPasswordToken: null,
      },
      blocker: false,
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  methods: {
    setPassword(e) {
      e.preventDefault()
      this.v$.$touch()
      this.user.resetPasswordToken = this.$route.params.token

      if (Object.keys(this.errors).length > 0 || this.v$.$error) {
        this.$error({
          message: 'Fill visible fields correctly.',
        })
      } else {
        this.blocker = true
        this.axios({
          method: 'post',
          url: 'session/reset_password',
          data: this.user,
        })
          .then(response => {
            if (response.status == 200) {
              this.$success({ message: 'New password was successfully set' })
              this.$router.push('/session/sign-in')
            }
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.blocker = false
          })
      }
    },
    showErrorMessage(error) {
      let errorMessage
      if (!error.response) {
        errorMessage = 'Major set-password error occurred'
      } else if (error.response.data.errors) {
        errorMessage = Object.values(error.response.data.errors).join(', ')
      } else if (
        error.response.data.error &&
        error.response.data.error.constructor == ''.constructor
      ) {
        errorMessage = error.response.data.error
      } else {
        errorMessage = 'Error occurred.'
      }
      this.$error({ message: errorMessage })
    },
  },
}
</script>

<template lang="pug">
ea-spinner(v-if="blocker" :spinner-size="30" matchParent)
.d-flex.flex-column.position-relative.w-100.h-100
  .session-wrapper
    h3.pb-3.mb-0 Set password
    .thick-divider.mb-4
    form.sign-in-form(@submit="setPassword")
      .w-100
        ea-input#user_password(
          :additional-errors="errors.password"
          :validators="{ presence: null }"
          v-model="user.password"
          label="New Password"
          name="session.password"
          type="password"
        )
      .w-100.mb-4
        ea-input#user_password_confirmation(
          :additional-errors="errors.passwordConfirmation"
          :validators="{ presence: null }"
          v-model="user.passwordConfirmation"
          label="Password confirmation"
          name="session.passwordConfirmation"
          type="password"
        )
      .w-100.text-right
        submit-button.w-100 Set password
</template>
