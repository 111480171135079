<script>
import SingleDialog from './_single-dialog.vue'

const component = {
  name: 'Dialogs',
  data() {
    return {
      activeDialogs: [],
    }
  },
  mounted() {
    this.$eventHub.$on('open-dialog', this.newDialogCameIn)
  },
  unmounted() {
    this.$eventHub.$off('open-dialog', this.newDialogCameIn)
  },
  methods: {
    newDialogCameIn(dialogData) {
      this.activeDialogs.push(dialogData)
    },
    closeDialog(item, result) {
      const i = this.activeDialogs.indexOf(item)
      if (i != -1) {
        this.activeDialogs.splice(i, 1)
        if (result) {
          item.resolve(result)
        } else {
          item.reject(result)
        }
      }
    },
  },
  components: {
    SingleDialog,
  },
}

export default component

const buildPromise = () => {
  let reject
  let resolve
  const promise = new Promise((promiseResolve, promiseReject) => {
    resolve = promiseResolve
    reject = promiseReject
  })
  return { promise, resolve, reject }
}

export const dialogsGlobalMethods = eventHub => {
  return {
    confirm: (
      body = 'Are you sure you want perform the action?',
      title = 'Please confirm the action',
      confirmBtnText = 'YES',
      dismissBtnText = 'NO',
      size = 'md',
    ) => {
      const dialogData = Object.assign(buildPromise(), {
        kind: 'confirm',
        title,
        body,
        size,
        confirmBtnText,
        dismissBtnText,
      })
      eventHub.$emit('open-dialog', dialogData)
      return dialogData.promise
    },
    alert: (
      body = 'Please provide an alert body',
      title = 'Attention!',
      size = 'md',
      confirmBtnText = 'I understand',
    ) => {
      const dialogData = Object.assign(buildPromise(), {
        kind: 'alert',
        title,
        body,
        size,
        confirmBtnText,
      })
      eventHub.$emit('open-dialog', dialogData)
      return dialogData.promise
    },
    info: (
      body = 'Please provide an info body',
      title = 'Information',
      size = 'md',
      confirmBtnText = 'OK',
    ) => {
      const dialogData = Object.assign(buildPromise(), {
        kind: 'info',
        title,
        body,
        size,
        confirmBtnText,
      })
      eventHub.$emit('open-dialog', dialogData)
      return dialogData.promise
    },
  }
}
</script>

<template lang="pug">
template(v-for="dialogData in activeDialogs")
  single-dialog(v-bind="dialogData" @closed="closeDialog(dialogData, $event)")
</template>
