<script>
export default {
  name: 'SessionSSOMobileCallback',
  data() {
    return {
      blocker: true,
      organization: null,
      signedIn: false,
    }
  },
  created() {
    if (document.body?.attributes?.rw?.value?.length) {
      return
    }
    this.signIn()
  },
  methods: {
    signIn(retryOnCsrf = true) {
      this.axios({
        method: 'POST',
        url: 'session/sso_sign_in',
        params: {
          ...this.$route.query,
          organization_id: this.$route.params.organizationId,
          redirect_url: `${window.location.protocol}//${this.$mainDomain}/session/sso/mobile/${this.$route.params.organizationId}`,
        },
        headers: { 'Csrf-Retry': false },
      })
        .then(() => {
          this.$store
            .dispatch('session/getOrFetchUser')
            .then(() => {
              this.$success({ message: 'You have been successfully signed in.' })
              this.signedIn = true
              window.location.hash = 'close-me'
            })
            .catch(err => {
              console.error(err)
              this.$error({ message: "We can't sign you in, try again." })
              this.$router.push('/session/sign-in?mobile=true')
            })
        })
        .catch(error => {
          if (retryOnCsrf && error.response.status == 460) {
            this.axios({ method: 'get', url: '', headers: { 'Csrf-Retry': false } })
              .then(() => {
                // should have csrf token now
                this.signIn(false)
              })
              .catch(() => {
                this.blocker = false
                this.showErrorMessage(error)
              })
            return
          } else if (error.response.status == 495 && error.response.data.customDomain?.length) {
            console.warn('need to use custom domain before sign in!', error.response)
            window.location.href = window.location.href.replace(
              window.location.host,
              error.response.data.customDomain,
            )
            return
          } else {
            this.showErrorMessage(error)
            this.$router.push('/session/sign-in?mobile=true')
          }
        })
    },
    showErrorMessage(error) {
      console.error('sign in error', error?.response || error)
      let errorMessage
      if (!error.response) {
        errorMessage = 'Major sign-in error occurred'
      } else if (
        (error.response.data?.error || '').toLowerCase().indexOf('too many attempts') != -1
      ) {
        errorMessage = 'Too many failed attempts, account is temporarily blocked.'
      } else if (error.response.status == 401) {
        errorMessage = "We can't sign you in, try again."
      } else if (error.response.data.errors) {
        errorMessage = Object.values(error.response.data.errors).join(', ')
      } else if (
        error.response.data.error &&
        error.response.data.error.constructor == ''.constructor
      ) {
        errorMessage = error.response.data.error
      } else {
        errorMessage = "We can't sign you in, try again."
      }
      this.$error({ message: errorMessage })
    },
  },
}
</script>
<template lang="pug">
ea-spinner(v-if="blocker" :spinner-size="30" matchParent)
.d-flex.align-items-center.justify-content-center.flex-column(v-if="signedIn")
  div You have been successfully signed in!
  div You may close the window now
  //- a.btn.btn-outline-primary.mt-4(@click="closeWindow()") Close the window
</template>
