<script>
import { ref } from 'vue'

import MinMaxHelper from './_min-max-helper.vue'
import PureInput from './_pure-input.vue'
//@vue/component
export default {
  name: 'EaInput',
  inheritAttrs: false,
  emits: ['update:modelValue', 'blur', 'focus'],
  props: {
    hideInvalidIcon: {
      type: Boolean,
      default: false,
    },
    hideErrors: { type: Boolean, default: false },
    focusOnInit: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
      validator(val) {
        return [
          'email',
          'password',
          'text',
          'float',
          'integer',
          'tel',
          'color',
          'range',
          'time',
        ].includes(val)
      },
    },
    rangeMin: {
      type: Number,
      default: 0,
    },
    rangeMax: {
      type: Number,
      default: 0,
    },
    label: { type: String, default: null },
    modelValue: { type: [String, Number] },
    inputSize: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['sm', 'lg'].includes(val)
      },
    },
    showPasswordEye: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    textarea: { type: Boolean, default: false },
    textareaHeight: { type: [Number, String], default: null },
    resizeDirection: { type: String, default: 'none' },
    precision: {
      type: Number,
      default: null,
      validator(val) {
        return !val || (val >= 0 && val <= 6)
      },
    },
    inputTextAlign: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['start', 'center', 'end'].includes(val)
      },
    },
    inputClasses: {
      type: [String, Array, Object],
      default: '',
    },
    mask: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    hint: { type: String, default: null },
    inputAppend: { type: String, default: null },
    inputPrepend: { type: String, default: null },
    validators: { type: Object, default: null },
    autocomplete: { type: String, default: null },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
    skipDirtyCheck: { type: Boolean, default: false },
    id: { type: String, default: null },
  },
  setup() {
    return {
      formControlContainerRef: ref(null),
      pureInputRef: ref(null),
      showPassword: ref(false),
    }
  },
  computed: {
    inputType() {
      switch (this.type) {
        case 'integer':
        case 'float':
        case 'tel':
          return 'tel'
        default:
          return this.type
      }
    },
    passedSlots() {
      return Object.keys(this.$slots).filter(slot =>
        ['label-addon', 'input-append', 'input-prepend'].includes(slot),
      )
    },
    visibleValue() {
      return this.getVisibleValue(this.modelValue, this.type)
    },
  },
  mounted() {
    if (this.modelValue === undefined && !this.disabled) {
      this.$emit('update:modelValue', null)
    }
    this.touchMe = () => {
      if (this.formControlContainerRef?.v$) this.formControlContainerRef?.v$?.$touch()
    }
  },
  methods: {
    getVisibleValue(modelValue) {
      if (this.type == 'float' && !!modelValue) {
        let result = modelValue.toString().replace(',', '.').replace(/\s/g, '')
        if (this.precision && result.indexOf('.') != -1) {
          result = result.split('.')
          if (result[1].length > this.precision) {
            result[1] = result[1].slice(0, this.precision)
          }
          result = result.join('.')
        }
        return result
      } else if (this.type == 'integer' && !!modelValue) {
        return modelValue.toString().replace(/\s/g, '').split('.')[0]
      } else {
        return modelValue
      }
    },
    updateValue(inputValue) {
      let pureInput
      if (
        this.pureInputRef.$el.classList.contains('form-control') ||
        this.pureInputRef.$el.classList.contains('form-range')
      ) {
        pureInput = this.$refs.pureInputRef.$el
      } else {
        pureInput =
          this.$refs.pureInputRef.getElementsByClassName('form-control')[0] ||
          this.$refs.pureInputRef.getElementsByClassName('form-range')[0]
      }
      if (this.type == 'float') {
        let valueToPass = inputValue.toString().replace(',', '.').replace(/\s/g, '')
        if (valueToPass == '-' || valueToPass.endsWith('.')) {
          return
        }
        if (valueToPass.search(/^(-){0,1}[\d]+(\.){0,1}([\d]+){0,1}$/gi) == 0) {
          if (this.precision && this.precision > 0) {
            if (valueToPass.indexOf('.') != -1) {
              valueToPass = valueToPass.split('.')
              if (valueToPass[1].length > this.precision) {
                valueToPass[1] = valueToPass[1].slice(0, this.precision)
              }
              valueToPass = parseFloat(valueToPass.join('.'))
            }
          } else {
            valueToPass = parseFloat(valueToPass)
          }
        } else {
          valueToPass = parseFloat(valueToPass.replace(/[^\d|.|-]/gi, ''))
          if (Number.isNaN(valueToPass)) {
            valueToPass = null
          }
        }
        if (
          !valueToPass ||
          valueToPass.toString() != parseFloat(inputValue.toString().replace(',', '.')).toString()
        ) {
          pureInput.value = this.getVisibleValue(valueToPass)
        } else {
          pureInput.value = (pureInput.value || '').replace(',', '.')
        }
        this.$emit('update:modelValue', valueToPass)
      } else if (this.type == 'integer') {
        let valueToPass = parseInt(inputValue ? inputValue.toString().replace(/[^\d|-]/gi, '') : '')
        if (Number.isNaN(valueToPass)) {
          valueToPass = null
        }
        this.$emit('update:modelValue', valueToPass)
        if ((inputValue || '').toString().replace(/[^\d|-]/gi, '') != '-') {
          pureInput.value = this.getVisibleValue(valueToPass)
        }
      } else {
        this.$emit('update:modelValue', inputValue)
      }
      if (this.touchMe) {
        this.touchMe()
      }
    },
  },
  components: {
    PureInput,
    MinMaxHelper,
  },
}
</script>

<template lang="pug">
form-control-container(
  ref="formControlContainerRef"
  :additional-errors="additionalErrors"
  :autodirty="false"
  :hideErrors="hideErrors"
  :hideInvalidIcon="hideInvalidIcon"
  :hint="hint"
  :input-size="inputSize"
  :inputAppend="inputAppend"
  :inputPrepend="inputPrepend"
  :label="label"
  :name="name"
  :skipDirtyCheck="skipDirtyCheck"
  :validators="validators"
  :value="modelValue"
)
  template(v-if="showPasswordEye" #input-append)
    .input-group-append(v-tooltip="'Show/Hide password'")
      a.input-group-text.bg-white(@click="showPassword = !showPassword")
        i(:class="showPassword ? 'bi-eye' : 'bi-eye-slash'")
  template(v-for="slotName in passedSlots" v-slot:[slotName]="item")
    slot(v-bind="item" :name="slotName")

  template(#default)
    pure-input(
      ref="pureInputRef"
      :additionalClasses="inputClasses"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :focusOnInit="focusOnInit"
      :id="id"
      :input-size="inputSize"
      :input-text-align="inputTextAlign"
      :mask="mask"
      :model-value="visibleValue"
      :name="name"
      :placeholder="placeholder"
      :rangeMax="rangeMax"
      :rangeMin="rangeMin"
      :required="required"
      :resize-direction="resizeDirection"
      :textarea="textarea"
      :textarea-height="textareaHeight"
      :type="showPassword ? 'text' : inputType"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      @update:modelValue="updateValue"
    )

  template(#length-helpers)
    min-max-helper(
      :current-value="modelValue"
      :input-type="inputType"
      :precision="precision"
      :v="formControlContainerRef?.v$?.value"
      :validation="formControlContainerRef?.computedValidation?.value"
    )
</template>
