<script>
import PureInput from 'frontend/common/ea-form-controls/_pure-input.vue'
import { nextTick } from 'vue'

export default {
  name: 'LayoutSpotlightSearch',
  data() {
    return {
      spotlightInput: '',
    }
  },
  methods: {
    openSpotlight(event) {
      if (event.target.value && event.data) {
        this.$eventHub.$emit('open-spotlight', event.target.value)
        nextTick(() => {
          this.spotlightInput = ''
        })
      }
    },
    openTabletSpotlight() {
      this.$eventHub.$emit('open-spotlight')
    },
  },
  components: {
    PureInput,
  },
}
</script>
<template lang="pug">
.header-search-wrapper
  pure-input(
    v-model="spotlightInput"
    @input="openSpotlight($event)"
    inputSize="sm"
    name="spotlight-search"
    placeholder="Search"
  )
.header-search-wrapper-small-and-medium-devices
  i.fas.fa-search(@click="openTabletSpotlight()")
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/_variables.scss';
.header-search-wrapper {
  position: relative;
  margin-right: 1rem;
  display: block;

  @include for-size(small-and-medium-devices) {
    display: none;
  }

  &:after {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    color: $darker-gray;
    content: 'Ctrl + /';
    border: 2px solid $gray;
    border-radius: $btn-border-radius-xs;
  }
}

.header-search-wrapper-small-and-medium-devices {
  display: none;
  margin: 0 1rem 0rem 0.5rem;

  @include for-size(small-and-medium-devices) {
    display: block;
  }
}

i {
  display: block;
  font-size: 1.5rem;
}
</style>
