<script>
export default {
  name: 'Tile',
  props: {
    item: Object,
  },
  computed: {
    footerItemsPresent() {
      return this.footerItems.length > 0
    },
  },
}
</script>

<template lang="pug">
.tile-wrapper
  .tile
    .tile-body
      slot
</template>
