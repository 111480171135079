<script>
import latinize from 'latinize'
import { provide, ref } from 'vue'

export default {
  name: 'SystemNotificationsSimpleTable',
  props: {
    values: {
      type: Array,
      required: true,
    },
    columnName: {
      type: String,
      default: 'Value',
    },
  },
  data() {
    return {
      filters: {},
    }
  },
  setup() {
    const searchPerformed = ref(false)
    provide('searchPerformed', searchPerformed)
    return {
      searchPerformed,
    }
  },
  computed: {
    filteredValues() {
      let result = this.values || []
      if (this.filters?.valueStart?.length) {
        const searchValue = latinize(this.filters.valueStart).toLowerCase()
        result = result.filter(el => latinize(el.toString()).toLowerCase().startsWith(searchValue))
      }
      if (this.filters?.valueEnd?.length) {
        const searchValue = latinize(this.filters.valueEnd).toLowerCase()
        result = result.filter(el => latinize(el.toString()).toLowerCase().endsWith(searchValue))
      }
      if (this.filters?.valueCont?.length) {
        const searchValue = latinize(this.filters.valueCont).toLowerCase()
        result = result.filter(
          el => latinize(el.toString()).toLowerCase().indexOf(searchValue) != -1,
        )
      }

      return result
    },
  },
  watch: {
    filters() {
      if (
        this.filters?.valueStart?.length ||
        this.filters?.valueEnd?.length ||
        this.filters?.valueCont?.length
      ) {
        this.searchPerformed = true
      } else {
        this.searchPerformed = false
      }
    },
  },
}
</script>
<template lang="pug">
div
  responsive-data-table.disable-sticky-actions(ref="tableScroller")
    data-table
      thead.align-middle
        tr
          data-table-header-cell(:title="columnName")
            ea-filter(
              :kinds="['start', 'end', 'cont']"
              v-model:filters="filters"
              attribute="value"
            )
            //- @update:filters="fetchItems()"
      data-table-tbody
        tr(v-for="value in filteredValues" :key="value")
          td.w-100 {{ value }}
</template>
