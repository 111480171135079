import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {Cluster} from 'frontend/clusters'
import ShuttleServicePoint from 'frontend/shuttle-services/shuttle-service-point.vue'
import {Osl} from 'frontend/osls'
import {VehicleType} from 'frontend/vehicle-types'
import {ClientGroup} from 'frontend/client-groups'
import DaysOfService from 'frontend/shuttle-services/shuttle-service-days-of-service.vue'
import FileUrl from 'frontend/common/file-url.vue'
import ImportStatus from 'frontend/common/import-status.vue'
import {STATES} from 'frontend/shuttle-service-imports'
import User from 'frontend/users/user.vue'

export const ShuttleServiceImportDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'file_url',
    'file_name',
    'aasm_state',
    'finished_at',
    'finished_with_errors_at',
    'major_error_at',
    'progress',
    'created_at',
    'updated_at',
    { created_by: ['id', 'email', 'name', 'surname'] },
  ],
  subscriptions: {
    items: 'ShuttleServiceImportsChannel',
    users: 'CreatedByChannel',
  },
  defaultColumns: [
    'eid',
    'fileName',
    'importStatus',
    'createdBy',
    'createdDatetime'
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    fileName: {
      label: 'File',
      header: {
        sort: 'file',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: markRaw(FileUrl),
        componentProps: item => ({
          label: item.fileName,
          url: item.fileUrl,
        }),
      },
    },
    importStatus: {
      label: 'Import status',
      header: {
        sort: 'aasmState',
      },
      cell: {
        kind: 'component',
        component: markRaw(ImportStatus),
        componentProps: item => ({
          importStatus: item,
          states: STATES,
        }),
      },
    },
    createdBy: {
      label: 'Created by',
      header: {
        sort: 'createdByUsername',
      },
      cell: {
        kind: 'component',
        component: markRaw(User),
        componentProps: item => ({
          user: item.createdBy,
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}