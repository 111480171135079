<script>
import { mapGetters, mapMutations } from 'vuex'

import SingleToast from './_single-toast.vue'

export default {
  name: 'ToastRoot',
  computed: {
    ...mapGetters('toast', ['toasts']),
  },
  methods: {
    ...mapMutations('toast', ['destroyToast']),
  },
  components: {
    SingleToast: SingleToast,
  },
}
</script>

<template lang="pug">
.toast-container
  single-toast(
    v-for="toast in toasts"
    :autohide="toast.autohide"
    :id="toast.id"
    :key="toast.id"
    :message="toast.message"
    :showSpinner="toast.showSpinner"
    :stay="toast.stay"
    :title="toast.title"
    :type="toast.type"
    @destroy="destroyToast"
  )
</template>

<style scoped lang="scss">
.toast-container {
  // display: flex;
  // flex-direction: column-reverse;
  // bottom: 30px;
  position: fixed;
  right: 0.7rem;
  top: calc(53px + 2rem);
  // top: 48px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10000;
  max-height: calc(100vh - 1.4rem - 38px);
  pointer-events: all;
}
</style>
