<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'TransportationPoint',
  props: {
    transportationPoint: { type: Object },
    disabled: { type: Boolean, default: false },
    nowrap: { type: Boolean, default: false },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
span(v-if="transportationPoint" :class="{ 'd-block text-nowrap': nowrap }")
  modal-button(
    :disabled="disabled"
    :item="transportationPoint"
    :modal="previewModals.TransportationPoint"
  )
    a(:class="{ 'text-reset': disabled, 'text-decoration-none': disabled }") {{ transportationPoint.name }}
span.text-black-15(v-else) empty
</template>
