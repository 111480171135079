import { defineAsyncComponent } from 'vue'

const EventOrganizationUser = defineAsyncComponent(() => import('./event-organization-user.vue'))
const EventOrganizationUsersIndex = defineAsyncComponent(() =>
  import('./event-organization-users-index.vue'),
)
const EventOrganizationUsersSelect = defineAsyncComponent(() =>
  import('./event-organization-users-select.vue'),
)
const EventOrganizationUsersTable = defineAsyncComponent(() =>
  import('./event-organization-users-table.vue'),
)

const EventOrganizationUserRolePreview = defineAsyncComponent(() =>
  import('./_event-organization-user-role-preview.vue'),
)

export {
  EventOrganizationUser,
  EventOrganizationUserRolePreview,
  EventOrganizationUsersIndex,
  EventOrganizationUsersSelect,
  EventOrganizationUsersTable,
}
