import {computed, onMounted, ref} from 'vue'

export function useSmoothVisualLoadingDebouncer(
  conditionFn: () => boolean,
  timeoutValue: number = 250
) {
  const hasInitialTimeoutPassed = ref(false)

  const condition = computed(conditionFn)

  const hasLoadedWithDebounce = computed(() => {
    return hasInitialTimeoutPassed.value && condition.value
  })

  onMounted(() => {
    if(condition.value) {
      hasInitialTimeoutPassed.value = true
      return
    }
    setTimeout(() => {
      hasInitialTimeoutPassed.value = true
    }, timeoutValue)
  })

  return {
    hasLoadedWithDebounce,
  }
}