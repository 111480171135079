<script>
export default {
  name: 'ShiftInclusiveCheckInOut',
  props: {
    shift: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-start
  true-false-label(:item="shift.computedInclusiveCheckInOut")
  .ms-2(v-if="[true, false].includes(shift.overriddenInclusiveCheckInOut)") overridden
  .ms-2.text-black-30(v-else) default
</template>
