<script>
import { computed, inject, onBeforeUnmount, ref, shallowRef, watch } from 'vue'

import { useFifaGisMap } from '../use-fifa-gis-map'

export default {
  name: 'FifaGisPolygon',
  props: {
    polygon: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const fifaGisMap = inject('fifaGisMap', ref(null))
    const fifaGisMapLoaded = inject('fifaGisMapLoaded', ref(false))

    const { preparePolygon } = useFifaGisMap()

    const instance = shallowRef(null)

    const isReady = computed(() => fifaGisMap.value && fifaGisMapLoaded.value)

    watch(
      [isReady, () => props.polygon],
      () => {
        if (!isReady.value) return

        if (!props.polygon?.type === 'Polygon' && instance.value) {
          instance.value.dispose()
        }

        if (instance.value) {
          instance.value.update(preparePolygon(props.polygon))
        } else {
          instance.value = fifaGisMap.value.addPolygon(preparePolygon(props.polygon))
        }
      },
      { immediate: true },
    )

    onBeforeUnmount(() => {
      if (instance.value) {
        instance.value.dispose()
        fifaGisMap.value.removePolygon(instance.value)
      }
    })

    return {
      fifaGisMap,
      fifaGisMapLoaded,
    }
  },
}
</script>
<template lang="pug"></template>
