<script>
export default {
  name: 'DataTable',
}
</script>

<template lang="pug">
table.table.table-striped.table-hover.table-sm.small
  slot
</template>
