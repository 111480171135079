<script>
export default {
  name: 'InputGroup',
  props: {
    inputAppend: { type: String, default: null },
    hint: { type: String, default: null },
    inputPrepend: { type: String, default: null },
    inputSize: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['sm', 'lg'].includes(val)
      },
    },
  },
  computed: {
    groupSizeClass() {
      return this.inputSize ? `input-group-${this.inputSize}` : ''
    },
    isTherePrependOrAppend() {
      return (
        (this.inputAppend && this.inputAppend.length) ||
        (this.hint && this.hint.length) ||
        (this.inputPrepend && this.inputPrepend.length) ||
        Object.keys(this.$slots).includes('input-prepend') ||
        Object.keys(this.$slots).includes('input-append')
      )
    },
  },
}
</script>

<template lang="pug">
div(:class="{ 'input-group': isTherePrependOrAppend }")
  .input-group-prepend(v-if="hint && hint.length" :class="groupSizeClass")
    .input-group-text(v-tooltip.options="{ title: $globals.newlinesToBr(hint), html: true }")
      i.bi-info-lg
  .input-group-prepend(v-if="inputPrepend && inputPrepend.length" :class="groupSizeClass")
    .input-group-text {{ inputPrepend }}
  template(v-if="Object.keys($slots).includes('input-prepend')" :class="groupSizeClass")
    slot(name="input-prepend")

  .flex-grow-1.input-group-main-item
    slot(name="default")

  .input-group-append(v-if="inputAppend && inputAppend.length" :class="groupSizeClass")
    .input-group-text {{ inputAppend }}
  template(v-if="Object.keys($slots).includes('input-append')" :class="groupSizeClass")
    slot(name="input-append")
</template>
