<script>
import ErebusLabel from 'frontend/common/erebus-label.vue'

export default {
  name: 'EventOrganizationUserRolePreview',
  props: {
    item: { type: Object, default: () => ({}) },
  },
  components: {
    ErebusLabel,
  },
}
</script>

<template lang="pug">
erebus-label(:item="{ backgroundColor: item.role?.color, name: item.role?.name }")
.mt-1(v-if="item.hasPersonalizedRole")
  erebus-label(:item="{ backgroundColor: '#000000', name: 'Personalized' }")
</template>
