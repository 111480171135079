<script>
export default {
  name: 'TransportBookingChanged',
  props: {
    transportBooking: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
template(
  v-if="!transportBooking.originalAttributesChangeSeen && transportBooking.originalAttributesChanged"
)
  true-false-label(:item="true" revertColors)
</template>
