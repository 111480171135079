import _axios from 'axios'
import { globalInterceptors } from 'frontend/_config/axios-interceptors'

import { applyConverters } from './case-converter'

const createAxiosInstance = (
  {
    baseURL = '/api',
    xsrfCookieName = 'CSRF-TOKEN',
    xsrfHeaderName = 'X-CSRF-Token',
    withCredentials = true,
    applyInterceptors = [globalInterceptors],
    converters = applyConverters,
  } = {
    baseURL: '/api',
    xsrfCookieName: 'CSRF-TOKEN',
    xsrfHeaderName: 'X-CSRF-Token',
    withCredentials: true,
    applyInterceptors: [globalInterceptors],
    converters: applyConverters,
  },
) => {
  const ax = _axios.create({ baseURL, xsrfCookieName, xsrfHeaderName, withCredentials })
  converters(ax)
  applyInterceptors.forEach(intercept => intercept(ax))
  return ax
}
const axios = createAxiosInstance()

export { createAxiosInstance }

export default axios
