import { defineAsyncComponent } from 'vue'

const Client = defineAsyncComponent(() => import('./client.vue'))
const ClientFormModal = defineAsyncComponent(() => import('./client-form-modal.vue'))
const ClientModal = defineAsyncComponent(() => import('./client-modal.vue'))
const ClientsIndex = defineAsyncComponent(() => import('./clients-index.vue'))
const ClientsSelect = defineAsyncComponent(() => import('./clients-select.vue'))
const ClientsTable = defineAsyncComponent(() => import('./clients-table.vue'))
const ClientCommunicationBlacklist = defineAsyncComponent(() =>
  import('./client-communication-blacklist.vue'),
)

export {
  Client,
  ClientCommunicationBlacklist,
  ClientFormModal,
  ClientModal,
  ClientsIndex,
  ClientsSelect,
  ClientsTable,
}
