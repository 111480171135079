<script>
export default {
  name: 'FormGroup',
  props: {
    invalid: { type: Boolean, default: false },
    dirty: { type: Boolean, default: false },
    hideInvalidIcon: { type: Boolean, default: false },
  },
}
</script>

<template lang="pug">
.form-group.mb-1(
  :class="{ 'has-error': invalid, 'no-validation-icon': hideInvalidIcon, 'has-success was-validated': !invalid && dirty }"
)
  slot
</template>
