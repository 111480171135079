import { Container } from 'inversify'
import { Ref } from 'vue'
import { RouteLocationNormalizedLoaded, useRouter } from 'vue-router'

import { createAxiosInstance } from '../../../_config/axios'
import { IApiProvider } from '../../../apiProviders/types/IApiProvider'
import { InterfaceType } from '../constants/InterfaceType'

export function bindConstants(container: Container): void {
  const router = useRouter()

  container
    .bind<IApiProvider>(InterfaceType.constants.ApiProvider)
    .toConstantValue(createAxiosInstance())
  container
    .bind<Ref<RouteLocationNormalizedLoaded>>(InterfaceType.constants.CurrentRoute)
    .toConstantValue(router.currentRoute)
}
