<script>
import { useBlocker } from 'frontend/common'
import { useMapProviders } from 'frontend/common/maps/map-providers'
import { useModals } from 'frontend/common/modals'
import { useTimeZonePresenter } from 'frontend/common/time-zone-presenter'
import { useValidatedForm } from 'frontend/common/use-validated-form'
import { LocationsSelect } from 'frontend/locations'
import { TransportationPointsSelect } from 'frontend/transportation-points'
import { TransportationPointsService } from 'frontend/transportation-points/transportation-points-service'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import DurationRuleConfig from '../duration-rule-config.vue'
import { DurationRulesService } from '../duration-rules-service'
import GisProviderDirectionsConfig from '../gis-provider-directions-config.vue'
import FifaGisDurationPreview from './fifa-gis-duration-preview.vue'
import GoogleMapsDurationPreview from './google-maps-duration-preview.vue'
import QmicDurationPreview from './qmic-duration-preview.vue'
import TomTomDurationPreview from './tom-tom-duration-preview.vue'

const DURATION_RULE_FIELDS = [
  'id',
  'days_of_week',
  'default_gis_provider',
  'end_at',
  'end_hour',
  'end_minute',
  'gis_provider_config',
  'kind',
  'resolve_with',
  'start_at',
  'start_hour',
  'start_minute',
  'travel_duration',
  'provider_api_key',
  {
    from_location: [
      'id',
      'name',
      'time_zone',
      'latitude',
      'longitude',
      { cluster: ['id', 'name', 'time_zone'] },
    ],
  },
  {
    to_location: [
      'id',
      'name',
      'time_zone',
      'latitude',
      'longitude',
      { cluster: ['id', 'name', 'time_zone'] },
    ],
  },
  {
    from_transportation_point: [
      'id',
      'name',
      'latitude',
      'longitude',
      { location: ['id', 'time_zone'] },
    ],
  },
  {
    to_transportation_point: [
      'id',
      'name',
      'latitude',
      'longitude',
      { location: ['id', 'time_zone'] },
    ],
  },
]

export default {
  name: 'TravelDurationPreviewPopoverContent',
  emits: ['closed'],
  props: {
    item: { type: Object },
    size: String,
  },
  setup() {
    const { blocker, isBlocked } = useBlocker()

    const fromLocation = ref(null)
    const fromTransportationPoint = ref(null)
    const toLocation = ref(null)
    const toTransportationPoint = ref(null)

    const fromTransportationPoints = ref([])
    const toTransportationPoints = ref([])

    const resolvePerformed = ref(false)
    const resolvedRule = ref(null)
    const resolvedDuration = ref(null)

    const showRulePreview = ref(false)

    const timeAt = ref(null)
    const providedStamp = ref('departureAt')

    const durationRulesService = new DurationRulesService({ blocker })
    const transportationPointsService = new TransportationPointsService({ blocker })

    const fromLocationTimeZone = computed(() => fromLocation.value?.timeZone)
    const toLocationTimeZone = computed(() => toLocation.value?.timeZone)

    const $store = useStore()
    const currentEvent = computed(() => $store.getters['currentContext/currentEvent'])

    const eventDurationRuleGisProvider = computed(() => {
      return currentEvent.value.durationRulesGisProvider
    })

    const eventDurationRuleGisConfig = computed(() => {
      return currentEvent.value.durationRulesGisConfig
    })

    const { getProviderConfig } = useMapProviders({
      initialProvider: eventDurationRuleGisProvider.value,
      only: [eventDurationRuleGisProvider.value],
    })

    const previewRuleParams = computed(() => {
      return {
        id: resolvedRule.value?.id,
        toLocationId: toTransportationPoint.value?.id ? null : toLocation.value?.id,
        fromLocationId: fromTransportationPoint.value?.id ? null : fromLocation.value?.id,
        toTransportationPointId: toTransportationPoint.value?.id,
        fromTransportationPointId: fromTransportationPoint.value?.id,
        apiKey: resolvedRule.value
          ? resolvedRule.value.providerApiKey
          : getProviderConfig(eventDurationRuleGisProvider.value)?.apiKey,
        [providedStamp.value]: timeAt.value,
      }
    })

    watch(
      [
        toLocation,
        fromLocation,
        toTransportationPoint,
        fromTransportationPoint,
        providedStamp,
        timeAt,
      ],
      () => {
        resolvedRule.value = null
        resolvePerformed.value = false
        showRulePreview.value = false
      },
    )

    return {
      durationRulesService,
      eventDurationRuleGisProvider,
      eventDurationRuleGisConfig,
      fromLocation,
      fromTransportationPoint,
      fromTransportationPoints,
      resolvedDuration,
      resolvedRule,
      resolvePerformed,
      showRulePreview,
      timeAt,
      toLocation,
      toTransportationPoint,
      toTransportationPoints,
      transportationPointsService,
      providedStamp,
      previewRuleParams,
      ...useModals(),
      ...useValidatedForm({ service: null, blocker }),
      isBlocked,
      ...useTimeZonePresenter(
        previewRuleParams,
        'departure',
        'fromLocationId',
        'Location',
        null,
        fromLocationTimeZone,
      ),
      ...useTimeZonePresenter(
        previewRuleParams,
        'arrival',
        'toLocationId',
        'Location',
        null,
        toLocationTimeZone,
      ),
    }
  },
  computed: {
    routeIsShown() {
      return this.showRulePreview && this.resolvedGisProvider
    },
    from() {
      return this.fromTransportationPoint ?? this.fromLocation
    },
    to() {
      return this.toTransportationPoint ?? this.toLocation
    },
    noFromTransportationPoints() {
      return this.fromLocation && this.fromTransportationPoints?.length === 0
    },
    noToTransportationPoints() {
      return this.toLocation && this.toTransportationPoints?.length === 0
    },
    resolvedGisProvider() {
      return this.resolvedRule?.defaultGisProvider || this.eventDurationRuleGisProvider
    },
    timeZone() {
      if (this.providedStamp === 'departureAt') {
        return this.departureTimeZone
      } else {
        return this.arrivalTimeZone
      }
    },
    timeZoneNotMine() {
      if (this.providedStamp === 'departureAt') {
        return this.departureTimeZoneNotMine
      } else {
        return this.arrivalTimeZoneNotMine
      }
    },
  },
  mounted() {
    if (this.item?.id) {
      this.fetchDurationRule(this.item.id)
    }
  },
  methods: {
    emitCurrentResult() {
      this.$emit('closed', this.result)
    },
    async fetchDurationRule(durationRuleId) {
      try {
        const data = await this.durationRulesService.show(durationRuleId, {
          data: { fields: DURATION_RULE_FIELDS },
        })

        if (data) {
          this.fromLocation = data.fromLocation ?? data.fromTransportationPoint?.location
          this.fromTransportationPoint = data.fromTransportationPoint
          this.toLocation = data.toLocation ?? data.toTransportationPoint?.location
          this.toTransportationPoint = data.toTransportationPoint
          if (data.fromTransportationPoint) {
            this.onFromLocationChange(this.fromLocation)
          }
          if (data.toTransportationPoint) {
            this.onToLocationChange(this.toLocation)
          }
        }
      } catch (error) {
        console.error(error)
        this.$error({ message: 'Could not fetch duration rule.' })
      }
    },
    clearForm() {
      this.fromLocation = null
      this.fromTransportationPoint = null
      this.fromTransportationPoints = []
      this.toLocation = null
      this.toTransportationPoint = null
      this.toTransportationPoints = []
      this.resolvePerformed = false
      this.resolvedRule = null
      this.resolvedDuration = null
      this.showRulePreview = false
      this.timeAt = null
      this.providedStamp = 'departureAt'
      this.$nextTick(() => {
        this.v$.$reset()
      })
    },
    async resolveRule() {
      if (!this.isFormValid()) return
      this.showRulePreview = false
      this.serverErrors = null

      try {
        const response = await this.durationRulesService.resolveRule({
          ...this.previewRuleParams,
          fields: DURATION_RULE_FIELDS,
        })
        this.resolvePerformed = true

        if (response.status === 204) {
          this.resolvedRule = null
        } else {
          this.resolvedRule = response.data
        }
        this.previewRule()
      } catch (error) {
        console.error(error)
        this.$error({ message: 'Could not resolve duration rule.' })
        this.serverErrors = error?.data?.errors
      }
    },
    async fetchTransportationPoints(locationId) {
      const response = await this.transportationPointsService.index({
        data: {
          locationIdEq: locationId,
          normalized: false,
          fields: ['id', 'name'],
          forDr: true,
        },
      })

      return response?.contents ?? []
    },
    async onFromLocationChange(location) {
      if (location != this.fromLocation) {
        this.fromLocation = location
        this.fromTransportationPoint = null
      }

      if (location?.id) {
        this.fromTransportationPoints = await this.fetchTransportationPoints(location.id)
      } else {
        this.fromTransportationPoints = []
        this.fromTransportationPoint = null
      }
    },
    onFromLocationClear(value) {
      if (value == null) {
        this.fromLocation = null
        this.fromTransportationPoint = null
        this.fromTransportationPoints = null
      }
    },
    async onToLocationChange(location) {
      if (location != this.toLocation) {
        this.toTransportationPoint = null
        this.toLocation = location
      }

      if (location?.id) {
        this.toTransportationPoints = await this.fetchTransportationPoints(location.id)
      } else {
        this.toTransportationPoints = []
        this.toTransportationPoint = null
      }
    },
    onToLocationClear(value) {
      if (value == null) {
        this.toLocation = null
        this.toTransportationPoint = null
        this.toTransportationPoints = null
      }
    },
    previewRule() {
      this.showRulePreview = true
    },
  },
  components: {
    DurationRuleConfig,
    FifaGisDurationPreview,
    GisProviderDirectionsConfig,
    GoogleMapsDurationPreview,
    LocationsSelect,
    QmicDurationPreview,
    TomTomDurationPreview,
    TransportationPointsSelect,
  },
}
</script>

<template lang="pug">
.travel-duration-preview-popover-content(
  :style="{ overflowY: routeIsShown ? 'auto' : 'visible', overflowX: routeIsShown ? 'hidden' : 'visible' }"
)
  form.container(autocomplete="oh-google-overlord-pls-dont-whisper-to-my-ear")
    .d-flex.align-items-start.justify-content-between.position-relative
      div(
        style="width: calc(50% - 18px); min-width: calc(50% - 18px); max-width: calc(50% - 18px)"
      )
        label.bold From:
        locations-select.select-nowrap(
          :additional-errors="serverErrors?.fromLocationId"
          :modelValue="fromLocation?.id"
          :validators="{ presence: null }"
          @option:selected="onFromLocationChange"
          @update:modelValue="onFromLocationClear"
          forDr
          label="Location"
          name="fromLocation"
          transferable
        )
        transportation-points-select.select-nowrap(
          :additional-errors="serverErrors?.fromTransportationPointId"
          :collection="fromTransportationPoints"
          :disabled="!fromLocation || noFromTransportationPoints"
          :modelValue="fromTransportationPoint?.id"
          :placeholder="noFromTransportationPoints ? 'No transportation points' : ''"
          @option:selected="fromTransportationPoint = $event"
          @update:modelValue="v => !v && (fromTransportationPoint = null)"
          forDr
          label="Transportation point"
          name="fromTransportationPoint"
        )
      .px-2
        i.fa.fa-long-arrow-alt-right.fs-5(style="padding-top: 3.3rem")
      div(
        style="width: calc(50% - 18px); min-width: calc(50% - 18px); max-width: calc(50% - 18px)"
      )
        label.bold To:
        locations-select.select-nowrap(
          :additional-errors="serverErrors?.toLocationId"
          :modelValue="toLocation?.id"
          :validators="{ presence: null }"
          @option:selected="onToLocationChange"
          @update:modelValue="onToLocationClear"
          forDr
          label="Location"
          name="toLocation"
          transferable
        )
        transportation-points-select.select-nowrap(
          :additional-errors="serverErrors?.toTransportationPointId"
          :collection="toTransportationPoints"
          :disabled="!toLocation || noToTransportationPoints"
          :modelValue="toTransportationPoint?.id"
          :placeholder="noToTransportationPoints ? 'No transportation points' : ''"
          @option:selected="toTransportationPoint = $event"
          @update:modelValue="v => !v && (toTransportationPoint = null)"
          forDr
          label="Transportation point"
          name="toTransportationPoint"
        )
    .row
      .col
        separated-datetimepicker(
          :clearable="false"
          :timeZone="timeZone"
          :timeZoneNotMine="timeZoneNotMine"
          :validators="{ presence: null }"
          v-model="timeAt"
          label="Time:"
          name="timeAt"
        )
      .col-md-4.col-sm-12
        .mt-4
          ea-chooser(
            :options="[ { label: 'Pick-up', value: 'departureAt' }, { label: 'Drop-off', value: 'arrivalAt' }, ]"
            v-model="providedStamp"
            name="requestedTimeChooser"
            stretch
          )
    .d-flex.align-items-start.small.justify-content-start
      .flex-grow-1
        div(v-if="resolvedRule")
          .border.rounded.d-inline-block.px-1
            template(
              v-if="resolvedRule.fromTransportationPoint?.id !== fromTransportationPoint?.id && resolvedRule.toTransportationPoint?.id !== toTransportationPoint?.id"
            )
              .mt-1.text-warning No rules for direct connection. <b>Origin</b> and <b>destination</b> were derived from locations.
              .mb-1 Resolved rule from <b>{{ fromLocation.name }}</b> to <b>{{ toLocation.name }}</b>
            template(
              v-else-if="resolvedRule.fromTransportationPoint?.id !== fromTransportationPoint?.id"
            )
              .text-warning.mt-1 No rules for direct connection. <b>Origin</b> was derived from location.
              .mb-1 Resolved rule from <b>{{ fromLocation.name }}</b> to <b>{{ resolvedRule.toTransportationPoint?.name }}</b>
            template(
              v-else-if="resolvedRule.toTransportationPoint?.id !== toTransportationPoint?.id"
            )
              .text-warning.mt-1 No rules for direct connection. <b>Destination</b> was derived from location.
              .mt-1 Resolved rule from <b>{{ from.name }}</b> to <b>{{ toLocation.name }}</b>
            template(v-else-if="resolvedRule")
              .mt-1.mb-1 Resolved direct connection from <b>{{ from?.name }}</b> to <b>{{ to?.name }}</b>:

            .d-flex.align-items-center.justify-content-between
              .me-2
                .duration-rule-kind-badge(:class="resolvedRule.kind" v-tooltip="resolvedRule.kind") {{ resolvedRule.kind }}
              .me-2
                duration-rule-config(
                  :fromTimeZone="fromLocation.timeZone"
                  :rule="resolvedRule"
                  :toTimeZone="toLocation.timeZone"
                )
              div
                template(v-if="resolvedRule.defaultGisProvider")
                  gis-provider-directions-config(
                    :config="resolvedRule.gisProviderConfig"
                    :provider="resolvedRule.defaultGisProvider"
                  )
                template(v-else-if="resolvedRule.travelDuration")
                  .d-flex.align-items-baseline
                    .small.text-muted Fixed time:
                    .bold.ms-1 {{ resolvedRule.travelDuration }}m

        div(v-else-if="resolvePerformed")
          .border.rounded.d-inline-block.px-1
            .my-1.text-warning <b>No rules for direct connection.</b> Using default rule for the Event instead:

            .d-flex.align-items-center.justify-content-start
              .me-2
                .duration-rule-kind-badge.event-default Event default
              div
                gis-provider-directions-config(
                  v-if="eventDurationRuleGisProvider"
                  :config="eventDurationRuleGisConfig"
                  :provider="eventDurationRuleGisProvider"
                )
                .text-danger(v-else-if="!resolvedGisProvider") There is no default GIS provider configured for the event

      .text-end(style="width: 90px")
        button.btn.btn-sm.btn-info.mb-1(@click="resolveRule" style="width: 90px" type="button") Resolve
        button.btn.btn-sm.btn-outline-danger(@click="clearForm" style="width: 90px" type="button") Clear

    .small.pt-1(v-if="routeIsShown")
      template(v-if="resolvedGisProvider === 'GOOGLE_MAPS'")
        google-maps-duration-preview(
          :durationRule="previewRuleParams"
          :providedStamp="providedStamp"
        )
      template(v-if="resolvedGisProvider === 'FIFA_GIS'")
        fifa-gis-duration-preview(:durationRule="previewRuleParams" :providedStamp="providedStamp")
      template(v-if="resolvedGisProvider === 'TOM_TOM'")
        tom-tom-duration-preview(:durationRule="previewRuleParams" :providedStamp="providedStamp")
      template(v-if="resolvedGisProvider === 'QMIC'")
        qmic-duration-preview(:durationRule="previewRuleParams" :providedStamp="providedStamp")
</template>
