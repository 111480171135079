import { defineAsyncComponent } from 'vue'

const ShuttleServiceWizzard = defineAsyncComponent(() => import('./shuttle-service-wizzard.vue'))
const ShuttleServiceModal = defineAsyncComponent(() => import('./shuttle-service-modal.vue'))
const ShuttleServicesTable = defineAsyncComponent(() => import('./shuttle-services-table.vue'))
const ShuttleServicesSelect = defineAsyncComponent(() => import('./shuttle-services-select.vue'))
const ShuttleService = defineAsyncComponent(() => import('./shuttle-service.vue'))

export {
  ShuttleService,
  ShuttleServiceModal,
  ShuttleServicesSelect,
  ShuttleServicesTable,
  ShuttleServiceWizzard,
}
