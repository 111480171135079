import {KeyboardSpecialKeyTypes} from 'frontend/keyboard/enum/KeyboardSpecialKeyTypes'

export const KeyboardKeyCodeMap = new Map<string, string>([
  ['ArrowDown', KeyboardSpecialKeyTypes.Down],
  ['ArrowLeft', KeyboardSpecialKeyTypes.Left],
  ['ArrowRight', KeyboardSpecialKeyTypes.Right],
  ['ArrowUp', KeyboardSpecialKeyTypes.Up],
  ['Enter', KeyboardSpecialKeyTypes.Enter],
  ['Escape', KeyboardSpecialKeyTypes.Escape],
  ['Tab', KeyboardSpecialKeyTypes.Tab],
  ['Space', KeyboardSpecialKeyTypes.Space],
])

const letters = 'abcdefghijklmnoprstuvqwxyz'
for(const letter of letters) {
  KeyboardKeyCodeMap.set(`Key${letter.toUpperCase()}`, letter)
}
