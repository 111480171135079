<script>
import { PROVIDED_STAMPS } from './index'

export default {
  name: 'TransportBookingProvidedStamp',
  props: {
    transportBooking: { type: Object, default: () => ({}) },
  },
  computed: {
    value() {
      return (
        (PROVIDED_STAMPS.find(el => el.value == this.transportBooking.providedStamp) || {})[
          `${this.transportBooking.kind}Label`
        ] || 'empty'
      )
    },
    oldValue() {
      if (
        this.transportBooking.originalProvidedStamp?.length &&
        this.transportBooking.providedStamp != this.transportBooking.originalProvidedStamp
      ) {
        return (PROVIDED_STAMPS.find(
          el => el.value == this.transportBooking.originalProvidedStamp,
        ) || {})[`${this.transportBooking.kind}Label`]
      } else {
        return null
      }
    },
  },
}
</script>

<template lang="pug">
div
  | {{ value }}
  .pl-2.small.line-through(v-if="oldValue?.length")
    | {{ oldValue }}
</template>
