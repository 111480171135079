<script>
import mapbox from 'mapbox-gl'
import { computed, inject, markRaw, onMounted, onUnmounted, ref, useSlots } from 'vue'

export default {
  name: 'Popup',
  props: {
    options: {
      type: Object,
    },
    coordinates: {
      type: Array,
    },
  },
  setup(props) {
    const map = inject('map')
    const marker = inject('marker', ref())
    const popupEl = ref(null)
    const popup = ref(null)
    const slots = useSlots()

    onMounted(() => {
      createPopup()
    })

    onUnmounted(() => {
      hidePopup()
      removeListeners()
      popup.value?.remove()
      popupEl.value?.remove()
    })

    const popupElement = computed(() => {
      return slots.default ? popupEl.value : null
    })

    const hoverListeners = computed(() => {
      if (props.options.hover) {
        return {
          mouseover: showPopup,
          mouseout: hidePopup,
        }
      } else {
        return {}
      }
    })

    function createPopup() {
      popup.value ||= markRaw(
        new mapbox.Popup({ closeOnClick: false, offset: 32, ...props.options }),
      )
      if (popupElement.value) {
        popup.value.setDomContent(popupElement.value)
      } else {
        popup.value.addClassName('small')
        popup.value.setText(props.options.title)
      }

      attachPopup(popup.value)
    }

    function attachPopup(popupInstance) {
      if (marker.value) {
        marker.value.setPopup(popupInstance)
        if (props.options.hover) {
          const markerEl = marker.value.getElement()
          Object.entries(hoverListeners.value).forEach(([key, listener]) => {
            markerEl.addEventListener(key, listener)
          })
        }
      }
    }

    function showPopup() {
      if (marker.value) {
        marker.value.togglePopup()
      } else {
        popup.value.setLngLat(props.coordinates).addTo(map.value)
      }
    }

    function hidePopup() {
      if (marker.value) {
        marker.value.togglePopup()
      } else {
        popup.value?.remove()
      }
    }

    function removeListeners() {
      if (marker.value) {
        const markerEl = marker.value.getElement()
        Object.entries(hoverListeners.value).forEach(([key, listener]) => {
          markerEl.removeEventListener(key, listener)
        })
      }
    }
  },
}
</script>

<template lang="pug">
.mapbox-popup(v-show="false")
  .popup-container(ref="popupEl")
    slot
</template>
