<script>
export default {
  name: 'SidebarIcon',
}
</script>

<template lang="pug">
.icon-with-subicon
  slot
  .subicon(v-if="!!$slots.subicon")
    slot(name="subicon")
</template>
