<script>
import FileUrl from 'frontend/common/file-url.vue'

export default {
  name: 'SystemNotificationDownloadButton',
  props: {
    notification: {
      type: Object,
    },
  },
  components: {
    FileUrl,
  },
}
</script>

<template lang="pug">
.mt-1.position-relative(v-if="notification?.additionalPayload?.downloadUrl")
  file-url(:url="notification?.additionalPayload?.downloadUrl" label="Download")
</template>
