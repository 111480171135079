import axios from 'frontend/_config/axios'
// import { useRoute } from 'vue-router'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import store from 'frontend/_stores'
import { POST, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class LocationsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute?.value?.params?.eventSlug,
    baseUrl = `/events/${eventSlug}/locations`,
    blocker,
    fetchError = 'Failed to fetch location.',
    saveError = 'Failed to save location.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'locations',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
    this.eventSlug = eventSlug
  }

  getForSelect() {
    return (additionalPayload = {}) => {
      let path = `${this.baseUrl}/selection_index`
      if (additionalPayload.transferable) {
        path = `${this.baseUrl}/transferable`
      }
      return this.selectService.fetchOptions({
        path,
        additionalPayload,
        fields: ['id', 'name', 'cluster_id', 'time_zone', 'longitude', 'latitude'],
      })
    }
  }

  dispatchAction(action, id, { url = `${this.baseUrl}${id ? '/' + id : ''}/${action}` } = {}) {
    const errorHandler = error => {
      const messagePayload = { message: 'Requested action cannot be run' }
      messagePayload.message = error.response?.data?.message || messagePayload.message
      if (error.response?.status == 422 && error.response?.data?.errors?.base?.length) {
        messagePayload.title = messagePayload.message
        messagePayload.message =
          error.response.data.errors.base.constructor == Array
            ? error.response.data.errors.base.join('; ')
            : error.response.data.errors.base
      }
      store.dispatch('toast/error', messagePayload)
    }
    return this._makeCall(POST, errorHandler, { url })
  }

  async nearestLocations({
    config = {},
    lonLatArray,
    fields = [
      'id',
      'name',
      'latitude',
      'longitude',
      'time_zone',
      'abbreviation',
      'cluster_id',
      { cluster: ['id', 'name'] },
    ],
  } = {}) {
    let sorts = 'name asc'
    if (lonLatArray?.length) {
      sorts = {
        name: 'distance_from',
        dir: 'asc',
        ransacker_args: lonLatArray,
      }
      fields.push({ distance_from: lonLatArray })
    }

    const result = await this._makeCall('post', error => this._onFetchError(error), {
      url: `${this.baseUrl}/transferable`,
      ...config,
      data: {
        normalized: false,
        fields,
        sorts,
        ...config.data,
      },
    })

    return result
  }
}
