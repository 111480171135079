<script>
import { nextTick } from 'vue'

export default {
  name: 'PureInput',
  emits: ['blur', 'focus', 'update:modelValue'],
  props: {
    name: {
      type: String,
      required: true,
    },
    focusOnInit: {
      type: Boolean,
      default: false,
    },
    modelValue: { type: [String, Number] },
    type: {
      type: String,
      default: 'text',
      validator(val) {
        return [
          'text',
          'tel',
          'number',
          'search',
          'password',
          'email',
          'color',
          'range',
          'time',
        ].includes(val)
      },
    },
    placeholder: { type: String, default: '' },
    inputSize: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['sm', 'lg'].includes(val)
      },
    },
    inputTextAlign: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['start', 'center', 'end'].includes(val)
      },
    },
    additionalClasses: {
      type: [Array, Object, String],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rangeMin: {
      type: Number,
      default: 0,
    },
    rangeMax: {
      type: Number,
      default: 0,
    },
    textarea: { type: Boolean, default: false },
    textareaHeight: { type: [Number, String], default: null },
    resizeDirection: { type: String, default: 'none' },
    disabled: { type: Boolean, default: false },
    mask: { type: String, default: null },
    autocomplete: { type: String, default: null },
  },
  data() {
    return {
      blurSet: false,
    }
  },
  computed: {
    properTextareaHeight() {
      if (this.textareaHeight) {
        if (this.textareaHeight.constructor == String) {
          return this.textareaHeight
        } else {
          return `${this.textareaHeight}px`
        }
      }
      return null
    },
    alignTextClass() {
      return this.inputTextAlign ? `text-${this.inputTextAlign}` : ''
    },
    sizeClass() {
      return this.inputSize ? `form-control-${this.inputSize}` : ''
    },
  },
  mounted() {
    if (this.focusOnInit) {
      nextTick(() => {
        if (this.$refs.inputRef) {
          this.$refs.inputRef.focus()
        }
      })
    }
  },
  methods: {
    updateValue(val) {
      this.$emit('update:modelValue', val)
    },
    onBlur() {
      setTimeout(() => {
        this.$emit('blur')
      }, 50)
    },
    focusInput() {
      nextTick(() => {
        this.$refs.inputRef.focus()
      })
    },
  },
}
</script>

<template lang="pug">
input.form-range(
  ref="inputRef"
  v-if="type == 'range'"
  :autocomplete="autocomplete"
  :class="[sizeClass, alignTextClass, additionalClasses]"
  :disabled="disabled"
  :max="rangeMax"
  :min="rangeMin"
  :name="name"
  :placeholder="placeholder"
  :type="type"
  :value="modelValue"
  @blur.stop="$emit('blur')"
  @focus.stop="$emit('focus')"
  @input.stop="updateValue($event.target.value)"
)
input.form-control(
  ref="inputRef"
  v-else-if="!textarea"
  :autocomplete="autocomplete"
  :class="[sizeClass, alignTextClass, additionalClasses]"
  :disabled="disabled"
  :name="name"
  :placeholder="placeholder"
  :required="required"
  :type="type"
  :value="modelValue"
  @blur.stop="$emit('blur')"
  @focus.stop="$emit('focus')"
  @input.stop="updateValue($event.target.value)"
  v-maska="mask"
)
textarea.form-control(
  ref="inputRef"
  v-else-if="!!textarea"
  :class="[sizeClass, alignTextClass, additionalClasses]"
  :disabled="disabled"
  :name="name"
  :placeholder="placeholder"
  :required="required"
  :style="!!properTextareaHeight ? `min-height: ${properTextareaHeight}; resize: ${resizeDirection};` : 'min-height: 70px;'"
  :value="modelValue"
  @blur.stop="$emit('blur')"
  @focus.stop="$emit('focus')"
  @input.stop="updateValue($event.target.value)"
)
</template>
