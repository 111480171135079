<script>
export default {
  name: 'DismissButton',
  emits: ['click'],
  props: {
    size: { type: String, default: null },
  },
  computed: {
    sizeClass() {
      return this.size ? `btn-${this.size}` : ''
    },
  },
}
</script>

<template lang="pug">
button.btn.btn-outline-primary(:class="sizeClass" @click="$emit('click', $event)" type="button")
  slot Dismiss
</template>
