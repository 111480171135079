import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class MapProvidersService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/map_providers`,
    blocker,
    fetchError = 'Failed to fetch MapProvider.',
    saveError = 'Failed to save MapProvider.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'map-providers',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return () => this.selectService.fetchOptions({ fields: ['id', 'name', 'provider'] })
  }
}
