<script setup lang="ts">
import { CriteriaValueType } from 'frontend/common/data-tables/search-criteria/enum/CriteriaValueType'
import DefaultCriteriaValueTypePreviewStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypePreviewStrategies/DefaultCriteriaValueTypePreviewStrategy.vue'
import ResourceListCriteriaValueTypePreviewStrategy from 'frontend/common/data-tables/search-criteria/strategies/CriteriaValueTypePreviewStrategies/ResourceListCriteriaValueTypePreviewStrategy.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import type { Component } from 'vue'
import { computed } from 'vue'

interface Props {
  criteriaValueType: CriteriaValueType
  modelValue: unknown
  humanizedPredicate: string
  criterion: ISearchCriteriaNode
  predicate?: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: unknown): void
}
const emit = defineEmits<Emits>()

const strategyMap = new Map<CriteriaValueType, Component>([
  [CriteriaValueType.ResourceList, ResourceListCriteriaValueTypePreviewStrategy],
])
const strategyComponent = computed(() => {
  return strategyMap.get(props.criteriaValueType) || DefaultCriteriaValueTypePreviewStrategy
})

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <Component
    :is="strategyComponent"
    v-if="strategyComponent"
    :key="criteriaValueType"
    v-model="valueModel"
    :humanized-predicate="humanizedPredicate"
    :criterion="criterion"
    :predicate="predicate"
  >
    <slot />
  </Component>
</template>
