import { checkAccessToPath } from 'frontend/common/access-helpers'
import { computed } from 'vue'

export function useSettingsSection(eou, user) {
  let currentOrganization = null
  if (user?.currentOrganizationId && user?.organizations?.length) {
    currentOrganization = user.organizations.find(o => o.id == user.currentOrganizationId)
  }

  function hasAccessTo(path) {
    return checkAccessToPath(eou.value, path)
  }

  const section = computed(() => {
    const result = {
      name: 'Settings',
      items: [
        {
          title: 'User accesses',
          isDisabled: false,
          path: 'user-accesses',
          icon: 'bi-people-fill',
        },
        {
          title: 'Transport buffers',
          isDisabled: false,
          path: 'transport-buffers',
          icon: 'bi-distribute-horizontal',
        },
        {
          title: 'PUSH notification templates',
          isDisabled: false,
          path: 'notification-templates',
          icon: 'bi-phone-vibrate',
        },
        {
          title: 'Client Group Paths',
          isDisabled: false,
          path: 'client-categories',
          icon: 'bi-person',
        },
        {
          title: 'Client Groups',
          isDisabled: false,
          path: 'client-groups',
          icon: 'bi-file-earmark-spreadsheet',
        },
        {
          title: 'Vehicle Sources',
          isDisabled: false,
          path: 'vehicle-sources',
          icon: 'bi-file-earmark-spreadsheet',
        },
        {
          title: 'Vehicle Source Pricing',
          isDisabled: false,
          path: 'vehicle-source-pricings',
          icon: 'bi-file-earmark-spreadsheet',
        },
        {
          title: 'Driver Sources',
          isDisabled: false,
          path: 'driver-sources',
          icon: 'bi-file-earmark-spreadsheet',
        },
        currentOrganization?.travelTimeModifiersEnabled
          ? {
              title: 'Travel Time Modifiers',
              isDisabled: false,
              path: 'travel-time-modifiers',
              icon: 'bi-pin-map',
            }
          : null,
        {
          title: 'Tracker Devices',
          isDisabled: false,
          path: 'tracker-devices',
          icon: 'fas fa-satellite-dish',
        },
        {
          title: 'Tracking Services',
          isDisabled: false,
          path: 'tracking-services',
          icon: 'fas fa-satellite-dish',
        },
        {
          title: 'Deleted resources',
          isDisabled: false,
          path: 'deleted-resources',
          icon: 'fas fa-trash-alt',
        },
      ],
    }

    result.items = result.items.filter(el => !!el && hasAccessTo(el.path))
    if (result.items.length) {
      return result
    } else {
      return {}
    }
  })

  return section
}
