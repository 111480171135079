import dayjs from 'frontend/_config/dayjs'
import { denormalize } from 'normalizr'

const initialState = () => ({
  entities: {},
  result: {},
  mappings: {},
  total: 0,
})

export const chatGroupsModule = {
  namespaced: true,
  state: initialState,
  actions: {
    updateLastMessage({ commit }, chatGroupId, message) {
      commit('updateEntity', { chatGroupId, message })
      commit('setLastMessage', { chatGroupId, message })
    },
  },
  mutations: {
    setTotal(state, value) {
      state.total = value
    },
    appendData(state, normalizedData) {
      state.result.items = Array.from(
        new Set([...state.result.items, ...normalizedData.result.items]),
      )

      Object.keys(normalizedData.entities).forEach(key => {
        const oldEntities = state.entities[key]
        const newEntities = normalizedData.entities[key]
        Object.keys(newEntities).forEach(k => {
          oldEntities[k] = newEntities[k]
        })
      })
    },
    setData(state, data) {
      Object.assign(state, { ...initialState(), ...data })
    },
    setSchema(state, schema) {
      state.schema = schema
    },
    updateEntity(state, { entityName, normalizedData }) {
      const entityKey = entityName === 'chatGroups' ? 'items' : entityName
      mergeEntities({ entityKey, state, normalizedData })

      if (entityName === 'chatGroups') {
        state.result.items = Array.from(
          new Set([...state.result.items, ...normalizedData.result.items]),
        )
      }
    },
    setLastMessage(state, { chatGroupId, message }) {
      const chatGroups = state.entity.items
      const chatGroup = chatGroups[chatGroupId]
      if (chatGroup) {
        const prevMessage = state.entities.messages[message.id]
        if (prevMessage && dayjs(message.updatedAt).isBefore(dayjs(prevMessage.updatedAt))) {
          chatGroups[chatGroupId].lastMessage = message.id
        }
      }
    },
    deleteEntity(state, { key, id, item = {} }) {
      const itemId = id ?? item?.id
      const entityKey = key === 'chatGroups' ? 'items' : key

      delete state.entities[entityKey]?.[itemId]
    },
    markEntityAsDeleted(state, { key, item, id }) {
      const itemId = id ?? item?.id
      const entity = state.entities[key]?.[itemId]
      if (entity) {
        entity._deleted = true
      }
    },
  },
  getters: {
    getChatGroup: state => id => {
      if (state.entities?.items?.[id]) {
        return denormalize(id, state.schema.items[0], state.entities)
      }
    },
  },
}

function mergeEntities({ entityKey, state, normalizedData, mergeNewEntities = true }) {
  Object.keys(state.entities).forEach(collectionKey => {
    const key = collectionKey === 'items' ? entityKey : collectionKey

    if (!mergeNewEntities && !state.entities?.[key]) {
      return
    } else if (!state.entities?.[key]) {
      state.entities[key] = {}
    }

    Object.keys(normalizedData?.entities?.[collectionKey] || {}).forEach(id => {
      if (!state.entities[key]) state.entities[key] = {}
      state.entities[key][id] = normalizedData.entities[collectionKey][id]
    })
  })
}
