<script>
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'PasswordRecovery',
  data() {
    return {
      errors: {},
      email: null,
      blocker: false,
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  methods: {
    recoverPassword(e) {
      e.preventDefault()
      this.v$.$touch()
      if (Object.keys(this.errors).length > 0 || this.v$.$error) {
        this.$error({
          message: 'Please provide email to receive password reset instruction.',
        })
      } else {
        this.blocker = true
        this.axios(
          {
            method: 'post',
            url: 'session/send_reset_password_instructions',
            data: {
              email: this.email,
            },
          },
          {
            withCredentials: true,
          },
        )
          .then(response => {
            if (response.status == 200) {
              this.$success({
                message: 'Password recovery instructions will be send if user account exist',
              })
              this.$router.push('/session/sign-in')
            }
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.blocker = false
          })
      }
    },
    showErrorMessage(error) {
      let errorMessage
      if (!error.response) {
        errorMessage = 'Major password recovery error occurred'
      } else if (error.response.data.error) {
        errorMessage = error.response.data.error
      } else {
        errorMessage = 'Error occurred.'
      }
      this.$error({ message: errorMessage })
    },
  },
}
</script>

<template lang="pug">
ea-spinner(v-if="blocker" :spinner-size="30" matchParent)
.d-flex.flex-column.position-relative.w-100.h-100
  .session-wrapper
    h3.pb-3.mb-0 Password recovery
    .thick-divider.mb-4
    form.sign-in-form(@submit="recoverPassword")
      .w-100.mb-4
        ea-input(
          :additional-errors="errors.email"
          :validators="{ presence: null }"
          v-model="email"
          label="E-mail"
          name="session.email"
          type="email"
        )
      .w-100.text-right
        submit-button.w-100 Send recovery instructions
</template>
