import { defineAsyncComponent } from 'vue'

const VehicleImportFormModal = defineAsyncComponent(() => import('./vehicle-import-form-modal.vue'))
const VehicleImportShow = defineAsyncComponent(() => import('./vehicle-import-show.vue'))
const VehicleImportsIndex = defineAsyncComponent(() => import('./vehicle-imports-index.vue'))
const VehicleImportsTable = defineAsyncComponent(() => import('./vehicle-imports-table.vue'))

const STATES = [
  { value: 'idle', label: 'Idle' },
  { value: 'rows_loading', label: 'Loading rows' },
  { value: 'rows_not_loaded', label: 'Error while loading rows' },
  { value: 'rows_loaded', label: 'Rows loaded, waiting for the import' },
  { value: 'in_progress', label: 'Import in progress' },
  { value: 'finished', label: 'Import finished' },
  { value: 'finished_with_errors', label: 'Import finished (with errors)' },
  { value: 'major_error', label: 'Major error, cannot continue' },
]

export {
  STATES,
  VehicleImportFormModal,
  VehicleImportShow,
  VehicleImportsIndex,
  VehicleImportsTable,
}
