import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

const FIELDS_FOR_SELECT = ['id', 'name']

export class AslsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = eventSlug?.length ? `/events/${eventSlug}/asls` : `/asls`,
    blocker,
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, { apiProvider, blocker, blockerKey: 'asls' }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError: 'Failed to fetch ASLs.',
      saveError: 'Failed to save ASL.',
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        errorHandler: this._onFetchError,
        fields: FIELDS_FOR_SELECT,
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }
}
