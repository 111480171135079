import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class ClientCategoriesService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/client_categories`,
    blocker,
    fetchError = 'Failed to fetch client category.',
    saveError = 'Failed to save client category.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'client_categories',
    }),
  } = {}) {
    super({ apiProvider, baseUrl, blocker, fetchError, saveError })
    this.selectService = selectService
  }

  get getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        fields: ['id', 'name', 'group_path'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }
}
