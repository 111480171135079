<script>
import { computed, provide, ref } from 'vue'

export default {
  name: 'TabsWrapper',
  emits: ['update:selected', 'update:selectedIndex'],
  props: {
    tabContainerClass: {
      type: String,
    },
    selected: {
      type: String,
    },
    selectedIndex: {
      type: Number,
    },
  },
  setup(props, { slots }) {
    let noTabsMessageTimeout = null
    const tabsWrapperRef = ref(null)
    const tabs = computed(() => {
      const tabNodes = slots.default().reduce((_tabs, node) => {
        if (node.type?.name === 'Tab') {
          _tabs.push(node)
        } else {
          if (node?.children?.constructor == Array) {
            const childTabs = (node?.children || []).filter(s => s?.type?.name === 'Tab')
            if (childTabs?.length) {
              _tabs.push(...childTabs)
            }
          }
        }
        if (!_tabs?.length && tabsWrapperRef.value) {
          clearTimeout(noTabsMessageTimeout)
          noTabsMessageTimeout = setTimeout(() => {
            console.warn('NO TABS FOUND BY TABS WRAPPER', slots.default(), tabsWrapperRef.value)
          }, 500)
        } else {
          clearTimeout(noTabsMessageTimeout)
        }

        return _tabs
      }, [])

      return tabNodes.map(tab => ({
        title: tab.props.title,
        counter: tab.props.counter,
        disabled: tab.props.disabled || false,
        onTabClick: tab.props.onTabClick,
        tooltip: tab.props.tooltip || '',
        children: tab.children,
      }))
    })
    let selectedTitle
    if (props.selected?.length && tabs.value.map(t => t.title).includes(props.selected)) {
      selectedTitle = ref(props.selected)
    } else {
      selectedTitle = ref(tabs.value[0]?.title)
    }
    provide('selectedTitle', selectedTitle)
    return {
      tabs,
      selectedTitle,
      tabsWrapperRef,
    }
  },
  watch: {
    tabs() {
      if (this.tabs.findIndex(t => t.title == this.selectedTitle) == -1) {
        this.selectedTitle = this.tabs[0]?.title
        this.$emit('update:selected', this.selectedTitle)
        this.$emit('update:selectedIndex', 0)
      }
    },
    selected() {
      if (this.selectedTitle != this.selected) {
        if (this.tabs.map(t => t.title).includes(this.selected)) {
          this.selectedTitle = this.selected
          this.$emit(
            'update:selectedIndex',
            this.tabs.findIndex(t => t.title == this.selectedTitle),
          )
        } else {
          this.$emit('update:selected', this.selectedTitle)
          this.$emit(
            'update:selectedIndex',
            this.tabs.findIndex(t => t.title == this.selectedTitle),
          )
        }
      }
    },
    selectedIndex() {
      const currentIndex = this.tabs.findIndex(t => t.title == this.selectedTitle)
      if (currentIndex != this.selectedIndex) {
        if (this.tabs.length > this.selectedIndex) {
          this.selectedTitle = this.tabs[this.selectedIndex].title
          this.$emit('update:selected', this.selectedTitle)
        } else {
          this.$emit('update:selected', this.selectedTitle)
          this.$emit('update:selectedIndex', currentIndex)
        }
      }
    },
  },
  methods: {
    onTabClick(tab) {
      if (tab.disabled) return
      this.selectedTitle = tab.title
      this.$emit('update:selected', this.selectedTitle)
      this.$emit(
        'update:selectedIndex',
        this.tabs.findIndex(t => t.title == this.selectedTitle),
      )
      if (typeof tab.onTabClick === 'function') {
        tab.onTabClick()
      }
      this.$eventHub.$emit('tab-changed')
    },
  },
}
</script>

<template lang="pug">
div(ref="tabsWrapperRef")
  .d-flex
    ul.nav.nav-tabs.flex-grow-1
      li.nav-item.position-relative(
        v-for="tab in tabs"
        :key="tab.title"
        @click="() => onTabClick(tab)"
        v-tooltip.options="{ title: tab.tooltip, placement: 'left' }"
      )
        a.nav-link(
          v-if="tab.children?.['title']"
          :class="{ active: selectedTitle == tab.title, disabled: tab.disabled, 'has-corner-counter': tab.counter || tab.counter == 0 }"
        )
          component(:is="tab.children['title']")
          .corner-counter(v-if="tab.counter || tab.counter == 0") {{ tab.counter > 9999 ? '9999+' : tab.counter }}
        template(v-else)
          a.nav-link(
            :class="{ active: selectedTitle == tab.title, disabled: tab.disabled, 'has-corner-counter': tab.counter || tab.counter == 0 }"
          )
            | {{ tab.title }}
            .corner-counter(v-if="tab.counter || tab.counter == 0") {{ tab.counter > 9999 ? '9999+' : tab.counter }}
    .with-bottom-border.ps-1(v-if="$slots?.button")
      slot(name="button")
  .pt-2.tab-wrapper(:class="tabContainerClass")
    slot
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/_variables.scss';
.tab-wrapper.pt-0-i {
  padding-top: 0 !important;
}
.nav {
  flex-wrap: initial;
  align-items: stretch;
  overflow: auto;
  .bottom-border {
    height: 100%;
    width: 100%;
    border-bottom: 1px solid $medium-gray;
  }

  li.nav-item {
    white-space: nowrap;
    .nav-link {
      margin: 0%;
    }
  }
}
.with-bottom-border {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: #dadada;
  }
}
</style>
