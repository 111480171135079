<script>
export default {
  name: 'StringTimePresenter',
  props: {
    t: {
      type: [String],
    },
    tz: { type: String, default: null },
  },
  computed: {
    browserTZ() {
      return this.$store.state.session.timeZone
    },
    providedTZ() {
      return this.tz?.length ? this.tz : this.$store.state.session.timeZone
    },
    tzIsDifferent() {
      return this.browserTZ != this.providedTZ
    },
  },
}
</script>

<template lang="pug">
template(v-if="t && providedTZ")
  span {{ t }}
  span.small(v-if="tzIsDifferent") {{ ' ' }}(in {{ providedTZ }})
span.text-black-15(v-else-if="!t") empty
</template>
