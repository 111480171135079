export enum RouteName {
  Events = 'events',
  EventDashboard = 'event-dashboard',
  PassengerEvents = 'passenger-events',
  PassengerPortal = 'passenger-portal',
  PassengerDemands = 'passenger-demands',
  PassengerEvent = 'passenger-event',
  DriverRegisterToken = 'driver-register-token',
  ShareQrToken = 'share-qr-token',
  Session = 'session',
  PasswordRecovery = 'password-recovery',
  ChooseContext = 'choose-context',
  LivePanel = 'live-panel',
  Roles = 'roles',
  Role = 'role',
  CreateRole = 'create-role',
}
