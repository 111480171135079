<script setup lang="ts">
import DismissButton from 'frontend/common/dismiss-button.vue'
import EaSelect from 'frontend/common/ea-form-controls/ea-select.vue'
import Modal from 'frontend/common/modal.vue'
import ButtonComponent from 'frontend/roles/components/ButtonComponent.vue'
import ContainerHeaderIconTitleComponent from 'frontend/roles/components/ContainerHeaderIconTitleComponent.vue'
import { DataModelTypeItems } from 'frontend/roles/config/DataModelTypeItems'
import InputComponent from 'frontend/ui/components/InputComponent.vue'
import { useVariables } from 'frontend/variables/composables/useVariables'
import { VariableService } from 'frontend/variables/services/VariableService'
import { computed, ref } from 'vue'

import { DataModelType } from '../../roles/enum/DataModelType'

interface Props {
  dataModelType: DataModelType
}

const props = withDefaults(defineProps<Props>(), {
  dataModelType: null,
})

interface Emits {
  (e: 'exit'): void
  (e: 'saveVariable', id: string): void
}
const emit = defineEmits<Emits>()

const { fetchItems } = useVariables()

const isCreating = ref(false)
const name = ref('')
const description = ref('')

let initialDataModelTypeValueItem = null
if (props.dataModelType) {
  const dataModelTypeItem = DataModelTypeItems.find(
    item => item.dataModelType === props.dataModelType,
  )
  initialDataModelTypeValueItem = {
    label: dataModelTypeItem.name,
    value: dataModelTypeItem.dataModelType,
  }
}

const dataModelTypeValueItem = ref<{
  label: string
  value: DataModelType
}>(initialDataModelTypeValueItem)

const variableService = new VariableService()

async function onSave(closeFn) {
  isCreating.value = true
  try {
    const dataToSave = {
      name: name.value,
      description: description.value,
      dataModelType: dataModelTypeValueItem.value.value,
    }
    const response = await variableService.save({
      data: dataToSave,
    })
    await fetchItems()
    emit('saveVariable', response.data.id)
    closeFn()
  } finally {
    isCreating.value = false
  }
}

const dataModelTypeValueItems = computed(() => {
  return DataModelTypeItems.map(item => {
    return {
      label: item.name,
      value: item.dataModelType,
    }
  })
})
</script>

<template>
  <Modal :size="320" @closed="emit('exit')">
    <template #header>
      <span>
        <ContainerHeaderIconTitleComponent icon-class="fas fa-th-large">
          Create variable
        </ContainerHeaderIconTitleComponent>
      </span>
    </template>
    <template #default>
      <div class="form">
        <InputComponent v-model="name" placeholder="Enter name for your variable" />
        <InputComponent v-model="description" placeholder="Description (optional)" />
        <EaSelect
          v-model="dataModelTypeValueItem"
          placeholder="Choose model type"
          :options="dataModelTypeValueItems"
          :disabled="!!props.dataModelType"
          name="modelTypeSelect"
        />
      </div>
    </template>
    <template #footer="modal">
      <DismissButton @click="modal.close()" />
      <ButtonComponent :is-loading="isCreating" @click.prevent="onSave(modal.close)"
        >SAVE</ButtonComponent
      >
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.create-variable-modal-content {
  min-height: 240px;
  min-width: 420px;
}

input {
  width: 100%;
}
.container-header {
  padding: 24px;
  padding-bottom: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  .button.back {
    padding: 0;
    width: 2.2rem;
  }
}

.variable-item {
  &.is-in-edit-mode {
    background: hsla(208, 57%, 95%, 1);
  }
}

.description {
  padding: 0 24px;
}
.header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto auto;
  padding: 32px;
}

.form {
  display: grid;
  gap: 8px;
  padding-bottom: 24px;
}
</style>
