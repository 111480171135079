import * as Sentry from '@sentry/vue'
import _axios from 'axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter.js'
// import airbrake from 'frontend/_config/airbrake'
import store from 'frontend/_stores/index'

export function globalInterceptors(axios) {
  axios.interceptors.request.use(function (config) {
    if (process.env.NODE_ENV === 'development' && config.url.includes('/undefined')) {
      window.location.reload()
    }
    if (store.state.session.timeZone) {
      config.headers.common['Current-Time-Zone'] = store.state.session.timeZone
    }
    return config
  })

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (_axios.isCancel(error)) {
        console.warn(error, 'Request was cancelled by client.')
        error.cancelled = true
        return Promise.reject(error)
      }
      if (![401, 403, 404, 412, 413, 460, 490, 491, 495].includes(error.response?.status)) {
        if (error?.message != 'Network Error') {
          Sentry.captureException(error)
        } else {
          console.error('RESPONSE ERROR', error?.response, error)
        }
      } else if (error.response?.status == 460) {
        // CSRF TOKEN
        if (error.config.headers['Csrf-Retry'] != false) {
          axios({ method: 'get', url: '', headers: { 'Csrf-Retry': false } })
        }
      }

      authorizationFilter(error)
      return Promise.reject(error)
    },
  )
}

function authorizationFilter(error) {
  const pathname = window.location.pathname
  if (
    (error.response || {}).status == 401 &&
    !['/session/sign-in', '/session/password-recovery'].includes(pathname) &&
    !pathname.includes('/session/sso/') &&
    !pathname.includes('/session/set-password/')
  ) {
    _showUnauthenticatedToastAndGoToSignInPage()
  } else if (
    (error.response || {}).status == 412 &&
    setupRouter.currentRoute.value.path != '/session/choose-context'
  ) {
    _showUnauthenticatedToastAndGoToChooseContextPage()
  } else if (
    (error.response || {}).status == 413 &&
    setupRouter.currentRoute.value.path != '/session/choose-context'
  ) {
    _showCustomDomainToastAndGoToChooseContextPage()
  } else if (error?.response?.status == 403 && setupRouter.currentRoute.value.path != '/events') {
    // router.go(-1)
    _showUnauthorizedToast()
  }
}

function _showCustomDomainToastAndGoToChooseContextPage() {
  setTimeout(() => {
    if (store.state.session.user) {
      let currentOrganization = null
      if (store.state.session.user?.currentOrganizationId) {
        currentOrganization = store.state.session.user.organizations.find(
          o => o.id == store.state.session.user.currentOrganizationId,
        )
      }
      store.commit('toast/clearToasts')
      store.dispatch('toast/error', {
        title: `Last chosen organization${
          currentOrganization?.name ? ' (' + currentOrganization?.name + ')' : ''
        } operates on different domain.`,
        message: `Please click ${
          currentOrganization?.name || 'the organization'
        } button to redirect to proper domain or choose other organization button to change context.`,
        stay: 7000,
      })
    }
    if (!setupRouter.currentRoute.value.fullPath.includes('/session/')) {
      if (setupRouter.currentRoute.value.fullPath == '/') {
        store.commit('session/setAfterSignInPath', window.location.pathname)
      } else {
        store.commit('session/setAfterSignInPath', setupRouter.currentRoute.value.path)
      }
    }
    setupRouter.push({ path: '/session/choose-context' })
  }, 10)
}

function _showUnauthenticatedToastAndGoToSignInPage() {
  setTimeout(() => {
    if (store.state.session.user) {
      store.commit('toast/clearToasts')
      store.dispatch('toast/error', {
        title: 'Your session timed out',
        message: 'Please sign in again.',
      })
    }
    store.commit('session/setUser', null)
    if (!setupRouter.currentRoute.value.fullPath.includes('/session/')) {
      if (setupRouter.currentRoute.value.fullPath == '/') {
        store.commit('session/setAfterSignInPath', window.location.pathname)
      } else {
        store.commit('session/setAfterSignInPath', setupRouter.currentRoute.value.path)
      }
    }
    setupRouter.push({ path: '/session/sign-in' })
  }, 10)
}

function _showUnauthenticatedToastAndGoToChooseContextPage() {
  setTimeout(() => {
    if (store.state.session.user) {
      let currentOrganization = null
      if (store.state.session.user?.currentOrganizationId) {
        currentOrganization = store.state.session.user.organizations.find(
          o => o.id == store.state.session.user.currentOrganizationId,
        )
      }
      store.commit('toast/clearToasts')
      store.dispatch('toast/error', {
        title: `In order to access ${
          currentOrganization?.name || 'the organization'
        } you need to sign in via SSO`,
        message: `Please click ${
          currentOrganization?.name || 'the organization'
        } button in order to start SSO process or choose other organization button to change context.`,
        stay: 7000,
      })
    }
    if (!setupRouter.currentRoute.value.fullPath.includes('/session/')) {
      if (setupRouter.currentRoute.value.fullPath == '/') {
        store.commit('session/setAfterSignInPath', window.location.pathname)
      } else {
        store.commit('session/setAfterSignInPath', setupRouter.currentRoute.value.path)
      }
    }
    setupRouter.push({ path: '/session/choose-context' })
  }, 10)
}

function _showUnauthorizedToast() {
  setTimeout(() => {
    store.commit('toast/clearToasts')
    store.dispatch('toast/error', {
      title: 'Unauthorized access',
      message: 'The action you requested has been denied.',
    })
  }, 10)
}
