import { camelCase } from 'lodash'
import { schema } from 'normalizr'
import pluralize from 'pluralize'

if (typeof window !== 'undefined') {
  window.pluralize = pluralize
}

export default function computeSchema(fields = [], mappings = {}, key = null) {
  const result = {}

  if (fields.filter) {
    const nestedFieldsEntry = fields.filter(item => item.constructor === Object)

    nestedFieldsEntry.forEach(entry => {
      const entryKeys = Object.keys(entry)
      warnIfWrongFormat(entryKeys)

      const entityKey = entryKeys[0]
      const camelEntityKey = camelCase(entityKey)

      if (mappings[camelEntityKey]) {
        const schema = computeSchema(
          entry[entityKey],
          mappings,
          mappingName(camelEntityKey, mappings),
        )
        result[camelEntityKey] = pluralize.isPlural(entityKey) ? [schema] : schema
      } else {
        warnMissingMappings(entityKey, key, mappings, fields)
      }
    })
  }

  if (key == null) {
    const mainItemSchema = new schema.Entity('items')
    mainItemSchema.define(result)
    return { items: [mainItemSchema] }
  } else {
    const returnedSchema = new schema.Entity(key)
    returnedSchema.define(result)
    return returnedSchema
  }
}

function mappingName(camelKey, knownMappings) {
  if (knownMappings[camelKey]) {
    return camelCase(knownMappings[camelKey])
  } else {
    return pluralize(camelKey)
  }
}

function warnIfWrongFormat(entryKeys) {
  if (entryKeys[1])
    console.warn('Denormalization: Associations should be defined in separate objects.')
}

function warnMissingMappings(entityKey, key, mappings, fields) {
  console.warn(
    'have no mapping for',
    entityKey,
    `(${key || 'root'})`,
    '\nknown mappings:',
    mappings,
    '\nfields:',
    fields,
  )
}
