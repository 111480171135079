<script>
export default {
  name: 'PassengerDefaultButton',
  emits: ['click'],
}
</script>

<template lang="pug">
button.btn.btn-passenger.btn-default(@click="$emit('click', $event)" type="button")
  slot
    span ACTION
</template>
