import {ISearchCriteria} from 'frontend/filters/types/ISearchCriteria'
import {ISearchCriteriaCategory} from 'frontend/filters/types/ISearchCriteriaCategory'

export function mapCriteriaToCategories(
  criteria: ISearchCriteria,
  parentId: symbol | null,
  allParentIds?: symbol[] = [],
  level? = 0,
): ISearchCriteriaCategory[] {
  let categories: ISearchCriteriaCategory[] = []
  for (const [key, item] of Object.entries(criteria)) {
    const id = Symbol(item.label)
    const config = JSON.parse(JSON.stringify(item))
    categories.push({
      label: item.label,
      id: id,
      parentCategoryId: parentId || null,
      allParentIds: allParentIds,
      level,
      config: config,
      key,
    })

    const children = item.children
      ? mapCriteriaToCategories(item.children, id, [...allParentIds, id], level + 1)
      : null
    if (children) {
      categories = [...categories, ...children]
    }
  }
  return categories
}