<script>
import { GROUPING_CAUSES } from './index'

export default {
  name: 'TransportBookingTime',
  props: {
    transportBooking: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      groupingCauses: GROUPING_CAUSES,
    }
  },
}
</script>

<template lang="pug">
div(v-if="transportBooking.groupingCause?.length")
  .bold.text-nowrap {{ groupingCauses[transportBooking.groupingCause] }}
  .small(
    v-if="transportBooking.groupingCause == 'rule' && !!transportBooking.transportBookingGroupingRule"
  )
    | [EID: {{ transportBooking.transportBookingGroupingRule.eid }}] {{ transportBooking.transportBookingGroupingRule.name }}
</template>
