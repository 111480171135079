<script>
export default {
  name: 'EventOrganizationUserVariableNames',
  props: {
    variables: { type: Array, default: () => [] },
  },
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-start(v-for="variable in variables")
  .bold.me-1.text-nowrap {{ variable.name }}
  .text-nowrap [{{ variable.dataModelType }}]
</template>
