<script>
export default {
  name: 'DriverInclusiveShiftCheckInOut',
  props: {
    driver: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-start
  true-false-label(:item="driver.computedInclusiveShiftCheckInOut")
  .ms-2(v-if="[true, false].includes(driver.inclusiveShiftCheckInOut)") overridden
  .ms-2.text-black-30(v-else) default
</template>
