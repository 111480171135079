<script>
export default {
  name: 'ValuesPerVariable',
  props: {
    variable: { type: Object, required: true },
    valueIds: { type: [Array, String], default: () => [] },
    usedValuePresets: { type: Array, default: () => [] },
  },
  data() {
    return {
      tooltipForValues: null,
      tooltipLoaded: false,
    }
  },
  computed: {
    valuePreset() {
      if (this.valueIds?.constructor == String) {
        return this.usedValuePresets?.find(vp => vp.id == this.valueIds)
      }
      return null
    },
  },
  created() {
    const span = document.createElement('span')
    span.innerText = 'loading...'
    this.tooltipForValues = span
  },
  methods: {
    loadValuesTooltip() {
      if (!this.tooltipLoaded) {
        this.tooltipLoaded = true
        if (this.valueIds?.constructor == Array)
          this.axios
            .post(
              `/events/${this.$route.params.eventSlug}/role_value_presets/fetch_values/${this.variable.dataModelType}`,
              {
                id_in: this.valueIds,
                normalized: false,
              },
            )
            .then(response => {
              this.tooltipForValues.innerHTML = response.data.contents
                .map(el => el.label)
                .join(', ')
            })
        else {
          this.axios
            .post(
              `/events/${this.$route.params.eventSlug}/role_value_presets/${this.valueIds}/fetch_values`,
              {
                normalized: false,
              },
            )
            .then(response => {
              this.tooltipForValues.innerHTML = response.data.contents
                .map(el => el.label)
                .join(', ')
            })
        }
      }
    },
  },
}
</script>

<template lang="pug">
.text-nowrap
  template(v-if="valueIds?.constructor == String")
    span(
      v-on="{ 'show.bs.tooltip': loadValuesTooltip }"
      v-tooltip.options="{ title: tooltipForValues, sync: true, html: true }"
    )
      i.fas.fa-info-circle.me-1
    span.bold {{ valuePreset?.label }}
  template(v-else-if="valueIds?.constructor == Array && valueIds?.length")
    span(
      v-on="{ 'show.bs.tooltip': loadValuesTooltip }"
      v-tooltip.options="{ title: tooltipForValues, sync: true, html: true }"
    )
      i.fas.fa-info-circle.me-1
    | Individual
  span.text-danger(v-else) No values defined
</template>
