import { defineAsyncComponent } from 'vue'

const ClientCommunicationRule = defineAsyncComponent(() =>
  import('./client-communication-rule.vue'),
)
const ClientCommunicationRuleFormModal = defineAsyncComponent(() =>
  import('./client-communication-rule-form-modal.vue'),
)
const ClientCommunicationRuleModal = defineAsyncComponent(() =>
  import('./client-communication-rule-modal.vue'),
)
const ClientCommunicationRulesTable = defineAsyncComponent(() =>
  import('./client-communication-rules-table.vue'),
)
const ClientCommunicationRulesSelect = defineAsyncComponent(() =>
  import('./client-communication-rules-select.vue'),
)

const OVERRIDABLE_CONDITIONS = [
  { key: 'is_departure', defaultValue: false, label: 'Is Departure', groupName: 'kind' },
  { key: 'is_arrival', defaultValue: false, label: 'Is Arrival', groupName: 'kind' },
  { key: 'is_onsite', defaultValue: true, label: 'Is On-site', groupName: 'kind' },
  {
    key: 'is_taxi_shift',
    defaultValue: false,
    label: 'The shift is a "TAXI" shift',
    groupName: 'shiftKind',
  },
  {
    key: 'is_normal_shift',
    defaultValue: true,
    label: 'The shift is a "normal" shift',
    groupName: 'shiftKind',
  },
  { key: 'shift_assigned', defaultValue: true, label: 'Shift is assigned' },
  { key: 'driver_assigned', defaultValue: true, label: 'Driver is assigned' },
  { key: 'vehicle_assigned', defaultValue: true, label: 'Vehicle is assigned' },
  { key: 'has_pickup_tp', defaultValue: true, label: 'Has pick-up TP' },
  { key: 'has_dropoff_tp', defaultValue: true, label: 'Has drop-off TP' },
]

const KINDS = [
  { value: 'email', label: 'E-mail' },
  { value: 'sms', label: 'SMS' },
]

export {
  ClientCommunicationRule,
  ClientCommunicationRuleFormModal,
  ClientCommunicationRuleModal,
  ClientCommunicationRulesSelect,
  ClientCommunicationRulesTable,
  KINDS,
  OVERRIDABLE_CONDITIONS,
}
