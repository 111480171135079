<script>
import { groupBy } from 'lodash'

import SimpleRadio from './_simple-radio.vue'

export default {
  name: 'EaRadioGroup',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    label: { type: String, default: null },
    modelValue: { type: [String, Number, Boolean] },
    collection: { type: Array, required: true },
    collectionLabel: { type: String, default: 'name' },
    collectionValue: { type: String, default: 'id' },
    columns: { type: Number, default: 1 },
    validators: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    name: { type: String, required: true },
    clearable: { type: Boolean, default: false },
    displayAs: {
      type: String,
      default: 'radio-group',
      validator: val => ['radio-group', 'button-group'].includes(val),
    },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  computed: {
    collectionGrouped() {
      return groupBy(this.collection, item => this.collection.indexOf(item) % this.columns)
    },
    isEnabled() {
      return !this.disabled && (this.modelValue || '').toString().length
    },
    flatColumns() {
      return Object.keys(this.collectionGrouped)
    },
  },
  mounted() {
    this.groupName = this.groupName || Math.random().toString(36).substring(3)
  },
  components: {
    SimpleRadio,
  },
}
</script>

<template lang="pug">
form-control-container(
  :additional-errors="additionalErrors"
  :label="label"
  :validators="validators"
  :value="modelValue"
)
  template(#label-addon)
    i.ms-2.bi-x-lg.text-danger.cursor-pointer(
      v-if="clearable && isEnabled"
      @click="$emit('update:modelValue', null)"
    )
  template(#default)
    .btn-group.btn-group-sm(v-if="displayAs == 'button-group'")
      template(v-for="item in collection")
        a.btn.modelValue(
          :class="modelValue == item[collectionValue] ? 'btn-primary' : 'btn-outline-primary'"
          :name="name"
          @click="$emit('update:modelValue', item[collectionValue])"
        ) {{ item[collectionLabel] }}

    .d-flex.align-items-start.justify-content-between.ps-2(v-else)
      .flex-grow-1(v-for="column in flatColumns")
        simple-radio(
          v-for="(item, index) in collectionGrouped[column]"
          :disabled="disabled"
          :index="`${column}.${index}`"
          :label="item[collectionLabel]"
          :name="name"
          :value="item[collectionValue]"
          v-model="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)"
        )
</template>
