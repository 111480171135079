<script>
import { difference, map } from 'lodash'

export default {
  name: 'ErrorsControl',
  props: {
    errors: { type: Array, default: () => [] },
    additionalErrors: { type: Array, default: () => [] },
  },
  computed: {
    humanizedErrors() {
      return map(this.errors, e => e.$message)
    },
    shownAdditionalErrors() {
      return difference(this.additionalErrors, this.humanizedErrors)
    },
    invalid() {
      return !!this.errors?.length || !!this.additionalErrors?.length
    },
  },
}
</script>

<template lang="pug">
.flex-1
  template(v-if="invalid")
    .invalid-feedback.lh-1.small(v-for="error in humanizedErrors") {{ error }}
    .invalid-feedback.lh-1.small(v-for="error in shownAdditionalErrors") {{ error }}
</template>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}
</style>
