<script>
export default {
  name: 'MainFooter',
  setup() {
    const year = dayjs().year()
    return {
      year,
    }
  },
  mounted() {
    this.$eventHub.$emit('footer-teleport-node', this.$refs.footerTeleportNode)
  },
  beforeUnmount() {
    this.$eventHub.$emit('footer-teleport-node', null)
  },
}
</script>

<template lang="pug">
footer.px-3.small
  .d-flex.align-items-center.justify-content-between
    .d-flex.align-items-center.justify-content-start.flex-grow-1
      #footer-teleport(ref="footerTeleportNode")
    .copyright.text-nowrap
      span.me-1 FIFA Transport, © {{ year }}
      a(href="https://www.fifa.com/" target="_blank") FIFA
      span.ms-1 All rights reserved
</template>
