<script>
export default {
  name: 'LengthHelpersControl',
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-end.small.lh-1.nowrap
  slot
</template>
