import { appModule } from 'frontend/_config/modules'

const roles = {
  PASSENGER: 'passenger',
  USER: 'user',
  ADMIN: 'admin',
  DRIVER: 'driver',
}

const moduleAccessMap = new Map([
  [roles.PASSENGER, [appModule.PASSENGER]],
  [roles.USER, [appModule.OPERATIONAL]],
  [roles.ADMIN, [appModule.OPERATIONAL, appModule.GIS, appModule.LITE, appModule.PASSENGER]],
  [roles.DRIVER, []],
])

const moduleAccessGuardFactory =
  (scopedRoleNamesExtractor, scopedRolesExtractor = null) =>
  (eouEntity, ouEntity) => {
    const roleNames = scopedRoleNamesExtractor(eouEntity, ouEntity)
    const roles = scopedRolesExtractor ? scopedRolesExtractor(eouEntity, ouEntity) : []
    const accessibleModules = getAccessibleModules(roleNames, roles)
    return {
      accessibleModules,
      hasAccessibleModules: accessibleModules.size !== 0,
      hasMultipleModulesAccess: accessibleModules.size > 1,
      hasPassengerAccess: accessibleModules.has(appModule.PASSENGER),
      hasOperationalAccess: accessibleModules.has(appModule.OPERATIONAL),
      hasLiteAccess: accessibleModules.has(appModule.LITE),
      hasGisAccess: accessibleModules.has(appModule.GIS),
      hasOnlyPassengerAccess: isOnlyPassenger(accessibleModules),
      hasOnlyOperationalAccess: isOnlyOperational(accessibleModules),
    }
  }

function uniqueRoleNamesFromAllEvents(eou, organizationUser) {
  let allRoleNames = [
    ...(organizationUser?.activeEventOrganizationUsers?.flatMap(eou => eou.roles) || []),
  ]
  if (organizationUser?.isAdmin) {
    allRoleNames = [...allRoleNames, ...(organizationUser?.providedRoles || [])]
    allRoleNames.push('admin')
    // allRoleNames.push('passenger')
    // console.log('organizationUser', organizationUser)
    // if (organizationUser.providedRoles.includes('passenger')) {
    // }
  }
  return [...new Set(allRoleNames)]
}

function uniqueRolesFromAllEvents(eou, organizationUser) {
  const allRoles = organizationUser?.activeEventOrganizationUsers?.map(eou => eou.role)
  return [...new Set(allRoles)]
}

function uniqueRoleNamesFromSingleEvents(eou, ou) {
  const allRoles = eou?.roles || []
  if (ou?.isAdmin) {
    allRoles.push('admin')
  }
  return [...new Set(allRoles)]
}

function roleFromSingleEvents(ou, eou) {
  return [eou?.role]
}

function getAccessibleModules(roleNames, roles) {
  const result = new Set(roleNames.flatMap(role => moduleAccessMap.get(role)))
  if (result.size < 3) {
    // for lite
    if (
      roles.find(
        r =>
          !!r &&
          (r.enabledViewTypes.includes('dispatcher-screen') ||
            r.enabledViewTypes.includes('shifts-manager-screen')),
      )
    ) {
      result.add(appModule.LITE)
    }
    // TODO: for GIS when decided to enable it for users
  }
  return result
}

function isOnlyPassenger(accessibleModules) {
  return accessibleModules.has('passenger') && accessibleModules.size === 1
}

function isOnlyOperational(accessibleModules) {
  return accessibleModules.has('operational') && accessibleModules.size === 1
}

const organizationModuleGuard = moduleAccessGuardFactory(
  uniqueRoleNamesFromAllEvents,
  uniqueRolesFromAllEvents,
)
const eventModuleGuard = moduleAccessGuardFactory(
  uniqueRoleNamesFromSingleEvents,
  roleFromSingleEvents,
)

export { eventModuleGuard, organizationModuleGuard }
