<script>
import { Tooltip } from 'bootstrap'
import consumer from 'channels/consumer'
import logoPlaceholder from 'frontend/_assets/trophy_icon.png'
import { getEventOrganizationUser, isAdmin } from 'frontend/common/access-helpers'
import { useModals } from 'frontend/common/modals'
import { useSignOut } from 'frontend/common/sign-out'
import { useEventAccess } from 'frontend/common/use-event-access'
import { DataModelActionType } from 'frontend/roles/enum/DataModelActionType'
import { UsersService } from 'frontend/users/users-service'
import { ref } from 'vue'
import { mapGetters } from 'vuex'

import { organizationModuleGuard } from '../module-access-guard'
import ChatIndicator from './chat-indicator.vue'
import ConnectionPreview from './connection-preview.vue'
import LayoutClock from './layout-clock.vue'
import LayoutSpotlightSearch from './layout-spotlight-search.vue'
import NotSentCcmIndicator from './not-sent-ccm-indicator.vue'
import SystemNotificationsIndicator from './system-notifications/system-notifications-indicator.vue'

export default {
  name: 'MainHeader',
  emits: ['toggleMenu'],
  data() {
    return {
      isMenuToggled: false,
      events: [],
      timeoutToClear: null,
      logoPlaceholder,
      mobileControlsOpen: null,
      impersonateModalOpened: false,
    }
  },
  setup() {
    const isPasswordChangeModalShown = ref(false)
    const blocker = ref(false)
    const isCableConnected = ref(!consumer.connection.disconnected)
    const windowWidth = ref(window.innerWidth)
    const accessData = useEventAccess()
    return {
      service: new UsersService(),
      ...useModals(),
      ...useSignOut(blocker),
      isCableConnected,
      isPasswordChangeModalShown,
      windowWidth,
      ...accessData,
    }
  },
  computed: {
    eventTooltip() {
      let result = this.currentEvent.name || 'Current event'
      if (this.formattedStartDate && this.formattedEndDate) {
        result += `<br>${this.formattedStartDate} - ${this.formattedEndDate}`
      }
      return result
    },
    formattedStartDate() {
      if (this.currentEvent.startDate) {
        return dayjs(this.currentEvent.startDate).format('DD MMM YYYY')
      }
      return null
    },
    formattedEndDate() {
      if (this.currentEvent.endDate) {
        return dayjs(this.currentEvent.endDate).format('DD MMM YYYY')
      }
      return null
    },
    eou() {
      if (this.currentEvent) {
        return getEventOrganizationUser(this.currentUser, this.currentEvent.slug)
      } else {
        return null
      }
    },
    ccmAvailable() {
      if (!this.currentOrganization) return false
      if (!this.currentEvent) return false
      if (!this.eou || !this.can(DataModelActionType.Read, 'ClientCommunicationMessage'))
        return false
      if (this.$route.path.includes('passenger-portal')) return false
      console.log('CCMAvailable!')
      return true
    },
    notificationsAvailable() {
      if (!this.currentOrganization) return false
      if (!this.currentEvent) return false
      if (!this.eou || !this.can(DataModelActionType.Read, 'SystemNotification')) return false
      if (this.$route.path.includes('passenger-portal')) return false
      console.log('NotificationsAvailable!')
      return true
    },
    ...mapGetters('currentContext', ['currentEvent', 'currentCluster']),
    ...mapGetters('session', ['currentUser']),
    userAcronym() {
      if (this.currentUser.name && this.currentUser.surname) {
        return `${this.currentUser.name[0]}${this.currentUser.surname[0]}`
      } else {
        return this.currentUser.email.substring(0, 2).toUpperCase()
      }
    },
    isAdmin() {
      return isAdmin(this.eou)
    },
    isImpersonatingOtherUser() {
      return !!this.currentUser?.impersonatedUserId
    },
    impersonatedUserTooltip() {
      if (this.currentUser?.impersonatedUserLabel) {
        return `You are currently impersonating ${this.currentUser.impersonatedUserLabel}`
      } else {
        return null
      }
    },
    currentOrganization() {
      if (!this.currentUser?.currentOrganizationId) {
        return {}
      } else {
        return (this.currentUser?.organizations || []).find(
          o => o.id == this.currentUser.currentOrganizationId,
        )
      }
    },
    shouldShowEventDropdownActions() {
      if (!this.currentEvent) return false
      if (!this.eou) return false

      if (
        (!this.currentEvent.syncLocationsEnabled ||
          !this.can(DataModelActionType.SyncLocations, 'Event')) &&
        (!this.currentEvent.syncClientsEnabled ||
          !this.can(DataModelActionType.SyncClients, 'Event')) &&
        (!this.currentEvent.syncClientCategoriesEnabled ||
          !this.can(DataModelActionType.SyncClientCategories, 'Event')) &&
        (!this.currentEvent.syncTransportBookingsEnabled ||
          !this.can(DataModelActionType.SyncTransportBookings, 'Event')) &&
        (!this.currentEvent.syncShuttleTransfersEnabled ||
          !this.can(DataModelActionType.SyncShuttleTransfers, 'Event')) &&
        (!this.currentEvent.syncDriversEnabled ||
          !this.can(DataModelActionType.SyncDrivers, 'Event'))
      )
        return false

      return true
    },
    eventDropdownActions() {
      const result = [{ type: 'header', title: 'Click item to sync event resource' }]
      result.push({ type: 'divider' })
      if (
        this.currentEvent?.syncLocationsEnabled &&
        this.can(DataModelActionType.SyncLocations, 'Event')
      ) {
        result.push({
          title: 'Sync Locations',
          clickFn: () => {
            this.$dialogs.confirm().then(() => {
              this.syncResource('sync_locations')
            })
          },
        })
      }
      if (
        this.currentEvent?.syncClientsEnabled &&
        this.can(DataModelActionType.SyncClients, 'Event')
      ) {
        result.push({
          title: 'Sync Clients',
          clickFn: () => {
            this.$dialogs.confirm().then(() => {
              this.syncResource('sync_clients')
            })
          },
        })
      }
      if (
        this.currentEvent?.syncClientCategoriesEnabled &&
        this.can(DataModelActionType.SyncClientCategories, 'Event')
      ) {
        result.push({
          title: 'Sync Client Group Paths',
          clickFn: () => {
            this.$dialogs.confirm().then(() => {
              this.syncResource('sync_client_categories')
            })
          },
        })
      }
      if (
        this.currentEvent?.syncTransportBookingsEnabled &&
        this.can(DataModelActionType.SyncTransportBookings, 'Event')
      ) {
        result.push({
          title: 'Sync Transport Bookings',
          clickFn: () => {
            this.$dialogs.confirm().then(() => {
              this.syncResource('sync_transport_bookings')
            })
          },
        })
      }
      if (
        this.currentEvent?.syncShuttleTransfersEnabled &&
        this.can(DataModelActionType.SyncShuttleTransfers, 'Event')
      ) {
        result.push({
          title: 'Sync Shuttle Transfers',
          clickFn: () => {
            this.$dialogs.confirm().then(() => {
              this.syncResource('sync_shuttle_transfers')
            })
          },
        })
      }
      if (
        this.currentEvent?.syncDriversEnabled &&
        this.can(DataModelActionType.SyncDrivers, 'Event')
      ) {
        result.push({
          title: 'Sync Drivers',
          clickFn: () => {
            this.$dialogs.confirm().then(() => {
              this.syncResource('sync_drivers')
            })
          },
        })
      }
      return result
    },
    userDropdownActions() {
      if (!this.currentUser) return []

      const isOrganizationAdmin = this.currentUser.currentOrganizationUser?.isAdmin
      const currentOrganizationHasNoSSO = this.currentOrganization.sso === false
      const isSuperuser = this.currentUser.isSuperuser

      const result = [{ title: this.currentUser.email, type: 'header' }]

      if (window.location.host == this.$mainDomain) {
        result.push({ type: 'divider' })
        result.push({ type: 'header', title: 'Click item to change your context' })
        ;(this.currentUser.organizations || []).forEach(o => {
          result.push({ title: o.name, clickFn: () => this.chooseOrganization(o) })
        })
      }

      if (isOrganizationAdmin || isSuperuser || this.canAccessMultipleModules) {
        result.push({ type: 'divider' })
        if (this.canAccessMultipleModules) {
          result.push({ title: 'Back to TMM Hub', href: '/tmm-hub' })
        }
        if (isOrganizationAdmin) {
          result.push({ title: 'Administration', href: '/admin', target: '_blank' })
        }
        if (isSuperuser) {
          result.push({ title: 'Super User Panel', href: '/su', target: '_blank' })
        }
      }
      if (currentOrganizationHasNoSSO) {
        result.push({ type: 'divider' })
        result.push({
          title: 'Change password',
          clickFn: () => this.changePasswordModalVisibility(true),
        })
      }
      if (isOrganizationAdmin) {
        result.push({ type: 'divider' })
        if (this.currentUser.impersonatedUserId) {
          result.push({
            title: 'Stop impersonation',
            clickFn: () => this.stopImpersonation(),
          })
        }
        result.push({
          title: `Impersonate ${this.currentUser.impersonatedUserId ? 'another' : 'a'} user`,
          clickFn: () => this.toggleImpersonateModal(true),
        })
      }
      result.push({ type: 'divider' })
      result.push({ title: 'Sign out', clickFn: () => this.doSignOut() })
      return result
    },
    canAccessMultipleModules() {
      const guard = organizationModuleGuard(
        null,
        this.currentUser?.impersonatedCurrentOrganizationUser ||
          this.currentUser?.currentOrganizationUser,
      )

      return guard.hasMultipleModulesAccess
    },
    eventLogo() {
      return this.currentEvent?.logoUrls?.max40x40
        ? `/api${this.currentEvent?.logoUrls?.max40x40}`
        : this.logoPlaceholder
      // : src = "event.logoUrls.max200x150 ? `/api${event.logoUrls.max200x150}` : logoPlaceholder"
    },
    isSmallDevice() {
      return this.windowWidth <= 576 // sm bootstrap $grid-breakpoint
    },
  },
  mounted() {
    this.$eventHub.$on('cable-connection-check', this.updateCableConnectionStatus)
    this.$eventHub.$on('windowResize', this.setWindowWidth)
  },
  unmounted() {
    clearTimeout(this.timeoutToClear)
    this.$eventHub.$off('cable-connection-check', this.updateCableConnectionStatus)
    this.$eventHub.$off('windowResize', this.setWindowWidth)
  },
  methods: {
    toggleImpersonateModal(value) {
      this.impersonateModalOpened = value
    },
    impersonationErrorhandler() {
      this.$error({ message: 'Cannot stop impersonating!' })
    },
    stopImpersonation() {
      this.responseErrors = null
      this.service
        .dispatchAction('impersonate', null, {
          data: {
            impersonatedUserId: null,
          },
          errorHandler: this.impersonationErrorhandler,
        })
        .then(() => {
          this.$success({ message: 'Impersonation stopped!' })
        })
        .catch(errorResponse => {
          console.error(
            'error happened during impersonating',
            errorResponse,
            errorResponse?.response,
          )
        })
    },
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    updateCableConnectionStatus() {
      clearTimeout(this.timeoutToClear)
      this.isCableConnected = !consumer.connection.disconnected
      this.$nextTick(() => {
        const tooltip = Tooltip.getInstance(this.$refs.cableConnectionEl)
        if (tooltip?.show) {
          tooltip.show()
        }
        if (this.isCableConnected) {
          this.timeoutToClear = setTimeout(() => {
            if (tooltip?.hide) {
              tooltip.hide()
            }
          }, 3000)
        }
      })
    },
    doSignOut() {
      this.signOut().then(() => {
        this.$router.push('/')
      })
    },
    chooseOrganization(organization) {
      this.blocker = true
      this.axios({
        method: 'post',
        url: 'session/set_context',
        data: {
          organization_id: organization.id,
        },
      })
        .then(response => {
          this.blocker = false
          this.$store.commit('session/setUser', response.data)
          this.$router.push('/')
          this.$store.dispatch('toast/success', {
            message: `The context has been successfully set to "${organization.name}"`,
          })
        })
        .catch(() => {
          this.$error({
            message: `Can't change context to "${organization.name}"`,
          })
        })
    },
    toggleMenu() {
      this.isMenuToggled = !this.isMenuToggled
      this.$emit('toggleMenu', this.isMenuToggled)
    },
    syncResource(action) {
      this.axios({
        method: 'post',
        url: `events/${this.$route.params.eventSlug}/${action}`,
      })
        .then(() => {
          this.$success({ message: 'The action was scheduled, it is going to be performed soon' })
        })
        .catch(error => {
          const toastPayload = { message: 'The action cannot be scheduled' }
          if (error.response?.status == 422 && error.response?.data?.errors?.base?.length) {
            toastPayload.title = toastPayload.message
            toastPayload.message =
              error.response?.data.errors.base.constructor == Array
                ? error.response?.data.errors.base.join('; ')
                : error.response?.data.errors.base
          }
          this.$error(toastPayload)
        })
    },
    changePasswordModalVisibility(val) {
      this.isPasswordChangeModalShown = val
    },
    toggleMobileControls() {
      this.mobileControlsOpen = !this.mobileControlsOpen
    },
  },
  components: {
    ChatIndicator,
    ConnectionPreview,
    NotSentCcmIndicator,
    LayoutClock,
    LayoutSpotlightSearch,
    SystemNotificationsIndicator,
  },
}
</script>

<template lang="pug">
header.d-flex.align-items-center.bg-white.text-primary.px-2
  .left-section.d-flex.align-items-center
    .icon-wrapper(v-if="$route.name !== 'tmm-hub'")
      i.bi.me-2(
        :class="!!isMenuToggled ? 'bi-x-lg' : 'bi-justify-left'"
        @click="toggleMenu()"
        v-tooltip="'Extend/hide menu'"
      )
    h5.align-items-center.justify-content-center.bg-white.text-primary.custom-app-name {{ currentOrganization?.customAppName || 'TMM' }}

  .right-section.d-flex.align-items-center.justify-content-between
    .event-info-wrapper
      template(v-if="!!currentEvent")
        img.logo(:src="eventLogo")
        span.event-title(
          v-tooltip.options="{ title: eventTooltip, placement: 'bottom', html: true }"
        ) {{ currentEvent.name }}
        //- v-tooltip="eventTooltip")
        .d-inline-flex(v-if="shouldShowEventDropdownActions")
          dropdown(:actions="eventDropdownActions")
            button.btn.btn-link.text-primary.py-0.px-0.ms-1(
              type="button"
              v-tooltip.options="{ title: 'Event Actions', placement: 'left' }"
            )
              i.bi-three-dots-vertical
      i.bi-arrow-right.pe-2.ps-1(v-if="!!currentCluster")
      span(v-if="!!currentCluster" v-tooltip="'Current cluster'") {{ currentCluster.name }}

    .controls-wrapper
      .controls(
        :class="{ 'mobile-controls-open': mobileControlsOpen, 'mobile-controls-closed': mobileControlsOpen === false, 'is-mobile-view': isSmallDevice }"
      )
        .cable-connection-status(
          ref="cableConnectionEl"
          v-if="!!currentOrganization"
          :class="{ connected: isCableConnected }"
          v-tooltip.options="{ placement: 'bottom', title: isCableConnected ? 'The app is online' : 'The app is offline, check your Internet connection' }"
        )
        layout-spotlight-search(v-if="!!currentUser && $route?.params?.eventSlug?.length")
        connection-preview(v-if="currentEvent")
        not-sent-ccm-indicator(v-if="ccmAvailable")
        //- chat-indicator(v-if="!!currentUser && $route?.params?.eventSlug?.length")
        system-notifications-indicator(v-if="notificationsAvailable")
        layout-clock
        button.btn-close.btn-close-controls(@click="toggleMobileControls()" type="button")

      button.btn.text-primary.ps-3.pe-3.py-2.px-2.border-0(
        v-if="isSmallDevice"
        @click="toggleMobileControls()"
      )
        i(:class="mobileControlsOpen ? 'bi-x-diamond-fill' : 'bi-x-diamond'")
      dropdown(v-if="!!currentOrganization" :actions="userDropdownActions")
        component(
          v-if="isPasswordChangeModalShown"
          :is="formModals.ChangePassword"
          @closed="() => changePasswordModalVisibility(false)"
          size="md"
        )
        .dropdown-toggle.btn.btn-sm.d-flex.align-items-center.justify-content-center(
          :class="isImpersonatingOtherUser ? 'blink' : 'btn-primary'"
          v-tooltip.options="{ title: impersonatedUserTooltip, placement: 'bottom' }"
        )
          i.fas.fa-user-secret.me-2.fs-6(v-if="isImpersonatingOtherUser")
          | {{ userAcronym }}
template(v-if="impersonateModalOpened")
  component(
    :is="formModals.Impersonate"
    :item="currentUser"
    @closed="() => toggleImpersonateModal(false)"
    name="impersonation-modal"
    size="lg"
  )
</template>

<style lang="scss" scoped>
@keyframes blink {
  0% {
    background-color: var(--custom-primary);
    color: white;
  }
  50% {
    background-color: orange;
    color: black;
  }
  100% {
    background-color: var(--custom-primary);
    color: white;
  }
}
.blink {
  animation: blink 2s ease-in-out infinite;
}
</style>
