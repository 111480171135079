<script setup lang="ts">
import { IMappedSearchCriteriaCategory } from 'frontend/filters/types/IMappedSearchCriteriaCategory'
import { computed } from 'vue'

interface Emits {
  (e: 'categoryClick', value: symbol): void
}
const emit = defineEmits<Emits>()

interface Props {
  categories: IMappedSearchCriteriaCategory[]
  categoryId: symbol
  currentCategoryId?: symbol
}
const props = withDefaults(defineProps<Props>(), {
  level: 0,
  currentCategoryId: null,
})

const category = computed<IMappedSearchCriteriaCategory>(() => {
  return props.categories.find(singleCategory => singleCategory.id === props.categoryId)
})

const childrenCategories = computed<IMappedSearchCriteriaCategory[]>(() => {
  return props.categories.filter(singleCategory => {
    return singleCategory.parentCategoryId === props.categoryId
  })
})
</script>

<template>
  <div class="criteria-builder-category">
    <div
      class="arrows-navigable-item header"
      tabindex="-1"
      :class="{
        'is-active': currentCategoryId === category.id,
        'is-active-filter-count-visible': category.activeFilterCountIncludingChildren,
      }"
      @click="emit('categoryClick', category.id)"
    >
      <div class="label">
        <span
          v-for="({ text, isMatch }, i) in category.textParts"
          :key="i"
          :class="{ 'is-match': isMatch }"
          >{{ text }}</span
        >
      </div>
      <div v-show="category.activeFilterCountIncludingChildren" class="active-filter-count">
        {{ category.activeFilterCountIncludingChildren }}
      </div>
      <i
        v-if="childrenCategories.length"
        class="arrow fas fa-chevron-right"
        :class="{ 'is-expanded': category.isExpanded }"
      />
    </div>
    <Transition name="expand-collapse">
      <div v-if="childrenCategories.length && category.isExpanded" class="children">
        <CriteriaBuilderCategoryComponent
          v-for="category in childrenCategories"
          :key="category.id"
          :categories="props.categories"
          :category-id="category.id"
          :current-category-id="currentCategoryId"
          @category-click="emit('categoryClick', $event)"
        />
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.expand-collapse-enter-active,
.expand-collapse-leave-active {
  transition: all 0.25s cubic-bezier(0, 0.6, 0.4, 1);
  max-height: v-bind('childrenCategories.length * 28 + "px"');
  opacity: 1;
  transform: none;
}

.expand-collapse-enter-from,
.expand-collapse-leave-to {
  opacity: 0;
  max-height: 0;
  transform: translateY(-8px);
}

.header {
  display: grid;
  grid-template-columns: 1fr 12px;

  &.is-active-filter-count-visible {
    grid-template-columns: 1fr auto 12px;
  }
  align-items: center;
  padding: 0px 12px 0px v-bind('(category.allParentIds.length + 1) * 16 + "px"');
  border-radius: 8px;
  cursor: pointer;
  height: 28px;

  .label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover,
  &:focus {
    background: hsla(208, 57%, 95%, 1);
    color: hsla(206, 58%, 55%, 1);
  }

  &.is-active {
    background: hsla(208, 57%, 95%, 1);
    color: hsla(206, 58%, 55%, 1);
  }
}

.arrow {
  font-size: 0.65rem;
  transition: transform 0.1s cubic-bezier(0.1, 0.6, 0.4, 1.6);
  justify-self: flex-end;

  &.is-expanded {
    transform: rotate(90deg);
  }
}

.children {
  overflow: hidden;
}

.label span {
  &.is-match {
    color: #4994ce;
    background: hsl(208deg, 57%, 95%);
    border-radius: 8px;
  }
}

.active-filter-count {
  height: 18px;
  min-width: 18px;
  border-radius: 100px;
  background: #4994ce;
  color: white;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}
</style>
