import IsCustomWithEndTime from 'frontend/client-group-activities/_client-group-activity-is-custom-with-end-time.vue'
import ActivityRequirements from 'frontend/client-group-activities/activity-requirements.vue'
import { Cluster } from 'frontend/clusters'
import Text from 'frontend/common/text.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import Location from 'frontend/locations/location.vue'
import TransportationPoint from 'frontend/transportation-points/transportation-point.vue'
import { markRaw } from 'vue'

export const ClientGroupActivityDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'title',
    'has_police_escort',
    'notes',
    'start_at',
    'end_at',
    'is_custom',
    'updated_at',
    'created_at',
    {
      client_group: ['id', 'name', 'border_color', 'font_color', 'background_color'],
    },
    { start_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
    { end_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
    {
      activity_requirements: ['id', 'quantity', { vehicle_type: ['id', 'name'] }],
    },
    { start_transportation_point: ['id', 'name'] },
    { end_transportation_point: ['id', 'name'] },
    {
      day_labels: ['id', 'name', 'font_color', 'background_color', 'border_color'],
    },
  ],
  subscriptions: {
    items: 'ClientGroupActivitiesChannel',
    clientGroups: 'ClientGroupsSimpleChannel',
    locations: 'LocationsSimpleChannel',
    activityRequirements: 'ActivityRequirementsSimpleChannel',
    transportationPoints: 'TransportationPointsSimpleChannel',
    clusters: 'ClustersSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'title',
    'startAt',
    'isCustomWithEndAt',
    'policeEscort',
    'clientGroup',
    'pickUpLocation',
    'dropOffLocation',
    'pickUpTp',
    'dropOffTp',
    'dayLabels',
    'activityRequirements',
    'notes',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    dayLabels: {
      label: 'Occasion',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: cga => ({
          items: cga.dayLabels,
          limit: 5,
          component: 'erebus-label',
          componentProps: c => ({ item: c }),
        }),
      },
    },

    title: {
      label: 'Title',
      header: {
        sort: 'title',
      },
      cell: {
        kind: 'simple',
        path: 'title',
      },
    },
    startAt: {
      label: 'Start Datetime',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.startAt,
          tz: clientGroupActivity.startLocation?.timeZone,
        }),
      },
    },
    startDate: {
      label: 'Start Date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.startAt,
          format: 'dateOnly',
          tz: clientGroupActivity.startLocation?.timeZone,
        }),
      },
    },
    startTime: {
      label: 'Start Time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.startAt,
          format: 'timeOnly',
          tz: clientGroupActivity.startLocation?.timeZone,
        }),
      },
    },
    isCustom: {
      label: 'Exclusive use',
      header: {
        sort: 'isCustom',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: clientGroupActivity => ({ item: !!clientGroupActivity.isCustom }),
      },
    },
    isCustomWithEndAt: {
      label: 'Exclusive use (with end time)',
      header: {
        sort: 'isCustom',
      },
      cell: {
        kind: 'component',
        component: markRaw(IsCustomWithEndTime),
        componentProps: clientGroupActivity => ({ item: clientGroupActivity }),
      },
    },
    endAt: {
      label: 'End Datetime (only for exclusive use)',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.endAt,
          tz: clientGroupActivity.endLocation?.timeZone,
        }),
      },
    },
    endDate: {
      label: 'End Date (only for exclusive use)',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.endAt,
          format: 'dateOnly',
          tz: clientGroupActivity.endLocation?.timeZone,
        }),
      },
    },
    endTime: {
      label: 'End Time (only for exclusive use)',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.endAt,
          format: 'timeOnly',
          tz: clientGroupActivity.endLocation?.timeZone,
        }),
      },
    },
    policeEscort: {
      label: 'Police Escort',
      header: {
        sort: 'hasPoliceEscort',
      },
      cell: {
        kind: 'component',
        component: 'true-false-label',
        componentProps: clientGroupActivity => ({ item: clientGroupActivity.hasPoliceEscort }),
      },
    },
    clientGroup: {
      label: 'Client group',
      header: {
        sort: 'clientGroupName',
      },
      cell: {
        kind: 'component',
        component: 'erebus-label',
        componentProps: clientGroupActivity => ({ item: clientGroupActivity.clientGroup }),
      },
    },
    pickUpLocation: {
      label: 'Pick-up location',
      header: {
        sort: 'startLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: clientGroupActivity => ({
          minWidth: 250,
          location: clientGroupActivity.startLocation,
        }),
      },
    },
    startCluster: {
      label: 'Pick-up cluster',
      header: {
        sort: 'startLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({ cluster: item?.startLocation?.cluster }),
      },
    },
    dropOffLocation: {
      label: 'Drop-off location',
      header: {
        sort: 'endLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: clientGroupActivity => ({
          minWidth: 250,
          location: clientGroupActivity.endLocation,
        }),
      },
    },
    endCluster: {
      label: 'Drop-off cluster',
      header: {
        sort: 'endLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({ cluster: item?.endLocation?.cluster }),
      },
    },
    pickUpTp: {
      label: 'Pick-up TP',
      header: {
        sort: 'startTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: clientGroupActivity => ({
          transportationPoint: clientGroupActivity.startTransportationPoint,
        }),
      },
    },
    dropOffTp: {
      label: 'Drop-off TP',
      header: {
        sort: 'endTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: clientGroupActivity => ({
          transportationPoint: clientGroupActivity.endTransportationPoint,
        }),
      },
    },
    activityRequirements: {
      label: 'Activity requirements',
      header: {},
      cell: {
        kind: 'component',
        component: markRaw(ActivityRequirements),
        componentProps: clientGroupActivity => ({
          items: clientGroupActivity.activityRequirements,
        }),
      },
    },
    notes: {
      label: 'Notes',
      header: {
        sort: 'notes',
      },
      cell: {
        kind: 'component',
        component: markRaw(Text),
        componentProps: clientGroupActivity => ({
          text: clientGroupActivity.notes,
        }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
