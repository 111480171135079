<script>
export default {
  name: 'ShowNumberOfHours',
  props: {
    numberOfHours: { type: Number, required: true },
  },
}
</script>

<template lang="pug">
span {{ numberOfHours }}{{ (numberOfHours || 0) > 0 ? 'h' : '' }}
</template>
