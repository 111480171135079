import { InterfaceType } from 'frontend/_setup/setupContainer/constants/InterfaceType'
import { IDataModelColumnConfig } from 'frontend/dataModels/types/IDataModelColumnConfig'
import { inject, injectable } from 'inversify'
import { type Ref } from 'vue'
import { type RouteLocationNormalizedLoaded } from 'vue-router'

import { IDataModelTypeStrategy } from '../../../types/IDataModelTypeStrategy'

@injectable()
export class LocationTypeDataModelTypeStrategy implements IDataModelTypeStrategy {
  public readonly baseUrl: string

  constructor(
    @inject(InterfaceType.constants.CurrentRoute)
    private currentRoute: Ref<RouteLocationNormalizedLoaded>,
  ) {
    this.baseUrl = currentRoute.value.params.eventSlug
      ? `/events/${currentRoute.value.params.eventSlug}/location_types`
      : '/location_types'
  }
  canEditColumns: boolean
  getColumnConfig(): IDataModelColumnConfig | null {
    return null
  }
}
