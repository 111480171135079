<script>
import { ClientCategoriesTable } from 'frontend/client-categories'
import { ClientsTable } from 'frontend/clients'
import { useBlocker } from 'frontend/common'
import ExportableTable from 'frontend/common/_exportable-table.vue'
import SimpleTable from 'frontend/common/_simple-table.vue'
import { DriversTable } from 'frontend/drivers'
import { LocationsTable } from 'frontend/locations'
import { ShuttleTransfersTable } from 'frontend/shuttle-transfers'
import { NOTIFICATIONS } from 'frontend/system-notifications'
import { TransportBookingsTable } from 'frontend/transport-bookings'

import { SystemNotificationsService } from './system-notifications-service'

//@vue/component
export default {
  name: 'SystemNotificationModal',
  emits: ['closed'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
    },
  },
  data() {
    return {
      localSystemNotification: null,
      result: null,
      parsingErrorsColumns: [
        { name: 'Error', attribute: 'error' },
        { name: 'Id', attribute: 'id' },
        { name: 'Remote Id', attribute: 'remoteId' },
        { name: 'Validation Error', attribute: 'validationErrors' },
      ],
      driversTabIndex: 0,
    }
  },
  setup() {
    const { blocker, isBlocked } = useBlocker()
    const systemNotificationsService = new SystemNotificationsService({ blocker })

    return { isBlocked, systemNotificationsService }
  },
  computed: {
    payload() {
      return this.localSystemNotification?.systemAction?.contextPayload || {}
    },
    modalHeader() {
      if (this.localSystemNotification?.content === 'integration_synclocations_finished')
        return 'Sync locations'
      if (this.localSystemNotification?.content === 'integration_syncclients_finished')
        return 'Sync clients'
      if (this.localSystemNotification?.content === 'integration_clientcategories_finished')
        return 'Sync group paths'
      if (this.localSystemNotification?.content === 'integration_synctransportbookings_finished')
        return 'Sync transport bookings'
      if (this.localSystemNotification?.content === 'integration_syncshuttletransfers_finished')
        return 'Sync shuttle transfers'
      if (this.localSystemNotification?.title && NOTIFICATIONS[this.localSystemNotification?.title])
        return NOTIFICATIONS[this.localSystemNotification?.title]
      if (this.localSystemNotification?.content) return this.localSystemNotification?.title
      return null
    },
    clientsSync() {
      return this.localSystemNotification?.content === 'integration_syncclients_finished'
    },
    transportBookingsSync() {
      return this.localSystemNotification?.content === 'integration_synctransportbookings_finished'
    },
    locationsSync() {
      return this.localSystemNotification?.content === 'integration_synclocations_finished'
    },
    shuttleTransfersSync() {
      return this.localSystemNotification?.content === 'integration_syncshuttletransfers_finished'
    },
    driversSync() {
      return this.localSystemNotification?.content === 'integration_syncdrivers_finished'
    },
    clientsOrTransportBookingsOrShuttleTransfersOrDriversSync() {
      return (
        this.clientsSync ||
        this.transportBookingsSync ||
        this.shuttleTransfersSync ||
        this.driversSync
      )
    },
    parsingErrorsFileName() {
      let fileName
      if (this.clientsSync) {
        fileName = 'clients_sync_parsing_errors'
      } else if (this.transportBookingsSync) {
        fileName = 'transport_bookings_parsing_errors'
      } else if (this.shuttleTransfersSync) {
        fileName = 'shuttle_transfers_parsing_errors'
      } else if (this.driversSync) {
        fileName = 'drivers_parsing_errors'
      }
      return fileName
    },
    deletionSuccessesFileName() {
      let fileName
      if (this.clientsSync) {
        fileName = 'clients_sync_deletion_successes'
      } else if (this.transportBookingsSync) {
        fileName = 'transport_bookings_deletion_successes'
      } else if (this.shuttleTransfersSync) {
        fileName = 'shuttle_transfers_deletion_successes'
      } else if (this.driversSync) {
        fileName = 'drivers_deletion_successes'
      }
      return fileName
    },
    payloadArrayParsingSuccesses() {
      return this.payload?.parsingSuccesses?.length
    },
    payloadArrayParsingErrors() {
      return this.payload?.parsingErrors?.length
    },
    payloadArrayDeletionSuccesses() {
      return this.payload?.deletionSuccesses?.length
    },
    payloadDuplicatesSize() {
      return this.payload?.duplicates?.length
    },
    payloadObjectParsingErrors() {
      return Object.keys(this.payload?.parsingErrors)
    },
    payloadObjectDeletionErrors() {
      return Object.keys(this.payload?.deletionErrors)
    },
    payloadSuccessfullySaved() {
      return this.payload?.successfullySaved?.length
    },
    payloadErrors() {
      return this.payload?.errors?.length
    },
    payloadRequiredFulfillment() {
      return this.payload?.requiredFulfillment?.length
    },
    deletionErrors() {
      const errors = this.payload.deletionErrors

      return Array.isArray(errors) ? errors : Object.values(errors, {})
    },
  },
  mounted() {
    this.fetchSystemNotification()
  },
  methods: {
    async fetchSystemNotification() {
      if (!this.item.id) {
        this.localSystemNotification = {}
      } else {
        this.localSystemNotification = await this.systemNotificationsService.read(this.item.id, {
          data: {
            fields: [
              'id',
              'title',
              'status',
              'additional_payload',
              'total_rows',
              'current_row',
              'content',
              'read',
              'resource_type',
              'created_at',
              'updated_at',
              'cluster_id',
              'event_organization_user_id',
              'resource_id',
              'staging_area_id',
              { system_action: ['id', 'context_payload'] },
            ],
          },
        })
      }
    },
    emitCurrentResult() {
      this.$emit('closed', this.result)
    },
  },
  components: {
    ExportableTable,
    LocationsTable,
    ClientCategoriesTable,
    ClientsTable,
    TransportBookingsTable,
    ShuttleTransfersTable,
    DriversTable,
    SimpleTable,
  },
}
</script>

<template lang="pug">
modal(:blocker="isBlocked" :size="size" @closed="emitCurrentResult()")
  template(#header="modal")
    span {{ modalHeader }}
    span.mx-2 -
    formatted-time(:t="localSystemNotification?.createdAt")
  template(#default="modal")
    .row(v-if="localSystemNotification")
      .col
        tabs-wrapper(v-if="payload")
          //- template(v-if="clientsOrTransportBookingsOrShuttleTransfersOrDriversSync")
          tab(v-if="clientsOrTransportBookingsOrShuttleTransfersOrDriversSync" title="Details")
            .row.text-danger(v-if="payloadDuplicatesSize")
              .col-3
                p Found duplicates
              .col-9
                b {{ payload.duplicates.length }}
                .small.text-black-50 by remote_id: {{ payload.duplicates.join(', ') }}
            .row
              .col-3
                p Parsing successes
              .col-9
                b(v-if="payloadArrayParsingSuccesses") {{ payload.parsingSuccesses.length }}
                b(v-else) 0
            .row
              .col-3
                p Parsing errors
              .col-9(v-if="clientsSync")
                b(v-if="payloadArrayParsingErrors") {{ payload.parsingErrors.length }}
                b(v-else) 0
              .col-9(v-else)
                b(v-if="payloadObjectParsingErrors") {{ Object.keys(payload.parsingErrors).length }}
            .row
              .col-3
                p Deletion successes
              .col-9
                b(v-if="payloadArrayDeletionSuccesses") {{ payload.deletionSuccesses.length }}
                b(v-else) 0
            .row
              .col-3
                p Deletion errors
              .col-9
                b(v-if="payloadObjectDeletionErrors") {{ Object.keys(payload.deletionErrors).length }}
            .row
              .col-3
                p Created Date
              .col-9
                p
                  formatted-time(:t="localSystemNotification.createdAt" format="long")
            .row
              .col-3
                p Updated Date
              .col-9
                p
                  formatted-time(:t="localSystemNotification.updatedAt" format="long")

          tab(
            v-if="clientsOrTransportBookingsOrShuttleTransfersOrDriversSync && payloadArrayParsingSuccesses"
            title="Parsing successes"
          )
            clients-table(v-if="clientsSync" :prefilters="{ id_in: payload.parsingSuccesses }")
            transport-bookings-table(
              v-else-if="transportBookingsSync"
              :prefilters="{ loose: true, id_in: payload.parsingSuccesses }"
            )
            shuttle-transfers-table(
              v-else-if="shuttleTransfersSync"
              :prefilters="{ loose: true, id_in: payload.parsingSuccesses }"
            )
            tabs-wrapper(v-else-if="driversSync" v-model:selectedIndex="driversTabIndex")
              tab(title="Already useable")
                drivers-table(
                  v-if="driversTabIndex == 0"
                  :prefilters="{ id_in: payload.parsingSuccesses }"
                  insideTab
                )
              tab(title="Incomplete")
                drivers-table(
                  v-if="driversTabIndex == 1"
                  :prefilters="{ drafts: true, id_in: payload.parsingSuccesses }"
                  insideTab
                )

          tab(
            v-if="clientsOrTransportBookingsOrShuttleTransfersOrDriversSync && payloadObjectParsingErrors.length > 0"
            title="Parsing Errors"
          )
            exportable-table(
              :columns="parsingErrorsColumns"
              :exportName="parsingErrorsFileName"
              :rows="Object.values(payload.parsingErrors || {})"
              copyable
            )

          tab(
            v-if="clientsOrTransportBookingsOrShuttleTransfersOrDriversSync && payloadArrayDeletionSuccesses"
            title="Deletion Successes"
          )
            exportable-table(
              :columns="[ { name: 'Deletion Success ID', attribute: 'id' }, { name: 'Remote ID', attribute: 'remoteId' }, ]"
              :exportName="deletionSuccessesFileName"
              :rows="payload.deletionSuccesses"
              copyable
            )

          tab(
            v-if="clientsOrTransportBookingsOrShuttleTransfersOrDriversSync && payloadObjectDeletionErrors.length > 0"
            title="Deletion Errors"
          )
            simple-table(:values="deletionErrors" columnName="Deletion Error")

          tab(v-if="!clientsOrTransportBookingsOrShuttleTransfersOrDriversSync" title="Details")
            .row(v-if="payload.totalRows || payload.totalRows == 0")
              .col-3
                p Total rows
              .col-9
                b {{ payload.totalRows || 0 }}
            .row(v-if="payload.successfullySaved")
              .col-3
                p Successes
              .col-9
                b(v-if="payloadSuccessfullySaved") {{ payload.successfullySaved.length }}
                b(v-else) 0
            .row
              .col-3
                p Errors
              .col-9
                p(v-if="payloadErrors")
                  b.me-1 {{ payload.errors.length }}
                  span(v-if="payload.errors[0]?.length < 100") ({{ payload.errors[0] }}...)
                b(v-else) 0
            .row(v-if="locationsSync")
              .col-3
                p Required Fulfillment
              .col-9
                b.me-1(v-if="payloadRequiredFulfillment") {{ payload.requiredFulfillment.length }} (latitude or longitude are empty)
                b(v-else) 0
            .row
              .col-3
                p Created Date
              .col-9
                p
                  formatted-time(:t="localSystemNotification.createdAt" format="long")
            .row
              .col-3
                p Updated Date
              .col-9
                p
                  formatted-time(:t="localSystemNotification.updatedAt" format="long")

          tab(
            v-if="!clientsOrTransportBookingsOrShuttleTransfersOrDriversSync && payloadSuccessfullySaved"
            title="Successes"
          )
            locations-table(
              v-if="locationsSync"
              :prefilters="{ id_in: payload.successfullySaved }"
            )
            client-categories-table(v-else :prefilters="{ id_in: payload.successfullySaved }")

          tab(
            v-if="!clientsOrTransportBookingsOrShuttleTransfersOrDriversSync && payloadErrors"
            title="Errors"
          )
            simple-table(:values="payload.errors" columnName="Error")

          tab(v-if="locationsSync && payloadRequiredFulfillment" title="Required Fulfillment")
            locations-table(:prefilters="{ id_in: payload.requiredFulfillment }")

  template(#footer="modal")
    dismiss-button(@click="modal.close()")
</template>
