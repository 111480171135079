<script>
export default {
  name: 'DataTableBatchActions',
  props: {
    actions: { type: Array, default: () => [] },
    total: { type: Number, default: 0 },
    itemIdsOnPage: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    inProgress: { type: Boolean, default: false },
    allRecordsTextAddon: { type: String, default: 'records' },
  },
  data() {
    return {
      allChecked: false,
      allOnPageChecked: false,
      checkedItems: [],
    }
  },
  computed: {
    currentNumberOfCheckedItems() {
      if (this.allChecked) {
        return this.total
      } else {
        return this.checkedItems.length
      }
    },
    actionsWithLocalItems() {
      const result = [...this.actions]
      result.unshift({ type: 'divider' })
      if (!this.allChecked)
        result.unshift({
          title: `Check all ${this.total} ${this.allRecordsTextAddon}`,
          clickFn: () => {
            this.toggleAll(true)
          },
        })
      if (this.allChecked || this.checkedItems.length > 0)
        result.unshift({
          title: 'Uncheck',
          clickFn: () => {
            this.toggleAll(false)
          },
        })
      return result
    },
  },
  watch: {
    itemIdsOnPage() {
      this.recomputeAllOnPageChecked()
    },
  },
  methods: {
    cleanAll() {
      this.allChecked = false
      this.checkedItems.length = 0
      this.allOnPageChecked = false
    },
    recomputeAllOnPageChecked() {
      let result
      if (this.allChecked) {
        this.checkedItems = [...this.itemIdsOnPage]
        result = true
      } else if (
        this.itemIdsOnPage.length > 0 &&
        Array.from(new Set([this.checkedItems, this.itemIdsOnPage].flat())).length ==
          this.checkedItems.length
      ) {
        result = true
      } else {
        result = false
      }
      this.allOnPageChecked = result
      return result
    },
    toggleAll(newValue) {
      this.allChecked = newValue
      if (!newValue) {
        this.checkedItems = []
      }
      this.recomputeAllOnPageChecked()
    },
    toggleAllOnPage(newValue) {
      if (newValue) {
        if (!this.allChecked) {
          this.checkedItems = Array.from(new Set([this.checkedItems, this.itemIdsOnPage].flat()))
        }
      } else {
        this.checkedItems = this.checkedItems.filter(el => !this.itemIdsOnPage.includes(el))
      }
      return this.recomputeAllOnPageChecked()
    },
    toggledRow(itemId) {
      if (this.allChecked) {
        return
        // this.checkedItems = [...this.itemIdsOnPage]
      }
      if (this.checkedItems.includes(itemId)) {
        this.checkedItems.splice(this.checkedItems.indexOf(itemId), 1)
      } else {
        this.checkedItems.push(itemId)
      }
      this.recomputeAllOnPageChecked()
    },
  },
}
</script>

<template lang="pug">
dropdown.ms-2(:actions="actionsWithLocalItems" alignRight)
  .btn.btn-sm.btn-outline-primary.dropdown-toggle(
    :class="{ disabled: disabled }"
    name="BatchActions"
  )
    .d-inline-flex.align-items-center
      .me-2
        .spinner-border.text-info.me-2(v-if="inProgress")
        span.data-table-batch-actions-label Batch actions
        span.data-table-batch-actions-label-small Batch
        span.bold.me-1(v-if="currentNumberOfCheckedItems > 0") |{{ currentNumberOfCheckedItems }}
</template>

<style scoped lang="scss">
@import 'frontend_stylesheets/_variables.scss';
.spinner-border {
  height: 1em;
  width: 1em;
  margin-top: 2px;
  display: inline-block;
}

.data-table-batch-actions-label {
  @include for-size(small-and-medium-devices) {
    display: none;
  }
}

.data-table-batch-actions-label-small {
  display: none;
  @include for-size(small-and-medium-devices) {
    display: inline;
  }
}
</style>
