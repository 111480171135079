import { defineAsyncComponent } from 'vue'

const TransportationPointImportFormModal = defineAsyncComponent(() =>
  import('./transportation-point-import-form-modal.vue'),
)
const TransportationPointImportShow = defineAsyncComponent(() =>
  import('./transportation-point-import-show.vue'),
)
const TransportationPointImportsIndex = defineAsyncComponent(() =>
  import('./transportation-point-import-index.vue'),
)
const TransportationPointImportsTable = defineAsyncComponent(() =>
  import('./transportation-point-imports-table.vue'),
)

const STATES = [
  { value: 'idle', label: 'Idle' },
  { value: 'rows_loading', label: 'Loading rows' },
  { value: 'rows_not_loaded', label: 'Error while loading rows' },
  { value: 'rows_loaded', label: 'Rows loaded, waiting for the import' },
  { value: 'in_progress', label: 'Import in progress' },
  { value: 'finished', label: 'Import finished' },
  { value: 'finished_with_errors', label: 'Import finished (with errors)' },
  { value: 'major_error', label: 'Major error, cannot continue' },
]

export {
  STATES,
  TransportationPointImportFormModal,
  TransportationPointImportShow,
  TransportationPointImportsIndex,
  TransportationPointImportsTable,
}
