<script>
import { ResourceService } from './services/resource-service'

export default {
  name: 'RestoreItemButton',
  inheritAttrs: false,
  emits: ['restored', 'restore', 'blocker'],
  props: {
    item: { type: Object },
    path: {
      type: String,
    },
    service: {
      type: ResourceService,
    },
    table: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmAction(action) {
      this.$dialogs.confirm('Are you sure that you want to restore chosen item?').then(action)
    },
    restoreItem() {
      if (this.path) {
        this.$emit('blocker', true)
        this.axios({
          method: 'post',
          url: this.path,
        })
          .then(() => {
            this.$success({ message: 'The item has been restored' })
            this.$emit('restored', true)
          })
          .catch(err => {
            console.error(err)
            const message =
              err?.response?.data?.errors?.base?.join('; ') ?? "Can't restore the item"
            this.$error({ message })
          })
          .finally(() => {
            this.$emit('blocker', false)
          })
      } else {
        this.$emit('restore')
      }
    },
    onRestoreClick() {
      this.confirmAction(this.restoreItem)
    },
  },
}
</script>

<template lang="pug">
.d-inline-block(@click="onRestoreClick($event)")
  slot
    template(v-if="table")
      data-table-restore-button(v-bind="$attrs")
    template(v-else)
      restore-button(v-bind="$attrs")
</template>

<style scoped lang="scss"></style>
