export class BaseService {
  constructor({ blocker, blockerKey, apiProvider }) {
    this.blocker = blocker
    this.blockerKey = blockerKey
    this.apiProvider = apiProvider
  }

  /**
   * @param {string} method
   * @param {(error: Error) => any} errorHandler
   * @param {{url?: string, method?: Method, baseURL?: string, transformRequest?: AxiosTransformer | AxiosTransformer[], transformResponse?: AxiosTransformer | AxiosTransformer[], headers?: any, params?: any, paramsSerializer?: (params: any) => string, data?: any, timeout?: number, timeoutErrorMessage?: string, withCredentials?: boolean, adapter?: AxiosAdapter, auth?: AxiosBasicCredentials, responseType?: ResponseType, xsrfCookieName?: string, xsrfHeaderName?: string, onUploadProgress?: (progressEvent: any) => void, onDownloadProgress?: (progressEvent: any) => void, maxContentLength?: number, validateStatus?: ((status: number) => boolean) | null, maxBodyLength?: number, maxRedirects?: number, socketPath?: string | null, httpAgent?: any, httpsAgent?: any, proxy?: AxiosProxyConfig | false, cancelToken?: CancelToken, decompress?: boolean}} config
   * @returns
   */
  async _makeCall(method, errorHandler, config, raw = false) {
    this._block()
    return this.apiProvider({ method, ...config })
      .then(res => {
        return raw ? res : res.data
      })
      .catch(error => {
        if (errorHandler) {
          errorHandler(error)
        }
        throw error
      })
      .finally(() => this._unblock())
  }

  /** @protected */
  _block() {
    if (this.blocker) this.blocker[this.blockerKey] = true
  }

  /** @protected */
  _unblock() {
    if (this.blocker) delete this.blocker[this.blockerKey]
  }
}
