import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const ASLDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name'
  ],
  subscriptions: {
    items: 'AslsChannel',
  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}