import { onUnmounted, ref } from 'vue'

export function useCancellable() {
  const cancellable = ref({})

  const handleCancel = error => {
    if (!error.cancelled) {
      throw error
    }
    return error
  }

  onUnmounted(() => {
    Object.entries(cancellable.value).forEach(([key, cancel]) => {
      if (cancel?.constructor == Function && key?.constructor == String) {
        cancel(key)
      }
    })
  })

  return { cancellable, handleCancel }
}
