import { defineAsyncComponent } from 'vue'

const Driver = defineAsyncComponent(() => import('./driver.vue'))
const DriverFormModal = defineAsyncComponent(() => import('./driver-form-modal.vue'))
const DriversIndex = defineAsyncComponent(() => import('./drivers-index.vue'))
const DriversSelect = defineAsyncComponent(() => import('./drivers-select.vue'))
const DriversTable = defineAsyncComponent(() => import('./drivers-table.vue'))
const DriverDetails = defineAsyncComponent(() => import('./driver-details.vue'))
const SuggestedVehicles = defineAsyncComponent(() => import('./suggested-vehicles.vue'))

const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Mandarin Chinese', value: 'zh' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Spanish', value: 'es' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Bangla/Bengali', value: 'bn' },
  { label: 'Russian', value: 'ru' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Indonesian', value: 'in' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Wolof (Dakar)', value: 'wo' },
  { label: 'Polish', value: 'pl' },
]

export {
  Driver,
  DriverDetails,
  DriverFormModal,
  DriversIndex,
  DriversSelect,
  DriversTable,
  LANGUAGES,
  SuggestedVehicles,
}
