import { defineAsyncComponent } from 'vue'

const Location = defineAsyncComponent(() => import('./location.vue'))
const LocationWithTp = defineAsyncComponent(() => import('./location-with-tp.vue'))
const LocationFormModal = defineAsyncComponent(() => import('./location-form-modal.vue'))
const CustomLocationFormPart = defineAsyncComponent(() => import('./custom-location-form-part.vue'))
const LocationModal = defineAsyncComponent(() => import('./location-modal.vue'))
const LocationDetails = defineAsyncComponent(() => import('./location-details.vue'))
const LocationsSelect = defineAsyncComponent(() => import('./locations-select.vue'))
const LocationsTable = defineAsyncComponent(() => import('./locations-table.vue'))

export {
  CustomLocationFormPart,
  Location,
  LocationDetails,
  LocationFormModal,
  LocationModal,
  LocationsSelect,
  LocationsTable,
  LocationWithTp,
}
