<script setup lang="ts">
import EaSelect from 'frontend/common/ea-form-controls/ea-select.vue'
import type { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: string | number
  criterion: ISearchCriteriaNode
  predicate: string
  label?: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: unknown): void
}
const emit = defineEmits<Emits>()

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <EaSelect
    v-model="valueModel"
    class="multiple"
    :label="label"
    :multiple="predicate !== 'eq'"
    :name="`toDo`"
    :options="criterion.collection"
    :reduce="item => item.value"
    :select-label="'label'"
    :selectable="opt => !opt.isOptgroup && !opt.disabled"
    skip-dirty-check
  >
    <template #option="option">
      <span :style="[option.isOptgroup ? 'margin-left: -0.5rem' : '']">
        <span v-if="option.labelHtml?.length" v-html="option.labelHtml"></span>
        <span v-else>{{ option.label }}</span>
      </span>
    </template>
    <template #selected-option="option">
      <span v-if="option.selectedLabelHtml?.length" v-html="option.selectedLabelHtml"></span>
      <span v-else-if="option.selectedLabel">{{ option.selectedLabel }}</span>
      <span v-else-if="option.labelHtml?.length" v-html="option.labelHtml"></span>
      <span v-else>{{ option.selectedLabel || option.label }}</span>
    </template>
  </EaSelect>
</template>
