<script setup lang="ts">
import { UsedCriteriaOutlineColorType } from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaOutlineColorType'
import UsedCriteriaValueWrapperComponent from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaValueWrapperComponent.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: string | string[]
  criterion: ISearchCriteriaNode
  predicate: string
}
const props = defineProps<Props>()

const isNegative = computed(() => {
  return (
    (props.predicate?.startsWith('not_') && !props.predicate?.startsWith('not_null')) ||
    props.predicate?.startsWith('null') ||
    props.predicate?.startsWith('blank')
  )
})
const outlineColorType = computed(() => {
  return isNegative.value
    ? UsedCriteriaOutlineColorType.Negative
    : UsedCriteriaOutlineColorType.Positive
})
</script>

<template>
  <UsedCriteriaValueWrapperComponent :outline-color-type="outlineColorType">
    <slot />
  </UsedCriteriaValueWrapperComponent>
</template>

<style scoped lang="scss">
.used-criteria-value-wrapper {
  &:deep(.form-control) {
    border: none;
    padding: 0;
    //min-height: 18px;
    //background: #f0f0f0;
    //border-radius: 4px;
    //display: flex;
    //align-items: center;
    //padding: 0 8px;
    //text-transform: uppercase;
    //font-size: 12px;
    //font-weight: 600;
    border-radius: 4px;
    color: hsla(211, 50%, 39%, 1);
    outline: none;
    box-shadow: none;
    font-size: inherit;
  }
}
</style>
