<script setup lang="ts">
import CriteriaBuilderComponent from 'frontend/filters/components/CriteriaBuilderComponent.vue'
import { ISearchCriteria } from 'frontend/filters/types/ISearchCriteria'
import { computed } from 'vue'

interface Props {
  searchCriteria: ISearchCriteria
  modelValue: unknown
  maxParentHeight?: number
}
const props = withDefaults(defineProps<Props>(), {
  maxParentHeight: null,
})

interface Emits {
  (e: 'update:modelValue', value: unknown): void
}
const emit = defineEmits<Emits>()

const model = computed({
  get() {
    return props.modelValue
  },
  set(value: unknown) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <CriteriaBuilderComponent
    v-model="model"
    :search-criteria="searchCriteria"
    :max-parent-height="maxParentHeight"
  >
    <template #empty-list-after>
      <slot name="empty-list-after" />
    </template>
  </CriteriaBuilderComponent>
</template>
