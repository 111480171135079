import { defineAsyncComponent } from 'vue'

const KINDS = {
  break: 'Break',
  location_backup: 'Location backup',
  custom_job: 'Custom Job',
}

const AASM_STATES = [
  { value: 'pending', label: 'Pending', action: 'reset_state' },
  {
    value: 'started',
    label: 'Started',
    action: 'start',
  },
  {
    value: 'completed',
    label: 'Completed',
    action: 'complete',
  },
  {
    value: 'incomplete',
    label: 'Incomplete',
    action: 'mark_as_incomplete',
    visible: item => item.shiftActionKind == 'custom_job',
    requiresDriverComment: true,
  },
  {
    value: 'declined',
    label: 'Declined',
    action: 'mark_as_declined',
    visible: item => item.shiftActionKind == 'location_backup',
    requiresDriverComment: true,
  },
]

const ShiftActionFormModal = defineAsyncComponent(() => import('./shift-action-form-modal.vue'))
const ShiftActionDetails = defineAsyncComponent(() => import('./shift-action-details.vue'))
const ShiftActionModal = defineAsyncComponent(() => import('./shift-action-modal.vue'))
const ShiftActionAasmState = defineAsyncComponent(() => import('./shift-action-aasm-state.vue'))

export {
  AASM_STATES,
  KINDS,
  ShiftActionAasmState,
  ShiftActionDetails,
  ShiftActionFormModal,
  ShiftActionModal,
}
