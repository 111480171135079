<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'ShuttleServiceDaysOfService',
  props: {
    shuttleService: { type: Object, default: () => ({}) },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
div(:style="{ minWidth: shuttleService.daysOfService?.length > 0 ? '125px' : 'auto' }")
  template(v-for="(item, i) in shuttleService.daysOfService")
    span(v-if="i > 0") ,{{ ' ' }}
    span.text-nowrap {{ item }}
</template>
