<script>
import { useGoogleDirectionOptions } from './duration-rules-wizzard/google-directions-config-subform.vue'

export default {
  name: 'GoogleDirectionsConfig',
  props: {
    config: { type: Object },
  },
  setup() {
    const { trafficModelsMap, avoidsMap } = useGoogleDirectionOptions()
    return { trafficModelsMap, avoidsMap }
  },
  computed: {
    trafficModel() {
      if (this.config?.trafficModel) {
        return this.trafficModelsMap[this.config.trafficModel]
      } else {
        return 'Best guess'
      }
    },
    avoids() {
      if (this.config?.avoid?.length) {
        return this.config.avoid?.map(avoid => this.avoidsMap[avoid]).join(', ')
      } else {
        return null
      }
    },
  },
}
</script>

<template lang="pug">
.dir-grid-container
  .small-with-normal-height.text-muted.text-end Traffic model:
  span.ms-1 {{ trafficModel }}
  template(v-if="avoids")
    .small-with-normal-height.text-muted.text-end Avoid:
    span.ms-1 {{ avoids }}
  .small-with-normal-height.text-muted.text-end Range:
  span.ms-1 {{ config?.rangePreference ?? 50 }}%
  template(v-if="config?.arriveByTrafficOffset && !config?.doubleCalculationForKnownDropoff")
    .small-with-normal-height.text-muted.text-end Arrive offset:
    span.ms-1 {{ config?.arriveByTrafficOffset }}%
template(v-if="config?.doubleCalculationForKnownDropoff")
  .small-with-normal-height.text-muted.text-end Double PU calculation for departures PU time
</template>
