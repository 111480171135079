import CancelToken from 'axios/lib/cancel/CancelToken'
import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { GET, PUT, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class EventsService extends ResourceService {
  constructor({
    baseUrl = `/events`,
    blocker,
    fetchError = 'Failed to fetch Events.',
    saveError = 'Failed to save Event.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,

      blocker,
      blockerKey: 'events',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return this.selectService.fetchOptions
  }

  availableTimeZones(payload = {}, errorHandler = this._onFetchError, config = {}) {
    const url = `${this.baseUrl}/${setupRouter.currentRoute.value.params.eventSlug}/available_time_zones`
    return this._makeCall(GET, errorHandler, { url, data: payload, ...config })
  }

  saveDefaultDurationRule(
    payload = {},
    errorHandler = error => this._onSaveError(error, 'Could not update default duration rule.'),
    config = {},
  ) {
    const url = `${this.baseUrl}/${setupRouter.currentRoute.value.params.eventSlug}/update_default_duration_rule`
    return this._makeCall(PUT, errorHandler, { url, data: { event: payload }, ...config })
  }

  saveAutoTimeRecalculationData(
    payload = {},
    errorHandler = error =>
      this._onSaveError(error, 'Could not update duration auto-recalculation config.'),
    config = {},
  ) {
    const url = `${this.baseUrl}/${setupRouter.currentRoute.value.params.eventSlug}/update_auto_time_recalculation_config`
    return this._makeCall(PUT, errorHandler, { url, data: { event: payload }, ...config })
  }

  saveGlobalFilterConfig(id, data, errorHandler = e => this._onSaveError(e)) {
    return this._makeCall('put', errorHandler, {
      url: `${this.baseUrl}/${id}/global_filter_config`,
      data: { event: data },
    })
  }

  saveOptOutSmsConfig(id, data, errorHandler = e => this._onSaveError(e)) {
    return this._makeCall('put', errorHandler, {
      url: `${this.baseUrl}/${id}/opt_out_sms_config`,
      data: { event: data },
    })
  }

  logo(eventId, cancellable = null) {
    const requestPath = `/events/${eventId}/logo`
    return this._makeCall(GET, null, {
      url: requestPath,
      cancelToken: new CancelToken(cancel => {
        cancellable.value[requestPath] = cancel
      }),
    })
  }
}
