<script>
import { useTomTomDirectionOptions } from './duration-rules-wizzard/tom-tom-directions-config-subform.vue'

export default {
  name: 'TomTomDirectionsConfig',
  props: {
    config: { type: Object, required: true },
  },
  setup() {
    const { routeTypesMap, avoidsMap } = useTomTomDirectionOptions()
    return { routeTypesMap, avoidsMap }
  },
  computed: {
    routeType() {
      if (this.config?.routeType) {
        return this.routeTypesMap[this.config.routeType]
      } else {
        return 'Fastest'
      }
    },
    avoids() {
      if (this.config?.avoid?.length) {
        return this.config.avoid?.map(avoid => this.avoidsMap[avoid]).join(', ')
      } else {
        return null
      }
    },
  },
}
</script>

<template lang="pug">
.dir-grid-container
  template(v-if="avoids")
    .small-with-normal-height.text-muted.text-end Avoid:
    span.ms-1 {{ avoids }}
  .small-with-normal-height.text-muted.text-end Traffic:
  span.ms-1 {{ config.traffic ? 'Enabled' : 'Disabled' }}
  template(v-if="config.traffic")
    .small-with-normal-height.text-muted.text-end Route type:
    span.ms-1 {{ routeType }}
    .small-with-normal-height.text-muted.text-end Range:
    span.ms-1 {{ config?.rangePreference ?? 50 }}%
</template>
