export class AsyncPresenter {
  constructor(serviceFactory, fields = ['name'], formatter = null) {
    this.serviceFactory = serviceFactory
    this.fields = fields
    this.formatter = formatter || this._joinWithSpaces.bind(this)
  }

  get present() {
    return this._format.bind(this)
  }

  async _format(ids) {
    if (ids?.length) {
      const response = await this.serviceFactory().index({
        data: {
          idIn: [].concat(ids),
          fields: this.fields,
        },
      })

      return Object.values(response?.entities?.items || {})
        .map(this.formatter)
        .join(', ')
    } else {
      return 'Empty'
    }
  }

  _joinWithSpaces(item) {
    return this.fields.map(key => item[key]).join(' ')
  }
}
