<script>
export default {
  name: 'PhoneNumber',
  props: { phoneNumber: { type: String, default: null }, additionalClass: { type: String } },
}
</script>

<template lang="pug">
span.text-black-15(v-if="!phoneNumber") empty
a.d-flex(
  v-else
  :additionalClass="additionalClass"
  :href="`tel:${phoneNumber}`"
  @click="$event.stopPropagation()"
)
  i.bi.bi-phone
  span(style="word-wrap: break-word; overflow: hidden") {{ phoneNumber }}
</template>
