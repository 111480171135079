import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { BaseService } from 'frontend/common/services/base-service'
import { POST } from 'frontend/common/services/resource-service'

export class SystemNotificationsService extends BaseService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/system_notifications`,
    blocker,
    blockerKey = 'system_notifications',
    apiProvider = axios,
  } = {}) {
    super({ apiProvider, blocker, blockerKey })
    this.baseUrl = baseUrl
  }

  read(
    id,
    { errorHandler = this._onFetchError, url = `${this.baseUrl}/${id}/read`, data } = {},
    config = {},
  ) {
    return this._makeCall(POST, errorHandler, { url, data, ...config })
  }

  getSearchCriteria() {
    return this._makeCall(POST, this._onFetchError, {
      url: `${this.baseUrl}/search_criteria`,
    })
  }
}
