import store from 'frontend/_stores'
import pluralize from 'pluralize'

import { convertToFormData } from '../form-data-converter'
import { BaseService } from './base-service'
import { ValidationService } from './validation-service'

export const POST = 'post'
export const GET = 'get'
export const PUT = 'put'
export const DELETE = 'delete'

export class ResourceService extends BaseService {
  constructor({
    baseUrl,
    fetchError = 'Failed to fetch resource',
    saveError = 'Failed to save resource',
    deleteError = 'Failed to delete resource',
    blocker,
    blockerKey = 'default',
    validationService,
    apiProvider,
  } = {}) {
    super({ apiProvider, blocker, blockerKey })
    this.baseUrl = baseUrl
    this.fetchError = fetchError
    this.saveError = saveError
    this.deleteError = deleteError
    this.blocker = blocker
    this.blockerKey = blockerKey
    this.validationService =
      validationService ?? new ValidationService({ apiProvider, baseUrl, blocker })
  }

  index(
    payload = {},
    { errorHandler = e => this._onFetchError(e), url = `${this.baseUrl}/index` } = {},
    config = {},
  ) {
    return this._makeCall(POST, errorHandler, { url, ...payload, ...config })
  }

  show(
    id,
    { errorHandler = e => this._onFetchError(e), url = `${this.baseUrl}/${id}/show`, data } = {},
    config = {},
  ) {
    return this._makeCall(POST, errorHandler, { url, data, ...config })
  }

  getSearchCriteria(additionalParams = {}) {
    return this._makeCall(POST, this._onFetchError, {
      url: `${this.baseUrl}/search_criteria`,
      params: additionalParams,
    })
  }

  getValidations(config) {
    return this.validationService.getValidations(config)
  }

  save({
    data,
    config = {},
    asFormData = false,
    errorHandler = e => this._onSaveError(e),
    additionalPayload = {},
  }) {
    const baseKey = pluralize(this.baseUrl.split('/').pop(), 1)
    let payload

    if (asFormData) {
      payload = convertToFormData(baseKey, data, additionalPayload)
      config = { ...config, headers: { 'Content-Type': 'multipart/form-data' } }
    } else {
      payload = { [baseKey]: data, ...additionalPayload }
    }

    if (data.id) {
      return this.update(data.id, payload, config, errorHandler)
    } else {
      return this._create(payload, config, errorHandler)
    }
  }

  delete(
    id,
    { errorHandler = e => this._onDeleteError(e), url = `${this.baseUrl}/${id}`, data } = {},
    config = {},
  ) {
    return this._makeCall(DELETE, errorHandler, { url, data, ...config })
  }

  update(id, data, config, errorHandler = e => this._onSaveError(e)) {
    this._block()

    return this.apiProvider
      .put(`${this.baseUrl}/${id}`, data, config)
      .catch(error => {
        errorHandler(error, this.saveError)
        throw error
      })
      .finally(() => {
        this._unblock()
      })
  }

  _create(data, config, errorHandler) {
    this._block()

    return this.apiProvider
      .post(this.baseUrl, data, config)
      .catch(error => {
        errorHandler(error, this.saveError)
        throw error
      })
      .finally(() => {
        this._unblock()
      })
  }

  //protected
  _onFetchError(error, message) {
    console.error('ON FETCH ERROR', error)
    if (this?.fetchError || message) {
      store.dispatch('toast/error', { message: message || this.fetchError })
    }
    throw error
  }

  //protected
  _onSaveError(error, message) {
    console.error('ON SAVE ERROR', error)
    if (this?.saveError || message) {
      store.dispatch('toast/error', { message: message || this.saveError })
    }
    throw error
  }

  _onDeleteError(error, message) {
    console.error('ON DELETE ERROR', error)
    if (this?.deleteError || message) {
      store.dispatch('toast/error', { message: message || this.deleteError })
    }
    throw error
  }

  //protected
}
