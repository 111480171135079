<script>
import { Dropdown } from 'bootstrap'
import { throttle } from 'lodash'
import { nextTick, ref } from 'vue'

export default {
  name: 'Dropdown',
  emits: ['open', 'hidden'],
  props: {
    actions: { type: Array, default: () => [] },
    stopTogglerPropagation: { type: Boolean, default: false },
    alignRight: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    autoClose: { type: [String, Boolean], default: true },
    strategy: { type: String, default: 'absolute' },
    disabled: { type: Boolean, default: false },
    hoverable: { type: Boolean, default: false },
  },
  setup() {
    const dropdownTriggerRef = ref(null)
    const dropdownMenuRef = ref(null)
    const isOpened = ref(false)
    const maxHeight = ref(Math.round(window.innerHeight * 0.8))

    function recomputeMaxHeight() {
      const dropdownTop =
        (dropdownMenuRef.value?.getBoundingClientRect()?.top || 0) + window.scrollY
      let result = Math.round(window.innerHeight - dropdownTop - 55)
      if (result < 100) {
        result = Math.round(window.innerHeight * 0.3)
      }
      maxHeight.value = result
    }

    const throttledRecomputeMaxHeight = throttle(recomputeMaxHeight, 100)

    return {
      throttledRecomputeMaxHeight,
      dropdownTriggerRef,
      dropdownMenuRef,
      isOpened,
      maxHeight,
      recomputeMaxHeight,
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.isOpened
      },
      set(newVal) {
        if (newVal && !this.isOpened) {
          this.show()
        } else if (!newVal && this.isOpened) {
          this.closeFn()
        }
      },
    },
    hoverListeners() {
      return this.hoverable
        ? {
            onMouseenter: this.show,
            onMouseleave: this.closeFn,
          }
        : {}
    },
    actionsPresent() {
      return this.actions.length > 0
    },
  },
  watch: {
    dropdownTriggerRef: {
      handler(newEl, oldEl) {
        if (newEl && !oldEl) {
          new Dropdown(newEl, {
            offset: this.hoverable ? [0, -1] : [],
            autoClose: false,
            popperConfig: {
              strategy: this.strategy,
              boundary: 'viewport',
            },
          })
          newEl.addEventListener('show.bs.dropdown', () => {
            this.$nextTick(() => {
              this.recomputeMaxHeight()
              this.isOpened = true
              this.$emit('open', true)
            })
          })
          newEl.addEventListener('hidden.bs.dropdown', () => {
            this.isOpened = false
            this.$emit('hidden', false)
          })
        }
      },
      immediate: true,
    },
    isOpened(value) {
      if (value) {
        this.$eventHub.$on('mouseup', this.closeIfAutoClose)
        this.$eventHub.$on('windowResize', this.repositionAndRecomputeHeight)
      } else {
        this.$eventHub.$off('mouseup', this.closeIfAutoClose)
        this.$eventHub.$off('windowResize', this.repositionAndRecomputeHeight)
        this.throttledRecomputeHeight?.cancel()
      }
    },
    actions() {
      nextTick(() => {
        this.reposition()
      })
    },
  },
  unmounted() {
    this.$eventHub.$off('mouseup', this.closeIfAutoClose)
  },
  methods: {
    show() {
      if (this.dropdownTriggerRef) {
        Dropdown.getInstance(this.dropdownTriggerRef)?.show()
      }
    },
    reposition() {
      if (this.alignRight && this.dropdownTriggerRef && this.isOpened) {
        Dropdown.getInstance(this.dropdownTriggerRef)?.update()
      }
    },
    repositionAndRecomputeHeight() {
      this.reposition()
      this.throttledRecomputeMaxHeight()
    },
    closeIfAutoClose(event) {
      if (
        this.autoClose &&
        !this.dropdownTriggerRef?.contains(event.target) &&
        this.dropdownTriggerRef != event.target
      ) {
        this.closeFn()
      }
    },
    closeFn() {
      if (this.dropdownTriggerRef) {
        Dropdown.getInstance(this.dropdownTriggerRef)?.hide()
      }
    },
    togglerClicked(event) {
      if (this.stopTogglerPropagation) event.stopPropagation()
    },
  },
}
</script>

<template lang="pug">
.dropdown(v-if="actionsPresent || !!$slots['items']" v-bind="hoverListeners" :class="{ disabled }")
  div(
    ref="dropdownTriggerRef"
    :data-bs-auto-close="autoClose"
    @click="togglerClicked($event)"
    data-bs-toggle="dropdown"
  )
    slot(:closeFn="closeFn" :isOpen="isOpen" :isOpened="isOpened" :show="show")
      button.btn.btn-sm.btn-default.dropdown-toggle
        i.bi-three-dots

  ul.dropdown-menu(
    ref="dropdownMenuRef"
    :class="{ 'dropdown-menu-end': alignRight, scrollable }"
    @mouseup.prevent.stop
  )
    slot(
      :closeFn="closeFn"
      :isOpen="isOpen"
      :isOpened="isOpened"
      :maxHeight="maxHeight"
      name="items"
    )
      template(v-for="action in actions")
        li(
          v-if="!!action.href && (!action.type || action.type == 'a')"
          :class="{ disabled: !!action.disabled }"
        )
          a.dropdown-item(
            v-if="!action.type || action.type == 'a'"
            :class="{ disabled: !!action.disabled }"
            :href="action.href"
            :target="action.target || '_self'"
          ) {{ action.title }}
        li(
          v-else
          :class="{ disabled: !!action.disabled }"
          @click.prevent.stop="action.clickFn && action.clickFn($event, closeFn)"
        )
          a.dropdown-item(
            v-if="!action.type || action.type == 'a'"
            :class="[{ disabled: !!action.disabled }, action.classes]"
          ) {{ action.title }}
          hr.dropdown-divider(v-if="action.type == 'divider'")
          h6.dropdown-header(v-if="action.type == 'header'" :class="action.classes")
            | {{ action.title }}
            .small.lh-1.text-black-30.ms-2.text-pre-wrap(v-if="action.subtitle?.length") {{ action.subtitle }}
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/_variables.scss';
.dropdown {
  .dropdown-toggle {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    cursor: pointer;
    &:after {
      display: none;
    }
    &:hover {
      background: $gray;
    }
    .dots-icon {
      width: 1rem;
    }
    .bi-three-dots {
      font-size: 1.25rem;
    }
  }
}
</style>

<style lang="scss">
@import 'frontend_stylesheets/_variables.scss';
.dropdown {
  .dropdown-toggle {
    cursor: pointer;
  }
  .dropdown-menu {
    border-radius: 8px;
    border: 0;
    box-shadow: $box-shadow-dropdown;
    &.dropdown-menu-end {
      border-top-right-radius: 0;
    }
    &:not(.dropdown-menu-end):not(.footer-top) {
      border-top-left-radius: 0;
    }
    &.scrollable {
      max-height: 80vh;
      overflow: auto;
      @include for-size(small-and-medium-devices) {
        max-height: 60vh;
      }
    }
    .dropdown-item {
      cursor: pointer;
      font-size: 0.85rem;
    }
    li > * {
      width: 100%;
    }
  }
}
</style>
