<script setup lang="ts">
import { UsedCriteriaOutlineColorType } from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaOutlineColorType'
import UsedCriteriaValueWrapperComponent from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaValueWrapperComponent.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: string | string[]
  criterion: ISearchCriteriaNode
  predicate: string
}
const props = defineProps<Props>()

const isNegative = computed(() => {
  return (
    (props.predicate?.startsWith('not_') && !props.predicate?.startsWith('not_null')) ||
    props.predicate?.startsWith('null') ||
    props.predicate?.startsWith('blank')
  )
})
const outlineColorType = computed(() => {
  return isNegative.value
    ? UsedCriteriaOutlineColorType.Negative
    : UsedCriteriaOutlineColorType.Positive
})
</script>

<template>
  <UsedCriteriaValueWrapperComponent :outline-color-type="outlineColorType">
    <div class="input-wrapper">
      <slot />
    </div>
  </UsedCriteriaValueWrapperComponent>
</template>

<style scoped lang="scss">
.used-criteria-value-wrapper {
  &:deep(.input-group-main-item) {
    min-width: 240px;
  }
}
.input-wrapper {
  &:deep(.vs__dropdown-toggle) {
    border: none;
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &:deep(ul) {
    padding-left: 0;
  }
}
</style>
