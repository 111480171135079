import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import TrueFalseLabel from 'frontend/common/true-false-label.vue'

export const TrackingServiceDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'provider',
    'api_url',
    'api_key',
    'sync_enabled',
    'updated_at'
  ],
  subscriptions: {
    items: 'TrackingServicesChannel',
  },
  defaultColumns: [
    'name',
    'provider',
    'apiUrl',
    'syncEnabled',
    'updatedAt'
  ],
  columnConfig: {
    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    provider: {
      label: 'Provider',
      header: {
        sort: 'provider',
      },
      cell: {
        kind: 'simple',
        path: 'provider',
      },
    },
    apiUrl: {
      label: 'API URL',
      header: {
        sort: 'apiUrl',
      },
      cell: {
        kind: 'simple',
        path: 'apiUrl',
      },
    },
    syncEnabled: {
      label: 'Synchronization',
      header: {
        sort: 'syncEnabled',
      },
      cell: {
        kind: 'component',
        component: TrueFalseLabel,
        componentProps: item => ({ item: item.syncEnabled }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },
    updatedAtTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAtDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
  }
}