export const TIME_VARIABLES = {
  beginning_of_today: 'Beginning of today',
  beginning_of_today_plus_one: 'Beginning of today + 1d',
  beginning_of_today_plus_two: 'Beginning of today + 2d',
  beginning_of_today_plus_three: 'Beginning of today + 3d',
  beginning_of_today_plus_four: 'Beginning of today + 4d',
  beginning_of_yesterday: 'Beginning of yesterday',
  beginning_of_week: 'Beginning of current week (monday)',
  beginning_of_month: 'Beginning of current month',
  beginning_of_year: 'Beginning of current year',
  end_of_today: 'End of today',
  end_of_yesterday: 'End of yesterday',
  end_of_week: 'End of current week (monday)',
  end_of_month: 'End of current month',
  end_of_year: 'End of current year',
}

export default {
  start: {
    kind: 'text',
    suffix: 'Start',
    name: 'starts with',
    isPresent: val => !!val && val.length,
  },
  end: {
    kind: 'text',
    suffix: 'End',
    name: 'ends with',
    isPresent: val => !!val && val.length,
  },
  cont: {
    kind: 'text',
    suffix: 'Cont',
    name: 'contains',
    isPresent: val => !!val && val.length,
  },
  contAny: {
    kind: 'select',
    suffix: 'ContAny',
    name: 'contains any',
    multiple: true,
    isPresent: val => !!val && val.length,
  },
  ccont: {
    kind: 'text',
    suffix: 'Ccont',
    name: 'contains',
    isPresent: val => !!val && val.length,
  },
  notNull: {
    kind: 'present',
    suffix: 'NotNull',
    name: 'is present',
    humanized: 'is present:',
    multiple: false,
    isPresent: val => [true, false].indexOf(val) != -1,
  },
  exists: {
    kind: 'exists',
    suffix: 'Exists',
    name: 'is present',
    humanized: 'is present:',
    isPresent: val => ['yes', 'no'].indexOf(val) != -1,
  },
  present: {
    kind: 'present',
    suffix: 'Present',
    name: 'is present',
    humanized: 'is present:',
    multiple: false,
    isPresent: val => [true, false].indexOf(val) != -1,
  },
  blank: {
    kind: 'blank',
    suffix: 'Blank',
    name: 'is blank',
    humanized: 'is blank:',
    multiple: false,
    isPresent: val => [true, false].indexOf(val) != -1,
  },
  isNull: {
    kind: 'blank',
    suffix: 'Null',
    name: 'is empty',
    humanized: 'empty:',
    multiple: false,
    isPresent: val => [true, false].indexOf(val) != -1,
  },
  bool: {
    kind: 'bool',
    suffix: 'Eq',
    name: 'equals',
    humanized: 'is',
    multiple: false,
    isPresent: val => [true, false].indexOf(val) != -1,
  },
  eq: {
    kind: 'select',
    suffix: 'Eq',
    name: 'equals',
    humanized: '=',
    multiple: false,
    isPresent: val => (!!val || val == 0 || val == false) && val.toString().length,
  },
  stringEq: {
    kind: 'text',
    suffix: 'Eq',
    name: 'equals',
    humanized: '=',
    multiple: false,
    isPresent: val => !!val && val.length,
  },
  in: {
    kind: 'select',
    suffix: 'In',
    name: 'is one of',
    humanized: 'is one of:',
    multiple: true,
    isPresent: val => !!val && val.length,
  },
  notIn: {
    kind: 'select',
    suffix: 'NotIn',
    name: 'is not one of',
    humanized: 'is not one of:',
    multiple: true,
    isPresent: val => !!val && val.length,
  },
  overlapArray: {
    kind: 'select',
    suffix: 'OverlapArray',
    name: 'is one of',
    humanized: 'is one of:',
    multiple: true,
    isPresent: val => !!val && val.length,
  },
  notOverlapArray: {
    kind: 'select',
    suffix: 'NotOverlapArray',
    name: 'is not one of',
    humanized: 'is not one of:',
    multiple: true,
    isPresent: val => !!val && val.length,
  },
  numberEq: {
    kind: 'float',
    suffix: 'Eq',
    name: 'equals',
    humanized: '=',
    isPresent: val => !!val || val == 0,
  },
  gteq: {
    kind: 'float',
    suffix: 'Gteq',
    name: 'greater than or equal to',
    humanized: '&ge;',
    isPresent: val => !!val || val == 0,
  },
  gt: {
    kind: 'float',
    suffix: 'Gt',
    name: 'greater than',
    humanized: '>',
    isPresent: val => !!val || val == 0,
  },
  lteq: {
    kind: 'float',
    suffix: 'Lteq',
    name: 'less than or equal to',
    humanized: '&le;',
    isPresent: val => !!val || val == 0,
  },
  lt: {
    kind: 'float',
    suffix: 'Lt',
    name: 'less than',
    humanized: '<',
    isPresent: val => !!val || val == 0,
  },
  dateGteq: {
    kind: 'date',
    suffix: 'Gteq',
    name: 'greater than or equal to',
    humanized: '&ge;',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  dateGt: {
    kind: 'date',
    suffix: 'Gt',
    name: 'greater than',
    humanized: '>',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  dateLteq: {
    kind: 'date',
    suffix: 'Lteq',
    name: 'less than or equal to',
    humanized: '&le;',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  dateLt: {
    kind: 'date',
    suffix: 'Lt',
    name: 'less than',
    humanized: '<',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  datetimeGteq: {
    kind: 'datetime',
    suffix: 'Gteq',
    name: 'greater than or equal',
    humanized: '&ge;',
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      time_24hr: true,
      enableTime: true,
      altFormat: 'd/m/Y H:i',
      altInput: true,
      dateFormat: 'Y-m-d H:i:00',
    },
  },
  datetimeGt: {
    kind: 'datetime',
    suffix: 'Gt',
    name: 'greater than',
    humanized: '>',
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      time_24hr: true,
      enableTime: true,
      altFormat: 'd/m/Y H:i',
      altInput: true,
      dateFormat: 'Y-m-d H:i:00',
    },
  },
  datetimeLteq: {
    kind: 'datetime',
    suffix: 'Lteq',
    name: 'less than or equal',
    humanized: '&le;',
    isPresent: val => !!val && val.length,
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      time_24hr: true,
      enableTime: true,
      altFormat: 'd/m/Y H:i',
      altInput: true,
      dateFormat: 'Y-m-d H:i:00',
    },
  },
  datetimeLt: {
    kind: 'datetime',
    suffix: 'Lt',
    name: 'less than',
    humanized: '<',
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      time_24hr: true,
      enableTime: true,
      altFormat: 'd/m/Y H:i',
      altInput: true,
      dateFormat: 'Y-m-d H:i:00',
    },
  },
  dateWithVariablesGteq: {
    kind: 'dateWithVariables',
    suffix: 'Gteq',
    name: 'greater than or equal to',
    humanized: '&ge;',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  dateWithVariablesGt: {
    kind: 'dateWithVariables',
    suffix: 'Gt',
    name: 'greater than',
    humanized: '>',
    isPresent: val => !!val && val.length,
    dayjsFormat: 'DD/MM/YYYY',
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  dateWithVariablesLteq: {
    kind: 'dateWithVariables',
    suffix: 'Lteq',
    name: 'less than or equal to',
    humanized: '&le;',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  dateWithVariablesLt: {
    kind: 'dateWithVariables',
    suffix: 'Lt',
    name: 'less than',
    humanized: '<',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      // wrap: true, // set wrap to true only when using 'input-group'
      static: false,
      altFormat: 'd/m/Y',
      altInput: true,
      dateFormat: 'Y-m-d 00:00:00',
    },
  },
  dateOverlapArray: {
    kind: 'dateMulti',
    suffix: 'OverlapArray',
    name: 'is one of',
    humanized: 'is one of:',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      altFormat: 'd/m/Y',
      enableTime: false,
      mode: 'multiple',
    },
  },
  dateNotOverlapArray: {
    kind: 'dateMulti',
    suffix: 'NotOverlapArray',
    name: 'is not one of',
    humanized: 'is not one of:',
    dayjsFormat: 'DD/MM/YYYY',
    isPresent: val => !!val && val.length,
    flatpickrConfig: {
      altFormat: 'd/m/Y',
      enableTime: false,
      mode: 'multiple',
    },
  },
  datetimeWithVariablesGteq: {
    kind: 'datetimeWithVariables',
    suffix: 'Gteq',
    name: 'greater than or equal',
    humanized: '&ge;',
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    isPresent: val => !!val && val.length,
    // flatpickrConfig: {
    //   // wrap: true, // set wrap to true only when using 'input-group'
    //   static: false,
    //   time_24hr: true,
    //   enableTime: true,
    //   altFormat: 'd/m/Y H:i',
    //   altInput: true,
    //   dateFormat: 'Y-m-d H:i:00',
    // },
  },
  datetimeWithVariablesGt: {
    kind: 'datetimeWithVariables',
    suffix: 'Gt',
    name: 'greater than',
    humanized: '>',
    isPresent: val => !!val && val.length,
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    // flatpickrConfig: {
    //   // wrap: true, // set wrap to true only when using 'input-group'
    //   static: false,
    //   time_24hr: true,
    //   enableTime: true,
    //   altFormat: 'd/m/Y H:i',
    //   altInput: true,
    //   dateFormat: 'Y-m-d H:i:00',
    // },
  },
  datetimeWithVariablesLteq: {
    kind: 'datetimeWithVariables',
    suffix: 'Lteq',
    name: 'less than or equal',
    humanized: '&le;',
    isPresent: val => !!val && val.length,
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    // flatpickrConfig: {
    //   // wrap: true, // set wrap to true only when using 'input-group'
    //   static: false,
    //   time_24hr: true,
    //   enableTime: true,
    //   altFormat: 'd/m/Y H:i',
    //   altInput: true,
    //   dateFormat: 'Y-m-d H:i:00',
    // },
  },
  datetimeWithVariablesLt: {
    kind: 'datetimeWithVariables',
    suffix: 'Lt',
    name: 'less than',
    humanized: '<',
    dayjsFormat: 'DD/MM/YYYY HH:mm',
    isPresent: val => !!val && val.length,
    // flatpickrConfig: {
    //   // wrap: true, // set wrap to true only when using 'input-group'
    //   static: false,
    //   time_24hr: true,
    //   enableTime: true,
    //   altFormat: 'd/m/Y H:i',
    //   altInput: true,
    //   dateFormat: 'Y-m-d H:i:00',
    // },
  },
  timeEq: {
    kind: 'time',
    suffix: 'Eq',
    name: 'equals',
    humanized: '=',
    isPresent: val => !!val?.length,
  },
}
