<script>
import EventDashboardItem from './_event-dashboard-item.vue'

export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'EventDashboardSection',
  emits: ['toggleFavorite'],
  props: {
    title: String,
    items: Object,
    activeItem: Object,
    firstItem: Object,
    inSpotlight: Boolean,
    favorites: Array,
  },
  components: {
    EventDashboardItem,
  },
}
</script>

<template lang="pug">
.row.mb-3
  .col-12
    h6.text-custom {{ title }}
    .event-dashboard-section
      event-dashboard-item(
        v-for="item in items"
        :activeItem="activeItem"
        :favorites="favorites"
        :firstItem="firstItem"
        :icon="item.icon"
        :id="item.id"
        :inSpotlight="inSpotlight"
        :isDisabled="item.isDisabled"
        :isModal="item.isModal"
        :modalComponent="item.modalComponent"
        :path="item.path"
        :title="item.title"
        @toggle-favorite="$emit('toggle-favorite', $event)"
      )
    .border-bottom
</template>

<style lang="scss" scoped>
@import 'frontend_stylesheets/_variables.scss';

.event-dashboard-section {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}
</style>
