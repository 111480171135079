import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const OSLDRuleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'priority',
    { cluster: ['id', 'name', 'time_zone'] },
    {
      operational_service_level: [
        'id',
        'name',
        'font_color',
        'background_color',
        'border_color',
      ],
    },
    { driver_categories: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
    {
      drivers: [
        'id',
        'name',
        'surname',
        'assigned_phone',
        'personal_phone',
        'primary_phone',
        { cluster: ['id', 'name', 'time_zone'] },
        { driver_category: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
        {
          driver_source: ['id', 'name'],
        },
      ],
    },
  ],
  subscriptions: {
    items: 'OSLDRulesChannel',
    clusters: 'ClustersSimpleChannel',
    operationalServiceLevels: 'OperationalServiceLevelsSimpleChannel',
    driverCategories: 'DriverCategoriesSimpleChannel',
    drivers: 'DriversDetailedChannel',
  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}