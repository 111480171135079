import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import store from 'frontend/_stores'
import { POST, ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class ClustersService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = `/events/${eventSlug}/clusters`,
    blocker,
    fetchError = 'Failed to fetch cluster.',
    saveError = 'Failed to save cluster.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'clusters',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return (additionalPayload = {}) =>
      this.selectService.fetchOptions({
        additionalPayload,
        sorts: 'name asc',
        fields: ['id', 'name'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }

  // get getForSelect() {
  //   return this.selectService.fetchOptions
  // }

  dispatchAction(
    action,
    id,
    data,
    { url = `${this.baseUrl}${id ? '/' + id : ''}/${action}` } = {},
  ) {
    const errorHandler = error => {
      const messagePayload = { message: 'Requested action cannot be run' }
      messagePayload.message = error.response?.data?.message || messagePayload.message
      if (error.response?.status == 422 && error.response?.data?.errors?.base?.length) {
        messagePayload.title = messagePayload.message
        messagePayload.message =
          error.response.data.errors.base.constructor == Array
            ? error.response.data.errors.base.join('; ')
            : error.response.data.errors.base
      }
      store.dispatch('toast/error', messagePayload)
    }
    return this._makeCall(POST, errorHandler, { url, data })
  }
}
