<script>
import '@fullcalendar/core/vdom.js'

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import dayjsTzPlugin from './dayjs-tz-fullcalendar'

export default {
  name: 'EaFullcalendar',
  emits: ['dateRangeUpdated', 'dateClick', 'eventClick', 'eventDrop', 'eventResize'],
  props: {
    options: {
      type: Object,
      default: () => ({}),
      validator(val) {
        if (!Object.keys(val).includes('timeZone')) {
          console.warning('PLEASE INCLUDE timeZone KEY IN EaFullcalendar OPTIONS')
          return false
        }
        return true
      },
    },
    events: {
      type: Array,
      default: () => [],
    },
    validRangeStart: {
      type: Date,
    },
  },
  data() {
    return {
      calendar: null,
    }
  },
  watch: {
    events: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.calendar.setOption('events', newVal)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.renderCalendar(this.initCalendar())
    this.$eventHub.$on('modal-resize', this.rerenderCalendar)
    this.$eventHub.$on('rerender-calendar', this.rerenderCalendar)
  },
  unmounted() {
    this.$eventHub.$off('modal-resize', this.rerenderCalendar)
    this.$eventHub.$off('rerender-calendar', this.rerenderCalendar)
  },
  methods: {
    rerenderCalendar() {
      if (this.calendar?.render) {
        this.calendar?.render()
      }
    },
    initCalendar() {
      const joinedOptions = Object.assign(
        {
          plugins: [dayjsTzPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin],
          themeSystem: 'bootstrap',
          initialView: 'dayGridMonth',
          events: this.events,
          height: 500,
          editable: true,
          headerToolbar: {
            left: 'prev,next,today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek',
          },
          eventDisplay: 'block',
          eventTimeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false,
            hour12: false,
          },
          displayEventEnd: true,
          validRange: {
            start: this.validRangeStart,
          },
          datesSet: dateInfo => {
            this.$emit('dateRangeUpdated', {
              endAt: dateInfo.endStr,
              startAt: dateInfo.startStr,
              calendar: this.calendar,
            })
          },
          eventClick: payload => {
            payload.calendar = this.calendar
            this.$emit('eventClick', payload)
          },
          dateClick: payload => {
            payload.calendar = this.calendar
            this.$emit('dateClick', payload)
          },
          eventDrop: payload => {
            payload.calendar = this.calendar
            this.$emit('eventDrop', payload)
          },
          eventResize: payload => {
            payload.calendar = this.calendar
            this.$emit('eventResize', payload)
          },
        },
        this.options,
      )
      this.calendar = new Calendar(this.$refs.fullcalendarEl, joinedOptions)

      return this.calendar
    },

    renderCalendar(calendar) {
      calendar.render()
    },
  },
}
</script>

<template lang="pug">
div(ref="fullcalendarEl")
</template>
