<script>
export default {
  name: 'ReportConfiguration',
  props: {
    startSendingAt: { type: String },
    stopSendingAt: { type: String },
    emailInterval: { type: Number },
  },
  computed: {
    isScheduled() {
      return !!(this.startSendingAt || this.stopSendingAt || this.emailInterval)
    },
    displayInterval() {
      let interval = this.emailInterval
      let days = 0
      Math.floor((interval ?? 0) / 24)
      const result = `Interval every ${interval} hours`
      if (!interval) {
        return 'Interval not set'
      } else if (interval > 24) {
        days = Math.floor(interval / 24)
        interval = interval - days * 24
        if (interval === 1) {
          return `Interval every ${days} days and ${interval} hour`
        } else if (interval > 1) {
          return `Interval every ${days} days and ${interval} hours`
        } else {
          return `Interval every ${days} days`
        }
      } else {
        return result
      }
    },
  },
}
</script>

<template lang="pug">
.text-nowrap(v-if="isScheduled")
  .row
    .col-sm-3.bold Start
    .col-sm-9
      formatted-time(:t="startSendingAt" format="short")
  .row
    .col-sm-3.bold Stop
    .col-sm-9
      formatted-time(:t="stopSendingAt" format="short")
  div {{ this.displayInterval }}
.text-black-30.text-nowrap(v-else) Settings not scheduled
</template>

<style scoped lang="scss"></style>
