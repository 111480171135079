<script setup lang="ts">
import EaInput from 'frontend/common/ea-form-controls/ea-input.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: string | number
  criterion: ISearchCriteriaNode
  predicate: string
  label?: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string | number): void
}
const emit = defineEmits<Emits>()

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <EaInput
    v-model="valueModel"
    :label="props.label"
    :name="`nameToBeRenamed`"
    :type="criterion.type == 'string' ? 'text' : criterion.type"
    skip-dirty-check
  />
</template>
