<script>
import { camelCase } from 'lodash'

export default {
  name: 'DriverShiftCheckInOut',
  props: {
    attr: { type: String, required: true },
    driver: { type: Object, default: () => ({}) },
  },
  computed: {
    computedAttr() {
      return camelCase(`computed ${this.attr}`)
    },
  },
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-start
  div {{ driver[computedAttr] || 0 }}min
  .ms-2(v-if="driver[attr] || driver[attr] == 0") overridden
  .ms-2.text-black-30(v-else) default
</template>
