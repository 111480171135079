<script>
export default {
  name: 'VehicleSourcesPricing',
  props: {
    vehicleSource: { type: Object, required: true },
  },
}
</script>

<template lang="pug">
span.text-nowrap
  span.text-black-15(v-if="vehicleSource.vehicleSourcePricings.length === 0") empty
  .d-flex.align-items-start.justify-content-between.mb-1(
    v-else
    v-for="vsp in vehicleSource.vehicleSourcePricings"
  )
    .d-flex
      erebus-label(:item="vsp.vehicleCategory")
      .ps-2 {{ vsp.vehicleType?.name }} {{ vsp.rate }} {{ vsp.rateCurrency }} per {{ vsp.rateAccuracy }}
</template>

<style scoped lang="scss"></style>
