import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const ColumnPresetDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'name',
    'data_model_type',
    'enabled_column_types',
    'default_sort_column_type',
    'default_sort_direction',
    'is_global',
  ],
  subscriptions: {

  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}