import { useVuelidate } from '@vuelidate/core'
import { useBlocker } from 'frontend/common'
import { ValidationService } from 'frontend/common/services/validation-service'
import { computed, inject, onMounted, ref } from 'vue'

export function useValidatedForm({
  validationUrl,
  service = new ValidationService({ validationUrl }),
  blocker,
} = {}) {
  let isBlocked

  if (!blocker) {
    const { blocker: _blocker, isBlocked: _isBlocked } = useBlocker()
    blocker = _blocker
    isBlocked = _isBlocked
  }

  if (service) {
    service.blocker = blocker
  }
  const result = ref(null)
  const validatorsHash = ref({})

  const validators = computed(() => {
    return validatorsHash.value || {}
  })
  const v$ = useVuelidate()

  const $error = inject('$error')
  const $success = inject('$success')
  const serverErrors = ref({})

  const errorHandler = (error, errorMessage) => {
    if (error?.response?.status == 422) {
      serverErrors.value = error.response.data?.errors
      let properMessage = 'The form contains errors'
      if (serverErrors.value?.base) {
        properMessage = serverErrors.value.base.join(', ')
      }
      if (serverErrors.value?.gisHuman) {
        properMessage = serverErrors.value.gisHuman.join(', ')
      }
      $error({
        title: errorMessage,
        message: properMessage,
      })
    } else if (error?.response?.status == 404) {
      $error({ title: errorMessage, message: "The record couldn't be found" })
    } else {
      $error({ message: errorMessage })
    }
  }

  const saveRecord = ({
    data,
    closeFn: onSuccess = () => {},
    asFormData = false,
    successMessage = 'The record was successfully saved.',
    errorMessage = 'Can not save the record',
    localErrorHandler = errorHandler,
    additionalPayload = {},
    save,
  } = {}) => {
    if (!isFormValid(errorMessage)) {
      return Promise.reject('Form contains errors')
    }

    const saveFn = save ?? ((...args) => service.save(...args))
    return saveFn({
      data,
      asFormData,
      errorHandler: e => localErrorHandler(e, errorMessage),
      additionalPayload,
    }).then(data => {
      result.value = data
      if (successMessage) {
        $success({ message: successMessage })
      }
      onSuccess()
      return data
    })
  }

  function isFormValid(errorMessage) {
    v$.value.$reset()
    v$.value.$touch()

    if (v$.value.$invalid) {
      $error({
        title: errorMessage,
        message: 'The form contains errors',
      })
      return false
    }
    return true
  }

  onMounted(async () => {
    if (service) {
      validatorsHash.value = await service.getValidations?.({ validationUrl })
    }
  })

  return {
    v$,
    service,
    blocker,
    isBlocked,
    validators,
    saveRecord,
    isFormValid,
    serverErrors,
    result,
    errorHandler,
  }
}
