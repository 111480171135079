<script>
export default {
  name: 'FullName',
  props: {
    item: { type: Object },
  },
}
</script>

<template lang="pug">
span.text-nowrap(v-if="item?.surname?.length || item?.name?.length")
  span.bold {{ item.surname }}
  span.ms-1 {{ item.name }}
span.text-nowrap.text-black-15(v-else) empty
</template>
