<script setup lang="ts">
import { UsedCriteriaOutlineColorType } from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaOutlineColorType'
import UsedCriteriaValueWrapperComponent from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaValueWrapperComponent.vue'
import { computed } from 'vue'

interface Props {
  modelValue: boolean
}
const props = defineProps<Props>()

const outlineColorType = computed(() => {
  return props.modelValue
    ? UsedCriteriaOutlineColorType.Positive
    : UsedCriteriaOutlineColorType.Falsy
})
</script>

<template>
  <UsedCriteriaValueWrapperComponent :outline-color-type="outlineColorType" :show-predicate="false">
    <slot />
  </UsedCriteriaValueWrapperComponent>
</template>

<style scoped lang="scss">
.used-criteria-value-wrapper {
  &:deep(.boolean-criteria-value-type-input .option) {
    min-height: 0;
  }
}
</style>
