<script>
import { computed, inject, onUnmounted, ref, shallowRef, watch } from 'vue'

import { useFifaGisMap } from '../use-fifa-gis-map'

export default {
  name: 'FifaGisMarker',
  props: {
    marker: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const fifaGisMap = inject('fifaGisMap', ref(null))
    const fifaGisMapLoaded = inject('fifaGisMapLoaded', ref(false))

    const { preparePoint } = useFifaGisMap()

    const instance = shallowRef(null)

    const isReady = computed(() => fifaGisMap.value && fifaGisMapLoaded.value)

    watch(
      [isReady, () => props.marker],
      () => {
        if (!isReady.value) return

        if (!props.marker && instance.value) instance.value.dispose()

        if (instance.value) {
          instance.value.update(preparePoint(props.marker))
        } else {
          instance.value = fifaGisMap.value.addMarker(preparePoint(props.marker))
        }
      },
      { immediate: true },
    )

    onUnmounted(() => {
      if (instance.value) instance.value.dispose()
    })

    return {
      fifaGisMap,
      fifaGisMapLoaded,
    }
  },
}
</script>
<template lang="pug"></template>
