<script>
import GoogleDirectionsConfig from './google-directions-config.vue'
import googleGLogo from './google-g-logo.png'
import QmicDirectionsConfig from './qmic-directions-config.vue'
import qmicLogo from './qmic-logo.png'
import TomTomDirectionsConfig from './tom-tom-directions-config.vue'
import tomTomLogo from './tom-tom-logo.png'

export default {
  name: 'GisProviderDirectionsConfig',
  props: {
    provider: { type: String, required: true },
    config: { type: Object },
  },
  data() {
    return {
      googleGLogo,
      qmicLogo,
      tomTomLogo,
    }
  },
  components: {
    GoogleDirectionsConfig,
    TomTomDirectionsConfig,
    QmicDirectionsConfig,
  },
}
</script>

<template lang="pug">
.gis-config.text-nowrap(v-if="provider")
  .d-flex
    template(v-if="provider === 'GOOGLE_MAPS'")
      img.mt-1(:src="googleGLogo" height="14" v-tooltip="'Google Maps'")
      .ms-1
        google-directions-config(:config="config")
    template(v-else-if="provider === 'TOM_TOM'")
      img.mt-1(:src="tomTomLogo" height="14" v-tooltip="'TomTom'")
      .ms-1
        tom-tom-directions-config(:config="config")
    template(v-else-if="provider === 'QMIC'")
      img.mt-1(:src="qmicLogo" height="14" v-tooltip="'Qmic'")
      .ms-1
        qmic-directions-config(:config="config")
</template>
