import { humanizedDuration } from 'frontend/live-panel/helpers/duration'
import { computed, inject, ref } from 'vue'

export function useDurationRulePreview(durationRule, service) {
  const route = ref(null)
  const routeMetadata = ref(null)
  const serverErrors = ref(null)
  const $error = inject('$error')
  const loading = ref(false)

  const fetchDurationRulePreview = async (durationRule, options = {}) => {
    serverErrors.value = null

    try {
      loading.value = true
      const response = await service.previewRule({
        id: durationRule.id,
        departureAt: durationRule.departureAt,
        arrivalAt: durationRule.arrivalAt,
        ...options,
      })

      routeMetadata.value = response
      route.value = response.route
      loading.value = false
    } catch (e) {
      loading.value = false
      serverErrors.value = e.response?.data?.errors
      $error({ message: 'Could not fetch duration preview.' })
      console.error(e)
    }
  }

  const fetchDefaultEventRulePreview = async (durationRule, options = {}) => {
    serverErrors.value = null

    try {
      loading.value = true
      const response = await service.previewEventDefault({
        ...durationRule,
        ...options,
      })

      routeMetadata.value = response
      route.value = response.route
    } catch (e) {
      loading.value = false
      serverErrors.value = e.response?.data?.errors
      $error({ message: 'Could not fetch duration preview.' })
      console.error(e)
    }
  }

  const fetchPreview = (durationRule, options = {}) => {
    if (durationRule?.id) {
      return fetchDurationRulePreview(durationRule, options)
    } else {
      return fetchDefaultEventRulePreview(durationRule, options)
    }
  }

  const duration = computed(() => {
    return humanizedDuration(routeMetadata.value?.time)
  })

  const arrivalAtCeiled = computed(() => dayjs(routeMetadata.value.arrivalAt).ceil('seconds', 60))

  return {
    route,
    routeMetadata,
    serverErrors,
    fetchDurationRulePreview,
    fetchDefaultEventRulePreview,
    fetchPreview,
    duration,
    arrivalAtCeiled,
    loading,
  }
}
