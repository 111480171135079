<script>
export default {
  name: 'ShiftCheckInOut',
  props: {
    attrSuffix: { type: String, required: true },
    shift: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
.d-flex.align-items-center.justify-content-start
  div {{ shift[`computed${attrSuffix}`] || 0 }}min
  .ms-1(v-if="shift[`overridden${attrSuffix}`] || shift[`overridden${attrSuffix}`] == 0") overridden
  .ms-1.text-black-30(v-else) default
</template>
