import AbstractRoute from '../../common/abstract-route.vue'
import { RouteName } from './types/RouteName'

export function setupPassengerPortalRoutes() {
  return [
    {
      path: '/passenger-portal',
      component: () => import('passenger_portal/passenger-scope.vue'),
      name: RouteName.PassengerPortal,
      redirect: { name: RouteName.PassengerEvents },
      meta: { title: 'Passenger Portal' },
      children: [
        {
          path: 'events',
          component: AbstractRoute,
          redirect: { name: RouteName.PassengerEvents },
          meta: { title: 'Events' },
          children: [
            {
              path: '',
              component: () => import('passenger_portal/events/events-index.vue'),
              name: RouteName.PassengerEvents,
              meta: { title: 'Events' },
            },
            {
              path: ':eventSlug',
              component: () => import('passenger_portal/events/event-scope.vue'),
              redirect: { name: RouteName.PassengerDemands },
              name: RouteName.PassengerEvent,
              children: [
                {
                  path: 'demands',
                  name: RouteName.PassengerDemands,
                  component: () => import('passenger_portal/demands/demands-index.vue'),
                  meta: { title: 'Transport Bookings' },
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
