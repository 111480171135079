<script>
export default {
  name: 'EaSpinner',
  props: {
    withoutTimeout: { type: Boolean },
    matchParent: { type: Boolean },
    spinnerSize: { type: Number },
    waitMs: { type: Number, default: 150 },
    message: { type: String },
  },
  data() {
    return {
      visible: this.withoutTimeout ? true : false,
      visibilityTimeout: null,
    }
  },
  computed: {
    delay() {
      return import.meta.env.MODE !== 'test' ? this.waitMs : 0
    },
    spinnerStyles() {
      return {
        '--spinner-size': `${
          !!this.spinnerSize || this.spinnerSize == 0 ? this.spinnerSize : 60
        }px`,
      }
    },
  },
  mounted() {
    if (this.withoutTimeout) {
      this.visible = true
    } else {
      this.visibilityTimeout = setTimeout(() => {
        this.visible = true
      }, this.delay)
    }
  },
  beforeUnmount() {
    if (this.visibilityTimeout) {
      clearTimeout(this.visibilityTimeout)
    }
  },
}
</script>

<template lang="pug">
.spinner-background(v-if="visible" :class="{ 'match-parent': matchParent }")
  .d-flex.flex-column.align-items-center
    .spinner(:style="spinnerStyles")
      .spinner-item
      .spinner-item
      .spinner-item
      .spinner-item
      .spinner-item
    .message.mt-2.text-muted.text-center(v-if="message") {{ message }}
</template>
