import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";

export const OSLVRuleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'priority',
    { cluster: ['id', 'name', 'time_zone'] },
    {
      operational_service_level: [
        'id',
        'name',
        'font_color',
        'background_color',
        'border_color',
        'description',
      ],
    },
    { vehicle_types: ['id', 'name'] },
    { vehicle_categories: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
    {
      vehicles: [
        'id',
        'fleet_number',
        'registration_plate',
        { cluster: ['id', 'name', 'time_zone'] },
        { vehicle_model: ['id', 'name', { vehicle_brand: ['id', 'name'] }] },
        { vehicle_type: ['id', 'name'] },
        {
          vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
        },
      ],
    },
  ],
  subscriptions: {
    items: 'OSLVRulesChannel',
    clusters: 'ClustersSimpleChannel',
    operationalServiceLevels: 'OperationalServiceLevelsSimpleChannel',
    vehicleTypes: 'VehicleTypesSimpleChannel',
    vehicleCategories: 'VehicleCategoriesSimpleChannel',
    vehicles: 'VehiclesDetailedChannel',
    vehicleModels: 'VehicleModelsSimpleChannel',
    vehicleBrands: 'VehicleBrandsSimpleChannel',
  },
  defaultColumns: [
  
  ],
  columnConfig: {

  }
}