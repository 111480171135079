import { inject } from 'vue'
import { useStore } from 'vuex'

function useSignOut(blocker = null) {
  const axios = inject('axios')
  const $store = useStore()
  const $error = inject('$error')
  const $success = inject('$success')

  const signOut = () => {
    if (blocker) blocker = true
    const result = axios({
      method: 'delete',
      url: 'session/sign_out',
    })

    result
      .then(() => {
        ;($store.state.session.user?.organizations || []).forEach(o => {
          if (o.sso && !!o.sloUrl) {
            axios({
              method: 'get',
              url: o.sloUrl,
            }).catch(() => {
              window.open(o.sloUrl, '_blank')
            })
          }
        })
        if (blocker) blocker = false
        $store.commit('session/setUser', null)
        $success({ message: 'You have been signed out' })
      })
      .catch(() => {
        if (blocker) blocker = false
        $error({ message: 'Sign out attempt failed.' })
      })
    return result
  }

  return {
    signOut,
  }
}

export { useSignOut }
