import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import Location from 'frontend/locations/location.vue'
import Cluster from 'frontend/clusters/cluster.vue'
import TPEnabledStatuses from 'frontend/transportation-points/transportation-point-enabled-statuses.vue'
import {markRaw} from 'vue'
import TrueFalseLabel from 'frontend/common/true-false-label.vue'

export const TransportationPointDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'priority',
    'address',
    'name',
    'on_spot_time',
    'is_load_zone',
    'is_specially_abled_load_zone',
    'updated_at',
    'enabled_for_activity',
    'enabled_for_arrival',
    'enabled_for_departure',
    'enabled_for_onsite',
    'enabled_for_shuttle',
    'activity_lead_time',
    'arrival_lead_time',
    'departure_lead_time',
    'onsite_lead_time',
    'shuttle_lead_time',
    'activity_load_time',
    'arrival_load_time',
    'departure_load_time',
    'onsite_load_time',
    'shuttle_load_time',
    { location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
  ],
  subscriptions: {
    items: 'TransportationPointsChannel',
    locations: 'LocationsSimpleChannel',
    clusters: 'ClustersSimpleChannel',
  },
  defaultColumns: [
    'priority',
    'eid',
    'name',
    'location',
    'address',
    'enabled',
    'onSpotTime',
    'isLoadZone',
    'isSpeciallyAbledLoadZone',
    'updatedAt',
  ],
  columnConfig: {
    priority: {
      label: 'Priority',
      header: {
        sort: 'priority',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'priority',
      },
    },
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'eid',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'name',
      },
    },
    location: {
      label: 'Location',
      header: {
        sort: 'locationName',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: Location,
        componentProps: el => ({ location: el.location, nameNowrap: true }),
      },
    },
    cluster: {
      label: 'Cluster',
      header: {
        sort: 'locationClusterName',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: Cluster,
        componentProps: el => ({ cluster: el.location?.cluster }),
      },
    },
    address: {
      label: 'Address',
      header: {
        sort: 'lowerAddress',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'address',
      },
    },
    enabled: {
      label: 'Enabled',
      header: {},
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: TPEnabledStatuses,
        componentProps: tp => ({ transportationPoint: tp }),
      },
    },
    isLoadZone: {
      label: 'Load Zone',
      header: {
        sort: 'isLoadZone',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: markRaw(TrueFalseLabel),
        componentProps: tp => ({ item: tp.isLoadZone }),
      },
    },
    isSpeciallyAbledLoadZone: {
      label: 'Specially Abled Load Zone',
      header: {
        sort: 'isSpeciallyAbledLoadZone',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: markRaw(TrueFalseLabel),
        componentProps: tp => ({ item: tp.isSpeciallyAbledLoadZone }),
      },
    },
    onSpotTime: {
      label: 'On spot time',
      header: {
        sort: 'onSpotTime',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'function',
        fn: el => (el.onSpotTime || el.onSpotTime == 0 ? `${el.onSpotTime}min` : null),
      },
    },
    updatedAt: {
      label: 'Updated At',
      header: {},
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {},
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {},
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroupActivity => ({
          t: clientGroupActivity.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}