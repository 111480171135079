<script>
import { useModals } from 'frontend/common/modals'

export default {
  name: 'VehicleType',
  props: {
    vehicleType: { type: Object, default: () => ({}) },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
modal-button(v-if="vehicleType?.id" :item="vehicleType" :modal="previewModals.VehicleType")
  a {{ vehicleType?.name }}
span.text-black-15(v-else) empty
</template>
