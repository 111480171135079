<script>
export default {
  name: 'RoleHasScopedDataModelsLabel',
  props: {
    item: {
      type: [Boolean, Object],
      default: false,
    },
  },
}
</script>

<template lang="pug">
span
  span.badge.bg-success(v-if="!!item") Scoped
  span.text-black-15(v-else) No scoping
</template>

<style scoped lang="scss">
.bg-light {
  color: var(--custom-primary);
}
</style>
