import { defineAsyncComponent } from 'vue'

import { TransportationPoint as TP } from './transportation-point.js'

const TransportationPointsSelect = defineAsyncComponent(() =>
  import('./transportation-points-select.vue'),
)
const TransportationPointsTable = defineAsyncComponent(() =>
  import('./transportation-points-table.vue'),
)
const TransportationPoint = defineAsyncComponent(() => import('./transportation-point.vue'))
const TransportationPointModal = defineAsyncComponent(() =>
  import('./transportation-point-modal.vue'),
)

const TP_KINDS = Object.keys(TP.TB_KINDS_DEFINITIONS).map(k => ({
  value: k,
  label: TP.TB_KINDS_DEFINITIONS[k].title,
}))

export {
  TP_KINDS,
  TransportationPoint,
  TransportationPointModal,
  TransportationPointsSelect,
  TransportationPointsTable,
}
