<script>
import { cloneDeep } from 'lodash'
import draggable from 'vuedraggable'

import FormLabel from './_form-label.vue'

export default {
  name: 'EaDraggableNestedFormCollection',
  emits: ['update:modelValue'],
  props: {
    label: { type: String, default: null },
    addLabel: { type: String, default: 'Add an item' },
    required: { type: Boolean, default: false },
    modelValue: { type: Array, default: () => [] },
    validatorsUrl: { type: String, default: null },
    newItemIndex: { type: Number, default: null },
    minItems: { type: Number, default: null },
    disabledDeletionItemIndexes: { type: Array, default: () => [] },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != Array)
      },
    },
  },
  data() {
    return {
      localModalValue: [],
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    draggableData() {
      return {
        tag: 'div',
        type: 'transition-group',
      }
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.localModalValue = cloneDeep(this.modelValue)
      },
      deep: true,
    },
  },
  mounted() {
    this.localModalValue = [...(this.modelValue || [])]
  },
  methods: {
    addItem() {
      const newItem = {
        draggableKey: this.draggableKey(),
        isPivotingPoint: false,
      }
      this.newItemIndex
        ? this.localModalValue.splice(this.newItemIndex, 0, newItem)
        : this.localModalValue.push(newItem)
      this.updateParentComponent()
    },
    removeItem(index) {
      if (this.localModalValue[index]?.id) {
        this.localModalValue[index]._destroy = true
      } else {
        this.localModalValue.splice(index, 1)
      }
      this.updateParentComponent()
    },
    itemUpdated(index, item) {
      if (item.isPivotingPoint) {
        this.localModalValue.map(item => (item.isPivotingPoint = false))
      }
      this.localModalValue[index] = item
      this.updateParentComponent()
    },
    draggableKey() {
      return Math.random().toString(36).substring(3)
    },
    updateParentComponent() {
      const newValue = cloneDeep(this.localModalValue)
      newValue.map((item, index) => (item.position = index + 1))
      this.$emit('update:modelValue', newValue)
    },
    isRemoveButtonVisible(index) {
      if (
        this.minItems < this.localModalValue.length &&
        !this.disabledDeletionItemIndexes.includes(index) &&
        !this.localModalValue[index]._destroy
      ) {
        return true
      } else {
        return false
      }
    },
    onMoveCallback(evt) {
      if (
        this.disabledDeletionItemIndexes.includes(evt.draggedContext.index) ||
        this.disabledDeletionItemIndexes.includes(evt.draggedContext.futureIndex)
      ) {
        return false
      }
      return true
    },
  },
  components: {
    FormLabel,
    draggable,
  },
}
</script>

<template lang="pug">
.ea-draggable-nested-form-collection
  draggable(
    v-bind="dragOptions"
    :component-data="draggableData"
    :list="localModalValue"
    :move="onMoveCallback"
    @change="updateParentComponent"
    handle=".reorder-handle"
    item-key="draggableKey"
    tag="div"
  )
    template(#item="{ element, index }")
      .d-flex.flex-grow-1
        .nested-form-wrapper
          ea-nested-form(
            :additional-errors="(additionalErrors || [])[index]"
            :modelValue="element"
            :validators-url="validatorsUrl"
            @update:modelValue="itemUpdated(index, $event)"
          )
            template(#default="item")
              slot(v-bind="Object.assign({ index: index }, item)")

        .remove-button-wrapper
          remove-button.mt-1.p-2.align-self-start(
            v-if="isRemoveButtonVisible(index)"
            @click="removeItem(index)"
            size="sm"
          )
            i.bi-x-lg
  .text-end.mt-1
    add-button(@click="addItem()" size="sm") {{ addLabel }}
</template>
