import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class DayLabelsService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = eventSlug?.length ? `/events/${eventSlug}/day_labels` : `/day_labels`,
    blocker,
    fetchError = 'Failed to fetch occasion',
    saveError = 'Failed to save occasion.',
    apiProvider = axios,
    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'day-labels',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  get getForSelect() {
    return () =>
      this.selectService.fetchOptions({
        fields: ['id', 'name', 'font_color', 'background_color', 'border_color'],
        path: `${this.selectService.baseUrl}/selection_index`,
      })
  }
}
