import { defineAsyncComponent } from 'vue'

const ClientCategoriesModal = defineAsyncComponent(() => import('./client-categories-modal.vue'))
const ClientCategoriesSelect = defineAsyncComponent(() => import('./client-categories-select.vue'))
const ClientCategoriesTable = defineAsyncComponent(() => import('./client-categories-table.vue'))
const ClientCategory = defineAsyncComponent(() => import('./client-category.vue'))
const ClientCategoryModal = defineAsyncComponent(() => import('./client-category-modal.vue'))

export {
  ClientCategoriesModal,
  ClientCategoriesSelect,
  ClientCategoriesTable,
  ClientCategory,
  ClientCategoryModal,
}
