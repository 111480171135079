import { defineAsyncComponent } from 'vue'

const Shift = defineAsyncComponent(() => import('./shift.vue'))
const ShiftsSelect = defineAsyncComponent(() => import('./shifts-select.vue'))
const ShiftCopyModal = defineAsyncComponent(() => import('./shift-copy-modal.vue'))
const ShiftFormModal = defineAsyncComponent(() => import('./shift-form-modal.vue'))
const ShiftModal = defineAsyncComponent(() => import('./shift-modal.vue'))
const ShiftsTable = defineAsyncComponent(() => import('./shifts-table.vue'))
const ShiftDetails = defineAsyncComponent(() => import('./shift-details.vue'))

const PROCESS_STATES = {
  draft: 'Draft',
  planned: 'Planned',
  declined: 'Declined',
  checking_in: 'Checking-in',
  ready: 'Ready',
  started: 'Started',
  ended: 'Ended',
  checked_out: 'Checked-out',
}

const ALL_STATES = {
  acceptance_not_sent: 'Acceptance request not sent yet',
  acceptance_declined: 'Driver declined',
  acceptance_sent: 'Acceptance request sent',
  acceptance_seen: 'Driver saw acceptance request',
  acceptance_not_required: `Acceptance process skipped, waiting for check-in`,
  acceptance_accepted: `Driver accepted, waiting for check-in`,
  checking_in: 'Checking-in',
  started: 'Started',
  ended: 'Ended, waiting for check-out',
  checked_out: 'Checked-out',
}

const ALL_STATES_FOR_SEARCH = Object.keys(ALL_STATES).map(k => ({ value: k, label: ALL_STATES[k] }))

const KINDS = {
  normal: 'Normal',
  taxi: 'TAXI',
  self_driven: 'Self-driven',
}

const KINDS_FOR_SEARCH = Object.keys(KINDS).map(k => ({ value: k, label: KINDS[k] }))

const currentState = item => {
  if (['started', 'ended', 'checking_in', 'checked_out'].includes(item.aasmState)) {
    return ALL_STATES[item.aasmState]
  } else {
    return ALL_STATES[item.acceptanceAasmState] || 'Unknown'
  }
}

export {
  ALL_STATES,
  ALL_STATES_FOR_SEARCH,
  currentState,
  KINDS,
  KINDS_FOR_SEARCH,
  PROCESS_STATES,
  Shift,
  ShiftCopyModal,
  ShiftDetails,
  ShiftFormModal,
  ShiftModal,
  ShiftsSelect,
  ShiftsTable,
}
