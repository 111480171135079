<script>
import { inject } from 'vue'

import EaQueryPreview from './ea-query-preview.vue'

export default {
  name: 'DatatableQueryPreview',
  props: {
    query: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  setup() {
    return {
      searchCriteria: inject('advanced-criteria'),
    }
  },
  computed: {
    criteriaCount() {
      const mainGroup = this.query?.g?.[0]
      return mainGroup ? this.countCriteria(mainGroup) : 0
    },
    additionalStyle() {
      if (this.scale) {
        return {
          transform: `scale(${this.scale})`,
          transformOrigin: 'top left',
          marginBottom: `calc((${this.scale} - 1) * 100%)`,
        }
      }
      return {}
    },
  },
  methods: {
    countCriteria(group) {
      let sum = group?.c?.length || 0

      group?.g?.forEach(g => {
        sum += this.countCriteria(g)
      })

      return sum
    },
  },
  components: {
    EaQueryPreview,
  },
}
</script>

<template lang="pug">
.datatable-query-preview
  .d-flex.align-items-center
    .query-title.text-nowrap
      span.text-black-15(v-if="criteriaCount === 0") No criteria
      span(v-else)
        span.bold {{ criteriaCount }}&nbsp;
        span {{ criteriaCount === 1 ? 'Criterion' : 'Criteria' }}
    button.btn.btn-sm.btn-default.ps-1.pe-1.ms-1(
      v-if="criteriaCount > 0 && searchCriteria"
      @click="isExpanded = !isExpanded"
    )
      span(v-if="!isExpanded") EXPAND
      span(v-else) COLLAPSE
  div(v-if="isExpanded" :style="additionalStyle")
    ea-query-preview(:query="query" :readonly="readonly" :searchCriteria="searchCriteria")
</template>
