<script>
export default {
  name: 'DataTableActionButton',
  emits: ['click'],
  props: {
    tooltip: { type: String, default: null },
  },
}
</script>

<template lang="pug">
button.btn.btn-link.text-primary.py-0.px-1.mb-1(
  @click="$emit('click', $event)"
  type="button"
  v-tooltip.options="{ title: tooltip, placement: 'left' }"
)
  i.bi-three-dots-vertical
</template>
