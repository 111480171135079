import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from "vue";
import TrueFalseLabel from "frontend/common/true-false-label.vue";
import VehicleSourcesPricing from "frontend/vehicle-sources/vehicle-sources-pricing.vue";
import FormattedTime from "frontend/common/formatted-time.vue";

export const VehicleSourceDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'billable',
    'maintainable',
    'name',
    'updated_at',
    'created_at',
    {
      vehicle_source_pricings: [
        'id',
        'rate',
        'rate_accuracy',
        'rate_currency',
        { vehicle_type: ['id', 'name'] },
        {
          vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
        },
      ],
    },
  ],
  subscriptions: {
    items: 'VehicleSourcesChannel',
    vehicleSourcePricings: 'VehicleSourcePricingsSimpleChannel',
    vehicleTypes: 'VehicleTypesSimpleChannel',
    vehicleCategories: 'VehicleCategoriesSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'name',
    'billable',
    'maintainable',
    'vehicleSourcePricings',
    'updatedDatetime',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'lower_name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    billable: {
      label: 'Billable',
      header: {
        sort: 'billable',
      },
      cell: {
        kind: 'component',
        component: markRaw(TrueFalseLabel),
        componentProps: vehicleSource => ({ item: vehicleSource.billable }),
      },
    },
    maintainable: {
      label: 'Maintainable',
      header: {
        sort: 'maintainable',
      },
      cell: {
        kind: 'component',
        component: markRaw(TrueFalseLabel),
        componentProps: vehicleSource => ({ item: vehicleSource.maintainable }),
      },
    },
    vehicleSourcePricings: {
      label: 'Pricings',
      cell: {
        kind: 'component',
        component: markRaw(VehicleSourcesPricing),
        componentProps: vehicleSource => ({ vehicleSource: vehicleSource }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: driverSource => ({
          t: driverSource.createdAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}