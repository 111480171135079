<script>
import { computed, inject } from 'vue'

import Marker from '../marker.vue'
import Popup from '../popup.vue'
import LocationPin from './location-pin.vue'

export default {
  name: 'LocationMarker',
  props: {
    options: {
      type: Object,
    },
    popupOptions: {
      type: Object,
    },
    coordinates: {
      type: Array,
      required: true,
    },
    cursor: {
      type: String,
      default: 'pointer',
    },
  },
  setup(props) {
    const map = inject('map')
    const floatCoordinates = computed(() => props.coordinates?.map(coord => parseFloat(coord)))

    function zoomToMarker() {
      map.value?.flyTo({ center: floatCoordinates.value, maxDuration: 2500, speed: 2, zoom: 17 })
    }

    return {
      floatCoordinates,
      zoomToMarker,
    }
  },
  components: {
    Marker,
    Popup,
    LocationPin,
  },
}
</script>

<template lang="pug">
Marker(:coordinates="floatCoordinates" :options="options" @click.stop="zoomToMarker")
  template(#default)
    .marker-container
      LocationPin(:options="options")
  template(v-if="popupOptions" #popup)
    Popup(:coordinates="floatCoordinates" :options="popupOptions")
</template>
