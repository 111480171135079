import { DataModelActionType } from '../enum/DataModelActionType'
import { DataModelType } from '../enum/DataModelType'
import { IDataModelTypeItem } from '../types/IDataModelTypeItem'
import { DefaultAvailableDataModelActionTypes } from './DefaultAvailableDataModelActionTypes'

export const DataModelTypeItems: IDataModelTypeItem[] = [
  {
    dataModelType: DataModelType.Event,
    name: 'Event',
    availableActionTypes: [
      // ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.SyncLocations,
      DataModelActionType.SyncClientCategories,
      DataModelActionType.SyncClients,
      DataModelActionType.SyncTransportBookings,
      DataModelActionType.SyncShuttleTransfers,
      DataModelActionType.SyncDrivers,
      DataModelActionType.UpdateDefaultDurationRule,
      DataModelActionType.UpdateAutoTimeRecalculationConfig,
      DataModelActionType.OptOutSmsConfig,
    ],
  },
  {
    dataModelType: DataModelType.ASL,
    name: 'ASL',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.ClientFunction,
    name: 'Client Function',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.ClientFunctionRemark,
    name: 'Client Function Remark',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.DayLabel,
    name: 'Day Label',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.Role,
    name: 'Role',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.DriverCategory,
    name: 'Driver Category',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.LocationType,
    name: 'Location Type',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.OperationalServiceLevel,
    name: 'Operational Service Level',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.VehicleBrand,
    name: 'Vehicle Brand',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.VehicleCategory,
    name: 'Vehicle Category',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.VehicleModel,
    name: 'Vehicle Model',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.VehicleType,
    name: 'Vehicle Type',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.TSL,
    name: 'TSL',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.ClientCategory,
    name: 'Client Group Path',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.MapProvider,
    name: 'Map Provider',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.SystemNotification,
    name: 'System Notification',
    availableActionTypes: [DataModelActionType.ReadInput, DataModelActionType.Read],
  },
  {
    dataModelType: DataModelType.ClusterCalendarEntry,
    name: 'Cluster Calendar Entry',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.Version,
    name: 'Deleted resource',
    availableActionTypes: [DataModelActionType.ReadInput, DataModelActionType.Read],
  },
  {
    dataModelType: DataModelType.NotificationTemplate,
    name: 'Notification Template',
    availableActionTypes: [
      DataModelActionType.ReadInput,
      DataModelActionType.Read,
      DataModelActionType.Update,
    ],
  },
  {
    dataModelType: DataModelType.DriverCalendarEntry,
    name: 'Driver Calendar Entry',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.StagingAreaCalendarEntry,
    name: 'Staging Area Calendar Entry',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.Shift,
    name: 'Shift',
    availableActionTypes: [
      ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.WorkingHoursReport,
      DataModelActionType.Reify,
      DataModelActionType.AssignDriver,
      DataModelActionType.AssignVehicle,
      DataModelActionType.EmergencyReplaceDriver,
      DataModelActionType.ChangeAcceptanceStates,
      DataModelActionType.ChangeExecutionStatus,
    ],
  },
  {
    dataModelType: DataModelType.ShiftJob,
    name: 'Shift Job',
    availableActionTypes: [
      ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.AssignToShift,
      DataModelActionType.ManageAutoRecomputations,
      DataModelActionType.ManageStatus,
    ],
  },
  {
    dataModelType: DataModelType.ClientNonCompanion,
    name: 'Client Non Companion',
    availableActionTypes: [
      DataModelActionType.ReadInput,
      DataModelActionType.Read,
      DataModelActionType.Create,
      DataModelActionType.Delete,
    ],
  },
  {
    dataModelType: DataModelType.Client,
    name: 'Client',
    belongsToStandardLibrary: true,
    availableActionTypes: [
      DataModelActionType.ReadInput,
      DataModelActionType.Read,
      DataModelActionType.Create,
      DataModelActionType.Update,
      DataModelActionType.ToggleEmailCommunicationBlacklist,
      DataModelActionType.ToggleSmsCommunicationBlacklist,
      DataModelActionType.ManageArchives,
    ],
  },
  {
    dataModelType: DataModelType.ClientGroup,
    name: 'Client Group',
    belongsToStandardLibrary: true,
  },
  {
    dataModelType: DataModelType.ClientGroupActivity,
    name: 'Client Group Activity',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.EmailTemplate,
    name: 'Email Template',
    belongsToStandardLibrary: true,
    availableActionTypes: [DataModelActionType.ReadInput, DataModelActionType.Read],
  },
  {
    dataModelType: DataModelType.ShuttleTransfer,
    name: 'Shuttle Transfer',
  },
  {
    dataModelType: DataModelType.ShuttleService,
    name: 'Shuttle Service',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.VehicleSourcePricing,
    name: 'Vehicle Source Pricing',
  },
  {
    dataModelType: DataModelType.VehicleSource,
    name: 'Vehicle Source',
    belongsToStandardLibrary: true,
  },
  {
    dataModelType: DataModelType.Vehicle,
    name: 'Vehicle',
  },
  {
    dataModelType: DataModelType.TrackerDevice,
    name: 'Tracker Device',
  },
  {
    dataModelType: DataModelType.TrackingService,
    name: 'Tracking Service',
  },
  {
    dataModelType: DataModelType.Driver,
    name: 'Driver',
    availableActionTypes: [
      ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.AppLoginPossibilityManagement,
    ],
  },
  {
    dataModelType: DataModelType.DriverSource,
    name: 'Driver Source',
    belongsToStandardLibrary: true,
  },
  {
    dataModelType: DataModelType.ClientCommunicationRule,
    name: 'Client Communication Rule',
    availableActionTypes: [
      ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.ManageArchives,
    ],
  },
  {
    dataModelType: DataModelType.ClientCommunicationMessage,
    name: 'Client Communication Message',
    availableActionTypes: [
      DataModelActionType.ReadInput,
      DataModelActionType.Read,
      DataModelActionType.Discard,
      DataModelActionType.Deliver,
      DataModelActionType.Create,
      DataModelActionType.Update,
    ],
  },
  {
    dataModelType: DataModelType.OSLTBRule,
    name: 'OSL TB Rule',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.OSLVRule,
    name: 'OSL V Rule',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.OSLDRule,
    name: 'OSL D Rule',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.DurationRule,
    name: 'Duration Rule',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.Location,
    name: 'Location',
    belongsToStandardLibrary: true,
    availableActionTypes: [
      ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.CreateCustom,
    ],
  },
  {
    dataModelType: DataModelType.TransportationPoint,
    name: 'Transportation Point',
    belongsToStandardLibrary: true,
  },
  {
    dataModelType: DataModelType.TransportBuffer,
    name: 'Transport Buffer',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
    // belongsToStandardLibrary: true,
  },
  {
    dataModelType: DataModelType.StagingArea,
    name: 'Staging Area',
    belongsToStandardLibrary: true,
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.Cluster,
    name: 'Cluster',
    belongsToStandardLibrary: true,
  },
  {
    dataModelType: DataModelType.Depot,
    name: 'Depot',
    belongsToStandardLibrary: true,
  },
  {
    dataModelType: DataModelType.TransportBooking,
    name: 'Transport Booking',
    availableActionTypes: [
      ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.ResolveTime,
      DataModelActionType.ResolveTransportationPoints,
      DataModelActionType.UpdateTptComment,
      DataModelActionType.ResolveOsl,
      DataModelActionType.ProvideOsl,
      DataModelActionType.RecalculateTransferGroup,
      DataModelActionType.OverrideLeadLoadTime,
      DataModelActionType.ChangeTransportationPoints,
      DataModelActionType.CreateCustom,
    ],
  },
  {
    dataModelType: DataModelType.BatchOnsiteRequest,
    name: 'Batch Onsite Request',
    availableActionTypes: [
      ...DefaultAvailableDataModelActionTypes,
      DataModelActionType.ResolveOsl,
      DataModelActionType.ProvideOsl,
      DataModelActionType.ResolveTime,
      DataModelActionType.ResolveTransportationPoints,
      DataModelActionType.UpdateTptComment,
      DataModelActionType.ChangeTransportationPoints,
      DataModelActionType.CreateCustom,
    ],
  },
  {
    dataModelType: DataModelType.TransportBookingGroupingRule,
    name: 'Transport Booking Grouping Rule',
  },
  {
    dataModelType: DataModelType.EventOrganizationUser,
    name: 'User',
    belongsToStandardLibrary: true,
    availableActionTypes: [
      DataModelActionType.ReadInput,
      DataModelActionType.Read,
      DataModelActionType.AssignRole,
      DataModelActionType.ManageStatus,
      DataModelActionType.Delete,
    ],
  },
  {
    dataModelType: DataModelType.DriverRegistrationQrToken,
    name: 'Driver registration QR token',
    availableActionTypes: [
      DataModelActionType.ReadInput,
      DataModelActionType.Read,
      DataModelActionType.Create,
      DataModelActionType.Delete,
    ],
  },
  {
    dataModelType: DataModelType.DriverRegistrationRequest,
    name: 'Driver registration request',
    availableActionTypes: [
      DataModelActionType.ReadInput,
      DataModelActionType.Read,
      DataModelActionType.Update,
      DataModelActionType.Approve,
      DataModelActionType.Delete,
    ],
  },
  {
    dataModelType: DataModelType.VehicleImport,
    name: 'Vehicle import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.DriverImport,
    name: 'Driver import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.ClientImport,
    name: 'Client import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.ClientGroupActivityImport,
    name: 'Client group activity import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.ShuttleServiceImport,
    name: 'Shuttle service import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.TransportBookingImport,
    name: 'Transport booking import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.TransportationPointImport,
    name: 'Transportation point import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
  {
    dataModelType: DataModelType.EventOrganizationUserImport,
    name: 'User access import',
    availableActionTypes: [...DefaultAvailableDataModelActionTypes],
  },
]
