<script>
export default {
  name: 'PassengerRoundButton',
  emits: ['click'],
}
</script>

<template lang="pug">
button.btn-round(@click="$emit('click', $event)" type="button")
  slot
    i.bi-question
</template>
