import {FuseResultMatch} from 'fuse.js'
import {ITextPartItem} from 'frontend/filters/types/ITextPartItem'

export function getTextPartsByMatches(
  text: string,
  matches?: ReadonlyArray<FuseResultMatch>,
): ITextPartItem[] {
  const filteredMatches = matches?.filter(match => match.key === 'label')
  let textParts = []
  if (filteredMatches?.length) {
    let lastIndex = 0
    for (const match of filteredMatches) {
      const { indices } = match
      for (const indice of indices) {
        textParts.push({
          text: text.substring(lastIndex, indice[0]),
          isMatch: false,
        })
        textParts.push({
          text: text.substring(indice[0], indice[1] + 1),
          isMatch: true,
        })
        lastIndex = Number(indice[1] + 1)
      }
    }
    textParts.push({
      text: text.substring(lastIndex, text.length),
      isMatch: false,
    })
  } else {
    textParts = [
      {
        text,
        isMatch: false,
      },
    ]
  }
  return textParts
}