// import airbrake from 'frontend/_config/airbrake'

export default function errorHandler(err, vueApp, info) {
  const componentNames = `${getComponentNames(vueApp?.$parent || null)} > ${
    (vueApp.$options || {}).name || 'UNNAMED'
  }`
  console.error(
    `%c ERROR IN ${info}: ${componentNames}`,
    'font-weight: bold;',
    ', details below\n',
    err,
  )

  // if (import.meta.env.MODE.toString() != 'development') {
  //   const currentUser = vueApp.$store.state.session.user
  //   const route = vueApp.$root.$route || {}

  //   const payload = {
  //     error: err,
  //     context: {
  //       component: componentNames,
  //       user: currentUser,
  //     },
  //     params: {
  //       errorAdditionalInfo: info,
  //       routeData: {
  //         name: route.name,
  //         meta: route.meta,
  //         path: route.path,
  //         hash: route.hash,
  //         query: route.query,
  //         params: route.params,
  //         fullPath: route.fullPath,
  //       },
  //       componentProps: (vueApp.$options || {}).propsData || 'empty',
  //     },
  //     session: {
  //       localStorage: window.localStorage,
  //       sessionStorage: window.sessionStorage,
  //     },
  //   }
  //   airbrake.notify(payload)
  // }
}

const getComponentNames = function (cmp) {
  if (cmp && cmp.$parent) {
    let value = getComponentNames(cmp?.$parent)
    if (value.length && cmp?.$options?.name) {
      value += ' > '
    }
    return `${value}${cmp?.$options?.name || ''}`
  } else {
    return cmp?.$options?.name || ''
  }
}
