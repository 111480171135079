<script>
import { Dropdown } from 'bootstrap'
import { isAdmin } from 'frontend/common/access-helpers'
import { useModals } from 'frontend/common/modals'
import { useBlocker } from 'frontend/common/use-blocker'
import { EventOrganizationUsersService } from 'frontend/event-organization-users/event-organization-users-service'
import { EventsService } from 'frontend/events/events-service'
import { camelCase } from 'lodash'
import { provide, ref } from 'vue'
import { mapGetters } from 'vuex'

import DataTableFiltersSection from './data-table-filters-section.vue'
import DataTableUsedFilters from './data-table-used-filters.vue'

export default {
  name: 'DataTableFilters',
  emits: ['update:filters', 'open', 'close', 'hidden'],
  props: {
    filtersConfig: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
    },
    height: {
      type: Number,
      default: 300,
    },
    standalone: {
      type: Boolean,
      default: true,
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
    tableName: {
      type: String,
    },
    withPresets: {
      type: Boolean,
      default: false,
    },
    footerTop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const eouService = new EventOrganizationUsersService()
    const eventsService = new EventsService()
    provide('selectAppendToBody', true)
    provide('height', `${props.height}px`)
    const localFilters = ref({ ...props.filters })
    const dropdownTriggerRef = ref(null)
    const isOpened = ref(false)
    const firstLevelFiltersSectionRef = ref(null)
    const windowWidth = ref(window.innerWidth)

    const reposition = () => {
      if (dropdownTriggerRef.value && isOpened) {
        Dropdown.getInstance(dropdownTriggerRef.value)?.update()
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      reposition()
    })

    return {
      eouService,
      eventsService,
      ...useModals(),
      ...useBlocker(),
      localFilters,
      dropdownTriggerRef,
      firstLevelFiltersSectionRef,
      isOpened,
      resizeObserver,
      windowWidth,
    }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent']),
    ...mapGetters('session', { getEventOrganizationUser: 'eventOrganizationUser' }),
    eventOrganizationUser() {
      return this.getEventOrganizationUser(this.$route.params.eventSlug)
    },
    storedName() {
      return this.tableName || this.getParentNameIfTable(this.$parent)
    },
    noDefaultTableConfig() {
      return !this.eventOrganizationUser?.filterConfig?.[this.storedName]?.some(el => el?.default)
    },
    isSmallDevice() {
      return this.windowWidth <= 576 // sm bootstrap $grid-breakpoint
    },
    anyFilterSet() {
      return (
        this.filters &&
        Object.keys(this.filters).length &&
        Object.values(this.filters).some(x => x !== null)
      )
    },
    eouTableFilterConfigs() {
      return this.eventOrganizationUser?.filterConfig?.[this.storedName] || []
    },
    alphabeticalEouTableFilterConfigs() {
      return [...this.eouTableFilterConfigs].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
    },
    globalTableFilterConfigs() {
      return this.currentEvent?.globalFilterConfig?.[this.storedName] || []
    },
    alphabeticalGlobalTableFilterConfigs() {
      return [...this.globalTableFilterConfigs].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      )
    },
    defaultGlobalTableFilterConfig() {
      return this.globalTableFilterConfigs.find(globalConfig => globalConfig.default)
    },
    isAdmin() {
      return this.eventOrganizationUser && isAdmin(this.eventOrganizationUser)
    },
    isDisabled() {
      return !(this.eouTableFilterConfigs.length || this.globalTableFilterConfigs.length)
    },
    isDisabledForAdmin() {
      return !this.globalTableFilterConfigs.length
    },
  },
  watch: {
    filters() {
      this.localFilters = this.filters
    },
    dropdownTriggerRef: {
      handler(newEl, oldEl) {
        if (newEl && !oldEl) {
          new Dropdown(newEl, {
            offset: [0, -2],
            popperConfig: {
              onFirstUpdate: () => setTimeout(this.addTransition, 100),
              strategy: this.strategy,
              modifiers: [
                {
                  name: 'computeStyles',
                  options: {
                    adaptive: false, // true by default
                  },
                },
                {
                  name: 'offset',
                  options: {
                    offset: [0, -2],
                  },
                },
              ],
            },
          })
          newEl.addEventListener('show.bs.dropdown', () => {
            this.isOpened = true
            this.$emit('open', true)
          })
          newEl.addEventListener('hidden.bs.dropdown', () => {
            this.isOpened = false
            this.removeTransition()
            this.$emit('hidden', false)
          })
        }
      },
      immediate: true,
    },
    isOpened(value) {
      if (value) {
        this.$eventHub.$on('mouseup', this.closeIfAutoClose)
        this.attachResizeObserver()
      } else {
        this.$eventHub.$off('mouseup', this.closeIfAutoClose)
        this.resizeObserver.disconnect()
      }
    },
  },
  mounted() {
    this.dropdownTriggerRef.addEventListener('show.bs.dropdown', () => {
      this.localFilters = { ...this.filters }
    })
    this.dropdownTriggerRef.addEventListener('hidden.bs.dropdown', this.deselect)
    this.$eventHub.$on('windowResize', this.setWindowWidth)
  },
  unmounted() {
    this.$eventHub.$off('mouseup', this.closeIfAutoClose)
    this.$eventHub.$off('windowResize', this.setWindowWidth)
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    deselect() {
      if (this.firstLevelFiltersSectionRef) {
        this.firstLevelFiltersSectionRef.deselect()
      }
    },
    getParentNameIfTable(comp, level = 0) {
      if (level > 10) {
        return null
      }
      if (comp.$options.name.endsWith('Table')) {
        return camelCase(comp.$options.name)
      }
      if (comp.$parent) {
        return this.getParentNameIfTable(comp.$parent, level + 1)
      }
      return null
    },
    attachResizeObserver() {
      this.resizeObserver.observe(this.$refs.dropdownMenuRef)
    },
    addTransition() {
      this.$refs.dropdownMenuRef?.classList?.add('smooth-reposition')
    },
    removeTransition() {
      this.$refs.dropdownMenuRef?.classList?.remove('smooth-reposition')
    },
    closeIfAutoClose(event) {
      if (
        this.autoClose &&
        !this.$el.contains(event.target) &&
        !(
          typeof event.composedPath !== 'function' ||
          event.composedPath()?.some(el => el.classList?.contains('vs__dropdown-menu'))
        ) &&
        !(
          typeof event.composedPath !== 'function' ||
          event.composedPath()?.some(el => el.classList?.contains('flatpickr-calendar'))
        )
      ) {
        this.closeFn()
      }
    },
    closeFn() {
      if (this.dropdownTriggerRef) {
        Dropdown.getInstance(this.dropdownTriggerRef)?.hide()
      }
    },
    slotNames(column) {
      return Object.keys(this.filtersConfig[column]?.slots || {})
    },
    clear() {
      this.$emit('update:filters', {})
      this.localFilters = {}
      this.hide()
    },
    dismiss() {
      this.localFilters = { ...this.filters }
      this.hide()
    },
    setFilters() {
      const newFilters = Object.assign({}, this.filters, this.localFilters)
      this.$emit('update:filters', newFilters)
      this.hide()
    },
    setFiltersInResponseToUsedFiltersChange(filters) {
      const newFilters = Object.assign({}, this.filters, filters ? filters : {})
      this.$emit('update:filters', newFilters)
    },
    hide() {
      const dropdown = Dropdown.getInstance(this.dropdownTriggerRef)
      if (dropdown?.hide) {
        dropdown.hide()
      }
    },
    setSystemDefaultConfig(closeFn) {
      this.$emit('update:filters', this.defaultGlobalTableFilterConfig.value)
      this.hide()
      if (closeFn) closeFn()
    },
    saveAs({ name } = {}) {
      if (!name) return
      const filterConfig = this.eventOrganizationUser.filterConfig || {}
      filterConfig[this.storedName] = filterConfig[this.storedName] || []
      const storedFilterConfig = filterConfig[this.storedName]?.find(tg => tg.name == name)
      const filtersWithValue = this.cleanEmpty(this.localFilters)
      if (storedFilterConfig) {
        storedFilterConfig.value = filtersWithValue
      } else {
        filterConfig[this.storedName].push({
          name,
          value: filtersWithValue,
        })
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot save config' })
      }
      this.saveUserFilterConfig(filterConfig, errorHandler).then(() => {
        this.$success({ message: 'Filter config saved' })
      })
    },
    async saveUserFilterConfig(filterConfig) {
      await this.eouService.saveFilterConfig(this.eventOrganizationUser.id, { filterConfig })
    },
    async saveGlobalFilterConfig(globalFilterConfig) {
      await this.eventsService.saveGlobalFilterConfig(this.currentEvent.id, { globalFilterConfig })
    },
    cleanEmpty(obj) {
      return Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
    },
    saveAsAdmin({ name } = {}) {
      if (!name) return
      const globalFilterConfig = this.currentEvent.globalFilterConfig || {}
      globalFilterConfig[this.storedName] = globalFilterConfig[this.storedName] || []
      const storedGlobalFilterConfig = globalFilterConfig[this.storedName]?.find(
        tg => tg.name == name,
      )
      const filtersWithValue = this.cleanEmpty(this.localFilters)
      if (storedGlobalFilterConfig) {
        storedGlobalFilterConfig.value = this.localFilters
      } else {
        globalFilterConfig[this.storedName].push({
          name,
          value: filtersWithValue,
        })
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot save config' })
      }
      this.saveGlobalFilterConfig(globalFilterConfig, errorHandler).then(() => {
        this.$success({ message: 'Filter config saved' })
      })
    },
    setMyConfig(filterConfig, closeFn) {
      this.$emit('update:filters', filterConfig?.value)
      this.hide()
      if (closeFn) closeFn()
    },
    removeMyConfig(filterConfig) {
      this.$dialogs.confirm('Are you sure that you want to remove chosen config?').then(() => {
        const newUserFilterConfig = this.eventOrganizationUser.filterConfig || {}
        newUserFilterConfig[this.storedName] = newUserFilterConfig[this.storedName] || []
        const i = newUserFilterConfig[this.storedName].findIndex(tg => tg.name == filterConfig.name)
        if (i > -1) {
          newUserFilterConfig[this.storedName].splice(i, 1)

          const errorHandler = () => {
            this.$error({ message: 'Cannot remove config' })
          }
          this.eouService
            .saveFilterConfig(
              this.eventOrganizationUser.id,
              { filterConfig: newUserFilterConfig },
              errorHandler,
            )
            .then(() => {
              this.$success({ message: 'Filter config removed' })
            })
        }
      })
    },
    removeGlobalConfig(globalFilterConfig) {
      this.$dialogs.confirm('Are you sure that you want to remove chosen config?').then(() => {
        const newGlobalFilterConfig = this.currentEvent.globalFilterConfig || {}
        newGlobalFilterConfig[this.storedName] = newGlobalFilterConfig[this.storedName] || []
        const i = newGlobalFilterConfig[this.storedName].findIndex(
          tg => tg.name == globalFilterConfig.name,
        )
        if (i > -1) {
          newGlobalFilterConfig[this.storedName].splice(i, 1)
          const errorHandler = () => {
            this.$error({ message: 'Cannot remove config' })
          }
          this.eventsService
            .saveGlobalFilterConfig(
              this.currentEvent.id,
              {
                globalFilterConfig: newGlobalFilterConfig,
              },
              errorHandler,
            )
            .then(() => {
              this.$success({ message: 'Filter config removed' })
            })
        }
      })
    },
    setDefault(name) {
      const filterConfig = this.eventOrganizationUser.filterConfig || {}
      filterConfig[this.storedName] = filterConfig[this.storedName] || []
      const usedDefaultFilterConfig = filterConfig[this.storedName]?.find(tg => tg.default == true)
      const storedFilterConfig = filterConfig[this.storedName]?.find(tg => tg.name == name)

      if (storedFilterConfig == usedDefaultFilterConfig) {
        storedFilterConfig.default = false
      } else {
        if (usedDefaultFilterConfig) {
          usedDefaultFilterConfig.default = false
        }
        if (name) {
          if (storedFilterConfig) {
            storedFilterConfig.default = true
          } else {
            filterConfig[this.storedName].push({
              name,
              value: this.saveUserFilterConfig(this.localFilters),
            })
          }
        }
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot change default status' })
      }
      this.saveUserFilterConfig(filterConfig, errorHandler).then(() => {
        usedDefaultFilterConfig == storedFilterConfig
          ? this.$success({ message: 'Filter unset from default' })
          : this.$success({ message: 'Filter set as default' })
      })
    },
    setGlobalDefault({ name } = {}) {
      const { globalFilterConfig = {} } = this.currentEvent
      const globalTableConfigs = globalFilterConfig[this.storedName] || []
      const storedGlobalFilterConfig = globalTableConfigs?.find(tg => tg.name == name)
      const usedGlobalDefaultFilterConfig = globalTableConfigs?.find(tg => tg.default == true)

      if (storedGlobalFilterConfig == usedGlobalDefaultFilterConfig) {
        storedGlobalFilterConfig.default = false
      } else {
        if (usedGlobalDefaultFilterConfig && storedGlobalFilterConfig) {
          usedGlobalDefaultFilterConfig.default = false
          storedGlobalFilterConfig.default = true
        }
        if (storedGlobalFilterConfig) {
          storedGlobalFilterConfig.default = true
        } else {
          globalFilterConfig[this.storedName].push({
            name,
            value: this.saveGlobalFilterConfig(this.localFilters),
          })
        }
      }
      const errorHandler = () => {
        this.$error({ message: 'Cannot change default status' })
      }
      this.saveGlobalFilterConfig(globalFilterConfig, errorHandler).then(() => {
        usedGlobalDefaultFilterConfig == storedGlobalFilterConfig
          ? this.$success({ message: 'Filter unset from default' })
          : this.$success({ message: 'Filter set as default' })
      })
    },
  },
  components: {
    DataTableFiltersSection,
    DataTableUsedFilters,
  },
}
</script>

<template lang="pug">
.dropdown.data-table-filters
  .d-trigger.ms-2(
    ref="dropdownTriggerRef"
    data-bs-auto-close="false"
    data-bs-offset="0,-2"
    data-bs-toggle="dropdown"
  )
    slot(:anyFilterSet="anyFilterSet && isSmallDevice")
      .btn.btn-sm.fs-6.pb-0.pe-2.ps-2.pt-0(
        :class="anyFilterSet && isSmallDevice ? 'btn-outline-warning' : 'btn-outline-secondary'"
      )
        i(:class="[anyFilterSet && isSmallDevice ? 'bi-funnel-fill' : 'bi-funnel']")

  .dropdown-menu.data-table-filters-menu.px-2(
    ref="dropdownMenuRef"
    :class="{ 'footer-top': footerTop }"
  )
    .duplicated-actions-container
      a.btn.btn-xs.btn-outline-secondary.btn-bg-white.px-1.border-radius-br-0.border-radius-tr-0(
        @click="dismiss()"
        v-tooltip="'Dismiss'"
      )
        i.fas.fa-times.fa-fw
      a.btn.btn-xs.btn-primary.px-1.border-radius-bl-0.border-radius-tl-0(
        @click="setFilters()"
        size="sm"
        v-tooltip="'Set'"
      )
        i.fas.fa-check.fa-fw

    ea-spinner(v-if="isBlocked" :spinnerSize="30" matchParent)
    data-table-filters-section(
      ref="firstLevelFiltersSectionRef"
      :config="filtersConfig"
      :footerTop="footerTop"
      :level="0"
      :windowWidth="windowWidth"
      v-model="localFilters"
    )

    .data-table-filters-menu-active-mobile-filters(v-if="isSmallDevice")
      data-table-used-filters.in-dropdown-menu(
        :filtersConfig="filtersConfig"
        v-model:filters="filters"
        @update:filters="setFiltersInResponseToUsedFiltersChange($event)"
      )

    .filters-actions.d-flex.align-items-center.justify-content-between
      .d-flex.align-items-center.justify-content-between
        span(
          v-if="withPresets"
          v-tooltip.options="{ title: isDisabled ? 'No configs saved' : '', placement: 'bottom' }"
        )
          dropdown(:disabled="isDisabled")
            .btn.btn-sm.btn-outline-info.ms-1.px-1
              span.d-none.d-md-inline Config
              i.fas.fa-cog.fa-fw.d-md-none
            template(#items="{ closeFn }")
              h6.dropdown-header.bold(v-if="eouTableFilterConfigs.length") My configs
              li(v-for="filterConfig in alphabeticalEouTableFilterConfigs")
                a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1(
                  :disabled="!filterConfig?.value?.length"
                  :name="filterConfig.name"
                  @click.stop.prevent
                )
                  div
                    | {{ filterConfig.name }}
                  .text-end
                    button.datatable-preview-btn.btn.btn-link.text-primary.py-0.px-1(
                      @click="setDefault(filterConfig.name)"
                      type="button"
                      v-tooltip.options="{ title: filterConfig.default ? 'Is default' : 'Set as default', placement: 'left' }"
                    )
                      .hover-backdrop
                      i(:class="filterConfig.default ? 'bi-check-square' : 'bi-square'")

                    data-table-show-button(
                      @click="setMyConfig(filterConfig)"
                      tooltip="Load config"
                    )

                    data-table-remove-button(
                      @click="removeMyConfig(filterConfig)"
                      noMarginBefore
                      rootClass="d-inline"
                      tooltip="Remove config"
                    )

              li(v-if="eouTableFilterConfigs.length && globalTableFilterConfigs.length")
                hr.dropdown-divider
              h6.dropdown-header.bold(v-if="globalTableFilterConfigs.length") Global configs
              li(v-for="globalFilterConfig in alphabeticalGlobalTableFilterConfigs")
                a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1(
                  :disabled="!globalFilterConfig?.value?.length"
                  :name="globalFilterConfig.name"
                  @click.stop.prevent
                )
                  div
                    | {{ globalFilterConfig.name }}
                  .text-end
                    data-table-show-button(
                      @click="setMyConfig(globalFilterConfig)"
                      tooltip="Load config"
                    )
              li
                a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1.text-primary(
                  v-if="!!defaultGlobalTableFilterConfig"
                  @click.prevent
                )
                  div
                    | {{ defaultGlobalTableFilterConfig.name }} (global default)
                  .text-end
                    button.datatable-preview-btn.btn.btn-link.text-primary.py-0.px-1(
                      @click="setDefault()"
                      type="button"
                      v-tooltip.options="{ title: noDefaultTableConfig ? 'Is default' : 'Set as default', placement: 'left' }"
                    )
                      .hover-backdrop
                      i(:class="noDefaultTableConfig ? 'bi-check-square' : 'bi-square'")
                    data-table-show-button(@click="setSystemDefaultConfig()" tooltip="Load config")
        span(
          v-if="withPresets"
          v-tooltip.options="{ title: isDisabledForAdmin ? 'No global configs saved' : '', placement: 'bottom' }"
        )
          dropdown(v-if="isAdmin" :disabled="isDisabledForAdmin")
            .btn.btn-sm.btn-outline-danger.ms-1.px-1 Global
            template(#items="{ closeFn }")
              h6.dropdown-header.bold.small(v-if="globalTableFilterConfigs.length") Configured presets for every user
              li(v-for="globalFilterConfig in alphabeticalGlobalTableFilterConfigs")
                a.dropdown-item.d-flex.align-items-center.justify-content-between.me-1(
                  :disabled="!globalFilterConfig?.value?.length"
                  :name="globalFilterConfig.name"
                  @click.stop.prevent
                )
                  div
                    | {{ globalFilterConfig.name }}
                  .text-end
                    data-table-show-button(
                      @click="setMyConfig(globalFilterConfig)"
                      tooltip="Load config"
                    )

                    button.datatable-preview-btn.btn.btn-link.text-primary.py-0.px-1(
                      @click="setGlobalDefault(globalFilterConfig)"
                      type="button"
                      v-tooltip.options="{ title: globalFilterConfig.default ? 'Is default' : 'Set as default', placement: 'left' }"
                    )
                      .hover-backdrop
                      i(:class="globalFilterConfig.default ? 'bi-check-square' : 'bi-square'")

                    data-table-remove-button(
                      @click="removeGlobalConfig(globalFilterConfig)"
                      noMarginBefore
                      rootClass="d-inline"
                      tooltip="Remove config"
                    )
        dropdown(v-if="withPresets")
          .btn.btn-sm.btn-outline-success.ms-1.px-1 Save as
          template(#items="{ closeFn }")
            div(v-if="!isAdmin")
              li(v-for="filterConfig in alphabeticalEouTableFilterConfigs")
                a.dropdown-item(
                  :disabled="!filterConfig?.value?.length"
                  :name="filterConfig.name"
                  @click="saveAs(filterConfig)"
                )
                  | {{ filterConfig.name }}
                  span.ms-1.text-black-50.small(v-if="filterConfig.default") (default)
              li(v-if="eouTableFilterConfigs?.length")
                hr.dropdown-divider
              li
                modal-button(
                  :item="{ name: '' }"
                  :modal="formModals.Name"
                  @modalClosed="$event?.length ? saveAs({ name: $event }) : null"
                  external
                  modalSize="sm"
                )
                  template(#default="{ open }")
                    a.dropdown-item.text-primary(@click="open()" name="system") New set of filters

            dropdown.dropend(hoverable)
              .dropdown-item.d-flex.align-items-center.justify-content-between User configs
                i.fa.fa-chevron-right
              template(v-if="isAdmin" #items="{ closeFn }")
                li(v-for="filterConfig in alphabeticalEouTableFilterConfigs")
                  a.dropdown-item(
                    :disabled="!filterConfig?.value?.length"
                    :name="filterConfig.name"
                    @click="saveAs(filterConfig)"
                  )
                    | {{ filterConfig.name }}
                    span.ms-1.text-black-50.small(v-if="filterConfig.default") (default)
                li(v-if="eouTableFilterConfigs.length")
                  hr.dropdown-divider
                li
                  modal-button(
                    :item="{ name: '' }"
                    :modal="formModals.Name"
                    @modalClosed="saveAs({ name: $event })"
                    external
                    modalSize="sm"
                  )
                    template(#default="{ open }")
                      a.dropdown-item.text-primary(@click="open()" name="system") New set of filters

            dropdown.dropend(hoverable)
              .dropdown-item.d-flex.align-items-center.justify-content-between Global configs
                i.fa.fa-chevron-right
              template(v-if="isAdmin" #items="{ closeFn }")
                li(v-for="globalFilterConfig in alphabeticalGlobalTableFilterConfigs")
                  a.dropdown-item(
                    :disabled="!globalFilterConfig?.value?.length"
                    :name="globalFilterConfig.name"
                    @click="saveAsAdmin(globalFilterConfig)"
                  )
                    | {{ globalFilterConfig.name }}
                    span.ms-1.text-black-50.small(v-if="globalFilterConfig.default") (default)
                li(v-if="globalTableFilterConfigs.length")
                  hr.dropdown-divider
                li
                  modal-button(
                    :item="{ name: '' }"
                    :modal="formModals.Name"
                    @modalClosed="saveAsAdmin({ name: $event })"
                    external
                    modalSize="sm"
                  )
                    template(#default="{ open }")
                      a.dropdown-item.text-primary(@click="open()" name="system") New set of global filters
      div
        .btn.btn-sm.btn-outline-secondary.ms-1.px-1(@click="dismiss()" name="dismiss-filters") Dismiss
        .btn.btn-sm.btn-outline-danger.ms-1.px-1(@click="clear()" name="clear-filters") Clear
        .btn.btn-sm.btn-primary.ms-1(@click="setFilters()" name="propagate-filters" size="sm")
          span.d-none.d-md-inline SET
          i.fas.fa-check.fa-fw.d-md-none
</template>
