import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue'

export const EACH_LIMIT = 50

export function useInfiniteScroll({ options = [], props, fetchPage, total }) {
  const modelValue = toRef(props?.value || props, 'modelValue')
  const search = ref('')

  const selectedValueLength = computed(() => {
    if (modelValue?.value?.constructor == Array) {
      return modelValue.value.length
    } else if (modelValue?.value?.constructor == Set) {
      return modelValue.value.size
    } else if (
      (modelValue?.value?.constructor == String && modelValue.value.length) ||
      (modelValue?.value?.constructor != String &&
        (!!modelValue?.value || modelValue?.value == 0 || modelValue?.value == false))
    ) {
      return 1
    }
    return 0
  })

  const limit = ref(selectedValueLength.value + EACH_LIMIT)

  const paginatedOptions = computed(() => {
    return Array.from(options.value).slice(0, limit.value)
  })

  const totalCount = total
    ? ref(total)
    : computed(() => {
        return options.value.length
      })

  if (typeof fetchPage === 'function') {
    watch(limit, async newLimit => {
      if (newLimit > totalCount.value) {
        await fetchPage({ skip: totalCount.value, limit: newLimit }).then(options.value.concat)
      }
    })
  }

  const hasNextPage = computed(() => {
    return paginatedOptions.value.length < totalCount.value
  })

  const bottomListEl = ref(null)

  const infiniteScroll = async ([{ isIntersecting, target }]) => {
    if (isIntersecting) {
      const ul = target.offsetParent
      const scrollTop = ul.scrollTop
      limit.value += EACH_LIMIT
      await nextTick()
      ul.scrollTop = scrollTop
    }
  }

  const observer = ref(null)

  onMounted(() => {
    observer.value = new IntersectionObserver(infiniteScroll)
  })

  const onOpen = async () => {
    if (hasNextPage.value) {
      await nextTick()
      observer.value.observe(bottomListEl.value)
    }
  }

  const onClose = () => {
    limit.value = selectedValueLength.value + EACH_LIMIT
    observer.value.disconnect()
  }

  const onSearch = term => {
    search.value = term
  }

  return {
    selectedValueLength,
    hasNextPage,
    limit,
    paginatedOptions,
    selectBindings: {
      onOpen,
      onClose,
      onSearch,
    },
    bottomListEl,
  }
}
