import 'reflect-metadata'

import { Container } from 'inversify'

import { bindConstants } from './bind/bindConstants'
import { bindFactories } from './bind/bindFactories'
import { bindServices } from './bind/bindServices'
import { bindStrategies } from './bind/bindStrategies'

export function setupContainer(): Container {
  const container: Container = new Container()
  bindConstants(container)
  bindServices(container)
  bindFactories(container)
  bindStrategies(container)
  return container
}
