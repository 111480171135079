import { defineAsyncComponent } from 'vue'

const DriverCategoriesModal = defineAsyncComponent(() => import('./driver-categories-modal.vue'))
const DriverCategoriesSelect = defineAsyncComponent(() => import('./driver-categories-select.vue'))
const DriverCategoriesTable = defineAsyncComponent(() => import('./driver-categories-table.vue'))
const DriverCategory = defineAsyncComponent(() => import('./driver-category.vue'))
const DriverCategoryModal = defineAsyncComponent(() => import('./driver-category-modal.vue'))

export {
  DriverCategoriesModal,
  DriverCategoriesSelect,
  DriverCategoriesTable,
  DriverCategory,
  DriverCategoryModal,
}
