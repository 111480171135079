<script>
import { snakeCase } from 'lodash'

export default {
  name: 'EASort',
  emits: ['update:sort'],
  props: {
    sort: { type: [String, Array] },
    attribute: { type: [String, Array] },
  },
  computed: {
    iconClass() {
      if (this.attribute?.constructor == String) {
        if (this.sort?.constructor != String) return 'bi-arrow-down-up text-black-50'
        if (this.sort == `${snakeCase(this.attribute)} asc`) return 'bi-sort-down-alt text-warning'
        if (this.sort == `${snakeCase(this.attribute)} desc`) return 'bi-sort-up text-warning'
        return 'bi-arrow-down-up text-black-50'
      } else {
        if (this.sort?.constructor != Array) return 'bi-arrow-down-up text-black-50'
        if (this.sort.join('|') == this.attribute.map(el => `${snakeCase(el)} asc`).join('|'))
          return 'bi-sort-down-alt text-warning'
        if (this.sort.join('|') == this.attribute.map(el => `${snakeCase(el)} desc`).join('|'))
          return 'bi-sort-up text-warning'
        return 'bi-arrow-down-up text-black-50'
      }
    },
  },
  methods: {
    triggerSort() {
      if (this.attribute?.constructor == Array) {
        if (this.sort?.constructor != Array) {
          this.$emit(
            'update:sort',
            this.attribute.map(el => `${snakeCase(el)} asc`),
          )
          return
        }
        if (this.sort.join('|') == this.attribute.map(el => `${snakeCase(el)} asc`).join('|')) {
          this.$emit(
            'update:sort',
            this.attribute.map(el => `${snakeCase(el)} desc`),
          )
        } else if (
          this.sort.join('|') == this.attribute.map(el => `${snakeCase(el)} desc`).join('|')
        ) {
          this.$emit('update:sort', null)
        } else {
          this.$emit(
            'update:sort',
            this.attribute.map(el => `${snakeCase(el)} asc`),
          )
        }
      } else {
        if (this.sort == `${snakeCase(this.attribute)} asc`) {
          this.$emit('update:sort', `${snakeCase(this.attribute)} desc`)
        } else if (this.sort == `${snakeCase(this.attribute)} desc`) {
          this.$emit('update:sort', null)
        } else {
          this.$emit('update:sort', `${snakeCase(this.attribute)} asc`)
        }
      }
    },
  },
}
</script>

<template lang="pug">
.btn.btn-link.py-0.px-0(:name="'table-sort.' + attribute" @click="triggerSort()")
  i(:class="iconClass")
</template>
