<script>
export default {
  name: 'ClientCommunicationRuleDeliveryConditions',
  props: {
    clientCommunicationRule: { type: Object, default: () => ({}) },
  },
}
</script>

<template lang="pug">
.text-nowrap {{ clientCommunicationRule.sendMinutesBeforeStart || 0 }} minutes before pick-up
.text-nowrap(v-if="clientCommunicationRule.resendOnUpdate") and {{ clientCommunicationRule.resendMinutesAfterUpdate || 0 }} minutes after each update
</template>
