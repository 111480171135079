import { InterfaceType } from 'frontend/_setup/setupContainer/constants/InterfaceType'
import { inject, injectable } from 'inversify'
import { type Ref } from 'vue'
import { type RouteLocationNormalizedLoaded } from 'vue-router'

import { IDataModelTypeStrategy } from '../../../types/IDataModelTypeStrategy'

@injectable()
export class TransportBookingGroupingRuleDataModelTypeStrategy implements IDataModelTypeStrategy {
  public readonly baseUrl: string

  constructor(
    @inject(InterfaceType.constants.CurrentRoute)
    private currentRoute: Ref<RouteLocationNormalizedLoaded>,
  ) {
    this.baseUrl = `/events/${currentRoute.value.params.eventSlug}/transport_booking_grouping_rules`
  }

  canEditColumns: boolean
}
