<script>
import { useValidatedForm } from 'frontend/common/use-validated-form'
import { isEqual } from 'lodash'

import FormLabel from './_form-label.vue'

export default {
  name: 'EaNestedForm',
  emits: ['update:modelValue'],
  props: {
    label: { type: String, default: null },
    addLabel: { type: String, default: 'Add an item' },
    required: { type: Boolean, default: false },
    modelValue: { type: Object, default: () => ({}) },
    validatorsUrl: { type: String, default: null },
    additionalErrors: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      localModelValue: { ...this.modelValue },
    }
  },
  setup(props) {
    return useValidatedForm({ validationUrl: props.validatorsUrl })
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (!isEqual(newValue, this.localModelValue)) {
          this.localModelValue = { ...newValue }
        }
      },
      deep: true,
      immediate: true,
    },
    localModelValue: {
      handler(newValue) {
        if (!isEqual(newValue, this.modelValue)) {
          this.$emit('update:modelValue', newValue)
        }
      },
      deep: true,
    },
  },
  components: {
    FormLabel,
  },
}
</script>

<template lang="pug">
.mb-1
  form-label(v-if="label" :label="label" :required="required")
  slot(:item="localModelValue" :rowErrors="additionalErrors" :rowValidators="validators")
</template>
