import axios from 'frontend/_config/axios'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter'
import { ResourceService } from 'frontend/common/services/resource-service'
import { SimpleSelectService } from 'frontend/common/services/simple-select-service'

export class ColumnPresetService extends ResourceService {
  constructor({
    eventSlug = setupRouter.currentRoute.value.params.eventSlug,
    baseUrl = eventSlug ? `/events/${eventSlug}/column_presets` : '/column_presets',
    blocker,
    fetchError = 'Failed to fetch presets.',
    saveError = 'Failed to save preset.',
    apiProvider = axios,

    selectService = new SimpleSelectService(baseUrl, {
      apiProvider,
      blocker,
      blockerKey: 'column_presets',
    }),
  } = {}) {
    super({
      apiProvider,
      baseUrl,
      blocker,
      fetchError,
      saveError,
    })
    this.selectService = selectService
  }

  getAvailableFor(dataModelType, fields, errorHandler = e => this._onSaveError(e)) {
    return this._makeCall('post', errorHandler, {
      url: `${this.baseUrl}/available_for`,
      data: {
        dataModelTypeEq: dataModelType,
        fields,
        sorts: 'position asc',
      },
    })
  }

  saveOrder(columnPresetIds: string[], errorHandler = e => this._onSaveError(e)) {
    return this._makeCall('post', errorHandler, {
      url: `${this.baseUrl}/update_positions`,
      data: {
        ids: columnPresetIds,
      },
    })
  }
}
