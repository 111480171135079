import {IDataModelTypeConfig} from "frontend/dataModels/types/IDataModelTypeConfig";
import {markRaw} from 'vue'
import {Location} from 'frontend/locations'
import {StagingArea} from 'frontend/staging-areas'

export const StagingAreaCalendarEntryDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'start_at',
    'end_at',
    'kind',
    'updated_at',
    'created_at',
    {
      staging_area: [
        'id',
        'has_minimum_vehicles_allotment',
        'has_subfleet',
        'has_welcome_desk',
        { location: ['id', 'name', 'time_zone'] },
      ],
    },
  ],
  subscriptions: {
    items: 'CalendarEntriesWelcomeDeskWorkingHoursChannel',
    stagingAreas: 'StagingAreasSimpleChannel',
    locations: 'LocationsFlatChannel',
  },
  defaultColumns: [
    'eid',
    'location',
    'stagingArea',
    'startAt',
    'endAt'
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    location: {
      label: 'Location',
      header: {
        sort: 'stagingAreaLocationName',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'component',
        component: markRaw(Location),
        componentProps: entry => ({ minWidth: 250, location: entry.stagingArea.location }),
      },
    },
    stagingArea: {
      label: 'Staging Area',
      cell: {
        kind: 'component',
        component: markRaw(StagingArea),
        componentProps: entry => ({ stagingArea: entry.stagingArea }),
      },
    },
    startAt: {
      label: 'Start Datetime',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item?.startAt, tz: item.stagingArea?.location?.timeZone }),
      },
    },
    startTime: {
      label: 'Start Time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item?.startAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'timeOnly',
        }),
      },
    },
    startDate: {
      label: 'Start Date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item?.startAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'dateOnly',
        }),
      },
    },
    endAt: {
      label: 'End Datetime',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item?.endAt, tz: item.stagingArea?.location?.timeZone }),
      },
    },
    endTime: {
      label: 'End Time',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item?.endAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'timeOnly',
        }),
      },
    },
    endDate: {
      label: 'End Date',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item?.endAt,
          tz: item.stagingArea?.location?.timeZone,
          format: 'dateOnly',
        }),
      },
    },
    createdDatetime: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: entry => ({
          t: entry.createdAt,
        }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: entry => ({
          t: entry.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: entry => ({
          t: entry.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: entry => ({
          t: entry.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  }
}