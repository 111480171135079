<script setup lang="ts">
import CriteriaBuilderCategoryComponent from 'frontend/filters/components/CriteriaBuilderCategoryComponent.vue'
import { IMappedSearchCriteriaCategory } from 'frontend/filters/types/IMappedSearchCriteriaCategory'
import { computed } from 'vue'

interface Emits {
  (e: 'categoryClick', value: symbol): void
}
const emit = defineEmits<Emits>()

interface Props {
  categories: IMappedSearchCriteriaCategory[]
  level?: number
  currentCategoryId?: symbol
}
const props = withDefaults(defineProps<Props>(), {
  level: 0,
  currentCategoryId: null,
})

const parentCategories = computed(() => {
  return props.categories.filter(category => category.parentCategoryId === null)
})
</script>

<template>
  <div class="expandable-category-chooser">
    <div class="category-list">
      <CriteriaBuilderCategoryComponent
        v-for="category in parentCategories"
        :key="category.id"
        :categories="props.categories"
        :category-id="category.id"
        :current-category-id="currentCategoryId"
        @category-click="emit('categoryClick', $event)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.expandable-category-chooser {
  overflow-y: auto;
  font-size: 14px;
}
</style>
